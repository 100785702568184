import React, { useState } from 'react'
import { Row, Nav, Tab } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useRouteMatch, Switch, Route } from 'react-router'
import {
  getUserModuleActions,
  getUserSubModuleActions,
} from '../../../../store/user/selectors'
import { checkPermission } from '../../../../utils/utilFunctions'
import SrtFileView from '../../../compensationRecommendation/loan/srt/SrtFileView'
import LoanAttribute from '../LoanAttribute'
import SrtApplication from './SrtApplication'
import SrtAttribute from './SrtAttribute'
import SrtFileRegistration from './SrtFileRegistration'
import SrtReturn from './SrtReturn'

const Srt = () => {
  const { path } = useRouteMatch()
  const [selectedSection, setSelectedSection] = useState('srt')

  const actionListModule = useSelector(
    getUserModuleActions('application-registration', 'loan', 'srt')
  )

  const actionListSubModule = useSelector(
    getUserSubModuleActions(
      'application-registration',
      'loan',
      'srt',
      'file-return'
    )
  )

  const attributeActionListSubModule = useSelector(
    getUserSubModuleActions(
      'application-registration',
      'loan',
      'srt',
      'attribute'
    )
  )

  const renderSwitch = (selection: string) => {
    switch (selection) {
      case 'srt':
        return <SrtApplication />
      case 'srt-return':
        return <SrtReturn />
      case 'srt-attribute':
        return <SrtAttribute />
      default:
        return <SrtReturn />
    }
  }

  return (
    <Switch>
      <Route path={`${path}/`} exact>
        <Tab.Container id="left-tabs-example" defaultActiveKey="srt">
          <Row className="tab-page-layout">
            <Nav variant="pills" className="flex-row">
              {checkPermission('view', actionListModule) && (
                <Nav.Item className="custom-nav-item">
                  <Nav.Link
                    onClick={() => {
                      setSelectedSection('srt')
                    }}
                    eventKey="srt"
                  >
                    SRT Application
                  </Nav.Link>
                </Nav.Item>
              )}
              {checkPermission('view', actionListSubModule) && (
                <Nav.Item className="custom-nav-item">
                  <Nav.Link
                    onClick={() => {
                      setSelectedSection('srt-return')
                    }}
                    eventKey="srt-return"
                  >
                    SRT Return
                  </Nav.Link>
                </Nav.Item>
              )}
              {checkPermission('view', attributeActionListSubModule) && (
                <Nav.Item className="custom-nav-item">
                  <Nav.Link
                    onClick={() => {
                      setSelectedSection('srt-attribute')
                    }}
                    eventKey="srt-attribute"
                  >
                    SRT Attribute Details
                  </Nav.Link>
                </Nav.Item>
              )}
            </Nav>
          </Row>
          <Row className="page-tiles-layout align-content-start">
            {renderSwitch(selectedSection)}
          </Row>
        </Tab.Container>
      </Route>
      <Route
        path={`${path}/view/:id`}
        render={(props) => <SrtFileRegistration type="create" {...props} />}
      />
      <Route
        path={`${path}/return/:id`}
        render={(props) => <SrtFileRegistration type="return" {...props} />}
      />
      <Route
        path={`${path}/view-similar/:id`}
        render={(props) => <SrtFileView type="similar" {...props} />}
      />
      <Route
        path={`${path}/attribute/:id`}
        render={(props) => <LoanAttribute type="srt" {...props} />}
      />
    </Switch>
  )
}

export default Srt
