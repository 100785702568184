import apiService from './api/apiManager'

class DashBoardDataService {
  async apiDashboardData(dashboardData: {
    from: number
    to: number
    timeFilterIndex: number
  }) {
    return apiService.apiPost('/dashboard/view', dashboardData)
  }

  async apiDashboardDataView(dashboardData: {
    from: number
    to: number
    timeFilterIndex: number
    status: string
  }) {
    return apiService.apiPost('/dashboard/view/list', dashboardData)
  }
}

export default new DashBoardDataService()
