import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import {
  RouteComponentProps,
  useHistory,
  //   useLocation,
} from 'react-router-dom'
import ConfirmationModal from '../../../components/modals/ConfirmationModal'
import { manageStampChargeModel } from '../../../models/systemConfiguration/manageStampCharges/manageStampChargeModel'
import manageStampChargesService from '../../../services/systemConfiguration/manageStampCharges/manageStampChargesService'
import stamp_img from '../../../assets/images/systemConfiguration/manage-stamp-charges.svg'
import {
  ALERT_SUCCESS,
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../../utils/constants'
import { stapmpChargeAmend } from '../../../utils/successMessages'
import { showTotast } from '../../../utils/toast'
import { produce } from 'immer'
import { StampChargeErrorModel } from '../../../models/systemConfiguration/manageStampCharges/stampChargeErrorModel'
import * as validation from './validations'
import { validationError } from '../../../utils/errorMessages'
import DatePicker from 'react-datepicker'
import { validateForm } from '../../../utils/utilFunctions'
import { ValidationObjectModel } from '../../../models/common/validationObjectModel'
import PriceInputComponent from '../../../components/common/priceInputComponent/PriceInputComponent'
import InvalidFeedback from '../../../components/common/invalidFeedback/InvalidFeedback'

type TParams = { id: string }
const ManageStampChargeModify = ({ match }: RouteComponentProps<TParams>) => {
  const [stampCharge, setStampCharge] = useState({} as manageStampChargeModel)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [errors, setErrors] = useState({} as StampChargeErrorModel)
  const history = useHistory()
  useEffect(() => {
    async function getStampCharge() {
      const stampCharge = await manageStampChargesService.apiGetStampChargeByID(
        parseInt(match.params.id)
      )
      setStampCharge(stampCharge.data)
    }
    getStampCharge()
  }, [])

  const modify = async () => {
    const foundErrros = findFormErrors()
    if (Object.keys(foundErrros).length > 0) {
      showTotast(
        ALERT_WARNING,
        validationError,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setErrors(foundErrros)
    } else {
      setShowConfirmation(true)
    }
  }

  const findFormErrors = () => {
    const newErrors = {} as StampChargeErrorModel
    if (validation.validateStamChargeAmount(stampCharge.stampCharge)) {
      newErrors.stampCharge = validation.validateStamChargeAmount(
        stampCharge.stampCharge
      )
    }

    return newErrors
  }

  const stampChargeModify = async () => {
    const response = await manageStampChargesService.apiAddStampCharges(
      stampCharge
    )
    let success = false
    let message = response.errorMessage ? response.errorMessage : ''
    if (response.status === 200) {
      success = true
      message = stapmpChargeAmend
      history.goBack()
    }
    showTotast(
      success ? ALERT_SUCCESS : ALERT_WARNING,
      message,
      TOAST_POSITION_TOP_RIGHT,
      5,
      TOAST_TRANSITION_SLIDE
    )
  }

  return (
    <>
      <Row className="title-layout">
        <Col className="d-flex justify-content-start">
          <img src={stamp_img} alt="" />
          <span className="m-2">Manage Stamp Charges</span>
        </Col>
      </Row>
      <Row className="role-and-permission-body-layout mt-4">
        <Col sm={12} className="border-label">
          <div className="border-label-span">Modify Stamp Charges</div>
          <Form id="file-registration-form">
            <Form.Group className="mb-3" controlId="stampCharge">
              <Form.Label>Stamp Charge (Rs.)</Form.Label>
              <PriceInputComponent
                isInvalid={!!errors.stampCharge}
                placeholder="Stamp Charge"
                onChange={(price: string) => {
                  const newErrors = produce(errors, (draft) => {
                    draft.stampCharge = validateForm({
                      value: parseFloat(price),
                      section: 'Stamp Charge',
                      maxLength: 6,
                      type: 'number',
                    } as ValidationObjectModel)
                  })
                  setErrors(newErrors)
                  const newValue = produce(stampCharge, (draft) => {
                    draft.stampCharge = parseFloat(price)
                  })
                  setStampCharge(newValue)
                }}
                onErrorHandle={(price: string) => {
                  if (!price) {
                    const newValue = produce(stampCharge, (draft) => {
                      draft.stampCharge = 0
                    })
                    setStampCharge(newValue)
                  } else {
                    const clearError = produce(errors, (draft) => {
                      draft.stampCharge = ''
                    })
                    setErrors(clearError)
                  }
                }}
                value={stampCharge.stampCharge}
              />
              <span className="feedback-error-span-custom">
                {errors.stampCharge}
              </span>
            </Form.Group>
            <Form.Group className="mb-3" controlId="startDate">
              <Form.Label>Start Date</Form.Label>
              <DatePicker
                placeholderText="Start Date"
                className={
                  errors.startDate ? 'date-picker-validate' : 'form-control'
                }
                selected={
                  stampCharge.startDate != null
                    ? new Date(stampCharge.startDate * 1000)
                    : null
                }
                onSelect={(date: Date) => {
                  const newValue = produce(stampCharge, (draft) => {
                    draft.startDate = new Date(date).getTime() / 1000
                  })
                  setStampCharge(newValue)
                  const clearError = produce(errors, (draft) => {
                    draft.startDate = ''
                  })
                  setErrors(clearError)
                }}
                onChange={(date: Date) => {
                  const newValue = produce(stampCharge, (draft) => {
                    draft.startDate = new Date(date).getTime() / 1000
                  })
                  setStampCharge(newValue)
                  const clearError = produce(errors, (draft) => {
                    draft.startDate = ''
                  })
                  setErrors(clearError)
                }}
              />
              <span className="feedback-error-span-custom">
                {errors.startDate}
              </span>
            </Form.Group>
            <Form.Group className="mb-3" controlId="endDate">
              <Form.Label>End Date</Form.Label>
              <DatePicker
                placeholderText="End Date"
                className={
                  errors.endDate ? 'date-picker-validate' : 'form-control'
                }
                selected={
                  stampCharge.endDate != null
                    ? new Date(stampCharge.endDate * 1000)
                    : null
                }
                onSelect={(date: Date) => {
                  const newValue = produce(stampCharge, (draft) => {
                    draft.endDate = new Date(date).getTime() / 1000
                  })
                  setStampCharge(newValue)
                  const clearError = produce(errors, (draft) => {
                    draft.endDate = ''
                  })
                  setErrors(clearError)
                }}
                onChange={(date: Date) => {
                  const newValue = produce(stampCharge, (draft) => {
                    draft.endDate = new Date(date).getTime() / 1000
                  })
                  setStampCharge(newValue)
                  const clearError = produce(errors, (draft) => {
                    draft.endDate = ''
                  })
                  setErrors(clearError)
                }}
              />
              <span className="feedback-error-span-custom">
                {errors.endDate}
              </span>
            </Form.Group>
            <Form.Group className="mb-3" controlId="status">
              <Form.Label>Status</Form.Label>
              <Form.Control
                isInvalid={!!errors.status}
                className="form-select"
                as="select"
                value={stampCharge.status}
                onBlur={() => {
                  const newErrors = produce(errors, (draft) => {
                    draft.status = validateForm({
                      type: 'text',
                      value: stampCharge.status,
                      section: 'Status',
                    } as ValidationObjectModel)
                  })
                  setErrors(newErrors)
                }}
                onChange={(e) => {
                  const newValue = produce(stampCharge, (draft) => {
                    draft.status = e.target.value
                  })
                  setStampCharge(newValue)
                  const clearError = produce(errors, (draft) => {
                    draft.status = ''
                  })
                  setErrors(clearError)
                }}
              >
                <option value="">--Select Status--</option>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </Form.Control>
              <InvalidFeedback message={errors.status} />
            </Form.Group>
          </Form>
          <Row>
            <Col sm={12} className="d-flex justify-content-end mt-4">
              <button
                className="cancel-button"
                onClick={(e) => {
                  e.preventDefault()
                  history.goBack()
                }}
              >
                Cancel
              </button>
              <button onClick={modify} type="button" className="save-button">
                Save
              </button>
            </Col>
            {showConfirmation && (
              <ConfirmationModal
                name={'Confirmation'}
                message={'Do you want to modify this stamp charge ?'}
                onCancel={() => {
                  setShowConfirmation(false)
                }}
                onConfirm={stampChargeModify}
              />
            )}
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default ManageStampChargeModify
