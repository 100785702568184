import React, { FC, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import NewReportService from '../../../services/report/NewReportService'
import { handleNotification } from '../../../utils/utilFunctions'
import LoadingComponent from '../../../components/common/loading/LoadingComponent'

interface Props {
  showModal?: any
  onCancel?: any
  fileId: number
  fileRefNo: string
}

const AllDetailsReportModal: FC<Props> = ({
  showModal,
  onCancel,
  fileId,
  fileRefNo,
}) => {
  const [state, setState] = useState('')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal])

  const getData = async () => {
    if (fileId != 0) {
      const result = await NewReportService.getAllDeatalReportHtml(fileId)
      handleNotification(result, 'Fetch Data Successfully')
      if (result.status == 200) {
        setState(result.data)
        setLoading(false)
      }
    }
  }

  const handlePrint = () => {
    const doc = new Blob([state], { type: 'application/msword' })
    // const doc = new Blob(['\ufeff', htmlContent], {
    //   type: 'application/doc',
    // })

    const docName = `${fileRefNo}.doc`
    const fileURL = URL.createObjectURL(doc)
    const link = document.createElement('a')
    link.href = fileURL
    link.download = docName
    link.click()
  }

  return (
    <Modal
      size="lg"
      show={showModal}
      onHide={onCancel}
      aria-labelledby="example-modal-sizes-title-sm"
      dialogClassName="custom-modal-layout"
      contentClassName="custom-modal-content"
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        className="custom-modal-header"
      >
        <Modal.Title id="example-modal-sizes-title-sm">
          Summary Report
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div className="p-5 d-flex justify-content-center">
            <LoadingComponent />
          </div>
        ) : (
          <div
            className="p-5 d-flex justify-content-center"
            dangerouslySetInnerHTML={{ __html: state }}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <button className={`save-button`} onClick={handlePrint}>
          Print
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default AllDetailsReportModal
