import produce from 'immer'
import React, { FC, useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import ReactDatePicker from 'react-datepicker'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import InvalidFeedback from '../../../../components/common/invalidFeedback/InvalidFeedback'
import LoadingComponent from '../../../../components/common/loading/LoadingComponent'
import PriceInputComponent from '../../../../components/common/priceInputComponent/PriceInputComponent'
import ConfirmationModal from '../../../../components/modals/ConfirmationModal'
import { DSDivisionModal } from '../../../../models/administrativeArea/dsDivisionModal'
import { FileRegistrationModel } from '../../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { HouseDetailsModel } from '../../../../models/applicationRegistration/loan/houseDetails'
import { HouseDetailsErrorModel } from '../../../../models/applicationRegistration/loan/houseDetailsErrorModel'
import { ValidationObjectModel } from '../../../../models/common/validationObjectModel'
import { DistrictOption } from '../../../../models/selectOptionModels/districtOption'
import { DsOption } from '../../../../models/selectOptionModels/dsOption'
import { ProvinceOption } from '../../../../models/selectOptionModels/provinceOption'
import ahService from '../../../../services/applicationRegistration/loan/ahService'
import metaDataService from '../../../../services/metaDataService'
import {
  getProvincesOptions,
  geDistrictsOptions,
  getDsOptions,
} from '../../../../store/administrativeArea/selectors'
import { getUser } from '../../../../store/user/selectors'
import {
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../../../utils/constants'
import { validationError } from '../../../../utils/errorMessages'
import { natureOfDamageList } from '../../../../utils/metaData'
import { showTotast } from '../../../../utils/toast'
import {
  checkLoggedUser,
  checkStatsDraftOrModifyDraft,
  validateForm,
} from '../../../../utils/utilFunctions'

export interface AffectedHouseSectionProps {
  fileRegistrationObject: FileRegistrationModel
  onOk: any
  onSave: any
  onNext: any
}

interface ConfirmationModal {
  isShow: boolean
  message: string
  section: string
}

const AffectedHouseSection: FC<AffectedHouseSectionProps> = ({
  fileRegistrationObject,
  onOk,
  onSave,
  onNext,
}) => {
  const [showConfirm, setShowConfirm] = useState({
    isShow: false,
  } as ConfirmationModal)
  const [bankList, setBankList] = useState([])
  const [branchList, setBranchList] = useState([])
  const [loading, setLoading] = useState(true)
  const [houseDetails, setHouseDetails] = useState({} as HouseDetailsModel)
  const provinceList = useSelector(getProvincesOptions)
  const districtList = useSelector(geDistrictsOptions)
  const dsList = useSelector(getDsOptions)
  const user = useSelector(getUser)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errors, setErrors] = useState({} as HouseDetailsErrorModel)
  useEffect(() => {
    async function getMetaData() {
      console.log(loading)
      console.log(houseDetails)
      const bankData = await metaDataService.getBankList()
      if (bankData.status === 200) {
        setBankList(bankData.data)
      }

      const houseDetailsData = await ahService.apiAhGetAffectedHouseDetails(
        fileRegistrationObject.id
      )

      if (houseDetailsData.status === 200 && houseDetailsData.data.id != null) {
        getBranchesList(houseDetailsData.data.bankId)
        setHouseDetails(houseDetailsData.data)
      } else {
        const newHouseDetails = produce(houseDetails, (draft) => {
          draft.applicationFileId = fileRegistrationObject.id
          draft.anyPropertyDamagedDueToViolenceActivity = false
          draft.qualifiedForConsolidatedSubsidiesHousingDonations = false
          draft.anyRehabilitationLoansTakenPreviouslyForTheSame = false
          draft.incidentDate = fileRegistrationObject.incidentDate
          draft.dsDivision = {} as DSDivisionModal
        })
        setHouseDetails(newHouseDetails)
      }
      setLoading(false)
    }
    getMetaData()
  }, [fileRegistrationObject])
  const customStyles = {
    control: (base: any, state: { isFocused: any }) => ({
      ...base,
      // state.isFocused can display different borderColor if you need it
      borderColor: state.isFocused ? '#ddd' : 'red',
      // overwrittes hover style
      '&:hover': {
        borderColor: state.isFocused ? '#ddd' : 'red',
      },
    }),
  }
  const findErrors = () => {
    const newErrors = {} as HouseDetailsErrorModel
    if (
      validateForm({
        type: 'boolean',
        value: houseDetails.anyPropertyDamagedDueToViolenceActivity,
      } as ValidationObjectModel)
    ) {
      newErrors.anyPropertyDamagedDueToViolenceActivityError = validateForm({
        type: 'boolean',
        value: houseDetails.anyPropertyDamagedDueToViolenceActivity,
        section: 'Any property damaged due to violence activity',
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        type: 'text',
        maxLength: 255,
        value: houseDetails.addressOfTheAffectedHouse,
      } as ValidationObjectModel)
    ) {
      newErrors.addressOfTheAffectedHouseError = validateForm({
        type: 'text',
        maxLength: 255,
        value: houseDetails.addressOfTheAffectedHouse,
        section: 'Address of the Affected House',
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        type: 'text',
        value: houseDetails.dsDivision.provinceId,
      } as ValidationObjectModel)
    ) {
      newErrors.provinceIdError = validateForm({
        type: 'text',
        value: houseDetails.dsDivision.provinceId,
        section: 'Province',
      } as ValidationObjectModel)
    }
    if (houseDetails.dsDivision.provinceId) {
      if (
        validateForm({
          type: 'text',
          value: houseDetails.dsDivision.districtId,
        } as ValidationObjectModel)
      ) {
        newErrors.districtIdError = validateForm({
          type: 'text',
          value: houseDetails.dsDivision.districtId,
          section: 'District',
        } as ValidationObjectModel)
      }
    }
    if (houseDetails.dsDivision.districtId) {
      if (
        validateForm({
          type: 'text',
          value: houseDetails.dsDivisionId,
        } as ValidationObjectModel)
      ) {
        newErrors.dsDivisionIdError = validateForm({
          type: 'text',
          value: houseDetails.dsDivisionId,
          section: 'Ds Division',
        } as ValidationObjectModel)
      }
    }

    // if (
    //   validateForm({
    //     type: 'number',
    //     value: houseDetails.incidentDate,
    //   } as ValidationObjectModel)
    // ) {
    //   newErrors.incidentDateError = validateForm({
    //     type: 'number',
    //     value: houseDetails.incidentDate,
    //     section: 'Incident Date',
    //   } as ValidationObjectModel)
    // }

    if (
      validateForm({
        type: 'text',
        value: houseDetails.natureOfTheDamage,
      } as ValidationObjectModel)
    ) {
      newErrors.natureOfTheDamageError = validateForm({
        type: 'text',
        value: houseDetails.natureOfTheDamage,
        section: 'Nature of the Damage',
      } as ValidationObjectModel)
    }

    // if (
    //   validateForm({
    //     type: 'number',
    //     maxLength: 15,
    //     value: houseDetails.familyRegistrationCardNumber,
    //   } as ValidationObjectModel)
    // ) {
    //   newErrors.familyRegistrationCardNumberError = validateForm({
    //     type: 'number',
    //     maxLength: 15,
    //     value: houseDetails.familyRegistrationCardNumber,
    //     section: 'Family Registration Card Number',
    //   } as ValidationObjectModel)
    // }

    if (
      validateForm({
        type: 'boolean',
        value: houseDetails.qualifiedForConsolidatedSubsidiesHousingDonations,
      } as ValidationObjectModel)
    ) {
      newErrors.qualifiedForConsolidatedSubsidiesHousingDonationsError =
        validateForm({
          type: 'boolean',
          value: houseDetails.qualifiedForConsolidatedSubsidiesHousingDonations,
          section: 'Qualified for Consolidated subsidies Housing Donations',
        } as ValidationObjectModel)
    }
    if (houseDetails.qualifiedForConsolidatedSubsidiesHousingDonations) {
      if (
        validateForm({
          type: 'text',
          maxLength: 100,
          value: houseDetails.detailsOfAnySubsidiesReceived,
        } as ValidationObjectModel)
      ) {
        newErrors.detailsOfAnySubsidiesReceivedError = validateForm({
          type: 'text',
          maxLength: 100,
          value: houseDetails.detailsOfAnySubsidiesReceived,
          section: 'Details of any subsidies received',
        } as ValidationObjectModel)
      }
    }

    if (
      validateForm({
        type: 'boolean',
        value: houseDetails.anyRehabilitationLoansTakenPreviouslyForTheSame,
      } as ValidationObjectModel)
    ) {
      newErrors.anyRehabilitationLoansTakenPreviouslyForTheSameError =
        validateForm({
          type: 'boolean',
          value: houseDetails.anyRehabilitationLoansTakenPreviouslyForTheSame,
          section: 'Any rehabilitation loans taken previously for the same?',
        } as ValidationObjectModel)
    }
    if (houseDetails.anyRehabilitationLoansTakenPreviouslyForTheSame) {
      if (
        validateForm({
          type: 'number',
          maxLength: 9,
          minValue: 1,
          value: houseDetails.outstandingAmount,
        } as ValidationObjectModel)
      ) {
        newErrors.outstandingAmountError = validateForm({
          type: 'number',
          maxLength: 9,
          minValue: 1,
          value: houseDetails.outstandingAmount,
          section: 'Outstanding Amount (Rs.)',
        } as ValidationObjectModel)
      }

      if (
        validateForm({
          type: 'number',
          value: houseDetails.previousLoanBankId,
        } as ValidationObjectModel)
      ) {
        newErrors.previousLoanBankIdError = validateForm({
          type: 'number',
          value: houseDetails.previousLoanBankId,
          section: 'Bank Name',
        } as ValidationObjectModel)
      }
    }

    if (
      validateForm({
        type: 'number',
        maxLength: 9,
        minValue: 1,
        value: houseDetails.requiredLoanAmount,
      } as ValidationObjectModel)
    ) {
      newErrors.requiredLoanAmountError = validateForm({
        type: 'number',
        maxLength: 9,
        minValue: 1,
        value: houseDetails.requiredLoanAmount,
        section: 'Required Loan Amount (Rs.)',
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        type: 'number',
        maxLength: 9,
        minValue: 1,
        value: houseDetails.grantedLoanAmount,
      } as ValidationObjectModel)
    ) {
      newErrors.grantedLoanAmountError = validateForm({
        type: 'number',
        maxLength: 9,
        minValue: 1,
        value: houseDetails.grantedLoanAmount,
        section: 'Approved Loan Amount (Rs.)',
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        type: 'number',
        maxLength: 3,
        value: houseDetails.repaymentPeriod,
      } as ValidationObjectModel)
    ) {
      newErrors.repaymentPeriodError = validateForm({
        type: 'number',
        maxLength: 3,
        value: houseDetails.repaymentPeriod,
        section: 'Repayment period',
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        type: 'number',
        maxLength: 3,
        value: houseDetails.gracePeriod,
      } as ValidationObjectModel)
    ) {
      newErrors.gracePeriodError = validateForm({
        type: 'number',
        maxLength: 3,
        value: houseDetails.gracePeriod,
        section: 'Grace period',
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        type: 'number',
        value: houseDetails.bankId,
      } as ValidationObjectModel)
    ) {
      newErrors.bankIdError = validateForm({
        type: 'number',
        value: houseDetails.bankId,
        section: 'Bank Name',
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        type: 'number',
        value: houseDetails.branchId,
      } as ValidationObjectModel)
    ) {
      newErrors.branchIdError = validateForm({
        type: 'number',
        value: houseDetails.branchId,
        section: 'Branch Name',
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        type: 'number',
        maxLength: 15,
        value: houseDetails.accountNumber,
      } as ValidationObjectModel)
    ) {
      newErrors.accountNumberError = validateForm({
        type: 'number',
        maxLength: 15,
        value: houseDetails.accountNumber,
        section: 'Account Number',
      } as ValidationObjectModel)
    }
    return newErrors
  }
  const onClickSave = () => {
    const foundErrros = findErrors()
    console.log(foundErrros)
    if (Object.keys(foundErrros).length > 0) {
      showTotast(
        ALERT_WARNING,
        validationError,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setErrors(foundErrros)
    } else {
      const message = 'Do you wish to Save application ?'
      const section = 'save'
      setShowConfirm({
        isShow: true,
        message: message,
        section: section,
      })
    }
  }
  const onClickNext = () => {
    const foundErrros = findErrors()
    if (Object.keys(foundErrros).length > 0) {
      showTotast(
        ALERT_WARNING,
        validationError,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setErrors(foundErrros)
    } else {
      const message = 'Do you wish to Continue application ?'
      const section = 'next'
      setShowConfirm({
        isShow: true,
        message: message,
        section: section,
      })
    }
  }
  const handleConfirm = () => {
    if (showConfirm.section === 'save') {
      onSave(houseDetails)
    } else if (showConfirm.section === 'next') {
      onNext(houseDetails)
    }
    setShowConfirm({ isShow: false, message: '', section: '' })
  }

  const getBranchesList = async (id: number) => {
    const branchData = await metaDataService.getBranchesList(id)
    if (branchData.status == 200) {
      setBranchList(branchData.data)
    } else {
      setBranchList([])
    }
  }
  return (
    <>
      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          <Col sm={12} className="border-label">
            <div className="border-label-span">
              Affected House Details and Other Loans
            </div>
            <Form id="file-family-details-form">
              <Col sm={12} className="border-label">
                <div className="border-label-span">Affected House Details</div>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formDamageDueToViolance">
                    <Form.Label>
                      Any property damaged due to violence activity{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Row>
                      <Col>
                        <Form.Check
                          inline
                          name="formDamageDueToViolance"
                          label="Yes"
                          type="radio"
                          id={`formDamageDueToViolance-N`}
                          checked={
                            houseDetails.anyPropertyDamagedDueToViolenceActivity
                          }
                          onChange={() => {
                            const newHouseDetails = produce(
                              houseDetails,
                              (draft: HouseDetailsModel) => {
                                draft.anyPropertyDamagedDueToViolenceActivity =
                                  true
                              }
                            )
                            setHouseDetails(newHouseDetails)
                            const clearError = produce(errors, (draft) => {
                              draft.anyPropertyDamagedDueToViolenceActivityError =
                                ''
                            })
                            setErrors(clearError)
                          }}
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          inline
                          name="formDamageDueToViolance"
                          label="No"
                          type="radio"
                          id={`formDamageDueToViolance-O`}
                          checked={
                            !houseDetails.anyPropertyDamagedDueToViolenceActivity
                          }
                          onChange={() => {
                            const newHouseDetails = produce(
                              houseDetails,
                              (draft: HouseDetailsModel) => {
                                draft.anyPropertyDamagedDueToViolenceActivity =
                                  false
                              }
                            )
                            setHouseDetails(newHouseDetails)
                            const clearError = produce(errors, (draft) => {
                              draft.anyPropertyDamagedDueToViolenceActivityError =
                                ''
                            })
                            setErrors(clearError)
                          }}
                        />
                      </Col>
                    </Row>
                    <InvalidFeedback
                      message={
                        errors.anyPropertyDamagedDueToViolenceActivityError
                      }
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group className="mb-3" controlId="formAhAddress">
                    <Form.Label>
                      Address of the Affected House{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Form.Control
                      isInvalid={!!errors.addressOfTheAffectedHouseError}
                      type="text"
                      placeholder="Address of the Affected House"
                      onBlur={() => {
                        const newErrors = produce(errors, (draft) => {
                          draft.addressOfTheAffectedHouseError = validateForm({
                            type: 'text',
                            maxLength: 255,
                            value: houseDetails.addressOfTheAffectedHouse,
                            section: 'Address of the Affected House',
                          } as ValidationObjectModel)
                        })
                        setErrors(newErrors)
                      }}
                      onChange={(e) => {
                        const newHouseDetails = produce(
                          houseDetails,
                          (draft: HouseDetailsModel) => {
                            draft.addressOfTheAffectedHouse = e.target.value
                          }
                        )
                        setHouseDetails(newHouseDetails)
                        const clearError = produce(errors, (draft) => {
                          draft.addressOfTheAffectedHouseError = ''
                        })
                        setErrors(clearError)
                      }}
                      value={houseDetails.addressOfTheAffectedHouse}
                    />
                    <InvalidFeedback
                      message={errors.addressOfTheAffectedHouseError}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formProvince">
                    <Form.Label>
                      Province <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Select
                      styles={errors.provinceIdError ? customStyles : undefined}
                      isDisabled={fileRegistrationObject.modifyIndex > 0}
                      value={provinceList.filter((province: ProvinceOption) => {
                        return (
                          province.value === houseDetails.dsDivision?.provinceId
                        )
                      })}
                      options={provinceList}
                      onChange={(selectedOption) => {
                        const newFormValue = produce(
                          houseDetails,
                          (draft: HouseDetailsModel) => {
                            draft.dsDivision.provinceId = parseInt(
                              selectedOption?.value
                            )
                            draft.dsDivision.districtId = null
                            draft.dsDivision.id = null
                          }
                        )
                        setHouseDetails(newFormValue)
                        const clearError = produce(errors, (draft) => {
                          draft.provinceIdError = ''
                        })
                        setErrors(clearError)
                      }}
                    />
                    <InvalidFeedback message={errors.provinceIdError} />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formDistrict">
                    <Form.Label>
                      District <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Select
                      styles={errors.districtIdError ? customStyles : undefined}
                      value={districtList.filter((district: DistrictOption) => {
                        return (
                          district.value === houseDetails.dsDivision?.districtId
                        )
                      })}
                      isDisabled={
                        houseDetails.dsDivision?.provinceId === undefined ||
                        fileRegistrationObject.modifyIndex > 0
                      }
                      options={districtList.filter(
                        (district: DistrictOption) => {
                          return (
                            district.provinceId ===
                            houseDetails.dsDivision?.provinceId
                          )
                        }
                      )}
                      onChange={(selectedOption) => {
                        const newFormValue = produce(
                          houseDetails,
                          (draft: HouseDetailsModel) => {
                            draft.dsDivision.districtId = parseInt(
                              selectedOption?.value
                            )
                            draft.dsDivision.id = null
                          }
                        )
                        setHouseDetails(newFormValue)
                        const clearError = produce(errors, (draft) => {
                          draft.districtIdError = ''
                        })
                        setErrors(clearError)
                      }}
                    />
                    <InvalidFeedback message={errors.districtIdError} />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formBirthCDsDivision">
                    <Form.Label>
                      Ds Division{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Select
                      styles={
                        errors.dsDivisionIdError ? customStyles : undefined
                      }
                      value={dsList.filter((ds: DsOption) => {
                        return ds.value === houseDetails.dsDivision.id
                      })}
                      isDisabled={
                        houseDetails.dsDivision?.districtId === undefined ||
                        fileRegistrationObject.modifyIndex > 0
                      }
                      options={dsList.filter((ds: DsOption) => {
                        return (
                          ds.districtId === houseDetails.dsDivision?.districtId
                        )
                      })}
                      onChange={(selectedOption) => {
                        const newFormValue = produce(
                          houseDetails,
                          (draft: HouseDetailsModel) => {
                            draft.dsDivision.id = selectedOption?.value
                            draft.dsDivisionId = selectedOption?.value
                          }
                        )
                        setHouseDetails(newFormValue)
                        const clearError = produce(errors, (draft) => {
                          draft.dsDivisionIdError = ''
                        })
                        setErrors(clearError)
                      }}
                    />
                    <InvalidFeedback message={errors.dsDivisionIdError} />
                  </Form.Group>
                </Row>
                <Form.Group className="mb-3" controlId="formIncidentDate">
                  <Form.Label>Incident Date</Form.Label>
                  <ReactDatePicker
                    disabled={true}
                    required={true}
                    maxDate={new Date()}
                    className={
                      errors.incidentDateError
                        ? 'date-picker-validate'
                        : 'form-control'
                    }
                    placeholderText="Incident Date"
                    selected={
                      fileRegistrationObject.incidentDate != null
                        ? new Date(fileRegistrationObject.incidentDate * 1000)
                        : null
                    }
                  />
                  <InvalidFeedback message={errors.incidentDateError} />
                </Form.Group>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formNatureOfDamage">
                    <Form.Label>
                      Nature of the Damage{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Form.Control
                      isInvalid={!!errors.natureOfTheDamageError}
                      className="form-select"
                      as="select"
                      value={houseDetails.natureOfTheDamage}
                      onBlur={() => {
                        const newErrors = produce(errors, (draft) => {
                          draft.natureOfTheDamageError = validateForm({
                            type: 'text',
                            value: houseDetails.natureOfTheDamage,
                            section: 'Nature of the Damage',
                          } as ValidationObjectModel)
                        })
                        setErrors(newErrors)
                      }}
                      onChange={(e) => {
                        const newHouseDetails = produce(
                          houseDetails,
                          (draft: HouseDetailsModel) => {
                            draft.natureOfTheDamage = e.target.value
                          }
                        )
                        setHouseDetails(newHouseDetails)
                        const clearError = produce(errors, (draft) => {
                          draft.natureOfTheDamageError = ''
                        })
                        setErrors(clearError)
                      }}
                    >
                      {1 === 1 ? (
                        <option value="">--Select Nature of Damage--</option>
                      ) : (
                        ''
                      )}
                      {natureOfDamageList.map(
                        (
                          title: { name: string; id: number },
                          index: number
                        ) => {
                          return (
                            <option key={`country-${index}`} value={title.name}>
                              {title.name}
                            </option>
                          )
                        }
                      )}
                    </Form.Control>
                    <InvalidFeedback message={errors.natureOfTheDamageError} />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group
                    className="mb-3"
                    controlId="formRegistrationCardNumber"
                  >
                    <Form.Label>Family Registration Card Number</Form.Label>
                    <Form.Control
                      isInvalid={!!errors.familyRegistrationCardNumberError}
                      type="text"
                      placeholder="Family Registration Card Number"
                      onBlur={() => {
                        if (houseDetails.familyRegistrationCardNumber) {
                          const newErrors = produce(errors, (draft) => {
                            draft.familyRegistrationCardNumberError =
                              validateForm({
                                type: 'number',
                                maxLength: 15,
                                value:
                                  houseDetails.familyRegistrationCardNumber,
                                section: 'Family Registration Card Number',
                              } as ValidationObjectModel)
                          })
                          setErrors(newErrors)
                        }
                      }}
                      onChange={(e) => {
                        const newHouseDetails = produce(
                          houseDetails,
                          (draft: HouseDetailsModel) => {
                            if (e.target.value) {
                              draft.familyRegistrationCardNumber = parseInt(
                                e.target.value
                              )
                            } else {
                              draft.familyRegistrationCardNumber = ''
                            }
                          }
                        )
                        setHouseDetails(newHouseDetails)
                        const clearError = produce(errors, (draft) => {
                          draft.familyRegistrationCardNumberError = ''
                        })
                        setErrors(clearError)
                      }}
                      value={houseDetails.familyRegistrationCardNumber}
                    />
                    <InvalidFeedback
                      message={errors.familyRegistrationCardNumberError}
                    />
                  </Form.Group>
                </Row>
              </Col>
              <Col sm={12} className="border-label mt-5">
                <div className="border-label-span">Other Loan Details</div>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formHouseDonation">
                    <Form.Label>
                      Qualified for Consolidated subsidies Housing Donations{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Row>
                      <Col>
                        <Form.Check
                          inline
                          name="formHouseDonation"
                          label="Yes"
                          type="radio"
                          id={`formHouseDonation-N`}
                          checked={
                            houseDetails.qualifiedForConsolidatedSubsidiesHousingDonations
                          }
                          onChange={() => {
                            const newHouseDetails = produce(
                              houseDetails,
                              (draft: HouseDetailsModel) => {
                                draft.qualifiedForConsolidatedSubsidiesHousingDonations =
                                  true
                              }
                            )
                            setHouseDetails(newHouseDetails)
                            const clearError = produce(errors, (draft) => {
                              draft.qualifiedForConsolidatedSubsidiesHousingDonationsError =
                                ''
                              draft.detailsOfAnySubsidiesReceivedError = ''
                            })
                            setErrors(clearError)
                          }}
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          inline
                          name="formHouseDonation"
                          label="No"
                          type="radio"
                          id={`formHouseDonation-O`}
                          checked={
                            !houseDetails.qualifiedForConsolidatedSubsidiesHousingDonations
                          }
                          onChange={() => {
                            const newHouseDetails = produce(
                              houseDetails,
                              (draft: HouseDetailsModel) => {
                                draft.qualifiedForConsolidatedSubsidiesHousingDonations =
                                  false
                              }
                            )
                            setHouseDetails(newHouseDetails)
                            const clearError = produce(errors, (draft) => {
                              draft.qualifiedForConsolidatedSubsidiesHousingDonationsError =
                                ''
                              draft.detailsOfAnySubsidiesReceivedError = ''
                            })
                            setErrors(clearError)
                          }}
                        />
                      </Col>
                    </Row>
                    <InvalidFeedback
                      message={
                        errors.qualifiedForConsolidatedSubsidiesHousingDonationsError
                      }
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group
                    className="mb-3"
                    controlId="formDeatilsofSubsidies"
                  >
                    <Form.Label>
                      Details of any subsidies received{' '}
                      {houseDetails.qualifiedForConsolidatedSubsidiesHousingDonations ? (
                        <span className="required-field-astrix">*</span>
                      ) : (
                        ''
                      )}
                    </Form.Label>
                    <Form.Control
                      isInvalid={!!errors.detailsOfAnySubsidiesReceivedError}
                      type="text"
                      placeholder="Details of any subsidies received"
                      onBlur={() => {
                        const newErrors = produce(errors, (draft) => {
                          draft.detailsOfAnySubsidiesReceivedError =
                            validateForm({
                              type: 'text',
                              maxLength: 100,
                              value: houseDetails.detailsOfAnySubsidiesReceived,
                              section: 'Details of any subsidies received',
                            } as ValidationObjectModel)
                        })
                        setErrors(newErrors)
                      }}
                      onChange={(e) => {
                        const newHouseDetails = produce(
                          houseDetails,
                          (draft: HouseDetailsModel) => {
                            draft.detailsOfAnySubsidiesReceived = e.target.value
                          }
                        )
                        setHouseDetails(newHouseDetails)
                        const clearError = produce(errors, (draft) => {
                          draft.detailsOfAnySubsidiesReceivedError = ''
                        })
                        setErrors(clearError)
                      }}
                      value={houseDetails.detailsOfAnySubsidiesReceived}
                    />
                    <InvalidFeedback
                      message={errors.detailsOfAnySubsidiesReceivedError}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formRehabilitation">
                    <Form.Label>
                      Any rehabilitation loans taken previously for the same?{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Row>
                      <Col>
                        <Form.Check
                          inline
                          name="formRehabilitation"
                          label="Yes"
                          type="radio"
                          id={`formRehabilitation-N`}
                          checked={
                            houseDetails.anyRehabilitationLoansTakenPreviouslyForTheSame
                          }
                          onChange={() => {
                            const newHouseDetails = produce(
                              houseDetails,
                              (draft: HouseDetailsModel) => {
                                draft.anyRehabilitationLoansTakenPreviouslyForTheSame =
                                  true
                              }
                            )
                            setHouseDetails(newHouseDetails)
                            const clearError = produce(errors, (draft) => {
                              draft.anyRehabilitationLoansTakenPreviouslyForTheSameError =
                                ''
                              draft.outstandingAmountError = ''
                              draft.previousLoanBankIdError = ''
                            })
                            setErrors(clearError)
                          }}
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          inline
                          name="formRehabilitation"
                          label="No"
                          type="radio"
                          id={`formRehabilitation-O`}
                          checked={
                            !houseDetails.anyRehabilitationLoansTakenPreviouslyForTheSame
                          }
                          onChange={() => {
                            const newHouseDetails = produce(
                              houseDetails,
                              (draft: HouseDetailsModel) => {
                                draft.anyRehabilitationLoansTakenPreviouslyForTheSame =
                                  false
                              }
                            )
                            setHouseDetails(newHouseDetails)
                            const clearError = produce(errors, (draft) => {
                              draft.anyRehabilitationLoansTakenPreviouslyForTheSameError =
                                ''
                              draft.outstandingAmountError = ''
                              draft.previousLoanBankIdError = ''
                            })
                            setErrors(clearError)
                          }}
                        />
                      </Col>
                    </Row>
                    <InvalidFeedback
                      message={
                        errors.anyRehabilitationLoansTakenPreviouslyForTheSameError
                      }
                    />
                  </Form.Group>
                </Row>
                {houseDetails.anyRehabilitationLoansTakenPreviouslyForTheSame && (
                  <>
                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="formOutstandingAmount">
                        <Form.Label>
                          Outstanding Amount (Rs.){' '}
                          <span className="required-field-astrix">*</span>
                        </Form.Label>
                        <PriceInputComponent
                          isInvalid={!!errors.outstandingAmountError}
                          value={houseDetails.outstandingAmount}
                          placeholder="Outstanding Amount (Rs.)"
                          onChange={(price: string) => {
                            const newHouseDetails = produce(
                              houseDetails,
                              (draft: HouseDetailsModel) => {
                                draft.outstandingAmount = parseFloat(price)
                              }
                            )
                            setHouseDetails(newHouseDetails)
                            const newErrors = produce(errors, (draft) => {
                              draft.outstandingAmountError = validateForm({
                                type: 'number',
                                maxLength: 9,
                                minValue: 1,
                                value: parseFloat(price),
                                section: 'Outstanding Amount (Rs.)',
                              } as ValidationObjectModel)
                            })
                            setErrors(newErrors)
                          }}
                          onErrorHandle={(price: string) => {
                            if (!price) {
                              const newHouseDetails = produce(
                                houseDetails,
                                (draft: HouseDetailsModel) => {
                                  draft.outstandingAmount = 0
                                }
                              )
                              setHouseDetails(newHouseDetails)
                            } else {
                              const clearError = produce(errors, (draft) => {
                                draft.outstandingAmountError = ''
                              })
                              setErrors(clearError)
                            }
                          }}
                        />
                        <InvalidFeedback
                          message={errors.outstandingAmountError}
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="formBankNamePrevious">
                        <Form.Label>
                          {`Bank Name`}{' '}
                          <span className="required-field-astrix">*</span>
                        </Form.Label>
                        <Form.Control
                          isInvalid={!!errors.previousLoanBankIdError}
                          className="form-select"
                          as="select"
                          value={houseDetails.previousLoanBankId}
                          onBlur={() => {
                            const newErrors = produce(errors, (draft) => {
                              draft.previousLoanBankIdError = validateForm({
                                type: 'number',
                                value: houseDetails.previousLoanBankId,
                                section: 'Bank Name',
                              } as ValidationObjectModel)
                            })
                            setErrors(newErrors)
                          }}
                          onChange={(e) => {
                            const newHouseDetails = produce(
                              houseDetails,
                              (draft: HouseDetailsModel) => {
                                draft.previousLoanBankId = parseInt(
                                  e.target.value
                                )
                              }
                            )
                            setHouseDetails(newHouseDetails)
                            const clearError = produce(errors, (draft) => {
                              draft.previousLoanBankIdError = ''
                            })
                            setErrors(clearError)
                          }}
                        >
                          {houseDetails.previousLoanBankId == null ||
                          houseDetails.previousLoanBankId == 0 ? (
                            <option value="0">--Select Bank--</option>
                          ) : (
                            ''
                          )}
                          {bankList.map(
                            (
                              bank: { name: string; id: number },
                              index: number
                            ) => {
                              return (
                                <option key={`bank-${index}`} value={bank.id}>
                                  {bank.name}
                                </option>
                              )
                            }
                          )}
                        </Form.Control>
                        <InvalidFeedback
                          message={errors.previousLoanBankIdError}
                        />
                      </Form.Group>
                    </Row>
                  </>
                )}
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formLoanAmount">
                    <Form.Label>
                      Required Loan Amount (Rs.){' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <PriceInputComponent
                      isInvalid={!!errors.requiredLoanAmountError}
                      value={houseDetails.requiredLoanAmount}
                      placeholder="Required Loan Amount (Rs.)"
                      onChange={(price: string) => {
                        const newHouseDetails = produce(
                          houseDetails,
                          (draft: HouseDetailsModel) => {
                            draft.requiredLoanAmount = parseFloat(price)
                          }
                        )
                        setHouseDetails(newHouseDetails)
                        const newErrors = produce(errors, (draft) => {
                          draft.requiredLoanAmountError = validateForm({
                            type: 'number',
                            maxLength: 9,
                            minValue: 1,
                            value: parseFloat(price),
                            section: 'Required Loan Amount (Rs.)',
                          } as ValidationObjectModel)
                        })
                        setErrors(newErrors)
                      }}
                      onErrorHandle={(price: string) => {
                        if (!price) {
                          const newHouseDetails = produce(
                            houseDetails,
                            (draft: HouseDetailsModel) => {
                              draft.requiredLoanAmount = 0
                            }
                          )
                          setHouseDetails(newHouseDetails)
                        } else {
                          const clearError = produce(errors, (draft) => {
                            draft.requiredLoanAmountError = ''
                          })
                          setErrors(clearError)
                        }
                      }}
                    />
                    <InvalidFeedback message={errors.requiredLoanAmountError} />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formGrantedLoanAmount">
                    <Form.Label>
                      Approved Loan Amount (Rs.){' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <PriceInputComponent
                      isInvalid={!!errors.grantedLoanAmountError}
                      value={houseDetails.grantedLoanAmount}
                      placeholder="Approved Loan Amount (Rs.)"
                      onChange={(price: string) => {
                        const newHouseDetails = produce(
                          houseDetails,
                          (draft: HouseDetailsModel) => {
                            draft.grantedLoanAmount = parseFloat(price)
                          }
                        )
                        setHouseDetails(newHouseDetails)
                        const newErrors = produce(errors, (draft) => {
                          draft.grantedLoanAmountError = validateForm({
                            type: 'number',
                            maxLength: 9,
                            minValue: 1,
                            value: parseFloat(price),
                            section: 'Approved Loan Amount (Rs.)',
                          } as ValidationObjectModel)
                        })
                        setErrors(newErrors)
                      }}
                      onErrorHandle={(price: string) => {
                        if (!price) {
                          const newHouseDetails = produce(
                            houseDetails,
                            (draft: HouseDetailsModel) => {
                              draft.grantedLoanAmount = 0
                            }
                          )
                          setHouseDetails(newHouseDetails)
                        } else {
                          const clearError = produce(errors, (draft) => {
                            draft.grantedLoanAmountError = ''
                          })
                          setErrors(clearError)
                        }
                      }}
                    />
                    <InvalidFeedback message={errors.grantedLoanAmountError} />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formRepaymentPeriod">
                    <Form.Label>
                      Repayment Period (in months){' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Form.Control
                      isInvalid={!!errors.repaymentPeriodError}
                      type="text"
                      placeholder="Repayment Period (in months)"
                      onBlur={() => {
                        const newErrors = produce(errors, (draft) => {
                          draft.repaymentPeriodError = validateForm({
                            type: 'number',
                            maxLength: 3,
                            value: houseDetails.repaymentPeriod,
                            section: 'Repayment period',
                          } as ValidationObjectModel)
                        })
                        setErrors(newErrors)
                      }}
                      onChange={(e) => {
                        const newHouseDetails = produce(
                          houseDetails,
                          (draft: HouseDetailsModel) => {
                            draft.repaymentPeriod = parseInt(e.target.value)
                          }
                        )
                        setHouseDetails(newHouseDetails)
                        const clearError = produce(errors, (draft) => {
                          draft.repaymentPeriodError = ''
                        })
                        setErrors(clearError)
                      }}
                      value={houseDetails.repaymentPeriod}
                    />
                    <InvalidFeedback message={errors.repaymentPeriodError} />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formGracePeriod">
                    <Form.Label>
                      Grace Period (in months){' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Form.Control
                      isInvalid={!!errors.gracePeriodError}
                      type="text"
                      placeholder="Grace Period (in months)"
                      onBlur={() => {
                        const newErrors = produce(errors, (draft) => {
                          draft.gracePeriodError = validateForm({
                            type: 'number',
                            maxLength: 3,
                            value: houseDetails.gracePeriod,
                            section: 'Grace period',
                          } as ValidationObjectModel)
                        })
                        setErrors(newErrors)
                      }}
                      onChange={(e) => {
                        const newHouseDetails = produce(
                          houseDetails,
                          (draft: HouseDetailsModel) => {
                            draft.gracePeriod = parseInt(e.target.value)
                          }
                        )
                        setHouseDetails(newHouseDetails)
                        const clearError = produce(errors, (draft) => {
                          draft.gracePeriodError = ''
                        })
                        setErrors(clearError)
                      }}
                      value={houseDetails.gracePeriod}
                    />
                    <InvalidFeedback message={errors.gracePeriodError} />
                  </Form.Group>
                </Row>
              </Col>
              <Col sm={12} className="border-label mt-5">
                <div className="border-label-span">{`Bank Details`}</div>
                <Row>
                  <Form.Group as={Col} controlId="formBankName">
                    <Form.Label>
                      {`Bank Name`}{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Form.Control
                      isInvalid={!!errors.bankIdError}
                      className="form-select"
                      as="select"
                      value={houseDetails.bankId}
                      onBlur={() => {
                        const newErrors = produce(errors, (draft) => {
                          draft.bankIdError = validateForm({
                            type: 'number',
                            value: houseDetails.bankId,
                            section: 'Bank Name',
                          } as ValidationObjectModel)
                        })
                        setErrors(newErrors)
                      }}
                      onChange={(e) => {
                        const newHouseDetails = produce(
                          houseDetails,
                          (draft: HouseDetailsModel) => {
                            draft.bankId = parseInt(e.target.value)
                            draft.branchId = 0
                          }
                        )
                        getBranchesList(parseInt(e.target.value))
                        setHouseDetails(newHouseDetails)
                        const clearError = produce(errors, (draft) => {
                          draft.bankIdError = ''
                        })
                        setErrors(clearError)
                      }}
                    >
                      {houseDetails.bankId == null ||
                      houseDetails.bankId == 0 ? (
                        <option value="0">--Select Bank--</option>
                      ) : (
                        ''
                      )}
                      {bankList.map(
                        (bank: { name: string; id: number }, index: number) => {
                          return (
                            <option key={`bank-${index}`} value={bank.id}>
                              {bank.name}
                            </option>
                          )
                        }
                      )}
                    </Form.Control>
                    <InvalidFeedback message={errors.bankIdError} />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formBranchName">
                    <Form.Label>
                      {`Branch Name`}{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Form.Control
                      isInvalid={!!errors.branchIdError}
                      className="form-select"
                      as="select"
                      disabled={
                        houseDetails.bankId == null || houseDetails.bankId == 0
                      }
                      value={houseDetails.branchId}
                      onBlur={() => {
                        const newErrors = produce(errors, (draft) => {
                          draft.branchIdError = validateForm({
                            type: 'number',
                            value: houseDetails.branchId,
                            section: 'Branch Name',
                          } as ValidationObjectModel)
                        })
                        setErrors(newErrors)
                      }}
                      onChange={(e) => {
                        const newHouseDetails = produce(
                          houseDetails,
                          (draft: HouseDetailsModel) => {
                            draft.branchId = parseInt(e.target.value)
                          }
                        )
                        setHouseDetails(newHouseDetails)
                        const clearError = produce(errors, (draft) => {
                          draft.branchIdError = ''
                        })
                        setErrors(clearError)
                      }}
                    >
                      {houseDetails.branchId == null ||
                      houseDetails.branchId == 0 ? (
                        <option value="0">--Select Branch--</option>
                      ) : (
                        ''
                      )}
                      {branchList.map(
                        (
                          branch: { name: string; id: number },
                          index: number
                        ) => {
                          return (
                            <option key={`branch-${index}`} value={branch.id}>
                              {branch.name}
                            </option>
                          )
                        }
                      )}
                    </Form.Control>
                    <InvalidFeedback message={errors.branchIdError} />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formAccountNumber">
                    <Form.Label>
                      Account Number{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Form.Control
                      isInvalid={!!errors.accountNumberError}
                      type="text"
                      placeholder="Account Number"
                      onBlur={() => {
                        const newErrors = produce(errors, (draft) => {
                          draft.accountNumberError = validateForm({
                            type: 'number',
                            maxLength: 15,
                            value: houseDetails.accountNumber,
                            section: 'Account Number',
                          } as ValidationObjectModel)
                        })
                        setErrors(newErrors)
                      }}
                      onChange={(e) => {
                        const newHouseDetails = produce(
                          houseDetails,
                          (draft: HouseDetailsModel) => {
                            draft.accountNumber = e.target.value
                          }
                        )
                        setHouseDetails(newHouseDetails)
                        const clearError = produce(errors, (draft) => {
                          draft.accountNumberError = ''
                        })
                        setErrors(clearError)
                      }}
                      value={houseDetails.accountNumber}
                    />
                    <InvalidFeedback message={errors.accountNumberError} />
                  </Form.Group>
                </Row>
              </Col>
            </Form>
          </Col>
          <Col sm={12} className="d-flex justify-content-end mt-4">
            {checkStatsDraftOrModifyDraft(fileRegistrationObject.status) &&
              checkLoggedUser(
                user.epfNumber,
                fileRegistrationObject.allocateTo
              ) && (
                <>
                  <button
                    className="save-button custom-margin-right"
                    onClick={onClickNext}
                  >
                    Next
                  </button>
                  <button
                    className="save-button custom-margin-right"
                    onClick={onClickSave}
                  >
                    Save
                  </button>
                </>
              )}

            <button className="cancel-button" onClick={onOk}>
              Ok
            </button>
          </Col>
          {showConfirm.isShow && (
            <ConfirmationModal
              name={'Confirmation'}
              message={showConfirm.message}
              onCancel={() => {
                setShowConfirm({
                  isShow: false,
                  message: '',
                  section: '',
                })
              }}
              isRemark={false}
              onConfirm={handleConfirm}
            />
          )}
        </>
      )}
    </>
  )
}

export default AffectedHouseSection
