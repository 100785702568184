// Api Error Messages

import { CommonOptionModel } from '../models/selectOptionModels/CommonOptionModel'

// Sucess Messages

export const applicationReadyToModify = 'Application ready to modify'

export const networkError = 'Network not responed'
export const networkSettingError = 'Something went wrong'

// Toast Types

export const ALERT_WARNING = 'Alert-Warning'
export const ALERT_ERROR = 'Alert-Error'
export const ALERT_SUCCESS = 'Alert-Success'

// Toast Positions

export const TOAST_POSITION_TOP_RIGHT = 'top-right'

// Toasts Transitions

export const TOAST_TRANSITION_SLIDE = 'Slide'

export const titles = [
  { id: 1, name: 'Mr' },
  { id: 2, name: 'Mrs' },
  { id: 3, name: 'Miss' },
  { id: 4, name: 'Ms' },
  { id: 5, name: 'Dr' },
  { id: 6, name: 'Prof' },
]

export interface FlavourOption {
  readonly value: string
  readonly label: string
  readonly rating: string
}

export const flavourOptions: readonly FlavourOption[] = [
  { value: 'vanilla', label: 'Vanilla', rating: 'safe' },
  { value: 'chocolate', label: 'Chocolate', rating: 'good' },
  { value: 'strawberry', label: 'Strawberry', rating: 'wild' },
  { value: 'salted-caramel', label: 'Salted Caramel', rating: 'crazy' },
]

export interface RoleOption {
  value: number
  label: string
}

export const roles: readonly RoleOption[] = [
  { value: 4, label: 'Manager' },
  { value: 3, label: 'Admin' },
  { value: 2, label: 'Contributor' },
]

export const languages: readonly CommonOptionModel[] = [
  { value: 'sin', label: 'Sinhala' },
  { value: 'tam', label: 'Tamil' },
  { value: 'eng', label: 'English' },
]

export const types: readonly CommonOptionModel[] = [
  { value: 'pdf', label: 'Pdf' },
  { value: 'docx', label: 'Word' },
]

export const pageList = [
  { id: 1, name: 'Overview', routeURL: 'overview' },
  {
    id: 2,
    name: 'Application Registration',
    routeURL: 'application-registration',
  },
  {
    id: 3,
    name: 'Compensation recommendation',
    routeURL: 'compensation-recommendation',
  },
  { id: 4, name: 'Compensation Approval', routeURL: 'compensation-approval' },
  {
    id: 5,
    name: 'Compensation Certification',
    routeURL: 'compensation-certification',
  },
  { id: 6, name: 'Cheque payments', routeURL: 'cheque-payments' },
  { id: 7, name: 'Enquire applications', routeURL: 'enquire-applications' },
  { id: 8, name: 'system configurations', routeURL: 'system-configaration' },
  { id: 9, name: 'user management', routeURL: 'user-management' },
  { id: 10, name: 'my profile', routeURL: 'my-profile' },
  { id: 11, name: 'reports', routeURL: 'reports' },
]

// routes

// user-management
export const usermanagementRoute = 'user-management'
export const rolesAndPermissionRoute = 'role-and-permission'
export const userRoute = 'user'

// application-registration

export const fileRegistrationRoute = 'file-registration'
export const applicationFile = 'application-files'
export const fileAllocations = 'file-allocation'

// system-configuration

export const defaultPageLimit = 10
export const defaultOrderListLimit = ['created_date_time']

// actions on application
export const approve = 'approve'
export const reject = 'reject'
export const ApplicationReturn = 'return'

//cheque payment
export const approveCheque = 'approve-cheque'
export const addChequeDetails = 'add-cheque-details'
export const modifyCheque = 'modify-cheque'
export const printCheque = 'print-cheque'
export const printCoverLetter = 'print-cover-letter'
export const returnCheque = 'return-cheque-print'
export const chequeCancel = 'cheque-cancel'
export const applicationReturn = 'application-return'
export const applicationReturnWithModifyIndex =
  'application-return-with-modify-index'

//validation types
export const text = 'text'
export const number = 'number'
export const array = 'array'
