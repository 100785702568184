import './AdministrativeArea.scss'
import { FC } from 'react'

export interface DsDivisionProps {
  name?: string
  id?: number
}

const DsDivision: FC<DsDivisionProps> = ({ name, id }) => {
  return (
    <div className="mt-2 ds-list-item">
      <ul key={`ul-${id}-${name}`}>
        <li key={`li-${id}-${name}`}>{name}</li>
      </ul>
    </div>
  )
}

export default DsDivision
