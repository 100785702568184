import React from 'react'
import { Container } from 'react-bootstrap'
import { Switch, useRouteMatch, Route } from 'react-router-dom'
import ApplicationRegistration from '../../../pages/applicationRegistration/ApplicationRegistration'
import ChequePayments from '../../../pages/chequePayments/ChequePayments'
import CompensationApprovals from '../../../pages/compensationApprovals/CompensationApprovals'
import CompensationCertifications from '../../../pages/compensationCertifications/CompensationCertifications'
import CompensationRecommendation from '../../../pages/compensationRecommendation/CompensationRecommendation'
import DashBoard from '../../../pages/dashboard/DashBoard'
import SystemConfiguration from '../../../pages/systemConfiguration/SystemConfiguration'
import UserManagementPageComponent from '../../../pages/userManagement/UserManagementPageComponent'
import AuthorizationRoute from '../../../providers/AuthorizationRouteProvider'
import MyProfileView from '../../../pages/myProfile/myProfileView'
import AllNotification from '../../../pages/displayAllNotification/DisplayAllNotification'
import './HomeLoader.scss'
import EnquireApplication from '../../../pages/enquireApplication/EnquireApplication'
import Reports from '../../../pages/report/Reports'
import DatabaseManagement from '../../../pages/databaseManagement/DatabaseManagement'
import Applicant360 from '../../../pages/applicant360/Applicant360'
import ApplicationModify from '../../../pages/applicationModify/ApplicationModify'

const DashboardPageLoaderComponent = () => {
  const { path } = useRouteMatch()
  return (
    <Container className="page-loader-layout">
      <Switch>
        <AuthorizationRoute
          path={`/user-management`}
          component={UserManagementPageComponent}
        />
        <AuthorizationRoute
          path={`/application-registration`}
          component={ApplicationRegistration}
        />
        <AuthorizationRoute
          path={`/compensation-recommendation`}
          component={CompensationRecommendation}
        />
        <AuthorizationRoute
          path={`/compensation-approval`}
          component={CompensationApprovals}
        />
        <AuthorizationRoute
          path={`/compensation-certification`}
          component={CompensationCertifications}
        />
        <AuthorizationRoute path={`/dashboard`} component={DashBoard} />
        <AuthorizationRoute
          path={`/system-configuration`}
          component={SystemConfiguration}
        />
        <AuthorizationRoute path={`/my-profile`} component={MyProfileView} />
        <AuthorizationRoute
          path={`/cheque-payment`}
          component={ChequePayments}
        />
        <AuthorizationRoute
          path={`/all-notification`}
          component={AllNotification}
        />
        <AuthorizationRoute
          path={`/enquire-application`}
          component={EnquireApplication}
        />

        <AuthorizationRoute
          path={`/application-modification`}
          component={ApplicationModify}
        />

        <AuthorizationRoute
          path={`/database-management`}
          component={DatabaseManagement}
        />
        <AuthorizationRoute path={`/report`} component={Reports} />
        <AuthorizationRoute path={`/applicant-360`} component={Applicant360} />
        <Route path={`${path}/`} exact={true} component={DashBoard} />
      </Switch>
    </Container>
  )
}

export default DashboardPageLoaderComponent
