import { DashboardFilterModel } from '../../models/utils/dashboardFilterModel'

export const SET_STATUS_FILTERS = 'SET_STATUS_FILTERS'
export const UPDATE_STATUS_FILTER = 'UPDATE_STATUS_FILTER'
export const UPDATE_TIME_FILTER = 'UPDATE_TIME_FILTER'
export const RESET_TIME_FILTER = 'RESET_TIME_FILTER'

export interface DashboardFilterState {
  timeFilters: DashboardFilterModel[]
  statusFileters: DashboardFilterModel[]
}

export interface SetDashboardFilterPayload {
  filters: DashboardFilterModel[]
}

export interface UpdateDashboardFilterPayload {
  filter: DashboardFilterModel
  index: number
}

export interface ResetTimeFilterRequest {
  type: typeof SET_STATUS_FILTERS
  payload: SetDashboardFilterPayload
}

export interface DashboardFiltersSetRequest {
  type: typeof RESET_TIME_FILTER
}

export interface StatusFiltersUpdateRequest {
  type: typeof UPDATE_STATUS_FILTER
  payload: UpdateDashboardFilterPayload
}

export interface TimeFiltersUpdateRequest {
  type: typeof UPDATE_TIME_FILTER
  payload: UpdateDashboardFilterPayload
}

export type DashboardFilterActions =
  | DashboardFiltersSetRequest
  | StatusFiltersUpdateRequest
  | TimeFiltersUpdateRequest
  | DashboardFiltersSetRequest
