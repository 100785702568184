//import { createSelector } from 'reselect'
import { createSelector } from 'reselect'
import { DistrictModal } from '../../models/administrativeArea/districtModal'
import { DSDivisionModal } from '../../models/administrativeArea/dsDivisionModal'
import { GNDivisionModal } from '../../models/administrativeArea/gnDivisionModal'
import {
  setDistrictSelectOptions,
  setDsSelectOptions,
  setGnSelectOptions,
  setProvinceSelectOptions,
} from '../../utils/utilFunctions'
import { AppState } from '../rootReducer'

export const getProvinces = (state: AppState) =>
  state.administrativeArea.provinces

export const getDistricts = (state: AppState) =>
  state.administrativeArea.districts

export const getDistrictsByProvinceId = (id: number) => (state: AppState) =>
  state.administrativeArea.districts.filter((district: DistrictModal) => {
    return district.provinceId === id
  })

export const getDsByDistrictId = (id: number) => (state: AppState) =>
  state.administrativeArea.ds.filter((dsDivision: DSDivisionModal) => {
    return dsDivision.districtId === id
  })

export const getGnByDsId = (id: number) => (state: AppState) =>
  state.administrativeArea.gn.filter((gnDivision: GNDivisionModal) => {
    return gnDivision.dsDivisionId === id
  })

export const getGnByDistrictId = (id: number) => (state: AppState) =>
  state.administrativeArea.gn.filter((gnDivision: GNDivisionModal) => {
    return gnDivision.districtId === id
  })

export const getAllGnList = (state: AppState) => state.administrativeArea.gn

// export const getSelectedGnList = createSelector(getAllGnList, (gnList) => {
//   const selectedGn: GNDivisionModal[] = []
//   if (gnList.length == 0) {
//     return selectedGn
//   } else {
//     for (let i = 0; i < gnList.length; i++) {
//       const gnListModal = gnList[i]
//       const gnListObject = {} as GNDivisionModal
//       gnListObject.id = gnListModal.id
//       gnListObject.code = gnListModal.code
//       gnListObject.districtId = gnListModal.districtId
//       gnListObject.provinceId = gnListModal.provinceId
//       gnListObject.dsDivisionId = gnListModal.dsDivisionId
//       gnListObject.name = gnListModal.name
//       if (gnListModal.isSelected == true) {
//         selectedGn.push(gnListObject)
//       }
//     }
//     return selectedGn
//   }
// })

export const getDs = (state: AppState) => state.administrativeArea.ds

export const getSelectedDsList = createSelector(getDs, (dsList) => {
  const selectedDs: DSDivisionModal[] = []
  if (dsList.length == 0) {
    return selectedDs
  } else {
    for (let i = 0; i < dsList.length; i++) {
      const ds = dsList[i]
      const newDsList = {} as DSDivisionModal
      newDsList.id = ds.id
      newDsList.code = ds.code
      newDsList.districtId = ds.districtId
      newDsList.provinceId = ds.provinceId
      newDsList.name = ds.name
      if (ds.isSelected == true) {
        selectedDs.push(newDsList)
      }
    }
    return selectedDs
  }
})

export const getProvincesOptions = (state: AppState) => {
  return setProvinceSelectOptions(state.administrativeArea.provinces)
}

export const geDistrictsOptions = (state: AppState) => {
  return setDistrictSelectOptions(state.administrativeArea.districts)
}

export const getDsOptions = (state: AppState) => {
  return setDsSelectOptions(state.administrativeArea.ds)
}

export const getGnOptions = (state: AppState) => {
  return setGnSelectOptions(state.administrativeArea.gn)
}

export const getProvinceByProvinceId = (id: number) => (state: AppState) => {
  const province = state.administrativeArea.provinces.filter((data) => {
    return data.id == id
  })[0]
  return province
}

export const getDistritByDistrictId = (id: number) => (state: AppState) => {
  const district = state.administrativeArea.districts.filter((data) => {
    return data.id == id
  })[0]
  return district
}

export const getDsByDsId = (id: number) => (state: AppState) => {
  const ds = state.administrativeArea.ds.filter((data) => {
    return data.id == id
  })[0]
  return ds
}

export const getGnByGnId = (id: number) => (state: AppState) => {
  const gn = state.administrativeArea.gn.filter((data) => {
    return data.id == id
  })[0]
  return gn
}
