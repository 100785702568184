import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import ReactTableWithPaginationComponent from '../../components/table/ReactTableWithPaginationComponent'
import { NotificationDetailsModel } from '../../models/common/notificationModel'
import myProfileService from '../../services/myProfile/myProfileService'
// import notification_img from '../../assets/images/notification_img.svg'
// src\assets\images
// import { useHistory } from 'react-router'
// import { useAuth } from '../../providers/AuthProvider'
// import emlem from '../../assets/images/login-emlem.png'
// import user from '../../assets/images/user.svg'
// import lock from '../../assets/images/lock.svg'
// import './LoginComponent.scss
// import { Link } from 'react-router-dom'
// import { showTotast } from '../../utils/toast'
// import {
//   ALERT_SUCCESS,
//   ALERT_WARNING,
//   TOAST_POSITION_TOP_RIGHT,
//   TOAST_TRANSITION_SLIDE,
// } from '../../utils/constants'
import myProfileDataService from '../../services/myProfile/myProfileService'
import { changeNotificationCountRequest } from '../../store/app/actionTypes'
import { fileRedirectUtil } from '../../utils/utilFunctions'
import GpcFileRegistration from '../applicationRegistration/compensation/gpc/GpcFileRegistration'
import GscFileRegistration from '../applicationRegistration/compensation/gsc/GscFileRegistration'
import GsiFileRegistration from '../applicationRegistration/compensation/gsi/GsiFileRegistration'
// import Gpc from '../applicationRegistration/compensation/gpc/Gpc'
// import Gsc from '../applicationRegistration/compensation/gsc/Gsc'
// import Gsi from '../applicationRegistration/compensation/gsi/Gsi'
// import Gsp from '../applicationRegistration/compensation/gsp/Gsp'
// import Pd from '../applicationRegistration/compensation/pd/Pd'
import PdFileRegistration from '../applicationRegistration/compensation/pd/PdFileRegistration'
import PiFileRegistration from '../applicationRegistration/compensation/pi/PiFileRegistration'
import RlpFileRegistration from '../applicationRegistration/compensation/rlp/RlpFileRegistration'
import AhFileRegistration from '../applicationRegistration/loan/ah/AhFileRegistration'
import SeFileRegistration from '../applicationRegistration/loan/se/SeFileRegistration'
import SrtFileRegistration from '../applicationRegistration/loan/srt/SrtFileRegistration'
// import Pi from '../applicationRegistration/compensation/pi/Pi'
// import Rlp from '../applicationRegistration/compensation/rlp/Rlp'
// import Ah from '../applicationRegistration/loan/ah/Ah'
// import Se from '../applicationRegistration/loan/se/Se'
// import Srt from '../applicationRegistration/loan/srt/Srt'
// D:\iterminal\workspace\orims_project\orims_front\orims\orims_front_end\src\services\myProfile\myProfileService.ts
// import { NotificationDetailsModel } from '../../models/common/notificationModel'

const AllNotification = () => {
  const [notficatiionList, setNotficatiionList] = useState([])
  const { path } = useRouteMatch()
  const history = useHistory()
  const dispatch = useDispatch()
  const columns = React.useMemo(
    () => [
      {
        Header: 'Sender',
        accessor: 'sender', // accessor is the "key" in the data
      },
      {
        Header: 'Description',
        accessor: 'messageData',
      },
      {
        Header: 'Send Date/Time',
        accessor: 'sendDateTime', // accessor is the "key" in the data
      },
      {
        Header: 'Type',
        accessor: 'type',
      },
    ],
    []
  )

  useEffect(() => {
    async function getNotficatiionList() {
      const response = await myProfileDataService.apiGetNotificationList()
      setNotficatiionList(response.data)
    }
    getNotficatiionList()
  }, [])

  // const handleView = (id: number, scheme: string) => {
  //   if (scheme === 'PD') {
  //     history.push(`${path}/pd/view/${id}`)
  //   } else if (scheme === 'PI') {
  //     history.push(`${path}/pi/view/${id}`)
  //   } else if (scheme === 'GSD') {
  //     history.push(`${path}/view/${id}`)
  //   } else if (scheme === 'GSI') {
  //     history.push(`${path}/gsi/view/${id}`)
  //   } else if (scheme === 'GPC') {
  //     history.push(`${path}/gpc/view/${id}`)
  //   } else if (scheme === 'GSC') {
  //     history.push(`${path}/gsc/view/${id}`)
  //   } else if (scheme === 'RLP') {
  //     history.push(`${path}/rlp/view/${id}`)
  //   } else if (scheme === 'SE') {
  //     history.push(`${path}/se/view/${id}`)
  //   } else if (scheme === 'AH') {
  //     history.push(`${path}/ah/view/${id}`)
  //   } else if (scheme === 'SRT') {
  //     history.push(`${path}/srt/view/${id}`)
  //   }
  // }

  const handleNotificationClick = async (
    notification: NotificationDetailsModel
  ) => {
    if (!notification.readMessage) {
      await myProfileService.apiReadNotification(notification.id)
      const notificationData = await myProfileService.apiGetNotificationCount()
      if (notificationData.status === 200) {
        dispatch(changeNotificationCountRequest(notificationData.data.count))
      }
    }
    const basePath = fileRedirectUtil(
      notification.applicationStatus,
      notification.programType,
      notification.scheme,
      notification.applicationFileId
    )
    if (basePath != '') {
      history.push(basePath)
    }
  }

  return (
    // <>
    <Switch>
      <Route path={`${path}/`} exact>
        <Row className="title-layout">
          <Col className="d-flex justify-content-start">
            <span className="m-2">Notification</span>
          </Col>
        </Row>
        <Row className="role-and-permission-body-layout mt-4">
          <Col sm={12}>
            <ReactTableWithPaginationComponent
              columns={columns}
              data={notficatiionList}
              onClickRow={(row: any) => {
                console.log(row)
                handleNotificationClick(row)
                // handleView(row.applicationFileId, row.scheme)
              }}
            />
          </Col>
        </Row>
      </Route>
      <Route
        path={`${path}/pd/view/:id`}
        render={(props) => <PdFileRegistration type="file" {...props} />}
      />
      <Route
        path={`${path}/pi/view/:id`}
        render={(props) => <PiFileRegistration type="file" {...props} />}
      />
      <Route
        path={`${path}/gsi/view/:id`}
        render={(props) => <GsiFileRegistration type="file" {...props} />}
      />
      <Route
        path={`${path}/gpc/view/:id`}
        render={(props) => <GpcFileRegistration type="file" {...props} />}
      />
      <Route
        path={`${path}/gsc/view/:id`}
        render={(props) => <GscFileRegistration type="file" {...props} />}
      />
      <Route
        path={`${path}/rlp/view/:id`}
        render={(props) => <RlpFileRegistration type="file" {...props} />}
      />
      <Route
        path={`${path}/ah/view/:id`}
        render={(props) => <AhFileRegistration type="create" {...props} />}
      />
      <Route
        path={`${path}/se/view/:id`}
        render={(props) => <SeFileRegistration type="create" {...props} />}
      />
      <Route
        path={`${path}/srt/view/:id`}
        render={(props) => <SrtFileRegistration type="create" {...props} />}
      />
    </Switch>
    // </>
  )
}

export default AllNotification
