import { FilterModel } from '../../../models/utils/filterModel'
import apiService from '../../api/apiManager'
class IncidentTypesServices {
  async apiFetchCompensationValuesList(filterData: FilterModel) {
    return apiService.apiPost(
      `/system-configuration/compensation-value/list`,
      filterData
    )
  }

  async apiFetchCompensationValuesCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/system-configuration/compensation-value/count`,
      filterData
    )
  }
  async apiGetAllCompensationValuesList() {
    return apiService.apiGet('/system-configuration/compensation-value/list')
  }
  async getSchemeListByProgramType(programType: string) {
    return apiService.apiGet(
      `/meta-data/program-type/${programType}/scheme/list`
    )
  }
  async apiGetIncidentTypeList() {
    return apiService.apiGet('/system-configuration/incident-type/list')
  }
  async getCompensationForListByScheme(scheme: string) {
    return apiService.apiGet(
      `/meta-data/scheme/${scheme}/compensation-for/list`
    )
  }
  async getPercentageAmongRelationshipList() {
    return apiService.apiGet(`/meta-data/percentage-among-relationship/list`)
  }
  async getDamageTypeList() {
    return apiService.apiGet(`/meta-data/damage-type/list`)
  }
  async apiAddCompensationValues(CompensationValue: any) {
    return apiService.apiPost(
      `/system-configuration/compensation-value/add`,
      CompensationValue
    )
  }
  async getCompensationValueHistory(id: number) {
    return apiService.apiGet(
      `/system-configuration/compensation-value/${id}/history`
    )
  }
  async getCompensationValueById(id: number) {
    return apiService.apiGet(
      `/system-configuration/compensation-value/view/${id}`
    )
  }
  async apiActiveCompensationValue(id: number) {
    return apiService.apiActiveInactive(
      `/system-configuration/compensation-value/${id}/active`
    )
  }
  async apiInactiveCompensationValue(id: number) {
    return apiService.apiActiveInactive(
      `/system-configuration/compensation-value/${id}/inactive`
    )
  }
  async apiUpdateCompensationValue(id: number | undefined, incidentType: any) {
    return apiService.apiPutCommon(
      `/system-configuration/compensation-value/modify/${id}`,
      incidentType
    )
  }
}
export default new IncidentTypesServices()
