/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable prefer-const */
import produce from 'immer'
import { createSelector } from 'reselect'
import { ActionListModal } from '../../models/package/actionListModal'
import { ModuleModal } from '../../models/package/moduleModal'
import { SubModuleModal } from '../../models/package/subModuleModal'
import { SubPackageModal } from '../../models/package/subPackageModal'
import { RouteModal } from '../../models/route/routeModal'
import { PackageModal } from '../../models/user/packageModal'
import { RoleListModal } from '../../models/user/roleListModel'
import { AppState } from '../rootReducer'

export const getPending = (state: AppState) => state.user.pending

export const getUser = (state: AppState) => state.user.user

export const getError = (state: AppState) => state.user.error

export const getUserPackages = createSelector(getUser, (user) => {
  if (user === null) {
    return []
  }
  const allPermissionRoleList = user.roleList.filter((role: RoleListModal) => {
    return role.role.allPermissions === true
  })
  if (allPermissionRoleList.length > 0) {
    const newReturnList = produce(
      allPermissionRoleList[0].role.permissions.packageList,
      (draft) => {
        for (let index = 0; index < draft.length; index++) {
          draft[index].subPackageList.sort((a, b) => a.sequence - b.sequence)
        }
      }
    )
    return newReturnList
  } else {
    const packageList: PackageModal[] = []

    for (let i = 0; i < user.roleList.length; i++) {
      const perimissionPackage = user.roleList[i]
      for (
        let j = 0;
        j < perimissionPackage.role.permissions.packageList.length;
        j++
      ) {
        const pack = perimissionPackage.role.permissions.packageList[j]
        let updatePackage = {} as PackageModal
        updatePackage.id = pack.id
        updatePackage.name = pack.name
        updatePackage.description = pack.description

        const updatePackageActionList: ActionListModal[] = []
        for (let k = 0; k < pack.actionList.length; k++) {
          const packageAction = pack.actionList[k]
          let updatePackageAction = {} as ActionListModal
          updatePackageAction.id = packageAction.id
          updatePackageAction.action = packageAction.action
          updatePackageAction.packageId = pack.id
          updatePackageActionList.push(updatePackageAction)
        }

        const updateSubPackageList: SubPackageModal[] = []
        for (let l = 0; l < pack.subPackageList.length; l++) {
          const subPackage = pack.subPackageList[l]
          let updateSubPackage = {} as SubPackageModal
          updateSubPackage.id = subPackage.id
          updateSubPackage.name = subPackage.name
          updateSubPackage.description = subPackage.description
          updateSubPackage.packageId = pack.id

          const updateSubPackageActionList: ActionListModal[] = []
          for (let m = 0; m < subPackage.actionList.length; m++) {
            const subPackageAction = subPackage.actionList[m]
            let updateSubPackageAction = {} as ActionListModal
            updateSubPackageAction.id = subPackageAction.id
            updateSubPackageAction.action = subPackageAction.action
            updateSubPackageAction.subPackageId = subPackage.id
            updateSubPackageActionList.push(subPackageAction)
          }

          const updateModuleList: ModuleModal[] = []
          for (let n = 0; n < subPackage.moduleList.length; n++) {
            const module = subPackage.moduleList[n]
            let updateModule = {} as ModuleModal
            updateModule.id = module.id
            updateModule.name = module.name
            updateModule.description = module.description
            updateModule.subPackageId = subPackage.id

            const updateModuleActionList: ActionListModal[] = []
            for (let o = 0; o < module.actionList.length; o++) {
              const moduleAction = module.actionList[o]
              let updateModuleAction = {} as ActionListModal
              updateModuleAction.id = moduleAction.id
              updateModuleAction.action = moduleAction.action
              updateModuleAction.moduleId = module.id
              updateModuleActionList.push(updateModuleAction)
            }

            const updateSubModuleList: SubModuleModal[] = []
            for (let p = 0; p < module.subModuleList.length; p++) {
              const subModule = module.subModuleList[p]
              let updateSubModule = {} as SubModuleModal
              updateSubModule.id = subModule.id
              updateSubModule.name = subModule.name
              updateSubModule.description = subModule.description
              updateSubModule.moduleId = module.id

              const updateSubModuleActionList: ActionListModal[] = []
              for (let q = 0; q < subModule.actionList.length; q++) {
                const subModuleAction = subModule.actionList[q]
                let updateSubModuleAction = {} as ActionListModal
                updateSubModuleAction.id = subModuleAction.id
                updateSubModuleAction.action = subModuleAction.action
                updateSubModuleAction.subModuleId = subModule.id
                updateSubModuleActionList.push(updateSubModuleAction)
              }
              updateSubModule.actionList = updateSubModuleActionList
              updateSubModuleList.push(updateSubModule)
            }

            updateModule.actionList = updateModuleActionList
            updateModule.subModuleList = updateSubModuleList
            updateModuleList.push(updateModule)
          }
          updateSubPackage.actionList = updateSubPackageActionList
          updateSubPackage.moduleList = updateModuleList
          updateSubPackageList.push(updateSubPackage)
        }
        updatePackage.actionList = updatePackageActionList
        updatePackage.subPackageList = updateSubPackageList
        packageList.push(updatePackage)
      }
    }

    const filterdPackageList: PackageModal[] = []
    const filterdPackageActionList: ActionListModal[] = []
    const filterdSubPackageList: SubPackageModal[] = []
    const filterdSubPackageActionList: ActionListModal[] = []
    const filterdModuleList: ModuleModal[] = []
    const filterdModuleActionList: ActionListModal[] = []
    const filterdSubModuleList: SubModuleModal[] = []
    const filterdSubModuleActionList: ActionListModal[] = []

    for (let i = 0; i < packageList.length; i++) {
      const pack = packageList[i]
      const checkPackageAlreadyIn = filterdPackageList.filter(
        (checkPackage) => {
          return checkPackage.id == pack.id
        }
      )
      if (checkPackageAlreadyIn.length === 0) {
        filterdPackageList.push(pack)
      }
      for (let j = 0; j < pack.actionList.length; j++) {
        const packAction = pack.actionList[j]
        const checkPackageActionAlreadyIn = filterdPackageActionList.filter(
          (checkPackageAction) => {
            return checkPackageAction.id === packAction.id
          }
        )
        if (checkPackageActionAlreadyIn.length === 0) {
          filterdPackageActionList.push(packAction)
        }
      }
      for (let k = 0; k < pack.subPackageList.length; k++) {
        const subPackage = pack.subPackageList[k]
        const checkSubPackageAlreadyIn = filterdSubPackageList.filter(
          (checkSubPackage) => {
            return checkSubPackage.id === subPackage.id
          }
        )
        if (checkSubPackageAlreadyIn.length === 0) {
          filterdSubPackageList.push(subPackage)
        }
        for (let l = 0; l < subPackage.actionList.length; l++) {
          const subPackageAction = subPackage.actionList[l]
          const checkSubPackageActionAlreadyIn =
            filterdSubPackageActionList.filter((checkSubPackageAction) => {
              return checkSubPackageAction.id === subPackageAction.id
            })
          if (checkSubPackageActionAlreadyIn.length === 0) {
            filterdSubPackageActionList.push(subPackageAction)
          }
        }
        for (let m = 0; m < subPackage.moduleList.length; m++) {
          const module = subPackage.moduleList[m]

          const checkModuleAlreadyIn = filterdModuleList.filter(
            (checkModule) => {
              return checkModule.id === module.id
            }
          )
          if (checkModuleAlreadyIn.length === 0) {
            filterdModuleList.push(module)
          }

          for (let n = 0; n < module.actionList.length; n++) {
            const moduleAction = module.actionList[n]
            const checkModuleActionAlreadyIn = filterdModuleActionList.filter(
              (checkModuleAction) => {
                return checkModuleAction.id === moduleAction.id
              }
            )
            if (checkModuleActionAlreadyIn.length === 0) {
              filterdModuleActionList.push(moduleAction)
            }
          }
          for (let o = 0; o < module.subModuleList.length; o++) {
            const subModule = module.subModuleList[o]
            const checkSubModuleAlreadyIn = filterdSubModuleList.filter(
              (checkSubModule) => {
                return checkSubModule.id === subModule.id
              }
            )
            if (checkSubModuleAlreadyIn.length === 0) {
              filterdSubModuleList.push(subModule)
            }
            for (let p = 0; p < subModule.actionList.length; p++) {
              const subModuleAction = subModule.actionList[p]
              const checkSubModuleActionAlreadyIn =
                filterdSubModuleActionList.filter((checkSubModuleAction) => {
                  return checkSubModuleAction.id === subModule.id
                })
              if (checkSubModuleActionAlreadyIn.length === 0) {
                filterdSubModuleActionList.push(subModuleAction)
              }
            }
          }
        }
      }
    }

    const finalList: PackageModal[] = []

    for (let i = 0; i < filterdPackageList.length; i++) {
      const pack = filterdPackageList[i]
      let packageObject = {} as PackageModal
      packageObject.name = pack.name
      packageObject.description = pack.description
      packageObject.id = pack.id
      packageObject.actionList = filterdPackageActionList.filter(
        (data) => data.packageId == pack.id
      )

      const subPackageList: SubPackageModal[] = []
      for (let j = 0; j < filterdSubPackageList.length; j++) {
        const subPackage = filterdSubPackageList[j]
        if (subPackage.packageId === pack.id) {
          let subPackageObject = {} as SubPackageModal
          subPackageObject.name = subPackage.name
          subPackageObject.description = subPackage.description
          subPackageObject.id = subPackage.id
          subPackageObject.actionList = filterdSubPackageActionList.filter(
            (data) => data.subPackageId == subPackage.id
          )

          const moduleList: ModuleModal[] = []
          for (let k = 0; k < filterdModuleList.length; k++) {
            const module = filterdModuleList[k]
            if (module.subPackageId == subPackage.id) {
              let moduleObject = {} as ModuleModal
              moduleObject.name = module.name
              moduleObject.description = module.description
              moduleObject.id = module.id
              moduleObject.actionList = filterdModuleActionList.filter(
                (data) => data.moduleId == module.id
              )

              const subModuleList: SubModuleModal[] = []
              for (let l = 0; l < filterdSubModuleList.length; l++) {
                const subModule = filterdSubModuleList[l]
                if (subModule.moduleId == module.id) {
                  let subModuleObject = {} as SubModuleModal
                  subModuleObject.name = subModule.name
                  subModuleObject.description = subModule.description
                  subModuleObject.id = subModule.id
                  subModuleObject.actionList =
                    filterdSubModuleActionList.filter(
                      (data) => data.subModuleId == subModule.id
                    )
                  subModuleList.push(subModuleObject)
                }
              }
              moduleObject.subModuleList = subModuleList
              moduleList.push(moduleObject)
            }
          }
          subPackageObject.moduleList = moduleList
          subPackageList.push(subPackageObject)
        }
      }
      packageObject.subPackageList = subPackageList
      finalList.push(packageObject)
    }

    const returnList = produce(finalList, (draft) => {
      draft.sort((a, b) => a.id - b.id)
      for (let index = 0; index < draft.length; index++) {
        draft[index].subPackageList.sort((a, b) => a.id - b.id)
        for (let i = 0; i < draft[index].subPackageList.length; i++) {
          draft[index].subPackageList[i].moduleList.sort((a, b) => a.id - b.id)

          for (
            let j = 0;
            j < draft[index].subPackageList[i].moduleList.length;
            j++
          ) {
            draft[index].subPackageList[i].moduleList[j].subModuleList.sort(
              (a, b) => a.id - b.id
            )
          }
        }
      }
    })
    return returnList
  }
})

export const getUserPackagesPrashan = createSelector(getUser, (user) => {
  if (user === null) {
    return []
  }
  const allPermissionRoleList = user.roleList.filter((role: RoleListModal) => {
    return role.role.allPermissions === true
  })
  if (allPermissionRoleList.length > 0) {
    const newReturnList = produce(
      allPermissionRoleList[0].role.permissions.packageList,
      (draft) => {
        for (let index = 0; index < draft.length; index++) {
          draft[index].subPackageList.sort((a, b) => a.sequence - b.sequence)
        }
      }
    )
    return newReturnList
  } else {
    const rolePermissionPackageList: PackageModal[] = []
    for (let i = 0; i < user.roleList.length; i++) {
      console.log(user.roleList)
      const roleList = user.roleList[i]
      for (let j = 0; j < roleList.role.permissions.packageList.length; j++) {
        const perimissionPackage = roleList.role.permissions.packageList[j]
        const checkPackageAlreadyIn = rolePermissionPackageList.filter(
          (checkPackage) => {
            return checkPackage.id === perimissionPackage.id
          }
        )
        if (checkPackageAlreadyIn.length === 0) {
          rolePermissionPackageList.push(perimissionPackage)
        } else {
          let updatedPackage = {} as PackageModal
          updatedPackage.description = checkPackageAlreadyIn[0].description
          updatedPackage.id = checkPackageAlreadyIn[0].id
          updatedPackage.actionList = []
          updatedPackage.name = checkPackageAlreadyIn[0].name
          updatedPackage.subPackageList = []

          for (
            let index = 0;
            index < checkPackageAlreadyIn[0].actionList.length;
            index++
          ) {
            updatedPackage.actionList.push(
              checkPackageAlreadyIn[0].actionList[index]
            )
          }

          for (
            let index = 0;
            index < checkPackageAlreadyIn[0].subPackageList.length;
            index++
          ) {
            updatedPackage.subPackageList.push(
              checkPackageAlreadyIn[0].subPackageList[index]
            )
          }

          for (let k = 0; k < perimissionPackage.actionList.length; k++) {
            const checkExistingPackageAction =
              updatedPackage.actionList.findIndex(
                (obj) => obj.id === perimissionPackage.actionList[k].id
              )
            if (checkExistingPackageAction < 0) {
              updatedPackage.actionList.push(perimissionPackage.actionList[k])
            }
          }

          for (let l = 0; l < perimissionPackage.subPackageList.length; l++) {
            const permissionSubPackage = perimissionPackage.subPackageList[l]
            const checkSubPackageAlreadyIn =
              updatedPackage.subPackageList.filter((checkSubPackage) => {
                return checkSubPackage.id === permissionSubPackage.id
              })

            if (checkSubPackageAlreadyIn.length === 0) {
              updatedPackage.subPackageList.push(permissionSubPackage)
            } else {
              let updatedSubPackage = {} as SubPackageModal
              updatedSubPackage.id = checkSubPackageAlreadyIn[0].id
              updatedSubPackage.description =
                checkSubPackageAlreadyIn[0].description
              updatedSubPackage.actionList = []
              updatedSubPackage.name = checkSubPackageAlreadyIn[0].name
              updatedSubPackage.moduleList = []
              updatedSubPackage.packageId =
                checkSubPackageAlreadyIn[0].packageId

              for (
                let index = 0;
                index < checkSubPackageAlreadyIn[0].actionList.length;
                index++
              ) {
                updatedSubPackage.actionList.push(
                  checkSubPackageAlreadyIn[0].actionList[index]
                )
              }

              for (
                let index = 0;
                index < checkSubPackageAlreadyIn[0].moduleList.length;
                index++
              ) {
                updatedSubPackage.moduleList.push(
                  checkSubPackageAlreadyIn[0].moduleList[index]
                )
              }

              for (let m = 0; m < permissionSubPackage.actionList.length; m++) {
                const checkExistingSubPackageAction =
                  updatedSubPackage.actionList.findIndex(
                    (obj) => obj.id === permissionSubPackage.actionList[m].id
                  )
                if (checkExistingSubPackageAction < 0) {
                  updatedSubPackage.actionList.push(
                    permissionSubPackage.actionList[m]
                  )
                }
              }

              for (let n = 0; n < permissionSubPackage.moduleList.length; n++) {
                const permissionModule = permissionSubPackage.moduleList[l]
                const checkModuleAlreadyIn =
                  updatedSubPackage.moduleList.filter((checkModule) => {
                    return checkModule.id === permissionModule.id
                  })
                if (checkModuleAlreadyIn.length === 0) {
                  updatedSubPackage.moduleList.push(permissionModule)
                } else {
                  let updatedModule = {} as ModuleModal
                  updatedModule.id = checkModuleAlreadyIn[0].id
                  updatedModule.actionList = []
                  updatedModule.description =
                    checkModuleAlreadyIn[0].description
                  updatedModule.name = checkModuleAlreadyIn[0].name
                  updatedModule.subModuleList = []
                  updatedModule.subPackageId =
                    checkModuleAlreadyIn[0].subPackageId

                  for (
                    let index = 0;
                    index < checkModuleAlreadyIn[0].actionList.length;
                    index++
                  ) {
                    updatedModule.actionList.push(
                      checkModuleAlreadyIn[0].actionList[index]
                    )
                  }

                  for (
                    let index = 0;
                    index < checkModuleAlreadyIn[0].subModuleList.length;
                    index++
                  ) {
                    updatedModule.subModuleList.push(
                      checkModuleAlreadyIn[0].subModuleList[index]
                    )
                  }

                  for (let o = 0; o < permissionModule.actionList.length; o++) {
                    const checkExistingModuleAction =
                      updatedModule.actionList.findIndex(
                        (obj) => obj.id === permissionModule.actionList[o].id
                      )
                    if (checkExistingModuleAction < 0) {
                      updatedModule.actionList.push(
                        permissionModule.actionList[o]
                      )
                    }
                  }

                  for (
                    let p = 0;
                    p < permissionModule.subModuleList.length;
                    p++
                  ) {
                    const permissionSubModule =
                      permissionModule.subModuleList[l]
                    const checkSubModuleAlreadyIn =
                      updatedModule.subModuleList.filter((checkSubModule) => {
                        return checkSubModule.id === permissionSubModule.id
                      })

                    if (checkSubModuleAlreadyIn.length === 0) {
                      updatedModule.subModuleList.push(permissionSubModule)
                    } else {
                      let updatedSubModule = {} as SubModuleModal
                      updatedSubModule.id = checkSubModuleAlreadyIn[0].id
                      updatedSubModule.actionList = []
                      updatedSubModule.description =
                        checkSubModuleAlreadyIn[0].description
                      updatedSubModule.name = checkSubModuleAlreadyIn[0].name
                      updatedSubModule.moduleId =
                        checkSubModuleAlreadyIn[0].moduleId

                      for (
                        let index = 0;
                        index < checkSubModuleAlreadyIn[0].actionList.length;
                        index++
                      ) {
                        updatedSubModule.actionList.push(
                          checkSubModuleAlreadyIn[0].actionList[index]
                        )
                      }

                      for (
                        let q = 0;
                        q < permissionSubModule.actionList.length;
                        q++
                      ) {
                        const checkExistingSubModuleAction =
                          updatedSubModule.actionList.findIndex(
                            (obj) =>
                              obj.id === permissionSubModule.actionList[q].id
                          )
                        if (checkExistingSubModuleAction < 0) {
                          updatedSubModule.actionList.push(
                            permissionSubModule.actionList[q]
                          )
                        }
                      }
                      const subModuleIndex =
                        updatedModule.subModuleList.findIndex(
                          (obj) => obj.id === permissionSubModule.id
                        )
                      if (subModuleIndex > -1) {
                        updatedModule.subModuleList[subModuleIndex] =
                          updatedSubModule
                      }

                      // updatedModule.subModuleList.sort((a, b) => a.id - b.id)
                    }

                    const moduleIndex = updatedSubPackage.moduleList.findIndex(
                      (obj) => obj.id === updatedModule.id
                    )
                    if (moduleIndex > -1) {
                      updatedSubPackage.moduleList[moduleIndex] = updatedModule
                    }

                    // updatedSubPackage.moduleList =
                    //   updatedSubPackage.moduleList.sort((a, b) => a.id - b.id)
                  }
                }
              }
              const subPackageIndex = updatedPackage.subPackageList.findIndex(
                (obj) => obj.id === updatedSubPackage.id
              )
              if (subPackageIndex > -1) {
                updatedPackage.subPackageList[subPackageIndex] =
                  updatedSubPackage
              }

              updatedPackage.subPackageList.sort((a, b) => a.id - b.id)
            }
          }

          const packageIndex = rolePermissionPackageList.findIndex(
            (obj) => obj.id === updatedPackage.id
          )
          if (packageIndex > -1) {
            rolePermissionPackageList[packageIndex] = updatedPackage
          }

          // rolePermissionPackageList.sort((a, b) => a.id - b.id)
        }
      }
    }

    const returnList = produce(rolePermissionPackageList, (draft) => {
      draft.sort((a, b) => a.id - b.id)
      for (let index = 0; index < draft.length; index++) {
        draft[index].subPackageList.sort((a, b) => a.sequence - b.sequence)
        for (let i = 0; i < draft[index].subPackageList.length; i++) {
          draft[index].subPackageList[i].moduleList.sort((a, b) => a.id - b.id)

          for (
            let j = 0;
            j < draft[index].subPackageList[i].moduleList.length;
            j++
          ) {
            draft[index].subPackageList[i].moduleList[j].subModuleList.sort(
              (a, b) => a.id - b.id
            )
          }
        }
      }
    })
    return returnList
  }
})

export const getUserSubPackages = (name: string) =>
  createSelector(getUserPackages, (packageList) => {
    let subPackageList: SubPackageModal[] = []
    if (packageList == undefined || packageList.length === 0) {
      return subPackageList
    }
    const selectedPackage = packageList.filter(
      (packageObject: PackageModal) => {
        return packageObject.name === name
      }
    )[0]
    if (selectedPackage === null || selectedPackage === undefined) {
      return subPackageList
    } else {
      subPackageList = selectedPackage.subPackageList
      return subPackageList
    }
  })

export const getUserModules = (packageName: string, subPackageName: string) =>
  createSelector(getUserSubPackages(packageName), (subPackageList) => {
    let moduleList: ModuleModal[] = []
    if (subPackageList == undefined || subPackageList.length === 0) {
      return moduleList
    }
    const selectedSubPackage = subPackageList.filter(
      (subPackageObject: SubPackageModal) => {
        return subPackageObject.name === subPackageName
      }
    )[0]
    if (selectedSubPackage === null || selectedSubPackage === undefined) {
      return moduleList
    } else {
      moduleList = selectedSubPackage.moduleList
      return moduleList
    }
  })

export const getUserSubModules = (
  packageName: string,
  subPackageName: string,
  moduleName: string
) =>
  createSelector(getUserModules(packageName, subPackageName), (moduleList) => {
    let subModuleList: SubModuleModal[] = []
    if (moduleList == undefined || moduleList.length === 0) {
      return subModuleList
    }
    const selectedModule = moduleList.filter((moduleObject: ModuleModal) => {
      return moduleObject.name === moduleName
    })[0]
    if (selectedModule === null || selectedModule === undefined) {
      return subModuleList
    } else {
      subModuleList = selectedModule.subModuleList
      return subModuleList
    }
  })

export const getUserPackageActions = (name: string) =>
  createSelector(getUserPackages, (packageList) => {
    let actionList: ActionListModal[] = []
    if (packageList == undefined || packageList.length === 0) {
      return actionList
    }
    const selectedPackage = packageList.filter(
      (packageObject: PackageModal) => {
        return packageObject.name === name
      }
    )[0]
    if (selectedPackage === null || selectedPackage === undefined) {
      return actionList
    } else {
      actionList = selectedPackage.actionList
      return actionList
    }
  })

export const getUserSubPackageActions = (
  packageName: string,
  subPackageName: string
) =>
  createSelector(getUserSubPackages(packageName), (subPackageList) => {
    let actionList: ActionListModal[] = []
    if (subPackageList == undefined || subPackageList.length === 0) {
      return actionList
    }
    const selectedSubPackage = subPackageList.filter(
      (subPackageObject: SubPackageModal) => {
        return subPackageObject.name === subPackageName
      }
    )[0]
    if (selectedSubPackage === null || selectedSubPackage === undefined) {
      return actionList
    } else {
      actionList = selectedSubPackage.actionList
      return actionList
    }
  })

export const getUserModuleActions = (
  packageName: string,
  subPackageName: string,
  moduleName: string
) =>
  createSelector(getUserModules(packageName, subPackageName), (moduleList) => {
    let actionList: ActionListModal[] = []
    if (moduleList == undefined || moduleList.length === 0) {
      return actionList
    }
    const selectedModule = moduleList.filter((moduleObject: ModuleModal) => {
      return moduleObject.name === moduleName
    })[0]
    if (selectedModule === null || selectedModule === undefined) {
      return actionList
    } else {
      actionList = selectedModule.actionList
      return actionList
    }
  })

export const getUserSubModuleActions = (
  packageName: string,
  subPackageName: string,
  moduleName: string,
  subModuleName: string
) =>
  createSelector(
    getUserSubModules(packageName, subPackageName, moduleName),
    (subModuleList) => {
      let actionList: ActionListModal[] = []
      if (subModuleList == undefined || subModuleList.length === 0) {
        return actionList
      }
      const selectedSubModule = subModuleList.filter(
        (subModuleObject: SubModuleModal) => {
          return subModuleObject.name === subModuleName
        }
      )[0]
      if (selectedSubModule === null || selectedSubModule === undefined) {
        return actionList
      } else {
        actionList = selectedSubModule.actionList
        return actionList
      }
    }
  )

export const getUserRoutes = createSelector(getUser, (user) => {
  const dashboardRouteList: RouteModal[] = []
  if (user.roleList === undefined) {
    return dashboardRouteList
  }
  const allPermissionRoleLisr = user.roleList.filter((role: RoleListModal) => {
    return role.role.allPermissions === true
  })
  console.log(allPermissionRoleLisr)
  if (allPermissionRoleLisr.length > 0) {
    for (
      let i = 0;
      i < allPermissionRoleLisr[0].role.permissions.packageList.length;
      i++
    ) {
      const routePackage =
        allPermissionRoleLisr[0].role.permissions.packageList[i]
      const routeObject: RouteModal = {
        id: routePackage.id,
        name: routePackage.description,
        routeURL: routePackage.name,
      }
      dashboardRouteList.push(routeObject)
    }
    return dashboardRouteList
  } else {
    const rolePermissionPackageList: PackageModal[] = []
    for (let i = 0; i < user.roleList.length; i++) {
      const roleList = user.roleList[i]
      for (let j = 0; j < roleList.role.permissions.packageList.length; j++) {
        const perimissionPackage = roleList.role.permissions.packageList[j]
        const checkPackageAlreadyIn = rolePermissionPackageList.filter(
          (checkPackage) => {
            return checkPackage.id === perimissionPackage.id
          }
        )
        if (checkPackageAlreadyIn.length === 0) {
          rolePermissionPackageList.push(perimissionPackage)
        }
      }
    }

    for (let i = 0; i < rolePermissionPackageList.length; i++) {
      const routePackage = rolePermissionPackageList[i]
      const routeObject: RouteModal = {
        id: routePackage.id,
        name: routePackage.description,
        routeURL: routePackage.name,
      }
      dashboardRouteList.push(routeObject)
    }
    return dashboardRouteList
  }
})
