// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-explicit-any */
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import metaDataService from '../../services/metaDataService'
import {
  provinceFetchrSuccess,
  provinceFetchFailure,
  districtFetchFailure,
  districtFetchSuccess,
  dsFetchrSuccess,
  dsFetchFailure,
  gnFetchrSuccess,
  gnFetchFailure,
} from './actions'
import {
  DISTRICT_FETCH_REQUEST,
  DS_FETCH_REQUEST,
  GN_FETCH_REQUEST,
  PROVINCE_FETCH_REQUEST,
} from './actionTypes'

/*
  Worker Saga: Fired on FETCH_USERS_REQUEST action
*/
function* fetchProvinceSagaWorker() {
  const { data, errorMessage } = yield call(metaDataService.apiProvincesFetch)
  if (data)
    yield put(
      provinceFetchrSuccess({
        provinces: data,
      })
    )
  else
    yield put(
      provinceFetchFailure({
        error: errorMessage,
      })
    )
}

/*
  Starts worker saga on latest dispatched `FETCH_USERS_REQUEST` action.
  Allows concurrent increments.
*/
function* fetchProvinceSaga() {
  yield all([takeLatest(PROVINCE_FETCH_REQUEST, fetchProvinceSagaWorker)])
}

/*
  Worker Saga: Fired on FETCH_TODO_REQUEST action
*/
function* fetchDistrictSagaWorker() {
  const { data, errorMessage } = yield call(metaDataService.apiDistrictsFetch)
  console.log('distric' + data)
  if (data) {
    yield put(
      districtFetchSuccess({
        districts: data,
      })
    )
  } else
    yield put(
      districtFetchFailure({
        error: errorMessage,
      })
    )
}

/*
  Starts worker saga on latest dispatched `CREATE_TODO_REQUEST` action.
  Allows concurrent increments.
*/
function* fetchDistrictSaga() {
  yield all([takeLatest(DISTRICT_FETCH_REQUEST, fetchDistrictSagaWorker)])
}

/*
  Worker Saga: Fired on FETCH_TODO_REQUEST action
*/
function* fetchDsagaWorker() {
  const { data, errorMessage } = yield call(metaDataService.apiDSDivisionsFetch)
  if (data) {
    yield put(
      dsFetchrSuccess({
        ds: data,
      })
    )
  } else
    yield put(
      dsFetchFailure({
        error: errorMessage,
      })
    )
}

/*
  Starts worker saga on latest dispatched `CREATE_TODO_REQUEST` action.
  Allows concurrent increments.
*/
function* fetchDsSaga() {
  yield all([takeLatest(DS_FETCH_REQUEST, fetchDsagaWorker)])
}

/*
  Worker Saga: Fired on FETCH_TODO_REQUEST action
*/
function* fetchGnagaWorker() {
  const { data, errorMessage } = yield call(metaDataService.apiGNDivisionsFetch)
  if (data) {
    yield put(
      gnFetchrSuccess({
        gn: data,
      })
    )
  } else
    yield put(
      gnFetchFailure({
        error: errorMessage,
      })
    )
}

/*
  Starts worker saga on latest dispatched `CREATE_TODO_REQUEST` action.
  Allows concurrent increments.
*/
function* fetchGnSaga() {
  yield all([takeLatest(GN_FETCH_REQUEST, fetchGnagaWorker)])
}

export default function* todoSaga() {
  yield all([
    fork(fetchProvinceSaga),
    fork(fetchDistrictSaga),
    fork(fetchDsSaga),
    fork(fetchGnSaga),
  ])
}
