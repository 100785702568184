import React, { useState, useEffect, useRef, FC } from 'react'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import { Row, Col, Form } from 'react-bootstrap'
import produce from 'immer'
import { useSelector, useDispatch } from 'react-redux'
import { dsSelectionModal } from '../../../models/applicationRegistration/fileRegistration/dsSelectionModel'
import { FileRegistrationErrorModel } from '../../../models/applicationRegistration/fileRegistration/fileRegistrationErrorModel'
import { FileRegistrationModel } from '../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { AllocateToTypeOption } from '../../../models/selectOptionModels/allocateOption'
import { DistrictOption } from '../../../models/selectOptionModels/districtOption'
import { DsOption } from '../../../models/selectOptionModels/dsOption'
import { GnOption } from '../../../models/selectOptionModels/gnOption'
import { IncidentTypeOption } from '../../../models/selectOptionModels/incidentTypeOption'
import { ProvinceOption } from '../../../models/selectOptionModels/provinceOption'
import { IncidentTypemodel } from '../../../models/systemConfiguration/incidentType/incidentTypemodel'
import metaDataService from '../../../services/metaDataService'
import {
  getProvincesOptions,
  geDistrictsOptions,
  getDsOptions,
  getGnOptions,
} from '../../../store/administrativeArea/selectors'
import { changeLoadingRequest } from '../../../store/app/actionTypes'

import {
  Schemeption,
  programeTypes,
  ProgramOption,
} from '../../../utils/metaData'
import {
  checkNicMandatory,
  handleNotification,
  getOldAndNewNicFromGivenNic,
  nicFormatFormat,
  calculateAge,
} from '../../../utils/utilFunctions'
import ConfirmationModal from '../../modals/ConfirmationModal'
import IdentificationTypesComponent from '../identificationComponent/IdentificationTypesComponent'
import NicTypeSelectionComponent from '../identificationComponent/NicTypeSelectionComponent'
import * as validation from '../../../pages/applicationRegistration/fileRegistration/fileRegistration/add/validations'
import applicationFilesDataService from '../../../services/applicationRegistration/applicationFiles'
import {
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../../utils/constants'
import { validationError } from '../../../utils/errorMessages'
import { showTotast } from '../../../utils/toast'

interface ConfirmationModal {
  isShow: boolean
  message: string
  section: string
  isRemark: boolean
}

export interface Props {
  information: FileRegistrationModel
  onCancel: any
  onSuccess: any
}

const ModifyBasicInformation: FC<Props> = ({
  information,
  onCancel,
  onSuccess,
}) => {
  const [fileRegistrationObject, setFileRegistrationObject] = useState(
    {} as FileRegistrationModel
  )
  const inputForm = useRef(null)
  const [showConfirm, setShowConfirm] = useState({
    isShow: false,
    isRemark: false,
  } as ConfirmationModal)
  const provinceList = useSelector(getProvincesOptions)
  const districtList = useSelector(geDistrictsOptions)
  const dsList = useSelector(getDsOptions)
  const gnList = useSelector(getGnOptions)
  const [incidentList, setIncidentList] = useState([] as IncidentTypeOption[])
  const [incidentDataList, setIncidentDataList] = useState(
    [] as IncidentTypemodel[]
  )

  const [selectedBirthCertificateDs, setSelectedBirthCertificateDs] = useState(
    {} as dsSelectionModal
  )
  const [selectedDeathCertificateDs, setSelectedDeathCertificateDs] = useState(
    {} as dsSelectionModal
  )
  const [selectedAgBirthCertificateDs, setSelectedAgBirthCertificateDs] =
    useState({} as dsSelectionModal)
  const [selectedInstrumentDs, setSelectedInstrumentDs] = useState(
    {} as dsSelectionModal
  )
  const [schemesList, setSchemesList] = useState([] as Schemeption[])
  const [errors, setErrors] = useState({} as FileRegistrationErrorModel)
  const dispatch = useDispatch()
  useEffect(() => {
    setFileRegistrationObject(information)

    getSchemeListByProgrammeType(information.programType)

    if (information.deathCertificateDsDivision) {
      const newValue = produce(selectedDeathCertificateDs, (draft) => {
        draft.dsOption = dsList.filter(
          (ds) => ds.value === information.deathCertificateDsDivision.id
        )[0]
        draft.districtOption = districtList.filter(
          (ds) => ds.value === information.deathCertificateDsDivision.districtId
        )[0]
        draft.provinceOption = provinceList.filter(
          (ds) => ds.value === information.deathCertificateDsDivision.provinceId
        )[0]
        draft.districtId = information.deathCertificateDsDivision.districtId
        draft.provinceId = information.deathCertificateDsDivision.provinceId
        draft.dsId = information.deathCertificateDsDivision.id
      })
      setSelectedDeathCertificateDs(newValue)
    }

    if (information.birthCertificateDsDivision) {
      const newValue = produce(selectedBirthCertificateDs, (draft) => {
        draft.dsOption = dsList.filter(
          (ds) => ds.value === information.birthCertificateDsDivision.id
        )[0]
        draft.districtOption = districtList.filter(
          (ds) => ds.value === information.birthCertificateDsDivision.districtId
        )[0]
        draft.provinceOption = provinceList.filter(
          (ds) => ds.value === information.birthCertificateDsDivision.provinceId
        )[0]
        draft.districtId = information.birthCertificateDsDivision.districtId
        draft.provinceId = information.birthCertificateDsDivision.provinceId
        draft.dsId = information.birthCertificateDsDivision.id
      })
      setSelectedBirthCertificateDs(newValue)
    }

    if (information.instrumentDsDivision) {
      const newValue = produce(selectedInstrumentDs, (draft) => {
        draft.dsOption = dsList.filter(
          (ds) => ds.value === information.instrumentDsDivision.id
        )[0]
        draft.districtOption = districtList.filter(
          (ds) => ds.value === information.instrumentDsDivision.districtId
        )[0]
        draft.provinceOption = provinceList.filter(
          (ds) => ds.value === information.instrumentDsDivision.provinceId
        )[0]
        draft.districtId = information.instrumentDsDivision.districtId
        draft.provinceId = information.instrumentDsDivision.provinceId
        draft.dsId = information.instrumentDsDivision.id
      })
      setSelectedInstrumentDs(newValue)
    }

    if (information.aggrievedPersonBirthCertificateDsDivisionId != null) {
      const agDsOption: DsOption = dsList.filter(
        (ds) =>
          ds.value === information.aggrievedPersonBirthCertificateDsDivisionId
      )[0]
      if (agDsOption) {
        const agDistrictOption: DistrictOption = districtList.filter(
          (dis) => dis.value === agDsOption.districtId
        )[0]
        if (agDistrictOption) {
          const agProvinceOption: ProvinceOption = provinceList.filter(
            (pro) => pro.value === agDistrictOption.provinceId
          )[0]

          const newValue = produce(selectedAgBirthCertificateDs, (draft) => {
            draft.dsOption = agDsOption
            draft.districtOption = agDistrictOption
            draft.provinceOption = agProvinceOption
            draft.districtId = agDistrictOption.value
            draft.provinceId = agProvinceOption.value
          })
          setSelectedAgBirthCertificateDs(newValue)
        }
      }
    }

    async function getMetaData() {
      console.log(gnList)
      const incidentTypeData = await metaDataService.apiIncidentTypeList()
      const incidentTypeTempList: IncidentTypeOption[] = []
      for (let i = 0; i < incidentTypeData.data.length; i++) {
        const incident = {} as IncidentTypeOption
        incident.value = incidentTypeData.data[i].id
        incident.label = incidentTypeData.data[i].incidentType
        incidentTypeTempList.push(incident)
      }
      setIncidentList(incidentTypeTempList)
      setIncidentDataList(incidentTypeData.data)
    }

    getMetaData()
  }, [])

  const getSchemeListByProgrammeType = async (programeType: string) => {
    const selectedProgrameType =
      programeType === 'COMPENSATION' ? 'Compensation' : 'Loan'
    const response = await metaDataService.getSchemeListByProgramTypeAndStatus(
      selectedProgrameType,
      'Active'
    )
    if (response.status === 200) {
      const schemeList = [] as Schemeption[]
      for (let index = 0; index < response.data.length; index++) {
        const element = {} as Schemeption
        element.value = response.data[index].code
        element.label = response.data[index].description
        element.programeType = programeType
        schemeList.push(element)
      }
      setSchemesList(schemeList)
    }
  }

  const handleConfirm = () => {
    if (showConfirm.section === 'cancel') {
      onCancel()
    } else if (showConfirm.section === 'save') {
      handleProceed()
    }

    setShowConfirm({ isShow: false, message: '', section: '', isRemark: false })
  }

  const customStyles = {
    control: (base: any, state: { isFocused: any }) => ({
      ...base,
      // state.isFocused can display different borderColor if you need it
      borderColor: state.isFocused ? '#ddd' : 'red',
      // overwrittes hover style
      '&:hover': {
        borderColor: state.isFocused ? '#ddd' : 'red',
      },
    }),
  }
  const findFormErrors = () => {
    const newErrors = {} as FileRegistrationErrorModel

    if (
      validation.validateReferenceNo(fileRegistrationObject.referenceNumber)
    ) {
      newErrors.referenceNumberError = validation.validateReferenceNo(
        fileRegistrationObject.referenceNumber
      )
    }

    if (validation.validateProgramType(fileRegistrationObject.programType)) {
      newErrors.programTypeError = validation.validateProgramType(
        fileRegistrationObject.programType
      )
    }
    if (validation.validateScheme(fileRegistrationObject.scheme)) {
      newErrors.schemeError = validation.validateScheme(
        fileRegistrationObject.scheme
      )
    }
    if (
      validation.validateApplicantName(fileRegistrationObject.applicantName)
    ) {
      newErrors.applicantNameError = validation.validateApplicantName(
        fileRegistrationObject.applicantName
      )
    }

    if (validation.validateDateofBirth(fileRegistrationObject.dateOfBirth)) {
      newErrors.dateOfBirthError = validation.validateDateofBirth(
        fileRegistrationObject.dateOfBirth
      )
    }

    // if (validation.validateAge(fileRegistrationObject.age)) {
    //   newErrors.ageError = validation.validateAge(fileRegistrationObject.age)
    // }

    if (
      validation.validateIdentificationType(
        fileRegistrationObject.identificationType
      )
    ) {
      newErrors.identificationTypeError = validation.validateIdentificationType(
        fileRegistrationObject.identificationType
      )
    }

    if (checkNicMandatory(fileRegistrationObject.identificationType)) {
      if (
        validation.validateNICnumber(
          fileRegistrationObject.newNicNumber,
          fileRegistrationObject.oldNicNumber
        )
      ) {
        newErrors.nicError = validation.validateNICnumber(
          fileRegistrationObject.newNicNumber,
          fileRegistrationObject.oldNicNumber
        )
      }
    }

    if (fileRegistrationObject.identificationType === 'PASSPORT') {
      if (validation.validatePassport(fileRegistrationObject.passportNumber)) {
        newErrors.passportNumberError = validation.validatePassport(
          fileRegistrationObject.passportNumber
        )
      }
    }
    if (fileRegistrationObject.identificationType === 'SENIOR_CITIZEN_ID') {
      if (
        validation.validatSeniorCitizenId(
          fileRegistrationObject.seniorCitizenId
        )
      ) {
        newErrors.seniorCitizenIdError = validation.validatSeniorCitizenId(
          fileRegistrationObject.seniorCitizenId
        )
      }
    }
    if (fileRegistrationObject.identificationType === 'DRIVING_LICENSE') {
      if (
        validation.validateDrivingLicense(
          fileRegistrationObject.drivingLicenseNumber
        )
      ) {
        newErrors.drivingLicenseNumberError = validation.validateDrivingLicense(
          fileRegistrationObject.drivingLicenseNumber
        )
      }
    }
    if (fileRegistrationObject.identificationType === 'TIC') {
      if (validation.validateTic(fileRegistrationObject.ticNumber)) {
        newErrors.ticNumberError = validation.validateTic(
          fileRegistrationObject.ticNumber
        )
      }
    }

    if (fileRegistrationObject.identificationType === 'BIRTH_CERTIFICATE') {
      if (
        validation.validateBCNumber(
          fileRegistrationObject.birthCertificateNumber
        )
      ) {
        newErrors.birthCertificateNumberError = validation.validateBCNumber(
          fileRegistrationObject.birthCertificateNumber
        )
      }

      if (validation.validateProvince(selectedBirthCertificateDs.provinceId)) {
        newErrors.bcNumberprovinceError = validation.validateProvince(
          selectedBirthCertificateDs.provinceIds
        )
      }

      if (validation.validateDistrict(selectedBirthCertificateDs.districtId)) {
        newErrors.bcNunberdistrictError = validation.validateDistrict(
          selectedBirthCertificateDs.districtId
        )
      }

      if (validation.validateDsDivision(selectedBirthCertificateDs.dsId)) {
        newErrors.birthCertificateDsDivisionError =
          validation.validateDsDivision(selectedBirthCertificateDs.dsId)
      }
    }
    if (
      fileRegistrationObject.scheme === 'PD' ||
      fileRegistrationObject.scheme === 'GSD'
    ) {
      if (
        validation.validateDCNumber(
          fileRegistrationObject.deathCertificateNumber
        )
      ) {
        newErrors.deathCertificateNumberError = validation.validateDCNumber(
          fileRegistrationObject.deathCertificateNumber
        )
      }
    }

    if (
      fileRegistrationObject.scheme === 'PD' ||
      fileRegistrationObject.scheme === 'GSD'
    ) {
      if (
        validation.validateDCNumberProvince(
          selectedDeathCertificateDs.provinceOption?.value
        )
      ) {
        newErrors.deathCertificateProvinceError =
          validation.validateDCNumberProvince(
            selectedDeathCertificateDs.provinceOption?.value
          )
      }
      if (selectedDeathCertificateDs.provinceOption?.value) {
        if (
          validation.validateDCNumberDistrict(
            selectedDeathCertificateDs.districtOption?.value
          )
        ) {
          newErrors.deathCertificateDistrictError =
            validation.validateDCNumberDistrict(
              selectedDeathCertificateDs.districtOption?.value
            )
        }
      }
      if (selectedDeathCertificateDs.districtOption?.value) {
        if (
          validation.validateDCNumberDsDivision(
            selectedDeathCertificateDs.dsOption?.value
          )
        ) {
          newErrors.deathCertificateDsDivisionError =
            validation.validateDCNumberDsDivision(
              selectedDeathCertificateDs.dsOption?.value
            )
        }
      }
    }

    if (
      fileRegistrationObject.scheme === 'PI' ||
      fileRegistrationObject.scheme === 'GSI'
    ) {
      if (
        validation.validateAggrievedPersonsAge(
          fileRegistrationObject.aggrievedPersonAge,
          fileRegistrationObject.scheme
        )
      ) {
        newErrors.aggrievedPersonAgeError =
          validation.validateAggrievedPersonsAge(
            fileRegistrationObject.aggrievedPersonAge,
            fileRegistrationObject.scheme
          )
      }
      if (
        fileRegistrationObject.aggrievedPersonAge > 16 &&
        fileRegistrationObject.scheme === 'PI'
      ) {
        if (
          validation.validateAggNICnumber(
            fileRegistrationObject.aggrievedPersonNewNicNumber,
            fileRegistrationObject.aggrievedPersonOldNicNumber
          )
        ) {
          newErrors.aggrievedPersonNicError = validation.validateAggNICnumber(
            fileRegistrationObject.aggrievedPersonNewNicNumber,
            fileRegistrationObject.aggrievedPersonOldNicNumber
          )
        }
      }
      if (
        fileRegistrationObject.aggrievedPersonAge < 16 &&
        fileRegistrationObject.scheme === 'PI'
      ) {
        if (
          validation.validateAggBCNumber(
            fileRegistrationObject.aggrievedPersonBirthCertificateNumber
          )
        ) {
          newErrors.aggrievedPersonBirthCertificateNumberError =
            validation.validateAggBCNumber(
              fileRegistrationObject.aggrievedPersonBirthCertificateNumber
            )
        }
      }
      if (fileRegistrationObject.scheme === 'GSI') {
        if (
          validation.validateAggNICnumber(
            fileRegistrationObject.aggrievedPersonNewNicNumber,
            fileRegistrationObject.aggrievedPersonOldNicNumber
          )
        ) {
          newErrors.aggrievedPersonNicError = validation.validateAggNICnumber(
            fileRegistrationObject.aggrievedPersonNewNicNumber,
            fileRegistrationObject.aggrievedPersonOldNicNumber
          )
        }
      }
      if (fileRegistrationObject.aggrievedPersonBirthCertificateNumber) {
        if (
          validation.validateAggBCProvince(
            selectedAgBirthCertificateDs.provinceOption?.value
          )
        ) {
          newErrors.aggrievedPersonBCProvinceError =
            validation.validateAggBCProvince(
              selectedAgBirthCertificateDs.provinceOption?.value
            )
        }
        if (selectedAgBirthCertificateDs.provinceOption?.value) {
          if (
            validation.validateAggBCDistrict(
              selectedAgBirthCertificateDs.districtOption?.value
            )
          ) {
            newErrors.aggrievedPersonBCDistrictError =
              validation.validateAggBCDistrict(
                selectedAgBirthCertificateDs.districtOption?.value
              )
          }
        }
        if (selectedAgBirthCertificateDs.districtOption?.value) {
          if (
            validation.validateAggBCDsDivision(
              selectedAgBirthCertificateDs.dsOption?.value
            )
          ) {
            newErrors.aggrievedPersoneBCDsDivisionError =
              validation.validateAggBCDsDivision(
                selectedAgBirthCertificateDs.dsOption?.value
              )
          }
        }
      }
    }
    if (
      fileRegistrationObject.scheme === 'GPC' ||
      fileRegistrationObject.scheme === 'GSC'
    ) {
      if (fileRegistrationObject.assetIdentificationNumber) {
        if (
          validation.validateAssetIdentificationNumber(
            fileRegistrationObject.assetIdentificationNumber
          )
        ) {
          newErrors.assetIdentificationNumberError =
            validation.validateAssetIdentificationNumber(
              fileRegistrationObject.assetIdentificationNumber
            )
        }
      }
      if (
        validation.validateInstrumentProvince(
          selectedInstrumentDs.provinceOption?.value
        )
      ) {
        newErrors.instrumentProvinceError =
          validation.validateInstrumentProvince(
            selectedInstrumentDs.provinceOption?.value
          )
      }
      if (selectedInstrumentDs.provinceOption?.value) {
        if (
          validation.validateInstrumentDistrict(
            selectedInstrumentDs.districtOption?.value
          )
        ) {
          newErrors.instrumentDistrictError =
            validation.validateInstrumentDistrict(
              selectedInstrumentDs.districtOption?.value
            )
        }
      }
      if (selectedInstrumentDs.districtOption?.value) {
        if (
          validation.validateInstrumentDsDivision(
            selectedInstrumentDs.dsOption?.value
          )
        ) {
          newErrors.instrumentDsDivisionError =
            validation.validateInstrumentDsDivision(
              selectedInstrumentDs.dsOption?.value
            )
        }
      }
    }
    if (fileRegistrationObject.scheme === 'RLP') {
      if (
        validation.validateReligiousPlaceRegNumber(
          fileRegistrationObject.religiousPlaceRegNumber
        )
      ) {
        newErrors.religiousPlaceRegstringNoError =
          validation.validateReligiousPlaceRegNumber(
            fileRegistrationObject.religiousPlaceRegNumber
          )
      }
    }
    return newErrors
  }

  const onClickSave = () => {
    const foundErrros = findFormErrors()

    if (Object.keys(foundErrros).length > 0) {
      showTotast(
        ALERT_WARNING,
        validationError,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setErrors(foundErrros)
    } else {
      const message = 'Do you wish to modify basic information ?'
      const section = 'save'
      setShowConfirm({
        isShow: true,
        message: message,
        section: section,
        isRemark: false,
      })
    }
  }

  const handleProceed = async () => {
    dispatch(changeLoadingRequest())

    const searchObject = produce(fileRegistrationObject, (draft) => {
      draft.birthCertificateDsDivisionId =
        selectedBirthCertificateDs.dsOption?.value
      draft.deathCertificateDsDivisionId =
        selectedDeathCertificateDs.dsOption?.value
      draft.instrumentDsDivisionId = selectedInstrumentDs.dsOption?.value
      draft.aggrievedPersonBirthCertificateDsDivisionId =
        selectedAgBirthCertificateDs.dsOption?.value
      draft.incidentTypeValue = draft.incidentType?.incidentType
    })
    setFileRegistrationObject(searchObject)

    const result = await applicationFilesDataService.apiUpdatBasicInformation(
      fileRegistrationObject.id,
      searchObject
    )
    dispatch(changeLoadingRequest())

    handleNotification(result, 'The application has been modified successfully')

    if (result.status === 200) {
      setFileRegistrationObject(result.data)
      onSuccess()
      onCancel()
      // window.location.reload()
    }
  }

  const renderIdentificationTypeName = (typeName: string) => {
    switch (typeName) {
      case 'TIC': {
        return 'Tic'
        break
      }
      case 'PASSPORT': {
        return 'Passport'
        break
      }
      case 'SENIOR_CITIZEN_ID': {
        return 'Senior Citizen ID'
        break
      }
      default: {
        return 'Driving License'
        break
      }
    }
  }

  const renderIdentificationTypeValue = (typeName: string) => {
    switch (typeName) {
      case 'TIC': {
        return fileRegistrationObject.ticNumber
        break
      }
      case 'PASSPORT': {
        return fileRegistrationObject.passportNumber
        break
      }
      case 'SENIOR_CITIZEN_ID': {
        return fileRegistrationObject.seniorCitizenId
        break
      }
      default: {
        return fileRegistrationObject.drivingLicenseNumber
        break
      }
    }
  }

  return (
    <>
      <Form ref={inputForm} id="file-registration-form">
        <Col sm={12} className="border-label mb-4">
          <div className="border-label-span">Application Details</div>

          <Form.Group className="mb-3" controlId="formReference">
            <Form.Label>Reference No.</Form.Label>
            <Form.Control
              isInvalid={!!errors.referenceNumberError}
              disabled={false}
              type="text"
              placeholder="Reference No."
              value={fileRegistrationObject.referenceNumber}
              onChange={(e) => {
                const newFileRegistrationObject = produce(
                  fileRegistrationObject,
                  (draft: FileRegistrationModel) => {
                    draft.referenceNumber = e.target.value
                  }
                )
                setFileRegistrationObject(newFileRegistrationObject)
                const clearError = produce(errors, (draft) => {
                  draft.referenceNumberError = ''
                })
                setErrors(clearError)
              }}
            />
            <Form.Control.Feedback type="invalid">
              {errors.referenceNumberError}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formStatus">
            <Form.Label>Status</Form.Label>
            <Form.Control
              disabled={true}
              type="text"
              placeholder="Status"
              value={fileRegistrationObject.status}
            />
          </Form.Group>
        </Col>
        <Col sm={12} className="border-label">
          <div className="border-label-span">Application Type Details</div>
          <Form.Group className="mb-3" controlId="formProgramType">
            <Form.Label>
              Program Type<span className="required-field-astrix">*</span>
            </Form.Label>
            <Select
              isDisabled={true}
              styles={errors.programTypeError ? customStyles : undefined}
              value={programeTypes.filter(
                (protype) =>
                  protype.value === fileRegistrationObject.programType
              )}
              options={programeTypes}
              onChange={(selectedOption) => {
                const newFileRegistrationObject = produce(
                  fileRegistrationObject,
                  (draft: FileRegistrationModel) => {
                    draft.programType = selectedOption
                      ? selectedOption.value
                      : ''
                    draft.programTypeOption = selectedOption
                      ? selectedOption
                      : ({} as ProgramOption)
                    draft.schemeOption = null
                    draft.scheme = ''
                  }
                )
                getSchemeListByProgrammeType(selectedOption.value)
                setFileRegistrationObject(newFileRegistrationObject)
                const clearError = produce(errors, (draft) => {
                  draft.programTypeError = ''
                })
                setErrors(clearError)
              }}
            />
            <span className="feedback-error-span-custom">
              {errors.programTypeError}
            </span>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formSchema">
            <Form.Label>
              Scheme<span className="required-field-astrix">*</span>
            </Form.Label>
            <Select
              isDisabled={true}
              styles={errors.schemeError ? customStyles : undefined}
              value={schemesList.filter(
                (scheme) => scheme.value === fileRegistrationObject.scheme
              )}
              options={schemesList.filter(
                (scheme) =>
                  scheme.programeType === fileRegistrationObject.programType
              )}
              onChange={(selectedOption) => {
                const newFileRegistrationObject = produce(
                  fileRegistrationObject,
                  (draft: FileRegistrationModel) => {
                    draft.scheme = selectedOption ? selectedOption.value : ''
                    draft.schemeOption = selectedOption
                  }
                )
                setFileRegistrationObject(newFileRegistrationObject)
                const clearError = produce(errors, (draft) => {
                  draft.schemeError = ''
                  draft.deathCertificateNumberError = ''
                  draft.deathCertificateProvinceError = ''
                  draft.deathCertificateDistrictError = ''
                  draft.deathCertificateDsDivisionError = ''
                  draft.aggrievedPersonNicError = ''
                  draft.aggrievedPersonBirthCertificateNumberError = ''
                  draft.aggrievedPersonBCProvinceError = ''
                  draft.aggrievedPersonBCDistrictError = ''
                  draft.aggrievedPersoneBCDsDivisionError = ''
                  draft.assetIdentificationNumberError = ''
                  draft.instrumentProvinceError = ''
                  draft.instrumentDistrictError = ''
                  draft.instrumentDsDivisionError = ''
                  draft.religiousPlaceRegstringNoError = ''
                })
                setErrors(clearError)
              }}
            />
            <span className="feedback-error-span-custom">
              {errors.schemeError}
            </span>
          </Form.Group>

          {fileRegistrationObject.scheme == 'MP' && (
            <Form.Group className="mb-3" controlId="formSchema">
              <Form.Label>OMP Number</Form.Label>
              <Form.Control
                maxLength={50}
                type="text"
                placeholder="Enter OMP Number"
                onChange={(e) => {
                  const newFileRegistrationObject = produce(
                    fileRegistrationObject,
                    (draft: FileRegistrationModel) => {
                      draft.ompNumber = e.target.value
                    }
                  )
                  setFileRegistrationObject(newFileRegistrationObject)
                }}
                value={fileRegistrationObject.ompNumber}
              />
            </Form.Group>
          )}
          {fileRegistrationObject.programType == 'COMPENSATION' && (
            <>
              <Form.Group className="mb-3" controlId="formIncidantType">
                <Form.Label>
                  Incident Type
                  <span className="required-field-astrix">*</span>
                </Form.Label>
                <Select
                  value={incidentList.filter((type: any) => {
                    if (type.value === fileRegistrationObject.incidentTypeId) {
                      return type
                    }
                  })}
                  styles={errors.incidentTypeIdError ? customStyles : undefined}
                  options={incidentList}
                  onChange={(selectedOption) => {
                    console.log(selectedOption)
                    const newFileRegistrationObject = produce(
                      fileRegistrationObject,
                      (draft: FileRegistrationModel) => {
                        draft.incidentTypeId = selectedOption
                          ? selectedOption.value
                          : 0
                        draft.incidentType = incidentDataList.filter(
                          (incident: IncidentTypemodel) => {
                            if (incident.id === selectedOption.value) {
                              return incident
                            }
                          }
                        )[0]
                      }
                    )
                    setFileRegistrationObject(newFileRegistrationObject)
                    const clearError = produce(errors, (draft) => {
                      draft.incidentTypeIdError = ''
                    })
                    setErrors(clearError)
                  }}
                />
                <span className="feedback-error-span-custom">
                  {errors.incidentTypeIdError}
                </span>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formIncidantDate">
                <Form.Label>
                  Incident Date
                  <span className="required-field-astrix">*</span>
                </Form.Label>
                <DatePicker
                  disabled={fileRegistrationObject.incidentTypeId == null}
                  minDate={
                    new Date(
                      fileRegistrationObject.incidentType?.effectiveDate * 1000
                    )
                  }
                  maxDate={new Date()}
                  className={
                    errors.incidentDateError
                      ? 'date-picker-validate'
                      : 'form-control'
                  }
                  placeholderText="Enter Incident Date"
                  selected={
                    fileRegistrationObject.incidentDate != null
                      ? new Date(fileRegistrationObject.incidentDate * 1000)
                      : null
                  }
                  onChange={(date: Date) => {
                    if (date != null) {
                      const dateOfBirth =
                        fileRegistrationObject.dateOfBirth != null
                          ? new Date(fileRegistrationObject.dateOfBirth * 1000)
                          : new Date()

                      const age = calculateAge(dateOfBirth, date)

                      const newFileRegistrationObject = produce(
                        fileRegistrationObject,
                        (draft: FileRegistrationModel) => {
                          draft.incidentDate = date.getTime() / 1000
                          draft.age = age.years
                          draft.ageInMonths = age.months
                          draft.ageInDays = age.days
                        }
                      )
                      setFileRegistrationObject(newFileRegistrationObject)
                    }
                  }}
                  onSelect={(date: any) => {
                    const dateOfBirth =
                      fileRegistrationObject.dateOfBirth != null
                        ? new Date(fileRegistrationObject.dateOfBirth * 1000)
                        : new Date()

                    const age = calculateAge(dateOfBirth, date)

                    const newFileRegistrationObject = produce(
                      fileRegistrationObject,
                      (draft: FileRegistrationModel) => {
                        draft.incidentDate = date.getTime() / 1000
                        draft.aggrievedPersonAge
                        draft.age = age.years
                        draft.ageInMonths = age.months
                        draft.ageInDays = age.days
                      }
                    )
                    setFileRegistrationObject(newFileRegistrationObject)
                  }}
                />
                <span className="feedback-error-span-custom">
                  {errors.incidentDateError}
                </span>
              </Form.Group>
            </>
          )}
        </Col>
        <Col sm={12} className="border-label mt-4">
          <div className="border-label-span">Applicant Details</div>
          <Form.Group className="mb-3" controlId="formApplicantName">
            <Form.Label>
              Applicant Name
              <span className="required-field-astrix">*</span>
            </Form.Label>
            <Form.Control
              isInvalid={!!errors.applicantNameError}
              maxLength={50}
              type="text"
              placeholder="Enter Applicant Name"
              onChange={(e) => {
                const newFileRegistrationObject = produce(
                  fileRegistrationObject,
                  (draft: FileRegistrationModel) => {
                    draft.applicantName = e.target.value
                  }
                )
                setFileRegistrationObject(newFileRegistrationObject)
                const clearError = produce(errors, (draft) => {
                  draft.applicantNameError = ''
                })
                setErrors(clearError)
              }}
              value={fileRegistrationObject.applicantName}
            />
            <Form.Control.Feedback type="invalid">
              {errors.applicantNameError}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formSchema">
            <Form.Label>Contact Number</Form.Label>
            <Form.Control
              maxLength={15}
              type="text"
              placeholder="Enter Contact Number"
              onChange={(e) => {
                const newFileRegistrationObject = produce(
                  fileRegistrationObject,
                  (draft: FileRegistrationModel) => {
                    draft.contactNo = e.target.value
                  }
                )
                setFileRegistrationObject(newFileRegistrationObject)
              }}
              value={fileRegistrationObject.contactNo}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formSelection">
            <Form.Label>
              Identification Type
              <span className="required-field-astrix">*</span>
            </Form.Label>
            <IdentificationTypesComponent
              identificationType={fileRegistrationObject.identificationType}
              onChange={(type: string) => {
                const newFileRegistrationObject = produce(
                  fileRegistrationObject,
                  (draft: FileRegistrationModel) => {
                    draft.identificationType = type
                    if (type === 'NIC') {
                      draft.dateOfBirth = null
                      draft.age = ''
                      draft.newNic = false
                      draft.newNicNumber = ''
                      draft.oldNicNumber = ''
                    }
                  }
                )
                setFileRegistrationObject(newFileRegistrationObject)
                const clearError = produce(errors, (draft) => {
                  draft.identificationTypeError = ''
                })
                setErrors(clearError)
              }}
              isDisabled={false}
            />
            <span className="feedback-error-span-custom">
              {errors.identificationTypeError}
            </span>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formApplicantDob">
            <Form.Label>
              Applicants DoB
              <span className="required-field-astrix">*</span>
            </Form.Label>
            <DatePicker
              disabled={fileRegistrationObject.identificationType === 'NIC'}
              maxDate={new Date()}
              className={
                errors.dateOfBirthError
                  ? 'date-picker-validate'
                  : 'form-control'
              }
              placeholderText="Enter Date of Birth"
              selected={
                fileRegistrationObject.dateOfBirth != null
                  ? new Date(fileRegistrationObject.dateOfBirth * 1000)
                  : null
              }
              onChange={(date: Date) => {
                if (date != null) {
                  const incidentDate =
                    fileRegistrationObject.incidentDate != null
                      ? new Date(fileRegistrationObject.incidentDate * 1000)
                      : new Date()

                  const age = calculateAge(date, incidentDate)

                  const newFileRegistrationObject = produce(
                    fileRegistrationObject,
                    (draft: FileRegistrationModel) => {
                      draft.dateOfBirth = date.getTime() / 1000
                      draft.age = age.years
                      draft.ageInMonths = age.months
                      draft.ageInDays = age.days
                    }
                  )
                  setFileRegistrationObject(newFileRegistrationObject)
                }
              }}
              onSelect={(date: any) => {
                const incidentDate =
                  fileRegistrationObject.incidentDate != null
                    ? new Date(fileRegistrationObject.incidentDate * 1000)
                    : new Date()

                const age = calculateAge(date, incidentDate)
                console.log(age)
                const newFileRegistrationObject = produce(
                  fileRegistrationObject,
                  (draft: FileRegistrationModel) => {
                    draft.dateOfBirth = date.getTime() / 1000
                    draft.age = age.years
                    draft.ageInMonths = age.months
                    draft.ageInDays = age.days
                  }
                )
                setFileRegistrationObject(newFileRegistrationObject)
              }}
            />
            <span className="feedback-error-span-custom">
              {errors.dateOfBirthError}
            </span>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formApplicantAge">
            <Form.Label>
              Applicant Age
              <span className="required-field-astrix">*</span>
            </Form.Label>
            <div className="d-flex align-items-center">
              <Form.Control
                style={{ width: '100px' }}
                isInvalid={!!errors.ageError}
                min={0}
                disabled={fileRegistrationObject.identificationType === 'NIC'}
                type="number"
                placeholder="Years"
                onBlur={(e) => {
                  const ageValue = e.target.value == '' ? '0' : e.target.value
                  const clearError = produce(errors, (draft) => {
                    draft.dateOfBirthError = ''

                    if (parseInt(ageValue) >= 16) {
                      draft.birthCertificateNumberError = ''
                      draft.bcNumberprovinceError = ''
                      draft.bcNunberdistrictError = ''
                      draft.birthCertificateDsDivisionError = ''
                    } else {
                      draft.identificationTypeError = ''
                      draft.nicError = ''
                      draft.passportNumberError = ''
                      draft.seniorCitizenIdError = ''
                      draft.drivingLicenseNumberError = ''
                    }
                  })
                  setErrors(clearError)
                }}
                onChange={(e) => {
                  const ageValue = e.target.value == '' ? null : e.target.value
                  const newFileRegistrationObject = produce(
                    fileRegistrationObject,
                    (draft: FileRegistrationModel) => {
                      draft.age = parseInt(ageValue)
                    }
                  )
                  setFileRegistrationObject(newFileRegistrationObject)
                  const clearError = produce(errors, (draft) => {
                    draft.ageError = ''
                  })
                  setErrors(clearError)
                }}
                value={fileRegistrationObject.age}
              />
              <span>&nbsp; (Years)</span>

              <Form.Control
                className="ms-3"
                style={{ width: '100px' }}
                isInvalid={!!errors.ageError}
                min={0}
                disabled={fileRegistrationObject.identificationType === 'NIC'}
                type="number"
                placeholder="Months"
                onBlur={(e) => {
                  const ageValue = e.target.value == '' ? '0' : e.target.value
                  const clearError = produce(errors, (draft) => {
                    draft.dateOfBirthError = ''

                    if (parseInt(ageValue) >= 16) {
                      draft.birthCertificateNumberError = ''
                      draft.bcNumberprovinceError = ''
                      draft.bcNunberdistrictError = ''
                      draft.birthCertificateDsDivisionError = ''
                    } else {
                      draft.identificationTypeError = ''
                      draft.nicError = ''
                      draft.passportNumberError = ''
                      draft.seniorCitizenIdError = ''
                      draft.drivingLicenseNumberError = ''
                    }
                  })
                  setErrors(clearError)
                }}
                onChange={(e) => {
                  const ageValue = e.target.value == '' ? null : e.target.value
                  const newFileRegistrationObject = produce(
                    fileRegistrationObject,
                    (draft: FileRegistrationModel) => {
                      draft.ageInMonths = parseInt(ageValue)
                    }
                  )

                  setFileRegistrationObject(newFileRegistrationObject)
                }}
                value={fileRegistrationObject.ageInMonths}
              />
              <span>&nbsp; (Months)</span>

              <Form.Control
                className="ms-3"
                style={{ width: '100px' }}
                isInvalid={!!errors.ageError}
                min={0}
                disabled={fileRegistrationObject.identificationType === 'NIC'}
                type="number"
                placeholder="Days"
                onBlur={(e) => {
                  const ageValue = e.target.value == '' ? '0' : e.target.value
                  const clearError = produce(errors, (draft) => {
                    draft.dateOfBirthError = ''

                    if (parseInt(ageValue) >= 16) {
                      draft.birthCertificateNumberError = ''
                      draft.bcNumberprovinceError = ''
                      draft.bcNunberdistrictError = ''
                      draft.birthCertificateDsDivisionError = ''
                    } else {
                      draft.identificationTypeError = ''
                      draft.nicError = ''
                      draft.passportNumberError = ''
                      draft.seniorCitizenIdError = ''
                      draft.drivingLicenseNumberError = ''
                    }
                  })
                  setErrors(clearError)
                }}
                onChange={(e) => {
                  const ageValue = e.target.value == '' ? null : e.target.value
                  const newFileRegistrationObject = produce(
                    fileRegistrationObject,
                    (draft: FileRegistrationModel) => {
                      draft.ageInDays = parseInt(ageValue)
                    }
                  )
                  setFileRegistrationObject(newFileRegistrationObject)
                }}
                value={fileRegistrationObject.ageInDays}
              />
              <span>&nbsp; (Days)</span>
            </div>
            <Form.Control.Feedback type="invalid">
              {errors.ageError}
            </Form.Control.Feedback>
          </Form.Group>
          {fileRegistrationObject.identificationType != 'BIRTH_CERTIFICATE' && (
            <>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formNICNumber">
                  <Form.Label>
                    Enter NIC Number
                    {checkNicMandatory(
                      fileRegistrationObject.identificationType
                    ) && <span className="required-field-astrix">*</span>}
                  </Form.Label>
                  <NicTypeSelectionComponent
                    newNic={fileRegistrationObject.newNic}
                    onChange={(value: boolean) => {
                      const newFileRegistrationObject = produce(
                        fileRegistrationObject,
                        (draft: FileRegistrationModel) => {
                          draft.newNic = value
                        }
                      )
                      setFileRegistrationObject(newFileRegistrationObject)
                    }}
                  />
                  <Form.Control
                    isInvalid={!!errors.nicError}
                    maxLength={fileRegistrationObject.newNic ? 12 : 10}
                    type="text"
                    placeholder="Enter NIC Number"
                    onBlur={async (e) => {
                      const identity = await getOldAndNewNicFromGivenNic(
                        fileRegistrationObject.incidentDate != null
                          ? new Date(fileRegistrationObject.incidentDate * 1000)
                          : new Date(),
                        e.target.value,
                        fileRegistrationObject.newNic ? 'N' : 'O'
                      )

                      if (identity.error) {
                        const clearError = produce(errors, (draft) => {
                          draft.nicError = 'Not a valid nic number'
                        })
                        setErrors(clearError)
                      } else {
                        const dateOfBirth = identity.birthDate
                        const incidentDate =
                          fileRegistrationObject.incidentDate != null
                            ? new Date(
                                fileRegistrationObject.incidentDate * 1000
                              )
                            : new Date()

                        const age = calculateAge(dateOfBirth, incidentDate)

                        const newFileRegistrationObject = produce(
                          fileRegistrationObject,
                          (draft: FileRegistrationModel) => {
                            draft.newNicNumber = identity.newNic
                            draft.oldNicNumber = identity.oldNic
                            draft.dateOfBirth =
                              identity.birthDate.getTime() / 1000
                            draft.age = age.years
                            draft.ageInMonths = age.months
                            draft.ageInDays = age.days
                            draft.gender = identity.gender
                          }
                        )
                        setFileRegistrationObject(newFileRegistrationObject)
                        const clearError = produce(errors, (draft) => {
                          draft.dateOfBirthError = ''
                          draft.ageError = ''
                        })
                        setErrors(clearError)
                      }
                    }}
                    onChange={(e) => {
                      const newFileRegistrationObject = produce(
                        fileRegistrationObject,
                        (draft: FileRegistrationModel) => {
                          if (draft.newNic) {
                            draft.newNicNumber = nicFormatFormat(e.target.value)
                          } else {
                            if (e.target.value.length < 10) {
                              draft.oldNicNumber = nicFormatFormat(
                                e.target.value
                              )
                            } else {
                              draft.oldNicNumber = e.target.value
                            }
                          }
                        }
                      )
                      setFileRegistrationObject(newFileRegistrationObject)
                      const clearError = produce(errors, (draft) => {
                        draft.nicError = ''
                      })
                      setErrors(clearError)
                    }}
                    value={
                      fileRegistrationObject.newNic
                        ? fileRegistrationObject.newNicNumber
                        : fileRegistrationObject.oldNicNumber
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.nicError}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              {fileRegistrationObject.identificationType != 'NIC' && (
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formPSDNumber">
                    <Form.Label>
                      {renderIdentificationTypeName(
                        fileRegistrationObject.identificationType
                      )}{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Form.Control
                      isInvalid={
                        !!errors.passportNumberError ||
                        !!errors.seniorCitizenIdError ||
                        !!errors.drivingLicenseNumberError ||
                        !!errors.ticNumberError
                      }
                      type="text"
                      disabled={
                        fileRegistrationObject.identificationType === ''
                      }
                      placeholder={`Enter ${renderIdentificationTypeName(
                        fileRegistrationObject.identificationType
                      )}`}
                      onChange={(e) => {
                        const newFileRegistrationObject = produce(
                          fileRegistrationObject,
                          (draft: FileRegistrationModel) => {
                            switch (fileRegistrationObject.identificationType) {
                              case 'PASSPORT': {
                                draft.passportNumber = e.target.value
                                break
                              }
                              case 'TIC': {
                                draft.ticNumber = e.target.value
                                break
                              }
                              case 'SENIOR_CITIZEN_ID': {
                                draft.seniorCitizenId = e.target.value
                                break
                              }
                              case 'DRIVING_LICENSE': {
                                draft.drivingLicenseNumber = e.target.value
                                break
                              }
                              default: {
                                break
                              }
                            }
                          }
                        )
                        setFileRegistrationObject(newFileRegistrationObject)
                        const clearError = produce(errors, (draft) => {
                          draft.passportNumberError = ''
                          draft.seniorCitizenIdError = ''
                          draft.drivingLicenseNumberError = ''
                        })
                        setErrors(clearError)
                      }}
                      value={renderIdentificationTypeValue(
                        fileRegistrationObject.identificationType
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {fileRegistrationObject.identificationType == 'PASSPORT'
                        ? errors.passportNumberError
                        : fileRegistrationObject.identificationType == 'TIC'
                        ? errors.ticNumberError
                        : fileRegistrationObject.identificationType ==
                          'SENIOR_CITIZEN_ID'
                        ? errors.seniorCitizenIdError
                        : fileRegistrationObject.identificationType ==
                          'DRIVING_LICENSE'
                        ? errors.drivingLicenseNumberError
                        : ''}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
              )}
            </>
          )}

          <Form.Group className="mb-3" controlId="idNumberRemark">
            <Form.Label>ID Number Remark</Form.Label>
            <Form.Control
              maxLength={45}
              type="text"
              placeholder="Enter ID Number Remark"
              onChange={(e) => {
                const newFileRegistrationObject = produce(
                  fileRegistrationObject,
                  (draft: FileRegistrationModel) => {
                    draft.idNumberRemark = e.target.value
                  }
                )
                setFileRegistrationObject(newFileRegistrationObject)
              }}
              value={fileRegistrationObject.idNumberRemark}
            />
          </Form.Group>

          {(fileRegistrationObject.scheme == 'PD' ||
            fileRegistrationObject.scheme == 'GSD') && (
            <Form.Group className="mb-3" controlId="certificateOfAbsence">
              <Form.Label>Certificate Of Absence</Form.Label>
              <Form.Control
                isInvalid={!!errors.certificateOfAbsenceError}
                maxLength={100}
                type="text"
                placeholder="Enter Certificate Of Absence"
                onChange={(e) => {
                  const newFileRegistrationObject = produce(
                    fileRegistrationObject,
                    (draft: FileRegistrationModel) => {
                      draft.certificateOfAbsence = e.target.value
                    }
                  )
                  setFileRegistrationObject(newFileRegistrationObject)
                  const clearError = produce(errors, (draft) => {
                    draft.certificateOfAbsenceError = ''
                  })
                  setErrors(clearError)
                }}
                value={fileRegistrationObject.certificateOfAbsence}
              />
              <Form.Control.Feedback type="invalid">
                {errors.certificateOfAbsenceError}
              </Form.Control.Feedback>
            </Form.Group>
          )}

          <Col sm={12} className="border-label mt-4">
            <div className="border-label-span">Birth Certificate Details</div>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formBirthCNumber">
                <Form.Label>
                  Birth Certificate Number
                  {fileRegistrationObject.identificationType ==
                    'BIRTH_CERTIFICATE' && (
                    <span className="required-field-astrix">*</span>
                  )}
                </Form.Label>
                <Form.Control
                  isInvalid={!!errors.birthCertificateNumberError}
                  type="text"
                  maxLength={4}
                  placeholder="Enter Birth Certificate Number"
                  onChange={(e) => {
                    const newFileRegistrationObject = produce(
                      fileRegistrationObject,
                      (draft: FileRegistrationModel) => {
                        draft.birthCertificateNumber = e.target.value
                      }
                    )
                    setFileRegistrationObject(newFileRegistrationObject)
                    const clearError = produce(errors, (draft) => {
                      draft.birthCertificateNumberError = ''
                    })
                    setErrors(clearError)
                  }}
                  value={fileRegistrationObject.birthCertificateNumber}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.birthCertificateNumberError}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className="mb-3 align-items-baseline">
              <Form.Group as={Col} controlId="formBirthCNumber">
                <Form.Label>Province</Form.Label>
                <Select
                  styles={
                    errors.bcNumberprovinceError ? customStyles : undefined
                  }
                  value={selectedBirthCertificateDs.provinceOption}
                  options={provinceList}
                  onChange={(selectedOption) => {
                    const newSelectedBirthCertificateDs = produce(
                      selectedBirthCertificateDs,
                      (draft: dsSelectionModal) => {
                        draft.provinceOption = selectedOption
                          ? selectedOption
                          : ({} as ProvinceOption)
                        draft.provinceId = selectedOption
                          ? selectedOption.value
                          : undefined
                        draft.districtOption = null
                        draft.districtId = undefined
                        draft.dsOption = null
                      }
                    )
                    setSelectedBirthCertificateDs(newSelectedBirthCertificateDs)
                    const clearError = produce(errors, (draft) => {
                      draft.bcNumberprovinceError = ''
                    })
                    setErrors(clearError)
                  }}
                />
                <span className="feedback-error-span-custom">
                  {errors.bcNumberprovinceError}
                </span>
              </Form.Group>
              <Form.Group as={Col} controlId="formBirthCDsDistrict">
                <Form.Label>District</Form.Label>
                <Select
                  styles={
                    errors.bcNunberdistrictError ? customStyles : undefined
                  }
                  value={selectedBirthCertificateDs.districtOption}
                  options={districtList.filter((district: DistrictOption) => {
                    return (
                      district.provinceId ===
                      selectedBirthCertificateDs.provinceId
                    )
                  })}
                  onChange={(selectedOption) => {
                    const newSelectedBirthCertificateDs = produce(
                      selectedBirthCertificateDs,
                      (draft: dsSelectionModal) => {
                        draft.districtOption = selectedOption
                          ? selectedOption
                          : ({} as DistrictOption)
                        draft.districtId = selectedOption
                          ? selectedOption.value
                          : undefined
                        draft.dsOption = null
                      }
                    )
                    setSelectedBirthCertificateDs(newSelectedBirthCertificateDs)
                    const clearError = produce(errors, (draft) => {
                      draft.bcNunberdistrictError = ''
                    })
                    setErrors(clearError)
                  }}
                />
                <span className="feedback-error-span-custom">
                  {errors.bcNunberdistrictError}
                </span>
              </Form.Group>
              <Form.Group as={Col} controlId="formBirthCDsDivision">
                <Form.Label>
                  Ds Division of Birth Certificate
                  {fileRegistrationObject.identificationType ==
                    'BIRTH_CERTIFICATE' && (
                    <span className="required-field-astrix">*</span>
                  )}
                </Form.Label>
                <Select
                  styles={
                    errors.birthCertificateDsDivisionError
                      ? customStyles
                      : undefined
                  }
                  value={selectedBirthCertificateDs.dsOption}
                  options={dsList.filter((ds: DsOption) => {
                    return (
                      ds.districtId === selectedBirthCertificateDs.districtId
                    )
                  })}
                  onChange={(selectedOption) => {
                    const newSelectedBirthCertificateDs = produce(
                      selectedBirthCertificateDs,
                      (draft: dsSelectionModal) => {
                        draft.dsOption = selectedOption
                          ? selectedOption
                          : ({} as DsOption)
                        draft.dsId = selectedOption
                          ? selectedOption.value
                          : undefined
                      }
                    )
                    setSelectedBirthCertificateDs(newSelectedBirthCertificateDs)
                    const clearError = produce(errors, (draft) => {
                      draft.birthCertificateDsDivisionError = ''
                    })
                    setErrors(clearError)
                  }}
                />
                <span className="feedback-error-span-custom">
                  {errors.birthCertificateDsDivisionError}
                </span>
              </Form.Group>
            </Row>
          </Col>
        </Col>
        {fileRegistrationObject.programType === 'COMPENSATION' &&
          fileRegistrationObject.scheme != 'MP' && (
            <Col sm={12} className="border-label mt-4">
              {fileRegistrationObject.scheme === 'PD' ||
              fileRegistrationObject.scheme === 'GSD' ||
              fileRegistrationObject.scheme === 'PI' ||
              fileRegistrationObject.scheme === 'GSI' ? (
                <div className="border-label-span">
                  Aggrieved Person Details
                </div>
              ) : (
                <div className="border-label-span">
                  Affected Property Details
                </div>
              )}

              {(fileRegistrationObject.scheme === 'PD' ||
                fileRegistrationObject.scheme === 'GSD') && (
                <Col sm={12} className="border-label mt-1 mb-4">
                  <div className="border-label-span">
                    Death Certificate Details
                  </div>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formDeathCNumber">
                      <Form.Label>
                        Death Certificate Number
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Form.Control
                        isInvalid={!!errors.deathCertificateNumberError}
                        type="text"
                        maxLength={8}
                        placeholder="Enter Death Certificate Number"
                        onChange={(e) => {
                          const newFileRegistrationObject = produce(
                            fileRegistrationObject,
                            (draft: FileRegistrationModel) => {
                              draft.deathCertificateNumber = e.target.value
                            }
                          )
                          setFileRegistrationObject(newFileRegistrationObject)
                          const clearError = produce(errors, (draft) => {
                            draft.deathCertificateNumberError = ''
                          })
                          setErrors(clearError)
                        }}
                        value={fileRegistrationObject.deathCertificateNumber}
                      />
                      <span className="feedback-error-span-custom">
                        {errors.deathCertificateNumberError}
                      </span>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3 align-items-baseline">
                    <Form.Group as={Col} controlId="formDeathCProvince">
                      <Form.Label>Province</Form.Label>
                      <Select
                        styles={
                          errors.deathCertificateProvinceError
                            ? customStyles
                            : undefined
                        }
                        value={selectedDeathCertificateDs.provinceOption}
                        options={provinceList}
                        onChange={(selectedOption) => {
                          const newSelectedDeathCertificateDs = produce(
                            selectedDeathCertificateDs,
                            (draft: dsSelectionModal) => {
                              draft.provinceOption = selectedOption
                                ? selectedOption
                                : ({} as ProvinceOption)
                              draft.provinceId = selectedOption
                                ? selectedOption.value
                                : undefined
                              draft.districtOption = null
                              draft.districtId = undefined
                              draft.dsOption = null
                            }
                          )
                          setSelectedDeathCertificateDs(
                            newSelectedDeathCertificateDs
                          )
                          const clearError = produce(errors, (draft) => {
                            draft.deathCertificateProvinceError = ''
                          })
                          setErrors(clearError)
                        }}
                      />
                      <span className="feedback-error-span-custom">
                        {errors.deathCertificateProvinceError}
                      </span>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formDeathCDsDistrict">
                      <Form.Label>District</Form.Label>
                      <Select
                        styles={
                          errors.deathCertificateDistrictError
                            ? customStyles
                            : undefined
                        }
                        value={selectedDeathCertificateDs.districtOption}
                        options={districtList.filter(
                          (district: DistrictOption) => {
                            return (
                              district.provinceId ===
                              selectedDeathCertificateDs.provinceId
                            )
                          }
                        )}
                        onChange={(selectedOption) => {
                          const newSelectedDeathCertificateDs = produce(
                            selectedDeathCertificateDs,
                            (draft: dsSelectionModal) => {
                              draft.districtOption = selectedOption
                                ? selectedOption
                                : ({} as DistrictOption)
                              draft.districtId = selectedOption
                                ? selectedOption.value
                                : undefined
                              draft.dsOption = null
                            }
                          )
                          setSelectedDeathCertificateDs(
                            newSelectedDeathCertificateDs
                          )
                          const clearError = produce(errors, (draft) => {
                            draft.deathCertificateDistrictError = ''
                          })
                          setErrors(clearError)
                        }}
                      />
                      <span className="feedback-error-span-custom">
                        {errors.deathCertificateDistrictError}
                      </span>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formDeathCDsDivision">
                      <Form.Label>
                        Ds Division of Death Certificate
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Select
                        styles={
                          errors.deathCertificateDsDivisionError
                            ? customStyles
                            : undefined
                        }
                        value={selectedDeathCertificateDs.dsOption}
                        options={dsList.filter((ds: DsOption) => {
                          return (
                            ds.districtId ===
                            selectedDeathCertificateDs.districtId
                          )
                        })}
                        onChange={(selectedOption) => {
                          const newSelectedDeathCertificateDs = produce(
                            selectedDeathCertificateDs,
                            (draft: dsSelectionModal) => {
                              draft.dsOption = selectedOption
                                ? selectedOption
                                : ({} as DsOption)
                              draft.dsId = selectedOption
                                ? selectedOption.value
                                : undefined
                            }
                          )
                          setSelectedDeathCertificateDs(
                            newSelectedDeathCertificateDs
                          )
                          const clearError = produce(errors, (draft) => {
                            draft.deathCertificateDsDivisionError = ''
                          })
                          setErrors(clearError)
                        }}
                      />
                      <span className="feedback-error-span-custom">
                        {errors.deathCertificateDsDivisionError}
                      </span>
                    </Form.Group>
                  </Row>
                </Col>
              )}
              {(fileRegistrationObject.scheme === 'PI' ||
                fileRegistrationObject.scheme === 'GSI') && (
                <>
                  <Form.Group className="mb-3" controlId="aggrivedPersionAge">
                    <Form.Label>
                      Aggrieved Persons Age
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Form.Control
                      isInvalid={!!errors.aggrievedPersonAgeError}
                      value={fileRegistrationObject.aggrievedPersonAge}
                      type="number"
                      min={0}
                      onChange={(e) => {
                        const ageValue =
                          e.target.value == '' ? '' : parseInt(e.target.value)
                        const newFileRegistrationObject = produce(
                          fileRegistrationObject,
                          (draft: FileRegistrationModel) => {
                            draft.aggrievedPersonAge = ageValue
                          }
                        )
                        setFileRegistrationObject(newFileRegistrationObject)
                        const clearError = produce(errors, (draft) => {
                          draft.aggrievedPersonNicError = ''
                        })
                        setErrors(clearError)
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.aggrievedPersonAgeError}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="formAggrievedPersonNIC"
                  >
                    <Form.Label>
                      Aggrieved Persons NIC
                      <span className="required-field-astrix">*</span>
                      <Col>
                        <Form.Check
                          inline
                          name="formAggrievedPersonNICType"
                          label="New"
                          type="radio"
                          id={`formAggrievedPersonNICType-N`}
                          checked={
                            fileRegistrationObject.aggrievedPersonNewNic ===
                            true
                          }
                          onChange={() => {
                            const newFileRegistrationObject = produce(
                              fileRegistrationObject,
                              (draft: FileRegistrationModel) => {
                                draft.aggrievedPersonNewNic = true
                              }
                            )
                            setFileRegistrationObject(newFileRegistrationObject)
                          }}
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          inline
                          name="formAggrievedPersonNICType"
                          label="Old"
                          type="radio"
                          id={`formAggrievedPersonNICType-O`}
                          checked={
                            fileRegistrationObject.aggrievedPersonNewNic ===
                            false
                          }
                          onChange={() => {
                            const newFileRegistrationObject = produce(
                              fileRegistrationObject,
                              (draft: FileRegistrationModel) => {
                                draft.aggrievedPersonNewNic = false
                              }
                            )
                            setFileRegistrationObject(newFileRegistrationObject)
                          }}
                        />
                      </Col>
                    </Form.Label>
                    <Form.Control
                      isInvalid={!!errors.aggrievedPersonNicError}
                      type="text"
                      placeholder="Enter Aggrieved Persons NIC"
                      onBlur={async (e) => {
                        const identity = await getOldAndNewNicFromGivenNic(
                          fileRegistrationObject.incidentDate != null
                            ? new Date(
                                fileRegistrationObject.incidentDate * 1000
                              )
                            : new Date(),
                          e.target.value,
                          fileRegistrationObject.aggrievedPersonNewNic
                            ? 'N'
                            : 'O'
                        )
                        if (identity.error) {
                          const clearError = produce(errors, (draft) => {
                            draft.aggrievedPersonNicError =
                              'Not a valid nic number'
                          })
                          setErrors(clearError)
                        } else {
                          const newFileRegistrationObject = produce(
                            fileRegistrationObject,
                            (draft: FileRegistrationModel) => {
                              draft.aggrievedPersonNewNicNumber =
                                identity.newNic
                              draft.aggrievedPersonOldNicNumber =
                                identity.oldNic
                              draft.aggrievedPersonAge = identity.age
                            }
                          )
                          setFileRegistrationObject(newFileRegistrationObject)
                        }
                      }}
                      onChange={(e) => {
                        const newFileRegistrationObject = produce(
                          fileRegistrationObject,
                          (draft: FileRegistrationModel) => {
                            if (draft.aggrievedPersonNewNic) {
                              draft.aggrievedPersonNewNicNumber = e.target.value
                            } else {
                              draft.aggrievedPersonOldNicNumber = e.target.value
                            }
                          }
                        )
                        setFileRegistrationObject(newFileRegistrationObject)
                        const clearError = produce(errors, (draft) => {
                          draft.aggrievedPersonNicError = ''
                        })
                        setErrors(clearError)
                      }}
                      value={
                        fileRegistrationObject.aggrievedPersonNewNic
                          ? fileRegistrationObject.aggrievedPersonNewNicNumber
                          : fileRegistrationObject.aggrievedPersonOldNicNumber
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.aggrievedPersonNicError}
                    </Form.Control.Feedback>
                  </Form.Group>
                </>
              )}
              {fileRegistrationObject.scheme === 'PI' && (
                <Col sm={12} className="border-label mt-4 mb-4">
                  <div className="border-label-span">{`Aggrieved Person's Birth Certificate Details`}</div>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formBirthCNumber">
                      <Form.Label>
                        {`Aggrieved Person's Birth Certificate Number`}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Form.Control
                        isInvalid={
                          !!errors.aggrievedPersonBirthCertificateNumberError
                        }
                        type="text"
                        maxLength={4}
                        placeholder={`Enter Aggrieved Person's Birth Certificate Number`}
                        onChange={(e) => {
                          const newFileRegistrationObject = produce(
                            fileRegistrationObject,
                            (draft: FileRegistrationModel) => {
                              draft.aggrievedPersonBirthCertificateNumber =
                                e.target.value
                            }
                          )
                          setFileRegistrationObject(newFileRegistrationObject)
                          const clearError = produce(errors, (draft) => {
                            draft.aggrievedPersonBirthCertificateNumberError =
                              ''
                          })
                          setErrors(clearError)
                        }}
                        value={
                          fileRegistrationObject.aggrievedPersonBirthCertificateNumber
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.aggrievedPersonBirthCertificateNumberError}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3 align-items-baseline">
                    <Form.Group as={Col} controlId="formAgProvince">
                      <Form.Label>Province</Form.Label>
                      <Select
                        styles={
                          errors.aggrievedPersonBCProvinceError
                            ? customStyles
                            : undefined
                        }
                        value={selectedAgBirthCertificateDs.provinceOption}
                        options={provinceList}
                        onChange={(selectedOption) => {
                          const newSelectedAgBirthCertificateDs = produce(
                            selectedAgBirthCertificateDs,
                            (draft: dsSelectionModal) => {
                              draft.provinceOption = selectedOption
                                ? selectedOption
                                : ({} as ProvinceOption)
                              draft.provinceId = selectedOption
                                ? selectedOption.value
                                : undefined
                              draft.districtOption = null
                              draft.districtId = undefined
                              draft.dsOption = null
                            }
                          )
                          setSelectedAgBirthCertificateDs(
                            newSelectedAgBirthCertificateDs
                          )
                          const clearError = produce(errors, (draft) => {
                            draft.aggrievedPersonBCProvinceError = ''
                          })
                          setErrors(clearError)
                        }}
                      />
                      <span className="feedback-error-span-custom">
                        {errors.aggrievedPersonBCProvinceError}
                      </span>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formAgBirthCDistrict">
                      <Form.Label>District</Form.Label>
                      <Select
                        styles={
                          errors.aggrievedPersonBCDistrictError
                            ? customStyles
                            : undefined
                        }
                        value={selectedAgBirthCertificateDs.districtOption}
                        options={districtList.filter(
                          (district: DistrictOption) => {
                            return (
                              district.provinceId ===
                              selectedAgBirthCertificateDs.provinceId
                            )
                          }
                        )}
                        onChange={(selectedOption) => {
                          const newSelectedAgBirthCertificateDs = produce(
                            selectedAgBirthCertificateDs,
                            (draft: dsSelectionModal) => {
                              draft.districtOption = selectedOption
                                ? selectedOption
                                : ({} as DistrictOption)
                              draft.districtId = selectedOption
                                ? selectedOption.value
                                : undefined
                              draft.dsOption = null
                            }
                          )
                          setSelectedAgBirthCertificateDs(
                            newSelectedAgBirthCertificateDs
                          )
                          const clearError = produce(errors, (draft) => {
                            draft.aggrievedPersonBCDistrictError = ''
                          })
                          setErrors(clearError)
                        }}
                      />
                      <span className="feedback-error-span-custom">
                        {errors.aggrievedPersonBCDistrictError}
                      </span>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formAgBirthCDsDivision">
                      <Form.Label>
                        {`DS Division of Aggrieved Person’s Birth Certificate`}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Select
                        styles={
                          errors.aggrievedPersoneBCDsDivisionError
                            ? customStyles
                            : undefined
                        }
                        value={selectedAgBirthCertificateDs.dsOption}
                        options={dsList.filter((ds: DsOption) => {
                          return (
                            ds.districtId ===
                            selectedAgBirthCertificateDs.districtId
                          )
                        })}
                        onChange={(selectedOption) => {
                          const newSelectedAgBirthCertificateDs = produce(
                            selectedAgBirthCertificateDs,
                            (draft: dsSelectionModal) => {
                              draft.dsOption = selectedOption
                                ? selectedOption
                                : ({} as DsOption)
                              draft.dsId = selectedOption?.value
                            }
                          )
                          setSelectedAgBirthCertificateDs(
                            newSelectedAgBirthCertificateDs
                          )
                          const clearError = produce(errors, (draft) => {
                            draft.aggrievedPersoneBCDsDivisionError = ''
                          })
                          setErrors(clearError)
                        }}
                      />
                      <span className="feedback-error-span-custom">
                        {errors.aggrievedPersoneBCDsDivisionError}
                      </span>
                    </Form.Group>
                  </Row>
                </Col>
              )}
              {(fileRegistrationObject.scheme === 'GPC' ||
                fileRegistrationObject.scheme === 'GSC') && (
                <Col sm={12} className="border-label mt-4 mb-4">
                  <div className="border-label-span">{`Instrument Details`}</div>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>{`Asset Identification Type`}</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={`Enter Asset Identification Type`}
                      />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formAssetIdNumber">
                      <Form.Label>
                        {`Asset Identification Number`}{' '}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Form.Control
                        isInvalid={!!errors.assetIdentificationNumberError}
                        type="text"
                        placeholder={`Enter Asset Identification Number`}
                        onChange={(e) => {
                          const newFileRegistrationObject = produce(
                            fileRegistrationObject,
                            (draft: FileRegistrationModel) => {
                              draft.assetIdentificationNumber = e.target.value
                            }
                          )
                          setFileRegistrationObject(newFileRegistrationObject)
                          const clearError = produce(errors, (draft) => {
                            draft.assetIdentificationNumberError = ''
                          })
                          setErrors(clearError)
                        }}
                        value={fileRegistrationObject.assetIdentificationNumber}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.assetIdentificationNumberError}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3 align-items-baseline">
                    <Form.Group as={Col} controlId="formInstrumentProvince">
                      <Form.Label>
                        Province{' '}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Select
                        styles={
                          errors.instrumentProvinceError
                            ? customStyles
                            : undefined
                        }
                        value={selectedInstrumentDs.provinceOption}
                        options={provinceList}
                        onChange={(selectedOption) => {
                          const newSelectedInstrumentDs = produce(
                            selectedInstrumentDs,
                            (draft: dsSelectionModal) => {
                              draft.provinceOption = selectedOption
                                ? selectedOption
                                : ({} as ProvinceOption)
                              draft.provinceId = selectedOption
                                ? selectedOption.value
                                : undefined
                              draft.districtOption = null
                              draft.districtId = undefined
                              draft.dsOption = null
                            }
                          )
                          setSelectedInstrumentDs(newSelectedInstrumentDs)
                          const clearError = produce(errors, (draft) => {
                            draft.instrumentProvinceError = ''
                          })
                          setErrors(clearError)
                        }}
                      />
                      <span className="feedback-error-span-custom">
                        {errors.instrumentProvinceError}
                      </span>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formInstrumentDistrict">
                      <Form.Label>
                        District{' '}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Select
                        styles={
                          errors.instrumentDistrictError
                            ? customStyles
                            : undefined
                        }
                        value={selectedInstrumentDs.districtOption}
                        options={districtList.filter(
                          (district: DistrictOption) => {
                            return (
                              district.provinceId ===
                              selectedInstrumentDs.provinceId
                            )
                          }
                        )}
                        onChange={(selectedOption) => {
                          const newSelectedInstrumentDs = produce(
                            selectedInstrumentDs,
                            (draft: dsSelectionModal) => {
                              draft.districtOption = selectedOption
                                ? selectedOption
                                : ({} as DistrictOption)
                              draft.districtId = selectedOption
                                ? selectedOption.value
                                : undefined
                              draft.dsOption = null
                            }
                          )
                          setSelectedInstrumentDs(newSelectedInstrumentDs)
                          const clearError = produce(errors, (draft) => {
                            draft.instrumentDistrictError = ''
                          })
                          setErrors(clearError)
                        }}
                      />
                      <span className="feedback-error-span-custom">
                        {errors.instrumentDistrictError}
                      </span>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formInstrumentDSDivision">
                      <Form.Label>
                        {`DS Division of Instrument`}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Select
                        styles={
                          errors.instrumentDsDivisionError
                            ? customStyles
                            : undefined
                        }
                        value={selectedInstrumentDs.dsOption}
                        options={dsList.filter((ds: DsOption) => {
                          return (
                            ds.districtId === selectedInstrumentDs.districtId
                          )
                        })}
                        onChange={(selectedOption) => {
                          const newSelectedInstrumentDs = produce(
                            selectedInstrumentDs,
                            (draft: dsSelectionModal) => {
                              draft.dsOption = selectedOption
                                ? selectedOption
                                : ({} as DsOption)
                              draft.dsId = selectedOption
                                ? selectedOption.value
                                : undefined
                            }
                          )
                          setSelectedInstrumentDs(newSelectedInstrumentDs)
                          const clearError = produce(errors, (draft) => {
                            draft.instrumentDsDivisionError = ''
                          })
                          setErrors(clearError)
                        }}
                      />
                      <span className="feedback-error-span-custom">
                        {errors.instrumentDsDivisionError}
                      </span>
                    </Form.Group>
                  </Row>
                </Col>
              )}
              {fileRegistrationObject.scheme === 'RLP' && (
                // <Row className="mb-3">
                //   <Form.Group as={Col} controlId="formReligiousPlaceRegNumber">
                //     <Form.Label>
                //       {`Religious Place Reg. Number`}
                //       <span className="required-field-astrix">*</span>
                //     </Form.Label>
                //     <Form.Control
                //       isInvalid={!!errors.religiousPlaceRegstringNoError}
                //       type="text"
                //       placeholder={`Enter Religious Place Reg. Number`}
                //       onChange={(e) => {
                //         const newFileRegistrationObject = produce(
                //           fileRegistrationObject,
                //           (draft: FileRegistrationModel) => {
                //             draft.religiousPlaceRegNumber = e.target.value
                //           }
                //         )
                //         setFileRegistrationObject(newFileRegistrationObject)
                //         const clearError = produce(errors, (draft) => {
                //           draft.religiousPlaceRegstringNoError = ''
                //         })
                //         setErrors(clearError)
                //       }}
                //       value={fileRegistrationObject.religiousPlaceRegNumber}
                //     />
                //     <Form.Control.Feedback type="invalid">
                //       {errors.religiousPlaceRegstringNoError}
                //     </Form.Control.Feedback>
                //   </Form.Group>
                // </Row>
                <>
                  <Form.Group as={Col} controlId="formReligiousPlaceRegNumber">
                    <Row className="mb-3">
                      <Form.Label>
                        {`Religious Place Reg. Number`}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Form.Control
                        isInvalid={!!errors.religiousPlaceRegstringNoError}
                        type="text"
                        placeholder={`Enter Religious Place Reg. Number`}
                        onChange={(e) => {
                          const newFileRegistrationObject = produce(
                            fileRegistrationObject,
                            (draft: FileRegistrationModel) => {
                              draft.religiousPlaceRegNumber = e.target.value
                            }
                          )
                          setFileRegistrationObject(newFileRegistrationObject)
                          const clearError = produce(errors, (draft) => {
                            draft.religiousPlaceRegstringNoError = ''
                          })
                          setErrors(clearError)
                        }}
                        value={fileRegistrationObject.religiousPlaceRegNumber}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.religiousPlaceRegstringNoError}
                      </Form.Control.Feedback>
                    </Row>

                    <Row className="mb-3">
                      <Form.Label>{`Religious Place Name`}</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={`Enter Religious Place Name`}
                        onChange={(e) => {
                          const newFileRegistrationObject = produce(
                            fileRegistrationObject,
                            (draft: FileRegistrationModel) => {
                              draft.religiousPlaceName = e.target.value
                            }
                          )
                          setFileRegistrationObject(newFileRegistrationObject)
                          const clearError = produce(errors, (draft) => {
                            draft.religiousPlaceRegstringNoError = ''
                          })
                          setErrors(clearError)
                        }}
                        value={fileRegistrationObject.religiousPlaceName}
                      />
                    </Row>
                  </Form.Group>
                </>
              )}
            </Col>
          )}
      </Form>

      <>
        <Col sm={12} className="border-label mt-4 mb-4">
          <div className="border-label-span">{`Other Details`}</div>
          <Row className="mb-3 align-items-baseline">
            <Form.Group as={Col} controlId="formProvince">
              <Form.Label>
                Province<span className="required-field-astrix">*</span>
              </Form.Label>
              <Select
                value={provinceList.filter((province: ProvinceOption) => {
                  return province.value === fileRegistrationObject.provinceId
                })}
                styles={
                  errors.applicantProvinceError ? customStyles : undefined
                }
                options={provinceList}
                onChange={(selectedOption) => {
                  const newFileRegistrationObject = produce(
                    fileRegistrationObject,
                    (draft: FileRegistrationModel) => {
                      draft.provinceId = selectedOption
                        ? selectedOption.value
                        : 0
                      draft.districtId = 0
                      draft.dsDivisionId = 0
                      draft.gnDivisionId = 0
                    }
                  )
                  setFileRegistrationObject(newFileRegistrationObject)
                  const clearError = produce(errors, (draft) => {
                    draft.applicantProvinceError = ''
                  })
                  setErrors(clearError)
                }}
              />
              <span className="feedback-error-span-custom">
                {errors.applicantProvinceError}
              </span>
            </Form.Group>
            <Form.Group as={Col} controlId="formDistrict">
              <Form.Label>
                District<span className="required-field-astrix">*</span>
              </Form.Label>
              <Select
                value={districtList.filter((district: DistrictOption) => {
                  return district.value === fileRegistrationObject.districtId
                })}
                styles={
                  errors.applicantDistrictError ? customStyles : undefined
                }
                isDisabled={
                  fileRegistrationObject.provinceId === undefined ||
                  fileRegistrationObject.provinceId === 0
                }
                options={districtList.filter((district: DistrictOption) => {
                  return (
                    district.provinceId === fileRegistrationObject.provinceId
                  )
                })}
                onChange={(selectedOption) => {
                  const newFileRegistrationObject = produce(
                    fileRegistrationObject,
                    (draft: FileRegistrationModel) => {
                      draft.districtId = selectedOption
                        ? selectedOption.value
                        : 0
                      draft.dsDivisionId = 0
                      draft.gnDivisionId = 0
                    }
                  )
                  setFileRegistrationObject(newFileRegistrationObject)
                  const clearError = produce(errors, (draft) => {
                    draft.applicantDistrictError = ''
                  })
                  setErrors(clearError)
                }}
              />
              <span className="feedback-error-span-custom">
                {errors.applicantDistrictError}
              </span>
            </Form.Group>
            <Form.Group as={Col} controlId="formDsDivision">
              <Form.Label>
                {`DS Division`}
                <span className="required-field-astrix">*</span>
              </Form.Label>
              <Select
                value={dsList.filter((ds: DsOption) => {
                  return ds.value === fileRegistrationObject.dsDivisionId
                })}
                styles={errors.applicantDSError ? customStyles : undefined}
                isDisabled={
                  fileRegistrationObject.districtId === undefined ||
                  fileRegistrationObject.districtId === 0
                }
                options={dsList.filter((ds: DsOption) => {
                  return ds.districtId === fileRegistrationObject.districtId
                })}
                onChange={(selectedOption) => {
                  const newFileRegistrationObject = produce(
                    fileRegistrationObject,
                    (draft: FileRegistrationModel) => {
                      draft.dsDivisionId = selectedOption
                        ? selectedOption.value
                        : 0
                      draft.allocateToTypeOption = {} as AllocateToTypeOption
                      draft.allocateTo = ''
                      draft.gnDivisionId = 0
                    }
                  )
                  setFileRegistrationObject(newFileRegistrationObject)

                  const clearError = produce(errors, (draft) => {
                    draft.applicantDSError = ''
                  })
                  setErrors(clearError)
                }}
              />
              <span className="feedback-error-span-custom">
                {errors.applicantDSError}
              </span>
            </Form.Group>
            <Form.Group as={Col} controlId="formGnDivision">
              <Form.Label>
                {`GN Division`}
                <span className="required-field-astrix">*</span>
              </Form.Label>
              <Select
                value={gnList.filter((gn: GnOption) => {
                  return gn.value === fileRegistrationObject.gnDivisionId
                })}
                styles={errors.applicantGNError ? customStyles : undefined}
                isDisabled={
                  fileRegistrationObject.dsDivisionId === undefined ||
                  fileRegistrationObject.dsDivisionId === 0
                }
                options={gnList.filter((gn: GnOption) => {
                  return gn.dsId === fileRegistrationObject.dsDivisionId
                })}
                onChange={(selectedOption) => {
                  const newFileRegistrationObject = produce(
                    fileRegistrationObject,
                    (draft: FileRegistrationModel) => {
                      draft.gnDivisionId = selectedOption
                        ? selectedOption.value
                        : 0
                    }
                  )
                  setFileRegistrationObject(newFileRegistrationObject)
                  const clearError = produce(errors, (draft) => {
                    draft.applicantGNError = ''
                  })
                  setErrors(clearError)
                }}
              />
              <span className="feedback-error-span-custom">
                {errors.applicantGNError}
              </span>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group className="mb-3" controlId="formAppliedDate">
              <Form.Label>
                Applied Date
                <span className="required-field-astrix">*</span>
              </Form.Label>
              <DatePicker
                maxDate={new Date()}
                placeholderText="Enter Applied Date"
                className={
                  errors.appliedDateError
                    ? 'date-picker-validate'
                    : 'form-control'
                }
                selected={
                  fileRegistrationObject.appliedDate != null
                    ? new Date(fileRegistrationObject.appliedDate * 1000)
                    : null
                }
                onChange={(date: any) => {
                  const newFileRegistrationObject = produce(
                    fileRegistrationObject,
                    (draft: FileRegistrationModel) => {
                      draft.appliedDate = date ? date.getTime() / 1000 : null
                    }
                  )
                  setFileRegistrationObject(newFileRegistrationObject)
                  const clearError = produce(errors, (draft) => {
                    draft.appliedDateError = ''
                  })
                  setErrors(clearError)
                }}
              />
              <span className="feedback-error-span-custom">
                {errors.appliedDateError}
              </span>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formFileRecievedDate">
              <Form.Label>
                File Received Date
                <span className="required-field-astrix">*</span>
              </Form.Label>
              <DatePicker
                maxDate={new Date()}
                placeholderText="Enter File Received Date"
                className={
                  errors.fileReceivedDateError
                    ? 'date-picker-validate'
                    : 'form-control'
                }
                selected={
                  fileRegistrationObject.fileReceivedDate != null
                    ? new Date(fileRegistrationObject.fileReceivedDate * 1000)
                    : null
                }
                onChange={(date: any) => {
                  const newFileRegistrationObject = produce(
                    fileRegistrationObject,
                    (draft: FileRegistrationModel) => {
                      draft.fileReceivedDate = date
                        ? date.getTime() / 1000
                        : null
                    }
                  )
                  setFileRegistrationObject(newFileRegistrationObject)
                  const clearError = produce(errors, (draft) => {
                    draft.fileReceivedDateError = ''
                  })
                  setErrors(clearError)
                }}
              />
              <span className="feedback-error-span-custom">
                {errors.fileReceivedDateError}
              </span>
            </Form.Group>
          </Row>
        </Col>
        <Col sm={12} className="d-flex justify-content-end mt-4">
          <button
            className="save-button"
            onClick={() => {
              const message = 'Do you wish to Cancel ?'
              const section = 'cancel'
              setShowConfirm({
                isShow: true,
                message: message,
                section: section,
                isRemark: false,
              })
            }}
          >
            Cancel
          </button>

          <button className="save-button" onClick={onClickSave}>
            Save
          </button>
        </Col>
      </>
      {showConfirm.isShow && (
        <ConfirmationModal
          name={'Confirmation'}
          message={showConfirm.message}
          onCancel={() => {
            setShowConfirm({
              isShow: false,
              message: '',
              section: '',
              isRemark: false,
            })
          }}
          isReason={false}
          isRemark={showConfirm.isRemark}
          onConfirm={() => {
            handleConfirm()
          }}
        />
      )}
    </>
  )
}

export default ModifyBasicInformation
