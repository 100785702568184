import React, { FC, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import {
  getOldAndNewNicFromGivenNic,
  nicFormatFormat,
} from '../../../utils/utilFunctions'

export interface NicInputComponentProps {
  value: string
  isDisabled: boolean
  onChange: any
  errors: any
  newNic: boolean
  incidentDate?: Date
}

const NicInputComponent: FC<NicInputComponentProps> = ({
  value,
  isDisabled,
  onChange,
  errors,
  newNic,
  incidentDate = new Date(),
}) => {
  const [nicValue, setNicValue] = useState(value)

  useEffect(() => {
    setNicValue(value)
  }, [newNic])

  return (
    <>
      <Form.Control
        isInvalid={!!errors.nicError}
        maxLength={newNic ? 12 : 10}
        disabled={isDisabled}
        type="text"
        placeholder="Enter NIC Number"
        onBlur={async (e) => {
          const identity = await getOldAndNewNicFromGivenNic(
            incidentDate,
            e.target.value,
            newNic ? 'N' : 'O'
          )
          onChange(identity)
        }}
        onChange={(e) => {
          let nicNumber = ''
          if (newNic) {
            nicNumber = nicFormatFormat(e.target.value)
          } else {
            if (e.target.value.length < 10) {
              nicNumber = nicFormatFormat(e.target.value)
            } else {
              nicNumber = e.target.value
            }
          }

          setNicValue(nicNumber)
        }}
        value={nicValue}
      />
      <Form.Control.Feedback type="invalid">
        {errors.nicError}
      </Form.Control.Feedback>
    </>
  )
}
export default NicInputComponent
