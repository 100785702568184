import apiService from '../api/apiManager'
import { ChangePassword } from '../../models/userProfileManager/changePassword'
class UserProfileManage {
  async getUserProfileDetails() {
    return apiService.apiGet('/my-profile/view')
  }

  async amendUserProfileDetails(name: string, myprofile: any) {
    return apiService.apiPutCommon('/my-profile/modify/' + name, myprofile)
  }

  async changePassword(passwordObject: ChangePassword) {
    return apiService.apiPost('/my-profile/change-password', passwordObject)
  }
}

export default new UserProfileManage()
