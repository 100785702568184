import React, { FC } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import './ActionButtonComponent.scss'

export interface ActionButtonComponentProps {
  name: string
  routeURL: string
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

const ActionButtonComponent: FC<ActionButtonComponentProps> = ({
  name,
  routeURL,
}) => {
  const history = useHistory()
  const { path } = useRouteMatch()
  function handleButtonClick() {
    history.push(`${path}${routeURL}`)
  }
  return (
    <button className="action-button-layout" onClick={handleButtonClick}>
      {name}
    </button>
  )
}

export default ActionButtonComponent
