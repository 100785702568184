/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useState } from 'react'
import { Modal, Form } from 'react-bootstrap'

export interface FileNameModalProps {
  name: string
  fileType: string
  acceptFileTypeList: any
  onConfirm?: any
  onCancel?: React.MouseEventHandler<HTMLButtonElement>
}

const FileNameModal: FC<FileNameModalProps> = ({
  name,
  fileType,
  acceptFileTypeList,
  onConfirm,
  onCancel,
}) => {
  const [customName, setCustomeName] = useState(name)
  return (
    <Modal
      size="lg"
      show={true}
      onHide={onCancel}
      aria-labelledby="example-modal-sizes-title-sm"
      dialogClassName="custom-modal-layout"
      contentClassName="custom-modal-content"
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        className="custom-modal-header"
      >
        <Modal.Title id="example-modal-sizes-title-sm">
          File Upload Name Change
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          id="name-form"
          onSubmit={(e) => {
            e.preventDefault()
            onConfirm(customName)
          }}
        >
          <Form.Group className="mb-3" controlId="name">
            <Form.Label>File Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Type Name"
              value={customName}
              onChange={(e) => {
                setCustomeName(e.target.value)
              }}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button className="cancel-button" onClick={onCancel}>
          Cancel
        </button>
        <button
          type="button"
          className="save-button"
          onClick={() => {
            onConfirm(customName)
          }}
        >
          Ok
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default FileNameModal
