// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/display-name */
import produce from 'immer'
import React, { useEffect, useState } from 'react'
import { Col, Form, Row, Table } from 'react-bootstrap'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ReactTableWithPaginationComponent from '../../../components/table/ReactTableWithPaginationComponent'
import { PaymentTypeModel } from '../../../models/systemConfiguration/incidentType/paymentTypeModel'
import metaDataService from '../../../services/metaDataService'
import LoadingComponent from '../../../components/common/loading/LoadingComponent'
import './PaymentType.scss'
import incidentTypesServices from '../../../services/systemConfiguration/incidentTypes/incidentTypesServices'
import ConfirmationModal from '../../../components/modals/ConfirmationModal'
import { handleNotification } from '../../../utils/utilFunctions'
import { useHistory } from 'react-router-dom'
import { InstallmentListModel } from '../../../models/systemConfiguration/incidentType/installmentListModel'
import {
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../../utils/constants'
import { showTotast } from '../../../utils/toast'
// eslint-disable-next-line @typescript-eslint/no-unused-vars

const PaymentType = (props: any) => {
  const {
    match: { params },
  } = props
  const [loading, setLoading] = useState(true)
  //const [installmentError, setInstallmentError] = useState(String)
  const [paymentTypesMetaData, setPaymentTypesMetaData] = useState([])
  const [paymentTypeList, setPaymentTypeList] = useState(
    [] as PaymentTypeModel[]
  )
  const [showConfirmation, setShowConfirmation] = useState(false)
  // const [sumOfInsatllemnt, setSumOfInsatllemnt] = useState([] as number[])
  const history = useHistory()

  useEffect(() => {
    async function getData() {
      const paymentData =
        await incidentTypesServices.apiGetPaymentTypeListByIncidentType(
          parseInt(params.id)
        )
      if (paymentData.data.length !== 0) {
        setPaymentTypeList(paymentData.data)
        setLoading(false)
      }
      //else {
      //   const schemeList = await metaDataService.getSchemeListByProgramType(
      //     'Compensation'
      //   )
      //   if (schemeList.status === 200) {
      //     const schemeListData = schemeList.data
      //     const newPaymentList = []
      //     for (let i = 0; i < schemeListData.length; i++) {
      //       const paymentType = {} as PaymentTypeModel
      //       paymentType.incidentTypeId = 1
      //       paymentType.scheme = schemeListData[i].code
      //       paymentType.installmentList = []
      //       newPaymentList.push(paymentType)
      //     }
      //     setPaymentTypeList(newPaymentList)
      //     setLoading(false)
      //   }
      // }
      const paymentTypeData = await metaDataService.getPaymentTypeList()
      if (paymentTypeData.status === 200) {
        setPaymentTypesMetaData(paymentTypeData.data)
      }
    }
    getData()
  }, [])

  const handleAdd = (payment: PaymentTypeModel) => {
    console.log(payment)
    const newPaymentList = produce(paymentTypeList, (draft) => {
      draft.map((paymentDraft: PaymentTypeModel) => {
        if (paymentDraft.scheme === payment.scheme) {
          if (payment.installmentValue) {
            const installment = {} as InstallmentListModel
            installment.installmentPercentage = parseFloat(
              payment.installmentValue
            )
            installment.installmentNumber =
              paymentDraft.installmentList.length + 1
            paymentDraft.installmentList.push(installment)
          } else {
            showTotast(
              ALERT_WARNING,
              'Installment percentage can not be empty!',
              TOAST_POSITION_TOP_RIGHT,
              5,
              TOAST_TRANSITION_SLIDE
            )
          }
        }
      })
    })
    setPaymentTypeList(newPaymentList)
    console.log(paymentTypeList)
  }

  const isOneTimePayment = (payment: PaymentTypeModel) => {
    if (payment.paymentType === 'One-time') {
      return true
    } else {
      return false
    }
  }
  const isPaymentType = (payment: PaymentTypeModel) => {
    if (payment.paymentType) {
      return false
    } else {
      return true
    }
  }

  const handleRemove = (scheme: string, installmentNumber: number) => {
    console.log(paymentTypeList, scheme, installmentNumber)

    const newValue = produce(paymentTypeList, (draft) => {
      draft.map((newDraft) => {
        if (newDraft.scheme == scheme) {
          const index = newDraft.installmentList.findIndex(
            (element) => element.installmentNumber === installmentNumber
          )
          newDraft.installmentList.splice(index, 1)
        }
      })
    })
    setPaymentTypeList(newValue)
  }
  const renderSchemeList = () => {
    return paymentTypeList.map((payment: PaymentTypeModel) => {
      return (
        <>
          <Col sm={12} className="border-label mb-3">
            <Row>
              <Col sm={4}>
                <Form.Group
                  className="mb-3"
                  controlId="scheme"
                  key={`form-group-scheme-${payment.scheme}`}
                >
                  <Form.Label>Scheme</Form.Label>
                  <Form.Control type="text" value={payment.scheme} disabled />
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group
                  className="mb-3"
                  controlId="paymnetType"
                  key={`form-group-paymnetType-${payment.scheme}`}
                >
                  <Form.Label>Payment Type</Form.Label>
                  <Form.Control
                    className="form-select"
                    as="select"
                    value={payment.paymentType}
                    onChange={(e) => {
                      const newPaymentList = produce(
                        paymentTypeList,
                        (draft) => {
                          draft.map((paymentDraft: PaymentTypeModel) => {
                            if (paymentDraft.scheme === payment.scheme) {
                              paymentDraft.paymentType = e.target.value
                            }
                            if (
                              !isOneTimePayment(payment) &&
                              paymentDraft.scheme === payment.scheme
                            ) {
                              paymentDraft.installmentList =
                                [] as InstallmentListModel[]
                              paymentDraft.installmentValue = ''
                            }
                          })
                        }
                      )
                      setPaymentTypeList(newPaymentList)
                    }}
                  >
                    {isPaymentType(payment) ? (
                      <option value="">--Select Payment Type--</option>
                    ) : (
                      ''
                    )}
                    {paymentTypesMetaData.map(
                      (data: { id: number; name: string }) => {
                        return (
                          <option key={`option-${data.id}`} value={data.name}>
                            {data.name}
                          </option>
                        )
                      }
                    )}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group
                  className="mb-3"
                  controlId="installment"
                  key={`form-group-paymnetType-${payment.scheme}`}
                >
                  <Form.Label>Installment %</Form.Label>
                  <Form.Control
                    id={payment.scheme}
                    disabled={isOneTimePayment(payment)}
                    defaultValue={payment.installmentValue}
                    type="text"
                    className="mb-2"
                    onChange={(e) => {
                      if (
                        !e.target.value.match(/([0-9]+([.][0-9]*)?|[.][0-9]+)/)
                      ) {
                        document.getElementById(payment.scheme).value = ''
                      } else {
                        const newPaymentList = produce(
                          paymentTypeList,
                          (draft) => {
                            draft.map((paymentDraft: PaymentTypeModel) => {
                              if (paymentDraft.scheme === payment.scheme) {
                                paymentDraft.installmentValue = e.target.value
                              }
                            })
                          }
                        )
                        setPaymentTypeList(newPaymentList)
                      }
                    }}
                  />
                </Form.Group>
              </Col>
              <Col sm={2}>
                <button
                  key={`button-${payment.scheme}`}
                  disabled={isOneTimePayment(payment)}
                  className={
                    !isOneTimePayment(payment)
                      ? 'payment-type-save-button'
                      : 'dassabled-save-button'
                  }
                  type="button"
                  onClick={(e) => {
                    e.preventDefault()
                    handleAdd(payment)
                  }}
                >
                  Add
                </button>
              </Col>
            </Row>
            {payment.installmentList.length > 0 && (
              <Row>
                {/* <ReactTableWithPaginationComponent
                  columns={columns}
                  data={payment.installmentList}
                  onClickRow={(row: any) => {
                    console.log(row)
                  }}
                /> */}
                <Table bordered key={`table-${payment.scheme}`}>
                  <thead className="custom-table-header">
                    <tr>
                      <th>Installment Number</th>
                      <th>Percentage (%)</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  {payment.installmentList.map(
                    (installment: {
                      installmentNumber: number
                      installmentPercentage: number
                    }) => {
                      return (
                        <tbody
                          key={payment.scheme}
                          className="custom-table-body"
                        >
                          <tr
                            key={`${payment.scheme}-${installment.installmentNumber}`}
                          >
                            <td>{installment.installmentNumber}</td>
                            <td>{installment.installmentPercentage}</td>
                            <td className="action-column">
                              <button
                                key={`button-${payment.scheme}-${installment.installmentNumber}`}
                                className="save-button"
                                onClick={(e) => {
                                  e.preventDefault()
                                  handleRemove(
                                    payment.scheme,
                                    installment.installmentNumber
                                  )
                                }}
                              >
                                Remove
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      )
                    }
                  )}
                </Table>
              </Row>
            )}
          </Col>
        </>
      )
    })
  }
  const handleConfirm = async () => {
    const result = await incidentTypesServices.apiUpdatePaymentType(
      paymentTypeList
    )
    if (result.status === 200) {
      setShowConfirmation(false)
      history.push(`/system-configuration/incident-type`)
    }
    handleNotification(result, 'Payment Type Updated')
  }
  return (
    <>
      {loading ? (
        <div className="loading-layout">
          <LoadingComponent />
        </div>
      ) : (
        <>
          <Row className="add-Incident-Type-page-layout">
            <Col sm={12} className="border-label">
              <div className="border-label-span">Set Payment Types</div>
              <Form>{renderSchemeList()}</Form>
              <Col className="d-flex justify-content-end mt-4">
                <button
                  className={'save-button'}
                  type="button"
                  onClick={(e) => {
                    e.preventDefault()
                    setShowConfirmation(true)
                  }}
                >
                  Save
                </button>
              </Col>
            </Col>
            {showConfirmation && (
              <ConfirmationModal
                name={'Confirmation'}
                message={'Are you sure to update  payment types ?'}
                onCancel={() => {
                  setShowConfirmation(false)
                }}
                onConfirm={handleConfirm}
              />
            )}
          </Row>
        </>
      )}
    </>
  )
}

export default PaymentType
