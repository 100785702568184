import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form, Table } from 'react-bootstrap'
import LoadingComponent from '../../../components/common/loading/LoadingComponent'
import { DependentAndBankDetailsModel } from '../../../models/applicationRegistration/compensation/dependentAndBankDetailsModel'
import { FileRegistrationModel } from '../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import ManageChequePaymentsService from '../../../services/chequePayments/manageChequePayments/manageChequePaymentsService'
import ConfirmationModal from '../../../components/modals/ConfirmationModal'
import produce from 'immer'
import DatePicker from 'react-datepicker'
import { ChequeDetailsModel } from '../../../models/chequePayments/chequeDetailsModel'
import {
  addChequeDetails,
  ALERT_WARNING,
  applicationReturn,
  approveCheque,
  modifyCheque,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../../utils/constants'
import {
  chequetDetails,
  messageModifyCheque,
  paymentApproved,
  returned,
} from '../../../utils/successMessages'
import { useHistory } from 'react-router-dom'
import { ResponseObject } from '../../../services/api/apiManager'
import {
  checkPermission,
  currencyMask,
  handleNotification,
  validateForm,
} from '../../../utils/utilFunctions'
import metaDataService from '../../../services/metaDataService'
import { ChequInstallmentListModel } from '../../../models/chequePayments/chequInstallmentListModel'
import { useSelector } from 'react-redux'
import { getUserSubPackageActions } from '../../../store/user/selectors'
import { ChequeDetailsErrorModel } from '../../../models/chequePayments/chequeDetailsErrorModel'
import { validationError } from '../../../utils/errorMessages'
import { showTotast } from '../../../utils/toast'
import { ValidationObjectModel } from '../../../models/common/validationObjectModel'

interface ConfirmationModal {
  isShow: boolean
  message: string
  section: string
  isRemark: boolean
}
const AddChequeDetails = (props: any) => {
  const {
    scheme,
    match: { params },
  } = props

  const [showConfirm, setShowConfirm] = useState({
    isShow: false,
    isRemark: false,
  } as ConfirmationModal)
  const [errors, setErrors] = useState({} as ChequeDetailsErrorModel)
  const [loading, setloading] = useState(true)
  const [stampChargeList, setStampChargeList] = useState([])
  const [editMode, setEditMode] = useState(false)
  const [chequeDetails, setChequeDetails] = useState({
    getIdentificationNo: false,
    stampChargesApplicable: false,
  } as ChequeDetailsModel)
  const [installmentList, setInstallmentList] = useState(
    [] as ChequInstallmentListModel[]
  )
  const [chequeDetailsList, setChequeDetailsList] = useState(
    [] as ChequeDetailsModel[]
  )
  const [dependentDetails, setDependentDetails] = useState(
    [] as DependentAndBankDetailsModel[]
  )
  const [bankDetails, setBankDetails] = useState(
    {} as DependentAndBankDetailsModel
  )
  const [fileRegistrationObject, setFileRegistrationObject] = useState(
    {} as FileRegistrationModel
  )
  const history = useHistory()
  const actionList = useSelector(
    getUserSubPackageActions('cheque-payment', 'manage-cheque-details')
  )
  useEffect(() => {
    async function getData() {
      const stampChageData = await metaDataService.getStampChargeList()
      if (stampChageData.status === 200) {
        setStampChargeList(stampChageData.data)
      }
      const chequeDetailsData =
        await ManageChequePaymentsService.apiGetChequePaymentDetails(
          parseInt(params.id)
        )
      if (chequeDetailsData.status === 200) {
        setChequeDetailsList(chequeDetailsData.data)
      }
      const result = await ManageChequePaymentsService.apiGetFileDetails(
        parseInt(params.id)
      )
      if (result.status === 200) {
        setFileRegistrationObject(result.data)
      }
      const dependentData = await ManageChequePaymentsService.apiGetDependents(
        parseInt(params.id)
      )
      if (dependentData.status === 200 || dependentData.status === 204) {
        setDependentDetails(dependentData.data)
        if (dependentData.data.length == 1) {
          setBankDetails(dependentData.data[0])

          const newValue = produce(
            chequeDetails,
            (draft: ChequeDetailsModel) => {
              draft.dependentId = dependentData.data[0].id
              if (result.data.paymentType === 'One-time') {
                draft.chequeAmount = result.data.compensationAmount
              }
              draft.getIdentificationNo = dependentData.data[0].printNicOnCheque
              draft.identificationNumber = dependentData.data[0].idNumber
            }
          )
          setChequeDetails(newValue)

          const installmentData =
            await metaDataService.getDependentInstallmentAmountList(
              dependentData.data[0].applicationFileId,
              dependentData.data[0].id
            )
          setInstallmentList(installmentData.data)
          // if (installmentData.data.lenght == 1) {
          //   const chequeAmount = produce(
          //     chequeDetails,
          //     (draft: ChequeDetailsModel) => {
          //       draft.chequeAmount = installmentData.data[0].amount
          //       draft.mergeInstallmentNumber =
          //         installmentData.data[0].mergeInstallmentNumber
          //       draft.dependentId = installmentData.data[0].id
          //     }
          //   )

          //   setChequeDetails(chequeAmount)
          // } else {
          //   setInstallmentList(installmentData.data)
          // }
        }
      }
    }
    getData()
    setloading(false)
  }, [loading])
  const handlePayee = async (id: number) => {
    const newValue = dependentDetails.filter(
      (dependent: DependentAndBankDetailsModel) => {
        return dependent.id === id
      }
    )[0]
    setBankDetails(newValue)
    let amount: number
    if (fileRegistrationObject.paymentType === 'One-time') {
      amount =
        (fileRegistrationObject.compensationAmount *
          newValue.amountPercentage) /
        100
    }

    const newChequeDetails = produce(
      chequeDetails,
      (draft: ChequeDetailsModel) => {
        draft.dependentId = id
        draft.chequeAmount = amount
        draft.getIdentificationNo =
          newValue.printNicOnCheque != null ? newValue.printNicOnCheque : false
        if (newValue.printNicOnCheque == true) {
          draft.identificationNumber = newValue.idNumber
        } else {
          draft.identificationNumber = ''
        }
        draft.identificationType = newValue.identificationType
      }
    )
    setChequeDetails(newChequeDetails)

    const installmentData =
      await metaDataService.getDependentInstallmentAmountList(
        newValue.applicationFileId,
        newValue.id
      )
    setInstallmentList(installmentData.data)

    if (installmentData.data.lenght == 1) {
      const chequeAmount = produce(
        chequeDetails,
        (draft: ChequeDetailsModel) => {
          draft.chequeAmount = installmentData.data[0].amount
          draft.mergeInstallmentNumber =
            installmentData.data[0].mergeInstallmentNumber
        }
      )
      setChequeDetails(chequeAmount)
    }
    setErrors({} as ChequeDetailsErrorModel)
  }
  const findErrors = () => {
    const newErrors = {} as ChequeDetailsErrorModel

    if (
      validateForm({
        value: chequeDetails.dependentId,
        type: 'text',
      } as ValidationObjectModel)
    ) {
      newErrors.payeeError = validateForm({
        value: chequeDetails.dependentId,
        customMessage: 'Please select the payee !',
        type: 'text',
      } as ValidationObjectModel)
    }
    if (chequeDetails.getIdentificationNo) {
      if (
        validateForm({
          value: chequeDetails.identificationNumber,
          type: 'text',
        } as ValidationObjectModel)
      ) {
        newErrors.identificationNumberError = validateForm({
          value: chequeDetails.identificationNumber,
          section: 'Identification number',
          type: 'text',
        } as ValidationObjectModel)
      }
    }
    if (
      validateForm({
        value: chequeDetails.voucherNumber,
        maxLength: 20,
        type: 'text',
      } as ValidationObjectModel)
    ) {
      newErrors.voucherNumberError = validateForm({
        value: chequeDetails.voucherNumber,
        section: 'Voucher number',
        maxLength: 20,
        type: 'text',
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        value: chequeDetails.chequeAmount,
        maxValue: 999999999,
        minValue: 0,
        type: 'number',
      } as ValidationObjectModel)
    ) {
      newErrors.chequeAmountError = validateForm({
        value: chequeDetails.chequeAmount,
        section: 'Cheque amount',
        maxValue: 999999999,
        minValue: 0,
        type: 'number',
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        value: chequeDetails.chequeNumber,
        maxLength: 20,
        type: 'text',
      } as ValidationObjectModel)
    ) {
      newErrors.chequeNumberError = validateForm({
        value: chequeDetails.chequeNumber,
        section: 'Cheque number',
        maxLength: 20,
        type: 'text',
      } as ValidationObjectModel)
    }
    if (chequeDetails.stampChargesApplicable) {
      if (
        validateForm({
          value: chequeDetails.stampCharge,
          type: 'number',
        } as ValidationObjectModel)
      ) {
        newErrors.stampChargeError = validateForm({
          value: chequeDetails.stampCharge,
          section: 'Stamp charge',
          type: 'number',
        } as ValidationObjectModel)
      }
    }
    if (
      validateForm({
        value: chequeDetails.chequeIssuedDate,
        type: 'number',
      } as ValidationObjectModel)
    ) {
      newErrors.chequeIssuedDateError = validateForm({
        value: chequeDetails.chequeIssuedDate,
        section: 'Cheque issued date',
        type: 'number',
      } as ValidationObjectModel)
    }

    return newErrors
  }
  // let newChequeAmount: number = chequeDetails.chequeAmount
  // if (chequeDetails.chequeAmount && chequeDetails.stampCharge) {
  //   newChequeAmount = chequeDetails.chequeAmount - chequeDetails.stampCharge
  // }
  const onClickAdd = async () => {
    const foundErrros = findErrors()

    if (Object.keys(foundErrros).length > 0) {
      showTotast(
        ALERT_WARNING,
        validationError,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setErrors(foundErrros)
    } else {
      const message = 'Do you wish to add this cheque details ?'
      const section = addChequeDetails
      setShowConfirm({
        isShow: true,
        message: message,
        section: section,
        isRemark: false,
      })
    }
  }
  const onClickModify = async () => {
    const foundErrros = findErrors()
    console.log(foundErrros)
    console.log(chequeDetails)
    if (Object.keys(foundErrros).length > 0) {
      showTotast(
        ALERT_WARNING,
        validationError,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setErrors(foundErrros)
    } else {
      const message = 'Do you wish to Modify this cheque details ?'
      const section = modifyCheque
      setShowConfirm({
        isShow: true,
        message: message,
        section: section,
        isRemark: false,
      })
    }
  }
  const resetDetails = () => {
    const resetValues = produce(chequeDetails, (draft) => {
      draft.voucherNumber = ''
      draft.chequeNumber = ''
      draft.stampChargesApplicable = false
      draft.getIdentificationNo = false
      draft.stampCharge = ''
      draft.chequeIssuedDate = null
      draft.chequeAmount = ''
    })
    setChequeDetails(resetValues)
  }
  const handleConfirm = async (remarkString: string) => {
    const newchequeDetails = produce(chequeDetails, (draft) => {
      draft.identificationType = bankDetails.identificationType
    })
    let result = {} as ResponseObject
    if (showConfirm.section == modifyCheque) {
      result = await ManageChequePaymentsService.apiModifyChequePaymentDetail(
        parseInt(params.id),
        newchequeDetails.id,
        newchequeDetails
      )
      if (result.status == 200) {
        resetDetails()
        setloading(true)
        setEditMode(false)
      }
      handleNotification(result, messageModifyCheque)
    }

    if (showConfirm.section == addChequeDetails) {
      result = await ManageChequePaymentsService.apiAddChequePaymentDetails(
        parseInt(params.id),
        newchequeDetails
      )
      if (result.status == 200) {
        resetDetails()
        setloading(true)
      }
      handleNotification(result, chequetDetails)
    } else if (showConfirm.section == approveCheque) {
      result = await ManageChequePaymentsService.apiApproveChequePayment(
        parseInt(params.id)
      )
      if (result.status === 200) {
        history.goBack()
      }
      handleNotification(result, paymentApproved)
    } else if (showConfirm.section == applicationReturn) {
      result = await ManageChequePaymentsService.apiReturnChequePayment(
        parseInt(params.id),
        remarkString
      )
      if (result.status === 200) {
        history.goBack()
      }
      handleNotification(result, returned)
    }
    setShowConfirm({ isShow: false, message: '', section: '', isRemark: false })
    console.log(remarkString)
  }
  const modifyDetails = async (id: number) => {
    const newValue = dependentDetails.filter(
      (dependent: DependentAndBankDetailsModel) => {
        return dependent.id === id
      }
    )[0]
    setBankDetails(newValue)

    const installmentData =
      await metaDataService.getDependentInstallmentAmountList(
        newValue.applicationFileId,
        newValue.id
      )
    setInstallmentList(installmentData.data)
  }
  const handleModify = (object: ChequeDetailsModel) => {
    console.log(object)
    modifyDetails(object.dependentId)
    const newValue = produce(chequeDetails, (draft) => {
      draft.dependentId = object.dependentId
      draft.voucherNumber = object.voucherNumber
      draft.chequeNumber = object.chequeNumber
      draft.stampCharge = object.stampCharge
      draft.stampChargesApplicable = object.stampChargesApplicable
      draft.chequeIssuedDate = object.chequeIssuedDate
      draft.identificationNumber = object.identificationNumber
      draft.getIdentificationNo = object.getIdentificationNo
      draft.id = object.id
      draft.chequeAmount = object.chequeAmount
    })
    setChequeDetails(newValue)

    setEditMode(true)

    setErrors({} as ChequeDetailsErrorModel)
  }

  const renderChequeDetailsTable = () => {
    return (
      <Table bordered>
        <thead className="custom-table-header">
          <tr>
            <th>Payee</th>
            <th>Bank Name</th>
            <th>Branch Name</th>
            <th>Account Number</th>
            <th>NIC Number</th>
            <th>Cheque Number</th>
            <th>Cheque Issued Date</th>
            <th>Cheque Amount (Rs.)</th>
            <th>Stamp Charge (Rs.)</th>
            <th>Voucher Number</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        {chequeDetailsList.map((cheques: ChequeDetailsModel) => {
          return (
            <tbody key={cheques.id} className="custom-table-body">
              <tr key={`tr-${cheques.id}`}>
                <td>{cheques.payee}</td>
                <td>{cheques.bankName}</td>
                <td>{cheques.branchName}</td>
                <td>{cheques.accountNumber}</td>
                <td>{cheques.newNicNumber}</td>
                <td>{cheques.chequeNumber}</td>
                <td>
                  {new Date(
                    cheques.chequeIssuedDate * 1000
                  ).toLocaleDateString()}
                </td>
                <td className="cell-align-right">
                  {cheques.chequeAmount ? currencyMask(cheques.paidAmount) : ''}
                </td>
                <td className="cell-align-right">
                  {cheques.stampCharge ? currencyMask(cheques.stampCharge) : ''}
                </td>
                <td>{cheques.voucherNumber}</td>
                <td>{cheques.status}</td>
                <td className="action-column">
                  <button
                    disabled={
                      !checkPermission('add', actionList) ||
                      cheques.status == 'Cheque Cancelled' ||
                      cheques.status == 'Cheque Posted' ||
                      (fileRegistrationObject.status !=
                        'Cheque Payment Pending' &&
                        cheques.select == false)
                    }
                    className={
                      cheques.status == 'Cheque Cancelled' ||
                      cheques.status == 'Cheque Posted' ||
                      (fileRegistrationObject.status !=
                        'Cheque Payment Pending' &&
                        cheques.select == false)
                        ? 'disable-button'
                        : 'save-button'
                    }
                    onClick={(action) => {
                      action.preventDefault()
                      action.stopPropagation()
                      handleModify(cheques)
                    }}
                  >
                    Modify
                  </button>
                </td>
              </tr>
            </tbody>
          )
        })}
      </Table>
    )
  }
  const handleSelect = (
    amount: number,
    mergeInstallmentNumber: number,
    installmentNumber: number
  ) => {
    const newValue = produce(chequeDetails, (draft: ChequeDetailsModel) => {
      draft.chequeAmount = amount
      draft.mergeInstallmentNumber = mergeInstallmentNumber
      draft.installmentNumber = installmentNumber
    })
    setChequeDetails(newValue)
    const clearError = produce(errors, (draft) => {
      draft.chequeAmountError = ''
    })
    setErrors(clearError)
  }
  // const checkStatusForApprove = () => {
  //   const pendingList = chequeDetailsList.filter((cheque) => {
  //     return cheque.status === 'Print Pending'
  //   })
  //   if (pendingList.length > 0) {
  //     return true
  //   } else {
  //     return false
  //   }
  // }
  return (
    <>
      {loading ? (
        <Container className="IncidentTypes-loading-layout">
          <LoadingComponent />
        </Container>
      ) : (
        <>
          <Row className="title-layout">
            <Col className="d-flex justify-content-start">
              <span className="compensation-title mt-2 mb-2 ml-2">
                Manage Cheque Payments
              </span>
              <span className="m-2">
                {`Application reference : ${fileRegistrationObject.referenceNumber}`}
              </span>
            </Col>
          </Row>
          <Row className="view-IncidentTypes-page-layout">
            <Col sm={12} className="border-label">
              <div className="border-label-span">
                {!editMode ? 'Add Cheque Details' : 'Modify Cheque Details'}
              </div>
              <Form>
                <Col className="border-label">
                  <div className="border-label-span">
                    {'Payee Bank Details'}
                  </div>
                  {bankDetails.nextOfKin && (
                    <div className="row justify-content-end">
                      <div className="col-3">
                        <span className="similar-record-span">
                          Next of Kin Added
                        </span>
                      </div>
                    </div>
                  )}
                  <Form.Group className="mb-3" controlId="formPayee">
                    <Form.Label>Payee</Form.Label>
                    {scheme == 'PD' || scheme == 'GSD' ? (
                      <>
                        <Form.Control
                          isInvalid={!!errors.payeeError}
                          disabled={editMode}
                          value={bankDetails.id}
                          type="text"
                          className="form-select"
                          as="select"
                          onChange={(e) => {
                            handlePayee(parseInt(e.target.value))
                          }}
                        >
                          {!bankDetails.fullName ? (
                            <option value="">--Select Payee--</option>
                          ) : (
                            ''
                          )}
                          {dependentDetails.map(
                            (
                              payee: DependentAndBankDetailsModel,
                              index: number
                            ) => {
                              return (
                                <option
                                  key={`payee-${index}-${payee.id}`}
                                  value={payee.id}
                                >
                                  {payee.fullName}
                                </option>
                              )
                            }
                          )}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.payeeError}
                        </Form.Control.Feedback>
                      </>
                    ) : (
                      <Form.Control
                        type="text"
                        disabled
                        value={bankDetails.fullName}
                      />
                    )}
                  </Form.Group>
                  {bankDetails && bankDetails.nextOfKin && (
                    <Form.Group className="mb-3" controlId="formNextOfkin">
                      <Form.Label>Next of Kin Name</Form.Label>
                      <Form.Control
                        type="text"
                        disabled
                        value={bankDetails ? bankDetails.nextOfKin.name : ''}
                      />
                    </Form.Group>
                  )}
                  <Form.Group className="mb-3" controlId="formBankName">
                    <Form.Label>Bank Name</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      value={
                        bankDetails.nextOfKin
                          ? bankDetails.nextOfKin.bank.name
                          : bankDetails.bank
                          ? bankDetails.bank.name
                          : ''
                      }
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBaranchName">
                    <Form.Label>Branch Name</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      value={
                        bankDetails.nextOfKin
                          ? bankDetails.nextOfKin.branch.name
                          : bankDetails.branch
                          ? bankDetails.branch.name
                          : ''
                      }
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formAccountNumber">
                    <Row>
                      <Col>
                        <Form.Label>Account Number</Form.Label>
                        <Form.Control
                          type="text"
                          disabled
                          value={
                            bankDetails.nextOfKin
                              ? bankDetails.nextOfKin.accountNumber
                              : bankDetails.accountNumber
                          }
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          disabled={
                            fileRegistrationObject.scheme === 'RLP' ||
                            !checkPermission('add', actionList) ||
                            bankDetails.printNicOnCheque
                          }
                          checked={chequeDetails.getIdentificationNo}
                          type="checkbox"
                          label="Get Identification Number"
                          className="mb-2"
                          onClick={() => {
                            if (!chequeDetails.dependentId) {
                              const newErrors = produce(errors, (draft) => {
                                draft.payeeError = validateForm({
                                  value: chequeDetails.dependentId,
                                  customMessage: 'Please select the payee !',
                                } as ValidationObjectModel)
                              })
                              setErrors(newErrors)
                            }
                          }}
                          onChange={(e) => {
                            // setGetIdNumber()
                            if (chequeDetails.dependentId) {
                              const newValue = produce(
                                chequeDetails,
                                (draft) => {
                                  draft.getIdentificationNo = e.target.checked
                                  if (e.target.checked == true) {
                                    if (bankDetails.nextOfKinId) {
                                      draft.identificationNumber = bankDetails
                                        ?.nextOfKin.newNic
                                        ? bankDetails.nextOfKin.newNicNumber
                                        : bankDetails.nextOfKin.oldNicNumber
                                    } else {
                                      draft.identificationNumber =
                                        bankDetails.idNumber
                                    }
                                  } else {
                                    draft.identificationNumber = ''
                                  }
                                }
                              )
                              setChequeDetails(newValue)
                            } else {
                              const newValue = produce(
                                chequeDetails,
                                (draft) => {
                                  draft.getIdentificationNo = false
                                }
                              )
                              setChequeDetails(newValue)
                            }
                          }}
                        />
                        <Form.Control
                          isInvalid={!!errors.identificationNumberError}
                          value={chequeDetails.identificationNumber}
                          type="text"
                          disabled
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
                {installmentList.length > 0 && (
                  <Col className="border-label mt-4">
                    <div className="border-label-span">
                      {'Installments Details'}
                    </div>
                    <Table bordered>
                      <thead className="custom-table-header">
                        <tr>
                          <th>Installment Number</th>
                          <th>Installment Amount (Rs.)</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      {installmentList.map(
                        (
                          installment: ChequInstallmentListModel,
                          index: number
                        ) => {
                          return (
                            <tbody
                              key={installment.installmentNumber}
                              className="custom-table-body"
                            >
                              <tr key={`tr-${installment.installmentNumber}`}>
                                <td>{index + 1}</td>
                                <td className="cell-align-right">
                                  {installment.amount
                                    ? currencyMask(installment.amount)
                                    : ''}
                                </td>
                                <td>{installment.status}</td>
                                <td className="action-column">
                                  <button
                                    key={`button-${installment.installmentNumber}`}
                                    className={'save-button'}
                                    onClick={(e) => {
                                      e.preventDefault()
                                      handleSelect(
                                        installment.amount,
                                        installment.mergeInstallmentNumber,
                                        installment.installmentNumber
                                      )
                                    }}
                                  >
                                    Select
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          )
                        }
                      )}
                    </Table>
                  </Col>
                )}
                {checkPermission('add', actionList) && (
                  <Col className="border-label mt-4">
                    <div className="border-label-span">
                      {'Cheque and Other Details'}
                    </div>
                    <Form.Group className="mb-3" controlId="formVoucherNumber">
                      <Form.Label>Voucher Number </Form.Label>
                      <Form.Control
                        isInvalid={!!errors.voucherNumberError}
                        value={chequeDetails.voucherNumber}
                        type="text"
                        onBlur={() => {
                          const newErrors = produce(errors, (draft) => {
                            draft.voucherNumberError = validateForm({
                              value: chequeDetails.voucherNumber,
                              section: 'Voucher number',
                              maxLength: 20,
                              type: 'text',
                            } as ValidationObjectModel)
                          })
                          setErrors(newErrors)
                        }}
                        onChange={(e) => {
                          const newValue = produce(chequeDetails, (draft) => {
                            draft.voucherNumber = e.target.value
                          })
                          setChequeDetails(newValue)
                          const clearError = produce(errors, (draft) => {
                            draft.voucherNumberError = ''
                          })
                          setErrors(clearError)
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.voucherNumberError}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formChequeNumber">
                      <Form.Label>Cheque Number</Form.Label>
                      <Form.Control
                        isInvalid={!!errors.chequeNumberError}
                        value={chequeDetails.chequeNumber}
                        type="text"
                        onBlur={() => {
                          const newErrors = produce(errors, (draft) => {
                            draft.chequeNumberError = validateForm({
                              value: chequeDetails.chequeNumber,
                              section: 'Cheque number',
                              maxLength: 20,
                              type: 'text',
                            } as ValidationObjectModel)
                          })
                          setErrors(newErrors)
                        }}
                        onChange={(e) => {
                          const newValue = produce(chequeDetails, (draft) => {
                            draft.chequeNumber = e.target.value
                          })
                          setChequeDetails(newValue)
                          const clearError = produce(errors, (draft) => {
                            draft.chequeNumberError = ''
                          })
                          setErrors(clearError)
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.chequeNumberError}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formStampCharges">
                      <Form.Check
                        checked={chequeDetails.stampChargesApplicable}
                        label="Stamp Charges Applicable"
                        className="mb-2"
                        onChange={(e) => {
                          // setStampChargesApplicable(e.target.checked)
                          const newValue = produce(chequeDetails, (draft) => {
                            draft.stampChargesApplicable = e.target.checked
                            if (e.target.checked == false) {
                              draft.stampCharge = ''
                            }
                          })
                          setChequeDetails(newValue)
                          const clearError = produce(errors, (draft) => {
                            draft.stampChargeError = ''
                          })
                          setErrors(clearError)
                        }}
                      />
                      <Form.Control
                        isInvalid={!!errors.stampChargeError}
                        value={chequeDetails.stampCharge}
                        type="text"
                        className="form-select"
                        as="select"
                        disabled={!chequeDetails.stampChargesApplicable}
                        onBlur={() => {
                          const newErrors = produce(errors, (draft) => {
                            draft.stampChargeError = validateForm({
                              value: chequeDetails.stampCharge,
                              section: 'Stamp charge',
                              type: 'number',
                            } as ValidationObjectModel)
                          })
                          setErrors(newErrors)
                        }}
                        onChange={(e) => {
                          const newValue = produce(chequeDetails, (draft) => {
                            draft.stampCharge = parseFloat(e.target.value)
                          })
                          setChequeDetails(newValue)
                          const clearError = produce(errors, (draft) => {
                            draft.stampChargeError = ''
                          })
                          setErrors(clearError)
                        }}
                      >
                        {!chequeDetails.stampCharge ? (
                          <option value="">--Select Stamp Charge--</option>
                        ) : (
                          ''
                        )}
                        {stampChargeList.map(
                          (stamp: { stampCharge: string }, index: number) => {
                            return (
                              <option
                                key={`stamp-${index}`}
                                value={stamp.stampCharge}
                              >
                                {stamp.stampCharge}
                              </option>
                            )
                          }
                        )}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.stampChargeError}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formChequeAmount">
                      <Form.Label>
                        Approved Amount (Rs.){'   '}
                        {chequeDetails.installmentNumber
                          ? `(Installment number ${chequeDetails.installmentNumber} selected)`
                          : ''}
                      </Form.Label>
                      <Form.Control
                        isInvalid={!!errors.chequeAmountError}
                        disabled
                        type="text"
                        value={
                          chequeDetails.chequeAmount
                            ? currencyMask(chequeDetails.chequeAmount)
                            : ''
                        }
                        onChange={(e) => {
                          const newValue = produce(chequeDetails, (draft) => {
                            draft.chequeAmount = parseInt(e.target.value)
                          })
                          setChequeDetails(newValue)
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.chequeAmountError}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="formChequeIssuedDate"
                    >
                      <Form.Label>Cheque Issued Date</Form.Label>
                      <DatePicker
                        maxDate={new Date()}
                        placeholderText="--Select--"
                        className={
                          errors.chequeIssuedDateError
                            ? 'date-picker-validate'
                            : 'form-control'
                        }
                        selected={
                          chequeDetails.chequeIssuedDate != null
                            ? new Date(chequeDetails.chequeIssuedDate * 1000)
                            : null
                        }
                        onBlur={() => {
                          const newErrors = produce(errors, (draft) => {
                            draft.chequeIssuedDateError = validateForm({
                              value: chequeDetails.chequeIssuedDate,
                              section: 'Cheque issued date',
                              type: 'number',
                            } as ValidationObjectModel)
                          })
                          setErrors(newErrors)
                        }}
                        onChange={(date: any) => {
                          const newValue = produce(
                            chequeDetails,
                            (draft: ChequeDetailsModel) => {
                              draft.chequeIssuedDate = date
                                ? date.getTime() / 1000
                                : null
                            }
                          )
                          setChequeDetails(newValue)
                          const clearError = produce(errors, (draft) => {
                            draft.chequeIssuedDateError = ''
                          })
                          setErrors(clearError)
                        }}
                      />
                      <span className="feedback-error-span-custom">
                        {errors.chequeIssuedDateError}
                      </span>
                    </Form.Group>

                    <Row>
                      <Col sm={12} className="d-flex justify-content-end mt-4">
                        {!editMode ? (
                          <button
                            type="button"
                            className="save-button"
                            onClick={onClickAdd}
                          >
                            Add
                          </button>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="save-button custom-margin-right"
                              onClick={() => {
                                setEditMode(false)
                                setloading(true)
                                resetDetails()

                                setloading(true)
                              }}
                            >
                              Reset
                            </button>
                            <button
                              type="button"
                              className="save-button"
                              onClick={onClickModify}
                            >
                              Modify
                            </button>
                          </>
                        )}
                      </Col>
                    </Row>
                  </Col>
                )}
              </Form>
              <Col sm={12} className="border-label mb-3 mt-4">
                <div className="border-label-span">Cheque Details</div>
                <div className=" table-wrapper">
                  {renderChequeDetailsTable()}
                </div>
              </Col>

              <Row>
                <Col sm={12} className="d-flex justify-content-end mt-4">
                  <button
                    className={'save-button'}
                    onClick={() => {
                      const message =
                        'Do you wish to approve this cheque payments ?'
                      const section = approveCheque
                      setShowConfirm({
                        isShow: true,
                        message: message,
                        section: section,
                        isRemark: false,
                      })
                    }}
                  >
                    Approve
                  </button>
                  {checkPermission('return', actionList) && (
                    <button
                      className="save-button"
                      onClick={() => {
                        const message =
                          'Do you wish to return this application ?'
                        const section = applicationReturn
                        setShowConfirm({
                          isShow: true,
                          message: message,
                          section: section,
                          isRemark: true,
                        })
                      }}
                    >
                      Return
                    </button>
                  )}
                </Col>
              </Row>
            </Col>
            {showConfirm.isShow && (
              <ConfirmationModal
                name={'Confirmation'}
                message={showConfirm.message}
                reasonType={'return'}
                section={'Manage Cheque Details'}
                onCancel={() => {
                  setShowConfirm({
                    isShow: false,
                    message: '',
                    section: '',
                    isRemark: false,
                  })
                }}
                isRemark={showConfirm.isRemark}
                onConfirm={(remark: string) => {
                  handleConfirm(remark)
                }}
              />
            )}
          </Row>
        </>
      )}
    </>
  )
}

export default AddChequeDetails
