import { FileRegistrationModel } from '../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { FilterModel } from '../../../models/utils/filterModel'
import apiService from '../../api/apiManager'

class GsdDataService {
  async apiFetchGsdList(filterData: FilterModel) {
    return apiService.apiPost(
      `/compensation-certification/compensation/gsd/view/list`,
      filterData
    )
  }

  async apiFetchGsdListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/compensation-certification/compensation/gsd/view/count`,
      filterData
    )
  }
  async apiFetchGsdReturnList(filterData: FilterModel) {
    return apiService.apiPost(
      `/compensation-certification/compensation/gsd/file-return/view/list`,
      filterData
    )
  }

  async apiFetchGsdReturnListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/compensation-certification/compensation/gsd/file-return/view/count`,
      filterData
    )
  }

  async apiApproveGsdFile(id: number) {
    return apiService.apiPutCommon(
      `/compensation-certification/compensation/gsd/${id}/approve`,
      {}
    )
  }

  async apiRejectGsdFile(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/compensation-certification/compensation/gsd/${id}/reject`,
      { remark: remark }
    )
  }
  async apiReturnGsdFile(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/compensation-certification/compensation/gsd/${id}/return`,
      { remark: remark }
    )
  }
  async apiUpdateGsdFile(id: number, file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/compensation-certification/compensation/gsd/file-return/${id}`,
      file
    )
  }
  async apiGetReturnGsdFile(id: number) {
    return apiService.apiGet(
      `/compensation-certification/compensation/gsd/file-return/view/${id}`
    )
  }
  async apiGetGsdFile(id: number) {
    return apiService.apiGet(
      `/compensation-certification/compensation/gsd/view/${id}`
    )
  }
}

export default new GsdDataService()
