import { FilterModel } from '../../../models/utils/filterModel'
import apiService from '../../api/apiManager'
class PoliceStationServices {
  async apiFetchPoliceStationList(filterData: FilterModel) {
    return apiService.apiPost(
      `/system-configuration/police-station/list`,
      filterData
    )
  }

  async apiFetchPoliceStationListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/system-configuration/police-station/count`,
      filterData
    )
  }

  async apiAddPoliceStation(policeStation: any) {
    return apiService.apiPost(
      `/system-configuration/police-station/add`,
      policeStation
    )
  }

  async apiGetPoliceStation(id: number) {
    return apiService.apiGet(`/system-configuration/police-station/view/${id}`)
  }

  async apiActivePoliceStation(id: number) {
    return apiService.apiActiveInactive(
      `/system-configuration/police-station/${id}/active`
    )
  }

  async apiInactivePoliceStation(id: number) {
    return apiService.apiActiveInactive(
      `/system-configuration/police-station/${id}/inactive`
    )
  }

  async apiUpdatePoliceStation(id: any, data: any) {
    return apiService.apiPutCommon(
      `/system-configuration/police-station/modify/${id}`,
      data
    )
  }
}
export default new PoliceStationServices()
