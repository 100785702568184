import produce from 'immer'
import {
  ApplicationActions,
  ApplicationState,
  SET_APP_STATE_LOADING,
  SET_APP_STATE_NOTIFICATION_COUNT,
} from './actions'

const initialState: ApplicationState = {
  applicationLoading: false,
  notificationCount: 0,
}

export default (state = initialState, action: ApplicationActions) => {
  switch (action.type) {
    case SET_APP_STATE_LOADING:
      return produce(state, (draft: ApplicationState) => {
        draft.applicationLoading = !draft.applicationLoading
      })
    case SET_APP_STATE_NOTIFICATION_COUNT:
      return produce(state, (draft: ApplicationState) => {
        draft.notificationCount = action.payload
      })
    default:
      return {
        ...state,
      }
  }
}
