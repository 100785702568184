import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { BreadCrumbModal } from '../../../models/breadcrumb/breadCrumb'
import { breadCrumbAddRequest } from '../../../store/breadCrumb/actions'
import './TileButtonComponent.scss'

export interface TileButtonComponentProps {
  name: string
  routeURL: string
  isIcon?: boolean
  onClick?: React.MouseEventHandler<HTMLDivElement>
}

const TileButtonComponent: FC<TileButtonComponentProps> = ({
  name,
  isIcon = true,
  routeURL,
}) => {
  const history = useHistory()
  const { path } = useRouteMatch()
  const dispatch = useDispatch()
  function handleButtonClick() {
    const breadCrumb: BreadCrumbModal = {
      name: name,
      route: routeURL,
      active: true,
    }
    dispatch(breadCrumbAddRequest({ breadcrumb: breadCrumb }))
    history.push(`${path}${routeURL}`)
  }
  return (
    <button className="tile-button-layout typo-600" onClick={handleButtonClick}>
      <div
        className={
          isIcon ? 'tile-button-icon-layout' : 'tile-button-title-layout'
        }
      >
        {isIcon ? (
          <img src={`/src/assets/images/tile-icons/${routeURL}.svg`} alt="" />
        ) : (
          routeURL
        )}
      </div>
      <div className="tile-button-name-layout">{name}</div>
    </button>
  )
}

export default TileButtonComponent
