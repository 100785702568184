import produce from 'immer'
import {
  FETCH_PERMISSIONS_LIST_FAILURE,
  FETCH_PERMISSIONS_LIST_REQUEST,
  FETCH_PERMISSIONS_LIST_SUCCESS,
  MODULE_ACTION_DESELECT_REQUEST,
  MODULE_ACTION_SELECT_REQUEST,
  PACKAGE_ACTION_DESELECT_REQUEST,
  PACKAGE_ACTION_SELECT_REQUEST,
  PermissionsListActions,
  PermissionsListState,
  PERMISSIONS_LIST_CLEAR_REQUEST,
  PERMISSIONS_LIST_RESET_REQUEST,
  SET_MODIFY_PERMISSION_LIST_REQUEST,
  SUBMODULE_ACTION_DESELECT_REQUEST,
  SUBMODULE_ACTION_SELECT_REQUEST,
  SUBPACKAGE_ACTION_DESELECT_REQUEST,
  SUBPACKAGE_ACTION_SELECT_REQUEST,
  DESELECT_ALL_ACTIONS_REQUEST,
} from './actionTypes'

const initialState: PermissionsListState = {
  pending: false,
  permissions: [],
  error: null,
}

export default (state = initialState, action: PermissionsListActions) => {
  switch (action.type) {
    case FETCH_PERMISSIONS_LIST_REQUEST:
      return produce(state, (draft: PermissionsListState) => {
        draft.pending = true
      })
    case FETCH_PERMISSIONS_LIST_SUCCESS:
      return produce(state, (draft: PermissionsListState) => {
        draft.pending = false
        draft.permissions = action.payload.permissions
        draft.error = null
      })
    case FETCH_PERMISSIONS_LIST_FAILURE:
      return produce(state, (draft: PermissionsListState) => {
        draft.pending = false
        draft.permissions = []
        draft.error = action.payload.error
      })
    case PACKAGE_ACTION_SELECT_REQUEST:
      return produce(state, (draft: PermissionsListState) => {
        const PackageIndex = action.payload.packageIndex
        const PackageActionIndex = draft.permissions[
          PackageIndex
        ].actionList.findIndex(
          (packageAction) => packageAction.id === action.payload.actionListId
        )
        draft.permissions[PackageIndex].actionList[
          PackageActionIndex
        ].isSelected = true
      })
    case PACKAGE_ACTION_DESELECT_REQUEST:
      return produce(state, (draft: PermissionsListState) => {
        const PackageIndex = action.payload.packageIndex
        const PackageActionIndex = draft.permissions[
          PackageIndex
        ].actionList.findIndex(
          (packageAction) => packageAction.id === action.payload.actionListId
        )
        draft.permissions[PackageIndex].actionList[
          PackageActionIndex
        ].isSelected = false
      })
    case SUBPACKAGE_ACTION_SELECT_REQUEST:
      return produce(state, (draft: PermissionsListState) => {
        const PackageIndex = action.payload.packageIndex
        const SubPackageIndex = action.payload.subPackageIndex
        const SubPackageActionIndex = draft.permissions[
          PackageIndex
        ].subPackageList[SubPackageIndex].actionList.findIndex(
          (subPackageAction) =>
            subPackageAction.id === action.payload.actionListId
        )
        draft.permissions[PackageIndex].subPackageList[
          SubPackageIndex
        ].actionList[SubPackageActionIndex].isSelected = true
      })
    case SUBPACKAGE_ACTION_DESELECT_REQUEST:
      return produce(state, (draft: PermissionsListState) => {
        const PackageIndex = action.payload.packageIndex
        const SubPackageIndex = action.payload.subPackageIndex
        const SubPackageActionIndex = draft.permissions[
          PackageIndex
        ].subPackageList[SubPackageIndex].actionList.findIndex(
          (subPackageAction) =>
            subPackageAction.id === action.payload.actionListId
        )
        draft.permissions[PackageIndex].subPackageList[
          SubPackageIndex
        ].actionList[SubPackageActionIndex].isSelected = false
      })
    case MODULE_ACTION_SELECT_REQUEST:
      return produce(state, (draft: PermissionsListState) => {
        const PackageIndex = action.payload.packageIndex
        const SubPackageIndex = action.payload.subPackageIndex
        const ModuleIndex = action.payload.moduleIndex
        const ModuleActionIndex = draft.permissions[
          PackageIndex
        ].subPackageList[SubPackageIndex].moduleList[
          ModuleIndex
        ].actionList.findIndex(
          (moduleAction) => moduleAction.id === action.payload.actionListId
        )
        draft.permissions[PackageIndex].subPackageList[
          SubPackageIndex
        ].moduleList[ModuleIndex].actionList[ModuleActionIndex].isSelected =
          true
      })
    case MODULE_ACTION_DESELECT_REQUEST:
      return produce(state, (draft: PermissionsListState) => {
        const PackageIndex = action.payload.packageIndex
        const SubPackageIndex = action.payload.subPackageIndex
        const ModuleIndex = action.payload.moduleIndex
        const ModuleActionIndex = draft.permissions[
          PackageIndex
        ].subPackageList[SubPackageIndex].moduleList[
          ModuleIndex
        ].actionList.findIndex(
          (moduleAction) => moduleAction.id === action.payload.actionListId
        )
        draft.permissions[PackageIndex].subPackageList[
          SubPackageIndex
        ].moduleList[ModuleIndex].actionList[ModuleActionIndex].isSelected =
          false
      })
    case SUBMODULE_ACTION_SELECT_REQUEST:
      return produce(state, (draft: PermissionsListState) => {
        const PackageIndex = action.payload.packageIndex
        const SubPackageIndex = action.payload.subPackageIndex
        const ModuleIndex = action.payload.moduleIndex
        const SubModuleIndex = action.payload.subModuleIndex
        const SubModuleActionIndex = draft.permissions[
          PackageIndex
        ].subPackageList[SubPackageIndex].moduleList[ModuleIndex].subModuleList[
          SubModuleIndex
        ].actionList.findIndex(
          (subModuleAction) =>
            subModuleAction.id === action.payload.actionListId
        )
        draft.permissions[PackageIndex].subPackageList[
          SubPackageIndex
        ].moduleList[ModuleIndex].subModuleList[SubModuleIndex].actionList[
          SubModuleActionIndex
        ].isSelected = true
      })
    case SUBMODULE_ACTION_DESELECT_REQUEST:
      return produce(state, (draft: PermissionsListState) => {
        const PackageIndex = action.payload.packageIndex
        const SubPackageIndex = action.payload.subPackageIndex
        const ModuleIndex = action.payload.moduleIndex
        const SubModuleIndex = action.payload.subModuleIndex
        const SubModuleActionIndex = draft.permissions[
          PackageIndex
        ].subPackageList[SubPackageIndex].moduleList[ModuleIndex].subModuleList[
          SubModuleIndex
        ].actionList.findIndex(
          (subModuleAction) =>
            subModuleAction.id === action.payload.actionListId
        )
        draft.permissions[PackageIndex].subPackageList[
          SubPackageIndex
        ].moduleList[ModuleIndex].subModuleList[SubModuleIndex].actionList[
          SubModuleActionIndex
        ].isSelected = false
      })
    case PERMISSIONS_LIST_RESET_REQUEST:
      return produce(state, (draft: PermissionsListState) => {
        draft.permissions = action.payload.permissions
      })

    case PERMISSIONS_LIST_CLEAR_REQUEST:
      return produce(state, (draft: PermissionsListState) => {
        draft.permissions = []
      })

    case DESELECT_ALL_ACTIONS_REQUEST:
      return produce(state, (draft: PermissionsListState) => {
        draft.permissions.map((permissionState) => {
          permissionState.actionList.map((permissionActions) => {
            permissionActions.isSelected = false
          })
          permissionState.subPackageList.map((subPackageState) => {
            subPackageState.actionList.map((subPackageActions) => {
              subPackageActions.isSelected = false
            })
            subPackageState.moduleList.map((moduleState) => {
              moduleState.actionList.map((moduleActions) => {
                moduleActions.isSelected = false
              })
              moduleState.subModuleList.map((subModuleState) => {
                subModuleState.actionList.map((subModuleActions) => {
                  subModuleActions.isSelected = false
                })
              })
            })
          })
        })
      })
    case SET_MODIFY_PERMISSION_LIST_REQUEST:
      return produce(state, (draft: PermissionsListState) => {
        for (let i = 0; i < action.payload.permissions.length; i++) {
          const permissionPayload = action.payload.permissions[i]
          draft.permissions.map((permissionState) => {
            if (permissionState.id === permissionPayload.id) {
              for (let j = 0; j < permissionPayload.actionList.length; j++) {
                permissionState.actionList.map((action) => {
                  if (action.id === permissionPayload.actionList[j].id) {
                    action.isSelected = true
                  }
                })
              }
              for (
                let k = 0;
                k < permissionPayload.subPackageList.length;
                k++
              ) {
                const subPackagePayload = permissionPayload.subPackageList[k]
                permissionState.subPackageList.map((subPackageState) => {
                  if (subPackageState.id === subPackagePayload.id) {
                    for (
                      let l = 0;
                      l < subPackagePayload.actionList.length;
                      l++
                    ) {
                      subPackageState.actionList.map((action) => {
                        if (action.id === subPackagePayload.actionList[l].id) {
                          action.isSelected = true
                        }
                      })
                    }
                    for (
                      let m = 0;
                      m < subPackagePayload.moduleList.length;
                      m++
                    ) {
                      const modulePayload = subPackagePayload.moduleList[m]
                      subPackageState.moduleList.map((moduleState) => {
                        if (moduleState.id === modulePayload.id) {
                          for (
                            let n = 0;
                            n < modulePayload.actionList.length;
                            n++
                          ) {
                            moduleState.actionList.map((action) => {
                              if (
                                action.id === modulePayload.actionList[n].id
                              ) {
                                action.isSelected = true
                              }
                            })
                          }
                          for (
                            let o = 0;
                            o < modulePayload.subModuleList.length;
                            o++
                          ) {
                            const subModulePayload =
                              modulePayload.subModuleList[o]
                            moduleState.subModuleList.map((subModuleState) => {
                              if (subModuleState.id === subModulePayload.id) {
                                for (
                                  let p = 0;
                                  p < subModulePayload.actionList.length;
                                  p++
                                ) {
                                  subModuleState.actionList.map((action) => {
                                    if (
                                      action.id ===
                                      subModulePayload.actionList[p].id
                                    ) {
                                      action.isSelected = true
                                    }
                                  })
                                }
                              }
                            })
                          }
                        }
                      })
                    }
                  }
                })
              }
            }
          })
        }
      })
    default:
      return {
        ...state,
      }
  }
}
