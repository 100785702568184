// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unused-vars */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/display-name */
import produce from 'immer'
import React, { FC, useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import InvalidFeedback from '../../../../components/common/invalidFeedback/InvalidFeedback'
import LoadingComponent from '../../../../components/common/loading/LoadingComponent'
import PriceInputComponent from '../../../../components/common/priceInputComponent/PriceInputComponent'
import ConfirmationModal from '../../../../components/modals/ConfirmationModal'
import { FileRegistrationModel } from '../../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { DependentAndRehabilitationDetailsModel } from '../../../../models/applicationRegistration/loan/dependentAndRehabilitationDetails'
import { DependentAndRehabilitationErrorModel } from '../../../../models/applicationRegistration/loan/dependentAndRehabilitationErrorModel'
import { LoanDependentDetailsModel } from '../../../../models/applicationRegistration/loan/dpendentDetails'
import { LoanDependentDetailsErrorModel } from '../../../../models/applicationRegistration/loan/dpendentDetailsErrorModel'
import { ValidationObjectModel } from '../../../../models/common/validationObjectModel'
import srtService from '../../../../services/applicationRegistration/loan/srtService'
import metaDataService from '../../../../services/metaDataService'
import { getUser } from '../../../../store/user/selectors'
import {
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../../../utils/constants'
import { validationError } from '../../../../utils/errorMessages'
import { occupationList } from '../../../../utils/metaData'
import { showTotast } from '../../../../utils/toast'
import {
  checkLoggedUser,
  checkStatsDraftOrModifyDraft,
  currencyMask,
  validateForm,
} from '../../../../utils/utilFunctions'

export interface DependentAndRehabilitationSectionProps {
  fileRegistrationObject: FileRegistrationModel
  onOk: any
  onSave: any
  onNext: any
}

interface ConfirmationModal {
  isShow: boolean
  message: string
  section: string
}

const DependentAndRehabilitationSection: FC<DependentAndRehabilitationSectionProps> =
  ({ fileRegistrationObject, onOk, onSave, onNext }) => {
    const [showConfirm, setShowConfirm] = useState({
      isShow: false,
    } as ConfirmationModal)
    const [loading, setLoading] = useState(true)
    const [relationshipList, setRelationShipList] = useState([])
    const [bankList, setBankList] = useState([])
    const [branchList, setBranchList] = useState([])
    const [dependentDetails, setDependentDetails] = useState(
      {} as DependentAndRehabilitationDetailsModel
    )
    const [dependent, setDependent] = useState({} as LoanDependentDetailsModel)
    const [dependentErrors, setDependentErrors] = useState(
      {} as LoanDependentDetailsErrorModel
    )

    const [rehabilitationErrors, setRehabilitationErrors] = useState(
      {} as DependentAndRehabilitationErrorModel
    )
    const user = useSelector(getUser)
    useEffect(() => {
      async function getMetaData() {
        console.log(loading)
        console.log(dependentDetails)
        const relationshipData = await metaDataService.getRelationshipList()
        if (relationshipData.status == 200) {
          setRelationShipList(relationshipData.data)
        }

        const bankData = await metaDataService.getBankList()
        if (bankData.status === 200) {
          setBankList(bankData.data)
        }

        const dependentDetailsData =
          await srtService.apiSrtGetDependentAndOtherDetails(
            fileRegistrationObject.id
          )

        if (
          dependentDetailsData.status === 200 &&
          dependentDetailsData.data.id != null
        ) {
          getBranchesList(dependentDetailsData.data.bankId)
          setDependentDetails(dependentDetailsData.data)
        } else {
          const dependentList: LoanDependentDetailsModel[] = []
          const newDependentDetails = {
            applicationFileId: fileRegistrationObject.id,
            dependentList: dependentList,
            anyLoanReceivedFromRehabilitationAuthority: false,
            havingPreviousExperiencesOnTheEmploymentYouApplied: false,
            planToStartASelfEmploymentBasedOnTheTraining: false,
          } as DependentAndRehabilitationDetailsModel
          setDependentDetails(newDependentDetails)
        }
        setLoading(false)
      }
      getMetaData()
    }, [fileRegistrationObject])

    const handleConfirm = () => {
      if (showConfirm.section === 'save') {
        onSave(dependentDetails)
      } else {
        onNext(dependentDetails)
      }
      setShowConfirm({ isShow: false, message: '', section: '' })
    }

    const handleDeleteButton = async (indexNumber: number) => {
      const newDependent = produce(
        dependentDetails,
        (draft: DependentAndRehabilitationDetailsModel) => {
          draft.dependentList = draft.dependentList.filter(
            (dependent, index) => {
              if (index != indexNumber) {
                return dependent
              }
            }
          )
        }
      )
      setDependentDetails(newDependent)
    }

    const renderFamilyDetails = () => {
      return dependentDetails.dependentList.map(
        (dependent: LoanDependentDetailsModel, index: number) => {
          return (
            <tr key={`dependent-${index}`}>
              <td>{dependent.name}</td>
              <td>{dependent.relationshipToApplicant}</td>
              <td>{dependent.gender}</td>
              <td>{dependent.age}</td>
              <td>{dependent.occupation}</td>
              <td>
                <button
                  onClick={(action) => {
                    action.preventDefault()
                    action.stopPropagation()
                    handleDeleteButton(index)
                  }}
                  className="save-button"
                >
                  Remove
                </button>
              </td>
            </tr>
          )
        }
      )
    }

    // const columns = React.useMemo(
    //   () => [
    //     {
    //       Header: 'Name',
    //       accessor: 'name',
    //     },
    //     {
    //       Header: 'Relationship',
    //       accessor: 'relationshipToApplicant',
    //     },
    //     {
    //       Header: 'Gender',
    //       accessor: 'gender',
    //     },
    //     {
    //       Header: 'Age',
    //       accessor: 'age',
    //     },
    //     {
    //       Header: 'Occupation',
    //       accessor: 'occupation',
    //     },
    //     {
    //       Header: 'Action',
    //       accessor: 'id',
    //       // Cell method will provide the cell value; we pass it to render a custom component
    //       Cell: (e: any) => {
    //         return (
    //           <button
    //             onClick={(action) => {
    //               action.preventDefault()
    //               action.stopPropagation()
    //               // handleDeleteButton(e.row.original)
    //               console.log(e.row)
    //               console.log(dependentDetails)
    //             }}
    //             className="save-button"
    //           >
    //             Remove
    //           </button>
    //         )
    //       },
    //     },
    //   ],
    //   []
    // )
    const findDependentErrors = () => {
      const newErrors = {} as LoanDependentDetailsErrorModel
      if (
        validateForm({
          type: 'text',
          value: dependent.name,
          maxLength: 50,
        } as ValidationObjectModel)
      ) {
        newErrors.nameError = validateForm({
          type: 'text',
          value: dependent.name,
          section: 'Name',
          maxLength: 50,
        } as ValidationObjectModel)
      }
      if (
        validateForm({
          type: 'text',
          value: dependent.relationshipToApplicant,
          maxLength: 20,
        } as ValidationObjectModel)
      ) {
        newErrors.relationshipToApplicantError = validateForm({
          type: 'text',
          value: dependent.relationshipToApplicant,
          section: 'Relationship',
          maxLength: 20,
        } as ValidationObjectModel)
      }
      if (
        validateForm({
          type: 'text',
          value: dependent.gender,
        } as ValidationObjectModel)
      ) {
        newErrors.genderError = validateForm({
          type: 'text',
          value: dependent.gender,
          section: 'Gender',
        } as ValidationObjectModel)
      }
      if (
        validateForm({
          type: 'number',
          value: dependent.age,

          maxLength: 3,
        } as ValidationObjectModel)
      ) {
        newErrors.ageError = validateForm({
          type: 'number',
          value: dependent.age,
          section: 'Age',
          maxLength: 3,
        } as ValidationObjectModel)
      }
      if (
        validateForm({
          type: 'text',
          value: dependent.occupation,

          maxLength: 30,
        } as ValidationObjectModel)
      ) {
        newErrors.occupationError = validateForm({
          type: 'text',
          value: dependent.occupation,
          section: 'Occupation',
          maxLength: 30,
        } as ValidationObjectModel)
      }
      return newErrors
    }

    const findRehabilitationErrors = () => {
      const newErrors = {} as DependentAndRehabilitationErrorModel

      if (
        validateForm({
          type: 'text',
          value: dependentDetails.nameOfTheRehabilitationInstitute,
          maxLength: 50,
        } as ValidationObjectModel)
      ) {
        newErrors.nameOfTheRehabilitationInstituteError = validateForm({
          type: 'text',
          value: dependentDetails.nameOfTheRehabilitationInstitute,
          section: 'Name of the Rehabilitation Institute',
          maxLength: 50,
        } as ValidationObjectModel)
      }

      if (
        validateForm({
          type: 'text',
          value: dependentDetails.detailsOfTheTrainingReceived,
          maxLength: 100,
        } as ValidationObjectModel)
      ) {
        newErrors.detailsOfTheTrainingReceivedError = validateForm({
          type: 'text',
          value: dependentDetails.detailsOfTheTrainingReceived,
          section: 'Details of the training received',
          maxLength: 100,
        } as ValidationObjectModel)
      }

      if (
        validateForm({
          type: 'number',
          value: dependentDetails.trainingPeriod,
          maxLength: 3,
        } as ValidationObjectModel)
      ) {
        newErrors.trainingPeriodError = validateForm({
          type: 'number',
          value: dependentDetails.trainingPeriod,
          section: 'Training Period',
          maxLength: 3,
        } as ValidationObjectModel)
      }

      if (dependentDetails.planToStartASelfEmploymentBasedOnTheTraining) {
        if (
          validateForm({
            type: 'text',
            value: dependentDetails.natureOfTheSelfEmployment,
            maxLength: 100,
          } as ValidationObjectModel)
        ) {
          newErrors.natureOfTheSelfEmploymentError = validateForm({
            type: 'text',
            value: dependentDetails.natureOfTheSelfEmployment,
            section: 'Nature of the Self-Employment',
            maxLength: 100,
          } as ValidationObjectModel)
        }

        if (
          validateForm({
            type: 'text',
            value: dependentDetails.selfEmploymentCategory,
            maxLength: 30,
          } as ValidationObjectModel)
        ) {
          newErrors.selfEmploymentCategoryError = validateForm({
            type: 'text',
            value: dependentDetails.selfEmploymentCategory,
            section: 'Self-Employment Category',
            maxLength: 30,
          } as ValidationObjectModel)
        }
      }

      if (dependentDetails.anyLoanReceivedFromRehabilitationAuthority) {
        if (
          validateForm({
            type: 'text',
            value: dependentDetails.nameOfTheLoanRecipient,
            maxLength: 50,
          } as ValidationObjectModel)
        ) {
          newErrors.nameOfTheLoanRecipientError = validateForm({
            type: 'text',
            value: dependentDetails.nameOfTheLoanRecipient,
            section: 'Name of the Loan recipient',
            maxLength: 50,
          } as ValidationObjectModel)
        }

        if (
          validateForm({
            type: 'text',
            value: dependentDetails.relationshipToApplicant,
            maxLength: 20,
          } as ValidationObjectModel)
        ) {
          newErrors.relationshipToApplicantError = validateForm({
            type: 'text',
            value: dependentDetails.relationshipToApplicant,
            section: 'Relationship to the Applicant',
            maxLength: 20,
          } as ValidationObjectModel)
        }

        if (
          validateForm({
            type: 'text',
            value: dependentDetails.obtainedLoanAmount,
            maxLength: 9,
          } as ValidationObjectModel)
        ) {
          newErrors.obtainedLoanAmountError = validateForm({
            type: 'text',
            value: dependentDetails.obtainedLoanAmount,
            section: 'Obtained Loan Amount (Rs.)',
            maxLength: 9,
          } as ValidationObjectModel)
        }

        if (
          validateForm({
            type: 'number',
            value: dependentDetails.interestRate,
            maxLength: 3,
          } as ValidationObjectModel)
        ) {
          newErrors.interestRateError = validateForm({
            type: 'number',
            value: dependentDetails.interestRate,
            section: 'Interest Rate',
            maxLength: 3,
          } as ValidationObjectModel)
        }
      }

      if (dependentDetails.otherDetails) {
        if (
          validateForm({
            type: 'text',
            value: dependentDetails.otherDetails,
            maxLength: 200,
          } as ValidationObjectModel)
        ) {
          newErrors.otherDetailsError = validateForm({
            type: 'text',
            value: dependentDetails.otherDetails,
            section: 'Any Other Details',
            maxLength: 200,
          } as ValidationObjectModel)
        }
      }

      //Not mentioned in SRS
      // if (dependentDetails.expectedLoanAmount) {
      if (
        validateForm({
          type: 'number',
          value: dependentDetails.expectedLoanAmount,
          maxLength: 9,
        } as ValidationObjectModel)
      ) {
        newErrors.expectedLoanAmountError = validateForm({
          type: 'number',
          value: dependentDetails.expectedLoanAmount,
          section: 'Expected Loan Amount (Rs.)',
          maxLength: 9,
        } as ValidationObjectModel)
      }
      // }

      // if (dependentDetails.grantedLoanAmount) {
      if (
        validateForm({
          type: 'number',
          value: dependentDetails.grantedLoanAmount,
          maxLength: 9,
        } as ValidationObjectModel)
      ) {
        newErrors.grantedLoanAmountError = validateForm({
          type: 'number',
          value: dependentDetails.grantedLoanAmount,
          section: 'Approved Loan Amount (Rs.)',
          maxLength: 9,
        } as ValidationObjectModel)
      }
      // }

      // if (dependentDetails.repaymentPeriod) {
      if (
        validateForm({
          type: 'number',
          value: dependentDetails.repaymentPeriod,
          maxLength: 3,
        } as ValidationObjectModel)
      ) {
        newErrors.repaymentPeriodError = validateForm({
          type: 'number',
          value: dependentDetails.repaymentPeriod,
          section: 'Repayment Period (in months)',
          maxLength: 3,
        } as ValidationObjectModel)
      }
      // }

      // if (dependentDetails.gracePeriod) {
      if (
        validateForm({
          type: 'number',
          value: dependentDetails.gracePeriod,
          maxLength: 3,
        } as ValidationObjectModel)
      ) {
        newErrors.gracePeriodError = validateForm({
          type: 'number',
          value: dependentDetails.gracePeriod,
          section: 'Grace Period (in months)',
          maxLength: 3,
        } as ValidationObjectModel)
      }
      // }

      if (
        validateForm({
          type: 'number',
          value: dependentDetails.bankId,
        } as ValidationObjectModel)
      ) {
        newErrors.bankIdError = validateForm({
          type: 'number',
          value: dependentDetails.bankId,
          section: 'Bank Name',
        } as ValidationObjectModel)
      }

      if (dependentDetails.bankId) {
        if (
          validateForm({
            type: 'text',
            value: dependentDetails.branchId,
          } as ValidationObjectModel)
        ) {
          newErrors.branchIdError = validateForm({
            type: 'text',
            value: dependentDetails.branchId,
            section: 'Branch Name',
          } as ValidationObjectModel)
        }
      }

      if (
        validateForm({
          type: 'text',
          value: dependentDetails.accountNumber,
          maxLength: 20,
        } as ValidationObjectModel)
      ) {
        newErrors.accountNumbergError = validateForm({
          type: 'text',
          value: dependentDetails.accountNumber,
          section: 'Account Number',
          maxLength: 20,
        } as ValidationObjectModel)
      }

      if (
        validateForm({
          type: 'number',
          value: dependentDetails.familyMonthlyIncome,
          minValue: 1,
          maxLength: 9,
        } as ValidationObjectModel)
      ) {
        newErrors.familyMonthlyIncomeError = validateForm({
          type: 'number',
          value: dependentDetails.familyMonthlyIncome,
          section: 'Monthly Family Income',
          maxLength: 9,
          minValue: 1,
        } as ValidationObjectModel)
      }

      return newErrors
    }

    const onClickSave = () => {
      const foundErrros = findRehabilitationErrors()
      console.log(foundErrros)
      if (Object.keys(foundErrros).length > 0) {
        showTotast(
          ALERT_WARNING,
          validationError,
          TOAST_POSITION_TOP_RIGHT,
          5,
          TOAST_TRANSITION_SLIDE
        )
        setRehabilitationErrors(foundErrros)
      } else {
        const message = 'Do you wish to Save application ?'
        const section = 'save'
        setShowConfirm({
          isShow: true,
          message: message,
          section: section,
        })
      }
    }

    const onClickNext = () => {
      const foundErrros = findRehabilitationErrors()
      console.log(foundErrros)
      if (Object.keys(foundErrros).length > 0) {
        showTotast(
          ALERT_WARNING,
          validationError,
          TOAST_POSITION_TOP_RIGHT,
          5,
          TOAST_TRANSITION_SLIDE
        )
        setRehabilitationErrors(foundErrros)
      } else {
        const message = 'Do you wish to Continue application ?'
        const section = 'next'
        setShowConfirm({
          isShow: true,
          message: message,
          section: section,
        })
      }
    }

    const handleAdd = () => {
      const foundErrros = findDependentErrors()
      console.log(foundErrros)
      if (Object.keys(foundErrros).length > 0) {
        showTotast(
          ALERT_WARNING,
          validationError,
          TOAST_POSITION_TOP_RIGHT,
          5,
          TOAST_TRANSITION_SLIDE
        )
        setDependentErrors(foundErrros)
      } else {
        const newDependent = produce(
          dependentDetails,
          (draft: DependentAndRehabilitationDetailsModel) => {
            const addDependent = produce(
              dependent,
              (draft: LoanDependentDetailsModel) => {
                draft.applicationFileId = fileRegistrationObject.id
              }
            )
            draft.dependentList.push(addDependent)
          }
        )
        setDependentDetails(newDependent)
        handleClear()
      }
    }

    const handleClear = () => {
      const newDependent = {} as LoanDependentDetailsModel
      const newDependentData = produce(newDependent, (draft) => {
        draft.name = ''
        draft.age = ''
        draft.occupation = ''
        draft.relationshipToApplicant = ''
        draft.gender = ''
      })
      setDependent(newDependentData)
    }

    const getBranchesList = async (id: number) => {
      const branchData = await metaDataService.getBranchesList(id)
      if (branchData.status == 200) {
        setBranchList(branchData.data)
      } else {
        setBranchList([])
      }
    }

    return (
      <>
        {loading ? (
          <LoadingComponent />
        ) : (
          <>
            <Col sm={12} className="border-label">
              <div className="border-label-span">
                Dependents and Rehabilitation Details
              </div>
              <Form id="file-dependent-details-form">
                <Col sm={12} className="border-label">
                  <div className="border-label-span">Dependent Details</div>
                  <Row className="mb-3">
                    <Form.Group className="mb-3" controlId="formDependentName">
                      <Form.Label>
                        Name <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Form.Control
                        isInvalid={!!dependentErrors.nameError}
                        type="text"
                        placeholder="Enter Name"
                        onBlur={() => {
                          const newErrors = produce(
                            dependentErrors,
                            (draft) => {
                              draft.nameError = validateForm({
                                type: 'text',
                                value: dependent.name,
                                section: 'Name',
                                maxLength: 50,
                              } as ValidationObjectModel)
                            }
                          )
                        }}
                        onChange={(e) => {
                          const newDependent = produce(
                            dependent,
                            (draft: LoanDependentDetailsModel) => {
                              draft.name = e.target.value
                            }
                          )
                          setDependent(newDependent)
                          const clearError = produce(
                            dependentErrors,
                            (draft) => {
                              draft.nameError = ''
                            }
                          )
                          setDependentErrors(clearError)
                        }}
                        value={dependent.name}
                      />
                      <InvalidFeedback message={dependentErrors.nameError} />
                    </Form.Group>
                  </Row>
                  <Form.Group as={Col} controlId="formApplicantRelationship">
                    <Form.Label>
                      Relationship{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Form.Control
                      isInvalid={!!dependentErrors.relationshipToApplicantError}
                      className="form-select"
                      as="select"
                      value={dependent.relationshipToApplicant}
                      onBlur={() => {
                        const newErrors = produce(dependentErrors, (draft) => {
                          draft.relationshipToApplicantError = validateForm({
                            type: 'text',
                            value: dependent.relationshipToApplicant,
                            section: 'Relationship',
                            maxLength: 20,
                          } as ValidationObjectModel)
                        })
                      }}
                      onChange={(e) => {
                        const newDependent = produce(
                          dependent,
                          (draft: LoanDependentDetailsModel) => {
                            draft.relationshipToApplicant = e.target.value
                          }
                        )
                        setDependent(newDependent)
                        const clearError = produce(dependentErrors, (draft) => {
                          draft.relationshipToApplicantError = ''
                        })
                        setDependentErrors(clearError)
                      }}
                    >
                      {dependent.relationshipToApplicant === '' ||
                      dependent.relationshipToApplicant === undefined ? (
                        <option value="">--Select Relationship--</option>
                      ) : (
                        ''
                      )}
                      {relationshipList.map(
                        (
                          title: { name: string; id: number },
                          index: number
                        ) => {
                          return (
                            <option key={`country-${index}`} value={title.name}>
                              {title.name}
                            </option>
                          )
                        }
                      )}
                    </Form.Control>
                    <InvalidFeedback
                      message={dependentErrors.relationshipToApplicantError}
                    />
                  </Form.Group>
                  <Row className="mt-3">
                    <Form.Group
                      className="mb-3"
                      controlId="formDependentGender"
                    >
                      <Form.Label>
                        Gender <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Form.Control
                        isInvalid={!!dependentErrors.genderError}
                        className="form-select"
                        as="select"
                        placeholder="Select Gender"
                        onBlur={() => {
                          const newErrors = produce(
                            dependentErrors,
                            (draft) => {
                              draft.genderError = validateForm({
                                type: 'text',
                                value: dependent.gender,
                                section: 'Gender',
                              } as ValidationObjectModel)
                            }
                          )
                        }}
                        onChange={(e) => {
                          const newDependent = produce(
                            dependent,
                            (draft: LoanDependentDetailsModel) => {
                              draft.gender = e.target.value
                            }
                          )
                          setDependent(newDependent)
                          const clearError = produce(
                            dependentErrors,
                            (draft) => {
                              draft.genderError = ''
                            }
                          )
                          setDependentErrors(clearError)
                        }}
                        value={dependent.gender}
                      >
                        <option value="N/A">--Select Gender--</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </Form.Control>
                      <InvalidFeedback message={dependentErrors.genderError} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formDependentAge">
                      <Form.Label>
                        Age <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Form.Control
                        isInvalid={!!dependentErrors.ageError}
                        type="text"
                        placeholder="Enter Age"
                        onBlur={() => {
                          const newErrors = produce(
                            dependentErrors,
                            (draft) => {
                              draft.ageError = validateForm({
                                type: 'number',
                                value: dependent.age,
                                section: 'Age',
                                maxLength: 3,
                              } as ValidationObjectModel)
                            }
                          )
                        }}
                        onChange={(e) => {
                          const newDependent = produce(
                            dependent,
                            (draft: LoanDependentDetailsModel) => {
                              draft.age = parseInt(e.target.value)
                            }
                          )
                          setDependent(newDependent)
                          const clearError = produce(
                            dependentErrors,
                            (draft) => {
                              draft.ageError = ''
                            }
                          )
                          setDependentErrors(clearError)
                        }}
                        value={dependent.age}
                      />
                      <InvalidFeedback message={dependentErrors.ageError} />
                    </Form.Group>
                  </Row>
                  <Form.Group as={Col} controlId="formApplicantOccupation">
                    <Form.Label>
                      Occupation{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Form.Control
                      isInvalid={!!dependentErrors.occupationError}
                      className="form-select"
                      as="select"
                      value={dependent.occupation}
                      onBlur={() => {
                        const newErrors = produce(dependentErrors, (draft) => {
                          draft.occupationError = validateForm({
                            type: 'text',
                            value: dependent.occupation,
                            section: 'Occupation',
                            maxLength: 30,
                          } as ValidationObjectModel)
                        })
                      }}
                      onChange={(e) => {
                        const newDependent = produce(
                          dependent,
                          (draft: LoanDependentDetailsModel) => {
                            draft.occupation = e.target.value
                          }
                        )
                        setDependent(newDependent)
                        const clearError = produce(dependentErrors, (draft) => {
                          draft.occupationError = ''
                        })
                        setDependentErrors(clearError)
                      }}
                    >
                      {dependent.occupation === '' ||
                      dependent.occupation === undefined ? (
                        <option value="">--Select Relationship--</option>
                      ) : (
                        ''
                      )}
                      {occupationList.map(
                        (
                          title: { name: string; id: number },
                          index: number
                        ) => {
                          return (
                            <option key={`country-${index}`} value={title.name}>
                              {title.name}
                            </option>
                          )
                        }
                      )}
                    </Form.Control>
                    <InvalidFeedback
                      message={dependentErrors.occupationError}
                    />
                  </Form.Group>
                  <Col sm={12} className="d-flex justify-content-end mt-4">
                    <button
                      type="button"
                      className="save-button custom-margin-right"
                      onClick={handleAdd}
                    >
                      {'Add'}
                    </button>
                    <button
                      type="button"
                      className="save-button custom-margin-right"
                      onClick={handleClear}
                    >
                      Clear
                    </button>
                  </Col>
                  <Col sm={12} className="border-label mt-3 mb-3">
                    <div className="border-label-span">{`Family Details`}</div>
                    <div className="table-wrapper">
                      <table className="table table-bordered">
                        <thead className="custom-table-header">
                          <tr>
                            <th>Name</th>
                            <th>Relationship</th>
                            <th>Gender</th>
                            <th>Age</th>
                            <th>Occupation</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody className="custom-table-body">
                          {renderFamilyDetails()}
                        </tbody>
                      </table>
                    </div>
                  </Col>
                  <Form.Group
                    className="mt-3"
                    controlId="formDependentMonthlyIncome"
                  >
                    <Form.Label>
                      Monthly Family Income{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <PriceInputComponent
                      isInvalid={
                        !!rehabilitationErrors.familyMonthlyIncomeError
                      }
                      value={dependentDetails.familyMonthlyIncome}
                      placeholder="Enter Monthly Family Income"
                      onChange={(price: string) => {
                        const newDependentDetails = produce(
                          dependentDetails,
                          (draft: DependentAndRehabilitationDetailsModel) => {
                            if (price) {
                              draft.familyMonthlyIncome = parseFloat(price)
                            } else {
                              draft.familyMonthlyIncome = ''
                            }
                          }
                        )
                        setDependentDetails(newDependentDetails)
                        const newErrors = produce(
                          rehabilitationErrors,
                          (draft) => {
                            draft.familyMonthlyIncomeError = validateForm({
                              type: 'number',
                              value: parseFloat(price),
                              section: 'Monthly Family Income',
                              maxLength: 9,
                              minValue: 1,
                            } as ValidationObjectModel)
                          }
                        )
                        setDependentErrors(newErrors)
                      }}
                      onErrorHandle={(price: string) => {
                        if (!price) {
                          const newDependentDetails = produce(
                            dependentDetails,
                            (draft: DependentAndRehabilitationDetailsModel) => {
                              draft.familyMonthlyIncome = 0
                            }
                          )
                          setDependentDetails(newDependentDetails)
                        } else {
                          const clearError = produce(
                            rehabilitationErrors,
                            (draft) => {
                              draft.familyMonthlyIncomeError = ''
                            }
                          )
                          setRehabilitationErrors(clearError)
                        }
                      }}
                    />
                    <InvalidFeedback
                      message={rehabilitationErrors.familyMonthlyIncomeError}
                    />
                  </Form.Group>
                </Col>
                <Col sm={12} className="border-label mt-5">
                  <div className="border-label-span">
                    Rehabilitation Details
                  </div>
                  <Form.Group
                    className="mb-3"
                    controlId="formNameOfRehabilitation"
                  >
                    <Form.Label>
                      Name of the Rehabilitation Institute{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Form.Control
                      isInvalid={
                        !!rehabilitationErrors.nameOfTheRehabilitationInstituteError
                      }
                      type="text"
                      placeholder="Enter Name of the Rehabilitation Institute"
                      onBlur={(e) => {
                        const newErrors = produce(
                          rehabilitationErrors,
                          (draft) => {
                            draft.nameOfTheRehabilitationInstituteError =
                              validateForm({
                                type: 'text',
                                value:
                                  dependentDetails.nameOfTheRehabilitationInstitute,
                                section: 'Name of the Rehabilitation Institute',
                                maxLength: 50,
                              } as ValidationObjectModel)
                          }
                        )
                        setRehabilitationErrors(newErrors)
                      }}
                      onChange={(e) => {
                        const newDependentDetails = produce(
                          dependentDetails,
                          (draft: DependentAndRehabilitationDetailsModel) => {
                            draft.nameOfTheRehabilitationInstitute =
                              e.target.value
                          }
                        )
                        setDependentDetails(newDependentDetails)
                        const clearError = produce(
                          rehabilitationErrors,
                          (draft) => {
                            draft.nameOfTheRehabilitationInstituteError = ''
                          }
                        )
                        setRehabilitationErrors(clearError)
                      }}
                      value={dependentDetails.nameOfTheRehabilitationInstitute}
                    />
                    <InvalidFeedback
                      message={
                        rehabilitationErrors.nameOfTheRehabilitationInstituteError
                      }
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formDetailsOfTrain">
                    <Form.Label>
                      Details of the training received{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Form.Control
                      isInvalid={
                        !!rehabilitationErrors.detailsOfTheTrainingReceivedError
                      }
                      type="text"
                      placeholder="Enter Details of the training received"
                      onBlur={(e) => {
                        const newErrors = produce(
                          rehabilitationErrors,
                          (draft) => {
                            draft.detailsOfTheTrainingReceivedError =
                              validateForm({
                                type: 'text',
                                value:
                                  dependentDetails.detailsOfTheTrainingReceived,
                                section: 'Details of the training received',
                                maxLength: 100,
                              } as ValidationObjectModel)
                          }
                        )
                        setRehabilitationErrors(newErrors)
                      }}
                      onChange={(e) => {
                        const newDependentDetails = produce(
                          dependentDetails,
                          (draft: DependentAndRehabilitationDetailsModel) => {
                            draft.detailsOfTheTrainingReceived = e.target.value
                          }
                        )
                        setDependentDetails(newDependentDetails)
                        const clearError = produce(
                          rehabilitationErrors,
                          (draft) => {
                            draft.detailsOfTheTrainingReceivedError = ''
                          }
                        )
                        setRehabilitationErrors(clearError)
                      }}
                      value={dependentDetails.detailsOfTheTrainingReceived}
                    />
                    <InvalidFeedback
                      message={
                        rehabilitationErrors.detailsOfTheTrainingReceivedError
                      }
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formTrainingPeriod">
                    <Form.Label>
                      Training Period(Months){' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Form.Control
                      isInvalid={!!rehabilitationErrors.trainingPeriodError}
                      type="text"
                      placeholder="Enter Training Period"
                      onBlur={(e) => {
                        const newErrors = produce(
                          rehabilitationErrors,
                          (draft) => {
                            draft.trainingPeriodError = validateForm({
                              type: 'number',
                              value: dependentDetails.trainingPeriod,
                              section: 'Training Period',
                              maxLength: 3,
                            } as ValidationObjectModel)
                          }
                        )
                        setRehabilitationErrors(newErrors)
                      }}
                      onChange={(e) => {
                        const newDependentDetails = produce(
                          dependentDetails,
                          (draft: DependentAndRehabilitationDetailsModel) => {
                            if (e.target.value) {
                              draft.trainingPeriod = parseInt(e.target.value)
                            } else {
                              draft.trainingPeriod = ''
                            }
                          }
                        )
                        setDependentDetails(newDependentDetails)
                        const clearError = produce(
                          rehabilitationErrors,
                          (draft) => {
                            draft.trainingPeriodError = ''
                          }
                        )
                        setRehabilitationErrors(clearError)
                      }}
                      value={dependentDetails.trainingPeriod}
                    />
                    <InvalidFeedback
                      message={rehabilitationErrors.trainingPeriodError}
                    />
                  </Form.Group>
                </Col>
                <Col sm={12} className="border-label mt-5">
                  <div className="border-label-span">
                    Self-Employment Details
                  </div>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formPlantToStart">
                      <Form.Label>
                        Plan to start a Self-Employment based on the training{' '}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Row>
                        <Col>
                          <Form.Check
                            inline
                            name="formPlantToStart"
                            label="Yes"
                            type="radio"
                            id={`formPlantToStart-N`}
                            checked={
                              dependentDetails.planToStartASelfEmploymentBasedOnTheTraining
                            }
                            onChange={() => {
                              const newDependentDetails = produce(
                                dependentDetails,
                                (
                                  draft: DependentAndRehabilitationDetailsModel
                                ) => {
                                  draft.planToStartASelfEmploymentBasedOnTheTraining =
                                    true
                                }
                              )
                              setDependentDetails(newDependentDetails)
                              const clearError = produce(
                                rehabilitationErrors,
                                (draft) => {
                                  draft.natureOfTheSelfEmploymentError = ''
                                  draft.selfEmploymentCategoryError = ''
                                }
                              )
                              setRehabilitationErrors(clearError)
                            }}
                          />
                        </Col>
                        <Col>
                          <Form.Check
                            inline
                            name="formPlantToStart"
                            label="No"
                            type="radio"
                            id={`formPlantToStart-O`}
                            checked={
                              !dependentDetails.planToStartASelfEmploymentBasedOnTheTraining
                            }
                            onChange={() => {
                              const newDependentDetails = produce(
                                dependentDetails,
                                (
                                  draft: DependentAndRehabilitationDetailsModel
                                ) => {
                                  draft.planToStartASelfEmploymentBasedOnTheTraining =
                                    false
                                }
                              )
                              setDependentDetails(newDependentDetails)
                              const clearError = produce(
                                rehabilitationErrors,
                                (draft) => {
                                  draft.natureOfTheSelfEmploymentError = ''
                                  draft.selfEmploymentCategoryError = ''
                                }
                              )
                              setRehabilitationErrors(clearError)
                            }}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Row>
                  {dependentDetails.planToStartASelfEmploymentBasedOnTheTraining && (
                    <>
                      <Form.Group className="mb-3" controlId="formNatureOfSelf">
                        <Form.Label>
                          Nature of the Self-Employment{' '}
                          <span className="required-field-astrix">*</span>
                        </Form.Label>
                        <Form.Control
                          isInvalid={
                            !!rehabilitationErrors.natureOfTheSelfEmploymentError
                          }
                          type="text"
                          placeholder="Enter Nature of the Self-Employment"
                          onBlur={() => {
                            const newErrors = produce(
                              rehabilitationErrors,
                              (draft) => {
                                draft.natureOfTheSelfEmploymentError =
                                  validateForm({
                                    type: 'text',
                                    value:
                                      dependentDetails.natureOfTheSelfEmployment,
                                    section: 'Nature of the Self-Employment',
                                    maxLength: 100,
                                  } as ValidationObjectModel)
                              }
                            )
                            setRehabilitationErrors(newErrors)
                          }}
                          onChange={(e) => {
                            const newDependentDetails = produce(
                              dependentDetails,
                              (
                                draft: DependentAndRehabilitationDetailsModel
                              ) => {
                                draft.natureOfTheSelfEmployment = e.target.value
                              }
                            )
                            setDependentDetails(newDependentDetails)
                            const clearError = produce(
                              rehabilitationErrors,
                              (draft) => {
                                draft.natureOfTheSelfEmploymentError = ''
                              }
                            )
                            setRehabilitationErrors(clearError)
                          }}
                          value={dependentDetails.natureOfTheSelfEmployment}
                        />
                        <InvalidFeedback
                          message={
                            rehabilitationErrors.natureOfTheSelfEmploymentError
                          }
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="formSelfEmpployementCategory"
                      >
                        <Form.Label>
                          Self-Employment Category{' '}
                          <span className="required-field-astrix">*</span>
                        </Form.Label>
                        <Form.Control
                          isInvalid={
                            !!rehabilitationErrors.selfEmploymentCategoryError
                          }
                          className="form-select"
                          as="select"
                          value={dependentDetails.selfEmploymentCategory}
                          onBlur={() => {
                            const newErrors = produce(
                              rehabilitationErrors,
                              (draft) => {
                                draft.selfEmploymentCategoryError =
                                  validateForm({
                                    type: 'text',
                                    value:
                                      dependentDetails.selfEmploymentCategory,
                                    section: 'Self-Employment Category',
                                    maxLength: 30,
                                  } as ValidationObjectModel)
                              }
                            )
                            setRehabilitationErrors(newErrors)
                          }}
                          onChange={(e) => {
                            const newDependentDetails = produce(
                              dependentDetails,
                              (
                                draft: DependentAndRehabilitationDetailsModel
                              ) => {
                                draft.selfEmploymentCategory = e.target.value
                              }
                            )
                            setDependentDetails(newDependentDetails)
                            const clearError = produce(
                              rehabilitationErrors,
                              (draft) => {
                                draft.selfEmploymentCategoryError = ''
                              }
                            )
                            setRehabilitationErrors(clearError)
                          }}
                        >
                          <option value="">--Select Category--</option>
                          {relationshipList.map(
                            (
                              title: { name: string; id: number },
                              index: number
                            ) => {
                              return (
                                <option
                                  key={`country-${index}`}
                                  value={title.name}
                                >
                                  {title.name}
                                </option>
                              )
                            }
                          )}
                        </Form.Control>
                        <InvalidFeedback
                          message={
                            rehabilitationErrors.selfEmploymentCategoryError
                          }
                        />
                      </Form.Group>
                      <Row className="mb-3">
                        <Form.Group as={Col} controlId="formHavingPreviousExp">
                          <Form.Label>
                            Having previous experiences on the employment you
                            applied{' '}
                            <span className="required-field-astrix">*</span>
                          </Form.Label>
                          <Row>
                            <Col>
                              <Form.Check
                                inline
                                name="formHavingPreviousExp"
                                label="Yes"
                                type="radio"
                                id={`formHavingPreviousExp-N`}
                                checked={
                                  dependentDetails.havingPreviousExperiencesOnTheEmploymentYouApplied
                                }
                                onChange={() => {
                                  const newDependentDetails = produce(
                                    dependentDetails,
                                    (
                                      draft: DependentAndRehabilitationDetailsModel
                                    ) => {
                                      draft.havingPreviousExperiencesOnTheEmploymentYouApplied =
                                        true
                                    }
                                  )
                                  setDependentDetails(newDependentDetails)
                                }}
                              />
                            </Col>
                            <Col>
                              <Form.Check
                                inline
                                name="formHavingPreviousExp"
                                label="No"
                                type="radio"
                                id={`formHavingPreviousExp-O`}
                                checked={
                                  !dependentDetails.havingPreviousExperiencesOnTheEmploymentYouApplied
                                }
                                onChange={() => {
                                  const newDependentDetails = produce(
                                    dependentDetails,
                                    (
                                      draft: DependentAndRehabilitationDetailsModel
                                    ) => {
                                      draft.havingPreviousExperiencesOnTheEmploymentYouApplied =
                                        false
                                    }
                                  )
                                  setDependentDetails(newDependentDetails)
                                }}
                              />
                            </Col>
                          </Row>
                        </Form.Group>
                      </Row>
                    </>
                  )}
                </Col>
                <Col sm={12} className="border-label mt-5">
                  <div className="border-label-span">
                    Loan details of Family members
                  </div>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formAnyLoanRecieved">
                      <Form.Label>
                        Any loan received from Rehabilitation Authority{' '}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Row>
                        <Col>
                          <Form.Check
                            inline
                            name="formAnyLoanRecieved"
                            label="Yes"
                            type="radio"
                            id={`formAnyLoanRecieved-N`}
                            checked={
                              dependentDetails.anyLoanReceivedFromRehabilitationAuthority
                            }
                            onChange={() => {
                              const newDependentDetails = produce(
                                dependentDetails,
                                (
                                  draft: DependentAndRehabilitationDetailsModel
                                ) => {
                                  draft.anyLoanReceivedFromRehabilitationAuthority =
                                    true
                                }
                              )
                              setDependentDetails(newDependentDetails)
                              const clearError = produce(
                                rehabilitationErrors,
                                (draft) => {
                                  draft.nameOfTheLoanRecipientError = ''
                                  draft.relationshipToApplicantError = ''
                                  draft.obtainedLoanAmountError = ''
                                  draft.interestRateError = ''
                                }
                              )
                              setRehabilitationErrors(clearError)
                            }}
                          />
                        </Col>
                        <Col>
                          <Form.Check
                            inline
                            name="formAnyLoanRecieved"
                            label="No"
                            type="radio"
                            id={`formAnyLoanRecieved-O`}
                            checked={
                              !dependentDetails.anyLoanReceivedFromRehabilitationAuthority
                            }
                            onChange={() => {
                              const newDependentDetails = produce(
                                dependentDetails,
                                (
                                  draft: DependentAndRehabilitationDetailsModel
                                ) => {
                                  draft.anyLoanReceivedFromRehabilitationAuthority =
                                    false
                                }
                              )
                              setDependentDetails(newDependentDetails)
                              const clearError = produce(
                                rehabilitationErrors,
                                (draft) => {
                                  draft.nameOfTheLoanRecipientError = ''
                                  draft.relationshipToApplicantError = ''
                                  draft.obtainedLoanAmountError = ''
                                  draft.interestRateError = ''
                                }
                              )
                              setRehabilitationErrors(clearError)
                            }}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Row>
                  <Form.Group
                    className="mb-3"
                    controlId="formNameOfLoanReciepient"
                  >
                    <Form.Label>
                      Name of the Loan recipient
                      {dependentDetails.anyLoanReceivedFromRehabilitationAuthority ? (
                        <span className="required-field-astrix">*</span>
                      ) : (
                        ''
                      )}
                    </Form.Label>
                    <Form.Control
                      isInvalid={
                        !!rehabilitationErrors.nameOfTheLoanRecipientError
                      }
                      type="text"
                      placeholder="Enter Name of the Loan recipient"
                      onBlur={() => {
                        const newErrors = produce(
                          rehabilitationErrors,
                          (draft) => {
                            draft.nameOfTheLoanRecipientError = validateForm({
                              type: 'text',
                              value: dependentDetails.nameOfTheLoanRecipient,
                              section: 'Name of the Loan recipient',
                              maxLength: 50,
                            } as ValidationObjectModel)
                          }
                        )
                        setRehabilitationErrors(newErrors)
                      }}
                      onChange={(e) => {
                        const newDependentDetails = produce(
                          dependentDetails,
                          (draft: DependentAndRehabilitationDetailsModel) => {
                            draft.nameOfTheLoanRecipient = e.target.value
                          }
                        )
                        setDependentDetails(newDependentDetails)
                        const clearError = produce(
                          rehabilitationErrors,
                          (draft) => {
                            draft.nameOfTheLoanRecipientError = ''
                          }
                        )
                        setRehabilitationErrors(clearError)
                      }}
                      value={dependentDetails.nameOfTheLoanRecipient}
                    />
                    <InvalidFeedback
                      message={rehabilitationErrors.nameOfTheLoanRecipientError}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="formRelationshipToApplicant"
                  >
                    <Form.Label>
                      Relationship to the Applicant{' '}
                      {dependentDetails.anyLoanReceivedFromRehabilitationAuthority ? (
                        <span className="required-field-astrix">*</span>
                      ) : (
                        ''
                      )}
                    </Form.Label>
                    <Form.Control
                      isInvalid={
                        !!rehabilitationErrors.relationshipToApplicantError
                      }
                      className="form-select"
                      as="select"
                      value={dependentDetails.relationshipToApplicant}
                      onBlur={() => {
                        const newErrors = produce(
                          rehabilitationErrors,
                          (draft) => {
                            draft.relationshipToApplicantError = validateForm({
                              type: 'text',
                              value: dependentDetails.relationshipToApplicant,
                              section: 'Relationship to the Applicant',
                              maxLength: 20,
                            } as ValidationObjectModel)
                          }
                        )
                        setRehabilitationErrors(newErrors)
                      }}
                      onChange={(e) => {
                        const newDependentDetails = produce(
                          dependentDetails,
                          (draft: DependentAndRehabilitationDetailsModel) => {
                            draft.relationshipToApplicant = e.target.value
                          }
                        )
                        setDependentDetails(newDependentDetails)
                        const clearError = produce(
                          rehabilitationErrors,
                          (draft) => {
                            draft.relationshipToApplicantError = ''
                          }
                        )
                        setRehabilitationErrors(clearError)
                      }}
                    >
                      {dependentDetails.relationshipToApplicant === '' ||
                      dependentDetails.relationshipToApplicant === undefined ? (
                        <option value="">--Select Relationship--</option>
                      ) : (
                        ''
                      )}
                      {relationshipList.map(
                        (
                          title: { name: string; id: number },
                          index: number
                        ) => {
                          return (
                            <option key={`country-${index}`} value={title.name}>
                              {title.name}
                            </option>
                          )
                        }
                      )}
                    </Form.Control>
                    <InvalidFeedback
                      message={
                        rehabilitationErrors.relationshipToApplicantError
                      }
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formObtainLoan">
                    <Form.Label>
                      Obtained Loan Amount (Rs.){' '}
                      {dependentDetails.anyLoanReceivedFromRehabilitationAuthority ? (
                        <span className="required-field-astrix">*</span>
                      ) : (
                        ''
                      )}
                    </Form.Label>
                    <PriceInputComponent
                      isInvalid={!!rehabilitationErrors.obtainedLoanAmountError}
                      value={dependentDetails.obtainedLoanAmount}
                      placeholder="Enter Obtained Loan Amount (Rs.)"
                      onChange={(price: string) => {
                        const newDependentDetails = produce(
                          dependentDetails,
                          (draft: DependentAndRehabilitationDetailsModel) => {
                            draft.obtainedLoanAmount = parseFloat(price)
                          }
                        )
                        setDependentDetails(newDependentDetails)
                        const newErrors = produce(
                          rehabilitationErrors,
                          (draft) => {
                            draft.obtainedLoanAmountError = validateForm({
                              type: 'number',
                              value: parseFloat(price),
                              section: 'Obtained Loan Amount (Rs.)',
                              maxLength: 9,
                              minValue: 1,
                            } as ValidationObjectModel)
                          }
                        )
                        setRehabilitationErrors(newErrors)
                      }}
                      onErrorHandle={(price: string) => {
                        if (!price) {
                          const newDependentDetails = produce(
                            dependentDetails,
                            (draft: DependentAndRehabilitationDetailsModel) => {
                              draft.obtainedLoanAmount = 0
                            }
                          )
                          setDependentDetails(newDependentDetails)
                        } else {
                          const clearError = produce(
                            rehabilitationErrors,
                            (draft) => {
                              draft.obtainedLoanAmountError = ''
                            }
                          )
                          setRehabilitationErrors(clearError)
                        }
                      }}
                    />
                    <InvalidFeedback
                      message={rehabilitationErrors.obtainedLoanAmountError}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formInterestRate">
                    <Form.Label>
                      Interest Rate{' '}
                      {dependentDetails.anyLoanReceivedFromRehabilitationAuthority ? (
                        <span className="required-field-astrix">*</span>
                      ) : (
                        ''
                      )}
                    </Form.Label>
                    <PriceInputComponent
                      isInvalid={!!rehabilitationErrors.interestRateError}
                      value={dependentDetails.interestRate}
                      placeholder="Enter Interest Rate"
                      onChange={(price: string) => {
                        const newDependentDetails = produce(
                          dependentDetails,
                          (draft: DependentAndRehabilitationDetailsModel) => {
                            draft.interestRate = parseFloat(price)
                          }
                        )
                        setDependentDetails(newDependentDetails)
                        const newErrors = produce(
                          rehabilitationErrors,
                          (draft) => {
                            draft.interestRateError = validateForm({
                              type: 'number',
                              value: parseFloat(price),
                              section: 'Obtained Loan Amount (Rs.)',
                              maxLength: 3,
                              minValue: 1,
                            } as ValidationObjectModel)
                          }
                        )
                        setRehabilitationErrors(newErrors)
                      }}
                      onErrorHandle={(price: string) => {
                        if (!price) {
                          const newDependentDetails = produce(
                            dependentDetails,
                            (draft: DependentAndRehabilitationDetailsModel) => {
                              draft.interestRate = 0
                            }
                          )
                          setDependentDetails(newDependentDetails)
                        } else {
                          const clearError = produce(
                            rehabilitationErrors,
                            (draft) => {
                              draft.interestRateError = ''
                            }
                          )
                          setRehabilitationErrors(clearError)
                        }
                      }}
                    />
                    <InvalidFeedback
                      message={rehabilitationErrors.interestRateError}
                    />
                  </Form.Group>
                </Col>
                <Col sm={12} className="border-label mt-5">
                  <div className="border-label-span">Other Details</div>
                  <Form.Group className="mb-3" controlId="formOtherDetails">
                    <Form.Label>Any Other Details</Form.Label>
                    <Form.Control
                      isInvalid={!!rehabilitationErrors.otherDetailsError}
                      type="text"
                      placeholder="Enter Reason"
                      onBlur={() => {
                        const newErrors = produce(
                          rehabilitationErrors,
                          (draft) => {
                            draft.otherDetailsError = validateForm({
                              type: 'text',
                              value: dependentDetails.otherDetails,
                              section: 'Any Other Details',
                              maxLength: 200,
                            } as ValidationObjectModel)
                          }
                        )
                        setRehabilitationErrors(newErrors)
                      }}
                      onChange={(e) => {
                        const newDependentDetails = produce(
                          dependentDetails,
                          (draft: DependentAndRehabilitationDetailsModel) => {
                            draft.otherDetails = e.target.value
                          }
                        )
                        setDependentDetails(newDependentDetails)
                        const clearError = produce(
                          rehabilitationErrors,
                          (draft) => {
                            draft.otherDetailsError = ''
                          }
                        )
                        setRehabilitationErrors(clearError)
                      }}
                      value={dependentDetails.otherDetails}
                    />
                    <InvalidFeedback
                      message={rehabilitationErrors.otherDetailsError}
                    />
                  </Form.Group>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formExpectedLoan">
                      <Form.Label>
                        Expected Loan Amount (Rs.){' '}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <PriceInputComponent
                        isInvalid={
                          !!rehabilitationErrors.expectedLoanAmountError
                        }
                        value={dependentDetails.expectedLoanAmount}
                        placeholder="Enter Expected Loan Amount (Rs.)"
                        onChange={(price: string) => {
                          const newDependentDetails = produce(
                            dependentDetails,
                            (draft: DependentAndRehabilitationDetailsModel) => {
                              draft.expectedLoanAmount = parseFloat(price)
                            }
                          )
                          setDependentDetails(newDependentDetails)
                          const newErrors = produce(
                            rehabilitationErrors,
                            (draft) => {
                              draft.expectedLoanAmountError = validateForm({
                                type: 'number',
                                value: parseFloat(price),
                                section: 'Expected Loan Amount (Rs.)',
                                maxLength: 9,
                                minValue: 1,
                              } as ValidationObjectModel)
                            }
                          )
                          setRehabilitationErrors(newErrors)
                        }}
                        onErrorHandle={(price: string) => {
                          if (!price) {
                            const newDependentDetails = produce(
                              dependentDetails,
                              (
                                draft: DependentAndRehabilitationDetailsModel
                              ) => {
                                draft.expectedLoanAmount = 0
                              }
                            )
                            setDependentDetails(newDependentDetails)
                          } else {
                            const clearError = produce(
                              rehabilitationErrors,
                              (draft) => {
                                draft.expectedLoanAmountError = ''
                              }
                            )
                            setRehabilitationErrors(clearError)
                          }
                        }}
                      />
                      <InvalidFeedback
                        message={rehabilitationErrors.expectedLoanAmountError}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGrantedLoan">
                      <Form.Label>
                        Approved Loan Amount (Rs.){' '}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <PriceInputComponent
                        isInvalid={
                          !!rehabilitationErrors.grantedLoanAmountError
                        }
                        value={dependentDetails.grantedLoanAmount}
                        placeholder="Enter Approved Loan Amount (Rs.)"
                        onChange={(price: string) => {
                          const newDependentDetails = produce(
                            dependentDetails,
                            (draft: DependentAndRehabilitationDetailsModel) => {
                              draft.grantedLoanAmount = parseFloat(price)
                            }
                          )
                          setDependentDetails(newDependentDetails)
                          const newErrors = produce(
                            rehabilitationErrors,
                            (draft) => {
                              draft.grantedLoanAmountError = validateForm({
                                type: 'number',
                                value: parseFloat(price),
                                section: 'Approved Loan Amount (Rs.)',
                                maxLength: 9,
                                minValue: 1,
                              } as ValidationObjectModel)
                            }
                          )
                          setRehabilitationErrors(newErrors)
                        }}
                        onErrorHandle={(price: string) => {
                          if (!price) {
                            const newDependentDetails = produce(
                              dependentDetails,
                              (
                                draft: DependentAndRehabilitationDetailsModel
                              ) => {
                                draft.grantedLoanAmount = 0
                              }
                            )
                            setDependentDetails(newDependentDetails)
                          } else {
                            const clearError = produce(
                              rehabilitationErrors,
                              (draft) => {
                                draft.grantedLoanAmountError = ''
                              }
                            )
                            setRehabilitationErrors(clearError)
                          }
                        }}
                      />
                      <InvalidFeedback
                        message={rehabilitationErrors.grantedLoanAmountError}
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formRepaymentPeriod">
                      <Form.Label>
                        Repayment Period (in months){' '}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Form.Control
                        isInvalid={!!rehabilitationErrors.repaymentPeriodError}
                        type="number"
                        min={0}
                        placeholder="Enter Repayment Period (in months)"
                        onBlur={() => {
                          const newErrors = produce(
                            rehabilitationErrors,
                            (draft) => {
                              draft.repaymentPeriodError = validateForm({
                                type: 'number',
                                value: dependentDetails.repaymentPeriod,
                                section: 'Repayment Period (in months)',
                                maxLength: 3,
                              } as ValidationObjectModel)
                            }
                          )
                          setRehabilitationErrors(newErrors)
                        }}
                        onChange={(e) => {
                          const newDependentDetails = produce(
                            dependentDetails,
                            (draft: DependentAndRehabilitationDetailsModel) => {
                              draft.repaymentPeriod = parseInt(e.target.value)
                            }
                          )
                          setDependentDetails(newDependentDetails)
                          const clearError = produce(
                            rehabilitationErrors,
                            (draft) => {
                              draft.repaymentPeriodError = ''
                            }
                          )
                          setRehabilitationErrors(clearError)
                        }}
                        value={dependentDetails.repaymentPeriod}
                      />
                      <InvalidFeedback
                        message={rehabilitationErrors.repaymentPeriodError}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGracePeriod">
                      <Form.Label>
                        Grace Period (in months){' '}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Form.Control
                        isInvalid={!!rehabilitationErrors.gracePeriodError}
                        type="number"
                        min={0}
                        placeholder="Enter Grace Period (in months)"
                        onBlur={() => {
                          const newErrors = produce(
                            rehabilitationErrors,
                            (draft) => {
                              draft.gracePeriodError = validateForm({
                                type: 'number',
                                value: dependentDetails.gracePeriod,
                                section: 'Grace Period (in months)',
                                maxLength: 3,
                              } as ValidationObjectModel)
                            }
                          )
                          setRehabilitationErrors(newErrors)
                        }}
                        onChange={(e) => {
                          const newDependentDetails = produce(
                            dependentDetails,
                            (draft: DependentAndRehabilitationDetailsModel) => {
                              draft.gracePeriod = parseInt(e.target.value)
                            }
                          )
                          setDependentDetails(newDependentDetails)
                          const clearError = produce(
                            rehabilitationErrors,
                            (draft) => {
                              draft.gracePeriodError = ''
                            }
                          )
                          setRehabilitationErrors(clearError)
                        }}
                        value={dependentDetails.gracePeriod}
                      />
                      <InvalidFeedback
                        message={rehabilitationErrors.gracePeriodError}
                      />
                    </Form.Group>
                  </Row>
                </Col>
                <Col sm={12} className="border-label mt-5">
                  <div className="border-label-span">{`Bank Details`}</div>
                  <Row>
                    <Form.Group as={Col} controlId="formBankName">
                      <Form.Label>
                        {`Bank Name`}{' '}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Form.Control
                        isInvalid={!!rehabilitationErrors.bankIdError}
                        className="form-select"
                        as="select"
                        value={dependentDetails.bankId}
                        onBlur={() => {
                          const newErrors = produce(
                            rehabilitationErrors,
                            (draft) => {
                              draft.bankIdError = validateForm({
                                type: 'number',
                                value: dependentDetails.bankId,
                                section: 'Bank Name',
                              } as ValidationObjectModel)
                            }
                          )
                          setRehabilitationErrors(newErrors)
                        }}
                        onChange={(e) => {
                          const newDependentDetails = produce(
                            dependentDetails,
                            (draft: DependentAndRehabilitationDetailsModel) => {
                              draft.bankId = parseInt(e.target.value)
                              draft.branchId = 0
                            }
                          )
                          getBranchesList(parseInt(e.target.value))
                          setDependentDetails(newDependentDetails)
                          const clearError = produce(
                            rehabilitationErrors,
                            (draft) => {
                              draft.bankIdError = ''
                            }
                          )
                          setRehabilitationErrors(clearError)
                        }}
                      >
                        {dependentDetails.bankId == null ||
                        dependentDetails.bankId == 0 ? (
                          <option value="0">--Select Bank--</option>
                        ) : (
                          ''
                        )}
                        {bankList.map(
                          (
                            bank: { name: string; id: number },
                            index: number
                          ) => {
                            return (
                              <option key={`bank-${index}`} value={bank.id}>
                                {bank.name}
                              </option>
                            )
                          }
                        )}
                      </Form.Control>
                      <InvalidFeedback
                        message={rehabilitationErrors.bankIdError}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formBranchkName">
                      <Form.Label>
                        {`Branch Name`}{' '}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Form.Control
                        isInvalid={!!rehabilitationErrors.branchIdError}
                        className="form-select"
                        as="select"
                        disabled={
                          dependentDetails.bankId == null ||
                          dependentDetails.bankId == 0
                        }
                        value={dependentDetails.branchId}
                        onBlur={() => {
                          const newErrors = produce(
                            rehabilitationErrors,
                            (draft) => {
                              draft.branchIdError = validateForm({
                                type: 'text',
                                value: dependentDetails.branchId,
                                section: 'Branch Name',
                              } as ValidationObjectModel)
                            }
                          )
                          setRehabilitationErrors(newErrors)
                        }}
                        onChange={(e) => {
                          const newDependentDetails = produce(
                            dependentDetails,
                            (draft: DependentAndRehabilitationDetailsModel) => {
                              draft.branchId = parseInt(e.target.value)
                            }
                          )
                          setDependentDetails(newDependentDetails)
                          const clearError = produce(
                            rehabilitationErrors,
                            (draft) => {
                              draft.branchIdError = ''
                            }
                          )
                          setRehabilitationErrors(clearError)
                        }}
                      >
                        {dependentDetails.branchId == null ||
                        dependentDetails.branchId == 0 ? (
                          <option value="0">--Select Branch--</option>
                        ) : (
                          ''
                        )}
                        {branchList.map(
                          (
                            branch: { name: string; id: number },
                            index: number
                          ) => {
                            return (
                              <option key={`branch-${index}`} value={branch.id}>
                                {branch.name}
                              </option>
                            )
                          }
                        )}
                      </Form.Control>
                      <InvalidFeedback
                        message={rehabilitationErrors.branchIdError}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formAccountNumber">
                      <Form.Label>
                        Account Number{' '}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Form.Control
                        isInvalid={!!rehabilitationErrors.accountNumbergError}
                        type="text"
                        placeholder="Enter Account Number"
                        onBlur={() => {
                          const newErrors = produce(
                            rehabilitationErrors,
                            (draft) => {
                              draft.accountNumbergError = validateForm({
                                type: 'text',
                                value: dependentDetails.accountNumber,
                                section: 'Account Number',
                                maxLength: 20,
                              } as ValidationObjectModel)
                            }
                          )
                          setRehabilitationErrors(newErrors)
                        }}
                        onChange={(e) => {
                          const newDependentDetails = produce(
                            dependentDetails,
                            (draft: DependentAndRehabilitationDetailsModel) => {
                              draft.accountNumber = e.target.value
                            }
                          )
                          setDependentDetails(newDependentDetails)
                          const clearError = produce(
                            rehabilitationErrors,
                            (draft) => {
                              draft.accountNumbergError = ''
                            }
                          )
                          setRehabilitationErrors(clearError)
                        }}
                        value={dependentDetails.accountNumber}
                      />
                      <InvalidFeedback
                        message={rehabilitationErrors.accountNumbergError}
                      />
                    </Form.Group>
                  </Row>
                </Col>
              </Form>
            </Col>
            <Col sm={12} className="d-flex justify-content-end mt-4">
              {checkStatsDraftOrModifyDraft(fileRegistrationObject.status) &&
                checkLoggedUser(
                  user.epfNumber,
                  fileRegistrationObject.allocateTo
                ) && (
                  <>
                    <button
                      className="save-button custom-margin-right"
                      onClick={onClickNext}
                    >
                      Next
                    </button>
                    <button
                      className="save-button custom-margin-right"
                      onClick={onClickSave}
                    >
                      Save
                    </button>
                  </>
                )}
              <button className="cancel-button" onClick={onOk}>
                Ok
              </button>
            </Col>
            {showConfirm.isShow && (
              <ConfirmationModal
                name={'Confirmation'}
                message={showConfirm.message}
                onCancel={() => {
                  setShowConfirm({
                    isShow: false,
                    message: '',
                    section: '',
                  })
                }}
                isRemark={false}
                onConfirm={handleConfirm}
              />
            )}
          </>
        )}
      </>
    )
  }

export default DependentAndRehabilitationSection
