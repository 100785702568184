import { FileRegistrationModel } from '../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { FilterModel } from '../../../models/utils/filterModel'
import apiService from '../../api/apiManager'

class SeDataService {
  async apiFetchSeList(filterData: FilterModel) {
    return apiService.apiPost(
      `/compensation-approval/loan/se/view/list`,
      filterData
    )
  }

  async apiFetchSeListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/compensation-approval/loan/se/view/count`,
      filterData
    )
  }

  async apiFetchSeReturnList(filterData: FilterModel) {
    return apiService.apiPost(
      `/compensation-approval/loan/se/file-return/view/list`,
      filterData
    )
  }

  async apiFetchSeReturnListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/compensation-approval/loan/se/file-return/view/count`,
      filterData
    )
  }
  async apiApproveSeFile(id: number) {
    return apiService.apiPutCommon(
      `/compensation-approval/loan/se/${id}/approve`,
      {}
    )
  }
  async apiRejectSeFile(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/compensation-approval/loan/se/${id}/reject`,
      { remark: remark }
    )
  }
  async apiReturnSeFile(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/compensation-approval/loan/se/${id}/return`,
      { remark: remark }
    )
  }
  async apiUpdateSeFile(id: number, file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/compensation-approval/loan/se/file-return/${id}`,
      file
    )
  }
  async apiGetReturnSeFile(id: number) {
    return apiService.apiGet(
      `/compensation-approval/loan/se/file-return/view/${id}`
    )
  }
  async apiGetSeFile(id: number) {
    return apiService.apiGet(`/compensation-approval/loan/se/view/${id}`)
  }
}

export default new SeDataService()
