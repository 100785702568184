import produce from 'immer'
import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import {
  useHistory,
  useRouteMatch,
  useLocation,
  Switch,
  Route,
} from 'react-router-dom'
import ActionButtonComponent from '../../../components/common/actionButton/ActionButtonComponent'
import IterminalTableComponent from '../../../components/table/IterminalTableComponent'
import { ActionListModal } from '../../../models/package/actionListModal'
import { FilterModel } from '../../../models/utils/filterModel'
import { FilterOptionModel } from '../../../models/utils/filterOption'
import { filtersSetRequest } from '../../../store/filter/actions'
import { getFilters } from '../../../store/filter/selectors'
import { getUserSubPackageActions } from '../../../store/user/selectors'
import { roleFilters } from '../../../utils/metaData'
import { getPageCount } from '../../../utils/tableFunctions'
import CreateRolesAndPermissionsComponent from './CreateRolesAndPermissionsComponent'
import ModifyRolePermissions from './ModifyRolePermissions'
import ViewRoleComponent from './ViewRoleComponent'
import UserManagementDataService from '../../../services/userManagementService'
import RoleFilter from '../../../components/filters/RoleFilter'
import {
  defaultPageLimit,
  defaultOrderListLimit,
} from '../../../utils/constants'

const RolesAndPermissionsComponent = () => {
  const [state, setState] = useState([])
  const history = useHistory()
  const { path } = useRouteMatch()
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  const filterOption = {
    descending: true,
    limit: defaultPageLimit,
    orderFields: defaultOrderListLimit,
  } as FilterModel
  const [filter, setFilter] = useState(filterOption)
  const [pageCount, setPageCount] = useState(0)
  const [dataCount, setDatacount] = useState(0)
  const filters = useSelector(getFilters)
  const dispatch = useDispatch()

  const actionList = useSelector(
    getUserSubPackageActions('user-management', 'role-and-permission')
  )

  const checkPermission = (permissionName: string) => {
    const filterdAction = actionList.filter((action: ActionListModal) => {
      if (action.action.name === permissionName) {
        return action
      }
    })[0]
    if (filterdAction != null) {
      return true
    } else {
      return false
    }
  }
  useEffect(() => {
    dispatch(filtersSetRequest({ filters: roleFilters }))
    async function getIncidentTypesData() {
      await retriveDataCountAndData(filter)
      setLoading(false)
    }
    getIncidentTypesData()
  }, [location.pathname])

  async function retriveDataCountAndData(filterData: FilterModel) {
    const roleDataCountData = await UserManagementDataService.apiGetRolesCount(
      filterData
    )
    setDatacount(roleDataCountData.data.count)
    setPageCount(getPageCount(roleDataCountData.data.count, filterOption.limit))
    const roleData = await UserManagementDataService.apiGetRoles(filterData)
    setState(roleData.data)
  }

  const retriveFilterData = async (selectedFilters: FilterOptionModel[]) => {
    console.log(selectedFilters)
    setLoading(true)
    const newFilter = produce(filter, (draft) => {
      draft.page = 1
      draft.filterData = selectedFilters
    })
    setFilter(newFilter)
    await retriveDataCountAndData(newFilter)
    setLoading(false)
  }

  const retriveData = async (pageDetails: any) => {
    setLoading(true)
    const newFilter = produce(filter, (draft) => {
      draft.page = pageDetails.pageIndex + 1
    })
    setFilter(newFilter)

    const roleData = await UserManagementDataService.apiGetRoles(newFilter)
    setState(roleData.data)
    setLoading(false)
  }
  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name', // accessor is the "key" in the data
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Created Date/Time',
        accessor: 'createdDateTime', // accessor is the "key" in the data
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
    ],
    []
  )
  const handleView = (id: number) => {
    history.push(`${path}/view/${id}`)
  }
  return (
    <>
      <Row className="title-layout">
        <Col className="d-flex justify-content-start">
          <span className="m-2">User Roles</span>
        </Col>
      </Row>

      <Switch>
        <Route path={`${path}/`} exact>
          <Row className="incident-types-body-layout mt-4">
            <Col sm={12} className="mb-4">
              <Row>
                <Col sm={6}>
                  {checkPermission('view') === true && !loading && (
                    <RoleFilter
                      filterData={filters}
                      onFilter={(e: FilterOptionModel[]) => {
                        dispatch(filtersSetRequest({ filters: e }))
                        retriveFilterData(
                          e.filter(
                            (filter: FilterOptionModel) =>
                              filter.selected === true
                          )
                        )
                      }}
                    />
                  )}
                </Col>
                <Col sm={6} className="d-flex justify-content-end">
                  {checkPermission('add') === true && (
                    <ActionButtonComponent name="Add" routeURL="add" />
                  )}
                </Col>
              </Row>
            </Col>
            <Col sm={12}>
              <IterminalTableComponent
                columns={columns}
                data={state}
                fetchData={retriveData}
                loading={loading}
                pageCount={pageCount}
                totalCount={dataCount}
                onClickRow={(row: any) => {
                  console.log(row)
                  handleView(row.id)
                }}
              />
            </Col>
          </Row>
        </Route>
        <Route
          path={`${path}/add`}
          component={CreateRolesAndPermissionsComponent}
        />
        <Route path={`${path}/view/:id`} component={ViewRoleComponent} />
        <Route path={`${path}/modify/:id`} component={ModifyRolePermissions} />
      </Switch>
    </>
  )
}

export default RolesAndPermissionsComponent
