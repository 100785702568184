import { FileRegistrationModel } from '../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { FilterModel } from '../../../models/utils/filterModel'
import apiService from '../../api/apiManager'

class AhDataService {
  async apiFetchAhList(filterData: FilterModel) {
    return apiService.apiPost(
      `/compensation-approval/loan/ah/view/list`,
      filterData
    )
  }

  async apiFetchAhListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/compensation-approval/loan/ah/view/count`,
      filterData
    )
  }

  async apiFetchAhReturnList(filterData: FilterModel) {
    return apiService.apiPost(
      `/compensation-approval/loan/ah/file-return/view/list`,
      filterData
    )
  }

  async apiFetchAhReturnListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/compensation-approval/loan/ah/file-return/view/count`,
      filterData
    )
  }
  async apiApproveAhFile(id: number) {
    return apiService.apiPutCommon(
      `/compensation-approval/loan/ah/${id}/approve`,
      {}
    )
  }
  async apiRejectAhFile(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/compensation-approval/loan/ah/${id}/reject`,
      { remark: remark }
    )
  }
  async apiReturnAhFile(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/compensation-approval/loan/ah/${id}/return`,
      { remark: remark }
    )
  }
  async apiUpdateAhFile(id: number, file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/compensation-approval/loan/ah/file-return/${id}`,
      file
    )
  }
  async apiGetReturnAhFile(id: number) {
    return apiService.apiGet(
      `/compensation-approval/loan/ah/file-return/view/${id}`
    )
  }
  async apiGetAhFile(id: number) {
    return apiService.apiGet(`/compensation-approval/loan/ah/view/${id}`)
  }
}

export default new AhDataService()
