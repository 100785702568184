import { CommonFilterModel } from '../../models/utils/commonFilterModel'

import apiService from '../api/apiManager'
class ApplicationModificationService {
  async apiGetFiles(filterData: CommonFilterModel) {
    return apiService.apiPost(
      `/application-modification/application_file/view/list`,
      filterData
    )
  }
  async apiGetFilesCount(filterData: CommonFilterModel) {
    return apiService.apiPost(
      `/application-modification/application_file/view/count`,
      filterData
    )
  }

  async apiUpdateBasicDetails(data: any) {
    return apiService.apiPutCommon(
      `/application-modification/update-basic-applicant-details`,
      data
    )
  }

  async apiUpdateAggrievedPerson(scheme: string, data: any) {
    return apiService.apiPutCommon(
      `/application-modification/update/${scheme}/aggrieved-person`,
      data
    )
  }

  async apiDeleteFile(data: any) {
    return apiService.apiPost(
      `/application-modification/application-file/delete`,
      data
    )
  }

  async apiUpdateDependent(data: any) {
    return apiService.apiPutCommon(
      `/application-modification/update/dependent-details`,
      data
    )
  }

  async apiDeleteSupportingDocument(id: any) {
    return apiService.apiDelete(
      `/application-modification/delete-supporting-document`,
      id
    )
  }

  async apiUpdateSupportingDocument(id: number, data: any) {
    return apiService.apiPutFormData(
      `/application-modification/update-supporting-document/${id}`,
      data
    )
  }

  async apiUploadInstallmentSupportDocument(
    fileId: number,
    merge_installment_number: number,
    formData: FormData
  ) {
    return apiService.apiPutFormData(
      `/application-modification/update/${fileId}/installment/${merge_installment_number}/document`,
      formData
    )
  }

  async apiUpdateChequeDetails(data: any) {
    return apiService.apiPutCommon(
      `/application-modification/update/cheque-details`,
      data
    )
  }

  async reallocate(id: number, epfNumber: string) {
    return apiService.apiPutCommon(
      `/application-modification/${id}/re-allocate/${epfNumber}`,
      {}
    )
  }

  async apiRlpAddBoardOfTrustee(formData: FormData, id: number) {
    return apiService.apiPutFormData(
      `/application-modification/update/rlp/${id}/board_of_trustee_and_bank`,
      formData
    )
  }

  async apiUpdateAffectedProperty(data: any, scheme: string) {
    return apiService.apiPutCommon(
      `/application-modification/update/${scheme.toLocaleLowerCase()}/affected-property`,
      data
    )
  }
}
export default new ApplicationModificationService()
