import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { useRouteMatch, useLocation, useHistory } from 'react-router-dom'
import ReactTableWithPaginationComponent from '../../../components/table/ReactTableWithPaginationComponent'
import metaDataService from '../../../services/metaDataService'

const ProvinceList = () => {
  const { path } = useRouteMatch()
  const [provinceList, setProvinceList] = React.useState([])
  const location = useLocation()
  const history = useHistory()

  React.useEffect(() => {
    getProvinceList()
  }, [location.pathname])

  const getProvinceList = async () => {
    const result = await metaDataService.apiProvincesFetch()
    if (result.status == 200) {
      setProvinceList(result.data)
    }
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Code',
        accessor: 'code', // accessor is the "key" in the data
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
    ],
    []
  )
  const handleView = (id: string) => {
    history.push(`${path}province/${id}/district/list`)
  }

  return (
    <>
      <Row className="title-layout">
        <Col className="d-flex justify-content-start">
          {/* <img src={incident_type} alt="" /> */}
          <span className="m-2">Provinces</span>
        </Col>
      </Row>

      <Row className="incident-types-body-layout mt-4">
        <Col className="mt-2">
          <ReactTableWithPaginationComponent
            hover
            columns={columns}
            data={provinceList ? provinceList : []}
            onClickRow={(row: any) => {
              handleView(row.id)
            }}
          />
        </Col>
      </Row>
    </>
  )
}

export default ProvinceList
