import React, { FC } from 'react'
import { Col, Form, Row } from 'react-bootstrap'

export interface IdentificationTypesComponentProps {
  isDisabled: boolean
  onChange?: any
  identificationType?: string
}

const IdentificationTypesComponent: FC<IdentificationTypesComponentProps> = ({
  isDisabled,
  onChange,
  identificationType,
}) => {
  console.log(identificationType)
  return (
    <Row>
      <Col>
        <Form.Check
          disabled={isDisabled}
          inline
          label="Birth Certificate"
          name="formSelection"
          type="radio"
          id={`inline-selection-1`}
          checked={identificationType === 'BIRTH_CERTIFICATE'}
          onChange={() => {
            onChange('BIRTH_CERTIFICATE')
          }}
        />
      </Col>
      <Col>
        <Form.Check
          disabled={isDisabled}
          inline
          label="NIC"
          name="formSelection"
          type="radio"
          id={`inline-selection-2`}
          checked={identificationType === 'NIC'}
          onChange={() => {
            onChange('NIC')
          }}
        />
      </Col>
      <Col>
        <Form.Check
          disabled={isDisabled}
          inline
          label="Passport"
          name="formSelection"
          type="radio"
          id={`inline-selection-3`}
          checked={identificationType === 'PASSPORT'}
          onChange={() => {
            onChange('PASSPORT')
          }}
        />
      </Col>
      <Col>
        <Form.Check
          disabled={isDisabled}
          inline
          name="formSelection"
          label="Senior Citizen Id"
          type="radio"
          id={`inline-selection-4`}
          checked={identificationType === 'SENIOR_CITIZEN_ID'}
          onChange={() => {
            onChange('SENIOR_CITIZEN_ID')
          }}
        />
      </Col>
      <Col>
        <Form.Check
          disabled={isDisabled}
          inline
          name="formSelection"
          label="TIC Number"
          type="radio"
          id={`inline-selection-5`}
          checked={identificationType === 'TIC'}
          onChange={() => {
            onChange('TIC')
          }}
        />
      </Col>
      <Col>
        <Form.Check
          disabled={isDisabled}
          inline
          name="formSelection"
          label="Driving License"
          type="radio"
          id={`inline-selection-6`}
          checked={identificationType === 'DRIVING_LICENSE'}
          onChange={() => {
            onChange('DRIVING_LICENSE')
          }}
        />
      </Col>
    </Row>
  )
}

export default IdentificationTypesComponent
