import produce from 'immer'
import React, { FC, useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import ReactDatePicker from 'react-datepicker'
import { useDispatch, useSelector } from 'react-redux'
import LoadingComponent from '../../../../components/common/loading/LoadingComponent'
import ConfirmationModal from '../../../../components/modals/ConfirmationModal'
import SupportingDocument from '../../../../components/supporting/SupportingDocument'
import { FileSupportingDocument } from '../../../../models/applicationRegistration/compensation/FileSupportingDocument'
import { ReminderModel } from '../../../../models/applicationRegistration/compensation/reminderModel'
import { SupportingDocumentDataModel } from '../../../../models/applicationRegistration/compensation/supportingDocument'
import { FileRegistrationModel } from '../../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { SupportingDocumentsModel } from '../../../../models/systemConfiguration/supportingDocuments/supportingDocumentsModel'
import { ResponseObject } from '../../../../services/api/apiManager'
import common from '../../../../services/applicationRegistration/common'
import gpcService from '../../../../services/applicationRegistration/compensation/gpcService'
import gscService from '../../../../services/applicationRegistration/compensation/gscService'
import gsdService from '../../../../services/applicationRegistration/compensation/gsdService'
import gsiService from '../../../../services/applicationRegistration/compensation/gsiService'
import pdService from '../../../../services/applicationRegistration/compensation/pdService'
import mpService from '../../../../services/applicationRegistration/compensation/mpService'
import piService from '../../../../services/applicationRegistration/compensation/piService'
import rlpService from '../../../../services/applicationRegistration/compensation/rlpService'
import ahService from '../../../../services/applicationRegistration/loan/ahService'
import seService from '../../../../services/applicationRegistration/loan/seService'
import srtService from '../../../../services/applicationRegistration/loan/srtService'
import metaDataService from '../../../../services/metaDataService'
import { changeLoadingRequest } from '../../../../store/app/actionTypes'
import {
  checkApplicationRegistrationButtonEnable,
  checkLoggedUser,
  checkStatsDraftOrModifyDraft,
  FetchRequesthandleNotification,
  handleNotification,
} from '../../../../utils/utilFunctions'
import { saveAs } from 'file-saver'
import { InstallmentModel } from '../../../../models/applicationRegistration/compensation/installmentModel'
import InstallmentDocument from '../../../../components/supporting/InstallmentDocument'
import ReminderPrintModal, {
  ReminderPrintModel,
} from '../../../../components/modals/ReminderPrintModal'
import ApplicationCloseReopenModal from '../../../../components/modals/ApplicationCloseReOpenModal'
import { useHistory } from 'react-router-dom'
import ClosePrintModal from '../../../../components/modals/ClosePrintModal'
import { ClosePrintModel } from '../../../../models/utils/closePrintModel'
import {
  ALERT_SUCCESS,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
  ALERT_WARNING,
} from '../../../../utils/constants'
import { showTotast } from '../../../../utils/toast'
import { getUser } from '../../../../store/user/selectors'
export interface SectionFiveProps {
  fileRegistrationObject: FileRegistrationModel
  onOk: any
  onSave: any
  onNext: any
}

interface ConfirmationModal {
  isShow: boolean
  message: string
  section: string
}

const SectionFive: FC<SectionFiveProps> = ({
  fileRegistrationObject,
  onOk,
  onSave,
  onNext,
}) => {
  const [showConfirm, setShowConfirm] = useState({
    isShow: false,
  } as ConfirmationModal)
  const [loading, setLoading] = useState(true)
  const [showPrintModal, setShowPrintModal] = useState(false)
  const [showClosePrintModal, setShowClosePrintModal] = useState(false)
  const [draftedReminders, setDraftedReminders] = useState(
    [] as ReminderModel[]
  )
  const [showCloseModal, setShowCloseModal] = useState(false)
  const [uploadFileList, setUploadFileList] = useState([] as File[])
  const [installmentList, setInstallmentList] = useState(
    [] as InstallmentModel[]
  )
  const [reminderObject, setReminderObject] = useState({
    applicationFileId: fileRegistrationObject.id,
  } as ReminderModel)
  const [supportingDocumentDetails, setSupportingDocumentDetails] = useState(
    {} as SupportingDocumentDataModel
  )

  const [showRemoveConfirm, setShowRemoveConfirm] = useState({
    isShow: false,
    message: '',
    fileToRemove: null as FileSupportingDocument | null, // Store the file to be removed
  })

  const confirmRemoveFile = async () => {
    if (showRemoveConfirm.fileToRemove) {
      await handleRemoveFile(showRemoveConfirm.fileToRemove)
      setShowRemoveConfirm({ isShow: false, message: '', fileToRemove: null })
    }
  }
  const history = useHistory()
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  useEffect(() => {
    async function getMetaData() {
      console.log(uploadFileList)
      const installmentData =
        await metaDataService.apiInstallmentListToSupportDocument(
          fileRegistrationObject.id
        )
      console.log(installmentData)
      if (installmentData.status === 200) {
        setInstallmentList(installmentData.data)
      }
      const supportingDocumentDataList =
        await metaDataService.getSupportingDocumentListByScheme(
          fileRegistrationObject.scheme
        )
      if (supportingDocumentDataList.status == 200) {
      }
      let supportingDocumentData = {} as ResponseObject
      supportingDocumentData = await getSupportDocumentDataByScheme()
      if (supportingDocumentData.status === 200) {
        setSupportingDocumentDetails(supportingDocumentData.data)
        const newReminder = produce(reminderObject, (draft: ReminderModel) => {
          draft.reminder = supportingDocumentData.data.reminderList.length + 1
        })
        setReminderObject(newReminder)

        const filterDraftedReminder =
          supportingDocumentData.data.reminderList.filter(
            (reminder: ReminderModel) => {
              return reminder.id
            }
          )
        setDraftedReminders(filterDraftedReminder)
      }

      setLoading(false)
    }
    getMetaData()
  }, [onSave])

  const renderRemarkTable = () => {
    return supportingDocumentDetails.reminderList.map(
      (reminder: ReminderModel, index: number) => {
        return (
          <tr key={`reminder-${reminder.id}`}>
            <td>{reminder.reminder}</td>
            <td>{new Date(reminder.sentDate * 1000).toLocaleDateString()}</td>
            <td>{reminder.remarks}</td>
            {checkStatsDraftOrModifyDraft(fileRegistrationObject.status) && (
              <td>
                <button
                  disabled={
                    index != supportingDocumentDetails.reminderList.length - 1
                  }
                  onClick={(action) => {
                    action.preventDefault()
                    action.stopPropagation()
                    handleDeleteReminderButton(reminder)
                  }}
                  className={`${
                    index == supportingDocumentDetails.reminderList.length - 1
                      ? 'save-button'
                      : 'disable-button'
                  } mt-2`}
                >
                  Remove
                </button>
              </td>
            )}
          </tr>
        )
      }
    )
  }

  const handleDeleteReminderButton = async (row: any) => {
    const newSupportingDocumentDetails = produce(
      supportingDocumentDetails,
      (draft: SupportingDocumentDataModel) => {
        draft.reminderList = draft.reminderList.filter(
          (reminder: ReminderModel) => {
            if (reminder.id !== row.id) return reminder
          }
        )
      }
    )
    dispatch(changeLoadingRequest())
    const response = await common.apiAddReminder(
      fileRegistrationObject.id,
      fileRegistrationObject.programType.toLowerCase(),
      fileRegistrationObject.scheme.toLowerCase(),
      newSupportingDocumentDetails.reminderList
    )

    // if (response.status === 200 || response.status === 204) {
    //   const newobject = produce(
    //     supportingDocumentDetails,
    //     (draft: SupportingDocumentDataModel) => {
    //       draft.reminderList = response.data
    //     }
    //   )
    //   setSupportingDocumentDetails(newobject)
    // }

    const newReminder = produce(reminderObject, (draft: ReminderModel) => {
      draft.reminder = draft.reminder - 1 < 0 ? 0 : draft.reminder - 1
      draft.remarks = ''
      draft.sentDate = null
    })
    setReminderObject(newReminder)
    handleNotification(response, 'Reminder removed successfully')
    dispatch(changeLoadingRequest())
  }

  const handleClose = async (object: any) => {
    console.log(object)
    dispatch(changeLoadingRequest())
    const response = await common.apiCloseApplication(
      fileRegistrationObject.id,
      fileRegistrationObject.programType.toLowerCase(),
      fileRegistrationObject.scheme.toLowerCase(),
      object.description,
      object.remark
    )
    handleNotification(response, 'Application Closed Succesfully')
    if (response.status === 200) {
      setShowClosePrintModal(true)
    }
    dispatch(changeLoadingRequest())
  }

  const handleClosePrint = async (printData: ClosePrintModel) => {
    if (printData.language == 'eng') {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }

      // ${process.env.baseUrl}application-registration/files/view/${fileRegistrationObject.id}/print-close-letter
      fetch(
        `${
          process.env.baseUrl
        }application-registration/${fileRegistrationObject.programType.toLowerCase()}/${fileRegistrationObject.scheme.toLowerCase()}/view/${
          fileRegistrationObject.id
        }/print-close-letter/${printData.type}`,
        requestOptions
      )
        .then(async (response) => {
          // window.open(URL.createObjectURL(await response.blob()))
          if (response.status === 200) {
            console.log(response)
            return response.blob()
          }
          return null
        })
        .then((data) => {
          if (data != null) {
            const fileName = `application_file_${fileRegistrationObject.id}_close_letter.${printData.type}`
            showTotast(
              ALERT_SUCCESS,
              'Letter generated Successfully',
              TOAST_POSITION_TOP_RIGHT,
              5,
              TOAST_TRANSITION_SLIDE
            )
            saveAs(data, fileName)
          } else {
            showTotast(
              ALERT_WARNING,
              'Letter generate UnSuccessfull',
              TOAST_POSITION_TOP_RIGHT,
              5,
              TOAST_TRANSITION_SLIDE
            )
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    } else {
      console.log(printData)
    }
    history.goBack()
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handlePrintReminder = (reminder: ReminderPrintModel) => {
    if (reminder.language == 'eng') {
      const bodyData = {
        language: reminder.language,
        reminder: reminder.reminder,
        supportingDocumentIds: reminder.supportingDocumentIds,
      }
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(bodyData),
      }
      fetch(
        `${
          process.env.baseUrl
        }application-registration/${fileRegistrationObject.programType.toLowerCase()}/${fileRegistrationObject.scheme.toLowerCase()}/view/${
          fileRegistrationObject.id
        }/print-reminder-letter/pdf`,
        requestOptions
      )
        .then(async (response) => {
          FetchRequesthandleNotification(
            response,
            'Reminder letter ready to print'
          )
          if (response.status === 200) {
            console.log(response)
            return response.blob()
          }
          return null
        })
        .then((response) => {
          if (response != null) {
            setShowConfirm({
              isShow: false,
              message: '',
              section: '',
            })
            const fileName = `reminder_${reminder.language}_${fileRegistrationObject.applicantName}.pdf`
            saveAs(response, fileName)
          }
        })
    } else {
      const bodyData = {
        language: reminder.language,
        reminder: reminder.reminder,
        supportingDocumentIds: reminder.supportingDocumentIds,
      }
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(bodyData),
      }
      fetch(
        `${
          process.env.baseUrl
        }application-registration/${fileRegistrationObject.programType.toLowerCase()}/${fileRegistrationObject.scheme.toLowerCase()}/view/${
          fileRegistrationObject.id
        }/print-reminder-letter/docx`,
        requestOptions
      )
        .then(async (response) => {
          FetchRequesthandleNotification(
            response,
            'Reminder letter ready to print'
          )
          if (response.status === 200) {
            console.log(response)
            return response.blob()
          }
          return null
        })
        .then((response) => {
          if (response != null) {
            setShowConfirm({
              isShow: false,
              message: '',
              section: '',
            })

            const fileName = `reminder_${reminder.language}_${fileRegistrationObject.applicantName}.docx`
            saveAs(response, fileName)
          }
        })
    }
  }
  const getSupportDocumentDataByScheme = async () => {
    let supportingDocumentData = {} as ResponseObject
    if (fileRegistrationObject.scheme === 'MP') {
      supportingDocumentData = await mpService.apiMpGetSupportingDocument(
        fileRegistrationObject.id
      )
    }
    if (fileRegistrationObject.scheme === 'PD') {
      supportingDocumentData = await pdService.apiPdGetSupportingDocument(
        fileRegistrationObject.id
      )
    } else if (fileRegistrationObject.scheme === 'PI') {
      supportingDocumentData = await piService.apiPiGetSupportingDocument(
        fileRegistrationObject.id
      )
    } else if (fileRegistrationObject.scheme === 'GSD') {
      supportingDocumentData = await gsdService.apiGsdGetSupportingDocument(
        fileRegistrationObject.id
      )
    } else if (fileRegistrationObject.scheme === 'GSI') {
      supportingDocumentData = await gsiService.apiGsiGetSupportingDocument(
        fileRegistrationObject.id
      )
    } else if (fileRegistrationObject.scheme === 'GPC') {
      supportingDocumentData = await gpcService.apiGpcGetSupportingDocument(
        fileRegistrationObject.id
      )
    } else if (fileRegistrationObject.scheme === 'GSC') {
      supportingDocumentData = await gscService.apiGscGetSupportingDocument(
        fileRegistrationObject.id
      )
    } else if (fileRegistrationObject.scheme === 'RLP') {
      supportingDocumentData = await rlpService.apiRlpGetSupportingDocument(
        fileRegistrationObject.id
      )
    } else if (fileRegistrationObject.scheme === 'AH') {
      supportingDocumentData = await ahService.apiAhGetSupportingDocument(
        fileRegistrationObject.id
      )
    } else if (fileRegistrationObject.scheme === 'SE') {
      supportingDocumentData = await seService.apiSeGetSupportingDocument(
        fileRegistrationObject.id
      )
    } else if (fileRegistrationObject.scheme === 'SRT') {
      supportingDocumentData = await srtService.apiSrtGetSupportingDocument(
        fileRegistrationObject.id
      )
    }
    return supportingDocumentData
  }

  const validateSubmit = () => {
    const mandatoryDocList =
      supportingDocumentDetails.supportingDocumentList.filter((document) => {
        return document.isMandatory === true
      })
    const filterdDocList = Array.from(
      supportingDocumentDetails.applicationFileSupportingDocumentList
        .reduce((map, obj) => map.set(obj.supportingDocumentId, obj), new Map())
        .values()
    ).filter((obj) => {
      if (obj.fileId != null) {
        return obj
      }
    })

    if (mandatoryDocList.length <= filterdDocList.length) {
      return true
    } else {
      return false
    }
  }

  const findunUploadedDocList = () => {
    const requiredDocList = supportingDocumentDetails.supportingDocumentList
    const uploadedDocList =
      supportingDocumentDetails.applicationFileSupportingDocumentList

    const filterdDocList = requiredDocList.filter(
      (elem) =>
        !uploadedDocList.find(
          ({ supportingDocumentId }) => elem.id === supportingDocumentId
        )
    )

    console.log(filterdDocList)
    return filterdDocList
  }

  const handleConfirm = () => {
    // console.log(supportingDocumentDetails)
    // const formData = new FormData()
    // if (uploadFileList.length == 0) {
    //   formData.append('files', new File([], ''))
    // }
    // for (let i = 0; i < uploadFileList.length; i++) {
    //   formData.append('files', uploadFileList[i])
    // }
    // const request = {
    //   documentList:
    //     supportingDocumentDetails.applicationFileSupportingDocumentList.filter(
    //       (file: FileSupportingDocument) => {
    //         if (file.fileId == null || file.fileId == undefined) {
    //           return file
    //         }
    //       }
    //     ),
    //   reminderList: supportingDocumentDetails.reminderList,
    // }
    // formData.append('request', JSON.stringify(request))
    // console.log(formData)
    if (showConfirm.section === 'save') {
      onSave()
    } else {
      // onSave(formData)
      onNext()
    }
    setShowConfirm({ isShow: false, message: '', section: '' })
  }

  // const handleFilesUpload = (files: any, id: number) => {
  //   const filesToAdd = [] as FileSupportingDocument[]
  //   for (let i = 0; i < files.length; i++) {
  //     const file = files[i]
  //     const addFile = {} as FileSupportingDocument
  //     addFile.applicationFileId = fileRegistrationObject.id
  //     addFile.supportingDocumentId = id
  //     addFile.fileName = file.name
  //     filesToAdd.push(addFile)
  //   }
  //   const newSupportingDocumentDetails = produce(
  //     supportingDocumentDetails,
  //     (draft: SupportingDocumentDataModel) => {
  //       draft.applicationFileSupportingDocumentList =
  //         draft.applicationFileSupportingDocumentList.concat(filesToAdd)
  //     }
  //   )
  //   setSupportingDocumentDetails(newSupportingDocumentDetails)
  //   setUploadFileList([])
  // }

  const handleFilesUpload = async (file: File, newName: string, id: number) => {
    const formData = new FormData()
    const addFile = {} as FileSupportingDocument
    addFile.applicationFileId = fileRegistrationObject.id
    addFile.supportingDocumentId = id
    addFile.fileName = file.name
    addFile.fileId = null
    addFile.name = newName
    addFile.fileType = file.type
    addFile.uuid = file.lastModified

    formData.append('file', file)
    const request = {
      fileId: null,
      supportingDocumentId: id,
      name: newName,
      fileName: file.name,
    }
    formData.append('request', JSON.stringify(request))
    dispatch(changeLoadingRequest())
    const response = await common.apiUploadSupportingDocument(
      fileRegistrationObject.id,
      fileRegistrationObject.programType.toLowerCase(),
      fileRegistrationObject.scheme.toLowerCase(),
      formData
    )

    if (response.status === 200) {
      const newSupportingDocumentDetails = produce(
        supportingDocumentDetails,
        (draft: SupportingDocumentDataModel) => {
          draft.applicationFileSupportingDocumentList.push(response.data)
        }
      )
      setSupportingDocumentDetails(newSupportingDocumentDetails)
    }
    // const newUploadFileList = produce(uploadFileList, (draft: File[]) => {
    //   draft.push(file)
    // })
    setUploadFileList([])
    handleNotification(response, 'File uploaded successfully')
    dispatch(changeLoadingRequest())
  }

  const handleAddRemark = async () => {
    const newSupportingDocumentDetails = produce(
      supportingDocumentDetails,
      (draft: SupportingDocumentDataModel) => {
        draft.reminderList.push(reminderObject)
      }
    )
    dispatch(changeLoadingRequest())
    const response = await common.apiAddReminder(
      fileRegistrationObject.id,
      fileRegistrationObject.programType.toLowerCase(),
      fileRegistrationObject.scheme.toLowerCase(),
      newSupportingDocumentDetails.reminderList
    )

    if (response.status === 200 || response.status === 204) {
      const newobject = produce(
        supportingDocumentDetails,
        (draft: SupportingDocumentDataModel) => {
          draft.reminderList = response.data
        }
      )
      setSupportingDocumentDetails(newobject)
    }

    const newReminder = produce(reminderObject, (draft: ReminderModel) => {
      draft.reminder = draft.reminder + 1
      draft.remarks = ''
      draft.sentDate = null
    })
    setReminderObject(newReminder)
    handleNotification(response, 'Reminder added successfully')
    dispatch(changeLoadingRequest())
  }

  const handleRemoveFile = async (file: FileSupportingDocument) => {
    console.log(uploadFileList)
    let response = { status: 200 } as ResponseObject
    const message = 'File Removed Successfully'
    if (file.fileId == null) {
      const newSupportingDocumentDetails = produce(
        supportingDocumentDetails,
        (draft: SupportingDocumentDataModel) => {
          draft.applicationFileSupportingDocumentList =
            draft.applicationFileSupportingDocumentList.filter(
              (document: FileSupportingDocument) => {
                if (document.uuid != file.uuid) {
                  return document
                }
              }
            )
        }
      )
      setSupportingDocumentDetails(newSupportingDocumentDetails)
    } else {
      dispatch(changeLoadingRequest())
      response = await common.apiDeleteSupportDocument(
        fileRegistrationObject.id,
        fileRegistrationObject.programType.toLowerCase(),
        fileRegistrationObject.scheme.toLowerCase(),
        file.fileId
      )
      if (response.status === 200) {
        const supportingDocumentData = await getSupportDocumentDataByScheme()
        if (supportingDocumentData.status === 200) {
          setSupportingDocumentDetails(supportingDocumentData.data)
        }
      }
      dispatch(changeLoadingRequest())
    }
    handleNotification(response, message)
  }

  const handleDownloadFile = async (file: FileSupportingDocument) => {
    if (file.fileId == null) {
      const downloadFile = uploadFileList.filter((uploadFile: File) => {
        if (uploadFile.lastModified === file.uuid) {
          return uploadFile
        }
      })[0]
      if (downloadFile != null) {
        const fileURL = URL.createObjectURL(downloadFile)
        const link = document.createElement('a')
        link.href = fileURL
        link.setAttribute('download', file.name)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    } else {
      const headers = {
        responseType: 'blob',
        'Content-Type': 'blob',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }
      fetch(`${process.env.baseUrl}download_file/${file?.fileName}`, {
        headers,
      })
        .then((response) => {
          return response.blob()
        })
        .then((data) => {
          saveAs(data, file.name)
        })
    }
  }

  const renderSupportingDocumentArea = () => {
    console.log(supportingDocumentDetails)
    return supportingDocumentDetails.supportingDocumentList.map(
      (document: SupportingDocumentsModel, index: number) => {
        return (
          <SupportingDocument
            key={index}
            isDisabled={fileRegistrationObject.modifyIndex > 0 ? true : false}
            fileRegistrationObject={fileRegistrationObject}
            supportDocument={document}
            fileList={supportingDocumentDetails.applicationFileSupportingDocumentList.filter(
              (file: FileSupportingDocument) => {
                return file.supportingDocumentId === document.id
              }
            )}
            upload={(data: any) => {
              console.log(data)
              handleFilesUpload(data.file, data.name, document.id)
            }}
            // remove={(file: FileSupportingDocument) => {
            //   handleRemoveFile(file)
            // }}
            remove={(file: FileSupportingDocument) => {
              setShowRemoveConfirm({
                isShow: true,
                message: `Do you want to remove the file: ${file.name}?`,
                fileToRemove: file,
              })
            }}
            download={(file: FileSupportingDocument) => {
              handleDownloadFile(file)
            }}
          />
        )
      }
    )
  }

  const handleUploadInstallmentDocument = async (
    file: File,
    mergeNumber: number
  ) => {
    dispatch(changeLoadingRequest())
    const formData = new FormData()
    formData.append('file', file[0])
    const result = await common.apiUploadInstallmentSupportDocument(
      fileRegistrationObject.id,
      fileRegistrationObject.scheme.toLowerCase(),
      mergeNumber,
      formData
    )
    const installmentData =
      await metaDataService.apiInstallmentListToSupportDocument(
        fileRegistrationObject.id
      )
    if (installmentData.status === 200) {
      setInstallmentList(installmentData.data)
    }
    handleNotification(result, 'File Upload Sucessfully')
    dispatch(changeLoadingRequest())
  }

  const handleDownloadInstallmentFile = async (url: string, name: string) => {
    const headers = {
      responseType: 'blob',
      'Content-Type': 'blob',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }
    fetch(`${process.env.baseUrl}download_file/${name}`, { headers })
      .then((response) => {
        return response.blob()
      })
      .then((data) => {
        saveAs(data, name)
      })
  }

  const renderInstallmentDocuments = () => {
    return installmentList.map(
      (installment: InstallmentModel, index: number) => {
        return (
          <Form.Group
            key={`installment-${installment.installmentNumber}-${index}`}
            className="mb-3"
            controlId="letter"
          >
            <Form.Label>Installment {installment.installmentNumber}</Form.Label>
            <InstallmentDocument
              fileRegistrationObject={fileRegistrationObject}
              installment={installment}
              upload={(file: File) => {
                handleUploadInstallmentDocument(
                  file,
                  installment.mergeInstallmentNumber
                )
              }}
              download={(url: string, name: string) => {
                handleDownloadInstallmentFile(url, name)
              }}
              isDisabled={false}
            />
          </Form.Group>
        )
      }
    )
  }

  const checkAddReminder = () => {
    let disabled = false
    if (
      reminderObject.remarks == undefined ||
      reminderObject.remarks == '' ||
      reminderObject.sentDate == null
    ) {
      disabled = true
    }
    return disabled
  }

  return (
    <>
      {loading ? (
        <div className="loading-layout">
          <LoadingComponent />
        </div>
      ) : (
        <>
          <Col sm={12} className="border-label">
            <div className="border-label-span">Supporting Documents</div>
            {renderSupportingDocumentArea()}
          </Col>
          {installmentList.length > 0 && (
            <Col sm={12} className="border-label mt-3">
              <div className="border-label-span">Installment Documents</div>
              {renderInstallmentDocuments()}
            </Col>
          )}
          <Col sm={12} className="border-label mt-3">
            <div className="border-label-span">Reminders</div>
            <Form id="reminder-form">
              <Form.Group className="mb-3" controlId="reminder">
                <Form.Label>Reminder</Form.Label>
                <Form.Control
                  disabled={true}
                  value={
                    supportingDocumentDetails.reminderList.length == 2
                      ? 'Maximum amount of reminders enterd.'
                      : reminderObject.reminder
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="date">
                <Form.Label>Sent Date</Form.Label>
                <ReactDatePicker
                  maxDate={new Date()}
                  disabled={
                    fileRegistrationObject.modifyIndex > 0 ||
                    supportingDocumentDetails.reminderList.length == 2
                  }
                  required={true}
                  placeholderText="Sent Date"
                  className="form-control"
                  selected={
                    reminderObject.sentDate != null
                      ? new Date(reminderObject.sentDate * 1000)
                      : null
                  }
                  onChange={(date: Date) => {
                    const newReminder = produce(
                      reminderObject,
                      (draft: ReminderModel) => {
                        draft.sentDate = date ? date.getTime() / 1000 : null
                      }
                    )
                    setReminderObject(newReminder)
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="remark">
                <Form.Label>Remark</Form.Label>
                <Form.Control
                  disabled={
                    fileRegistrationObject.modifyIndex > 0 ||
                    supportingDocumentDetails.reminderList.length == 2
                  }
                  type="text"
                  placeholder="Enter Remark"
                  onChange={(e) => {
                    const newReminder = produce(
                      reminderObject,
                      (draft: ReminderModel) => {
                        draft.remarks = e.target.value
                      }
                    )
                    setReminderObject(newReminder)
                  }}
                  value={reminderObject.remarks}
                />
              </Form.Group>
            </Form>
            {checkApplicationRegistrationButtonEnable(
              fileRegistrationObject.status,
              fileRegistrationObject.modifyIndex
            ) && (
              <Col sm={12} className="d-flex justify-content-end mt-4">
                <button
                  disabled={checkAddReminder()}
                  className={`${
                    checkAddReminder() ? 'disable-button' : 'save-button'
                  }`}
                  onClick={handleAddRemark}
                >
                  Add
                </button>
              </Col>
            )}
            {supportingDocumentDetails.reminderList.length > 0 && (
              <Row className="mt-3">
                <table className="table table-bordered">
                  <thead className="custom-table-header">
                    <tr>
                      <th>Reminder</th>
                      <th>Reminder Date</th>
                      <th>Remark</th>
                      {checkStatsDraftOrModifyDraft(
                        fileRegistrationObject.status
                      ) && <th>Action</th>}
                    </tr>
                  </thead>
                  <tbody className="custom-table-body">
                    {renderRemarkTable()}
                  </tbody>
                </table>
              </Row>
            )}

            {draftedReminders.length > 0 && (
              <Col sm={12} className="d-flex justify-content-end">
                <button
                  className="save-button"
                  onClick={(action) => {
                    action.preventDefault()
                    action.stopPropagation()
                    setShowPrintModal(true)
                  }}
                >
                  Print
                </button>
              </Col>
            )}
          </Col>
          <Col sm={12} className="d-flex justify-content-end mt-4">
            {checkStatsDraftOrModifyDraft(fileRegistrationObject.status) &&
              checkLoggedUser(
                user.epfNumber,
                fileRegistrationObject.allocateTo
              ) && (
                <>
                  <button
                    disabled={!validateSubmit()}
                    className={
                      !validateSubmit()
                        ? 'disable-button custom-margin-right'
                        : 'save-button custom-margin-right'
                    }
                    onClick={() => {
                      const message = 'Do you wish to Submit application ?'
                      const section = 'submit'
                      setShowConfirm({
                        isShow: true,
                        message: message,
                        section: section,
                      })
                    }}
                  >
                    Submit
                  </button>
                  {/* <button
                    className="save-button custom-margin-right"
                    onClick={() => {
                      const message = 'Do you wish to Save application ?'
                      const section = 'save'
                      setShowConfirm({
                        isShow: true,
                        message: message,
                        section: section,
                      })
                    }}
                  >
                    Save
                  </button> */}
                  {fileRegistrationObject.status != 'Application Closed' && (
                    <button
                      className="save-button custom-margin-right"
                      onClick={() => {
                        setShowCloseModal(true)
                      }}
                    >
                      Close
                    </button>
                  )}
                </>
              )}

            <button className="cancel-button" onClick={onOk}>
              Ok
            </button>
          </Col>
          {showConfirm.isShow && (
            <ConfirmationModal
              name={'Confirmation'}
              message={showConfirm.message}
              onCancel={() => {
                setShowConfirm({
                  isShow: false,
                  message: '',
                  section: '',
                })
              }}
              isRemark={false}
              onConfirm={handleConfirm}
            />
          )}
          {showCloseModal && (
            <ApplicationCloseReopenModal
              dsId={fileRegistrationObject.dsDivisionId}
              type={'close'}
              message={`Do you wish to close this application?`}
              onCancel={() => {
                setShowCloseModal(false)
              }}
              onSave={(object: any) => {
                setShowCloseModal(false)
                handleClose(object)
              }}
            />
          )}
          {showPrintModal && (
            <ReminderPrintModal
              onCancel={() => {
                setShowPrintModal(false)
              }}
              onConfirm={(reminder: {
                reminder: number
                supportingDocumentIds: number[]
              }) => {
                handlePrintReminder(reminder)
              }}
              supportingDocumentList={
                supportingDocumentDetails.supportingDocumentList
              }
              draftedReminderList={draftedReminders}
              unUploadedDocList={findunUploadedDocList()}
            />
          )}
          {showClosePrintModal && (
            <ClosePrintModal
              onConfirm={(data: ClosePrintModel) => {
                console.log(data)
                handleClosePrint(data)
                setShowClosePrintModal(false)
              }}
              onCancel={() => {
                history.goBack()
              }}
            />
          )}
          {/* Confirmation Modal */}
          {showRemoveConfirm.isShow && (
            <ConfirmationModal
              name={'Confirmation'}
              message={showRemoveConfirm.message}
              onCancel={() => {
                setShowRemoveConfirm({
                  isShow: false,
                  message: '',
                  fileToRemove: null,
                })
              }}
              onConfirm={confirmRemoveFile}
              isRemark={false}
            />
          )}
        </>
      )}
    </>
  )
}

export default SectionFive
