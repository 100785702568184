import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { useLocation, useHistory, RouteComponentProps } from 'react-router-dom'
import ReactTableWithPaginationComponent from '../../../components/table/ReactTableWithPaginationComponent'
import metaDataService from '../../../services/metaDataService'

type TParams = { id: string }
const DistrictList = ({ match }: RouteComponentProps<TParams>) => {
  const [districtList, setDistrictList] = React.useState([])
  const location = useLocation()
  const history = useHistory()

  React.useEffect(() => {
    getProvinceList()
  }, [location.pathname])

  const getProvinceList = async () => {
    const result = await metaDataService.apiDistrictsByProvinceFetch(
      parseInt(match.params.id)
    )
    if (result.status == 200) {
      setDistrictList(result.data)
    }
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Code',
        accessor: 'code', // accessor is the "key" in the data
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
    ],
    []
  )
  const handleView = (id: string, provinceId: string) => {
    history.push(
      `/system-configuration/administrative-areas/province/${provinceId}/district/${id}/ds-division/list`
    )
  }

  return (
    <>
      <Row className="title-layout">
        <Col className="d-flex justify-content-start">
          {/* <img src={incident_type} alt="" /> */}
          <span className="m-2">Districts</span>
        </Col>
      </Row>

      <Row className="incident-types-body-layout mt-4">
        <Col className="mt-2">
          <ReactTableWithPaginationComponent
            hover
            columns={columns}
            data={districtList ? districtList : []}
            onClickRow={(row: any) => {
              handleView(row.id, row.provinceId)
            }}
          />
        </Col>
      </Row>
    </>
  )
}

export default DistrictList
