/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusSquare, faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import React, { FC, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { ActionListModal } from '../../../models/package/actionListModal'
import { PackageModal } from '../../../models/package/packageModal'
import { SubPackageModal } from '../../../models/package/subPackageModal'
import Action from './Action'
import SubPackage from './SubPackage'

export interface PackageProps {
  packageModal: PackageModal
  packageIndex: number
  mode: boolean
  // onCheck: any
}

const Package: FC<PackageProps> = ({ packageModal, packageIndex, mode }) => {
  const [isSelected, setIsSelected] = useState(false)
  const renderSubPackages = () => {
    return packageModal.subPackageList.map(
      (subPackage: SubPackageModal, subPackageIndex: number) => {
        return (
          <ul className="custom-ul" key={`sub-package-${subPackage.id}`}>
            <SubPackage
              subPackageModal={subPackage}
              mode={mode}
              subPackageIndex={subPackageIndex}
              packageIndex={packageIndex}
            />
          </ul>
        )
      }
    )
  }

  const renderActionList = () => {
    return packageModal.actionList.map(
      (action: ActionListModal, index: number) => {
        return (
          <Action
            key={`package-action-${packageModal.id}-${index}`}
            actionListModal={action}
            index={index}
            mode={mode}
            relevantModue={`package`}
            packageIndex={packageIndex}
          />
        )
      }
    )
  }

  return (
    <div
      role="button"
      className="custom-permission-button"
      onClick={(e) => {
        e.stopPropagation()
        e.preventDefault()
        setIsSelected(!isSelected)
      }}
    >
      <Container fluid>
        <Row>
          <Col className="align-self-center" sm={1}>
            {isSelected ? (
              <FontAwesomeIcon icon={faMinusSquare} />
            ) : (
              <FontAwesomeIcon icon={faPlusSquare} />
            )}
          </Col>
          <Col sm={11}>
            <Row
              className={
                isSelected ? 'custom-header-selected' : 'custom-header'
              }
            >
              {packageModal.description}
            </Row>
          </Col>
        </Row>
        {isSelected && (
          <Row>
            <Col className="custom-inner-loop">
              {renderActionList()}
              {renderSubPackages()}
            </Col>
          </Row>
        )}
      </Container>
    </div>
  )
}

export default Package
