import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import TileButtonComponent from '../../../components/common/tileButton/TileButtonComponent'
import { schemeTiles } from '../../../utils/metaData'
import Gpc from './gpc/Gpc'
import Gsc from './gsc/Gsc'
import Gsd from './gsd/Gsd'
import Gsi from './gsi/Gsi'
import Pd from './pd/Pd'
import Pi from './pi/Pi'
import Rlp from './rlp/Rlp'
import Mp from './mp/Mp'

const ManageChequePayments = () => {
  const { path } = useRouteMatch()
  const renderModules = () => {
    return schemeTiles.map(
      (module: { name: string; description: string }, index: number) => {
        return (
          <Col key={`module-${index}`} xs={4}>
            <TileButtonComponent
              isIcon={false}
              name={module.description}
              routeURL={module.name}
            />
          </Col>
        )
      }
    )
  }
  return (
    <Switch>
      <Route path={`${path}/`} exact>
        <Row className="title-layout">Manage Cheque Payments</Row>
        <Row className="page-tiles-layout">{renderModules()}</Row>
      </Route>
      <Route path={`${path}/gpc`} component={Gpc} />
      <Route path={`${path}/gsc`} component={Gsc} />
      <Route path={`${path}/gsd`} component={Gsd} />
      <Route path={`${path}/gsi`} component={Gsi} />
      <Route path={`${path}/pd`} component={Pd} />
      <Route path={`${path}/pi`} component={Pi} />
      <Route path={`${path}/rlp`} component={Rlp} />
      <Route path={`${path}/mp`} component={Mp} />
    </Switch>
  )
}

export default ManageChequePayments
