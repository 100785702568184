import produce from 'immer'
import {
  AdministrativeAreaState,
  AdminsitrativeAreaActions,
  CLEAR_ADMINISTRATIVEAREA_LIST_REQUEST,
  DESELECT_ALL_DS_REQUEST,
  DESELECT_ALL_GN_REQUEST,
  DESELECT_DS_REQUEST,
  DISTRICT_FETCH_FAILURE,
  DISTRICT_FETCH_REQUEST,
  DISTRICT_FETCH_SUCCESS,
  DS_FETCH_FAILURE,
  DS_FETCH_REQUEST,
  DS_FETCH_SUCCESS,
  GN_FETCH_FAILURE,
  GN_FETCH_REQUEST,
  GN_FETCH_SUCCESS,
  PROVINCE_FETCH_FAILURE,
  PROVINCE_FETCH_REQUEST,
  PROVINCE_FETCH_SUCCESS,
  SELECT_ALL_DS_REQUEST,
  SELECT_ALL_GN_REQUEST,
  SELECT_DS_REQUEST,
  SET_MODIFY_ADMINISTRATIVEAREA_LIST_REQUEST,
} from './actionTypes'

const initialState: AdministrativeAreaState = {
  provinces: [],
  districts: [],
  ds: [],
  gn: [],
  pending: false,
  error: null,
}

export default (state = initialState, action: AdminsitrativeAreaActions) => {
  switch (action.type) {
    case PROVINCE_FETCH_REQUEST:
      return produce(state, (draft: AdministrativeAreaState) => {
        draft.pending = true
      })
    case PROVINCE_FETCH_SUCCESS:
      return produce(state, (draft: AdministrativeAreaState) => {
        draft.pending = false
        draft.provinces = action.payload.provinces
        draft.error = null
      })
    case PROVINCE_FETCH_FAILURE:
      return produce(state, (draft: AdministrativeAreaState) => {
        draft.pending = false
        draft.provinces = []
        draft.error = action.payload.error
      })
    case DISTRICT_FETCH_REQUEST:
      return produce(state, (draft: AdministrativeAreaState) => {
        draft.pending = true
      })
    case DISTRICT_FETCH_SUCCESS:
      return produce(state, (draft: AdministrativeAreaState) => {
        draft.pending = false
        draft.districts = action.payload.districts
        draft.error = null
      })
    case DISTRICT_FETCH_FAILURE:
      return produce(state, (draft: AdministrativeAreaState) => {
        draft.pending = false
        draft.districts = []
        draft.error = action.payload.error
      })
    case DS_FETCH_REQUEST:
      return produce(state, (draft: AdministrativeAreaState) => {
        draft.pending = true
      })
    case DS_FETCH_SUCCESS:
      return produce(state, (draft: AdministrativeAreaState) => {
        draft.pending = false
        draft.ds = action.payload.ds
        draft.error = null
      })
    case DS_FETCH_FAILURE:
      return produce(state, (draft: AdministrativeAreaState) => {
        draft.pending = false
        draft.ds = []
        draft.error = action.payload.error
      })
    case GN_FETCH_REQUEST:
      return produce(state, (draft: AdministrativeAreaState) => {
        draft.pending = true
      })
    case GN_FETCH_SUCCESS:
      return produce(state, (draft: AdministrativeAreaState) => {
        draft.pending = false
        draft.gn = action.payload.gn
        draft.error = null
      })
    case GN_FETCH_FAILURE:
      return produce(state, (draft: AdministrativeAreaState) => {
        draft.pending = false
        draft.gn = []
        draft.error = action.payload.error
      })
    case SELECT_DS_REQUEST:
      return produce(state, (draft: AdministrativeAreaState) => {
        draft.ds.map((ds) => {
          if (ds.id === action.payload.id) {
            ds.isSelected = true
          }
        })
        const allSelectedDSList = draft.ds.filter((ds) => {
          return ds.isSelected == true
        })
        const dsListByDistrictId = draft.ds.filter((ds) => {
          return ds.districtId == action.payload.districtId
        })
        if (allSelectedDSList.length == dsListByDistrictId.length) {
          draft.districts.map((district) => {
            if (district.id == action.payload.districtId) {
              district.allSelected = true
            }
          })
        }
      })

    case DESELECT_DS_REQUEST:
      return produce(state, (draft: AdministrativeAreaState) => {
        draft.ds.map((ds) => {
          if (ds.id === action.payload.id) {
            ds.isSelected = false
          }
        })
        draft.districts.map((district) => {
          if (district.id === action.payload.districtId) {
            district.allSelected = false
          }
        })
      })

    case CLEAR_ADMINISTRATIVEAREA_LIST_REQUEST:
      return produce(state, (draft: AdministrativeAreaState) => {
        draft.provinces = []
        draft.districts = []
        draft.ds = []
        draft.gn = []
      })
    case SET_MODIFY_ADMINISTRATIVEAREA_LIST_REQUEST:
      return produce(state, (draft: AdministrativeAreaState) => {
        draft.ds.map((ds) => {
          action.payload.administrativeAreaList.map((selectedProvince) => {
            selectedProvince.districtList.map((selectedDistrict) => {
              selectedDistrict.dsDivisionList.map((selectedDs) => {
                if (selectedDs.dsDivisionId == ds.id) {
                  ds.isSelected = true
                }
              })
            })
          })
        })

        draft.districts.map((district) => {
          action.payload.administrativeAreaList.map((selectedProvince) => {
            selectedProvince.districtList.map((selectedDistrict) => {
              if (selectedDistrict.districtId == district.id) {
                const filtedDs = draft.ds.filter((data) => {
                  return data.districtId == selectedDistrict.districtId
                })
                if (selectedDistrict.dsDivisionList.length == filtedDs.length) {
                  return (district.allSelected = true)
                }
              }
            })
          })
        })
      })

    case SELECT_ALL_GN_REQUEST:
      return produce(state, (draft: AdministrativeAreaState) => {
        draft.ds.map((ds) => {
          if (ds.id === action.payload.id) {
            ds.allSelected = true
          }
        })

        draft.gn = action.payload.gnList
        const allSelectedDSList = draft.ds.filter((ds) => {
          return (
            ds.allSelected == true && ds.districtId == action.payload.districtId
          )
        })
        const dsListByDistrictId = draft.ds.filter((ds) => {
          return ds.districtId == action.payload.districtId
        })
        if (allSelectedDSList.length == dsListByDistrictId.length) {
          draft.districts.map((district) => {
            if (district.id == action.payload.districtId) {
              district.allSelected = true
            }
          })
        }
      })
    case DESELECT_ALL_GN_REQUEST:
      return produce(state, (draft: AdministrativeAreaState) => {
        draft.districts.map((district) => {
          if (district.id === action.payload.districtId) {
            district.allSelected = false
          }
        })
        draft.ds.map((ds) => {
          if (ds.id === action.payload.id) {
            ds.allSelected = false
          }
        })
        draft.gn = action.payload.gnList
      })

    case SELECT_ALL_DS_REQUEST:
      return produce(state, (draft: AdministrativeAreaState) => {
        draft.districts.map((district) => {
          if (district.id === action.payload.id) {
            district.allSelected = true
          }
        })

        draft.ds = action.payload.dsList
      })
    case DESELECT_ALL_DS_REQUEST:
      return produce(state, (draft: AdministrativeAreaState) => {
        draft.districts.map((district) => {
          if (district.id === action.payload.id) {
            district.allSelected = false
          }
        })

        draft.ds = action.payload.dsList
      })
    default:
      return {
        ...state,
      }
  }
}
