import React from 'react'

import { Switch, Route, useRouteMatch } from 'react-router-dom'
import AddDsDivistion from './AddDsDivistion'
import AddGnDivistion from './AddGnDivistion'
import DistrictList from './DistrictList'
import DsDivisionList from './DsDivisionList'
import GnDivisionList from './GnDivisionList'
import ModifyDsDivistion from './ModifyDsDivistion'
import ModifyGnDivistion from './ModifyGnDivistion'
import ProvinceList from './ProvinceList'

const AdministrativeAreas = () => {
  const { path } = useRouteMatch()
  return (
    <div>
      <Switch>
        <Route path={`${path}/`} exact>
          <ProvinceList />
        </Route>
        <Route
          path={`${path}/province/:id/district/list`}
          component={DistrictList}
        />
        <Route
          path={`${path}/province/:provinceId/district/:districtId/ds-division/list`}
          component={DsDivisionList}
        />
        <Route
          path={`${path}/province/:provinceId/district/:districtId/ds-division/add`}
          component={AddDsDivistion}
        />
        <Route
          path={`${path}/province/:provinceId/district/:districtId/ds-division/:dsId/modify`}
          component={ModifyDsDivistion}
        />
        <Route
          path={`${path}/province/:provinceId/district/:districtId/ds-division/:dsId/gn-division/list`}
          component={GnDivisionList}
        />
        <Route
          path={`${path}/province/:provinceId/district/:districtId/ds-division/:dsId/gn-division/add`}
          component={AddGnDivistion}
        />
        <Route
          path={`${path}/province/:provinceId/district/:districtId/ds-division/:dsId/gn-division/:gnId/modify`}
          component={ModifyGnDivistion}
        />
      </Switch>
    </div>
  )
}

export default AdministrativeAreas
