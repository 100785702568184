import apiService from './api/apiManager'

class TodoDataService {
  /**
   * Todo get function.
   */
  async apiTodoGet() {
    return apiService.apiGet('/user/list')
  }

  /**
   * Todo post function.
   * @param {Object} user user.
   * @param {string} name user name.
   */
  async apiTodoPost(user: {
    age: number
    firstName: string
    lastName: string
  }) {
    return apiService.apiPost('/user/', user)
  }

  /**
   * Todo put function.
   * @param {Object} user user.
   * @param {number} id user id.
   * @param {string} name user name.
   */
  async apiTodoPut(user: { id: number; name: string }) {
    return apiService.apiPut('/user', user.id, user)
  }

  /**
   * Test delete function.
   * @param {number} id user id.
   */
  async apiTestDelete(id: number) {
    return apiService.apiDelete('/user', id)
  }
}

export default new TodoDataService()
