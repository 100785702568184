import React, { FC } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import LoginFormComponent from '../../components/login/LoginFormComponent'
import LoginLogoComponent from '../../components/login/LoginLogoComponent'
import './LoginPageComponent.scss'

export interface LoginPageComponentProps {
  redirectUrl?: string
}

const LoginPageComponent: FC<LoginPageComponentProps> = ({ redirectUrl }) => {
  return (
    <Container fluid>
      <Row>
        <Col className="login-logo-area" sm={12} md={12} lg={7}>
          <LoginLogoComponent />
        </Col>
        <Col sm={12} md={12} lg={5}>
          <LoginFormComponent redirectUrl={redirectUrl} />
        </Col>
      </Row>
      <span className="version">v.1.6.7</span>
    </Container>
  )
}

export default LoginPageComponent
