import { FilterModel } from '../../../models/utils/filterModel'
import apiService from '../../api/apiManager'
class SupportingDocumentServices {
  async apiFetchSupportingDocumentList(filterData: FilterModel) {
    return apiService.apiPost(
      `/system-configuration/supporting-document/list`,
      filterData
    )
  }

  async apiFetchSupportingDocumentCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/system-configuration/supporting-document/count`,
      filterData
    )
  }
  async apiGetAllSupportingDocumentList() {
    return apiService.apiGet('/system-configuration/supporting-document/list')
  }
  async apiGetSupportingDocumenById(id: number) {
    return apiService.apiGet(
      `/system-configuration/supporting-document/view/${id}`
    )
  }
  async apiGetSupportingDocumentsHistory(id: number) {
    return apiService.apiGet(
      `/system-configuration/supporting-document/${id}/history`
    )
  }
  async apiAddSupportingDocuments(supportingDocument: any) {
    return apiService.apiPost(
      '/system-configuration/supporting-document/add',
      supportingDocument
    )
  }
  async apiActiveSupportingDocument(id: number) {
    return apiService.apiActiveInactive(
      `/system-configuration/supporting-document/${id}/active`
    )
  }
  async apiInactiveSupportingDocument(id: number) {
    return apiService.apiActiveInactive(
      `/system-configuration/supporting-document/${id}/inactive`
    )
  }
  async apiUpdateSupportingDocument(
    id: number | undefined,
    supportingDocument: any
  ) {
    return apiService.apiPutCommon(
      `/system-configuration/supporting-document/modify/${id}`,
      supportingDocument
    )
  }
}
export default new SupportingDocumentServices()
