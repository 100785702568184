import { FC, useEffect, useState } from 'react'
import { Col, Container, Nav, Row, Tab } from 'react-bootstrap'
import LoadingComponent from '../../../components/common/loading/LoadingComponent'
import SimilarRecordTable from '../../../components/common/similarRecordTable/SimilarRecordTable'
import ReactTableWithPaginationComponent from '../../../components/table/ReactTableWithPaginationComponent'
import { FileRegistrationModel } from '../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import applicationFiles from '../../../services/applicationRegistration/applicationFiles'
import common from '../../../services/applicationRegistration/common'
import { fileRegistrationHistoryColumns } from '../../../utils/metaData'
import ModifyBasicDetails from '../modifyBasicInformation/ModifyBasicDetails'
import FileDeleteModal from '../../../components/modals/FileDeleteModal'
import { getUser } from '../../../store/user/selectors'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ReAllocateModal from '../../../components/modals/ReAllocateModal'

export interface SectionOneProps {
  fileRegistrationObject: FileRegistrationModel
  onClick?: any
  packageName: string
  onReload: any
}

const SectionOne: FC<SectionOneProps> = ({
  fileRegistrationObject,
  onClick,
  onReload,
}) => {
  const [similarRecordList, setSimilarRecordList] = useState([])
  const [historyList, setHistoryList] = useState([])
  const [loading, setLoading] = useState(true)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showReallocateModal, setShowReallocateModal] = useState(false)
  const user = useSelector(getUser)
  const history = useHistory()
  useEffect(() => {
    getFileDetails()
  }, [loading])

  async function getFileDetails() {
    const fileHistoryData =
      await applicationFiles.apiFetchApplicationFilesHistory(
        fileRegistrationObject.id
      )
    const similarRecordData = await common.apiGetSimilarRecords(
      fileRegistrationObject.id
    )
    if (fileHistoryData.status === 200) {
      setHistoryList(fileHistoryData.data)
    }
    if (similarRecordData.status === 200) {
      setSimilarRecordList(similarRecordData.data)
    }
    setLoading(false)
  }

  return (
    <>
      {loading ? (
        <div className="loading-layout">
          <LoadingComponent />
        </div>
      ) : (
        <>
          <Col sm={12} className="border-label">
            <div className="border-label-span">Basic Information</div>
            <ModifyBasicDetails
              information={fileRegistrationObject}
              onCancel={() => {
                setLoading(true)
              }}
              onSuccess={onReload}
            />
          </Col>
          <Col sm={12} className="mt-3 mb-3 p-3">
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey="audit-history"
            >
              <Row className="tab-page-layout">
                <Nav variant="pills" className="flex-row">
                  <Nav.Item className="custom-nav-item">
                    <Nav.Link eventKey="audit-history">Audit History</Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="custom-nav-item">
                    <Nav.Link eventKey="similar-records">
                      Similar Record
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Row>
              <Row>
                <Tab.Content>
                  <Tab.Pane eventKey="audit-history">
                    <Container fluid className="mt-4">
                      <ReactTableWithPaginationComponent
                        columns={fileRegistrationHistoryColumns}
                        data={historyList}
                        onClickRow={(row: any) => {
                          console.log(row)
                        }}
                      />
                    </Container>
                  </Tab.Pane>
                  <Tab.Pane eventKey="similar-records">
                    <SimilarRecordTable similarRecordList={similarRecordList} />
                  </Tab.Pane>
                </Tab.Content>
              </Row>
            </Tab.Container>
          </Col>
          <Col sm={12} className="d-flex justify-content-end mt-4">
            <button
              className="save-button"
              onClick={() => {
                setShowReallocateModal(true)
              }}
            >
              Re-Allocate
            </button>
            {fileRegistrationObject.status != 'File Deleted' && (
              <button
                className="save-button"
                onClick={() => {
                  setShowDeleteModal(true)
                }}
              >
                Delete
              </button>
            )}

            <button
              className="cancel-button"
              onClick={() => {
                onClick({ section: 'ok', remark: '' })
              }}
            >
              Ok
            </button>
          </Col>
        </>
      )}

      <FileDeleteModal
        applicationFileId={fileRegistrationObject.id}
        onCancel={() => {
          setShowDeleteModal(false)
        }}
        username={user.epfNumber}
        show={showDeleteModal}
        onSuccess={() => {
          history.goBack()
        }}
      />
      <ReAllocateModal
        fileId={fileRegistrationObject.id}
        show={showReallocateModal}
        onCancel={() => {
          setShowReallocateModal(false)
          getFileDetails()
        }}
      />
    </>
  )
}

export default SectionOne
