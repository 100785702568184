import React, { FC, useEffect, useState } from 'react'
import { Form, Modal, Row } from 'react-bootstrap'
import Select from 'react-select'
import { AllocateToTypeOption } from '../../models/selectOptionModels/allocateOption'
import { ResponseObject } from '../../services/api/apiManager'
import metaDataService from '../../services/metaDataService'
import { seAllocateUsersOptions } from '../../utils/utilFunctions'
import { showTotast } from '../../utils/toast'
import {
  ALERT_SUCCESS,
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../utils/constants'
import ApplicationModificationService from '../../services/applicationModification/ApplicationModificationService'

export interface AllocateModalProps {
  fileId: number
  show: boolean
  onCancel?: any
}

const ReAllocateModal: FC<AllocateModalProps> = ({
  show,
  onCancel,
  fileId,
}) => {
  const [userList, setUserList] = useState([] as AllocateToTypeOption[])
  const [selectedUser, setSelectedUser] = useState('')

  useEffect(() => {
    async function getAllocateUsers() {
      const response = await metaDataService.apiGetActiveUserList()
      setUserList(seAllocateUsersOptions(response.data))
    }
    getAllocateUsers()
  }, [])

  const handleClick = async () => {
    let result = {} as ResponseObject
    let success = false
    let message = result.errorMessage ? result.errorMessage : ''
    result = await ApplicationModificationService.reallocate(
      fileId,
      selectedUser
    )
    message = result.errorMessage ? result.errorMessage : ''
    if (result.status === 200) {
      success = true
      message = 'Successfully Allocated'
      onCancel()
    }
    showTotast(
      success ? ALERT_SUCCESS : ALERT_WARNING,
      message,
      TOAST_POSITION_TOP_RIGHT,
      5,
      TOAST_TRANSITION_SLIDE
    )
  }
  return (
    <Modal
      size="lg"
      show={show}
      onHide={onCancel}
      aria-labelledby="example-modal-sizes-title-sm"
      dialogClassName="custom-modal-layout"
      contentClassName="custom-modal-content"
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        className="custom-modal-header"
      >
        <Modal.Title id="example-modal-sizes-title-sm">Re-Allocate</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row className="mb-3">
            <Form.Group className="mb-3" controlId="formAllocate">
              <Form.Label>Allocate User</Form.Label>
              <Select
                options={userList}
                onChange={(selectedOption) => {
                  setSelectedUser(selectedOption ? selectedOption.value : '')
                }}
              />
            </Form.Group>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button className="save-button" onClick={handleClick}>
          Allocate
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default ReAllocateModal
