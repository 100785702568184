import React, { FC } from 'react'

export interface NoDataBannerProps {
  permission: boolean
}

const NoDataBanner: FC<NoDataBannerProps> = ({ permission }) => {
  return (
    <div>
      {permission ? 'No data available' : 'Do not have permission to view data'}
    </div>
  )
}

export default NoDataBanner
