import { FileRegistrationModel } from '../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { FamilyDetailsModel } from '../../../models/applicationRegistration/loan/familyDetails'
import { OtherDetailsModel } from '../../../models/applicationRegistration/loan/otherDetails'
import { FilterModel } from '../../../models/utils/filterModel'
import apiService from '../../api/apiManager'

class SeDataService {
  async apiFetchSeList(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/loan/se/view/list`,
      filterData
    )
  }

  async apiFetchSeListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/loan/se/view/count`,
      filterData
    )
  }

  async apiFetchSeReturnList(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/loan/se/file-return/view/list`,
      filterData
    )
  }

  async apiFetchSeReturnListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/loan/se/file-return/view/count`,
      filterData
    )
  }

  async apiFetchSeAttributeList(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/loan/se/attribute/view/list`,
      filterData
    )
  }

  async apiFetchSeAttributeListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/loan/se/attribute/view/count`,
      filterData
    )
  }

  async apiFetchSeFile(id: number) {
    return apiService.apiGet(`/application-registration/loan/se/view/${id}`)
  }

  async apiFetchSeReturnFile(id: number) {
    return apiService.apiGet(
      `/application-registration/loan/se/file-return/view/${id}`
    )
  }

  async apiSeProceed(file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/application-registration/loan/se/add/proceed`,
      file
    )
  }

  async apiSeModify(file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/application-registration/loan/se/file-return/modify/${file.id}`,
      file
    )
  }

  async apiSeReject(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/application-registration/loan/se/${id}/reject`,
      { remark: remark }
    )
  }

  async apiSeReturn(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/application-registration/loan/se/${id}/return`,
      { remark: remark }
    )
  }

  async apiSeClose(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/application-registration/loan/se/${id}/close`,
      { remark: remark }
    )
  }

  async apiSeReOpen(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/application-registration/loan/se/${id}/reopen`,
      { remark: remark }
    )
  }

  async apiSeReRegister(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/application-registration/loan/se/${id}/register`,
      { remark: remark }
    )
  }
  async apiSeAddApplicantDetails(file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/application-registration/loan/se/add/applicant`,
      file
    )
  }

  async apiSeAddOthersDetails(file: OtherDetailsModel) {
    return apiService.apiPutCommon(
      `/application-registration/loan/se/add/${file.applicationFileId}/other-details`,
      file
    )
  }

  async apiSeGetOthersDetails(id: number) {
    return apiService.apiGet(
      `/application-registration/loan/se/view/${id}/other-details`
    )
  }

  async apiSeAddFamilyDetails(file: FamilyDetailsModel) {
    return apiService.apiPutCommon(
      `/application-registration/loan/se/add/${file.applicationFileId}/family-details`,
      file
    )
  }

  async apiSeGetFamilyDetails(id: number) {
    return apiService.apiGet(
      `/application-registration/loan/se/view/${id}/family-details`
    )
  }

  async apiSeAddDocument(file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/application-registration/loan/se/add/${file.id}/document`,
      file
    )
  }

  async apiSeGetDocument(id: number) {
    return apiService.apiGet(
      `/application-registration/loan/se/view/${id}/document`
    )
  }
  async apiGetFamilyDetail(id: number) {
    return apiService.apiGet(
      `/application-registration/loan/se/view/${id}/family-details`
    )
  }

  async apiSeGetSupportingDocument(id: number) {
    return apiService.apiGet(
      `/application-registration/loan/se/view/${id}/document`
    )
  }

  async apiSeAddSupportingDocument(formData: FormData, id: number) {
    return apiService.apiPutFormData(
      `/application-registration/loan/se/add/${id}/document`,
      formData
    )
  }

  async apiSeRegisterDocument(id: number) {
    return apiService.apiPutCommon(
      `/application-registration/loan/se/add/${id}/register`,
      {}
    )
  }
}

export default new SeDataService()
