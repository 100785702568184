import { PdAggrivedPersonDetailsModel } from '../../../models/applicationRegistration/compensation/pd/pdAggrivedPerson'
import { PdDependentDetailsModel } from '../../../models/applicationRegistration/compensation/pd/pdDependentDetails'
import { NextOfKinModel } from '../../../models/applicationRegistration/compensation/nextOfKinModel'
import { FileRegistrationModel } from '../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { FilterModel } from '../../../models/utils/filterModel'
import apiService from '../../api/apiManager'

class PiDataService {
  async apiFetchPiList(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/compensation/pi/view/list`,
      filterData
    )
  }

  async apiFetchPiListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/compensation/pi/view/count`,
      filterData
    )
  }
  async apiFetchPiReturnList(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/compensation/pi/file-return/view/list`,
      filterData
    )
  }

  async apiFetchPiReturnListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/compensation/pi/file-return/view/count`,
      filterData
    )
  }

  async apiFetchPiFile(id: number) {
    return apiService.apiGet(
      `/application-registration/compensation/pi/view/${id}`
    )
  }

  async apiPiProceed(file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/pi/add/proceed`,
      file
    )
  }

  async apiPiModify(file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/pi/file-return/modify/${file.id}`,
      file
    )
  }

  async apiPiReject(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/pi/${id}/reject`,
      { remark: remark }
    )
  }

  async apiPiReturn(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/pi/${id}/return`,
      { remark: remark }
    )
  }

  async apiPiClose(id: number, remark: string, description: string) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/pi/${id}/close`,
      { remark: remark, description: description }
    )
  }

  async apiPiReopen(
    id: number,
    remark: string,
    description: string,
    allocateTo: string
  ) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/pi/${id}/reopen`,
      { remark: remark, description: description, allocateTo: allocateTo }
    )
  }

  async apiPiMerge(id: number, idList: any[]) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/pi/add/${id}/merge`,
      idList
    )
  }

  async apiPiAddApplicantDetails(file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/pi/add/applicant`,
      file
    )
  }

  async apiPiAddAggrivedPersonDetailsDetails(
    file: PdAggrivedPersonDetailsModel
  ) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/pi/add/${file.applicationFileId}/aggrieved_person`,
      file
    )
  }

  async apiPiGetAggrivedPersonDetailsDetails(id: number) {
    return apiService.apiGet(
      `/application-registration/compensation/pi/view/${id}/aggrieved_person`
    )
  }

  async apiPiGetDependentDetails(id: number) {
    return apiService.apiGet(
      `/application-registration/compensation/pi/view/${id}/dependents`
    )
  }

  async apiPiAddDependantDetails(file: PdDependentDetailsModel) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/pi/add/${file.applicationFileId}/dependent`,
      file
    )
  }

  async apiPiDeleteDependantDetails(file: PdDependentDetailsModel) {
    return apiService.apiDelete(
      `/application-registration/compensation/pi/add/${file.applicationFileId}/dependent`,
      file.id
    )
  }

  async apiPiAddDependantNextOfKinDetails(file: NextOfKinModel) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/pi/add/${file.applicationFileId}/dependent/${file.dependentId}/next_of_kin`,
      file
    )
  }

  async apiPiGetDependantNextOfKinDetails(fileId: number, dependentId: number) {
    return apiService.apiGet(
      `/application-registration/compensation/pi/view/${fileId}/dependent/${dependentId}/next_of_kin`
    )
  }

  async apiPiGetSupportingDocument(id: number) {
    return apiService.apiGet(
      `/application-registration/compensation/pi/view/${id}/document`
    )
  }
  async apiPiAddSupportingDocument(formData: FormData, id: number) {
    return apiService.apiPutFormData(
      `/application-registration/compensation/pi/add/${id}/document`,
      formData
    )
  }

  async apiPiRegisterDocument(id: number) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/pi/add/${id}/register`,
      {}
    )
  }
}

export default new PiDataService()
