import { AdvancedFilterModel } from '../../../models/utils/filterModel'
import { defaultPageLimit } from '../../../utils/constants'

export interface DataItem {
  [key: string]: any
}

export interface Column {
  header: string
  accessor: string
  render?: (row: DataItem) => JSX.Element
}

export const advancedTableCustomRender = (data: any, accessor: string) => {
  switch (accessor) {
    case 'createdDateTime':
    case 'modifiedDateTime':
    case 'performedDateTime':
    case 'sendDateTime':
      return data == null ? 'N/A' : new Date(data * 1000).toLocaleString()
    case 'effectiveDate':
    case 'sentDate':
    case 'dateOfBirth':
    case 'receivedDate':
    case 'fileReceivedDate':
    case 'cheque_issued_date':
    case 'chequeIssuedDate':
    case 'startDate':
    case 'endDate':
      return data == null ? 'N/A' : new Date(data * 1000).toLocaleDateString()
    case 'identificationType':
      const identificationType = data
      if (identificationType == null) {
        return data
      } else {
        switch (identificationType) {
          case 'TIC':
            return 'TIC'
          case 'PASSPORT':
            return 'Passport'
          case 'SENIOR_CITIZEN_ID':
            return 'Senior Citizen ID'
          case 'DRIVING_LICENSE':
            return 'Driving License'
          case 'BIRTH_CERTIFICATE':
            return 'Birth Certificate'
          default:
            return 'NIC'
        }
      }
    default:
      return data
  }
}

export const commonColumns: Column[] = [
  { header: 'Application Reference', accessor: 'referenceNumber' },
  // { header: 'Incident Type', accessor: 'incidentType.incidentType' },
  { header: 'Scheme', accessor: 'scheme' },
  { header: 'Applicant Name', accessor: 'applicantName' },
  { header: 'Identification Type', accessor: 'identificationType' },
  { header: 'ID Number', accessor: 'idNumber' },
  { header: 'District', accessor: 'district.name' },
  { header: 'DS Division', accessor: 'dsDivision.name' },
  { header: 'File Received Date', accessor: 'fileReceivedDate' },
  { header: 'Created Date and Time', accessor: 'createdDateTime' },
  { header: 'Status', accessor: 'status' },
]

export const fileRegistrationModuleColumns: Column[] = [
  { header: 'Identification Type', accessor: 'identificationType' },
  { header: 'Scheme', accessor: 'scheme' },
  { header: 'Applicant Name', accessor: 'applicantName' },
  { header: 'NIC', accessor: 'newNicNumber' },
  { header: 'Incident Type', accessor: 'incidentType.incidentType' },
  { header: 'ID Number', accessor: 'idNumber' },
  // { header: 'District', accessor: 'district.name' },
  // { header: 'DS Division', accessor: 'dsDivision.name' },
  // { header: 'File Received Date', accessor: 'fileReceivedDate' },
  { header: 'Created Date and Time', accessor: 'createdDateTime' },
  { header: 'Status', accessor: 'status' },
]

export const applicationCommonColumns = [
  { header: 'Application Reference', accessor: 'referenceNumber' },
  { header: 'Incident Type', accessor: 'incidentType.incidentType' },
  { header: 'Scheme', accessor: 'scheme' },
  { header: 'Applicant Name', accessor: 'applicantName' },
  // { header: 'NIC', accessor: 'newNicNumber' },
  { header: 'Identification Type', accessor: 'identificationType' },
  { header: 'ID Number', accessor: 'idNumber' },
  // { header: 'Province', accessor: 'province.name' },
  { header: 'District', accessor: 'district.name' },
  { header: 'DS Division', accessor: 'dsDivision.name' },
  // { header: 'GN Division', accessor: 'gnDivision.name' },
  { header: 'File Received Date', accessor: 'fileReceivedDate' },
  { header: 'Created Date and Time', accessor: 'createdDateTime' },
  { header: 'Status', accessor: 'status' },
]

export const fileRegistrationLoanColumns = [
  { header: 'Application Reference', accessor: 'referenceNumber' },
  { header: 'Scheme', accessor: 'scheme' },
  { header: 'Applicant Name', accessor: 'applicantName' },
  // { header: 'NIC', accessor: 'newNicNumber' },
  { header: 'Identification Type', accessor: 'identificationType' },
  { header: 'ID Number', accessor: 'idNumber' },
  // { header: 'Province', accessor: 'province.name' },
  { header: 'District', accessor: 'district.name' },
  { header: 'DS Division', accessor: 'dsDivision.name' },
  // { header: 'GN Division', accessor: 'gnDivision.name' },
  { header: 'File Received Date', accessor: 'fileReceivedDate' },
  { header: 'Created Date and Time', accessor: 'createdDateTime' },
  { header: 'Status', accessor: 'status' },
]

export const initFilterOption: AdvancedFilterModel = {
  filterValue: null,
  limit: defaultPageLimit,
  page: 1,
  orderBy: 'created_date_time',
  order: 'DESC',
  statusList: null,
  createdDateFrom: null,
  createdDateTo: null,
  scheme: null,
  programType: null,
  incidentTypeId: null,
  fileReceivedDate: null,
}
