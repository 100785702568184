import { all, fork } from 'redux-saga/effects'
import permissionsListSaga from './permissions/saga'

import todoSaga from './todo/sagas'
import userManagementSaga from './usermanagement/saga'
import administrativeAreaSaga from './administrativeArea/saga'
// import applicationSaga from './app/saga'

export function* rootSaga() {
  yield all([
    fork(todoSaga),
    fork(userManagementSaga),
    fork(permissionsListSaga),
    fork(administrativeAreaSaga),
  ])
}
