import { FileRegistrationModel } from '../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { FilterModel } from '../../../models/utils/filterModel'
import apiService from '../../api/apiManager'

class GsiDataService {
  async apiFetchGsiList(filterData: FilterModel) {
    return apiService.apiPost(
      `/compensation-approval/compensation/gsi/view/list`,
      filterData
    )
  }

  async apiFetchGsiListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/compensation-approval/compensation/gsi/view/count`,
      filterData
    )
  }

  async apiFetchGsiReturnList(filterData: FilterModel) {
    return apiService.apiPost(
      `/compensation-approval/compensation/gsi/file-return/view/list`,
      filterData
    )
  }

  async apiFetchGsiReturnListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/compensation-approval/compensation/gsi/file-return/view/count`,
      filterData
    )
  }

  async apiApproveGsiFile(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/compensation-approval/compensation/gsi/${id}/approve`,
      { remark: remark }
    )
  }

  async apiRejectGsiFile(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/compensation-approval/compensation/gsi/${id}/reject`,
      { remark: remark }
    )
  }
  async apiReturnGsiFile(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/compensation-approval/compensation/gsi/${id}/return`,
      { remark: remark }
    )
  }
  async apiUpdateGsiFile(id: number, file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/compensation-approval/compensation/gsi/file-return/${id}`,
      file
    )
  }
  async apiGetReturnGsiFile(id: number) {
    return apiService.apiGet(
      `/compensation-approval/compensation/gsi/file-return/view/${id}`
    )
  }
  async apiGetGsiFile(id: number) {
    return apiService.apiGet(
      `/compensation-approval/compensation/gsi/view/${id}`
    )
  }
}

export default new GsiDataService()
