import React, { FC, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import LoadingComponent from '../../../components/common/loading/LoadingComponent'
import ReactTableWithPaginationComponent from '../../../components/table/ReactTableWithPaginationComponent'
import { FileRegistrationModel } from '../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { LoanRepaymentDetailsModel } from '../../../models/applicationRegistration/loan/loanRepaymentDetails'
import enquireApplicationService from '../../../services/enquireApplication/enquireApplicationService'
import { repaymentHistoryColumns } from '../../../utils/metaData'
import {
  renderPayeeNames,
  thousandSeperator,
} from '../../../utils/utilFunctions'

export interface RepaymentDetailsSectionProps {
  fileRegistrationObject: FileRegistrationModel
}

const RepaymentDetails: FC<RepaymentDetailsSectionProps> = ({
  fileRegistrationObject,
}) => {
  const [loading, setLoading] = useState(true)
  const [repaymentDetailsList, setRepaymentDetailsList] = useState(
    [] as LoanRepaymentDetailsModel[]
  )
  const [historyList, setHistoryList] = useState([])
  useEffect(() => {
    async function getData() {
      const repaymentDetailsData =
        await enquireApplicationService.apiFetchRepaymentDetails(
          fileRegistrationObject.id
        )
      if (repaymentDetailsData.status === 200) {
        setRepaymentDetailsList(repaymentDetailsData.data)
      }
      const historyData =
        await enquireApplicationService.apiFetchRepaymentHistoryDetails(
          fileRegistrationObject.id
        )
      if (historyData.status === 200) {
        setHistoryList(historyData.data)
      }
      setLoading(false)
    }
    getData()
  }, [])
  const renderRepaymentDetailsTable = () => {
    return repaymentDetailsList.map((repayment: LoanRepaymentDetailsModel) => {
      return (
        <tr key={`repayment-${repayment.id}`}>
          <td>
            {repayment.guarantorPayment
              ? renderPayeeNames(repayment.loanRepaymentGuarantorList)
              : 'Applicant'}
          </td>
          <td>{thousandSeperator(repayment.repaymentCapitalAmount)}</td>
          <td>{thousandSeperator(repayment.repaymentInterestAmount)}</td>
          <td>{new Date(repayment.paidDate * 1000).toLocaleDateString()}</td>
          <td>{repayment.gracePeriod}</td>
          <td>{repayment.loanStatus}</td>
          <td>{repayment.specialRemarks}</td>
        </tr>
      )
    })
  }
  return (
    <>
      {loading ? (
        <div className="loading-layout">
          <LoadingComponent />
        </div>
      ) : (
        <>
          {repaymentDetailsList.length > 0 && (
            <Col sm={12} className="border-label mb-4">
              <div className="border-label-span">Repayments</div>
              <Row className="mt-3">
                <div className="table-wrapper">
                  <table className="table table-bordered">
                    <thead className="custom-table-header">
                      <tr>
                        <th>Payee</th>
                        <th>Capital Amount</th>
                        <th>Interest Amount</th>
                        <th>Paid Date</th>
                        <th>Grace Period (in months)</th>
                        <th>Loan Status</th>
                        <th>Special Remark</th>a
                      </tr>
                    </thead>
                    <tbody className="custom-table-body">
                      {renderRepaymentDetailsTable()}
                    </tbody>
                  </table>
                </div>
              </Row>
            </Col>
          )}
        </>
      )}
      {historyList.length > 0 && (
        <Col sm={12} className="border-label mb-4">
          <div className="border-label-span">History</div>
          <Row className="mt-4">
            <ReactTableWithPaginationComponent
              columns={repaymentHistoryColumns}
              data={historyList}
              onClickRow={(row: any) => {
                console.log(row)
              }}
            />
          </Row>
        </Col>
      )}
    </>
  )
}

export default RepaymentDetails
