import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { ReportFilterModel } from '../../../models/reports/ReportFilterModel'
import DatePicker from 'react-datepicker'
import produce from 'immer'
import Select from 'react-select'
import { saveAs } from 'file-saver'
import {
  SchemeptionReport,
  mpStatReportTableHeaders,
  programeTypes,
  reportTableColumn,
  reportTableHeaders,
  statsReportstatusList,
} from '../../../utils/metaData'
import metaDataService from '../../../services/metaDataService'
import { IncidentTypeOption } from '../../../models/selectOptionModels/incidentTypeOption'
import IterminalTableComponent from '../../../components/table/IterminalTableComponent'
import { FileRegistrationModel } from '../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import NewReportService from '../../../services/report/NewReportService'
import { getPageCount } from '../../../utils/tableFunctions'
import { ReportTableHeaderModel } from '../../../models/reports/ReportTableHeaderModel'
import AllDetailsReportModal from './AllDetailsReportModal'
import { geDistrictsOptions } from '../../../store/administrativeArea/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { handleNotification } from '../../../utils/utilFunctions'
import { changeLoadingRequest } from '../../../store/app/actionTypes'

const PaymentStatsReport = () => {
  const [printReport, setPrintReport] = useState({
    show: false,
    fileId: 0,
    fileRefNo: '',
  })
  const [state, setstate] = useState([])
  const [reportHeaders, setReportHeaders] = useState(
    [] as ReportTableHeaderModel[]
  )
  const [loading, setLoading] = useState(false)
  const [pageCount, setPageCount] = useState(0)
  const [dataCount, setDatacount] = useState(0)
  const [filters, setFilters] = useState({ limit: 100 } as ReportFilterModel)
  const [schemesList, setSchemesList] = useState([] as SchemeptionReport[])
  const [incidentList, setIncidentList] = useState([] as IncidentTypeOption[])

  const districtList = useSelector(geDistrictsOptions)
  const dispatch = useDispatch()
  useEffect(() => {
    getMetaData()
    setReportHeaders(reportTableHeaders)
  }, [])

  async function getMetaData() {
    const incidentTypeData = await metaDataService.apiIncidentTypeList()
    const incidentTypeTempList: IncidentTypeOption[] = []
    for (let i = 0; i < incidentTypeData.data.length; i++) {
      const incident = {} as IncidentTypeOption
      incident.value = incidentTypeData.data[i].id
      incident.label = incidentTypeData.data[i].incidentType
      incidentTypeTempList.push(incident)
    }
    setIncidentList(incidentTypeTempList)
  }

  const getSchemeListByProgrammeType = async (programeType: string) => {
    const selectedProgrameType =
      programeType === 'COMPENSATION' ? 'Compensation' : 'Loan'
    const response = await metaDataService.getSchemeListByProgramTypeAndStatus(
      selectedProgrameType,
      'Active'
    )
    if (response.status === 200) {
      const schemeList = [] as SchemeptionReport[]
      for (let index = 0; index < response.data.length; index++) {
        const element = {} as SchemeptionReport
        element.value = response.data[index].code
        element.label = response.data[index].description
        element.programeType = programeType
        schemeList.push(element)
      }
      setSchemesList(schemeList)
    }
  }

  const retriveApplicationFilesData = async (pageDetails: any) => {
    if (filters.programType != '' && filters.programType != null) {
      setLoading(true)
      const newFilter = produce(filters, (draft) => {
        draft.page = pageDetails.pageIndex + 1
      })
      setFilters(newFilter)
      const applicationData = await NewReportService.getReportStatsFileList(
        newFilter
      )
      setstate(applicationData.data)
      setLoading(false)
    }
  }

  async function retriveDataCountAndData(filterData: ReportFilterModel) {
    const applicationCountData = await NewReportService.getReportStatsFileCount(
      filterData
    )
    if (applicationCountData.status === 200) {
      setDatacount(applicationCountData.data.count)
      setPageCount(
        getPageCount(applicationCountData.data.count, filterData.limit)
      )
      const applicationData = await NewReportService.getReportStatsFileList(
        filterData
      )
      handleNotification(applicationData, 'Fetch Data Successfully')
      setstate(applicationData.data)
    } else {
      setDatacount(0)
      setPageCount(0)
      setstate([])
    }
  }

  const onSubmit = async () => {
    setLoading(true)
    const newFilter = produce(filters, (draft) => {
      draft.page = 1
    })
    setFilters(newFilter)
    await retriveDataCountAndData(newFilter)
    setLoading(false)
  }

  const handleView = (row: FileRegistrationModel) => {
    if (row.referenceNumber != null && row.referenceNumber != '-')
      setPrintReport({
        show: true,
        fileRefNo: row.referenceNumber,
        fileId: row.id,
      })
  }

  const onDownload = async () => {
    const filterdHeaders = reportHeaders.filter((r) => r.isSelected === true)

    const newFilters = produce(filters, (draft) => {
      draft.reportTableHeaders = filterdHeaders
    })

    dispatch(changeLoadingRequest())
    const requestOptions: any = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(newFilters),
    }
    fetch(
      `${process.env.baseUrl}report/application-report/application-payment-stats-report/download`,
      requestOptions
    )
      .then((response) => response.blob())
      .then((data) => {
        saveAs(data, 'analysis_report.xlsx')
        dispatch(changeLoadingRequest())
      })
  }

  const handleSelectAllHeaders = () => {
    const newData = produce(reportHeaders, (draft) => {
      draft.map((h) => (h.isSelected = true))
    })
    setReportHeaders(newData)
  }

  const columns = React.useMemo(() => reportTableColumn, [])
  return (
    <>
      {/* <Row className="title-layout">Complete Details Report</Row> */}

      <Row className="page-section-layout m-2 p-2">
        <Col sm={12} className="border-label">
          <div className="border-label-span">Filter Criteria</div>
          <div className="row mt-3 mb-3">
            <Col sm={4}>
              <Form.Group controlId="fromProgram">
                <Form.Label>Program Type </Form.Label>
                <Col>
                  <Select
                    options={programeTypes}
                    onChange={(selectedOption: any) => {
                      const newfilters = produce(
                        filters,
                        (draft: ReportFilterModel) => {
                          draft.programType = selectedOption
                            ? selectedOption.value
                            : ''
                          draft.scheme = ''
                        }
                      )
                      getSchemeListByProgrammeType(selectedOption.value)
                      setFilters(newfilters)
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group controlId="Scheme">
                <Form.Label>Scheme </Form.Label>
                <Col>
                  <Select
                    options={schemesList.filter(
                      (scheme) => scheme.programeType === filters.programType
                    )}
                    onChange={(selectedOption: any) => {
                      const newfilters = produce(
                        filters,
                        (draft: ReportFilterModel) => {
                          draft.scheme = selectedOption
                            ? selectedOption.value
                            : ''
                        }
                      )
                      setFilters(newfilters)
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group controlId="Incident">
                <Form.Label>Incident Type</Form.Label>
                <Col>
                  <Select
                    options={incidentList}
                    onChange={(selectedOption: any) => {
                      const newfilters = produce(
                        filters,
                        (draft: ReportFilterModel) => {
                          draft.incidentTypeId = selectedOption
                            ? selectedOption.value
                            : ''
                        }
                      )
                      setFilters(newfilters)
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </div>
          <div className="row mt-3 mb-3">
            <Col sm={4}>
              <Form.Group controlId="Status">
                <Form.Label>Status</Form.Label>
                <Col>
                  <Select
                    options={statsReportstatusList}
                    onChange={(selectedOption: any) => {
                      const newfilters = produce(
                        filters,
                        (draft: ReportFilterModel) => {
                          draft.status = selectedOption
                            ? selectedOption.value
                            : ''
                        }
                      )
                      setFilters(newfilters)
                      if (filters.scheme === 'MP') {
                        setReportHeaders(
                          mpStatReportTableHeaders(newfilters.status)
                        )
                      } else {
                        setReportHeaders(reportTableHeaders)
                      }
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group controlId="From">
                <Form.Label>From Date </Form.Label>
                <Col>
                  <DatePicker
                    className={'form-control'}
                    selected={
                      filters.from != null
                        ? new Date(filters.from * 1000)
                        : null
                    }
                    onSelect={(date: Date) => {
                      const newFilters = produce(
                        filters,
                        (draft: ReportFilterModel) => {
                          draft.from = date
                            ? new Date(date).getTime() / 1000
                            : null
                        }
                      )
                      setFilters(newFilters)
                    }}
                    onChange={(date: Date) => {
                      console.log(date)
                      const newFilters = produce(
                        filters,
                        (draft: ReportFilterModel) => {
                          draft.from = date
                            ? new Date(date).getTime() / 1000
                            : null
                        }
                      )
                      setFilters(newFilters)
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group controlId="toDate">
                <Form.Label>To Date </Form.Label>
                <Col>
                  <DatePicker
                    className={'form-control'}
                    selected={
                      filters.to != null ? new Date(filters.to * 1000) : null
                    }
                    onSelect={(date: Date) => {
                      const newFilters = produce(
                        filters,
                        (draft: ReportFilterModel) => {
                          draft.to = date
                            ? new Date(date).getTime() / 1000
                            : null
                        }
                      )
                      setFilters(newFilters)
                    }}
                    onChange={(date: Date) => {
                      console.log(date)
                      const newFilters = produce(
                        filters,
                        (draft: ReportFilterModel) => {
                          draft.to = date
                            ? new Date(date).getTime() / 1000
                            : null
                        }
                      )
                      setFilters(newFilters)
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </div>
          <div className="row mt-3 mb-3">
            <Col sm={4}>
              <Form.Group controlId="nicOld">
                <Form.Label>Old NIC Number </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    onChange={(e: any) => {
                      const newfilters = produce(
                        filters,
                        (draft: ReportFilterModel) => {
                          draft.oldNic = e.target.value
                        }
                      )
                      setFilters(newfilters)
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group controlId="NewNic">
                <Form.Label>New NIC Number </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    onChange={(e: any) => {
                      const newfilters = produce(
                        filters,
                        (draft: ReportFilterModel) => {
                          draft.newNic = e.target.value
                        }
                      )
                      setFilters(newfilters)
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group controlId="District">
                <Form.Label>District </Form.Label>
                <Col>
                  <Select
                    options={districtList}
                    onChange={(selectedOption: any) => {
                      const newfilters = produce(
                        filters,
                        (draft: ReportFilterModel) => {
                          draft.districtId = selectedOption
                            ? selectedOption.value
                            : ''
                        }
                      )
                      setFilters(newfilters)
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </div>

          <div className="row mt-3 mb-3">
            <Col sm={4}>
              <Form.Group controlId="Allocated">
                <Form.Label>Allocated User </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    onChange={(e: any) => {
                      const newfilters = produce(
                        filters,
                        (draft: ReportFilterModel) => {
                          draft.allocatedUser = e.target.value
                        }
                      )
                      setFilters(newfilters)
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group controlId="CreatedUser">
                <Form.Label>Created User</Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    onChange={(e: any) => {
                      const newfilters = produce(
                        filters,
                        (draft: ReportFilterModel) => {
                          draft.createdUser = e.target.value
                        }
                      )
                      setFilters(newfilters)
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group controlId="Contact">
                <Form.Label>Contact No</Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    onChange={(e: any) => {
                      const newfilters = produce(
                        filters,
                        (draft: ReportFilterModel) => {
                          draft.contactNo = e.target.value
                        }
                      )
                      setFilters(newfilters)
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </div>
          <div className="row mt-3 mb-3">
            <Col sm={4}>
              <Form.Group controlId="Allocated">
                <Form.Label>Reference Number </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    onChange={(e: any) => {
                      const newfilters = produce(
                        filters,
                        (draft: ReportFilterModel) => {
                          draft.referenceNumber = e.target.value
                        }
                      )
                      setFilters(newfilters)
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group controlId="omp">
                <Form.Label>OMP Number</Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    onChange={(e: any) => {
                      const newfilters = produce(
                        filters,
                        (draft: ReportFilterModel) => {
                          draft.ompNumber = e.target.value
                        }
                      )
                      setFilters(newfilters)
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group controlId="idn">
                <Form.Label>ID Number</Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    onChange={(e: any) => {
                      const newfilters = produce(
                        filters,
                        (draft: ReportFilterModel) => {
                          draft.idNumber = e.target.value
                        }
                      )
                      setFilters(newfilters)
                    }}
                  />
                </Col>
              </Form.Group>
            </Col>
          </div>

          <div className="d-flex justify-content-right mt-3 mb-3">
            <button
              disabled={!filters.programType || !filters.status}
              className={`me-4 ${
                !filters.programType || !filters.status
                  ? 'disable-button'
                  : 'save-button'
              }`}
              onClick={onSubmit}
            >
              Search
            </button>
          </div>
        </Col>
      </Row>

      <Row className="page-section-layout m-2 p-3">
        <Col sm={12} className="border-label">
          <div className="border-label-span">
            Select Table Headers For Report
          </div>
          <div className="row mt-3 mb-3 ">
            {reportHeaders.map((data: ReportTableHeaderModel, i: number) => {
              return (
                <Col sm={4} key={`col-${data.keyName}`}>
                  <Form.Check
                    checked={data.isSelected}
                    name={data.keyName}
                    label={data.headerName}
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                    onChange={(e) => {
                      const newData = produce(reportHeaders, (draft) => {
                        draft[i].isSelected = e.target.checked
                      })
                      setReportHeaders(newData)
                    }}
                  />
                </Col>
              )
            })}
          </div>
          <div className="d-flex justify-content-right mt-3 mb-3">
            <button className={`save-button`} onClick={handleSelectAllHeaders}>
              Select All
            </button>
            <button
              className={`save-button`}
              onClick={() => {
                if (filters.scheme === 'MP') {
                  setReportHeaders(mpStatReportTableHeaders(filters.status))
                } else {
                  setReportHeaders(reportTableHeaders)
                }
              }}
            >
              Reset
            </button>
            <button
              disabled={!filters.programType || !filters.status}
              className={`me-4 ${
                !filters.programType || !filters.status
                  ? 'disable-button'
                  : 'save-button'
              }`}
              onClick={onDownload}
            >
              Download
            </button>
          </div>
        </Col>
      </Row>
      <Row className="page-section-layout m-2 p-3">
        <div>
          <IterminalTableComponent
            columns={columns}
            data={state}
            fetchData={retriveApplicationFilesData}
            loading={loading}
            pageCount={pageCount}
            totalCount={dataCount}
            onClickRow={(row: any) => {
              handleView(row)
            }}
          />
        </div>
      </Row>
      <AllDetailsReportModal
        fileId={printReport.fileId}
        onCancel={() => {
          setPrintReport({ fileId: 0, show: false, fileRefNo: '' })
        }}
        showModal={printReport.show}
        fileRefNo={printReport.fileRefNo}
      />
    </>
  )
}

export default PaymentStatsReport
