export const FETCH_PERMISSIONS_LIST_REQUEST = 'FETCH_PERMISSIONS_LIST_REQUEST'
export const FETCH_PERMISSIONS_LIST_SUCCESS = 'FETCH_PERMISSIONS_LIST_SUCCESS'
export const FETCH_PERMISSIONS_LIST_FAILURE = 'FETCH_PERMISSIONS_LIST_FAILURE'
export const PACKAGE_ACTION_SELECT_REQUEST = 'PACKAGE_ACTION_SELECT_REQUEST'
export const PACKAGE_ACTION_DESELECT_REQUEST = 'PACKAGE_ACTION_DESELECT_REQUEST'
export const SUBPACKAGE_ACTION_SELECT_REQUEST =
  'SUBPACKAGE_ACTION_SELECT_REQUEST'
export const SUBPACKAGE_ACTION_DESELECT_REQUEST =
  'SUBPACKAGE_ACTION_DESELECT_REQUEST'
export const MODULE_ACTION_SELECT_REQUEST = 'MODULE_ACTION_SELECT_REQUEST'
export const MODULE_ACTION_DESELECT_REQUEST = 'MODULE_ACTION_DESELECT_REQUEST'
export const SUBMODULE_ACTION_SELECT_REQUEST = 'SUBMODULE_ACTION_SELECT_REQUEST'
export const SUBMODULE_ACTION_DESELECT_REQUEST =
  'SUBMODULE_ACTION_DESELECT_REQUEST'
export const PERMISSIONS_LIST_RESET_REQUEST = 'PERMISSIONS_LIST_RESET_REQUEST'
export const PERMISSIONS_LIST_CLEAR_REQUEST = 'PERMISSIONS_LIST_CLEAR_REQUEST'
export const SET_MODIFY_PERMISSION_LIST_REQUEST =
  'SET_MODIFY_PERMISSION_LIST_REQUEST'
export const DESELECT_ALL_ACTIONS_REQUEST = 'DESELECT_ALL_ACTIONS_REQUEST'
import { PackageModal } from '../../models/package/packageModal'

export interface PermissionsListState {
  pending: boolean
  permissions: PackageModal[]
  error: string | null
}

export interface PermissionsListResetPayload {
  permissions: PackageModal[]
}

export interface SetModifyPermissionsListRequest {
  type: typeof SET_MODIFY_PERMISSION_LIST_REQUEST
  payload: PermissionsListResetPayload
}

export interface PermissionsListResetRequest {
  type: typeof PERMISSIONS_LIST_RESET_REQUEST
  payload: PermissionsListResetPayload
}

export interface FetchPermissionsListSuccessPayload {
  permissions: PackageModal[]
}

export interface FetchPermissionsListFailurePayload {
  error: string
}

export interface FetchPermissionsListRequest {
  type: typeof FETCH_PERMISSIONS_LIST_REQUEST
}

export type FetchPermissionsListSuccess = {
  type: typeof FETCH_PERMISSIONS_LIST_SUCCESS
  payload: FetchPermissionsListSuccessPayload
}

export type FetchPermissionsListFailure = {
  type: typeof FETCH_PERMISSIONS_LIST_FAILURE
  payload: FetchPermissionsListFailurePayload
}

export interface PackageActionSelectRequestPayload {
  actionListId: number
  packageIndex: number
}
export interface PackageActionDeselectRequestPayload {
  actionListId: number
  packageIndex: number
}

export interface PackageActionSelectRequest {
  type: typeof PACKAGE_ACTION_SELECT_REQUEST
  payload: PackageActionSelectRequestPayload
}

export interface PackageActionDeselectRequest {
  type: typeof PACKAGE_ACTION_DESELECT_REQUEST
  payload: PackageActionDeselectRequestPayload
}

export interface SubPackageActionSelectRequestPayload {
  actionListId: number
  packageIndex: number
  subPackageIndex: number
}
export interface SubPackageActionDeselectRequestPayload {
  actionListId: number
  packageIndex: number
  subPackageIndex: number
}

export interface SubPackageActionSelectRequest {
  type: typeof SUBPACKAGE_ACTION_SELECT_REQUEST
  payload: SubPackageActionSelectRequestPayload
}

export interface SubPackageActionDeselectRequest {
  type: typeof SUBPACKAGE_ACTION_DESELECT_REQUEST
  payload: SubPackageActionDeselectRequestPayload
}

export interface ModuleActionSelectRequestPayload {
  actionListId: number
  packageIndex: number
  subPackageIndex: number
  moduleIndex: number
}
export interface ModuleActionDeselectRequestPayload {
  actionListId: number
  packageIndex: number
  subPackageIndex: number
  moduleIndex: number
}

export interface ModuleActionSelectRequest {
  type: typeof MODULE_ACTION_SELECT_REQUEST
  payload: ModuleActionSelectRequestPayload
}

export interface ModuleActionDeselectRequest {
  type: typeof MODULE_ACTION_DESELECT_REQUEST
  payload: ModuleActionDeselectRequestPayload
}

export interface SubModuleActionSelectRequestPayload {
  actionListId: number
  packageIndex: number
  subPackageIndex: number
  moduleIndex: number
  subModuleIndex: number
}
export interface SubModuleActionDeselectRequestPayload {
  actionListId: number
  packageIndex: number
  subPackageIndex: number
  moduleIndex: number
  subModuleIndex: number
}

export interface SubModuleActionSelectRequest {
  type: typeof SUBMODULE_ACTION_SELECT_REQUEST
  payload: SubModuleActionSelectRequestPayload
}

export interface SubModuleActionDeselectRequest {
  type: typeof SUBMODULE_ACTION_DESELECT_REQUEST
  payload: SubModuleActionDeselectRequestPayload
}

export interface ClearPermissionsListRequest {
  type: typeof PERMISSIONS_LIST_CLEAR_REQUEST
}

export interface DeselectAllActionsRequest {
  type: typeof DESELECT_ALL_ACTIONS_REQUEST
}

export type PermissionsListActions =
  | FetchPermissionsListRequest
  | FetchPermissionsListSuccess
  | FetchPermissionsListFailure
  | PackageActionSelectRequest
  | PackageActionDeselectRequest
  | SubPackageActionSelectRequest
  | SubPackageActionDeselectRequest
  | ModuleActionSelectRequest
  | ModuleActionDeselectRequest
  | SubModuleActionSelectRequest
  | SubModuleActionDeselectRequest
  | PermissionsListResetRequest
  | ClearPermissionsListRequest
  | SetModifyPermissionsListRequest
  | DeselectAllActionsRequest
