import { FilterModel } from '../../models/utils/filterModel'
import apiService from '../api/apiManager'

class FileAllocationDataService {
  async apiFetchFileAllocationList(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/file-registration/file-allocation/view/list`,
      filterData
    )
  }

  async apiFetchFileAllocationListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/file-registration/file-allocation/view/count`,
      filterData
    )
  }

  async apiFetchFileAllocationView(id: number) {
    return apiService.apiGet(
      `/application-registration/file-registration/file-allocation/view/${id}`
    )
  }

  async apiFetchFileAllocationPendingList(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/file-registration/file-allocation/pending-file-allocation/view/list`,
      filterData
    )
  }

  async apiFetchFileAllocationPendingListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/file-registration/file-allocation/pending-file-allocation/view/count`,
      filterData
    )
  }

  async apiFetchFileAllocationPendingView(id: number) {
    return apiService.apiGet(
      `/application-registration/file-registration/file-allocation/pending-file-allocation/view/${id}`
    )
  }

  async apiFetchFileAllocationReAllocate(id: number, name: string) {
    return apiService.apiPutCommon(
      `/application-registration/file-registration/file-allocation/${id}/reallocate/${name}`,
      {}
    )
  }

  async apiFetchFileAllocationPendingAllocate(id: number, name: string) {
    return apiService.apiPutCommon(
      `/application-registration/file-registration/file-allocation/pending-file-allocation/${id}/allocate/${name}`,
      {}
    )
  }
}

export default new FileAllocationDataService()
