import { FileRegistrationModel } from '../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { DependentAndRehabilitationDetailsModel } from '../../../models/applicationRegistration/loan/dependentAndRehabilitationDetails'
import { FilterModel } from '../../../models/utils/filterModel'
import apiService from '../../api/apiManager'

class SrtDataService {
  async apiFetchSrtList(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/loan/srt/view/list`,
      filterData
    )
  }

  async apiFetchSrtListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/loan/srt/view/count`,
      filterData
    )
  }

  async apiFetchSrtReturnList(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/loan/srt/file-return/view/list`,
      filterData
    )
  }

  async apiFetchSrtReturnListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/loan/srt/file-return/view/count`,
      filterData
    )
  }

  async apiFetchSrtAttributeList(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/loan/srt/attribute/view/list`,
      filterData
    )
  }

  async apiFetchSrtAttributeListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/loan/srt/attribute/view/count`,
      filterData
    )
  }

  async apiFetchSrtFile(id: number) {
    return apiService.apiGet(`/application-registration/loan/srt/view/${id}`)
  }

  async apiFetchSrtReturnFile(id: number) {
    return apiService.apiGet(
      `/application-registration/loan/srt/file-return/view/${id}`
    )
  }

  async apiSrtProceed(file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/application-registration/loan/srt/add/proceed`,
      file
    )
  }

  async apiSrtModify(file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/application-registration/loan/srt/file-return/modify/${file.id}`,
      file
    )
  }

  async apiSrtReject(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/application-registration/loan/srt/${id}/reject`,
      { remark: remark }
    )
  }

  async apiSrtReturn(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/application-registration/loan/srt/${id}/return`,
      { remark: remark }
    )
  }

  async apiSrtClose(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/application-registration/loan/srt/${id}/close`,
      { remark: remark }
    )
  }

  async apiSrtReOpen(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/application-registration/loan/srt/${id}/reopen`,
      { remark: remark }
    )
  }

  async apiSrtReRegister(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/application-registration/loan/srt/${id}/register`,
      { remark: remark }
    )
  }
  async apiSrtAddApplicantDetails(file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/application-registration/loan/srt/add/applicant`,
      file
    )
  }

  async apiSrtAddDependentAndOtherDetails(
    file: DependentAndRehabilitationDetailsModel
  ) {
    return apiService.apiPutCommon(
      `/application-registration/loan/srt/add/${file.applicationFileId}/dependent-and-other-details`,
      file
    )
  }

  async apiSrtGetDependentAndOtherDetails(id: number) {
    return apiService.apiGet(
      `/application-registration/loan/srt/view/${id}/dependent-and-other-details`
    )
  }

  async apiSrtAddDocument(file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/application-registration/loan/srt/add/${file.id}/document`,
      file
    )
  }

  async apiSrtGetDocument(id: number) {
    return apiService.apiGet(
      `/application-registration/loan/srt/view/${id}/document`
    )
  }

  async apiSrtGetSupportingDocument(id: number) {
    return apiService.apiGet(
      `/application-registration/loan/srt/view/${id}/document`
    )
  }

  async apiSrtAddSupportingDocument(formData: FormData, id: number) {
    return apiService.apiPutFormData(
      `/application-registration/loan/srt/add/${id}/document`,
      formData
    )
  }

  async apiSrtRegisterDocument(id: number) {
    return apiService.apiPutCommon(
      `/application-registration/loan/srt/add/${id}/register`,
      {}
    )
  }
}

export default new SrtDataService()
