import produce from 'immer'
import React, { useState } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import InvalidFeedback from '../../../components/common/invalidFeedback/InvalidFeedback'
import ConfirmationModal from '../../../components/modals/ConfirmationModal'
import { IGnDivision } from '../../../models/administrativeArea/IGnDivision'
import { ValidationObjectModel } from '../../../models/common/validationObjectModel'
import metaDataService from '../../../services/metaDataService'
import { gnFetchRequest } from '../../../store/administrativeArea/actions'
import {
  getDistritByDistrictId,
  getDsByDsId,
  getGnByGnId,
  getProvinceByProvinceId,
} from '../../../store/administrativeArea/selectors'
import { changeLoadingRequest } from '../../../store/app/actionTypes'
import {
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
  ALERT_SUCCESS,
} from '../../../utils/constants'
import { validationError } from '../../../utils/errorMessages'
import { showTotast } from '../../../utils/toast'
import { validateForm } from '../../../utils/utilFunctions'

interface ErrorModel {
  codeError?: string
  nameError?: string
}
type TParams = {
  dsId: string
  provinceId: string
  districtId: string
  gnId: string
}
const ModifyGnDivistion = ({ match }: RouteComponentProps<TParams>) => {
  const [state, setSate] = useState({} as IGnDivision)
  const history = useHistory()
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [errors, setErrors] = useState({} as ErrorModel)
  const dispatch = useDispatch()
  const distrit = useSelector(
    getDistritByDistrictId(parseInt(match.params.districtId))
  )

  const province = useSelector(
    getProvinceByProvinceId(parseInt(match.params.provinceId))
  )

  const ds = useSelector(getDsByDsId(parseInt(match.params.dsId)))

  const gn = useSelector(getGnByGnId(parseInt(match.params.gnId)))

  React.useEffect(() => {
    if (gn) {
      setSate({
        name: gn.name,
        code: gn.code,
        districtId: gn.districtId,
        id: gn.id,
        provinceId: gn.provinceId,
        dsDivisionId: gn.dsDivisionId,
      })
    }
  }, [gn])

  const findFormErrors = () => {
    const newErrors = {} as ErrorModel
    if (
      validateForm({
        maxLength: 75,
        type: 'text',
        value: state.name,
      } as ValidationObjectModel)
    ) {
      newErrors.nameError = validateForm({
        type: 'text',
        maxLength: 75,
        value: state.name,
        section: 'Name of the gn division',
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        maxLength: 16,
        type: 'text',
        value: state.code,
      } as ValidationObjectModel)
    ) {
      newErrors.codeError = validateForm({
        type: 'text',
        maxLength: 16,
        value: state.code,
        section: 'Code of the gn division',
      } as ValidationObjectModel)
    }

    return newErrors
  }

  const handleSubmit = () => {
    const foundErrros = findFormErrors()
    if (Object.keys(foundErrros).length > 0) {
      showTotast(
        ALERT_WARNING,
        validationError,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setErrors(foundErrros)
    } else {
      setErrors(foundErrros)
      setShowConfirmation(true)
    }
  }
  const handleConfirm = async () => {
    dispatch(changeLoadingRequest())
    const newState = produce(state, (draft) => {
      draft.districtId = distrit.id
      draft.provinceId = province.id
      draft.dsDivisionId = ds.id
    })

    const result = await metaDataService.apiUpdateGnDivistion(
      match.params.gnId,
      newState
    )
    let success = false
    let message = result.errorMessage ? result.errorMessage : ''
    if (result.status === 200) {
      success = true
      message = 'Gn Division successfully updated'
      dispatch(gnFetchRequest())

      history.goBack()
    }
    showTotast(
      success ? ALERT_SUCCESS : ALERT_WARNING,
      message,
      TOAST_POSITION_TOP_RIGHT,
      5,
      TOAST_TRANSITION_SLIDE
    )
    dispatch(changeLoadingRequest())
  }

  return (
    <>
      <Row className="title-layout">
        <Col className="d-flex justify-content-start">
          {/* <img
            src={
              '/src/assets/images/systemConfiguration/supporting-document.svg'
            }
            alt=""
          /> */}
          <span className="m-2">Manage Gn Division</span>
        </Col>
      </Row>
      <Row className="add-Incident-Type-page-layout">
        <Col sm={12} className="border-label">
          <div className="border-label-span">Modify Gn Division</div>
          <Form>
            <Form.Group className="mb-3" controlId="branchCode">
              <Form.Label>Province</Form.Label>
              <Form.Control disabled value={province && province.name} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="branchCode">
              <Form.Label>District</Form.Label>
              <Form.Control disabled value={distrit && distrit.name} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="branchCode">
              <Form.Label>DS Division</Form.Label>
              <Form.Control disabled value={ds && ds.name} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="branchCode">
              <Form.Label>
                Code <span className="required-field-astrix">*</span>
              </Form.Label>
              <Form.Control
                value={state.code}
                isInvalid={!!errors.codeError}
                type="text"
                onChange={(e) => {
                  const newVal = produce(state, (draft) => {
                    draft.code = e.target.value
                  })
                  setSate(newVal)
                  const clearError = produce(errors, (draft) => {
                    draft.codeError = ''
                  })
                  setErrors(clearError)
                }}
              />
              <InvalidFeedback message={errors.codeError} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="bankName">
              <Form.Label>
                Name <span className="required-field-astrix">*</span>
              </Form.Label>
              <Form.Control
                value={state.name}
                isInvalid={!!errors.nameError}
                type="text"
                onChange={(e) => {
                  const newVal = produce(state, (draft) => {
                    draft.name = e.target.value
                  })
                  setSate(newVal)
                  const clearError = produce(errors, (draft) => {
                    draft.nameError = ''
                  })
                  setErrors(clearError)
                }}
              />
              <InvalidFeedback message={errors.nameError} />
            </Form.Group>
          </Form>
          <Row>
            <Col sm={12} className="d-flex justify-content-end mt-4">
              <button
                className="cancel-button"
                onClick={(e) => {
                  e.preventDefault()
                  history.goBack()
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="save-button"
                onClick={handleSubmit}
              >
                Save
              </button>
            </Col>
          </Row>
        </Col>
        {showConfirmation && (
          <ConfirmationModal
            name={'Confirmation'}
            message={'Are you sure to update this gn division ?'}
            onCancel={() => {
              setShowConfirmation(false)
            }}
            onConfirm={handleConfirm}
          />
        )}
      </Row>
    </>
  )
}

export default ModifyGnDivistion
