import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import stamp_img from '../../../assets/images/systemConfiguration/manage-stamp-charges.svg'
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
  //   useLocation,
} from 'react-router-dom'
import ReactTableWithPaginationComponent from '../../../components/table/ReactTableWithPaginationComponent'
import ActionButtonComponent from '../../../components/common/actionButton/ActionButtonComponent'
import './ManageStampChargeComponent.scss'
import manageStampChargesService from '../../../services/systemConfiguration/manageStampCharges/manageStampChargesService'
import addstampChargers from './ManageStampChargers'
import stampChargersView from './ManageStampChargerView'
import stampChargersAmend from './ManageStampChargeModify'
import { currencyMask } from '../../../utils/utilFunctions'

const ManageStampChargeComponent = () => {
  const [stampChargeList, setstampChargeList] = useState([])
  const { path } = useRouteMatch()
  const history = useHistory()
  const location = useLocation()

  const handleView = (id: number) => {
    history.push(`${path}/view/${id}`)
  }
  useEffect(() => {
    async function getManageStampChargeList() {
      const stampChargeDataList =
        await manageStampChargesService.apiGetStampChargeList()
      setstampChargeList(stampChargeDataList.data)
    }
    getManageStampChargeList()
  }, [location.pathname])
  // const columns = React.useMemo(
  //   () => [
  //     {
  //       Header: 'Stamp Charge (Rs.)',
  //       accessor: 'stampCharge', // accessor is the "key" in the data
  //     },
  //     {
  //       Header: 'Start Date',
  //       accessor: 'startDate',
  //     },
  //     {
  //       Header: 'EndDate',
  //       accessor: 'endDate', // accessor is the "key" in the data
  //     },
  //     {
  //       Header: 'Status',
  //       accessor: 'status',
  //     },
  //   ],
  //   []
  // )
  const columns = React.useMemo(
    () => [
      {
        Header: 'Stamp Charge (Rs.)',
        accessor: 'stampCharge',
        Cell: (e: any) => {
          if (e.cell.value) {
            return (
              <div className="cell-align-right">
                {e.row.original.stampCharge
                  ? currencyMask(e.row.original.stampCharge)
                  : ''}
              </div>
            )
          } else if (e.cell.value == 0 || e.cell.value == null) {
            return <div className="cell-align-right">{'N/A'}</div>
          }
        },
      },
      {
        Header: 'Start Date',
        accessor: 'startDate',
      },
      {
        Header: 'End Date',
        accessor: 'endDate',
        Cell: (e: any) => {
          if (e.cell.value != null) {
            return (
              <div>{new Date(e.cell.value * 1000).toLocaleDateString()}</div>
            )
          } else {
            return <div>{'N/A'}</div>
          }
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
    ],
    []
  )

  return (
    <Switch>
      <Route path={`${path}/`} exact>
        <Row className="title-layout">
          <Col className="d-flex justify-content-start">
            <img src={stamp_img} alt="" />
            <span className="m-2">Manage Stamp Charges</span>
          </Col>
        </Row>
        <Row className="role-and-permission-body-layout mt-4">
          {/* {checkPermission('add') === true && ( */}
          <Col sm={12} className="d-flex justify-content-end">
            <ActionButtonComponent name="Add" routeURL="add" />
          </Col>
          {/* )} */}
          <Col sm={12}>
            <ReactTableWithPaginationComponent
              columns={columns}
              data={stampChargeList}
              onClickRow={(row: any) => {
                handleView(row.id)
              }}
            />
          </Col>
        </Row>
      </Route>
      <Route path={`${path}/add`} component={addstampChargers} />
      <Route path={`${path}/view/:id`} component={stampChargersView} />
      <Route path={`${path}/modify/:id`} component={stampChargersAmend} />
    </Switch>
  )
}

export default ManageStampChargeComponent
