import { FileRegistrationModel } from '../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { FilterModel } from '../../../models/utils/filterModel'
import apiService from '../../api/apiManager'

class GscDataService {
  async apiFetchGscList(filterData: FilterModel) {
    return apiService.apiPost(
      `/compensation-certification/compensation/gsc/view/list`,
      filterData
    )
  }

  async apiFetchGscListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/compensation-certification/compensation/gsc/view/count`,
      filterData
    )
  }

  async apiFetchGscReturnList(filterData: FilterModel) {
    return apiService.apiPost(
      `/compensation-certification/compensation/gsc/file-return/view/list`,
      filterData
    )
  }

  async apiFetchGscReturnListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/compensation-certification/compensation/gsc/file-return/view/count`,
      filterData
    )
  }

  async apiApproveGscFile(id: number) {
    return apiService.apiPutCommon(
      `/compensation-certification/compensation/gsc/${id}/approve`,
      {}
    )
  }

  async apiRejectGscFile(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/compensation-certification/compensation/gsc/${id}/reject`,
      { remark: remark }
    )
  }
  async apiReturnGscFile(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/compensation-certification/compensation/gsc/${id}/return`,
      { remark: remark }
    )
  }
  async apiUpdateGscFile(id: number, file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/compensation-certification/compensation/gsc/file-return/${id}`,
      file
    )
  }
  async apiGetReturnGscFile(id: number) {
    return apiService.apiGet(
      `/compensation-certification/compensation/gsc/file-return/view/${id}`
    )
  }
  async apiGetGscFile(id: number) {
    return apiService.apiGet(
      `/compensation-certification/compensation/gsc/view/${id}`
    )
  }
}

export default new GscDataService()
