import { useRouteMatch, useHistory } from 'react-router-dom'
import {
  commonColumns,
  initFilterOption,
} from '../../../../components/table/advancedSearchTable/AdvancedSearchTableUtil'
import AdvancedSearchTable from '../../../../components/table/advancedSearchTable/AdvancedSearchTable'

const MpApplication = () => {
  const { path } = useRouteMatch()
  const history = useHistory()

  const handleView = (id: number) => {
    history.push(`${path}view/${id}`)
  }

  return (
    <>
      {/* {!loading && (
        <div className="mb-4">
          <CompensationApplicationsFilter
            scheme="PD"
            filterData={filters}
            onFilter={(e: FilterOptionModel[]) => {
              dispatch(filtersSetRequest({ filters: e }))
              retriveFilterData(
                e.filter(
                  (filter: FilterOptionModel) => filter.selected === true
                )
              )
            }}
          />
        </div>
      )}
      <div>
        <IterminalTableComponent
          columns={columns}
          data={state}
          fetchData={retriveApplicationFilesData}
          loading={loading}
          pageCount={pageCount}
          totalCount={dataCount}
          onClickRow={(row: any) => {
            handleView(row.id)
          }}
        />
      </div> */}
      <AdvancedSearchTable
        initFilterOption={initFilterOption}
        columns={commonColumns}
        dataApiPath={'/compensation-recommendation/compensation/mp/view/list'}
        countApiPath={'/compensation-recommendation/compensation/mp/view/count'}
        onClickRow={(row: any) => {
          handleView(row.id)
        }}
      />
    </>
  )
}

export default MpApplication
