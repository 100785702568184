import produce from 'immer'
import React, { FC, useState } from 'react'
import { Form, Modal, Row } from 'react-bootstrap'
import { ClosePrintModel } from '../../models/utils/closePrintModel'
import { languages } from '../../utils/constants'
import Select from 'react-select'

export interface ClosePrintModalProps {
  onConfirm?: any
  onCancel?: any
}

const ClosePrintModal: FC<ClosePrintModalProps> = ({ onConfirm, onCancel }) => {
  const [data, setData] = useState({
    language: '',
    type: '',
  } as ClosePrintModel)

  const checkDisable = () => {
    if (data.language == '') {
      return true
    }
    return false
  }

  const handleClick = () => {
    console.log(data)
    onConfirm(data)
  }
  return (
    <Modal
      size="lg"
      show={true}
      onHide={onCancel}
      aria-labelledby="example-modal-sizes-title-sm"
      dialogClassName="custom-modal-layout"
      contentClassName="custom-modal-content"
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        className="custom-modal-header"
      >
        <Modal.Title id="example-modal-sizes-title-sm">
          Print Close Letter
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row className="mb-3">
            <Form.Group className="mb-3" controlId="formLanguage">
              <Form.Label>Language</Form.Label>
              <Select
                options={languages}
                onChange={(selectedOption) => {
                  const newData = produce(data, (draft) => {
                    draft.language = selectedOption ? selectedOption.value : ''
                  })
                  setData(newData)
                }}
              />
            </Form.Group>
          </Row>
          {/* <Row className="mb-3">
            <Form.Group className="mb-3" controlId="formType">
              <Form.Label>Export Type</Form.Label>
              <Select
                options={types}
                onChange={(selectedOption) => {
                  const newData = produce(data, (draft) => {
                    draft.type = selectedOption ? selectedOption.value : ''
                  })
                  setData(newData)
                }}
              />
            </Form.Group>
          </Row> */}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button
          disabled={checkDisable()}
          className={`${checkDisable() ? 'disable-button' : 'save-button'}`}
          onClick={handleClick}
        >
          Print
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default ClosePrintModal
