/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable eslint-comments/no-duplicate-disable */
/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/display-name */
import React, { FC, useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { FileRegistrationModel } from '../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { DependentAndRehabilitationDetailsModel } from '../../models/applicationRegistration/loan/dependentAndRehabilitationDetails'
import { ResponseObject } from '../../services/api/apiManager'
import common from '../../services/applicationRegistration/common'
import enquireApplicationService from '../../services/enquireApplication/enquireApplicationService'
import { currencyMask } from '../../utils/utilFunctions'
import LoadingComponent from '../common/loading/LoadingComponent'
import ReactTableWithPaginationComponent from '../table/ReactTableWithPaginationComponent'

export interface DependentAndRehabilitationSectionProps {
  fileRegistrationObject: FileRegistrationModel
  onOk: any
  packageName?: string
}

const DependentAndRehabilitationSection: FC<DependentAndRehabilitationSectionProps> =
  ({
    fileRegistrationObject,
    onOk,
    packageName = 'application-registration',
  }) => {
    const [loading, setLoading] = useState(true)

    const [dependentDetails, setDependentDetails] = useState(
      {} as DependentAndRehabilitationDetailsModel
    )

    useEffect(() => {
      async function getMetaData() {
        let dependentDetailsData = {} as ResponseObject
        if (packageName === 'enquire-application') {
          dependentDetailsData =
            await enquireApplicationService.apiSrtGetDependentAndOtherDetails(
              fileRegistrationObject.id,
              fileRegistrationObject.scheme.toLowerCase()
            )
        } else {
          dependentDetailsData = await common.apiGetDependentAndOtherDetails(
            packageName,
            fileRegistrationObject.programType.toLowerCase(),
            fileRegistrationObject.scheme.toLowerCase(),
            fileRegistrationObject.id
          )
        }
        if (dependentDetailsData.status === 200) {
          setDependentDetails(dependentDetailsData.data)
        }
        setLoading(false)
      }
      getMetaData()
    }, [])

    const columns = React.useMemo(
      () => [
        {
          Header: 'Name',
          accessor: 'name',
        },
        {
          Header: 'Relationship',
          accessor: 'relationshipToApplicant',
        },
        {
          Header: 'Gender',
          accessor: 'gender',
        },
        {
          Header: 'Age',
          accessor: 'age',
        },
        {
          Header: 'Occupation',
          accessor: 'occupation',
        },
        {
          Header: 'Family Monthly Income (Rs.)',
          accessor: 'familyMonthlyIncome',
        },
      ],
      []
    )

    return (
      <>
        {loading ? (
          <LoadingComponent />
        ) : (
          <>
            <Col sm={12} className="border-label">
              <div className="border-label-span">
                Dependents and Rehabilitation Details
              </div>
              <Form id="file-dependent-details-form">
                <Col sm={12} className="border-label">
                  <div className="border-label-span">Dependent Details</div>
                  <Col sm={12}>
                    <ReactTableWithPaginationComponent
                      columns={columns}
                      data={dependentDetails.dependentList}
                      onClickRow={(row: any) => {}}
                    />
                  </Col>
                </Col>
                <Col sm={12} className="border-label mt-4 mb-3">
                  <div className="border-label-span">
                    Rehabilitation Details
                  </div>
                  <Form.Group
                    className="mb-3"
                    controlId="formNameOfRehabilitation"
                  >
                    <Form.Label>
                      Name of the Rehabilitation Institute
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name of the Rehabilitation Institute"
                      disabled
                      value={dependentDetails.nameOfTheRehabilitationInstitute}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formDetailsOfTrain">
                    <Form.Label>Details of the training received</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      placeholder="Details of the training received"
                      value={dependentDetails.detailsOfTheTrainingReceived}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formTrainingPeriod">
                    <Form.Label>Training Period(Months)</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      placeholder="Training Period"
                      value={dependentDetails.trainingPeriod}
                    />
                  </Form.Group>
                </Col>
                <Col sm={12} className="border-label mt-4 mb-3">
                  <div className="border-label-span">
                    Self-Employment Details
                  </div>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formPlantToStart">
                      <Form.Label>
                        Plan to start a Self-Employment based on the training
                      </Form.Label>
                      <Row>
                        <Col>
                          <Form.Check
                            disabled
                            inline
                            name="formPlantToStart"
                            label="Yes"
                            type="radio"
                            id={`formPlantToStart-N`}
                            checked={
                              dependentDetails.planToStartASelfEmploymentBasedOnTheTraining
                            }
                          />
                        </Col>
                        <Col>
                          <Form.Check
                            disabled
                            inline
                            name="formPlantToStart"
                            label="No"
                            type="radio"
                            id={`formPlantToStart-O`}
                            checked={
                              !dependentDetails.planToStartASelfEmploymentBasedOnTheTraining
                            }
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Row>
                  <Form.Group className="mb-3" controlId="formNatureOfSelf">
                    <Form.Label>Nature of the Self-Employment</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      placeholder="Nature of the Self-Employment"
                      value={dependentDetails.natureOfTheSelfEmployment}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="formSelfEmpployementCategory"
                  >
                    <Form.Label>Self-Employment Category</Form.Label>
                    <Form.Control
                      disabled
                      type="text"
                      placeholder="Self-Employment Category"
                      value={dependentDetails.selfEmploymentCategory}
                    />
                  </Form.Group>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formHavingPreviousExp">
                      <Form.Label>
                        Having previous experiences on the employment you
                        applied
                      </Form.Label>
                      <Row>
                        <Col>
                          <Form.Check
                            disabled
                            inline
                            name="formHavingPreviousExp"
                            label="Yes"
                            type="radio"
                            id={`formHavingPreviousExp-N`}
                            checked={
                              dependentDetails.havingPreviousExperiencesOnTheEmploymentYouApplied
                            }
                          />
                        </Col>
                        <Col>
                          <Form.Check
                            disabled
                            inline
                            name="formHavingPreviousExp"
                            label="No"
                            type="radio"
                            id={`formHavingPreviousExp-O`}
                            checked={
                              !dependentDetails.havingPreviousExperiencesOnTheEmploymentYouApplied
                            }
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Row>
                </Col>
                <Col sm={12} className="border-label mt-4 mb-3">
                  <div className="border-label-span">
                    Loan details of Family members
                  </div>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formAnyLoanRecieved">
                      <Form.Label>
                        Any loan received from Rehabilitation Authority
                      </Form.Label>
                      <Row>
                        <Col>
                          <Form.Check
                            disabled
                            inline
                            name="formAnyLoanRecieved"
                            label="Yes"
                            type="radio"
                            id={`formAnyLoanRecieved-N`}
                            checked={
                              dependentDetails.anyLoanReceivedFromRehabilitationAuthority
                            }
                          />
                        </Col>
                        <Col>
                          <Form.Check
                            disabled
                            inline
                            name="formAnyLoanRecieved"
                            label="No"
                            type="radio"
                            id={`formAnyLoanRecieved-O`}
                            checked={
                              !dependentDetails.anyLoanReceivedFromRehabilitationAuthority
                            }
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Row>
                  <Form.Group
                    className="mb-3"
                    controlId="formNameOfLoanReciepient"
                  >
                    <Form.Label>Name of the Loan recipient</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      placeholder="Name of the Loan recipient"
                      value={dependentDetails.nameOfTheLoanRecipient}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="formRelationshipToApplicant"
                  >
                    <Form.Label>Relationship to the Applicant</Form.Label>
                    <Form.Control
                      disabled
                      type="text"
                      placeholder="Relationship to the Applican"
                      value={dependentDetails.relationshipToApplicant}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formObtainLoan">
                    <Form.Label>Obtained Loan Amount (Rs.)</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      placeholder="Obtained Loan Amount"
                      value={
                        dependentDetails.obtainedLoanAmount
                          ? currencyMask(dependentDetails.obtainedLoanAmount)
                          : ''
                      }
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formInterestRate">
                    <Form.Label>Interest Rate</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      placeholder="Interest Rate"
                      value={dependentDetails.interestRate}
                    />
                  </Form.Group>
                </Col>
                <Col sm={12} className="border-label mt-4 mb-3">
                  <div className="border-label-span">Other Details</div>
                  <Form.Group className="mb-3" controlId="formOtherDetails">
                    <Form.Label>Any Other Details</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      placeholder="Any Other Details"
                      value={dependentDetails.otherDetails}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formExpectedLoan">
                    <Form.Label>Expected Loan Amount (Rs.)</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      placeholder="Expected Loan Amount"
                      value={currencyMask(dependentDetails.expectedLoanAmount)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formOtherDetails">
                    <Form.Label>Approved Loan Amount (Rs.)</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      placeholder="Approved Loan Amount"
                      value={currencyMask(dependentDetails.grantedLoanAmount)}
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formOtherDetails">
                    <Form.Label>Repayment Period (in months)</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      placeholder="Repayment Period"
                      value={dependentDetails.repaymentPeriod}
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formOtherDetails">
                    <Form.Label>Grace Period (in months)</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      placeholder="Grace Period"
                      value={dependentDetails.gracePeriod}
                    />
                  </Form.Group>
                </Col>
                <Col sm={12} className="border-label mt-4 mb-3">
                  <div className="border-label-span">{`Bank Details`}</div>
                  <Row>
                    <Form.Group as={Col} controlId="formBankName">
                      <Form.Label>{`Bank Name`}</Form.Label>
                      <Form.Control
                        disabled
                        type="text"
                        placeholder="Bank Name"
                        value={
                          dependentDetails.bank
                            ? dependentDetails.bank.name
                            : ''
                        }
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formBranchkName">
                      <Form.Label>{`Branch Name`}</Form.Label>
                      <Form.Control
                        disabled
                        type="text"
                        placeholder="Branch Name"
                        value={
                          dependentDetails.branch
                            ? dependentDetails.branch.name
                            : ''
                        }
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formAccountNumber">
                      <Form.Label>Account Number</Form.Label>
                      <Form.Control
                        type="text"
                        disabled
                        placeholder="Account Number"
                        value={dependentDetails.accountNumber}
                      />
                    </Form.Group>
                  </Row>
                </Col>
              </Form>
            </Col>
            <Col sm={12} className="d-flex justify-content-end mt-4">
              <button className="cancel-button" onClick={onOk}>
                Ok
              </button>
            </Col>
          </>
        )}
      </>
    )
  }

export default DependentAndRehabilitationSection
