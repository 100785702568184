import produce from 'immer'
import React, { FC, useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
//import Select from 'react-select'
import { DSDivisionModal } from '../../models/administrativeArea/dsDivisionModal'
import { InstallmentModel } from '../../models/applicationRegistration/compensation/installmentModel'
import { PdAggrivedPersonDetailsModel } from '../../models/applicationRegistration/compensation/pd/pdAggrivedPerson'
import { OtherAssistanceModel } from '../../models/applicationRegistration/compensation/OtherAssistanceModel'
import { dsSelectionModal } from '../../models/applicationRegistration/fileRegistration/dsSelectionModel'
import { FileRegistrationModel } from '../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { DistrictOption } from '../../models/selectOptionModels/districtOption'
import { DsOption } from '../../models/selectOptionModels/dsOption'
import { GnOption } from '../../models/selectOptionModels/gnOption'
import { IncidentTypeOption } from '../../models/selectOptionModels/incidentTypeOption'
import { ProvinceOption } from '../../models/selectOptionModels/provinceOption'
import { ResponseObject } from '../../services/api/apiManager'
import common from '../../services/applicationRegistration/common'
import enquireApplicationService from '../../services/enquireApplication/enquireApplicationService'
import metaDataService from '../../services/metaDataService'
import {
  getProvincesOptions,
  geDistrictsOptions,
  getDsOptions,
  getGnOptions,
} from '../../store/administrativeArea/selectors'
import {
  renderIdentificationTypeName,
  renderIdentificationTypeValue,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getInjuryTypeDisabled,
  getConfirmationOfDeath,
  currencyMask,
  thousandSeperator,
} from '../../utils/utilFunctions'
import IdentificationTypesComponent from '../common/identificationComponent/IdentificationTypesComponent'
import LoadingComponent from '../common/loading/LoadingComponent'

export interface SectionThreeProps {
  fileRegistrationObject: FileRegistrationModel
  packageName?: string
  onOk?: any
}

const SectionThree: FC<SectionThreeProps> = ({
  fileRegistrationObject,
  packageName = 'application-registration',
  onOk,
}) => {
  const [loading, setLoading] = useState(true)
  const provinceList = useSelector(getProvincesOptions)
  const districtList = useSelector(geDistrictsOptions)
  const dsList = useSelector(getDsOptions)
  const gnList = useSelector(getGnOptions)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [incidentList, setIncidentList] = useState([] as IncidentTypeOption[])
  const [selectedDeathCertificateDs, setSelectedDeathCertificateDs] = useState(
    {} as dsSelectionModal
  )
  const [otherAssistantance, setOtherAssistantance] = useState(
    {} as OtherAssistanceModel
  )
  const [aggrivedPersonDetails, setAggrivedPersonDetails] = useState({
    birthCertificateDsDivision: {} as DSDivisionModal,
  } as PdAggrivedPersonDetailsModel)
  const [installmentList, setInstallmentList] = useState(
    [] as InstallmentModel[]
  )
  useEffect(() => {
    async function getMetaData() {
      const incidentTypeData = await metaDataService.apiIncidentTypeList()
      const incidentTypeTempList: IncidentTypeOption[] = []
      for (let i = 0; i < incidentTypeData.data.length; i++) {
        const incident = {} as IncidentTypeOption
        incident.value = incidentTypeData.data[i].id
        incident.label = incidentTypeData.data[i].incidentType
        incidentTypeTempList.push(incident)
      }

      setIncidentList(incidentTypeTempList)
      let aggrivedDetailsData = {} as ResponseObject
      if (packageName === 'enquire-application') {
        aggrivedDetailsData =
          await enquireApplicationService.apiGetAggrivedDetails(
            fileRegistrationObject.id,
            fileRegistrationObject.scheme.toLowerCase()
          )
      } else {
        aggrivedDetailsData = await common.apiGetAggrivedDetails(
          packageName,
          fileRegistrationObject.programType.toLowerCase(),
          fileRegistrationObject.scheme.toLowerCase(),
          fileRegistrationObject.id
        )
      }
      // aggrivedDetailsData = await common.apiGetAggrivedDetails(
      //   packageName,
      //   fileRegistrationObject.programType.toLowerCase(),
      //   fileRegistrationObject.scheme.toLowerCase(),
      //   fileRegistrationObject.id
      // )

      if (aggrivedDetailsData.data.otherAssistanceList.length > 0) {
        const newAssitanceDetails = produce(otherAssistantance, (draft) => {
          draft.institution =
            aggrivedDetailsData.data.otherAssistanceList[0].institution
          draft.receivedDate =
            aggrivedDetailsData.data.otherAssistanceList[0].receivedDate
          draft.amountReceived =
            aggrivedDetailsData.data.otherAssistanceList[0].amountReceived
        })
        setOtherAssistantance(newAssitanceDetails)
      }

      if (aggrivedDetailsData.data && aggrivedDetailsData.data.id != null) {
        setAggrivedPersonDetails(aggrivedDetailsData.data)
        const newSelectedDeathCertificateDs = produce(
          selectedDeathCertificateDs,
          (draft: dsSelectionModal) => {
            if (fileRegistrationObject.deathCertificateDsDivision) {
              draft.provinceId =
                fileRegistrationObject.deathCertificateDsDivision.provinceId
              draft.districtId =
                fileRegistrationObject.deathCertificateDsDivision.districtId
              draft.dsId = fileRegistrationObject.deathCertificateDsDivisionId
            }
          }
        )
        setSelectedDeathCertificateDs(newSelectedDeathCertificateDs)
      } else {
        const newFormValue = produce(
          aggrivedPersonDetails,
          (draft: PdAggrivedPersonDetailsModel) => {
            draft.applicationFileId = fileRegistrationObject.id
            draft.incidentTypeId = fileRegistrationObject.incidentTypeId
            draft.deathCertificateNumber =
              fileRegistrationObject.deathCertificateNumber
            draft.dcIssuedDsDivisionId =
              fileRegistrationObject.deathCertificateDsDivisionId
            draft.newNic = true
            draft.birthCertificateDsDivision =
              fileRegistrationObject.birthCertificateDsDivision
            draft.birthCertificateDsDivisionId =
              fileRegistrationObject.birthCertificateDsDivisionId
            if (
              fileRegistrationObject.scheme === 'PD' ||
              fileRegistrationObject.scheme === 'GSD'
            ) {
              draft.typeOfInjuryId = 2
            }
          }
        )
        setAggrivedPersonDetails(newFormValue)
        if (fileRegistrationObject.applicantType === 'Primary Applicant') {
          const newFormValue = produce(
            aggrivedPersonDetails,
            (draft: PdAggrivedPersonDetailsModel) => {
              draft.fullName = fileRegistrationObject.applicantName
              draft.identificationType =
                fileRegistrationObject.identificationType
              draft.newNic = fileRegistrationObject.newNic
              draft.newNicNumber = fileRegistrationObject.newNicNumber
              draft.oldNicNumber = fileRegistrationObject.oldNicNumber
              draft.birthCertificateDsDivision =
                fileRegistrationObject.birthCertificateDsDivision
              draft.birthCertificateDsDivisionId =
                fileRegistrationObject.birthCertificateDsDivisionId
              draft.passportNumber = fileRegistrationObject.passportNumber
              draft.seniorCitizenId = fileRegistrationObject.seniorCitizenId
              draft.drivingLicenseNumber =
                fileRegistrationObject.drivingLicenseNumber
              draft.dateOfBirth = fileRegistrationObject.dateOfBirth
              draft.age = fileRegistrationObject.age
              draft.gender = fileRegistrationObject.gender
            }
          )
          setAggrivedPersonDetails(newFormValue)
        }
        if (fileRegistrationObject.scheme === 'PD') {
          const newSelectedDeathCertificateDs = produce(
            selectedDeathCertificateDs,
            (draft: dsSelectionModal) => {
              draft.provinceId =
                fileRegistrationObject.deathCertificateDsDivision.provinceId
              draft.districtId =
                fileRegistrationObject.deathCertificateDsDivision.districtId
              draft.dsId = fileRegistrationObject.deathCertificateDsDivisionId
            }
          )
          setSelectedDeathCertificateDs(newSelectedDeathCertificateDs)
        }
      }
      const installmentData =
        await metaDataService.getApplicationInstallmentAmountList(
          fileRegistrationObject.id
        )

      if (installmentData.status === 200) {
        const sortedIntallment = installmentData.data.sort((a, b) => {
          return a.installmentNumber - b.installmentNumber
        })
        setInstallmentList(sortedIntallment)
      }
      setLoading(false)
    }
    getMetaData()
  }, [])

  const renderMergedInstallmentByMegeNumber = (mergeNumber: number) => {
    const mergedInstallment = installmentList.filter(
      (installment: InstallmentModel) => {
        if (installment.mergeNumber === mergeNumber) {
          return installment
        }
      }
    )

    return mergedInstallment.map(
      (installment: InstallmentModel, index: number) => {
        return (
          <div
            className={`${index != mergedInstallment.length - 1 ? 'mb-3' : ''}`}
            key={`installment-merge-${index}`}
          >
            {`Installment ${
              installment.installmentNumber
            } Rs. ${thousandSeperator(installment.installmentAmount)}`}
          </div>
        )
      }
    )
  }

  const checkPreviousHasMergeSame = (index: number, mergeNumber: number) => {
    if (index == 0 || mergeNumber == 0) {
      return false
    }
    const previousInstallment = installmentList[index - 1]
    if (previousInstallment.mergeNumber === mergeNumber) {
      return true
    }

    return false
  }

  const renderInstallmentList = () => {
    return installmentList.map(
      (installment: InstallmentModel, index: number) => {
        return (
          <div key={`installment-${installment.id}`}>
            {!checkPreviousHasMergeSame(index, installment.mergeNumber) && (
              <ul key={`installment-ul-${installment.id}`}>
                {installment.mergeNumber == 0 && (
                  <Form.Check
                    key={`installment-check-${installment.id}`}
                    label={`Installment ${installment.installmentNumber} Rs. ${
                      installment.installmentAmount
                        ? currencyMask(installment.installmentAmount)
                        : ''
                    }`}
                    name={`installment`}
                    className="custom-form-check"
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                    disabled
                    checked={installment.merge || installment.isChecked}
                  />
                )}
                {installment.mergeNumber > 0 && (
                  <div className="row mb-0">
                    <div className="col max-width-content">
                      <Form.Check
                        className="custom-form-check"
                        disabled={true}
                        checked={true}
                        label={renderMergedInstallmentByMegeNumber(
                          installment.mergeNumber
                        )}
                      />
                    </div>
                  </div>
                )}
              </ul>
            )}
          </div>
        )
      }
    )
  }

  return (
    <>
      {loading ? (
        <div className="loading-layout">
          <LoadingComponent />
        </div>
      ) : (
        <>
          <Col sm={12} className="border-label">
            <div className="border-label-span">Aggrieved Person Details</div>
            <Form id="file-aggrieved-person-details-form">
              <Form.Group className="mb-3" controlId="formGroupTitle">
                <Form.Label>
                  Title <span className="required-field-astrix">*</span>
                </Form.Label>
                <Form.Control
                  disabled={true}
                  placeholder="Title"
                  value={aggrivedPersonDetails.title}
                ></Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formApplicantName">
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                  disabled={true}
                  type="text"
                  placeholder="Full Name"
                  value={aggrivedPersonDetails.fullName}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formSelection">
                <Form.Label>Identification Type</Form.Label>
                <IdentificationTypesComponent
                  isDisabled={true}
                  identificationType={aggrivedPersonDetails.identificationType}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formApplicantDob">
                <Form.Label>Date of birth</Form.Label>
                <Form.Control
                  disabled={true}
                  type="text"
                  placeholder="Date of birth"
                  value={
                    aggrivedPersonDetails.dateOfBirth != null
                      ? new Date(
                          aggrivedPersonDetails.dateOfBirth * 1000
                        ).toLocaleDateString()
                      : undefined
                  }
                />
              </Form.Group>
              {aggrivedPersonDetails.identificationType !=
                'BIRTH_CERTIFICATE' && (
                <>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formNICNumber">
                      <Form.Label>NIC Number *</Form.Label>
                      <Row>
                        <Col sm={1}>
                          <Form.Check
                            inline
                            name="formNICNumberType"
                            label="New"
                            type="radio"
                            id={`formNICNumberType-N`}
                            checked={aggrivedPersonDetails.newNic}
                          />
                        </Col>
                        <Col sm={1}>
                          <Form.Check
                            checked={!aggrivedPersonDetails.newNic}
                            inline
                            name="formNICNumberType"
                            label="Old"
                            type="radio"
                            id={`formNICNumberType-O`}
                          />
                        </Col>
                      </Row>
                      <Form.Control
                        disabled={true}
                        type="text"
                        placeholder="NIC Number"
                        value={
                          aggrivedPersonDetails.newNic
                            ? aggrivedPersonDetails.newNicNumber
                            : aggrivedPersonDetails.oldNicNumber
                        }
                      />
                    </Form.Group>
                  </Row>
                  {aggrivedPersonDetails.identificationType != 'NIC' && (
                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="formPSDNumber">
                        <Form.Label>
                          {renderIdentificationTypeName(
                            aggrivedPersonDetails.identificationType
                          )}{' '}
                          *
                        </Form.Label>
                        <Form.Control
                          type="text"
                          disabled
                          placeholder={`Enter ${renderIdentificationTypeName(
                            aggrivedPersonDetails.identificationType
                          )}`}
                          value={renderIdentificationTypeValue(
                            aggrivedPersonDetails.identificationType,
                            aggrivedPersonDetails
                          )}
                        />
                      </Form.Group>
                    </Row>
                  )}
                </>
              )}

              <Form.Group className="mb-3" controlId="idNumberRemark">
                <Form.Label>ID Number Remark</Form.Label>
                <Form.Control
                  disabled
                  type="text"
                  value={aggrivedPersonDetails.idNumberRemark}
                />
              </Form.Group>
              <Col sm={12} className="border-label mt-4">
                <div className="border-label-span">
                  Birth Certificate Details
                </div>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formBirthCNumber">
                    <Form.Label>Birth Certificate Number</Form.Label>
                    <Form.Control
                      disabled={true}
                      type="text"
                      placeholder="Birth Certificate Number"
                      value={aggrivedPersonDetails.birthCertificateNumber}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formBirthCDsDivision">
                    <Form.Label>Ds Division of Birth Certificate</Form.Label>
                    <Form.Control
                      disabled={true}
                      type="text"
                      placeholder="Ds Division of Birth Certificate"
                      value={
                        aggrivedPersonDetails.birthCertificateDsDivision
                          ? aggrivedPersonDetails.birthCertificateDsDivision
                              .name
                          : ''
                      }
                    />
                  </Form.Group>
                </Row>
              </Col>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formAge">
                  <Form.Label>Age</Form.Label>
                  <Form.Control
                    disabled={true}
                    type="text"
                    placeholder="Age"
                    value={aggrivedPersonDetails.age}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formGender">
                  <Form.Label>Gender</Form.Label>
                  <Form.Control
                    disabled={true}
                    type="text"
                    placeholder="Gender"
                    value={aggrivedPersonDetails.gender}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formGroupTitle">
                  <Form.Label>
                    Marital Status{' '}
                    <span className="required-field-astrix">*</span>
                  </Form.Label>
                  <Form.Control
                    disabled={true}
                    placeholder="Marital Status"
                    value={aggrivedPersonDetails.maritalStatus}
                  ></Form.Control>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group
                  as={Col}
                  controlId="formPermanentAddressWhenIncidentOccurred"
                >
                  <Form.Label>Permanent Address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Permanent Address"
                    disabled={true}
                    value={aggrivedPersonDetails.address}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formAgProvince">
                  <Form.Label>Province</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Province"
                    disabled={true}
                    value={
                      provinceList.filter((province: ProvinceOption) => {
                        return (
                          province.value === aggrivedPersonDetails.provinceId
                        )
                      })[0].label
                    }
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formAgDistrict">
                  <Form.Label>District</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="District"
                    disabled={true}
                    value={
                      districtList.filter((district: DistrictOption) => {
                        return (
                          district.value === aggrivedPersonDetails.districtId
                        )
                      })[0]?.label
                    }
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formAgDsDivision">
                  <Form.Label>{`DS Division`}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="DS Division"
                    disabled={true}
                    value={
                      dsList.filter((ds: DsOption) => {
                        return ds.value === aggrivedPersonDetails.dsDivisionId
                      })[0]?.label
                    }
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formAgGnDivision">
                  <Form.Label>{`GN Division`}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="GN Division"
                    disabled={true}
                    value={
                      gnList.filter((gn: GnOption) => {
                        return gn.value === aggrivedPersonDetails.gnDivisionId
                      })[0]?.label
                    }
                  />
                </Form.Group>
              </Row>
              <Form.Group className="mb-3" controlId="formIncidentDate">
                <Form.Label>Incident Date</Form.Label>
                <Form.Control
                  disabled={true}
                  type="text"
                  placeholder="Incident Date"
                  value={
                    fileRegistrationObject.incidentDate != null
                      ? new Date(
                          fileRegistrationObject.incidentDate * 1000
                        ).toLocaleDateString()
                      : undefined
                  }
                />
              </Form.Group>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formPlaceOfTheIncident">
                  <Form.Label>Place of the Incident occurred</Form.Label>
                  <Form.Control
                    placeholder="Place of the Incident occurred"
                    disabled={true}
                    type="text"
                    value={aggrivedPersonDetails.placeOfTheIncidentOccurred}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formIncidantType">
                  <Form.Label>Incident Type</Form.Label>
                  <Form.Control
                    placeholder="Incident Type"
                    disabled={true}
                    type="text"
                    value={
                      incidentList.filter((incident: IncidentTypeOption) => {
                        return (
                          incident.value ===
                          fileRegistrationObject.incidentTypeId
                        )
                      })[0].label
                    }
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formTypeOfInjury">
                  <Form.Label>{`Type of Injury`}</Form.Label>
                  <Form.Control
                    disabled={true}
                    placeholder="Type of Injury"
                    type="text"
                    value={
                      aggrivedPersonDetails.typeOfInjury
                        ? aggrivedPersonDetails.typeOfInjury.injuryDescription
                        : ''
                    }
                  />
                </Form.Group>
              </Row>
              <Form.Group className="mb-3" controlId="formReasonsForDamage">
                <Form.Label>Reasons for Damage</Form.Label>
                <Form.Control
                  disabled={true}
                  type="text"
                  placeholder="Reasons for Damage"
                  value={aggrivedPersonDetails.reasonsForDamage}
                />
              </Form.Group>
              <Col sm={12} className="border-label mb-3">
                <div className="border-label-span">
                  Details of the Occupations
                </div>
                <Row>
                  <Form.Group as={Col} controlId="formAgDesignation">
                    <Form.Label>Designation</Form.Label>
                    <Form.Control
                      disabled={true}
                      type="text"
                      placeholder="Designation"
                      value={aggrivedPersonDetails.designation}
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formAgOrganization">
                    <Form.Label>Name of the Organization</Form.Label>
                    <Form.Control
                      disabled={true}
                      type="text"
                      placeholder="Name of the Organization"
                      value={aggrivedPersonDetails.organization}
                    />
                  </Form.Group>
                </Row>
                <Row className="mt-2">
                  <Form.Group as={Col} controlId="formAgEarnings">
                    <Form.Label>Salary/ Wages/ Earnings</Form.Label>
                    <Form.Control
                      disabled={true}
                      type="text"
                      placeholder="Salary/ Wages/ Earnings"
                      value={currencyMask(aggrivedPersonDetails.salary)}
                    />
                  </Form.Group>
                  {(fileRegistrationObject.scheme === 'GSD' ||
                    fileRegistrationObject.scheme === 'GSI') && (
                    <>
                      <Form.Group as={Col} controlId="formAgEPFOrStaffNumber">
                        <Form.Label>EPF or Staff number</Form.Label>
                        <Form.Control
                          disabled={true}
                          type="text"
                          placeholder="EPF or Staff number"
                          value={aggrivedPersonDetails.epfOrStaffNumber}
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="formAgBalanceLoanAmount">
                        <Form.Label>Balance Loan amount</Form.Label>
                        <Form.Control
                          disabled={true}
                          type="text"
                          placeholder="Balance Loan amount"
                          value={
                            aggrivedPersonDetails.balanceLoanAmount
                              ? currencyMask(
                                  aggrivedPersonDetails.balanceLoanAmount
                                )
                              : ''
                          }
                        />
                      </Form.Group>
                    </>
                  )}
                </Row>
              </Col>
              {getConfirmationOfDeath(fileRegistrationObject.scheme) ? (
                <Col sm={12} className="border-label mb-3">
                  <div className="border-label-span">Confirmation of Death</div>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formAgDeathCNumber">
                      <Form.Label>Death Certificate Number</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Death Certificate Number"
                        disabled={true}
                        value={fileRegistrationObject.deathCertificateNumber}
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formAgProvince">
                      <Form.Label>Province</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Province"
                        disabled={true}
                        value={
                          aggrivedPersonDetails.province
                            ? aggrivedPersonDetails.province.name
                            : ''
                        }
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formAgDistrict">
                      <Form.Label>District</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="District"
                        disabled={true}
                        value={
                          districtList.filter((district: DistrictOption) => {
                            return (
                              district.value ===
                              selectedDeathCertificateDs.districtId
                            )
                          })[0]?.label
                        }
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formAgDeathCDsDivision">
                      <Form.Label>Ds Division of Death Certificate</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Ds Division of Death Certificate"
                        disabled={true}
                        value={
                          dsList.filter((ds: DsOption) => {
                            return ds.value === selectedDeathCertificateDs.dsId
                          })[0]?.label
                        }
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formAgIssuedDate">
                      <Form.Label>Issued Date</Form.Label>
                      <Form.Control
                        disabled={true}
                        placeholder="Issued Date"
                        type="text"
                        value={
                          aggrivedPersonDetails.issuedDate != null
                            ? new Date(
                                aggrivedPersonDetails.issuedDate * 1000
                              ).toLocaleDateString()
                            : ''
                        }
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formAgIssuedPlace">
                      <Form.Label>Issued Place</Form.Label>
                      <Form.Control
                        disabled={true}
                        type="text"
                        placeholder="Issued Place"
                        value={aggrivedPersonDetails.issuedPlace}
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formAgMOReport">
                      <Form.Label>JMO Report</Form.Label>
                      <Form.Control
                        disabled={true}
                        type="text"
                        placeholder="JMO Report"
                        value={aggrivedPersonDetails.jmoReport}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formAgCoronerReport">
                      <Form.Label>Coroner Report</Form.Label>
                      <Form.Control
                        disabled={true}
                        type="text"
                        placeholder="Coroner Report"
                        value={aggrivedPersonDetails.coronerReport}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formAgOther">
                      <Form.Label>Other</Form.Label>
                      <Form.Control
                        disabled={true}
                        type="text"
                        placeholder="Other"
                        value={aggrivedPersonDetails.other}
                      />
                    </Form.Group>
                  </Row>
                </Col>
              ) : (
                <Col sm={12} className="border-label mb-3">
                  <div className="border-label-span">
                    Confirmation of Injury
                  </div>
                  <Row className="mb-3">
                    <Form.Group
                      as={Col}
                      controlId="formAgDiagnosedOrTreatmentsGivenBy"
                    >
                      <Form.Label>Diagnosed or Treatments given by</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Diagnosed or Treatments given by"
                        disabled={true}
                        value={
                          aggrivedPersonDetails.diagnosedOrTreatmentsGivenBy
                        }
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formAgNameOfTheHospital">
                      <Form.Label>Name of the Hospital</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Name of the Hospital"
                        disabled={true}
                        value={aggrivedPersonDetails.nameOfTheHospital}
                      />
                    </Form.Group>
                  </Row>
                </Col>
              )}
              <Col sm={12} className="border-label mb-3">
                <div className="border-label-span">
                  Details of Police Complain
                </div>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formAgPoliceStation">
                    <Form.Label>Police Station</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Police Station"
                      disabled={true}
                      value={aggrivedPersonDetails.policeStation}
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formAgComplainDate">
                    <Form.Label>Complain Date</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Complain Date"
                      disabled={true}
                      value={
                        aggrivedPersonDetails.complaintDate
                          ? new Date(
                              aggrivedPersonDetails.complaintDate * 1000
                            ).toLocaleDateString()
                          : ''
                      }
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formAgComplainNumber">
                    <Form.Label>Complain Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Complain Number"
                      disabled={true}
                      value={aggrivedPersonDetails.complaintNumber}
                    />
                  </Form.Group>
                </Row>
              </Col>
              <Col sm={12} className="border-label mb-3">
                <div className="border-label-span">
                  Other Assistance Details
                </div>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formAgNameOfTheInstitution">
                    <Form.Label>Name of the Institution</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name of the Institution"
                      disabled={true}
                      value={otherAssistantance.institution}
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formAgAmountReceived">
                    <Form.Label>Amount Received</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Amount Received"
                      disabled={true}
                      value={
                        otherAssistantance.amountReceived
                          ? currencyMask(otherAssistantance.amountReceived)
                          : ''
                      }
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formAgReceivedDate">
                    <Form.Label>Received Date</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Received Date"
                      disabled={true}
                      value={
                        otherAssistantance.receivedDate
                          ? new Date(
                              otherAssistantance.receivedDate * 1000
                            ).toLocaleDateString()
                          : ''
                      }
                    />
                  </Form.Group>
                </Row>
              </Col>
              {getConfirmationOfDeath(fileRegistrationObject.scheme) && (
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formFuneralExpenses">
                    <Form.Label>Funeral Expenses (Rs.)</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Funeral Expenses"
                      disabled={true}
                      value={
                        aggrivedPersonDetails.funeralExpenses
                          ? currencyMask(aggrivedPersonDetails.funeralExpenses)
                          : ''
                      }
                    />
                  </Form.Group>
                </Row>
              )}
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formAgCompensationAmount">
                  <Form.Label>Compensation Amount (Rs.)</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Compensation Amount"
                    disabled={true}
                    value={
                      aggrivedPersonDetails.compensationAmount
                        ? currencyMask(aggrivedPersonDetails.compensationAmount)
                        : ''
                    }
                  />
                </Form.Group>
              </Row>
              {installmentList.length > 0 && (
                <Col sm={12} className="border-label mb-3">
                  <div className="border-label-span">Installment Details</div>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="installmentList">
                      <Row>{renderInstallmentList()}</Row>
                    </Form.Group>
                  </Row>
                </Col>
              )}
            </Form>
          </Col>
          <Col sm={12} className="d-flex justify-content-end mt-4">
            <button className="cancel-button" onClick={onOk}>
              Ok
            </button>
          </Col>
        </>
      )}
    </>
  )
}

export default SectionThree
