/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { FC, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { ActionListModal } from '../../../models/package/actionListModal'
import { ModuleModal } from '../../../models/package/moduleModal'
import { SubPackageModal } from '../../../models/package/subPackageModal'
import Action from './Action'
import Module from './Module'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusSquare, faPlusSquare } from '@fortawesome/free-solid-svg-icons'

export interface SubPackagerops {
  subPackageModal: SubPackageModal
  mode: boolean
  subPackageIndex: number
  packageIndex: number
}

const SubPackage: FC<SubPackagerops> = ({
  subPackageModal,
  mode,
  subPackageIndex,
  packageIndex,
}) => {
  const [isSelected, setIsSelected] = useState(false)
  const renderModules = () => {
    return subPackageModal.moduleList.map(
      (module: ModuleModal, moduleIndex: number) => {
        return (
          <ul className="custom-ul" key={module.id}>
            <Module
              moduleModal={module}
              mode={mode}
              packageIndex={packageIndex}
              subPackageIndex={subPackageIndex}
              moduleIndex={moduleIndex}
            />
          </ul>
        )
      }
    )
  }
  const renderActionList = () => {
    return subPackageModal.actionList.map(
      (action: ActionListModal | any, index: number) => {
        return (
          <Action
            key={`sub-package-action-${subPackageModal.id}-${index}`}
            actionListModal={action}
            index={index}
            mode={mode}
            relevantModue={`sub-package`}
            packageIndex={packageIndex}
            subPackageIndex={subPackageIndex}
          />
        )
      }
    )
  }
  return (
    <div
      role="button"
      className="custom-permission-button"
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        setIsSelected(!isSelected)
      }}
    >
      <Container fluid>
        <Row>
          <Col className="align-self-center" sm={1}>
            {isSelected ? (
              <FontAwesomeIcon icon={faMinusSquare} />
            ) : (
              <FontAwesomeIcon icon={faPlusSquare} />
            )}
          </Col>
          <Col sm={11}>
            <Row
              className={
                isSelected ? 'custom-header-selected' : 'custom-header'
              }
            >
              {subPackageModal.description}
            </Row>
          </Col>
        </Row>
        {isSelected && (
          <Row>
            <Col className="custom-inner-loop">
              {renderActionList()}
              {renderModules()}
            </Col>
          </Row>
        )}
      </Container>
    </div>
  )
}

export default SubPackage
