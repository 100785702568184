import * as errorMessages from '../../../utils/errorMessages'

export const validateStamChargeAmount = (stampCharge: number) => {
  if (!stampCharge) {
    return errorMessages.stamChargeAmountEmpty
  }
}
export const validateStartDate = (startDate: number) => {
  if (!startDate) {
    return errorMessages.startDateEmpty
  }
}
export const validateEndDate = (endDate: number) => {
  if (!endDate) {
    return errorMessages.endDateEmpty
  }
}
