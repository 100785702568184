import React, { useState } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import ConfirmationModal from '../../../components/modals/ConfirmationModal'
// import Select from 'react-select'
import { showTotast } from '../../../utils/toast'
// import LoadingComponent from '../../../components/common/loading/LoadingComponent'
import stamp_img from '../../../assets/images/systemConfiguration/manage-stamp-charges.svg'
// import { Col, Row } from 'react-bootstrap'
import './ManageStampChargers.scss'
import { manageStampChargeModel } from '../../../models/systemConfiguration/manageStampCharges/manageStampChargeModel'
import manageStampChargesService from '../../../services/systemConfiguration/manageStampCharges/manageStampChargesService'
import { stapmpChargeAdd } from '../../../utils/successMessages'
import { useHistory } from 'react-router-dom'
import * as constants from '../../../utils/constants'
import { produce } from 'immer'
import * as validation from './validations'
import { StampChargeErrorModel } from '../../../models/systemConfiguration/manageStampCharges/stampChargeErrorModel'
import {
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../../utils/constants'
import { validationError } from '../../../utils/errorMessages'
import PriceInputComponent from '../../../components/common/priceInputComponent/PriceInputComponent'
import { ValidationObjectModel } from '../../../models/common/validationObjectModel'
import { validateForm } from '../../../utils/utilFunctions'
import DatePicker from 'react-datepicker'
import InvalidFeedback from '../../../components/common/invalidFeedback/InvalidFeedback'
const stampChargeModel = {} as manageStampChargeModel

const ManageStampChargers = () => {
  const [stampCharge, setStampCharge] = useState(stampChargeModel)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [errors, setErrors] = useState({} as StampChargeErrorModel)
  // const [showConfirmation, setShowConfirmation] = useErrorHandlign(false)
  // const [loading, setloading] = useState(true)
  // const [isSubmi, setIsSubmi] = useState(false)
  // const [isValid, setisValid] = useState(true)
  // const saveStampCharge = () => {
  //   alert('kavinthaka')
  // }
  const history = useHistory()

  const findFormErrors = () => {
    const newErrors = {} as StampChargeErrorModel
    if (validation.validateStamChargeAmount(stampCharge.stampCharge)) {
      newErrors.stampCharge = validation.validateStamChargeAmount(
        stampCharge.stampCharge
      )
    }

    return newErrors
  }

  const buttonClickSubmit = async () => {
    const foundErrros = findFormErrors()
    if (Object.keys(foundErrros).length > 0) {
      showTotast(
        ALERT_WARNING,
        validationError,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setErrors(foundErrros)
    } else {
      setErrors(foundErrros)
      setStampCharge
      setShowConfirmation(true)
    }
  }

  const handleConfirm = async () => {
    // setloading(true)
    console.log(stampCharge)
    const result = await manageStampChargesService.apiAddStampCharges(
      stampCharge
    )
    console.log(result.errorMessage)
    let success = false
    let message = result.errorMessage ? result.errorMessage : ''
    if (result.status === 200) {
      success = true
      message = stapmpChargeAdd
      history.goBack()
    }
    showTotast(
      success ? constants.ALERT_SUCCESS : constants.ALERT_WARNING,
      message,
      constants.TOAST_POSITION_TOP_RIGHT,
      5,
      constants.TOAST_TRANSITION_SLIDE
    )
  }
  return (
    <>
      {/* {loading ? (
        <Container className="loading-layout">
          <LoadingComponent />
        </Container>
      ) : ( */}
      <Row className="title-layout">
        <Col className="d-flex justify-content-start">
          <img src={stamp_img} alt="" />
          <span className="m-2">Manage Stamp Charges</span>
        </Col>
      </Row>
      <Row className="create-user-page-layout">
        {/* <Col className="d-flex justify-content-start">
          <img src={stamp_img} alt="" />
          <span className="m-2">Manage Stamp Chargers</span>
        </Col> */}
        {/* </Row>
      <Row className="incident-types-body-layout"> */}
        <Col sm={12} className="border-label">
          <div className="border-label-span">Add Stamp Charges</div>
          <Form id="file-registration-form">
            <Form.Group className="mb-3" controlId="formGroupTitle">
              <Form.Label>Stamp Charge (Rs.)</Form.Label>
              <PriceInputComponent
                isInvalid={!!errors.stampCharge}
                placeholder="Stamp Charge"
                onChange={(price: string) => {
                  const newErrors = produce(errors, (draft) => {
                    draft.stampCharge = validateForm({
                      value: parseFloat(price),
                      section: 'Stamp Charge',
                      maxLength: 6,
                      type: 'number',
                    } as ValidationObjectModel)
                  })
                  setErrors(newErrors)
                  const newValue = produce(stampCharge, (draft) => {
                    draft.stampCharge = parseFloat(price)
                  })
                  setStampCharge(newValue)
                }}
                onErrorHandle={(price: string) => {
                  if (!price) {
                    const newValue = produce(stampCharge, (draft) => {
                      draft.stampCharge = 0
                    })
                    setStampCharge(newValue)
                  } else {
                    const clearError = produce(errors, (draft) => {
                      draft.stampCharge = ''
                    })
                    setErrors(clearError)
                  }
                }}
                value={stampCharge.stampCharge}
              />
              <span className="feedback-error-span-custom">
                {errors.stampCharge}
              </span>
            </Form.Group>
            <Form.Group className="mb-3" controlId="startdate">
              <Form.Label>Start Date</Form.Label>
              <DatePicker
                placeholderText="Start Date"
                className={
                  errors.startDate ? 'date-picker-validate' : 'form-control'
                }
                selected={
                  stampCharge.startDate != null
                    ? new Date(stampCharge.startDate * 1000)
                    : null
                }
                onSelect={(date: Date) => {
                  const newValue = produce(stampCharge, (draft) => {
                    draft.startDate = new Date(date).getTime() / 1000
                  })
                  setStampCharge(newValue)
                  const clearError = produce(errors, (draft) => {
                    draft.startDate = ''
                  })
                  setErrors(clearError)
                }}
                onChange={(date: Date) => {
                  const newValue = produce(stampCharge, (draft) => {
                    draft.startDate = new Date(date).getTime() / 1000
                  })
                  setStampCharge(newValue)
                  const clearError = produce(errors, (draft) => {
                    draft.startDate = ''
                  })
                  setErrors(clearError)
                }}
              />
              <span className="feedback-error-span-custom">
                {errors.startDate}
              </span>
            </Form.Group>
            <Form.Group className="mb-3" controlId="endDate">
              <Form.Label>End Date</Form.Label>
              <DatePicker
                placeholderText="End Date"
                className={
                  errors.endDate ? 'date-picker-validate' : 'form-control'
                }
                selected={
                  stampCharge.endDate != null
                    ? new Date(stampCharge.endDate * 1000)
                    : null
                }
                onSelect={(date: Date) => {
                  const newValue = produce(stampCharge, (draft) => {
                    draft.endDate = new Date(date).getTime() / 1000
                  })
                  setStampCharge(newValue)
                  const clearError = produce(errors, (draft) => {
                    draft.endDate = ''
                  })
                  setErrors(clearError)
                }}
                onChange={(date: Date) => {
                  const newValue = produce(stampCharge, (draft) => {
                    draft.endDate = new Date(date).getTime() / 1000
                  })
                  setStampCharge(newValue)
                  const clearError = produce(errors, (draft) => {
                    draft.endDate = ''
                  })
                  setErrors(clearError)
                }}
              />
              <span className="feedback-error-span-custom">
                {errors.endDate}
              </span>
            </Form.Group>
            <Form.Group className="mb-3" controlId="status">
              <Form.Label>Status</Form.Label>
              <Form.Control
                isInvalid={!!errors.status}
                className="form-select"
                as="select"
                value={stampCharge.status}
                onBlur={() => {
                  const newErrors = produce(errors, (draft) => {
                    draft.status = validateForm({
                      type: 'text',
                      value: stampCharge.status,
                      section: 'Status',
                    } as ValidationObjectModel)
                  })
                  setErrors(newErrors)
                }}
                onChange={(e) => {
                  const newValue = produce(stampCharge, (draft) => {
                    draft.status = e.target.value
                  })
                  setStampCharge(newValue)
                  const clearError = produce(errors, (draft) => {
                    draft.status = ''
                  })
                  setErrors(clearError)
                }}
              >
                <option value="">--Select Status--</option>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </Form.Control>
              <InvalidFeedback message={errors.status} />
            </Form.Group>
          </Form>
        </Col>
        <Col sm={12} className="d-flex justify-content-end mt-4">
          <button className="cancel-button">Cancel</button>
          <button
            type="submit"
            className="save-button"
            onClick={buttonClickSubmit}
          >
            Save
          </button>
        </Col>
        {showConfirmation && (
          <ConfirmationModal
            name={'Confirmation'}
            message={'Do you want to add this stamp charge ?'}
            onCancel={() => {
              setShowConfirmation(false)
            }}
            onConfirm={handleConfirm}
          />
        )}
      </Row>
      {/* )} */}
    </>
  )
}

export default ManageStampChargers
