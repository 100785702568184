import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useRouteMatch, Switch, Route } from 'react-router-dom'
import TileButtonComponent from '../../components/common/tileButton/TileButtonComponent'
import { SubPackageModal } from '../../models/package/subPackageModal'
import { getUserSubPackages } from '../../store/user/selectors'
import ApplicationReports from './applicationReports/ApplicationReports'
import system_configuration from '../../assets/images/nav-icons/report.svg'
import AdminReports from './adminReports/AdminReports'
import ReportForProperty from './reportForProperty/ReportForProperty'
import ReportForDeath from './reportForDeath/ReportForDeath'
import ReportForInjury from './reportForInjury/ReportForInjury'
import ReportForLoans from './reportForLoans/ReportForLoans'
import FinanceBranchReport from './financeBranchReport/FinanceBranchReport'
import ProcessingAndPaymentsReport from './gpcReports/ProcessingAndPaymentsReport'
import CompleteDetailsReport from './completeDetailsReport/CompleteDetailsReport'

const Reports = () => {
  const { path } = useRouteMatch()
  const subPackageList = useSelector(getUserSubPackages('report'))
  const renderSubPackages = () => {
    return subPackageList.map((subPackage: SubPackageModal, index: number) => {
      return (
        <Col key={`sub-package-${index}`} sm={12} lg={6} className="mt-4">
          <TileButtonComponent
            name={subPackage.description}
            routeURL={subPackage.name}
          />
        </Col>
      )
    })
  }
  return (
    <Switch>
      <Route path={`${path}/`} exact>
        <Row className="title-layout">
          <Col className="d-flex justify-content-start">
            <img src={system_configuration} alt="" />
            <span className="m-2">Reports</span>
          </Col>
        </Row>
        <Row className="page-tiles-layout">{renderSubPackages()}</Row>
      </Route>
      <Route
        path={`${path}/application-report`}
        component={ApplicationReports}
      />
      <Route
        path={`${path}/administration-branch-report`}
        component={AdminReports}
      />
      <Route
        path={`${path}/compensation-divisional-report-property`}
        component={ReportForProperty}
      />
      <Route
        path={`${path}/compensation-divisional-report-for-death`}
        component={ReportForDeath}
      />
      <Route
        path={`${path}/compensation-divisional-report-for-injury`}
        component={ReportForInjury}
      />
      <Route path={`${path}/loan-reports`} component={ReportForLoans} />

      <Route
        path={`${path}/finance-branch-report`}
        component={FinanceBranchReport}
      />
      <Route path={`${path}/progress-reports`}>
        <Row className="title-layout">Processing And Payments Report</Row>
        <Row className="page-tiles-layout">
          <ProcessingAndPaymentsReport />
        </Row>
      </Route>
      <Route
        path={`${path}/complete-details-report`}
        component={CompleteDetailsReport}
      />
    </Switch>
  )
}

export default Reports
