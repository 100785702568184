import { BreadCrumbModal } from '../../models/breadcrumb/breadCrumb'

export const ADD_BREAD_CRUMB = 'ADD_BREAD_CRUMB'
export const SELECT_BREAD_CRUMB = 'SELECT_BREAD_CRUMB'
export const ADD_BREAD_CRUMB_NAVIGATION = 'ADD_BREAD_CRUMB_NAVIGATION'
export const ADD_BREAD_CRUMB_LIST = 'ADD_BREAD_CRUMB_LIST'
export const RESET_BREAD_CRUMB = 'RESET_BREAD_CRUMB'

export interface BreadCrumbState {
  breadcrumbs: BreadCrumbModal[]
}

export interface BreadCrumbPayload {
  breadcrumb: BreadCrumbModal
}

export interface BreadCrumbListPayload {
  breadcrumbs: BreadCrumbModal[]
}

export interface BreadCrumbSelectedPayload {
  index: number
}

export interface BreadCrumbResetRequest {
  type: typeof RESET_BREAD_CRUMB
}

export interface BreadCrumbAddRequest {
  type: typeof ADD_BREAD_CRUMB
  payload: BreadCrumbPayload
}

export interface BreadCrumbSelectRequest {
  type: typeof SELECT_BREAD_CRUMB
  payload: BreadCrumbSelectedPayload
}

export interface BreadCrumbNavigationAddRequest {
  type: typeof ADD_BREAD_CRUMB_NAVIGATION
  payload: BreadCrumbPayload
}

export interface BreadCrumbListAddRequest {
  type: typeof ADD_BREAD_CRUMB_LIST
  payload: BreadCrumbListPayload
}

export type BreadCrumbActions =
  | BreadCrumbAddRequest
  | BreadCrumbSelectRequest
  | BreadCrumbNavigationAddRequest
  | BreadCrumbListAddRequest
  | BreadCrumbResetRequest
