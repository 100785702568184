import { PdAggrivedPersonDetailsModel } from '../../../models/applicationRegistration/compensation/pd/pdAggrivedPerson'
import { PdDependentDetailsModel } from '../../../models/applicationRegistration/compensation/pd/pdDependentDetails'
import { NextOfKinModel } from '../../../models/applicationRegistration/compensation/nextOfKinModel'
import { FileRegistrationModel } from '../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { FilterModel } from '../../../models/utils/filterModel'
import apiService from '../../api/apiManager'

class GsiDataService {
  async apiFetchGsiList(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/compensation/gsi/view/list`,
      filterData
    )
  }

  async apiFetchGsiListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/compensation/gsi/view/count`,
      filterData
    )
  }

  async apiFetchGsiReturnList(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/compensation/gsi/file-return/view/list`,
      filterData
    )
  }

  async apiFetchGsiReturnListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/compensation/gsi/file-return/view/count`,
      filterData
    )
  }

  async apiFetchGsiFile(id: number) {
    return apiService.apiGet(
      `/application-registration/compensation/gsi/view/${id}`
    )
  }

  async apiGsiProceed(file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/gsi/add/proceed`,
      file
    )
  }

  async apiGsiModify(file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/gsi/file-return/modify/${file.id}`,
      file
    )
  }

  async apiGsiReject(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/gsi/${id}/reject`,
      { remark: remark }
    )
  }

  async apiGsiReturn(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/gsi/${id}/return`,
      { remark: remark }
    )
  }

  async apiGsiClose(id: number, remark: string, description: string) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/gsi/${id}/close`,
      { remark: remark, description: description }
    )
  }

  async apiGsiReopen(
    id: number,
    remark: string,
    description: string,
    allocateTo: string
  ) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/gsi/${id}/reopen`,
      { remark: remark, description: description, allocateTo: allocateTo }
    )
  }

  async apiGsiMerge(id: number, idList: any[]) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/gsi/add/${id}/merge`,
      idList
    )
  }

  async apiGsiAddApplicantDetails(file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/gsi/add/applicant`,
      file
    )
  }

  async apiGsiAddAggrivedPersonDetailsDetails(
    file: PdAggrivedPersonDetailsModel
  ) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/gsi/add/${file.applicationFileId}/aggrieved_person`,
      file
    )
  }

  async apiGsiGetAggrivedPersonDetailsDetails(id: number) {
    return apiService.apiGet(
      `/application-registration/compensation/gsi/view/${id}/aggrieved_person`
    )
  }

  async apiGsiGetDependentDetails(id: number) {
    return apiService.apiGet(
      `/application-registration/compensation/gsi/view/${id}/dependents`
    )
  }

  async apiGsiAddDependantDetails(file: PdDependentDetailsModel) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/gsi/add/${file.applicationFileId}/dependent`,
      file
    )
  }

  async apiGsiDeleteDependantDetails(file: PdDependentDetailsModel) {
    return apiService.apiDelete(
      `/application-registration/compensation/gsi/add/${file.applicationFileId}/dependent`,
      file.id
    )
  }

  async apiGsiAddDependantNextOfKinDetails(file: NextOfKinModel) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/gsi/add/${file.applicationFileId}/dependent/${file.dependentId}/next_of_kin`,
      file
    )
  }

  async apiGsiGetDependantNextOfKinDetails(
    fileId: number,
    dependentId: number
  ) {
    return apiService.apiGet(
      `/application-registration/compensation/gsi/view/${fileId}/dependent/${dependentId}/next_of_kin`
    )
  }

  async apiGsiGetSupportingDocument(id: number) {
    return apiService.apiGet(
      `/application-registration/compensation/gsi/view/${id}/document`
    )
  }
  async apiGsiAddSupportingDocument(formData: FormData, id: number) {
    return apiService.apiPutFormData(
      `/application-registration/compensation/gsi/add/${id}/document`,
      formData
    )
  }

  async apiGsiRegisterDocument(id: number) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/gsi/add/${id}/register`,
      {}
    )
  }
}

export default new GsiDataService()
