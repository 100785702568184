import React from 'react'
import { Tab, Row, Nav } from 'react-bootstrap'
import { useRouteMatch, Switch, Route } from 'react-router'
import AddChequeDetails from '../AddChequeDetails'
import MpApplication from './MpApplication'
import MpReturn from './MpReturn'

const Mp = () => {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route path={`${path}/`} exact>
        <Tab.Container id="left-tabs-example" defaultActiveKey="gpc">
          <Row className="tab-page-layout">
            <Nav variant="pills" className="flex-row">
              <Nav.Item className="custom-nav-item">
                <Nav.Link eventKey="gpc">MP Applications</Nav.Link>
              </Nav.Item>
              <Nav.Item className="custom-nav-item">
                <Nav.Link eventKey="gpc-return">MP Return</Nav.Link>
              </Nav.Item>
            </Nav>
          </Row>
          <Row className="page-tiles-layout">
            <Tab.Content>
              <Tab.Pane eventKey="gpc">
                <MpApplication />
              </Tab.Pane>
              <Tab.Pane eventKey="gpc-return">
                <MpReturn />
              </Tab.Pane>
            </Tab.Content>
          </Row>
        </Tab.Container>
      </Route>
      <Route
        path={`${path}/add/:id`}
        render={(props) => <AddChequeDetails scheme={'PD'} {...props} />}
      />
    </Switch>
  )
}

export default Mp
