import React, { useEffect, useState } from 'react'
import './CompensationValues.scss'
import { Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import {
  useHistory,
  useRouteMatch,
  useLocation,
  Switch,
  Route,
} from 'react-router'
import ActionButtonComponent from '../../../components/common/actionButton/ActionButtonComponent'
import LoadingComponent from '../../../components/common/loading/LoadingComponent'
import { ActionListModal } from '../../../models/package/actionListModal'
import { getUserSubPackageActions } from '../../../store/user/selectors'
import CompensationValuesServices from '../../../services/systemConfiguration/compensationValues/compensationValuesServices'
import compensation_value from '../../../assets/images/systemConfiguration/compensation-value.svg'
import AddCompensationValues from './AddCompensationValues'
import ViewCompensationValues from './ViewCompensationValues'
import ModifyCompensationValues from './ModifyCompensationValues'
import { compensationValueFilters } from '../../../utils/metaData'
import { filtersSetRequest } from '../../../store/filter/actions'
import { getFilters } from '../../../store/filter/selectors'
import { FilterModel } from '../../../models/utils/filterModel'
import { getPageCount } from '../../../utils/tableFunctions'
import { FilterOptionModel } from '../../../models/utils/filterOption'
import produce from 'immer'
import IterminalTableComponent from '../../../components/table/IterminalTableComponent'
import CompensationValuesFilters from '../../../components/filters/CompensationValuesFilters'
import { currencyMask } from '../../../utils/utilFunctions'
import {
  defaultPageLimit,
  defaultOrderListLimit,
} from '../../../utils/constants'
const CompensationValues = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [compensationValuesList, setCompensationValuesList] = useState([])
  const pending = false
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const history = useHistory()
  const { path } = useRouteMatch()
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  const filterOption = {
    descending: true,
    limit: defaultPageLimit,
    orderFields: defaultOrderListLimit,
  } as FilterModel
  const [filter, setFilter] = useState(filterOption)
  const [pageCount, setPageCount] = useState(0)
  const [dataCount, setDatacount] = useState(0)
  const filters = useSelector(getFilters)
  const dispatch = useDispatch()

  const actionList = useSelector(
    getUserSubPackageActions('system-configuration', 'compensation-value')
  )

  const checkPermission = (permissionName: string) => {
    const filterdAction = actionList.filter((action: ActionListModal) => {
      if (action.action.name === permissionName) {
        return action
      }
    })[0]
    if (filterdAction != null) {
      return true
    } else {
      return false
    }
  }
  useEffect(() => {
    dispatch(filtersSetRequest({ filters: compensationValueFilters }))
    async function getIncidentTypesData() {
      await retriveDataCountAndData(filter)
      setLoading(false)
    }
    getIncidentTypesData()
  }, [location.pathname])
  async function retriveDataCountAndData(filterData: FilterModel) {
    const compensationValueCountData =
      await CompensationValuesServices.apiFetchCompensationValuesCount(
        filterData
      )
    setDatacount(compensationValueCountData.data.count)
    setPageCount(
      getPageCount(compensationValueCountData.data.count, filterOption.limit)
    )
    const compensationValueData =
      await CompensationValuesServices.apiFetchCompensationValuesList(
        filterData
      )
    setCompensationValuesList(compensationValueData.data)
  }

  const retriveFilterData = async (selectedFilters: FilterOptionModel[]) => {
    console.log(selectedFilters)
    setLoading(true)
    const newFilter = produce(filter, (draft) => {
      draft.page = 1
      draft.filterData = selectedFilters
    })
    setFilter(newFilter)
    await retriveDataCountAndData(newFilter)
    setLoading(false)
  }

  const retriveIncidentData = async (pageDetails: any) => {
    setLoading(true)
    const newFilter = produce(filter, (draft) => {
      draft.page = pageDetails.pageIndex + 1
    })
    setFilter(newFilter)

    const incidentData =
      await CompensationValuesServices.apiFetchCompensationValuesList(newFilter)
    setCompensationValuesList(incidentData.data)
    setLoading(false)
  }
  const columns = React.useMemo(
    () => [
      {
        Header: 'Type of Program',
        accessor: 'programType',
      },
      {
        Header: 'Scheme',
        accessor: 'scheme',
      },
      {
        Header: 'Incident Type',
        accessor: 'incidentType',
      },
      {
        Header: 'Minimum Amount (Rs.)',
        accessor: 'minimumAmount',
        Cell: (e: any) => {
          if (e.cell.value) {
            return (
              <div className="cell-align-right">
                {e.row.original.minimumAmount
                  ? currencyMask(e.row.original.minimumAmount)
                  : ''}
              </div>
            )
          } else if (e.cell.value == 0 || e.cell.value == null) {
            return <div className="cell-align-right">{'N/A'}</div>
          }
        },
      },
      {
        Header: 'Maximum Amount (Rs.)',
        accessor: 'maximumAmount',
        Cell: (e: any) => {
          if (e.cell.value !== null) {
            return (
              <div className="cell-align-right">
                {e.row.original.maximumAmount
                  ? currencyMask(e.row.original.maximumAmount)
                  : ''}
              </div>
            )
          }
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
    ],
    []
  )
  const handleView = (id: number) => {
    history.push(`${path}/view/${id}`)
  }
  return (
    <>
      <Row className="title-layout">
        <Col className="d-flex justify-content-start">
          <img src={compensation_value} alt="" />
          <span className="m-2">Compensation Values</span>
        </Col>
      </Row>
      {pending ? (
        <div className="loading-layout">
          <LoadingComponent />
        </div>
      ) : (
        <Switch>
          <Route path={`${path}/`} exact>
            <Row className="incident-types-body-layout mt-4">
              <Col sm={12} className="mb-4">
                <Row>
                  <Col sm={6}>
                    {checkPermission('view') === true && !loading && (
                      <CompensationValuesFilters
                        filterData={filters}
                        onFilter={(e: FilterOptionModel[]) => {
                          dispatch(filtersSetRequest({ filters: e }))
                          retriveFilterData(
                            e.filter(
                              (filter: FilterOptionModel) =>
                                filter.selected === true
                            )
                          )
                        }}
                      />
                    )}
                  </Col>
                  <Col sm={6} className="d-flex justify-content-end">
                    {checkPermission('add') === true && (
                      <ActionButtonComponent name="Add" routeURL="add" />
                    )}
                  </Col>
                </Row>
              </Col>
              <Col sm={12}>
                <IterminalTableComponent
                  columns={columns}
                  data={compensationValuesList}
                  fetchData={retriveIncidentData}
                  loading={loading}
                  pageCount={pageCount}
                  totalCount={dataCount}
                  onClickRow={(row: any) => {
                    console.log(row)
                    handleView(row.id)
                  }}
                />
              </Col>
            </Row>
          </Route>
          <Route path={`${path}/add`} component={AddCompensationValues} />
          <Route path={`${path}/view/:id`} component={ViewCompensationValues} />
          <Route
            path={`${path}/modify/:id`}
            component={ModifyCompensationValues}
          />
        </Switch>
      )}
    </>
  )
}

export default CompensationValues
