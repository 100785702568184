import React, { FC, useEffect, useState } from 'react'
import { Row } from 'react-bootstrap'
import SectionButton from '../../../components/common/ITerminalWizardComponent/SectionButton'
import LoadingComponent from '../../../components/common/loading/LoadingComponent'
import { FileRegistrationModel } from '../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { DisbursementDetailsModel } from '../../../models/applicationRegistration/loan/disbursementDetails'
import { GuarantorDetailsModel } from '../../../models/applicationRegistration/loan/guarantorDetails'
import { LoanNPADetailsModel } from '../../../models/applicationRegistration/loan/loanNPADetails'
import enquireApplicationService from '../../../services/enquireApplication/enquireApplicationService'
import { loadAttributeSectionList } from '../../../utils/metaData'
import DisbursmentDetails from './DisbursmentDetails'
import GurantorDetails from './GurantorDetails'
import NPADetails from './NPADetails'
import RepaymentDetails from './RepaymentDetails'
import RescheduleDetails from './RescheduleDetails'

export interface LoanAttributeDetailsProps {
  fileRegistrationObject: FileRegistrationModel
}

const LoanAttributeDetails: FC<LoanAttributeDetailsProps> = ({
  fileRegistrationObject,
}) => {
  const [loading, setLoading] = useState(true)
  const [activeSection, setActiveSection] = useState(1)
  const [gurantorDetailsList, setGurantorDetailsList] = useState(
    [] as GuarantorDetailsModel[]
  )

  const [disbursmentDetails, setDisbursmentDetails] = useState(
    {} as DisbursementDetailsModel
  )

  const [npaDetailsList, setNpaDetailsList] = useState(
    [] as LoanNPADetailsModel[]
  )

  useEffect(() => {
    async function getFileDetails() {
      const gurantorData =
        await enquireApplicationService.apiFetchGurantorDetails(
          fileRegistrationObject.id
        )
      if (gurantorData.status === 200) {
        setGurantorDetailsList(gurantorData.data)
      }
      const disbursmentData =
        await enquireApplicationService.apiFetchDisbursementDetails(
          fileRegistrationObject.id
        )
      if (disbursmentData.status === 200) {
        setDisbursmentDetails(disbursmentData.data)
      }
      const npaData = await enquireApplicationService.apiFetchNPADetails(
        fileRegistrationObject.id
      )
      if (npaData.status === 200) {
        setNpaDetailsList(npaData.data)
      }

      setLoading(false)
    }
    getFileDetails()
  }, [])

  const checkAvailable = (index: number) => {
    switch (index) {
      case 0:
        return true
        break
      case 1:
        if (
          gurantorDetailsList?.find((element) => element.status === 'Active')
        ) {
          return true
        }
        return false
        break
      case 2:
        if (disbursmentDetails.id != null) {
          return true
        }
        return false
        break
      case 3:
        if (disbursmentDetails.id != null) {
          return true
        }
        return false
        break
      case 4:
        if (npaDetailsList.length > 0) {
          return true
        }
        return false
        break
      default:
        return false
    }
  }

  const renderSectionButton = () => {
    return loadAttributeSectionList.map((section: any, index: number) => {
      return (
        <SectionButton
          key={`section-button-${index}`}
          name={`${section.name}`}
          number={index + 1}
          isLeft={index === 0 ? false : true}
          isRight={index === loadAttributeSectionList.length - 1 ? false : true}
          available={checkAvailable(index)}
          active={activeSection === index + 1}
          onClick={() => {
            setActiveSection(index + 1)
          }}
        />
      )
    })
  }

  return (
    <>
      {loading ? (
        <div className="loading-layout">
          <LoadingComponent />
        </div>
      ) : (
        <>
          <Row className="section-layout p-0 m-0">
            <Row className="section-tab-layout p-2">
              {renderSectionButton()}
            </Row>
          </Row>
          <Row className="section-layout p-0 m-0 mt-4">
            {activeSection === 1 && (
              <GurantorDetails
                fileRegistrationObject={fileRegistrationObject}
                gurantorDetailsList={gurantorDetailsList}
              />
            )}
            {activeSection === 2 && (
              <DisbursmentDetails
                fileRegistrationObject={fileRegistrationObject}
              />
            )}
            {activeSection === 3 && (
              <RepaymentDetails
                fileRegistrationObject={fileRegistrationObject}
              />
            )}
            {activeSection === 4 && (
              <NPADetails
                fileRegistrationObject={fileRegistrationObject}
                npaDetailsList={npaDetailsList}
              />
            )}
            {activeSection === 5 && (
              <RescheduleDetails
                fileRegistrationObject={fileRegistrationObject}
              />
            )}
          </Row>
        </>
      )}
    </>
  )
}

export default LoanAttributeDetails
