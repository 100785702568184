import { AppState } from '../rootReducer'

export const getTimeFilters = (state: AppState) =>
  state.dashboardFilters.timeFilters
export const getStatusilters = (state: AppState) =>
  state.dashboardFilters.statusFileters
export const getSelectedTimeFilters = (state: AppState) => {
  let indexNumber = 0
  const selected = state.dashboardFilters.timeFilters.filter(
    (filter: any, index: number) => {
      if (filter.selected) {
        filter
        indexNumber = index
      }
    }
  )[0]
  return {
    filter: selected != null ? selected : { to: null, value: null },
    index: indexNumber,
  }
}
