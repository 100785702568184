import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'
import ReactTableWithPaginationComponent from '../../../components/table/ReactTableWithPaginationComponent'
import { ChequeDetailsModel } from '../../../models/chequePayments/chequeDetailsModel'
import ManageChequePaymentsService from '../../../services/chequePayments/manageChequePayments/manageChequePaymentsService'
import { currencyMask } from '../../../utils/utilFunctions'
import ViewAndPrintCheque from './ViewAndPrintCheque'

const ViewChequeList = (props: any) => {
  const {
    match: { params },
  } = props
  const [chequeDetailsList, setChequeDetailsList] = useState(
    [] as ChequeDetailsModel[]
  )
  const [referenceNumber, setReferenceNumber] = useState()
  const history = useHistory()
  const location = useLocation()
  const { path } = useRouteMatch()
  useEffect(() => {
    async function getData() {
      const application = await ManageChequePaymentsService.apiGetFileDetails(
        parseInt(params.id)
      )
      setReferenceNumber(application.data.referenceNumber)

      const chequeDetailsData =
        await ManageChequePaymentsService.apiGetChequePaymentDetails(
          parseInt(params.id)
        )
      if (chequeDetailsData.status === 200) {
        setChequeDetailsList(chequeDetailsData.data)
      }
    }
    getData()
  }, [location.pathname])
  const handleView = (row: ChequeDetailsModel) => {
    history.push(
      `/cheque-payment/cheque-print/pd/view-cheques/${params.id}/print/${row.id}`
    )
  }

  const renderChequeDetailsTable = () => {
    return (
      <ReactTableWithPaginationComponent
        hover={true}
        columns={columns}
        data={chequeDetailsList}
        onClickRow={(row: any) => {
          handleView(row)
        }}
      />
    )
  }
  const columns = React.useMemo(
    () => [
      {
        Header: 'Payee',
        accessor: 'payee',
      },
      {
        Header: 'Bank Name',
        accessor: 'bankName',
      },
      {
        Header: 'Branch Name',
        accessor: 'branchName',
      },
      {
        Header: 'Account Number',
        accessor: 'accountNumber',
      },
      {
        Header: 'NIC Number',
        accessor: 'newNicNumber',
      },
      {
        Header: 'Cheque Number',
        accessor: 'chequeNumber',
      },
      {
        Header: 'Cheque Issued Date',
        accessor: 'chequeIssuedDate',
      },
      {
        Header: 'Cheque Amount (Rs.)',
        accessor: 'paidAmount',
        Cell: (e: any) => {
          if (!e.cell.value || e.cell.value == 0 || e.cell.value == '') {
            return <div className="cell-align-right">0</div>
          } else {
            return (
              <div className="cell-align-right">
                {e.row.original.paidAmount
                  ? currencyMask(e.row.original.paidAmount)
                  : ''}
              </div>
            )
          }
        },
      },
      {
        Header: 'Voucher Number',
        accessor: 'voucherNumber',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
    ],
    []
  )
  return (
    <>
      <Switch>
        <Route path={`${path}/`} exact>
          <Row className="title-layout">
            <Col className="d-flex justify-content-start">
              <span className="compensation-title mt-2 mb-2 ml-2">Cheques</span>
              <span className="m-2">
                {`Application reference : ${referenceNumber}`}
              </span>
            </Col>
          </Row>
          <Row className="incident-types-body-layout mt-4">
            <Col sm={12} className="mb-4">
              <Row>{renderChequeDetailsTable()}</Row>
            </Col>
          </Row>
        </Route>
        <Route
          path={`/cheque-payment/cheque-print/pd/view-cheques/:applicationFileId/print/:id`}
          render={(props) => <ViewAndPrintCheque {...props} />}
        />
      </Switch>
    </>
  )
}

export default ViewChequeList
