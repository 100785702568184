// import produce from 'immer'
// import React, { useEffect, useState } from 'react'
// import { Col, Row } from 'react-bootstrap'
// import { useDispatch, useSelector } from 'react-redux'
// import { useRouteMatch, Switch, Route, useHistory } from 'react-router-dom'
// import ActionButtonComponent from '../../../../components/common/actionButton/ActionButtonComponent'
// import FileAllocationFilters from '../../../../components/filters/FileAllocationFilters'
// import IterminalTableComponent from '../../../../components/table/IterminalTableComponent'
// import { ActionListModal } from '../../../../models/package/actionListModal'
// import { FilterModel } from '../../../../models/utils/filterModel'
// import { FilterOptionModel } from '../../../../models/utils/filterOption'
// import fileRegistrationService from '../../../../services/applicationRegistration/fileRegistration/fileRegistrationService'
// import { filtersSetRequest } from '../../../../store/filter/actions'
// import { getFilters } from '../../../../store/filter/selectors'
// import { getUserModuleActions } from '../../../../store/user/selectors'
// import {
//   defaultOrderListLimit,
//   defaultPageLimit,
// } from '../../../../utils/constants'
// import {
//   fileAllocationFilters,
//   fileRegistrationDraftColumns,
// } from '../../../../utils/metaData'
// import { getPageCount } from '../../../../utils/tableFunctions'
// import Add from './add/Add'

// const FileRegistrationModule = () => {
//   const filterOption = {
//     descending: true,
//     limit: defaultPageLimit,
//     orderFields: defaultOrderListLimit,
//   } as FilterModel
//   const { path } = useRouteMatch()
//   const [filter, setFilter] = useState(filterOption)
//   const [pageCount, setPageCount] = useState(0)
//   const [dataCount, setDatacount] = useState(0)
//   const [state, setstate] = useState([])
//   const [loading, setLoading] = useState(true)
//   const filters = useSelector(getFilters)
//   const dispatch = useDispatch()
//   const history = useHistory()
//   const actionList = useSelector(
//     getUserModuleActions(
//       'application-registration',
//       'file-registration',
//       'file-registration'
//     )
//   )

//   useEffect(() => {
//     dispatch(filtersSetRequest({ filters: fileAllocationFilters }))
//     async function getApplicationFiles() {
//       await retriveDataCountAndData(filter)
//       setLoading(false)
//     }
//     getApplicationFiles()
//   }, [])

//   const columns = React.useMemo(() => fileRegistrationDraftColumns, [])

//   const checkPermission = (permissionName: string) => {
//     const filterdAction = actionList.filter((action: ActionListModal) => {
//       if (action.action.name === permissionName) {
//         return action
//       }
//     })[0]
//     if (filterdAction != null) {
//       return true
//     } else {
//       return false
//     }
//   }

//   const retriveApplicationFilesData = async (pageDetails: any) => {
//     setLoading(true)
//     const newFilter = produce(filter, (draft) => {
//       draft.page = pageDetails.pageIndex + 1
//     })
//     setFilter(newFilter)
//     const fileRegistrationData =
//       await fileRegistrationService.apiFetchFileRegistrationList(newFilter)
//     if (fileRegistrationData.status === 200) {
//       setstate(fileRegistrationData.data)
//     }

//     setLoading(false)
//   }

//   async function retriveDataCountAndData(filterData: FilterModel) {
//     const applicationCountData =
//       await fileRegistrationService.apiFetchFileRegistrationListCount(
//         filterData
//       )
//     if (applicationCountData.status === 200) {
//       setDatacount(applicationCountData.data.count)
//       setPageCount(
//         getPageCount(applicationCountData.data.count, filterOption.limit)
//       )
//     }
//     const applicationData =
//       await fileRegistrationService.apiFetchFileRegistrationList(filterData)

//     if (applicationData.status === 200) {
//       setstate(applicationData.data)
//     }
//   }

//   const retriveFilterData = async (selectedFilters: FilterOptionModel[]) => {
//     setLoading(true)
//     const newFilter = produce(filter, (draft) => {
//       draft.page = 1
//       draft.filterData = selectedFilters
//     })
//     setFilter(newFilter)
//     await retriveDataCountAndData(newFilter)
//     setLoading(false)
//   }

//   const handleView = (id: number) => {
//     history.push(`${path}/draft/${id}`)
//   }

//   return (
//     <>
//       <Row className="title-layout">File Registration</Row>
//       <Switch>
//         <Route path={`${path}/`} exact>
//           <Row className="role-and-permission-body-layout mt-4">
//             <Col sm={12} className="mb-4">
//               <Row>
//                 <Col sm={6}>
//                   {checkPermission('view') === true && !loading && (
//                     <FileAllocationFilters
//                       filterData={filters}
//                       isDraft={true}
//                       onFilter={(e: FilterOptionModel[]) => {
//                         dispatch(filtersSetRequest({ filters: e }))
//                         retriveFilterData(
//                           e.filter(
//                             (filter: FilterOptionModel) =>
//                               filter.selected === true
//                           )
//                         )
//                       }}
//                     />
//                   )}
//                 </Col>
//                 <Col sm={6} className="d-flex justify-content-end">
//                   {checkPermission('add') === true && (
//                     <ActionButtonComponent name="Add" routeURL="add" />
//                   )}
//                 </Col>
//               </Row>
//             </Col>
//             {checkPermission('view') === true && (
//               <Col sm={12}>
//                 <div>
//                   <IterminalTableComponent
//                     columns={columns}
//                     data={state}
//                     fetchData={retriveApplicationFilesData}
//                     loading={loading}
//                     pageCount={pageCount}
//                     totalCount={dataCount}
//                     onClickRow={(row: any) => {
//                       handleView(row.id)
//                     }}
//                   />
//                 </div>
//               </Col>
//             )}
//           </Row>
//         </Route>
//         <Route path={`${path}/add`} component={Add} />
//         <Route
//           path={`${path}/draft/:id`}
//           render={(props) => <Add isDraft={true} {...props} />}
//         />
//       </Switch>
//     </>
//   )
// }

// export default FileRegistrationModule

import React, { useEffect } from 'react'
import { useRouteMatch, useHistory, Switch, Route } from 'react-router-dom'
import { defaultPageLimit } from '../../../../utils/constants'
import AdvancedSearchTable from '../../../../components/table/advancedSearchTable/AdvancedSearchTable'
import { fileRegistrationModuleColumns } from '../../../../components/table/advancedSearchTable/AdvancedSearchTableUtil'
import { AdvancedFilterModel } from '../../../../models/utils/filterModel'
import { useDispatch, useSelector } from 'react-redux'
import { filtersSetRequest } from '../../../../store/filter/actions'
import { compensationApplicationsFilters } from '../../../../utils/metaData'
import ActionButtonComponent from '../../../../components/common/actionButton/ActionButtonComponent'
import { Row, Col } from 'react-bootstrap'
import { getUserModuleActions } from '../../../../store/user/selectors'
import { ActionListModal } from '../../../../models/package/actionListModal'
import Add from './add/Add' // Adjust the import path as needed

const FileRegistrationModule = () => {
  // Initialize the filter option as per the AdvancedSearchTable requirements
  const initFilterOption = {
    filterValue: null,
    limit: defaultPageLimit, // Adjust if needed
    page: 1,
    orderBy: 'created_date_time', // Adjust the default orderBy field if necessary
    order: 'DESC',
  } as AdvancedFilterModel

  const { path } = useRouteMatch()
  const history = useHistory()
  const dispatch = useDispatch()
  const actionList = useSelector(
    getUserModuleActions(
      'application-registration',
      'file-registration',
      'file-registration'
    )
  )

  // Effect to set the filters in the Redux store
  useEffect(() => {
    dispatch(filtersSetRequest({ filters: compensationApplicationsFilters }))
  }, [dispatch])

  // Handle row click event
  const handleView = (id: number) => {
    history.push(`${path}/draft/${id}`)
  }

  // Check if the user has the specified permission
  const checkPermission = (permissionName: string) => {
    const filteredAction = actionList.filter((action: ActionListModal) => {
      return action.action.name === permissionName
    })[0]
    return filteredAction != null
  }

  return (
    <>
      <Row className="title-layout">File Registration</Row>
      <Switch>
        <Route path={`${path}/`} exact>
          <Row className="role-and-permission-body-layout mt-4">
            <Col className="mb-4">
              <Row className="d-flex justify-content-end">
                {/* Add button with permission check */}
                {checkPermission('add') && (
                  <ActionButtonComponent name="Add" routeURL="add" />
                )}
              </Row>
              <Row>
                <Col>
                  {/* Add a check for permissions before rendering the advanced search filter */}
                  {checkPermission('view') && (
                    <AdvancedSearchTable
                      initFilterOption={initFilterOption} // Pass the initialized filter option
                      columns={fileRegistrationModuleColumns} // Use the columns relevant to your data
                      dataApiPath={
                        '/application-registration/file-registration/file-registration/view/list'
                      } // Set to your actual data API path
                      countApiPath={
                        '/application-registration/file-registration/file-registration/view/count'
                      } // Set to your actual count API path
                      onClickRow={(row: any) => {
                        handleView(row.id) // Handle row clicks
                      }}
                    />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Route>
        <Route path={`${path}/add`} component={Add} />
        <Route
          path={`${path}/draft/:id`}
          render={(props) => <Add isDraft={true} {...props} />}
        />
      </Switch>
    </>
  )
}

export default FileRegistrationModule
