import { DSDivisionModal } from '../../models/administrativeArea/dsDivisionModal'
import { GNDivisionModal } from '../../models/administrativeArea/gnDivisionModal'
import {
  ProvinceFetchRequest,
  PROVINCE_FETCH_REQUEST,
  ProvinceFetchSuccessPayload,
  ProvinceFetchSuccess,
  PROVINCE_FETCH_SUCCESS,
  ProvinceFetchFailurePayload,
  ProvinceFetchFailure,
  PROVINCE_FETCH_FAILURE,
  DistrictFetchFailure,
  DistrictFetchFailurePayload,
  DistrictFetchRequest,
  DistrictFetchSuccess,
  DistrictFetchSuccessPayload,
  DISTRICT_FETCH_FAILURE,
  DISTRICT_FETCH_REQUEST,
  DISTRICT_FETCH_SUCCESS,
  DsFetchFailure,
  DsFetchFailurePayload,
  DsFetchRequest,
  DsFetchSuccess,
  DsFetchSuccessPayload,
  DS_FETCH_FAILURE,
  DS_FETCH_REQUEST,
  DS_FETCH_SUCCESS,
  GnFetchFailure,
  GnFetchFailurePayload,
  GnFetchRequest,
  GnFetchSuccess,
  GnFetchSuccessPayload,
  GN_FETCH_FAILURE,
  GN_FETCH_REQUEST,
  GN_FETCH_SUCCESS,
  DSDivisionSelectRequest,
  DSDivisionDeselectRequest,
  SELECT_DS_REQUEST,
  DESELECT_DS_REQUEST,
  ClearAdminsitrativeAreListRequest,
  CLEAR_ADMINISTRATIVEAREA_LIST_REQUEST,
  SetModifyAdministrativeAreaListRequest,
  ModifyAdministrativeAreaListdPayload,
  SET_MODIFY_ADMINISTRATIVEAREA_LIST_REQUEST,
  GNDivisionAllSelectRequest,
  SELECT_ALL_GN_REQUEST,
  DESELECT_ALL_GN_REQUEST,
  GNDivisionAllDeselectRequest,
  SELECT_ALL_DS_REQUEST,
  DESELECT_ALL_DS_REQUEST,
  DSDivisionAllDeselectRequest,
  DSDivisionAllSelectRequest,
} from './actionTypes'

export const setModifyAdministrativeAreaListRequest = (
  payload: ModifyAdministrativeAreaListdPayload
): SetModifyAdministrativeAreaListRequest => ({
  type: SET_MODIFY_ADMINISTRATIVEAREA_LIST_REQUEST,
  payload,
})

export const clearAdminsitrativeAreeLisRequest =
  (): ClearAdminsitrativeAreListRequest => ({
    type: CLEAR_ADMINISTRATIVEAREA_LIST_REQUEST,
  })

export const provinceFetchRequest = (): ProvinceFetchRequest => ({
  type: PROVINCE_FETCH_REQUEST,
})

export const dsDivisionSelectRequest = (
  id: number,
  districtId: number
): DSDivisionSelectRequest => ({
  type: SELECT_DS_REQUEST,
  payload: { id, districtId },
})

export const gnDivisionAllSelectRequest = (
  id: number,
  districtId: number,
  gnList: GNDivisionModal[]
): GNDivisionAllSelectRequest => ({
  type: SELECT_ALL_GN_REQUEST,
  payload: { id, districtId, gnList },
})

export const gnDivisionAllDeselectRequest = (
  id: number,
  districtId: number,
  gnList: GNDivisionModal[]
): GNDivisionAllDeselectRequest => ({
  type: DESELECT_ALL_GN_REQUEST,
  payload: { id, districtId, gnList },
})

export const dsDivisionAllSelectRequest = (
  id: number,
  dsList: DSDivisionModal[]
): DSDivisionAllSelectRequest => ({
  type: SELECT_ALL_DS_REQUEST,
  payload: { id, dsList },
})

export const dsDivisionAllDeselectRequest = (
  id: number,
  dsList: DSDivisionModal[]
): DSDivisionAllDeselectRequest => ({
  type: DESELECT_ALL_DS_REQUEST,
  payload: { id, dsList },
})

export const dsDivisionDeselectRequest = (
  id: number,
  districtId: number
): DSDivisionDeselectRequest => ({
  type: DESELECT_DS_REQUEST,
  payload: { id, districtId },
})

export const provinceFetchrSuccess = (
  payload: ProvinceFetchSuccessPayload
): ProvinceFetchSuccess => ({
  type: PROVINCE_FETCH_SUCCESS,
  payload,
})

export const provinceFetchFailure = (
  payload: ProvinceFetchFailurePayload
): ProvinceFetchFailure => ({
  type: PROVINCE_FETCH_FAILURE,
  payload,
})

export const districtFetchRequest = (): DistrictFetchRequest => ({
  type: DISTRICT_FETCH_REQUEST,
})

export const districtFetchSuccess = (
  payload: DistrictFetchSuccessPayload
): DistrictFetchSuccess => ({
  type: DISTRICT_FETCH_SUCCESS,
  payload,
})

export const districtFetchFailure = (
  payload: DistrictFetchFailurePayload
): DistrictFetchFailure => ({
  type: DISTRICT_FETCH_FAILURE,
  payload,
})

export const dsFetchRequest = (): DsFetchRequest => ({
  type: DS_FETCH_REQUEST,
})

export const dsFetchrSuccess = (
  payload: DsFetchSuccessPayload
): DsFetchSuccess => ({
  type: DS_FETCH_SUCCESS,
  payload,
})

export const dsFetchFailure = (
  payload: DsFetchFailurePayload
): DsFetchFailure => ({
  type: DS_FETCH_FAILURE,
  payload,
})

export const gnFetchRequest = (): GnFetchRequest => ({
  type: GN_FETCH_REQUEST,
})

export const gnFetchrSuccess = (
  payload: GnFetchSuccessPayload
): GnFetchSuccess => ({
  type: GN_FETCH_SUCCESS,
  payload,
})

export const gnFetchFailure = (
  payload: GnFetchFailurePayload
): GnFetchFailure => ({
  type: GN_FETCH_FAILURE,
  payload,
})
