import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import {
  useHistory,
  useRouteMatch,
  useLocation,
  Route,
  Switch,
} from 'react-router-dom'
import ActionButtonComponent from '../../../components/common/actionButton/ActionButtonComponent'
import LoadingComponent from '../../../components/common/loading/LoadingComponent'
import { ActionListModal } from '../../../models/package/actionListModal'
import TypeOfInjuriesServices from '../../../services/systemConfiguration/typeOfInjuries/typeOfInjuriesServices'
import { getUserSubPackageActions } from '../../../store/user/selectors'
import injury_type from '../../../assets/images/systemConfiguration/injury-type.svg'
import ViewTypeOfInjuries from './ViewTypeOfInjuries'
import AddTypeOfInjuries from './AddTypeOfInjuries'
import ModifyTypeOfInjuries from './ModifyTypeOfInjuries'
import { FilterModel } from '../../../models/utils/filterModel'
import { getFilters } from '../../../store/filter/selectors'
import { typeOfInjuriesFilters } from '../../../utils/metaData'
import { filtersSetRequest } from '../../../store/filter/actions'
import { getPageCount } from '../../../utils/tableFunctions'
import produce from 'immer'
import { FilterOptionModel } from '../../../models/utils/filterOption'
import IterminalTableComponent from '../../../components/table/IterminalTableComponent'
import TypesOfInjuriesFilters from '../../../components/filters/TypesOfInjuriesFilters'
import {
  defaultPageLimit,
  defaultOrderListLimit,
} from '../../../utils/constants'
const TypeOfInjuries = () => {
  const [typeOfInjuriesList, setTypeOfInjuriesList] = useState([])
  const pending = false
  const history = useHistory()
  const { path } = useRouteMatch()
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  const filterOption = {
    descending: true,
    limit: defaultPageLimit,
    orderFields: defaultOrderListLimit,
  } as FilterModel
  const [filter, setFilter] = useState(filterOption)
  const [pageCount, setPageCount] = useState(0)
  const [dataCount, setDatacount] = useState(0)
  const filters = useSelector(getFilters)
  const dispatch = useDispatch()

  const actionList = useSelector(
    getUserSubPackageActions('system-configuration', 'injury-type')
  )

  const checkPermission = (permissionName: string) => {
    const filterdAction = actionList.filter((action: ActionListModal) => {
      if (action.action.name === permissionName) {
        return action
      }
    })[0]
    if (filterdAction != null) {
      return true
    } else {
      return false
    }
  }
  useEffect(() => {
    dispatch(filtersSetRequest({ filters: typeOfInjuriesFilters }))
    async function getTypeOfInjuriesData() {
      await retriveDataCountAndData(filter)
      setLoading(false)
    }
    getTypeOfInjuriesData()
  }, [location.pathname])

  async function retriveDataCountAndData(filterData: FilterModel) {
    const typeOfInjuriesCountData =
      await TypeOfInjuriesServices.apiFetchTypeOfInjuriesListCount(filterData)
    setDatacount(typeOfInjuriesCountData.data.count)
    setPageCount(
      getPageCount(typeOfInjuriesCountData.data.count, filterOption.limit)
    )
    const typeOfInjuries =
      await TypeOfInjuriesServices.apiFetchTypeOfInjuriesList(filterData)
    setTypeOfInjuriesList(typeOfInjuries.data)
  }

  const retriveFilterData = async (selectedFilters: FilterOptionModel[]) => {
    console.log(selectedFilters)
    setLoading(true)
    const newFilter = produce(filter, (draft) => {
      draft.page = 1
      draft.filterData = selectedFilters
    })
    setFilter(newFilter)
    await retriveDataCountAndData(newFilter)
    setLoading(false)
  }

  const retrivegetTypeOfInjuriesData = async (pageDetails: any) => {
    setLoading(true)
    const newFilter = produce(filter, (draft) => {
      draft.page = pageDetails.pageIndex + 1
    })
    setFilter(newFilter)

    const incidentData =
      await TypeOfInjuriesServices.apiFetchTypeOfInjuriesList(newFilter)
    setTypeOfInjuriesList(incidentData.data)
    setLoading(false)
  }
  const columns = React.useMemo(
    () => [
      {
        Header: 'Type of Disablement',
        accessor: 'typeOfDisablement',
      },
      {
        Header: 'Category of Injury',
        accessor: 'categoryOfInjury',
      },
      {
        Header: 'Injury Description',
        accessor: 'injuryDescription',
      },
      {
        Header: 'Disablement Period',
        accessor: 'disablementPeriod',
      },
      {
        Header: 'Eligible Compensation Percentage (%)',
        accessor: 'compensationEligibility',
        Cell: (e: any) => {
          if (e.cell.value) {
            return <div>{e.row.original.compensationEligibility}</div>
          } else if (e.cell.value == 0 || e.cell.value == null) {
            return <div>{'N/A'}</div>
          }
        },
      },
      {
        Header: 'Schemes',
        id: 'schemeList',
        accessor: (data: { schemeList: any[] }) => {
          const output: any[] = []
          data.schemeList.map((item) => {
            return output.push(item.schemeCode)
          })
          return output.join(', ')
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
    ],
    []
  )
  const handleView = (id: number) => {
    history.push(`${path}/view/${id}`)
  }
  return (
    <>
      <Row className="title-layout">
        <Col className="d-flex justify-content-start">
          <img src={injury_type} alt="" />
          <span className="m-2">Type of Injuries</span>
        </Col>
      </Row>
      {pending ? (
        <div className="loading-layout">
          <LoadingComponent />
        </div>
      ) : (
        <Switch>
          <Route path={`${path}/`} exact>
            <Row className="incident-types-body-layout mt-4">
              <Col sm={12} className="mb-4">
                <Row>
                  <Col sm={6}>
                    {checkPermission('view') === true && !loading && (
                      <TypesOfInjuriesFilters
                        filterData={filters}
                        onFilter={(e: FilterOptionModel[]) => {
                          // dispatch(filtersSetRequest({ filters: e }))
                          retriveFilterData(
                            e.filter(
                              (filter: FilterOptionModel) =>
                                filter.selected === true
                            )
                          )
                        }}
                      />
                    )}
                  </Col>
                  <Col sm={6} className="d-flex justify-content-end">
                    {checkPermission('add') === true && (
                      <ActionButtonComponent name="Add" routeURL="add" />
                    )}
                  </Col>
                </Row>
              </Col>
              <Col sm={12}>
                <IterminalTableComponent
                  columns={columns}
                  data={typeOfInjuriesList}
                  fetchData={retrivegetTypeOfInjuriesData}
                  loading={loading}
                  pageCount={pageCount}
                  totalCount={dataCount}
                  onClickRow={(row: any) => {
                    console.log(row)
                    handleView(row.id)
                  }}
                />
              </Col>
            </Row>
          </Route>
          <Route path={`${path}/add`} component={AddTypeOfInjuries} />
          <Route path={`${path}/view/:id`} component={ViewTypeOfInjuries} />
          <Route path={`${path}/modify/:id`} component={ModifyTypeOfInjuries} />
        </Switch>
      )}
    </>
  )
}

export default TypeOfInjuries
