import {
  LoginUserRequest,
  USER_LOGIN_REQUEST,
  LoginUserSuccessPayload,
  LoginUserSuccess,
  LoginUserFailurePayload,
  LoginUserFailure,
  LogoutUserRequest,
  USER_LOGOUT_REQUEST,
  USER_LOGIN_FAILURE,
  USER_LOGIN_SUCCESS,
} from './actionTypes'

export const loginUserRequest = (): LoginUserRequest => ({
  type: USER_LOGIN_REQUEST,
})

export const loginUserSuccess = (
  payload: LoginUserSuccessPayload
): LoginUserSuccess => ({
  type: USER_LOGIN_SUCCESS,
  payload,
})

export const loginUserFailure = (
  payload: LoginUserFailurePayload
): LoginUserFailure => ({
  type: USER_LOGIN_FAILURE,
  payload,
})

export const logoutUserRequest = (): LogoutUserRequest => ({
  type: USER_LOGOUT_REQUEST,
})
