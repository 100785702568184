import React from 'react'
import './LoginComponent.scss'
import login from '../../assets/images/login.png'

const LoginLogoComponent = () => {
  return (
    <div className="login-image">
      <img src={login} alt="" />
    </div>
  )
}

export default LoginLogoComponent
