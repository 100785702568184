import * as errorMessages from '../../../utils/errorMessages'

export const validateIncidentCategory = (incidentCategory: string) => {
  if (!incidentCategory) {
    return errorMessages.incidentCategoryEmpty
  }
}
export const validateIncidentType = (incidentType: string) => {
  if (!incidentType) {
    return errorMessages.incidentTypeEmpty
  } else if (incidentType.length > 35) {
    return errorMessages.incidentTypeLength
  }
}
export const validateIncidentDescription = (description: string) => {
  if (!description) {
    return errorMessages.incidentDescriptionEmpty
  } else if (description.length > 75) {
    return errorMessages.incidentDescriptionLength
  }
}
// export const validateSchemeList = (schemeList: SchemeCodeModel[] | any) => {
//   if (!schemeList || schemeList.length === 0) {
//     return errorMessages.schemeCodeEmpty
//   }
// }
export const validateEffectiveDate = (effectiveDate: number) => {
  if (!effectiveDate) {
    return errorMessages.effectiveDateEmpty
  }
}
export const validateEndDate = (endDate: number) => {
  if (!endDate) {
    return errorMessages.endDateEmpty
  }
}
