import { useRouteMatch, useHistory } from 'react-router-dom'
import { AdvancedFilterModel } from '../../../../models/utils/filterModel'
import { defaultPageLimit } from '../../../../utils/constants'
import AdvancedSearchTable from '../../../../components/table/advancedSearchTable/AdvancedSearchTable'
import { commonColumns } from '../../../../components/table/advancedSearchTable/AdvancedSearchTableUtil'

const initFilterOption: AdvancedFilterModel = {
  filterValue: null,
  limit: defaultPageLimit,
  page: 1,
  orderBy: 'created_date_time',
  order: 'DESC',
  statusList: null,
  createdDateFrom: null,
  createdDateTo: null,
  scheme: 'GPC',
  programType: 'COMPENSATION',
  incidentTypeId: null,
  fileReceivedDate: null,
}

const GpcApplications = () => {
  const { path } = useRouteMatch()
  const history = useHistory()

  const handleView = (id: number) => {
    history.push(`${path}add/${id}`)
  }

  return (
    <>
      <AdvancedSearchTable
        initFilterOption={initFilterOption}
        columns={commonColumns}
        dataApiPath={'/cheque-payment/manage-cheque-details/view/list'}
        countApiPath={'/cheque-payment/manage-cheque-details/view/count'}
        onClickRow={(row: any) => {
          handleView(row.id)
        }}
      />
    </>
  )
}

export default GpcApplications
