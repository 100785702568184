import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useRouteMatch, Switch, Route } from 'react-router-dom'
import TileButtonComponent from '../../../components/common/tileButton/TileButtonComponent'
import { ModuleModal } from '../../../models/package/moduleModal'
import { getUserModules } from '../../../store/user/selectors'
import Ah from './ah/Ah'
import Se from './se/Se'
import Srt from './srt/Srt'

const Loan = () => {
  const { path } = useRouteMatch()
  const moduleList = useSelector(
    getUserModules('compensation-approval', 'loan')
  )

  const renderModules = () => {
    return moduleList.map((module: ModuleModal, index: number) => {
      return (
        <Col key={`module-${index}`} xs={4}>
          <TileButtonComponent
            isIcon={false}
            name={module.description}
            routeURL={module.name}
          />
        </Col>
      )
    })
  }
  return (
    <Switch>
      <Route path={`${path}/`} exact>
        <Row className="title-layout">Loan</Row>
        <Row className="page-tiles-layout">{renderModules()}</Row>
      </Route>
      <Route path={`${path}/ah`} component={Ah} />
      <Route path={`${path}/se`} component={Se} />
      <Route path={`${path}/srt`} component={Srt} />
    </Switch>
  )
}

export default Loan
