import React, { FC, useState } from 'react'
import { Container, Table } from 'react-bootstrap'
import { SimilarRecordModel } from '../../../models/applicationRegistration/fileRegistration/similarRecordModel'
import SimlarRecordViewModal from '../../modals/SimlarRecordViewModal'

export interface SimilarRecordTableProps {
  similarRecordList: any[]
  onClick?: any
}

const SimilarRecordTable: FC<SimilarRecordTableProps> = ({
  similarRecordList,
}) => {
  const [similarRecord, setSimilarRecord] = useState({} as SimilarRecordModel)
  const [showSimilarModal, setShowSimilarModal] = useState(false)
  const handleViewSimilarRecord = (record: any) => {
    if (record.isFromReppia) {
      setSimilarRecord(record.reppiaApplicationDetails)
      setShowSimilarModal(true)
    } else {
      const win = window.open(
        `/enquire-application/view/${record.orimsApplicationFileId}`,
        '_blank'
      )
      win.focus()
    }
  }

  return (
    <>
      <Container fluid className="mt-4">
        <Table striped bordered hover size="sm">
          <thead className="custom-table-header">
            <tr>
              <th>Application Reference</th>
              <th>Program Type</th>
              <th>Scheme</th>
              <th>Applicant Name</th>
              <th>Created Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody className="custom-table-body">
            {similarRecordList.map((record: any) => (
              <tr
                key={record.id}
                onClick={() => {
                  handleViewSimilarRecord(record)
                }}
              >
                <td className="td">
                  {record.isFromReppia
                    ? record.reppiaApplicationDetails.mapFileNo
                    : record.applicationReference}
                </td>
                <td className="td">{record.programType}</td>
                <td className="td">{record.scheme}</td>
                <td className="td">{record.applicantName}</td>
                <td className="td">
                  {record.createDateAndTime == null
                    ? 'N/A'
                    : new Date(
                        record.createDateAndTime * 1000
                      ).toLocaleDateString()}
                </td>
                <td className="td">{record.status}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
      {showSimilarModal && (
        <SimlarRecordViewModal
          similarRecord={similarRecord}
          onCancel={() => {
            setShowSimilarModal(false)
          }}
        />
      )}
    </>
  )
}

export default SimilarRecordTable
