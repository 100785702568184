import produce from 'immer'
import React, { FC, useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
// import ReactDatePicker from 'react-datepicker'
import { useSelector } from 'react-redux'
import InvalidFeedback from '../../../../components/common/invalidFeedback/InvalidFeedback'
import LoadingComponent from '../../../../components/common/loading/LoadingComponent'
import PriceInputComponent from '../../../../components/common/priceInputComponent/PriceInputComponent'
import ConfirmationModal from '../../../../components/modals/ConfirmationModal'
import { FileRegistrationModel } from '../../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { FamilyDetailsModel } from '../../../../models/applicationRegistration/loan/familyDetails'
import { FamilyDetailsErrorModel } from '../../../../models/applicationRegistration/loan/familyDetailsErrorModel'
import { ValidationObjectModel } from '../../../../models/common/validationObjectModel'
import seService from '../../../../services/applicationRegistration/loan/seService'
import metaDataService from '../../../../services/metaDataService'
import { getUser } from '../../../../store/user/selectors'
import {
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../../../utils/constants'
import { validationError } from '../../../../utils/errorMessages'
import { showTotast } from '../../../../utils/toast'
import {
  checkLoggedUser,
  checkStatsDraftOrModifyDraft,
  validateForm,
} from '../../../../utils/utilFunctions'

export interface FamilyDetailsSectionProps {
  fileRegistrationObject: FileRegistrationModel
  onOk: any
  onSave: any
  onNext: any
}

interface ConfirmationModal {
  isShow: boolean
  message: string
  section: string
}

const FamilyDetailsSection: FC<FamilyDetailsSectionProps> = ({
  fileRegistrationObject,
  onOk,
  onSave,
  onNext,
}) => {
  const [showConfirm, setShowConfirm] = useState({
    isShow: false,
  } as ConfirmationModal)
  const [relationshipList, setRelationShipList] = useState([])
  const [loading, setLoading] = useState(true)
  const [familyDetails, setFamilyDetails] = useState({} as FamilyDetailsModel)
  const [errors, setErrors] = useState({} as FamilyDetailsErrorModel)
  const user = useSelector(getUser)
  useEffect(() => {
    async function getMetaData() {
      console.log(loading)
      console.log(familyDetails)
      console.log(relationshipList)
      const relationshipData = await metaDataService.getRelationshipList()
      if (relationshipData.status === 200) {
        setRelationShipList(relationshipData.data)
      }

      const familyDetailsData = await seService.apiSeGetFamilyDetails(
        fileRegistrationObject.id
      )

      if (
        familyDetailsData.status === 200 &&
        familyDetailsData.data.id != null
      ) {
        setFamilyDetails(familyDetailsData.data)
      } else {
        const newFamilyDetails = produce(familyDetails, (draft) => {
          draft.applicationFileId = fileRegistrationObject.id
          draft.anyFamilyMemberDiedDueToViolenceActivity = false
          draft.deceasedPersonIsTheHeadOfTheFamily = false
          draft.widowDueToTheWar = false
          draft.incidentDate = fileRegistrationObject.incidentDate
        })
        setFamilyDetails(newFamilyDetails)
      }
      setLoading(false)
    }
    getMetaData()
  }, [fileRegistrationObject])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const findErrors = () => {
    const newErrors = {} as FamilyDetailsErrorModel
    if (familyDetails.anyFamilyMemberDiedDueToViolenceActivity) {
      if (!familyDetails.deceasedPersonIsTheHeadOfTheFamily) {
        if (
          validateForm({
            type: 'text',
            value: familyDetails.relationshipToTheHeadOfTheFamily,
          } as ValidationObjectModel)
        ) {
          newErrors.relationshipToTheHeadOfTheFamilyError = validateForm({
            type: 'text',
            value: familyDetails.relationshipToTheHeadOfTheFamily,
            section: 'Relationship to the Head of the Family',
          } as ValidationObjectModel)
        }
      }
      if (
        validateForm({
          type: 'text',
          value: familyDetails.typeOfWarDamage,
        } as ValidationObjectModel)
      ) {
        newErrors.typeOfWarDamageError = validateForm({
          type: 'text',
          value: familyDetails.typeOfWarDamage,
          section: 'Type of damage',
        } as ValidationObjectModel)
      }
    }

    if (familyDetails.specifyTheReason) {
      if (
        validateForm({
          type: 'text',
          maxLength: 50,
          value: familyDetails.specifyTheReason,
        } as ValidationObjectModel)
      ) {
        newErrors.specifyTheReasonError = validateForm({
          type: 'text',
          maxLength: 50,
          value: familyDetails.specifyTheReason,
          section:
            'If Head of the Family is not the Applicant, specify the reason',
        } as ValidationObjectModel)
      }
    }
    if (familyDetails.placeOfTheIncidentOccurred) {
      if (
        validateForm({
          type: 'text',
          maxLength: 100,
          value: familyDetails.placeOfTheIncidentOccurred,
        } as ValidationObjectModel)
      ) {
        newErrors.placeOfTheIncidentOccurredError = validateForm({
          type: 'text',
          maxLength: 100,
          value: familyDetails.placeOfTheIncidentOccurred,
          section: 'Place of the Incident occurred',
        } as ValidationObjectModel)
      }
    }
    if (
      validateForm({
        type: 'number',
        maxLength: 9,
        minValue: 1,
        value: familyDetails.familyMonthlyIncome,
      } as ValidationObjectModel)
    ) {
      newErrors.familyMonthlyIncomeError = validateForm({
        type: 'number',
        maxLength: 9,
        minValue: 1,
        value: familyDetails.familyMonthlyIncome,
        section: 'Family Monthly Income',
      } as ValidationObjectModel)
    }
    return newErrors
  }

  const onClickSave = () => {
    const foundErrros = findErrors()
    console.log(foundErrros)
    if (Object.keys(foundErrros).length > 0) {
      showTotast(
        ALERT_WARNING,
        validationError,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setErrors(foundErrros)
    } else {
      const message = 'Do you wish to Save application ?'
      const section = 'save'
      setShowConfirm({
        isShow: true,
        message: message,
        section: section,
      })
    }
  }

  const onClickNext = () => {
    const foundErrros = findErrors()
    console.log(foundErrros)
    if (Object.keys(foundErrros).length > 0) {
      showTotast(
        ALERT_WARNING,
        validationError,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setErrors(foundErrros)
    } else {
      const message = 'Do you wish to Continue application ?'
      const section = 'next'
      setShowConfirm({
        isShow: true,
        message: message,
        section: section,
      })
    }
  }

  const handleConfirm = () => {
    if (showConfirm.section === 'save') {
      onSave(familyDetails)
    } else {
      onNext(familyDetails)
    }
    setShowConfirm({ isShow: false, message: '', section: '' })
  }
  return (
    <>
      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          <Col sm={12} className="border-label">
            <div className="border-label-span">Family Details</div>
            <Form id="file-family-details-form">
              <Col sm={12} className="border-label">
                <div className="border-label-span">Deceased Member Details</div>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formAnyFamilyMember">
                    <Form.Label>
                      Any family member is victimized due to the war
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Row>
                      <Col>
                        <Form.Check
                          inline
                          name="formAnyFamilyMember"
                          label="Yes"
                          type="radio"
                          id={`formAnyFamilyMember-N`}
                          checked={
                            familyDetails.anyFamilyMemberDiedDueToViolenceActivity
                          }
                          onChange={() => {
                            const newFamilyDetails = produce(
                              familyDetails,
                              (draft: FamilyDetailsModel) => {
                                draft.anyFamilyMemberDiedDueToViolenceActivity =
                                  true
                              }
                            )
                            setFamilyDetails(newFamilyDetails)
                            const clearError = produce(errors, (draft) => {
                              draft.relationshipToTheHeadOfTheFamilyError = ''
                            })
                            setErrors(clearError)
                          }}
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          inline
                          name="formAnyFamilyMember"
                          label="No"
                          type="radio"
                          id={`formAnyFamilyMember-O`}
                          checked={
                            !familyDetails.anyFamilyMemberDiedDueToViolenceActivity
                          }
                          onChange={() => {
                            const newFamilyDetails = produce(
                              familyDetails,
                              (draft: FamilyDetailsModel) => {
                                draft.anyFamilyMemberDiedDueToViolenceActivity =
                                  false
                              }
                            )
                            setFamilyDetails(newFamilyDetails)
                            const clearError = produce(errors, (draft) => {
                              draft.relationshipToTheHeadOfTheFamilyError = ''
                            })
                            setErrors(clearError)
                          }}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Row>
                {familyDetails.anyFamilyMemberDiedDueToViolenceActivity && (
                  <>
                    <Row className="mb-4">
                      <Form.Group as={Col} controlId="formTypeOfDamanage">
                        <Form.Label>
                          Type of damage{' '}
                          <span className="required-field-astrix">*</span>
                        </Form.Label>
                        <Form.Control
                          isInvalid={!!errors.typeOfWarDamageError}
                          disabled={fileRegistrationObject.modifyIndex > 0}
                          className="form-select"
                          as="select"
                          value={familyDetails.typeOfWarDamage}
                          onChange={(e) => {
                            const newFamilyDetails = produce(
                              familyDetails,
                              (draft: FamilyDetailsModel) => {
                                draft.typeOfWarDamage = e.target.value
                              }
                            )
                            setFamilyDetails(newFamilyDetails)
                            const clearError = produce(errors, (draft) => {
                              draft.typeOfWarDamageError = ''
                            })
                            setErrors(clearError)
                          }}
                        >
                          <option value="">--Select Damage Type--</option>
                          <option value="Injured">Injured</option>
                          <option value="Dead">Dead</option>
                          <option value="Missing">Missing</option>
                        </Form.Control>
                        <InvalidFeedback
                          message={errors.typeOfWarDamageError}
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="formDeceasedHead">
                        <Form.Label>
                          Deceased person is the Head of the family{' '}
                          {familyDetails.anyFamilyMemberDiedDueToViolenceActivity ? (
                            <span className="required-field-astrix">*</span>
                          ) : (
                            ''
                          )}
                        </Form.Label>
                        <Row>
                          <Col>
                            <Form.Check
                              inline
                              name="formDeceasedHead"
                              label="Yes"
                              type="radio"
                              id={`formDeceasedHead-N`}
                              checked={
                                familyDetails.deceasedPersonIsTheHeadOfTheFamily
                              }
                              onChange={() => {
                                const newFamilyDetails = produce(
                                  familyDetails,
                                  (draft: FamilyDetailsModel) => {
                                    draft.deceasedPersonIsTheHeadOfTheFamily =
                                      true
                                  }
                                )
                                setFamilyDetails(newFamilyDetails)
                                const clearError = produce(errors, (draft) => {
                                  draft.relationshipToTheHeadOfTheFamilyError =
                                    ''
                                })
                                setErrors(clearError)
                              }}
                            />
                          </Col>
                          <Col>
                            <Form.Check
                              inline
                              name="formDeceasedHead"
                              label="No"
                              type="radio"
                              id={`formDeceasedHead-O`}
                              checked={
                                !familyDetails.deceasedPersonIsTheHeadOfTheFamily
                              }
                              onChange={() => {
                                const newFamilyDetails = produce(
                                  familyDetails,
                                  (draft: FamilyDetailsModel) => {
                                    draft.deceasedPersonIsTheHeadOfTheFamily =
                                      false
                                  }
                                )
                                setFamilyDetails(newFamilyDetails)
                                const clearError = produce(errors, (draft) => {
                                  draft.relationshipToTheHeadOfTheFamilyError =
                                    ''
                                })
                                setErrors(clearError)
                              }}
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                    </Row>
                    {!familyDetails.deceasedPersonIsTheHeadOfTheFamily && (
                      <Row className="mb-3">
                        <Form.Group as={Col} controlId="formRelationshipToHead">
                          <Form.Label>
                            Relationship to the Head of the Family{' '}
                            {!familyDetails.deceasedPersonIsTheHeadOfTheFamily ? (
                              <span className="required-field-astrix">*</span>
                            ) : (
                              ''
                            )}
                          </Form.Label>
                          <Form.Control
                            isInvalid={
                              !!errors.relationshipToTheHeadOfTheFamilyError
                            }
                            className="form-select"
                            as="select"
                            value={
                              familyDetails.relationshipToTheHeadOfTheFamily
                            }
                            onChange={(e) => {
                              const newFamilyDetails = produce(
                                familyDetails,
                                (draft: FamilyDetailsModel) => {
                                  draft.relationshipToTheHeadOfTheFamily =
                                    e.target.value
                                }
                              )
                              setFamilyDetails(newFamilyDetails)
                              const clearError = produce(errors, (draft) => {
                                draft.relationshipToTheHeadOfTheFamilyError = ''
                              })
                              setErrors(clearError)
                            }}
                          >
                            {1 === 1 ? (
                              <option value="">--Select Relationship--</option>
                            ) : (
                              ''
                            )}
                            {relationshipList.map(
                              (
                                title: { name: string; id: number },
                                index: number
                              ) => {
                                return (
                                  <option
                                    key={`country-${index}`}
                                    value={title.name}
                                  >
                                    {title.name}
                                  </option>
                                )
                              }
                            )}
                          </Form.Control>
                          <InvalidFeedback
                            message={
                              errors.relationshipToTheHeadOfTheFamilyError
                            }
                          />
                        </Form.Group>
                      </Row>
                    )}
                  </>
                )}
              </Col>
              <Col sm={12} className="border-label mt-4">
                <div className="border-label-span">Other Details</div>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formwidow">
                    <Form.Label>
                      Widow due to the war{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Row>
                      <Col>
                        <Form.Check
                          inline
                          name="formwidow"
                          label="Yes"
                          type="radio"
                          id={`formwidow-N`}
                          checked={familyDetails.widowDueToTheWar}
                          onChange={() => {
                            const newFamilyDetails = produce(
                              familyDetails,
                              (draft: FamilyDetailsModel) => {
                                draft.widowDueToTheWar = true
                              }
                            )
                            setFamilyDetails(newFamilyDetails)
                          }}
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          inline
                          name="formwidow"
                          label="No"
                          type="radio"
                          id={`formwidow-O`}
                          checked={!familyDetails.widowDueToTheWar}
                          onChange={() => {
                            const newFamilyDetails = produce(
                              familyDetails,
                              (draft: FamilyDetailsModel) => {
                                draft.widowDueToTheWar = false
                              }
                            )
                            setFamilyDetails(newFamilyDetails)
                          }}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Row>
                <Form.Group className="mb-3" controlId="formReason">
                  <Form.Label>
                    If Head of the Family is not the Applicant, specify the
                    reason
                  </Form.Label>
                  <Form.Control
                    isInvalid={!!errors.specifyTheReasonError}
                    type="text"
                    placeholder="Enter Reason"
                    onBlur={() => {
                      if (familyDetails.specifyTheReason) {
                        const newErrors = produce(errors, (draft) => {
                          draft.specifyTheReasonError = validateForm({
                            type: 'text',
                            maxLength: 50,
                            value: familyDetails.specifyTheReason,
                            section:
                              'If Head of the Family is not the Applicant, specify the reason',
                          } as ValidationObjectModel)
                        })
                        setErrors(newErrors)
                      }
                    }}
                    onChange={(e) => {
                      const newFamilyDetails = produce(
                        familyDetails,
                        (draft: FamilyDetailsModel) => {
                          draft.specifyTheReason = e.target.value
                        }
                      )
                      setFamilyDetails(newFamilyDetails)
                      const clearError = produce(errors, (draft) => {
                        draft.specifyTheReasonError = ''
                      })
                      setErrors(clearError)
                    }}
                    value={familyDetails.specifyTheReason}
                  />
                  <InvalidFeedback message={errors.specifyTheReasonError} />
                </Form.Group>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formRelationshipToAggrived">
                    <Form.Label>
                      Applicant’s Relationship to the Aggrieved Person
                    </Form.Label>
                    <Form.Control
                      isInvalid={!!errors.relationshipToTheAggrievedPersonError}
                      className="form-select"
                      as="select"
                      value={familyDetails.relationshipToTheAggrievedPerson}
                      onChange={(e) => {
                        const newFamilyDetails = produce(
                          familyDetails,
                          (draft: FamilyDetailsModel) => {
                            draft.relationshipToTheAggrievedPerson =
                              e.target.value
                          }
                        )
                        setFamilyDetails(newFamilyDetails)
                      }}
                    >
                      {1 === 1 ? (
                        <option value="">--Select Relationship--</option>
                      ) : (
                        ''
                      )}
                      {relationshipList.map(
                        (
                          title: { name: string; id: number },
                          index: number
                        ) => {
                          return (
                            <option key={`country-${index}`} value={title.name}>
                              {title.name}
                            </option>
                          )
                        }
                      )}
                    </Form.Control>
                    <InvalidFeedback
                      message={errors.relationshipToTheAggrievedPersonError}
                    />
                  </Form.Group>
                </Row>
                {/* <Form.Group className="mb-3" controlId="formIncidentDate">
                  <Form.Label>Incident Date</Form.Label>
                  <ReactDatePicker
                    disabled={true}
                    required={true}
                    maxDate={new Date()}
                    className="form-control"
                    placeholderText="Select Incident Date"
                    selected={
                      fileRegistrationObject.incidentDate != null
                        ? new Date(fileRegistrationObject.incidentDate * 1000)
                        : null
                    }
                  />
                </Form.Group> */}
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formIncidentOccur">
                    <Form.Label>Place of the Incident occurred</Form.Label>
                    <Form.Control
                      isInvalid={!!errors.placeOfTheIncidentOccurredError}
                      type="text"
                      placeholder="Enter Place of the Incident occurred"
                      onBlur={() => {
                        if (familyDetails.placeOfTheIncidentOccurred) {
                          const newErrors = produce(errors, (draft) => {
                            draft.placeOfTheIncidentOccurredError =
                              validateForm({
                                type: 'text',
                                maxLength: 100,
                                value: familyDetails.placeOfTheIncidentOccurred,
                                section: 'Place of the Incident occurred',
                              } as ValidationObjectModel)
                          })
                          setErrors(newErrors)
                        }
                      }}
                      onChange={(e) => {
                        const newFamilyDetails = produce(
                          familyDetails,
                          (draft: FamilyDetailsModel) => {
                            draft.placeOfTheIncidentOccurred = e.target.value
                          }
                        )
                        setFamilyDetails(newFamilyDetails)
                        const clearError = produce(errors, (draft) => {
                          draft.placeOfTheIncidentOccurredError = ''
                        })
                        setErrors(clearError)
                      }}
                      value={familyDetails.placeOfTheIncidentOccurred}
                    />
                    <InvalidFeedback
                      message={errors.placeOfTheIncidentOccurredError}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formFamilyIncome">
                    <Form.Label>
                      Family Monthly Income{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <PriceInputComponent
                      isInvalid={errors.familyMonthlyIncomeError}
                      value={familyDetails.familyMonthlyIncome}
                      placeholder="Enter Enter Family Monthly Income"
                      onChange={(price: string) => {
                        const newFamilyDetails = produce(
                          familyDetails,
                          (draft: FamilyDetailsModel) => {
                            draft.familyMonthlyIncome = parseFloat(price)
                          }
                        )
                        setFamilyDetails(newFamilyDetails)

                        const newErrors = produce(errors, (draft) => {
                          draft.familyMonthlyIncomeError = validateForm({
                            type: 'number',
                            maxLength: 9,
                            minValue: 1,
                            value: parseFloat(price),
                            section: 'Family Monthly Income',
                          } as ValidationObjectModel)
                        })
                        setErrors(newErrors)
                      }}
                      onErrorHandle={() => {
                        const clearError = produce(errors, (draft) => {
                          draft.familyMonthlyIncomeError = ''
                        })
                        setErrors(clearError)
                      }}
                    />
                    <InvalidFeedback
                      message={errors.familyMonthlyIncomeError}
                    />
                  </Form.Group>
                </Row>
              </Col>
            </Form>
          </Col>
          <Col sm={12} className="d-flex justify-content-end mt-4">
            {checkStatsDraftOrModifyDraft(fileRegistrationObject.status) &&
              checkLoggedUser(
                user.epfNumber,
                fileRegistrationObject.allocateTo
              ) && (
                <>
                  <button
                    className="save-button custom-margin-right"
                    onClick={onClickNext}
                  >
                    Next
                  </button>
                  <button
                    className="save-button custom-margin-right"
                    onClick={onClickSave}
                  >
                    Save
                  </button>
                </>
              )}

            <button className="cancel-button" onClick={onOk}>
              Ok
            </button>
          </Col>
          {showConfirm.isShow && (
            <ConfirmationModal
              name={'Confirmation'}
              message={showConfirm.message}
              onCancel={() => {
                setShowConfirm({
                  isShow: false,
                  message: '',
                  section: '',
                })
              }}
              isRemark={false}
              onConfirm={handleConfirm}
            />
          )}
        </>
      )}
    </>
  )
}

export default FamilyDetailsSection
