import React, { useEffect, useState } from 'react'
import './CreateRolesAndPermissionsComponent.scss'
import { Container, Row, Col, Form } from 'react-bootstrap'
import userManagementService from '../../../services/userManagementService'
import PermissionsList from '../../../components/common/allPermissions/PermissionsList'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedPackages } from '../../../store/permissions/selectors'
import produce from 'immer'
import { IRole } from '../../../store/usermanagement/actionTypes'
import { PermissionModel } from '../../../models/usermanagement/permissionModel'
import { useHistory } from 'react-router'
import {
  clearPermissionsLisRequest,
  fetchPermissionsLisRequest,
} from '../../../store/permissions/actions'

import { showTotast } from '../../../utils/toast'
import {
  ALERT_SUCCESS,
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../../utils/constants'
import {
  roleNameEmpty,
  roleNameLength,
  roleDescription,
  rolePermission,
  validationError,
} from '../../../utils/errorMessages'
import ConfirmationModal from '../../../components/modals/ConfirmationModal'
import { roleCreate } from '../../../utils/successMessages'
import { changeLoadingRequest } from '../../../store/app/actionTypes'

const initialState: IRole = {
  name: '',
  description: '',
  allPermissions: false,
  permissions: {} as PermissionModel,
  createdBy: '',
  createdDateTime: 0,
  status: '',
  modifiedBy: '',
  modifiedDateTime: 0,
  roleId: 0,
}

interface FormErrorModel {
  roleNameError: string
  roleDescriptionError: string
  rolePermissionError: string
}

const CreateRolesAndPermissionsComponent = () => {
  const [formValue, setFormValue] = useState(initialState)
  const [showConfirmation, seShowConfirmation] = useState(false)
  const [errors, setErrors] = useState({} as FormErrorModel)
  const selectedPackagesList = useSelector(getSelectedPackages)
  const { name, description } = formValue
  const history = useHistory()

  const dispatch = useDispatch()

  const handleBack = () => {
    history.goBack()
  }

  const findFormErrors = () => {
    const newErrors = {} as FormErrorModel

    // Role name error
    if (!formValue.name || formValue.name === '') {
      newErrors.roleNameError = roleNameEmpty
    } else if (formValue.name != null && formValue.name?.length > 50) {
      newErrors.roleNameError = roleNameLength
    }

    // Role description error
    if (formValue.description != null && formValue.description?.length > 250) {
      newErrors.roleDescriptionError = roleDescription
    }

    // Role permission error
    if (formValue.allPermissions === false && selectedPackagesList == null) {
      newErrors.rolePermissionError = rolePermission
    } else if (
      formValue.allPermissions === false &&
      selectedPackagesList.length === 0
    ) {
      newErrors.rolePermissionError = rolePermission
    }
    return newErrors
  }

  useEffect(() => {
    async function getMetaData() {
      dispatch(fetchPermissionsLisRequest())
    }
    getMetaData()
    return () => {
      dispatch(clearPermissionsLisRequest())
    }
  }, [])

  const onSelected = (value: boolean) => {
    if (value == true) {
      const newValue = produce(formValue, (draft: IRole) => {
        draft.allPermissions = true
      })
      setFormValue(newValue)
    } else {
      const newValue = produce(formValue, (draft: IRole) => {
        draft.allPermissions = false
      })
      setFormValue(newValue)
    }
  }
  const handleSubmit = async () => {
    const newErrors = findFormErrors()
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors)
      showTotast(
        ALERT_WARNING,
        validationError,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
    } else {
      setErrors(newErrors)
      seShowConfirmation(true)
    }
  }

  const handleConfirm = async () => {
    const submitObject = produce(formValue, (draft: IRole) => {
      draft.permissions.packageList = selectedPackagesList
    })
    dispatch(changeLoadingRequest())
    const result = await userManagementService.apiRoleCreate(submitObject)
    dispatch(changeLoadingRequest())
    let success = false
    let message = result.errorMessage ? result.errorMessage : ''
    if (result.status === 200) {
      success = true
      message = roleCreate
      history.goBack()
    }
    showTotast(
      success ? ALERT_SUCCESS : ALERT_WARNING,
      message,
      TOAST_POSITION_TOP_RIGHT,
      5,
      TOAST_TRANSITION_SLIDE
    )
  }
  return (
    <Row className="create-role-page-layout">
      <Col sm={12} className="border-label">
        <div className="border-label-span">Add Role</div>
        <Form>
          <Form.Group className="mb-3" controlId="formGroupName">
            <Form.Label>
              Name <span className="required-field-astrix">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Name"
              onChange={(e) => {
                const newFormValue = produce(formValue, (draft: IRole) => {
                  draft.name = e.target.value
                })
                setFormValue(newFormValue)
              }}
              onBlur={(e) => {
                const newError = produce(errors, (draft) => {
                  if (e.target.value === '') {
                    draft.roleNameError = roleNameEmpty
                  } else if (e.target.value?.length > 50) {
                    draft.roleNameError = roleNameLength
                  } else {
                    draft.roleNameError = ''
                  }
                })
                setErrors(newError)
              }}
              value={name}
              isInvalid={!!errors.roleNameError}
            />
            <Form.Control.Feedback type="invalid">
              {errors.roleNameError}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formGroupDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={2}
              type="text"
              placeholder="Enter Description"
              onChange={(e) => {
                const newFormValue = produce(formValue, (draft: IRole) => {
                  draft.description = e.target.value
                })
                setFormValue(newFormValue)
              }}
              value={description}
              onBlur={(e) => {
                const newError = produce(errors, (draft) => {
                  if (e.target.value?.length > 250) {
                    draft.roleDescriptionError = roleDescription
                  } else {
                    draft.roleDescriptionError = ''
                  }
                })
                setErrors(newError)
              }}
              isInvalid={!!errors.roleDescriptionError}
            />
            <Form.Control.Feedback type="invalid">
              {errors.roleDescriptionError}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formGroupPermission">
            <Form.Label>
              Permission <span className="required-field-astrix">*</span>
            </Form.Label>
            <Row>
              <Col sm={12}>
                <Container>
                  <Form.Check
                    type="checkbox"
                    label="All Permissions"
                    name="allPermissions"
                    onChange={(e) => {
                      onSelected(e.target.checked)
                    }}
                    isInvalid={!!errors.rolePermissionError}
                  />
                </Container>
              </Col>
              {!formValue.allPermissions && (
                <Col sm={12}>
                  <PermissionsList unEditable={false} />
                </Col>
              )}
            </Row>
            <Form.Control.Feedback type="invalid">
              {errors.rolePermissionError}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Col>
      <Col sm={12} className="d-flex justify-content-end mt-4">
        <button className="cancel-button" onClick={handleBack}>
          Cancel
        </button>
        <button className="save-button" onClick={handleSubmit}>
          Save
        </button>
      </Col>
      {showConfirmation && (
        <ConfirmationModal
          name={'Confirmation'}
          message={'Are you sure to create this role ?'}
          onCancel={() => {
            seShowConfirmation(false)
          }}
          onConfirm={handleConfirm}
        />
      )}
    </Row>
  )
}
export default CreateRolesAndPermissionsComponent
