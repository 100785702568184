import React, { useEffect, useState } from 'react'
import produce from 'immer'
import { IPoliceStation } from '../../../models/systemConfiguration/mangePoliceStations/policeStation'
import { Col, Form, Row } from 'react-bootstrap'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import ConfirmationModal from '../../../components/modals/ConfirmationModal'
import PoliceStationServices from '../../../services/systemConfiguration/manegePoliceStation/policeStationServices'
import {
  ALERT_SUCCESS,
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../../utils/constants'
import { showTotast } from '../../../utils/toast'
import { validationError } from '../../../utils/errorMessages'
import { ValidationObjectModel } from '../../../models/common/validationObjectModel'
import { validateForm } from '../../../utils/utilFunctions'
import InvalidFeedback from '../../../components/common/invalidFeedback/InvalidFeedback'

interface PoliceStationErrorModel {
  nameError?: string
}
type TParams = { id: string }
const ModifyPoliceStations = ({ match }: RouteComponentProps<TParams>) => {
  const [state, setSate] = useState({} as IPoliceStation)
  const history = useHistory()
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [errors, setErrors] = useState({} as PoliceStationErrorModel)

  useEffect(() => {
    async function getData() {
      const result = await PoliceStationServices.apiGetPoliceStation(
        parseInt(match.params.id)
      )
      setSate(result.data)
    }
    getData()
  }, [])

  const findFormErrors = () => {
    const newErrors = {} as PoliceStationErrorModel
    if (
      validateForm({
        maxLength: 50,
        type: 'text',
        value: state.name,
      } as ValidationObjectModel)
    ) {
      newErrors.nameError = validateForm({
        type: 'text',
        maxLength: 50,
        value: state.name,
        section: 'Name of the police station',
      } as ValidationObjectModel)
    }
    return newErrors
  }

  const handleSubmit = () => {
    const foundErrros = findFormErrors()
    if (Object.keys(foundErrros).length > 0) {
      showTotast(
        ALERT_WARNING,
        validationError,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setErrors(foundErrros)
    } else {
      setErrors(foundErrros)
      setShowConfirmation(true)
    }
  }
  const handleConfirm = async () => {
    const result = await PoliceStationServices.apiUpdatePoliceStation(
      match.params.id,
      state
    )
    let success = false
    let message = result.errorMessage ? result.errorMessage : ''
    if (result.status === 200) {
      success = true
      message = 'Police station successfully updated'
      history.goBack()
    }
    showTotast(
      success ? ALERT_SUCCESS : ALERT_WARNING,
      message,
      TOAST_POSITION_TOP_RIGHT,
      5,
      TOAST_TRANSITION_SLIDE
    )
  }

  return (
    <>
      <Row className="add-Incident-Type-page-layout">
        <Col sm={12} className="border-label">
          <div className="border-label-span">Add Police Station</div>
          <Form>
            <Form.Group className="mb-3" controlId="stampCharge">
              <Form.Label>Name</Form.Label>
              <Form.Control
                isInvalid={!!errors.nameError}
                type="text"
                value={state.name}
                onChange={(e) => {
                  const newVal = produce(state, (draft) => {
                    draft.name = e.target.value
                  })
                  setSate(newVal)
                  const clearError = produce(errors, (draft) => {
                    draft.nameError = ''
                  })
                  setErrors(clearError)
                }}
              />
              <InvalidFeedback message={errors.nameError} />
            </Form.Group>
          </Form>
          <Row>
            <Col sm={12} className="d-flex justify-content-end mt-4">
              <button
                className="cancel-button"
                onClick={(e) => {
                  e.preventDefault()
                  history.goBack()
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="save-button"
                onClick={handleSubmit}
              >
                Save
              </button>
            </Col>
          </Row>
        </Col>
        {showConfirmation && (
          <ConfirmationModal
            name={'Confirmation'}
            message={'Are you sure to update this police station ?'}
            onCancel={() => {
              setShowConfirmation(false)
            }}
            onConfirm={handleConfirm}
          />
        )}
      </Row>
    </>
  )
}

export default ModifyPoliceStations
