import React from 'react'
import { Route, Switch } from 'react-router-dom'
import './App.scss'
import SampleComponent from './components/sample/SampleComponent'
import ChangePasswordPageComponent from './pages/changePassword/ChangePasswordPageComponent'
import Home from './pages/home/Home'
import LoginPageComponent from './pages/login/LoginPageComponent'
// eslint-disable-next-line import/no-unresolved
import ForgotPasswordComponent from './pages/forgotPassword/ForgotPasswordComponent'
import TodoPageComponent from './pages/todo/TodoPageComponent'
import PrivateRoute from './providers/PrivateRouteProvider'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
function App() {
  toast.configure()
  return (
    <div>
      <Switch>
        <Route exact path="/todo" component={TodoPageComponent} />
        <Route exact path="/sample" component={SampleComponent} />
        <PrivateRoute
          exact
          path="/changepassword"
          component={ChangePasswordPageComponent}
        />
        <Route exact path="/login" component={LoginPageComponent} />
        <Route
          exact
          path="/forgot_password"
          component={ForgotPasswordComponent}
        />
        <PrivateRoute path="/" component={Home} />
      </Switch>
    </div>
  )
}

export default App
