import * as errorMessages from '../../utils/errorMessages'

export const validateCurrentPassword = (currentPassword: string) => {
  if (!currentPassword) {
    return errorMessages.currentPasswordEmpty
  }
}
export const validateNewPassword = (newPassword: string) => {
  if (!newPassword) {
    return errorMessages.newPasswordEmpty
  }
}
export const validateReEnterdewPassword = (
  reEnterdewPassword?: string | '',
  newPassword?: string | ''
) => {
  if (!reEnterdewPassword || reEnterdewPassword == '') {
    return errorMessages.reEnterdPasswordEmpty
  } else if (newPassword !== reEnterdewPassword) {
    return errorMessages.passwordMissmatch
  }
}
