import apiService from '../api/apiManager'

class MyProfileDataService {
  async apiGetNotificationCount() {
    return apiService.apiGet(`/my-profile/view/notification/count`)
  }

  async apiResetNotificationCount() {
    return apiService.apiGet(`/my-profile/view/notification/reset`)
  }

  async apiGetNotificationList() {
    return apiService.apiGet(`/my-profile/view/notification/list`)
  }

  async apiReadNotification(id: number) {
    return apiService.apiPutCommon(
      `/my-profile/view/notification/${id}/read`,
      {}
    )
  }
}

export default new MyProfileDataService()
