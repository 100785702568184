import { ActionTypeListModel } from '../../../models/systemConfiguration/rejectionReturnReasons/actionTypeListModel'
import * as errorMessages from '../../../utils/errorMessages'

export const validateReasonType = (value: number) => {
  if (!value) {
    return errorMessages.reasonTypeEmpty
  }
}
export const validateReasonDescription = (value: string) => {
  if (!value) {
    return errorMessages.reasonDescriptionEmpty
  } else if (value.length > 75) {
    return errorMessages.reasonDescriptionLength
  }
}
export const validateActionTypeList = (
  actionType: ActionTypeListModel[] | any
) => {
  if (!actionType || actionType.length === 0) {
    return errorMessages.actionTypeEmpty
  }
}
