/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-empty-function */
// eslint-disable-next-line eslint-comments/no-duplicate-disable
/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { ActionListModal } from '../../../models/package/actionListModal'
import {
  packageActionSelectRequest,
  packageActionDeselectRequest,
  subPackageActionSelectRequest,
  subPackageActionDeselectRequest,
  moduleActionDeselectRequest,
  moduleActionSelectRequest,
  subModuleActionDeselectRequest,
  subModuleActionSelectRequest,
} from '../../../store/permissions/actions'

export interface Actionrops {
  actionListModal: ActionListModal
  index: number
  mode: boolean
  relevantModue: string
  moduleId?: number
  packageId?: number
  subPackageId?: number
  subModuleId?: number
  packageIndex?: number
  subPackageIndex?: number
  moduleIndex?: number
  subModuleIndex?: number
}

const Action: FC<Actionrops> = ({
  actionListModal,
  index,
  mode,
  relevantModue,
  moduleId = 0,
  packageIndex = 0,
  subPackageIndex = 0,
  moduleIndex = 0,
  subModuleIndex = 0,
}) => {
  const [isSelected, setIsSelected] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {}, [actionListModal.isSelected])

  const onSelected = (value: boolean) => {
    switch (relevantModue) {
      case 'package':
        if (value) {
          dispatch(
            packageActionSelectRequest({
              packageIndex: packageIndex,
              actionListId: actionListModal.id,
            })
          )
        } else {
          dispatch(
            packageActionDeselectRequest({
              packageIndex: packageIndex,
              actionListId: actionListModal.id,
            })
          )
        }
        break
      case 'sub-package':
        if (value) {
          dispatch(
            subPackageActionSelectRequest({
              actionListId: actionListModal.id,
              packageIndex: packageIndex,
              subPackageIndex: subPackageIndex,
            })
          )
        } else {
          dispatch(
            subPackageActionDeselectRequest({
              actionListId: actionListModal.id,
              packageIndex: packageIndex,
              subPackageIndex: subPackageIndex,
            })
          )
        }
        break
      case 'module':
        if (value) {
          dispatch(
            moduleActionSelectRequest({
              actionListId: actionListModal.id,
              packageIndex: packageIndex,
              subPackageIndex: subPackageIndex,
              moduleIndex: moduleIndex,
            })
          )
        } else {
          dispatch(
            moduleActionDeselectRequest({
              actionListId: actionListModal.id,
              packageIndex: packageIndex,
              subPackageIndex: subPackageIndex,
              moduleIndex: moduleIndex,
            })
          )
        }
        break
      case 'sub-module':
        if (value) {
          dispatch(
            subModuleActionSelectRequest({
              actionListId: actionListModal.id,
              packageIndex: packageIndex,
              subPackageIndex: subPackageIndex,
              moduleIndex: moduleIndex,
              subModuleIndex: subModuleIndex,
            })
          )
        } else {
          dispatch(
            subModuleActionDeselectRequest({
              actionListId: actionListModal.id,
              packageIndex: packageIndex,
              subPackageIndex: subPackageIndex,
              moduleIndex: moduleIndex,
              subModuleIndex: subModuleIndex,
            })
          )
        }
        break
      default:
        break
    }
  }
  return (
    <div className="action-area">
      {!mode ? (
        <ul
          key={`${relevantModue}-action-${actionListModal.action.name}-${index}`}
        >
          <Form.Check
            key={`${relevantModue}-action-${actionListModal.action.name}`}
            label={actionListModal.action.description}
            name={actionListModal.action.name}
            className="custom-form-check"
            onChange={(e) => {
              onSelected(e.target.checked)
              setIsSelected(e.target.checked)
            }}
            onClick={(e) => {
              e.stopPropagation()
            }}
            disabled={mode}
            checked={actionListModal.isSelected}
          />
        </ul>
      ) : (
        <ul key={`action-${actionListModal.action.name}-${index}`}>
          <li key={`action-${actionListModal.action.name}`}>
            {actionListModal.action.description}
          </li>
        </ul>
      )}
    </div>
  )
}

export default Action
