import { FilterOptionModel } from '../../models/utils/filterOption'

export const SET_FILTERS = 'SET_FILTERS'
export const RESET_FILTERS = 'RESER_FILTERS'
export const REMOVE_FILTERS = 'REMOVE_FILTERS'
export const UPDATE_FILTER = 'UPDATE_FILTER'
export const SET_FILTER_META_DATA = 'SET_FILTER_META_DATA'

export interface FilterState {
  filters: FilterOptionModel[]
}

export interface SetFilterPayload {
  filters: FilterOptionModel[]
}

export interface UpdateFilterPayload {
  filter: FilterOptionModel
}

export interface FiltersSetRequest {
  type: typeof SET_FILTERS
  payload: SetFilterPayload
}

export interface FilterResetRequest {
  type: typeof RESET_FILTERS
}

export interface FiltersRemovedRequest {
  type: typeof REMOVE_FILTERS
}

export interface FiltersUpdateRequest {
  type: typeof UPDATE_FILTER
  payload: UpdateFilterPayload
}

export type FilterActions =
  | FiltersSetRequest
  | FilterResetRequest
  | FiltersRemovedRequest
  | FiltersUpdateRequest
