export const validationError =
  'Validation error found. Please complete all required fields and try again.'
export const roleNameEmpty = 'Role name cannot be blank!'
export const roleNameLength = 'Role name cannot be more than 50 characters!'
export const roleDescription =
  'Role description cannot be more than 250 characters!'
export const rolePermission = 'Role permission cannot be empty!'

export const titleEmpty = 'Title cannot be empty'
export const firstNameEmpty = 'First name cannot be empty'
export const firstNameLength = 'First name cannot be more than 50 characters'
export const lastNameEmpty = 'Last name cannot be empty'
export const lastNameLength = 'Last name cannot be more than 50 characters'
export const epfNumberEmpty = 'EPF number cannot be empty'
export const epfNumberLength = 'EPF number cannot be more than 50 characters'
export const emailAdressEmpty = 'Email address cannot be empty'
export const emailAdressInvalid = 'Email address  is invalid'
export const emailAdressLength =
  'Email address cannot be more than 50 characters'
export const mobileNumberEmpty = 'Mobile number cannot be empty'
export const mobileNumberInvalid = 'Mobile number is invalid'
export const organizationEmpty = 'Organization cannot be empty'
export const designationEmpty = 'Designation cannot be empty'
export const rolesEmpty = 'Roles cannot be empty'
export const payeeEmpty = 'Payees cannot be empty'
export const administrativeAreaListEmpty =
  'Administrative Area List cannot be empty'
export const schemeDescriptionEmpty = 'Scheme Description  cannot be empty'
export const incidentCategoryEmpty = 'Incident Category cannot be empty'
export const incidentTypeEmpty = 'Incident Type cannot be empty'
export const incidentDescriptionEmpty = 'Incident Description cannot be empty'
export const schemeCodeEmpty = 'Scheme Code cannot be empty'
export const effectiveDateEmpty = 'Effective Date cannot be empty'
export const endDateEmpty = 'End Date cannot be empty'
export const incidentTypeLength =
  'Incident Type cannot be more than 35 characters'
export const incidentDescriptionLength =
  'Incident Description cannot be more than 75 characters'
export const typeOfDisablementEmpty = 'Type of Disablement  cannot be empty'
export const categoryOfInjuryEmpty = 'Category of Injury cannot be empty'
export const injuryDescriptionEmpty = 'Injury Description cannot be empty'
export const injuryDescriptionLength =
  'Injury Description cannot be more than 50 characters'
export const earningCapacityLossEmpty =
  'Percentage of Loss of Earning Capacity cannot be empty'
export const earningCapacityLossLength =
  'Percentage of Loss of Earning Capacity is not valid'
export const compensationEligibilityEmpty =
  'Eligible Compensation Percentage cannot be empty'
export const compensationEligibilityLength =
  'Eligible Compensation Percentage is not valid'
export const disablementPeriodEmpty = 'Disablement Period cannot be empty'
export const schemeListEmpty = 'Scheme Code cannot be empty'
export const startDateEmpty = 'Start Date cannot be empty'
export const stamChargeAmountEmpty = 'Stamp Charge Amount should not be empty'
export const currentPasswordEmpty = 'Current Password should not be empty'
export const newPasswordEmpty = 'New Password should not be empty'
export const reEnterdPasswordEmpty = 'Re Enterd Password should not be empty'
export const passwordMissmatch =
  'New Password and ReEnterd Password are Mismatched'
export const documentNameEmpty = 'Document name can not be empty!'
export const documentNameLength =
  'Document name cannot be more than 400 characters!'
export const fileTypeEmpty = 'File Type cannot be empty!'
export const fileSizeNotValid = 'File size is not valid!'
export const fileSizeEmpty = 'File size cannot be empty!'
export const checkboxEmpty = 'Please select an option!'

//systeme configuaration - compensation values
export const typeofProgramEmpty = 'Type of program cannot be empty!'
export const schemeEmpty = 'Scheme cannot be empty!'
//export const incidentTypeEmpty = 'Incident Type cannot be empty!'
export const compensationforEmpty = 'Compensation for cannot be empty!'
export const percentagesAmongRelationshipEmpty =
  'Percentages among relationship cannot be empty!'
export const damageTypeEmpty = 'Damage Type cannot be empty!'
export const maximumAmountEmpty = 'Maximum Amount cannot be empty!'
export const maximumAmountNotValid = 'Maximum Amount is not valid!'
export const minimumAmountNotValid = 'Minimum Amount is not valid!'

export const reasonTypeEmpty = 'Reason Type cannot be empty!'
export const actionTypeEmpty = 'Action Type cannot be empty!'
export const reasonDescriptionEmpty = 'Reason description cannot be empty!'
export const reasonDescriptionLength =
  'Reason description cannot be more than 75 characters!'

export const propertyNameEmpty = 'Name of the property cannot be empty!'
export const propertyNameLength =
  'Name of the property cannot be more than 75 characters!'
export const propertyCategoryEmpty = 'Property category cannot be empty!'
export const applicablevaluesEmpty = 'Applicable values cannot be empty!'
export const propertyDescriptionEmpty = 'Property description cannot be empty!'
export const propertyDescriptionLength =
  'Property description cannot be more than 75 characters!'
