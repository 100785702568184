import React, { useEffect, useState } from 'react'
import { Col, Row, Form } from 'react-bootstrap'
// import {
//   RouteComponentProps,
//   // useHistory,
//   //   useLocation,
// } from 'react-router-dom'
import dashboard_icon from '../../assets/images/nav-icons/my-profile.svg'
import { UserProfileView } from '../../models/userProfileManager/userProfileView'
import userProfileManageService from '../../services/userProfileManagement/userProfileManageService'
import { Title } from '../../models/title/title'
import ChangePassword from '../../components/modals/changePasswordModal'
import produce from 'immer'
import Province from '../userManagement/users/userAdministrativeAreas/Province'
import { ProvinceModal } from '../../models/administrativeArea/provinceModal'
import { myProfileAmendMassage } from '../../utils/successMessages'
import { showTotast } from '../../utils/toast'
import { useHistory, useRouteMatch } from 'react-router-dom'
import {
  ALERT_SUCCESS,
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../utils/constants'
import ConfirmationModal from '../../components/modals/ConfirmationModal'
import { UserProfileError } from '../../models/userProfileManager/userProfileError'
import * as validation from './validations'
import { validationError } from '../../utils/errorMessages'
import metaDataService from '../../services/metaDataService'
import { useSelector } from 'react-redux'
import { getUserPackageActions } from '../../store/user/selectors'
import { ActionListModal } from '../../models/package/actionListModal'
// import userManagementService from '../../services/userManagementService'
// import { validationError } from '../../../utils/errorMessages'

const MyProfileView = () => {
  const { path } = useRouteMatch()
  const [myProfile, setMyProfile] = useState({} as UserProfileView)
  const [title, setTitle] = useState([] as Array<Title>)
  const [isDisable, setIsDisable] = useState(true)
  const history = useHistory()
  const [showConfirmation, setShowConfirmation] = useState(false)
  const actionList = useSelector(getUserPackageActions('my-profile'))
  const [errors, setErrors] = useState({} as UserProfileError)
  const optionsTitle = title.map((obj) => {
    return <option key={obj.id}>{obj.name}</option>
  })
  const [modalShow, setModalShow] = useState(false)
  const renderAdministrativeAreas = () => {
    if (myProfile.userAdministrativeAreas) {
      return (
        <Col>
          {myProfile.userAdministrativeAreas.map(
            (province: ProvinceModal, index: number) => {
              return (
                <ul className="custom-ul" key={`province-${province.id}`}>
                  <Province
                    key={`province-${index}`}
                    name={province.provinceName}
                    districtList={province.districtList}
                  />
                </ul>
              )
            }
          )}
        </Col>
      )
    } else {
      return <span>No Roles Found</span>
    }
  }

  const checkPermission = (permissionName: string) => {
    const filterdAction = actionList.filter((action: ActionListModal) => {
      if (action.action.name === permissionName) {
        return action
      }
    })[0]
    if (filterdAction != null) {
      return true
    } else {
      return false
    }
  }

  // const user = await userManagementService.apiFetchUserDataByEpfNumber(
  //   myProfile.epfNumber
  // )
  // const [titleModel, setTitleModel] = useState({} as Title)
  useEffect(() => {
    async function getUerProfile() {
      const response = await userProfileManageService.getUserProfileDetails()
      setMyProfile(response.data)
      console.log(response.data)
    }
    getUerProfile()
    // async function getUserInfor() {
    //   const user = await userManagementService.apiFetchUserDataByEpfNumber(
    //     myProfile.epfNumber
    //   )
    // }
    // getUserInfor()
  }, [])

  useEffect(() => {
    async function getTitle() {
      const response = await metaDataService.apiTitleListFetch()
      setTitle(response.data)
    }
    getTitle()
  }, [])

  const myProfileAmendScreen = () => {
    setIsDisable(false)
    // history.push(`${path}/modify`)
  }

  const myProfileAmend = async () => {
    console.log(myProfile)
    delete myProfile.organization
    const response = await userProfileManageService.amendUserProfileDetails(
      myProfile.epfNumber,
      myProfile
    )
    debugger
    let success = false
    let message = response.errorMessage ? response.errorMessage : ''
    if (response.status === 200) {
      success = true
      message = myProfileAmendMassage
      history.replace(`${path}/modify`)
    }
    showTotast(
      success ? ALERT_SUCCESS : ALERT_WARNING,
      message,
      TOAST_POSITION_TOP_RIGHT,
      5,
      TOAST_TRANSITION_SLIDE
    )
  }

  const cancel = async () => {
    setIsDisable(true)
  }

  const handleConfirm = async () => {
    setModalShow(true)
  }

  const handleAmend = async () => {
    const foundErrros = findFormErrors()
    if (Object.keys(foundErrros).length > 0) {
      showTotast(
        ALERT_WARNING,
        validationError,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setErrors(foundErrros)
    } else {
      setMyProfile
      setShowConfirmation(true)
    }
  }

  const findFormErrors = () => {
    const newErrors = {} as UserProfileError
    if (validation.validateTitle(myProfile.title)) {
      newErrors.title = validation.validateTitle(myProfile.title)
    }
    if (validation.validateFirstName(myProfile.firstName)) {
      newErrors.firstName = validation.validateFirstName(myProfile.firstName)
    }
    if (validation.validateLastName(myProfile.lastName)) {
      newErrors.lastName = validation.validateLastName(myProfile.lastName)
    }
    if (validation.validateEmail(myProfile.email)) {
      newErrors.email = validation.validateEmail(myProfile.email)
    }
    if (validation.validateMobile(myProfile.mobileNumber)) {
      newErrors.mobileNumber = validation.validateMobile(myProfile.mobileNumber)
    }

    return newErrors
  }

  return (
    <>
      <Row className="title-layout">
        <Col className="d-flex justify-content-start">
          <img src={dashboard_icon} alt="" />
          <span className="m-2">My Profile</span>
        </Col>
      </Row>
      <Row className="create-user-page-layout">
        <Col sm={12} className="border-label">
          <div className="border-label-span">My Profile View</div>
          <Form>
            <Form.Group className="mb-3" controlId="formGroupTitle">
              <Form.Label>
                Title <span className="required-field-astrix">*</span>
              </Form.Label>
              <Form.Control
                isInvalid={!!errors.title}
                as="select"
                defaultValue={myProfile.title}
                disabled={isDisable}
                onBlur={(e) => {
                  const newError = produce(errors, (draft) => {
                    draft.title = validation.validateTitle(e.target.value)
                  })
                  setErrors(newError)
                }}
                onChange={(e) => {
                  const newFormValue = produce(
                    myProfile,
                    (draft: UserProfileView) => {
                      draft.title = e.target.value
                    }
                  )
                  setMyProfile(newFormValue)
                }}
              >
                {optionsTitle}
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupFirstName">
              <Form.Label>
                First Name <span className="required-field-astrix">*</span>
              </Form.Label>
              <Form.Control
                isInvalid={!!errors.firstName}
                placeholder="Enter First Name"
                type="text"
                disabled={isDisable}
                defaultValue={myProfile.firstName}
                onBlur={(e) => {
                  const newError = produce(errors, (draft) => {
                    draft.firstName = validation.validateFirstName(
                      e.target.value
                    )
                  })
                  setErrors(newError)
                }}
                onChange={(e) => {
                  const newFormValue = produce(
                    myProfile,
                    (draft: UserProfileView) => {
                      draft.firstName = e.target.value
                    }
                  )
                  setMyProfile(newFormValue)
                }}
              />
              <Form.Control.Feedback type="invalid">
                {errors.firstName}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupLastName">
              <Form.Label>
                Last Name <span className="required-field-astrix">*</span>
              </Form.Label>
              <Form.Control
                isInvalid={!!errors.lastName}
                placeholder="Enter Last Name"
                type="text"
                disabled={isDisable}
                defaultValue={myProfile.lastName}
                onBlur={(e) => {
                  const newError = produce(errors, (draft) => {
                    draft.lastName = validation.validateLastName(e.target.value)
                  })
                  setErrors(newError)
                }}
                onChange={(e) => {
                  const newFormValue = produce(
                    myProfile,
                    (draft: UserProfileView) => {
                      draft.lastName = e.target.value
                    }
                  )
                  setMyProfile(newFormValue)
                }}
              />
              <Form.Control.Feedback type="invalid">
                {errors.lastName}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupEPFNumber">
              <Form.Label>
                EPF Number <span className="required-field-astrix">*</span>
              </Form.Label>
              <Form.Control
                placeholder="Enter EPF Number"
                type="text"
                disabled
                defaultValue={myProfile.epfNumber}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupEmailAddress">
              <Form.Label>
                Email Address <span className="required-field-astrix">*</span>
              </Form.Label>
              <Form.Control
                isInvalid={!!errors.email}
                placeholder="Enter Email Address"
                type="email"
                disabled={isDisable}
                defaultValue={myProfile.email}
                onBlur={(e) => {
                  const newError = produce(errors, (draft) => {
                    draft.email = validation.validateEmail(e.target.value)
                  })
                  setErrors(newError)
                }}
                onChange={(e) => {
                  const newFormValue = produce(
                    myProfile,
                    (draft: UserProfileView) => {
                      draft.email = e.target.value
                    }
                  )
                  setMyProfile(newFormValue)
                }}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupMobileNumber">
              <Form.Label>
                Mobile Number <span className="required-field-astrix">*</span>
              </Form.Label>
              <Form.Control
                isInvalid={!!errors.mobileNumber}
                maxLength={10}
                placeholder="Enter Mobile Number"
                type="text"
                disabled={isDisable}
                defaultValue={myProfile.mobileNumber}
                onBlur={(e) => {
                  const newError = produce(errors, (draft) => {
                    draft.mobileNumber = validation.validateMobile(
                      e.target.value
                    )
                  })
                  setErrors(newError)
                }}
                onChange={(e) => {
                  const newFormValue = produce(
                    myProfile,
                    (draft: UserProfileView) => {
                      draft.mobileNumber = e.target.value
                    }
                  )
                  setMyProfile(newFormValue)
                }}
              />
              <Form.Control.Feedback type="invalid">
                {errors.mobileNumber}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupOrganization">
              <Form.Label>
                Organization <span className="required-field-astrix">*</span>
              </Form.Label>
              <Form.Control
                className="form-select"
                type="text"
                disabled
                defaultValue={myProfile.organization}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">
                {/* {errors.organizationError} */}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupDesignation">
              <Form.Label>
                Designation <span className="required-field-astrix">*</span>
              </Form.Label>
              <Form.Control
                className="form-select"
                type="text"
                disabled
                defaultValue={myProfile.organization}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">
                {/* {errors.designationError} */}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupAdminAreaList">
              <Col>
                <Form.Label>Administrative Area List</Form.Label>
                <br />
                <Row className="admin-area-list">
                  {renderAdministrativeAreas()}
                </Row>
              </Col>
              <br />
              <span className="feedback-error-span">
                {/* {errors.userAdministrativeAreasError} */}
              </span>
            </Form.Group>
            {checkPermission('change-password') && (
              <Form.Group className="mb-3" controlId="formGroupAdminAreaList">
                <button
                  type="button"
                  className="system-active-button"
                  onClick={() => {
                    setModalShow(true)
                  }}
                >
                  Change Password
                </button>
                {/* <Button
                  className="save-button"
                  hidden={isDisable}
                  onClick={() => setModalShow(true)}
                >
                  Change Password
                </Button> */}
                {modalShow && (
                  <ChangePassword
                    name={myProfile.epfNumber}
                    message={'Do you want to change password ?'}
                    modalClose={() => {
                      setModalShow(false)
                    }}
                    modalShow={handleConfirm}
                  />
                )}
              </Form.Group>
            )}
          </Form>
        </Col>
        <Col sm={12} className="d-flex justify-content-end mt-4">
          <button className="cancel-button" onClick={cancel}>
            Cancel
          </button>
          {checkPermission('modify') && (
            <>
              <button
                // type="button"
                className="save-button"
                onClick={myProfileAmendScreen}
                hidden={!isDisable}
              >
                Modify
              </button>
              <button
                // type="button"
                className="save-button"
                hidden={isDisable}
                onClick={handleAmend}
              >
                Save
              </button>
            </>
          )}
        </Col>
        {showConfirmation && (
          <ConfirmationModal
            name={myProfile.epfNumber}
            message={'Do you want to update this user profile ?'}
            onCancel={() => {
              setShowConfirmation(false)
            }}
            onConfirm={myProfileAmend}
          />
        )}
      </Row>
    </>
  )
}

export default MyProfileView
