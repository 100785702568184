import React, { useState } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory, RouteComponentProps } from 'react-router-dom'
import ConfirmationModal from '../../../components/modals/ConfirmationModal'
import ReactTableWithPaginationComponent from '../../../components/table/ReactTableWithPaginationComponent'
import { ActionListModal } from '../../../models/package/actionListModal'
import metaDataService from '../../../services/metaDataService'
import { dsFetchRequest } from '../../../store/administrativeArea/actions'
import { getDistritByDistrictId } from '../../../store/administrativeArea/selectors'
import { getUserSubPackageActions } from '../../../store/user/selectors'
import {
  ALERT_SUCCESS,
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../../utils/constants'
import { showTotast } from '../../../utils/toast'

interface ConfirmationModal {
  isShow: boolean
  message: string
  section: string
  isRemark: boolean
}

type TParams = {
  provinceId: any
  districtId: string
}

const DsDivisionList = ({ match }: RouteComponentProps<TParams>) => {
  const [dsList, setDsList] = useState([])
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const [showConfirm, setShowConfirm] = useState({
    isShow: false,
    isRemark: false,
  } as ConfirmationModal)

  const [dsId, setDsId] = useState('')

  const distrit = useSelector(
    getDistritByDistrictId(parseInt(match.params.districtId))
  )

  const actionList = useSelector(
    getUserSubPackageActions('system-configuration', 'administrative-areas')
  )

  const checkPermission = (permissionName: string) => {
    const filterdAction = actionList.filter((action: ActionListModal) => {
      if (action.action.name === permissionName) {
        return action
      }
    })[0]
    if (filterdAction != null) {
      return true
    } else {
      return false
    }
  }

  React.useEffect(() => {
    getDsList('')
  }, [location.pathname])

  const getDsList = async (value: string) => {
    const result = await metaDataService.apiGetDsListByDistrictId(
      match.params.districtId
    )
    if (result.status == 200) {
      if (value === '') {
        setDsList(result.data)
      }
      const newData = result.data.filter((data: any) => {
        return data.name.includes(value)
      })
      setDsList(newData)
    }
  }

  const deleteDsDivision = async () => {
    const result = await metaDataService.apiDeleteDsDivistion(dsId)
    let success = false
    let message = result.errorMessage ? result.errorMessage : ''
    if (result.status == 200) {
      success = true
      message = 'Ds Division successfully deleted'
      setDsList(result.data)
      dispatch(dsFetchRequest())
      setDsId('')
      setShowConfirm({
        isShow: false,
        message: '',
        section: '',
        isRemark: false,
      })
    }
    showTotast(
      success ? ALERT_SUCCESS : ALERT_WARNING,
      message,
      TOAST_POSITION_TOP_RIGHT,
      5,
      TOAST_TRANSITION_SLIDE
    )
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Code',
        accessor: 'code', // accessor is the "key" in the data
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Action',
        accessor: 'id',
        width: 100,
        // eslint-disable-next-line react/display-name
        Cell: (e: any) => {
          return (
            <div className="text-center">
              <button
                style={{ width: '6rem' }}
                onClick={(action) => {
                  action.preventDefault()
                  action.stopPropagation()
                  setDsId(e.cell.value)
                  setShowConfirm({
                    isShow: true,
                    message: 'Are you sure you want to modify this record ?',
                    section: 'modify',
                    isRemark: false,
                  })
                }}
                className="save-button m-1 me-1"
              >
                Modify
              </button>
              <button
                style={{ width: '5rem' }}
                onClick={(action) => {
                  action.preventDefault()
                  action.stopPropagation()
                  setDsId(e.cell.value)
                  setShowConfirm({
                    isShow: true,
                    message: 'Are you sure you want to delete this record ?',
                    section: 'delete',
                    isRemark: false,
                  })
                }}
                className="save-button m-1"
              >
                Delete
              </button>
            </div>
          )
        },
      },
    ],
    []
  )
  const handleView = (
    gnDivisionId: string,
    provinceId: string,
    districtId: string
  ) => {
    history.push(
      `/system-configuration/administrative-areas/province/${provinceId}/district/${districtId}/ds-division/${gnDivisionId}/gn-division/list`
    )
  }

  const handleConfirm = () => {
    if (showConfirm.section == 'delete') {
      deleteDsDivision()
    } else if (showConfirm.section == 'modify') {
      history.push(
        `/system-configuration/administrative-areas/province/${match.params.provinceId}/district/${match.params.districtId}/ds-division/${dsId}/modify`
      )
      setDsId('')
    }
  }

  return (
    <>
      <Row className="title-layout">
        <Col className="d-flex justify-content-start">
          {/* <img src={incident_type} alt="" /> */}
          <span className="m-2">DS Divisions - {distrit && distrit.name}</span>
        </Col>
      </Row>

      <Row className="incident-types-body-layout mt-4">
        <Col sm={12} className="mb-4">
          <Row>
            <Col sm={3}>
              <Form.Control
                placeholder="Enter name for filter records"
                className="mt-2 ms-1"
                onChange={(e) => {
                  getDsList(e.target.value)
                }}
              />
            </Col>
            <Col sm={9} className="d-flex justify-content-end">
              {checkPermission('add') === true && (
                <button
                  className="action-button-layout"
                  onClick={() => {
                    history.push(
                      `/system-configuration/administrative-areas/province/${match.params.provinceId}/district/${match.params.districtId}/ds-division/add`
                    )
                  }}
                >
                  Add
                </button>
              )}
            </Col>
          </Row>
        </Col>
        <Col sm={12}>
          <ReactTableWithPaginationComponent
            hover
            columns={columns}
            data={dsList ? dsList : []}
            onClickRow={(row: any) => {
              handleView(row.id, row.provinceId, row.districtId)
            }}
          />
        </Col>
        {showConfirm.isShow && (
          <ConfirmationModal
            name={'Confirmation'}
            message={showConfirm.message}
            onCancel={() => {
              setShowConfirm({
                isShow: false,
                message: '',
                section: '',
                isRemark: false,
              })
            }}
            onConfirm={handleConfirm}
          />
        )}
      </Row>
    </>
  )
}

export default DsDivisionList
