import { useRouteMatch, useHistory } from 'react-router-dom'
import { AdvancedFilterModel } from '../../../../models/utils/filterModel'
import { defaultPageLimit } from '../../../../utils/constants'
import AdvancedSearchTable from '../../../../components/table/advancedSearchTable/AdvancedSearchTable'
import { Column } from '../../../../components/table/advancedSearchTable/AdvancedSearchTableUtil'
import manageChequePaymentsService from '../../../../services/chequePayments/manageChequePayments/manageChequePaymentsService'
import { handleNotification } from '../../../../utils/utilFunctions'

const initFilterOption: AdvancedFilterModel = {
  filterValue: null,
  limit: defaultPageLimit,
  page: 1,
  orderBy: 'created_date_time',
  order: 'DESC',
  statusList: null,
  createdDateFrom: null,
  createdDateTo: null,
  scheme: 'MP',
  programType: 'COMPENSATION',
  incidentTypeId: null,
  fileReceivedDate: null,
}

const MpReturn = () => {
  const { path } = useRouteMatch()
  const history = useHistory()

  const commonColumns: Column[] = [
    { header: 'Application Reference', accessor: 'referenceNumber' },
    { header: 'Incident Type', accessor: 'incidentType.incidentType' },
    { header: 'Scheme', accessor: 'scheme' },
    { header: 'Applicant Name', accessor: 'applicantName' },
    { header: 'Identification Type', accessor: 'identificationType' },
    { header: 'ID Number', accessor: 'idNumber' },
    { header: 'District', accessor: 'district.name' },
    { header: 'DS Division', accessor: 'dsDivision.name' },
    { header: 'File Received Date', accessor: 'fileReceivedDate' },
    { header: 'Created Date and Time', accessor: 'createdDateTime' },
    { header: 'Status', accessor: 'status' },
    {
      header: 'Action',
      accessor: 'id',
      // eslint-disable-next-line react/display-name
      render: (row: any): JSX.Element => (
        <button
          className="save-button"
          onClick={(action) => {
            action.preventDefault()
            action.stopPropagation()
            handleView(row)
          }}
        >
          Modify
        </button>
      ),
    },
  ]

  const handleView = async (object: any) => {
    if (object.status == 'Cheque Payment Returned') {
      const applicationData =
        await manageChequePaymentsService.apiModifyChequePrintReturnFile(
          object.id
        )
      if (applicationData.status == 200) {
        handleNotification(applicationData, 'Ready to modify')
        history.push(`${path}add/${object.id}`)
      }
    }
  }

  return (
    <>
      <AdvancedSearchTable
        initFilterOption={initFilterOption}
        columns={commonColumns}
        dataApiPath={'/cheque-payment/cheque-print/file-return/view/list'}
        countApiPath={'/cheque-payment/cheque-print/file-return/view/count'}
      />
    </>
  )
}

export default MpReturn
