/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import './AdministrativeArea.scss'
import React, { FC, useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { DSDivisionModal } from '../../../models/administrativeArea/dsDivisionModal'
import {
  getDs,
  getDsByDistrictId,
} from '../../../store/administrativeArea/selectors'
import DsDivision from './DsDivision'
import { faMinusSquare, faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  dsDivisionAllSelectRequest,
  dsDivisionAllDeselectRequest,
} from '../../../store/administrativeArea/actions'
import produce from 'immer'

export interface DistrictProps {
  id: number
  name?: string
  code?: string
  isSelected?: boolean
  allDsSelected?: boolean
}

const District: FC<DistrictProps> = ({ id, name, code, allDsSelected }) => {
  const dsList = useSelector(getDsByDistrictId(id))
  const allDsList = useSelector(getDs)
  const [isSelected, setIsSelected] = useState(false)
  const dispatch = useDispatch()

  const renderDsList = () => {
    return dsList.map((ds: DSDivisionModal, index: number) => {
      return (
        <ul className="custom-ul" key={`ds-divission-${ds.id}`}>
          <DsDivision
            key={`ds-${ds.id}-${index}`}
            id={ds.id}
            name={ds.name}
            code={ds.code}
            allSelected={ds.allSelected}
            districtId={id}
            isDsSelected={ds.isSelected}
          />
        </ul>
      )
    })
  }
  const handleSelectAll = (value: boolean) => {
    if (value) {
      const newDsList = produce(allDsList, (draft) => {
        for (let i = 0; i < draft.length; i++) {
          if (draft[i].districtId === id) {
            draft[i].isSelected = true
          }
        }
      })
      console.log(newDsList)
      dispatch(dsDivisionAllSelectRequest(id, newDsList))
    } else {
      const newDsList = produce(allDsList, (draft) => {
        for (let i = 0; i < draft.length; i++) {
          if (draft[i].districtId === id) {
            draft[i].isSelected = false
          }
        }
      })
      console.log(newDsList)
      dispatch(dsDivisionAllDeselectRequest(id, newDsList))
    }
  }
  return (
    <div
      role="button"
      className="custom-permission-button"
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        setIsSelected(!isSelected)
      }}
    >
      <Container fluid>
        <Row>
          <Col className="align-self-center" sm={1}>
            {isSelected ? (
              <FontAwesomeIcon icon={faMinusSquare} />
            ) : (
              <FontAwesomeIcon icon={faPlusSquare} />
            )}
          </Col>
          <Col sm={11}>
            <Row
              className={
                isSelected ? 'custom-header-selected' : 'custom-header'
              }
            >
              {name}
            </Row>
          </Col>
        </Row>
        {isSelected && (
          <Row>
            <Col className="admin-area-custom-inner-loop">
              <Form.Check
                checked={allDsSelected}
                key={`all-ds-division-${id}-${code}`}
                name="Select All"
                label="Select All"
                className="select-all-checkbox"
                onClick={(e) => {
                  e.stopPropagation()
                }}
                onChange={(e) => {
                  handleSelectAll(e.target.checked)
                }}
              />
              <Col className="custom-inner-loop">{renderDsList()}</Col>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  )
}

export default District
