import React, { useState } from 'react'
import { Row, Nav, Tab } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useRouteMatch, Switch, Route } from 'react-router'
import {
  getUserModuleActions,
  getUserSubModuleActions,
} from '../../../../store/user/selectors'
import { checkPermission } from '../../../../utils/utilFunctions'
import SeFileView from '../../../compensationRecommendation/loan/se/SeFileView'
import LoanAttribute from '../LoanAttribute'
import SeApplication from './SeApplication'
import SeAttribute from './SeAttribute'
import SeFileRegistration from './SeFileRegistration'
import SeReturn from './SeReturn'

const Se = () => {
  const { path } = useRouteMatch()
  const [selectedSection, setSelectedSection] = useState('se')
  const actionListModule = useSelector(
    getUserModuleActions('application-registration', 'loan', 'se')
  )

  const actionListSubModule = useSelector(
    getUserSubModuleActions(
      'application-registration',
      'loan',
      'se',
      'file-return'
    )
  )

  const attributeActionListSubModule = useSelector(
    getUserSubModuleActions(
      'application-registration',
      'loan',
      'se',
      'attribute'
    )
  )

  const renderSwitch = (selection: string) => {
    switch (selection) {
      case 'se':
        return <SeApplication />
      case 'se-return':
        return <SeReturn />
      case 'se-attribute':
        return <SeAttribute />
      default:
        return <SeReturn />
    }
  }

  return (
    <Switch>
      <Route path={`${path}/`} exact>
        <Tab.Container id="left-tabs-example" defaultActiveKey="se">
          <Row className="tab-page-layout">
            <Nav variant="pills" className="flex-row">
              {checkPermission('view', actionListModule) && (
                <Nav.Item className="custom-nav-item">
                  <Nav.Link
                    onClick={() => {
                      setSelectedSection('se')
                    }}
                    eventKey="se"
                  >
                    SE Application
                  </Nav.Link>
                </Nav.Item>
              )}
              {checkPermission('view', actionListSubModule) && (
                <Nav.Item className="custom-nav-item">
                  <Nav.Link
                    onClick={() => {
                      setSelectedSection('se-return')
                    }}
                    eventKey="se-return"
                  >
                    SE Return
                  </Nav.Link>
                </Nav.Item>
              )}
              {checkPermission('view', attributeActionListSubModule) && (
                <Nav.Item className="custom-nav-item">
                  <Nav.Link
                    onClick={() => {
                      setSelectedSection('se-attribute')
                    }}
                    eventKey="se-attribute"
                  >
                    SE Attribute Details
                  </Nav.Link>
                </Nav.Item>
              )}
            </Nav>
          </Row>
          <Row className="page-tiles-layout align-content-start">
            {renderSwitch(selectedSection)}
          </Row>
        </Tab.Container>
      </Route>
      <Route
        path={`${path}/view/:id`}
        render={(props) => <SeFileRegistration type="create" {...props} />}
      />
      <Route
        path={`${path}/return/:id`}
        render={(props) => <SeFileRegistration type="return" {...props} />}
      />
      <Route
        path={`${path}/view-similar/:id`}
        render={(props) => <SeFileView type="similar" {...props} />}
      />
      <Route
        path={`${path}/attribute/:id`}
        render={(props) => <LoanAttribute type="se" {...props} />}
      />
    </Switch>
  )
}

export default Se
