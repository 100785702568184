/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FC, useEffect } from 'react'
import { useTable, usePagination } from 'react-table'
import { customCellRender } from '../../utils/utilFunctions'
import './ReactTable.scss'

export interface ReactTableWithPaginationComponentProps {
  columns: any
  data: any
  onClickRow?: any
  hover?: boolean
}

const ReactTableWithPaginationComponent: FC<ReactTableWithPaginationComponentProps> =
  ({ columns, data, onClickRow, hover = false }) => {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page, // Instead of using 'rows', we'll use page,
      // which has only the rows for the active page

      // The rest of these things are super handy, too ;)
      canPreviousPage,
      canNextPage,
      pageOptions,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex },
    } = useTable(
      {
        columns,
        data,
        initialState: { pageIndex: 0 },
      },
      usePagination
    )
    useEffect(() => {
      setPageSize(10)
    }, [])

    const renderPageNumbers = (pageCount: number) => {
      const pageList: number[] = []
      if (pageCount > 10) {
        pageList.push(1)
        pageList.push(2)
        pageList.push(3)
        pageList.push(-1)
        pageList.push(pageCount - 2)
        pageList.push(pageCount - 1)
        pageList.push(pageCount)
      } else {
        for (let i = 0; i < pageCount; i++) {
          pageList.push(i + 1)
        }
      }

      return pageList.map((page) => {
        return (
          <>
            {page !== -1 && (
              <button
                type="button"
                className={`table-page-button ${
                  pageIndex == page - 1 ? 'active-page' : ''
                }`}
                key={`page-${page}`}
                onClick={() => gotoPage(page - 1)}
              >
                {`${page}`}
              </button>
            )}
            {page === -1 && <span className="pagination-elipses">...</span>}
          </>
        )
      })
    }

    return (
      <div className="table-wrapper">
        <table
          className={
            !hover
              ? 'table table-bordered'
              : 'table table-bordered table-hover cursor-point'
          }
          {...getTableProps()}
        >
          <thead className="custom-table-header">
            {headerGroups.map((headerGroup: any, index: number) => (
              <tr
                key={`header-${index}`}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column: any, index: number) => (
                  <th
                    key={`header-tr-${index}`}
                    {...column.getHeaderProps({
                      style: { width: column.width },
                    })}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="custom-table-body" {...getTableBodyProps()}>
            {page.map((row: any, index: number) => {
              prepareRow(row)
              return (
                <tr
                  onClick={() => onClickRow(row.original)}
                  key={`row-tr-${index}`}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell: any, index: number) => {
                    return (
                      <td
                        className={cell.column.className}
                        key={`col-td-${index}`}
                        {...cell.getCellProps()}
                      >
                        {customCellRender(cell)}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
        {pageOptions.length > 1 && (
          <div className="pagination">
            <button
              type="button"
              className={`table-nav-button ${canPreviousPage ? 'enable' : ''}`}
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              {'< Previous'}
            </button>{' '}
            {renderPageNumbers(pageOptions.length)}
            <button
              type="button"
              className={`table-nav-button ${canNextPage ? 'enable' : ''}`}
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              {'Next >'}
            </button>{' '}
            <div className="pagination-go-to-page">
              <span> Go to page </span>
              <input
                type="number"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0
                  gotoPage(page)
                }}
              />
            </div>
            {/* <span>
              <input
                type="number"
                className="form-control input-lg"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0
                  gotoPage(page)
                }}
              />
            </span> */}
          </div>
        )}
      </div>
    )
  }

export default ReactTableWithPaginationComponent
