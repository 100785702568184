import { Slide, Bounce, Zoom, Flip, toast, ToastPosition } from 'react-toastify'
import { ALERT_ERROR, ALERT_SUCCESS, ALERT_WARNING } from './constants'

export const showTotast = (
  notificationType: string,
  message: string,
  position: ToastPosition,
  displayTime: number,
  alertTransition: string
) => {
  console.log(notificationType)
  switch (notificationType) {
    // case 'Alert-Info':
    //   return (
    //     toast.info(message, {
    //       position: position,
    //       autoClose: displayTime * 1000,
    //       transition: selectTransition(alertTransition),
    //     })
    //   );
    case ALERT_SUCCESS:
      return toast.success(message, {
        position: position,
        autoClose: displayTime * 1000,
        transition: selectTransition(alertTransition),
      })
    case ALERT_WARNING:
      return toast.warning(message, {
        position: position,
        autoClose: displayTime * 1000,
        transition: selectTransition(alertTransition),
      })
    case ALERT_ERROR:
      return toast.error(message, {
        position: position,
        autoClose: displayTime * 1000,
        transition: selectTransition(alertTransition),
      })
    // case 'Alert-Default':
    //   return (
    //     toast(message, {
    //       position: position,
    //       autoClose: displayTime * 1000,
    //       transition: selectTransition(alertTransition),
    //     })
    //   );
    // case 'Alert-Dark':
    //   return (
    //     toast.dark(message, {
    //       position: position,
    //       autoClose: displayTime * 1000,
    //       transition: selectTransition(alertTransition),
    //     })
    //   );
    default:
      return null
  }
}

export const selectTransition = (alertTransition: string) => {
  switch (alertTransition) {
    case 'Slide':
      return Slide
    case 'Zoom':
      return Zoom
    case 'Flip':
      return Flip
    case 'Bounce':
      return Bounce
    default:
      return undefined
  }
}
