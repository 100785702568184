// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useState } from 'react'
import { Container, Form, Row, Col, InputGroup } from 'react-bootstrap'
import { useHistory } from 'react-router'
import { useAuth } from '../../providers/AuthProvider'
import emlem from '../../assets/images/login-emlem.png'
import user from '../../assets/images/user.svg'
import lock from '../../assets/images/lock.svg'
import './LoginComponent.scss'
import { officeTitle, officeSubTitle } from '../../utils/labels'
import { Link } from 'react-router-dom'
import { showTotast } from '../../utils/toast'
import {
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../utils/constants'

export interface LoginFormComponentProps {
  redirectUrl?: string
}

const LoginFormComponent: FC<LoginFormComponentProps> = ({
  redirectUrl = '/dashboard',
}) => {
  const { error, login } = useAuth()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  async function handleLogin() {
    setLoading(true)
    const { isAuthenticate, isFirstTime } = await login(username, password)
    setLoading(false)
    if (isAuthenticate && isFirstTime) {
      history.push('/changepassword')
    } else if (isAuthenticate) {
      history.push(redirectUrl)
    }
  }

  React.useEffect(() => {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    localStorage.removeItem('refreshToken')
  }, [])

  return (
    <Container className="login-form-layout">
      <Form
        onSubmit={(e) => {
          e.preventDefault()
          handleLogin()
        }}
        className="p-2"
      >
        <Row className="justify-content-center">
          <img src={emlem} alt="" className="login-emlem" />
        </Row>
        <Row>
          <Col sm={12}>
            <div className="login-heading-title">{officeTitle}</div>
            <div className="login-sub-heading-title">{officeSubTitle}</div>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <div className="login-header">{'LOGIN'}</div>
          </Col>
        </Row>
        <Form.Group className="mb-3" controlId="loginUserName">
          <Form.Label className="login-label">Username</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              required
              type="text"
              placeholder="Username"
              onChange={(e) => {
                setUsername(e.target.value)
              }}
            />
            <InputGroup.Text>
              <img src={user} alt="" />
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>
        <Form.Group className="mb-3" controlId="loginPassword">
          <Form.Label className="login-label">Password</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              autoComplete="off"
              required
              type="password"
              placeholder="Password"
              onChange={(e) => {
                setPassword(e.target.value)
              }}
            />
            <InputGroup.Text>
              <img src={lock} alt="" />
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>
        <Form.Group className="mb-3" controlId="loginUtil">
          <Row className="justify-content-end">
            <Link className="login-link" to="/forgot_password">
              Forgot Password?
            </Link>
          </Row>
        </Form.Group>
        <Form.Group className="mb-3" controlId="loginButton">
          <button
            disabled={loading}
            className={`${loading ? 'disable-login-btn' : 'login-btn'}`}
            type="submit"
          >
            {loading ? 'Login.....' : 'Login'}{' '}
          </button>
        </Form.Group>
      </Form>

      <Form.Group className="mb-3 p-2">
        <span className="mt-5">Log in to Psycho-Social Application</span>{' '}
        <a
          href="http://orims.reparations.gov.lk:82/main"
          target="_blank"
          rel="noreferrer"
        >
          click here
        </a>
      </Form.Group>
    </Container>
  )
}

export default LoginFormComponent
