import { FileRegistrationModel } from '../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { FilterModel } from '../../../models/utils/filterModel'
import apiService from '../../api/apiManager'

class RlpDataService {
  async apiFetchRlpList(filterData: FilterModel) {
    return apiService.apiPost(
      `/compensation-approval/compensation/rlp/view/list`,
      filterData
    )
  }

  async apiFetchRlpListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/compensation-approval/compensation/rlp/view/count`,
      filterData
    )
  }

  async apiFetchRlpReturnList(filterData: FilterModel) {
    return apiService.apiPost(
      `/compensation-approval/compensation/rlp/file-return/view/list`,
      filterData
    )
  }

  async apiFetchRlpReturnListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/compensation-approval/compensation/rlp/file-return/view/count`,
      filterData
    )
  }

  async apiApproveRlpFile(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/compensation-approval/compensation/rlp/${id}/approve`,
      { remark: remark }
    )
  }
  async apiRejectRlpFile(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/compensation-approval/compensation/rlp/${id}/reject`,
      { remark: remark }
    )
  }
  async apiReturnRlpFile(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/compensation-approval/compensation/rlp/${id}/return`,
      { remark: remark }
    )
  }
  async apiUpdateRlpFile(id: number, file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/compensation-approval/compensation/rlp/file-return/${id}`,
      file
    )
  }
  async apiGetReturnRlpFile(id: number) {
    return apiService.apiGet(
      `/compensation-approval/compensation/rlp/file-return/view/${id}`
    )
  }
  async apiGetRlpFile(id: number) {
    return apiService.apiGet(
      `/compensation-approval/compensation/rlp/view/${id}`
    )
  }
}

export default new RlpDataService()
