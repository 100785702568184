// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-explicit-any */
import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import metaDataService from '../../services/metaDataService'
import {
  fetchPermissionsLisFailure,
  fetchPermissionsLisSuccess,
} from './actions'
import { FETCH_PERMISSIONS_LIST_REQUEST } from './actionTypes'

/*
  Worker Saga: Fired on FETCH_PERMISSIONS_LIS_REQUEST action
*/
function* fetchPermissionsListSagaWorker() {
  const { data, errorMessage } = yield call(
    metaDataService.apiPermissionListFetch
  )
  if (data)
    yield put(
      fetchPermissionsLisSuccess({
        permissions: data,
      })
    )
  else
    yield put(
      fetchPermissionsLisFailure({
        error: errorMessage,
      })
    )
}

/*
  Starts worker saga on latest dispatched `FETCH_PERMISSIONS_LIST_REQUEST` action.
  Allows concurrent increments.
*/
function* fetchPermissionsListSaga() {
  yield all([
    takeLatest(FETCH_PERMISSIONS_LIST_REQUEST, fetchPermissionsListSagaWorker),
  ])
}

export default function* permissionsListSaga() {
  yield all([fork(fetchPermissionsListSaga)])
}
