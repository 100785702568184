import { FilterModel } from '../../../models/utils/filterModel'
import apiService from '../../api/apiManager'
class IncidentTypesServices {
  async apiGetAllIncidentTypesList() {
    return apiService.apiGet('/system-configuration/incident-type/list')
  }

  async apiFetchIncidentTypesList(filterData: FilterModel) {
    return apiService.apiPost(
      `/system-configuration/incident-type/list`,
      filterData
    )
  }

  async apiFetchIncidentTypesListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/system-configuration/incident-type/count`,
      filterData
    )
  }
  async apiGetIncidentTypesById(id: number) {
    return apiService.apiGet(`/system-configuration/incident-type/view/${id}`)
  }
  async apiGetIncidentTypeHistory(id: number) {
    return apiService.apiGet(
      `/system-configuration/incident-type/${id}/history`
    )
  }
  async apiAddIncidentType(incidentType: any) {
    return apiService.apiPost(
      '/system-configuration/incident-type/add',
      incidentType
    )
  }
  async apiActiveIncidentType(id: number) {
    return apiService.apiActiveInactive(
      `/system-configuration/incident-type/${id}/active`
    )
  }
  async apiInactiveIncidentType(id: number) {
    return apiService.apiActiveInactive(
      `/system-configuration/incident-type/${id}/inactive`
    )
  }
  async apiUpdateIncidentType(id: number | undefined, incidentType: any) {
    return apiService.apiPutCommon(
      `/system-configuration/incident-type/modify/${id}`,
      incidentType
    )
  }
  async apiGetPaymentTypeListByIncidentType(id: number) {
    return apiService.apiGet(
      `/system-configuration/incident-type/view/${id}/payment-type/list`
    )
  }
  async apiUpdatePaymentType(payment: any) {
    return apiService.apiPost(
      '/system-configuration/incident-type/add/payment-type',
      payment
    )
  }
}

export default new IncidentTypesServices()
