import produce from 'immer'
import React, { FC, useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import ReactDatePicker from 'react-datepicker'
import { useDispatch, useSelector } from 'react-redux'
import InvalidFeedback from '../../../../components/common/invalidFeedback/InvalidFeedback'
import LoadingComponent from '../../../../components/common/loading/LoadingComponent'
import PriceInputComponent from '../../../../components/common/priceInputComponent/PriceInputComponent'
import ReactTableWithPaginationComponent from '../../../../components/table/ReactTableWithPaginationComponent'
import { FileRegistrationModel } from '../../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { LoanRepaymentDetailsModel } from '../../../../models/applicationRegistration/loan/loanRepaymentDetails'
import { LoanRescheduleDetailsModel } from '../../../../models/applicationRegistration/loan/loanRescheduleDetails'
import { LoanRescheduleDetailsErrorModel } from '../../../../models/applicationRegistration/loan/loanRescheduleDetailsError'
import { ValidationObjectModel } from '../../../../models/common/validationObjectModel'
import { ResponseObject } from '../../../../services/api/apiManager'
import attributeService from '../../../../services/applicationRegistration/loan/attributeService'
import { changeLoadingRequest } from '../../../../store/app/actionTypes'
import { getUserSubModuleActions } from '../../../../store/user/selectors'
import {
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
  number,
} from '../../../../utils/constants'
import { validationError } from '../../../../utils/errorMessages'
import { rescheduleHistoryColumns } from '../../../../utils/metaData'
import { showTotast } from '../../../../utils/toast'
import {
  checkPermission,
  handleNotification,
  thousandSeperator,
  validateForm,
} from '../../../../utils/utilFunctions'

export interface RescheduleSectionProps {
  fileRegistrationObject: FileRegistrationModel
  onOk: any
  onUpdate: any
}

const RescheduleSection: FC<RescheduleSectionProps> = ({
  fileRegistrationObject,
  onOk,
  onUpdate,
}) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [rescheduleDetails, setRecheduleDetails] = useState(
    {} as LoanRescheduleDetailsModel
  )
  const [rescheduleDetailsList, setRecheduleDetailsList] = useState(
    [] as LoanRescheduleDetailsModel[]
  )
  const [repaymentDetailsList, setRepaymentDetailsList] = useState(
    [] as LoanRepaymentDetailsModel[]
  )
  const [errors, setErrors] = useState({} as LoanRescheduleDetailsErrorModel)
  const [historyList, setHistoryList] = useState([])
  useEffect(() => {
    async function getData() {
      const rescheduleData = await attributeService.apiFetchRescheduleDetails(
        fileRegistrationObject.id,
        fileRegistrationObject.scheme.toLowerCase()
      )
      if (rescheduleData.status === 200) {
        setRecheduleDetailsList(rescheduleData.data)
      }
      const repaymentDetailsData =
        await attributeService.apiFetchRepaymentDetails(
          fileRegistrationObject.id,
          fileRegistrationObject.scheme.toLowerCase()
        )
      if (repaymentDetailsData.status === 200) {
        setRepaymentDetailsList(repaymentDetailsData.data)
      }
      getHistoryData()
      setLoading(false)
    }
    getData()
  }, [])

  const getHistoryData = async () => {
    const historyData = await attributeService.apiFetchRescheduleHistoryDetails(
      fileRegistrationObject.id,
      fileRegistrationObject.scheme.toLowerCase()
    )
    if (historyData.status === 200) {
      setHistoryList(historyData.data)
    }
  }

  const attributeActionListSubModule = useSelector(
    getUserSubModuleActions(
      'application-registration',
      'loan',
      fileRegistrationObject.scheme.toLowerCase(),
      'attribute'
    )
  )

  const findErrors = () => {
    const newErrors = {} as LoanRescheduleDetailsErrorModel
    if (
      validateForm({
        type: 'number',
        value: rescheduleDetails.instalmentAmount,
        minValue: 1,
        maxLength: 9,
      } as ValidationObjectModel)
    ) {
      newErrors.instalmentAmountError = validateForm({
        type: 'number',
        value: rescheduleDetails.instalmentAmount,
        section: 'Installment Amount',
        maxLength: 9,
        minValue: 1,
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        type: 'number',
        value: rescheduleDetails.rescheduleAmount,
        minValue: 1,
        maxLength: 9,
      } as ValidationObjectModel)
    ) {
      newErrors.rescheduleAmountError = validateForm({
        type: 'number',
        value: rescheduleDetails.rescheduleAmount,
        section: 'Reschedule Amount',
        maxLength: 9,
        minValue: 1,
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        type: 'number',
        value: rescheduleDetails.reschedulePeriod,
        minValue: 1,
        maxLength: 9,
      } as ValidationObjectModel)
    ) {
      newErrors.reschedulePeriodError = validateForm({
        type: 'number',
        value: rescheduleDetails.reschedulePeriod,
        section: 'Reschedule Period',
        maxLength: 9,
        minValue: 1,
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        type: 'date',
        maxValue: new Date().getTime() / 1000,
        value: rescheduleDetails.rescheduleDate,
      } as ValidationObjectModel)
    ) {
      newErrors.rescheduleDateError = validateForm({
        type: 'date',
        maxValue: new Date().getTime() / 1000,
        value: rescheduleDetails.rescheduleDate,
        section: 'Reschedule Date',
      } as ValidationObjectModel)
    }
    return newErrors
  }

  const handleClear = () => {
    document.getElementById('file-reschedule-details-form').reset()

    const resetReschedule = {
      applicationFileId: fileRegistrationObject.id,
    } as LoanRescheduleDetailsModel

    const resetRescheduleData = produce(resetReschedule, (draft) => {
      draft.rescheduleAmount = ''
      draft.instalmentAmount = ''
      draft.reschedulePeriod = null
      draft.rescheduleDate = null
    })
    setRecheduleDetails(resetRescheduleData)
    setErrors({})
  }

  const handleAdd = async (action: string) => {
    console.log(action)
    const foundErrros = findErrors()
    if (Object.keys(foundErrros).length > 0) {
      showTotast(
        ALERT_WARNING,
        validationError,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setErrors(foundErrros)
    } else {
      dispatch(changeLoadingRequest())
      let rescheduleDataAdd = {} as ResponseObject
      if (action === 'add') {
        rescheduleDataAdd = await attributeService.apiAddRescheduleDetails(
          rescheduleDetails,
          fileRegistrationObject.id,
          fileRegistrationObject.scheme.toLowerCase()
        )
      } else {
        rescheduleDataAdd = await attributeService.apiModifyRescheduleDetail(
          rescheduleDetails,
          fileRegistrationObject.id,
          fileRegistrationObject.scheme.toLowerCase()
        )
      }
      handleNotification(
        rescheduleDataAdd,
        action === 'add'
          ? 'Reschedule Added Successfully'
          : 'Reschedule Updated Successfully'
      )
      if (rescheduleDataAdd.status == 200) {
        handleClear()
        const rescheduleData = await attributeService.apiFetchRescheduleDetails(
          fileRegistrationObject.id,
          fileRegistrationObject.scheme.toLowerCase()
        )
        if (rescheduleData.status === 200) {
          setRecheduleDetailsList(rescheduleData.data)
        }
        onUpdate()
        getHistoryData()
      }
      dispatch(changeLoadingRequest())
    }
  }

  const handleRemove = async (rescheduleId: number) => {
    dispatch(changeLoadingRequest())
    const rescheduleDeleteData =
      await attributeService.apiDeleteRescheduleDetail(
        rescheduleId,
        fileRegistrationObject.id,
        fileRegistrationObject.scheme.toLowerCase()
      )
    handleNotification(rescheduleDeleteData, 'Reschedule Removed Successfully')
    if (rescheduleDeleteData.status == 204) {
      handleClear()
      getHistoryData()
      const rescheduleData = await attributeService.apiFetchRescheduleDetails(
        fileRegistrationObject.id,
        fileRegistrationObject.scheme.toLowerCase()
      )
      if (rescheduleData.status === 200) {
        setRecheduleDetailsList(rescheduleData.data)
      }
    }
    dispatch(changeLoadingRequest())
  }

  const renderRescheduleDetailsTable = () => {
    return rescheduleDetailsList.map(
      (reschedule: LoanRescheduleDetailsModel) => {
        return (
          <tr key={`reschedule-${reschedule.id}`}>
            <td>{reschedule.reschedulePeriod}</td>
            <td>{thousandSeperator(reschedule.rescheduleAmount)}</td>
            <td>
              {new Date(reschedule.rescheduleDate * 1000).toLocaleDateString()}
            </td>
            <td>{thousandSeperator(reschedule.instalmentAmount)}</td>
            {reschedule.active &&
              checkActionEnable() &&
              checkPermission('modify', attributeActionListSubModule) &&
              fileRegistrationObject.status === 'Loan Rescheduled' && (
                <td>
                  <button
                    className="save-button custom-margin-right"
                    type="button"
                    onClick={() => {
                      setRecheduleDetails(reschedule)
                    }}
                  >
                    Edit
                  </button>
                  <button
                    className="save-button custom-margin-right"
                    type="button"
                    onClick={() => {
                      handleRemove(reschedule.id)
                    }}
                  >
                    Remove
                  </button>
                </td>
              )}
          </tr>
        )
      }
    )
  }

  const checkActionEnable = () => {
    const activeReschedule = rescheduleDetailsList.find(
      (element: LoanRescheduleDetailsModel) => element.active == true
    )
    if (activeReschedule) {
      const reschedulePaymentList = repaymentDetailsList.filter((rePayment) => {
        if (rePayment.loanRescheduleDetailsId === activeReschedule.id) {
          return rePayment
        }
      })

      if (reschedulePaymentList.length > 0) {
        return false
      }
      return true
    }
    return false
  }

  return (
    <>
      {loading ? (
        <div className="loading-layout">
          <LoadingComponent />
        </div>
      ) : (
        <>
          <Col sm={12} className="border-label mb-4">
            <div className="border-label-span">Reschedule Details</div>
            <Form id="file-reschedule-details-form">
              <Form.Group className="mb-3" controlId="formPeriod">
                <Form.Label>
                  Reschedule Period{' '}
                  <span className="required-field-astrix">*</span>
                </Form.Label>
                <Form.Control
                  isInvalid={!!errors.reschedulePeriodError}
                  type="text"
                  placeholder="Enter Reschedule Period"
                  value={rescheduleDetails.reschedulePeriod}
                  onBlur={() => {
                    const newErrors = produce(errors, (draft) => {
                      draft.reschedulePeriodError = validateForm({
                        type: number,
                        maxLength: 3,
                        value: rescheduleDetails.reschedulePeriod,
                        section: 'Reschedule Period',
                      } as ValidationObjectModel)
                    })
                    setErrors(newErrors)
                  }}
                  onChange={(e) => {
                    const numberValue =
                      e.target.value == '' ? '0' : e.target.value
                    const newFormValue = produce(
                      rescheduleDetails,
                      (draft: LoanRescheduleDetailsModel) => {
                        draft.reschedulePeriod = parseInt(numberValue)
                      }
                    )
                    setRecheduleDetails(newFormValue)
                  }}
                />
                <InvalidFeedback message={errors.reschedulePeriodError} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formRescheduleAmount">
                <Form.Label>
                  Reschedule Amount{' '}
                  <span className="required-field-astrix">*</span>
                </Form.Label>
                <PriceInputComponent
                  isInvalid={!!errors.rescheduleAmountError}
                  value={rescheduleDetails.rescheduleAmount}
                  placeholder="Reschedule Amount (Rs.)"
                  onChange={(price: string) => {
                    const newFormValue = produce(
                      rescheduleDetails,
                      (draft: LoanRescheduleDetailsModel) => {
                        draft.rescheduleAmount = parseFloat(price)
                      }
                    )
                    setRecheduleDetails(newFormValue)
                    const newErrors = produce(errors, (draft) => {
                      draft.rescheduleAmountError = validateForm({
                        type: 'number',
                        maxLength: 9,
                        minValue: 1,
                        value: parseFloat(price),
                        section: 'Reschedule Amount (Rs.)',
                      } as ValidationObjectModel)
                    })
                    setErrors(newErrors)
                  }}
                  onErrorHandle={(price: string) => {
                    if (!price) {
                      const newFormValue = produce(
                        rescheduleDetails,
                        (draft: LoanRescheduleDetailsModel) => {
                          draft.rescheduleAmount = 0
                        }
                      )
                      setRecheduleDetails(newFormValue)
                    } else {
                      const clearError = produce(errors, (draft) => {
                        draft.rescheduleAmountError = ''
                      })
                      setErrors(clearError)
                    }
                  }}
                />
                <InvalidFeedback message={errors.rescheduleAmountError} />
              </Form.Group>
              <Form.Group as={Col} controlId="formRescheduleDate">
                <Form.Label>
                  Reschedule Date{' '}
                  <span className="required-field-astrix">*</span>
                </Form.Label>
                <ReactDatePicker
                  required={true}
                  maxDate={new Date()}
                  className={
                    errors.rescheduleDateError
                      ? 'date-picker-validate'
                      : 'form-control'
                  }
                  placeholderText="Disbursment Date"
                  selected={
                    rescheduleDetails.rescheduleDate != null
                      ? new Date(rescheduleDetails.rescheduleDate * 1000)
                      : null
                  }
                  onChange={(date: Date) => {
                    const newFormValue = produce(
                      rescheduleDetails,
                      (draft: LoanRescheduleDetailsModel) => {
                        draft.rescheduleDate = date
                          ? date.getTime() / 1000
                          : null
                      }
                    )
                    setRecheduleDetails(newFormValue)
                    const clearError = produce(errors, (draft) => {
                      draft.rescheduleDateError = ''
                    })
                    setErrors(clearError)
                  }}
                />
                <InvalidFeedback message={errors.rescheduleDateError} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formInstallmentAmount">
                <Form.Label>
                  Installment Amount{' '}
                  <span className="required-field-astrix">*</span>
                </Form.Label>
                <PriceInputComponent
                  isInvalid={!!errors.instalmentAmountError}
                  value={rescheduleDetails.instalmentAmount}
                  placeholder="Installment Amount (Rs.)"
                  onChange={(price: string) => {
                    const newFormValue = produce(
                      rescheduleDetails,
                      (draft: LoanRescheduleDetailsModel) => {
                        draft.instalmentAmount = parseFloat(price)
                      }
                    )
                    setRecheduleDetails(newFormValue)
                    const newErrors = produce(errors, (draft) => {
                      draft.instalmentAmountError = validateForm({
                        type: 'number',
                        maxLength: 9,
                        minValue: 1,
                        value: parseFloat(price),
                        section: 'Installment Amount (Rs.)',
                      } as ValidationObjectModel)
                    })
                    setErrors(newErrors)
                  }}
                  onErrorHandle={(price: string) => {
                    if (!price) {
                      const newFormValue = produce(
                        rescheduleDetails,
                        (draft: LoanRescheduleDetailsModel) => {
                          draft.instalmentAmount = 0
                        }
                      )
                      setRecheduleDetails(newFormValue)
                    } else {
                      const clearError = produce(errors, (draft) => {
                        draft.instalmentAmountError = ''
                      })
                      setErrors(clearError)
                    }
                  }}
                />
                <InvalidFeedback message={errors.instalmentAmountError} />
              </Form.Group>
            </Form>
            <Col sm={12} className="d-flex justify-content-end mt-4">
              {checkPermission('modify', attributeActionListSubModule) &&
                fileRegistrationObject.status === 'Loan Rescheduled' &&
                rescheduleDetails.id != null && (
                  <button
                    className="save-button custom-margin-right"
                    onClick={() => {
                      handleAdd('update')
                    }}
                  >
                    Update
                  </button>
                )}
              {fileRegistrationObject.status != 'Loan Rescheduled' &&
                checkPermission('add', attributeActionListSubModule) &&
                rescheduleDetails.id == null && (
                  <button
                    className="save-button custom-margin-right"
                    onClick={() => {
                      handleAdd('add')
                    }}
                  >
                    Add
                  </button>
                )}
              {fileRegistrationObject.status != 'Loan Rescheduled' &&
                checkPermission('modify', attributeActionListSubModule) &&
                rescheduleDetails.id != null && (
                  <button
                    className="save-button custom-margin-right"
                    onClick={() => {
                      handleAdd('update')
                    }}
                  >
                    Update
                  </button>
                )}
              <button
                className="save-button custom-margin-right"
                onClick={handleClear}
              >
                Clear
              </button>
            </Col>
          </Col>
          {rescheduleDetailsList.length > 0 && (
            <Col sm={12} className="border-label mb-4">
              <div className="border-label-span">Reschedule List</div>
              <Row className="mt-3">
                <div className="table-wrapper">
                  <table className="table table-bordered">
                    <thead className="custom-table-header">
                      <tr>
                        <th>Reschedule Period</th>
                        <th>Reschedule Amount</th>
                        <th>Reschedule Date</th>
                        <th>Installment Amount</th>
                        {fileRegistrationObject.status === 'Loan Rescheduled' &&
                          checkPermission(
                            'modify',
                            attributeActionListSubModule
                          ) && <th>Action</th>}
                      </tr>
                    </thead>
                    <tbody className="custom-table-body">
                      {renderRescheduleDetailsTable()}
                    </tbody>
                  </table>
                </div>
              </Row>
            </Col>
          )}
          {historyList.length > 0 && (
            <Col sm={12} className="border-label mb-4">
              <div className="border-label-span">History</div>
              <Row className="mt-4">
                <ReactTableWithPaginationComponent
                  columns={rescheduleHistoryColumns}
                  data={historyList}
                  onClickRow={(row: any) => {
                    console.log(row)
                  }}
                />
              </Row>
            </Col>
          )}
          <Col sm={12} className="d-flex justify-content-end mt-4">
            <button className="cancel-button" onClick={onOk}>
              Ok
            </button>
          </Col>
        </>
      )}
    </>
  )
}

export default RescheduleSection
