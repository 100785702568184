import { PermissionModel } from '../../models/usermanagement/permissionModel'
import { UserModal } from '../../models/usermanagement/userModel'

export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST'
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE'
export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE'
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE'
export const CLEAR_USERS_REQUEST = 'CLEAR_USERS_REQUEST'
// export const FETCH_ROLES_REQUEST = 'FETCH_ROLES_REQUEST'
// export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS'
// export const FETCH_ROLES_FAILURE = 'FETCH_ROLES_FAILURE'
export const CLEAR_ROLES_REQUEST = 'CLEAR_ROLES_REQUEST'

export interface IUser {
  id?: number
  firstName: string
}

export interface IRole {
  allPermissions: boolean
  createdBy: string
  createdDateTime: number
  description: string
  id?: number
  name: string
  status: string
  modifiedBy: string
  modifiedDateTime: number
  roleId: number
  permissions: PermissionModel
}

export interface UserManagementState {
  pending: boolean
  users: UserModal[]
  roles: IRole[]
  error: string | null
}

export interface FetchUsersSuccessPayload {
  users: UserModal[]
}

export interface FetchUsersFailurePayload {
  error: string
}

export interface ClearUsersRequest {
  type: typeof CLEAR_USERS_REQUEST
}

export interface FetchUsersRequest {
  type: typeof FETCH_USERS_REQUEST
}

export type FetchUsersSuccess = {
  type: typeof FETCH_USERS_SUCCESS
  payload: FetchUsersSuccessPayload
}

export type FetchUsersFailure = {
  type: typeof FETCH_USERS_FAILURE
  payload: FetchUsersFailurePayload
}

export interface CreateUserSuccessPayload {
  user: IUser
}

export interface CreateUserFailurePayload {
  error: string
}

export interface CreateUserRequest {
  type: typeof CREATE_USER_REQUEST
  user: IUser
}

export type CreateUserSuccess = {
  type: typeof CREATE_USER_SUCCESS
  payload: CreateUserSuccessPayload
}

export type CreateUserFailure = {
  type: typeof CREATE_USER_FAILURE
  payload: CreateUserFailurePayload
}

export interface UpdateUserSuccessPayload {
  user: IUser
}

export interface UpdateUserFailurePayload {
  error: string
}

export interface UpdateUserRequest {
  type: typeof UPDATE_USER_REQUEST
  user: IUser
}

export type UpdateUserSuccess = {
  type: typeof UPDATE_USER_SUCCESS
  payload: UpdateUserSuccessPayload
}

export type UpdateUserFailure = {
  type: typeof UPDATE_USER_FAILURE
  payload: UpdateUserFailurePayload
}

export interface FetchRolesSuccessPayload {
  roles: IRole[]
}

export interface FetchURolesFailurePayload {
  error: string
}

// export interface FetchRolesRequest {
//   type: typeof FETCH_ROLES_REQUEST
// }

// export type FetchRolesSuccess = {
//   type: typeof FETCH_ROLES_SUCCESS
//   payload: FetchRolesSuccessPayload
// }

// export type FetchRolesFailure = {
//   type: typeof FETCH_ROLES_FAILURE
//   payload: FetchURolesFailurePayload
// }

// export interface ClearRolesRequest {
//   type: typeof CLEAR_ROLES_REQUEST
// }
export type UserManagementActions =
  | FetchUsersRequest
  | FetchUsersSuccess
  | FetchUsersFailure
  | CreateUserRequest
  | CreateUserSuccess
  | CreateUserFailure
  | UpdateUserRequest
  | UpdateUserSuccess
  | UpdateUserFailure
  | ClearUsersRequest
// | FetchRolesRequest
// | FetchRolesSuccess
// | FetchRolesFailure
// | ClearRolesRequest
