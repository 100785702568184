import React, { FC, useRef } from 'react'
import { Row } from 'react-bootstrap'
import { InstallmentModel } from '../../models/applicationRegistration/compensation/installmentModel'
import { FileRegistrationModel } from '../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { checkInstallmentDocumentEnable } from '../../utils/utilFunctions'

export interface InstallmentDocumentProps {
  fileRegistrationObject: FileRegistrationModel
  installment: InstallmentModel
  upload: any
  download: any
  isDisabled: boolean
}

const InstallmentDocument: FC<InstallmentDocumentProps> = ({
  fileRegistrationObject,
  installment,
  upload,
  download,
  isDisabled,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const inputFile = useRef(null)

  const handleUpload = (file: any) => {
    upload(file)
    inputFile.current.value = null
  }

  const getFileNameFromUri = (url: string) => {
    const splitList = url.split('/')
    if (splitList.length > 0) {
      return splitList[splitList.length - 1]
    }
    return 'file'
  }

  function getFileExtensionFromUrl(url: string): string {
    const parts = url.split('.')
    if (parts.length > 1) {
      return parts.pop()!.toLowerCase()
    }
    return ''
  }

  const handlePdf = async (file: any) => {
    const headers: any = {
      responseType: 'blob',
      'Content-Type': 'blob',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }
    fetch(file?.fileDownloadUri, { headers })
      .then((response) => {
        return response.blob()
      })
      .then((data) => {
        window.open(URL.createObjectURL(data))
      })
  }

  return (
    <>
      <Row className="justify-content-end">
        <div className="col-sm-4">
          {checkInstallmentDocumentEnable(
            fileRegistrationObject.status,
            fileRegistrationObject.modifyIndex
          ) && (
            <>
              <button
                type="button"
                disabled={isDisabled}
                className={isDisabled ? 'disable-button' : 'save-button'}
                onClick={() => {
                  inputFile.current.click()
                }}
              >
                {installment.fileDownloadUri != null ? 'Replace' : 'Upload'}
              </button>
            </>
          )}

          <input
            ref={inputFile}
            id={`installment-document-${installment.installmentNumber}`}
            type="file"
            style={{ display: 'none' }}
            onChange={(event) => {
              event.stopPropagation()
              event.preventDefault()
              handleUpload(event.target.files)
            }}
          />
        </div>
      </Row>
      <Row className="mt-3">
        <table className="table table-bordered">
          <thead className="custom-table-header">
            <tr>
              <th>File Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className="custom-table-body">
            {installment.fileDownloadUri != null && (
              <tr>
                <td>{getFileNameFromUri(installment.fileDownloadUri)}</td>
                <td>
                  {(getFileExtensionFromUrl(installment?.fileDownloadUri) ==
                    'png' ||
                    getFileExtensionFromUrl(installment?.fileDownloadUri) ==
                      'jpg' ||
                    getFileExtensionFromUrl(installment?.fileDownloadUri) ==
                      'jpeg' ||
                    getFileExtensionFromUrl(installment?.fileDownloadUri) ==
                      'pdf') && (
                    <button
                      className="save-button custom-margin-right"
                      onClick={() => {
                        handlePdf(installment)
                      }}
                    >
                      View
                    </button>
                  )}
                  <button
                    type="button"
                    className="save-button"
                    onClick={() => {
                      console.log(installment)
                      download(
                        installment.fileDownloadUri,
                        getFileNameFromUri(installment.fileDownloadUri)
                      )
                    }}
                  >
                    Download
                  </button>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Row>
    </>
  )
}

export default InstallmentDocument
