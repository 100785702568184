import apiService from './api/apiManager'

class UserDataService {
  /**
   * Login post function.
   * @param {Object} user user.
   * @param {string} username user username.
   * @param {string} password user password.
   * @param {string} newPassword user password.
   */
  async apiChangePassword(user: {
    username: string
    password: string
    newPassword: string
  }) {
    return apiService.apiPost('/my-profile/change-password', user)
  }

  async apiChangePasswordFirstTime(user: {
    username: string
    password: string
    newPassword: string
  }) {
    return apiService.apiPost('/meta-data/change-password', user)
  }
}

export default new UserDataService()
