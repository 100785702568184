import { Row, Col } from 'react-bootstrap'
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom'
import { AdvancedFilterModel } from '../../../../models/utils/filterModel'
import ViewChequeList from '../ViewChequeList'
import { defaultPageLimit } from '../../../../utils/constants'
import AdvancedSearchTable from '../../../../components/table/advancedSearchTable/AdvancedSearchTable'
import { Column } from '../../../../components/table/advancedSearchTable/AdvancedSearchTableUtil'

const initFilterOption: AdvancedFilterModel = {
  filterValue: null,
  limit: defaultPageLimit,
  page: 1,
  orderBy: 'created_date_time',
  order: 'DESC',
  statusList: null,
  createdDateFrom: null,
  createdDateTo: null,
  scheme: 'PI',
  programType: 'COMPENSATION',
  incidentTypeId: null,
  fileReceivedDate: null,
}

const PiApplication = () => {
  const { path } = useRouteMatch()
  const history = useHistory()

  const commonColumns: Column[] = [
    { header: 'Application Reference', accessor: 'referenceNumber' },
    { header: 'Incident Type', accessor: 'incidentType.incidentType' },
    { header: 'Scheme', accessor: 'scheme' },
    { header: 'Applicant Name', accessor: 'applicantName' },
    { header: 'Identification Type', accessor: 'identificationType' },
    { header: 'ID Number', accessor: 'idNumber' },
    { header: 'District', accessor: 'district.name' },
    { header: 'DS Division', accessor: 'dsDivision.name' },
    { header: 'File Received Date', accessor: 'fileReceivedDate' },
    { header: 'Created Date and Time', accessor: 'createdDateTime' },
    { header: 'Status', accessor: 'status' },
    {
      header: 'Action',
      accessor: 'id',
      // eslint-disable-next-line react/display-name
      render: (row: any): JSX.Element => (
        <button
          className="save-button"
          onClick={(action) => {
            action.preventDefault()
            action.stopPropagation()
            handleView(row)
          }}
        >
          Cheque Details
        </button>
      ),
    },
  ]

  const handleView = (original: any) => {
    history.push(`${path}/view-cheques/${original.id}`)
  }

  return (
    <>
      <Switch>
        <Route path={`${path}/`} exact>
          <Row className="title-layout">
            <Col className="d-flex justify-content-start">
              <span className="m-2">PI Applications</span>
            </Col>
          </Row>
          <Row className="incident-types-body-layout mt-4">
            <Col sm={12} className="mb-4">
              <AdvancedSearchTable
                initFilterOption={initFilterOption}
                columns={commonColumns}
                dataApiPath={'/cheque-payment/cheque-print/view/list'}
                countApiPath={'/cheque-payment/cheque-print/view/count'}
              />
            </Col>
          </Row>
        </Route>
        <Route
          path={`${path}/view-cheques/:id`}
          render={(props) => <ViewChequeList {...props} />}
        />
      </Switch>
    </>
  )
}

export default PiApplication
