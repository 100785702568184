import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { networkError } from '../../utils/constants' // Ensure this path is correct

const http = axios.create({
  baseURL: process.env.baseUrl, // Use uppercase for environment variables
  headers: {
    'Content-Type': 'application/json',
  },
})

export interface ResponseObject<T = any> {
  data?: T
  status?: number
  token?: string
  refreshToken?: string
  errorCode?: number
  errorMessage?: string
}

http.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  },
  (error: AxiosError) => {
    return Promise.reject(error)
  }
)

let isRefreshing = false
let subscribers: Array<(token: string) => void> = []

// Function to add subscribers to retry the request after the token is refreshed
function subscribeTokenRefresh(cb: (token: string) => void) {
  subscribers.push(cb)
}

// Function to notify subscribers with the new token
function onRefreshed(token: string) {
  subscribers.forEach((cb) => cb(token))
  subscribers = []
}

// Refresh token function
async function refreshToken() {
  const refreshToken = localStorage.getItem('refreshToken')
  const accessToken = localStorage.getItem('token')

  try {
    const response = await axios.post(
      process.env.baseUrl + 'auth/refresh-token',
      { refreshToken: refreshToken, accessToken: accessToken }
    )
    if (response.status == 200) {
      localStorage.setItem('token', response.data.accessToken)
      localStorage.setItem('refreshToken', response.data.refreshToken)
      return response.data.accessToken
    }
  } catch (error) {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    localStorage.setItem('refreshToken', 'expired')
  }

  // else {
  //   localStorage.removeItem('token')
  //   localStorage.removeItem('user')
  //   localStorage.setItem('refreshToken', 'expired')
  //   debugger
  // }
}

http.interceptors.response.use(
  (response: AxiosResponse) => {
    return response
  },
  async (error: AxiosError) => {
    const status = error.response?.status
    const errorData = error.response?.data

    if (status === 401) {
      if (errorData.errorCode == 9) {
        if (!isRefreshing) {
          isRefreshing = true
          try {
            const newToken = await refreshToken()
            onRefreshed(newToken)
            error.config.headers['Authorization'] = `Bearer ${newToken}`
            return axios(error.config)
          } catch (refreshError) {
            console.error('Refresh token failed:', refreshError)
            return Promise.reject(refreshError)
          } finally {
            isRefreshing = false
          }
        }

        // If the token is already being refreshed, return a promise that resolves when it’s done
        return new Promise((resolve) => {
          subscribeTokenRefresh((token: string) => {
            error.config.headers['Authorization'] = `Bearer ${token}`
            resolve(axios(error.config))
          })
        })
      } else {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        localStorage.setItem('refreshToken', 'expired')
      }
    }

    console.error(
      `Error ${status}: ${error.message} | URL: ${error.config?.url}`
    )
    return Promise.reject(error)
  }
)

const errorHandling = (error: any): ResponseObject => {
  const { response } = error
  const errorObject: ResponseObject = {}

  if (response) {
    if (response.status === 401) {
      errorObject.errorMessage =
        'You are not authorized to perform this operation'
      errorObject.data = {}
    } else if (response.status === 400) {
      errorObject.errorCode = response.data.errorCode || null
      errorObject.errorMessage = response.data.message || 'Bad request'
      errorObject.data = {}
    }
  } else {
    errorObject.status = 408
    errorObject.errorMessage = networkError
  }

  return errorObject
}

const responseHandling = <T>(response: AxiosResponse<T>): ResponseObject<T> => {
  return {
    data: response.data,
    status: response.status,
    token: response.headers['authorization'],
    refreshToken: response.headers['refreshtoken'],
  }
}

const apiService = {
  apiGet: async (url: string, config?: AxiosRequestConfig) => {
    try {
      const response = await http.get(url, config)
      return responseHandling(response)
    } catch (error) {
      return errorHandling(error)
    }
  },
  apiPost: async (url: string, body: any, config?: AxiosRequestConfig) => {
    try {
      const response = await http.post(url, body, config)
      return responseHandling(response)
    } catch (error) {
      return errorHandling(error)
    }
  },
  apiPut: async (
    url: string,
    id: number,
    body: any,
    config?: AxiosRequestConfig
  ) => {
    try {
      const response = await http.put(`${url}/${id}`, body, config)
      return responseHandling(response)
    } catch (error) {
      return errorHandling(error)
    }
  },
  apiPutCommon: async (url: string, body: any, config?: AxiosRequestConfig) => {
    try {
      const response = await http.put(url, body, config)
      return responseHandling(response)
    } catch (error) {
      return errorHandling(error)
    }
  },
  apiDelete: async (url: string, id: number, config?: AxiosRequestConfig) => {
    try {
      const response = await http.delete(`${url}/${id}`, config)
      return responseHandling(response)
    } catch (error) {
      return errorHandling(error)
    }
  },
  apiDeleteCommon: async (url: string, config?: AxiosRequestConfig) => {
    try {
      const response = await http.delete(url, config)
      return responseHandling(response)
    } catch (error) {
      return errorHandling(error)
    }
  },
  apiActiveInactive: async (url: string, config?: AxiosRequestConfig) => {
    try {
      const response = await http.put(url, config)
      return responseHandling(response)
    } catch (error) {
      return errorHandling(error)
    }
  },
  apiPutFormData: async (
    url: string,
    formData: FormData,
    config?: AxiosRequestConfig
  ) => {
    try {
      const response = await http.put(url, formData, config)
      return responseHandling(response)
    } catch (error) {
      return errorHandling(error)
    }
  },
  apiPostBinary: async (
    url: string,
    body: any,
    config?: AxiosRequestConfig
  ) => {
    try {
      const response = await http.post(url, body, config)
      return responseHandling(response)
    } catch (error) {
      return errorHandling(error)
    }
  },
  apiDownloadFile: async (url: string, config?: AxiosRequestConfig) => {
    try {
      const response = await http.get(url, { ...config, responseType: 'blob' })
      return responseHandling(response)
    } catch (error) {
      return errorHandling(error)
    }
  },
}

export default apiService
