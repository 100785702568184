import produce from 'immer'
import React, { FC } from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import ReactDatePicker from 'react-datepicker'
import { useDispatch, useSelector } from 'react-redux'
import { DashboardFilterModel } from '../../models/utils/dashboardFilterModel'
import { DashboardTimeFilterUpdateRequest } from '../../store/dashboard/actions'
import { getTimeFilters } from '../../store/dashboard/selectors'

export interface DashboardTimeFilterProps {
  onFilter: any
  onCancel: any
}

const DashboardTimeFilter: FC<DashboardTimeFilterProps> = ({
  onFilter,
  onCancel,
}) => {
  const timeFilters = useSelector(getTimeFilters)
  const dispatch = useDispatch()
  const onSubmit = () => {
    let indexNumber = 0
    const selected = timeFilters.filter(
      (filter: DashboardFilterModel, index: number) => {
        if (filter.selected) {
          indexNumber = index
          return filter
        }
      }
    )
    onFilter(selected, indexNumber)
  }

  const checkDisabled = () => {
    if (
      timeFilters[4].selected &&
      (timeFilters[4].value == null || timeFilters[4].to == null)
    ) {
      return true
    }
    return false
  }
  return (
    <div className="time-filter-container">
      <div className="notification-drawer-header">
        <button className="notification-close-button" onClick={onCancel}>
          X
        </button>
      </div>
      <div className="p-4">
        <Form
          onSubmit={(e) => {
            e.preventDefault()
            onSubmit()
          }}
        >
          <Form.Group as={Row} className="mb-3" controlId="formLast24">
            <Col sm="2" className="align-self-center">
              <Form.Check
                type="checkbox"
                checked={timeFilters[0].selected}
                onChange={(e) => {
                  const newFilter = produce(
                    timeFilters[0],
                    (draft: DashboardFilterModel) => {
                      draft.selected = e.target.checked
                    }
                  )
                  dispatch(
                    DashboardTimeFilterUpdateRequest({
                      filter: newFilter,
                      index: 0,
                    })
                  )
                }}
              />
            </Col>
            <Form.Label column sm="5">
              {`${timeFilters[0].label}`}
            </Form.Label>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formLast24">
            <Col sm="2" className="align-self-center">
              <Form.Check
                type="checkbox"
                checked={timeFilters[1].selected}
                onChange={(e) => {
                  const newFilter = produce(
                    timeFilters[1],
                    (draft: DashboardFilterModel) => {
                      draft.selected = e.target.checked
                    }
                  )
                  dispatch(
                    DashboardTimeFilterUpdateRequest({
                      filter: newFilter,
                      index: 1,
                    })
                  )
                }}
              />
            </Col>
            <Form.Label column sm="5">
              {`${timeFilters[1].label}`}
            </Form.Label>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formLast24">
            <Col sm="2" className="align-self-center">
              <Form.Check
                type="checkbox"
                checked={timeFilters[2].selected}
                onChange={(e) => {
                  const newFilter = produce(
                    timeFilters[2],
                    (draft: DashboardFilterModel) => {
                      draft.selected = e.target.checked
                    }
                  )
                  dispatch(
                    DashboardTimeFilterUpdateRequest({
                      filter: newFilter,
                      index: 2,
                    })
                  )
                }}
              />
            </Col>
            <Form.Label column sm="5">
              {`${timeFilters[2].label}`}
            </Form.Label>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formLast24">
            <Col sm="2" className="align-self-center">
              <Form.Check
                type="checkbox"
                checked={timeFilters[3].selected}
                onChange={(e) => {
                  const newFilter = produce(
                    timeFilters[3],
                    (draft: DashboardFilterModel) => {
                      draft.selected = e.target.checked
                    }
                  )
                  dispatch(
                    DashboardTimeFilterUpdateRequest({
                      filter: newFilter,
                      index: 3,
                    })
                  )
                }}
              />
            </Col>
            <Form.Label column sm="5">
              {`${timeFilters[3].label}`}
            </Form.Label>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formLast24">
            <Col sm="2" className="align-self-center">
              <Form.Check
                type="checkbox"
                checked={timeFilters[4].selected}
                onChange={(e) => {
                  const newFilter = produce(
                    timeFilters[4],
                    (draft: DashboardFilterModel) => {
                      draft.selected = e.target.checked
                    }
                  )
                  dispatch(
                    DashboardTimeFilterUpdateRequest({
                      filter: newFilter,
                      index: 4,
                    })
                  )
                }}
              />
            </Col>
            <Form.Label column sm="5">
              {`${timeFilters[4].label}`}
            </Form.Label>
          </Form.Group>
          {timeFilters[4].selected && (
            <Row className="mb-2">
              <Col sm="12" className="mb-1">
                <ReactDatePicker
                  placeholderText="From Date"
                  className="form-control"
                  selected={
                    timeFilters[4].value != null
                      ? new Date(timeFilters[4].value * 1000)
                      : null
                  }
                  onChange={(date: Date) => {
                    const newFilter = produce(
                      timeFilters[4],
                      (draft: DashboardFilterModel) => {
                        draft.value = date ? date.getTime() / 1000 : null
                        draft.to = null
                      }
                    )
                    dispatch(
                      DashboardTimeFilterUpdateRequest({
                        filter: newFilter,
                        index: 4,
                      })
                    )
                  }}
                />
              </Col>
              <Col sm="12">
                <ReactDatePicker
                  disabled={timeFilters[4].value == null}
                  minDate={
                    timeFilters[4].value != null
                      ? new Date(timeFilters[4].value * 1000)
                      : null
                  }
                  placeholderText="To Date"
                  className="form-control"
                  selected={
                    timeFilters[4].to != null
                      ? new Date(timeFilters[4].to * 1000)
                      : null
                  }
                  onChange={(date: Date) => {
                    const newFilter = produce(
                      timeFilters[4],
                      (draft: DashboardFilterModel) => {
                        draft.to = date ? date.getTime() / 1000 : null
                      }
                    )
                    dispatch(
                      DashboardTimeFilterUpdateRequest({
                        filter: newFilter,
                        index: 4,
                      })
                    )
                  }}
                />
              </Col>
            </Row>
          )}
          <button
            disabled={checkDisabled()}
            className={` ${checkDisabled() ? 'disable-button' : 'save-button'}`}
          >
            Filter
          </button>
        </Form>
      </div>
    </div>
  )
}

export default DashboardTimeFilter
