import React, { FC, useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { FileRegistrationModel } from '../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { HouseDetailsModel } from '../../models/applicationRegistration/loan/houseDetails'
import { ResponseObject } from '../../services/api/apiManager'
import common from '../../services/applicationRegistration/common'
import enquireApplicationService from '../../services/enquireApplication/enquireApplicationService'
import { currencyMask } from '../../utils/utilFunctions'
import LoadingComponent from '../common/loading/LoadingComponent'

export interface AffectedHouseSectionProps {
  fileRegistrationObject: FileRegistrationModel
  onOk: any
  packageName?: string
}

const AffectedHouseSection: FC<AffectedHouseSectionProps> = ({
  fileRegistrationObject,
  onOk,
  packageName = 'application-registration',
}) => {
  const [loading, setLoading] = useState(true)
  const [houseDetails, setHouseDetails] = useState({} as HouseDetailsModel)

  useEffect(() => {
    async function getMetaData() {
      let houseDetailsData = {} as ResponseObject
      if (packageName === 'enquire-application') {
        houseDetailsData =
          await enquireApplicationService.apiGetAffectedHouseDetails(
            fileRegistrationObject.id,
            fileRegistrationObject.scheme.toLowerCase()
          )
      } else {
        houseDetailsData = await common.apiGetAffectedHouseDetails(
          packageName,
          fileRegistrationObject.programType.toLowerCase(),
          fileRegistrationObject.scheme.toLowerCase(),
          fileRegistrationObject.id
        )
      }
      if (houseDetailsData.status === 200) {
        setHouseDetails(houseDetailsData.data)
      }
      setLoading(false)
    }
    getMetaData()
  }, [])

  return (
    <>
      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          <Col sm={12} className="border-label">
            <div className="border-label-span">
              Affected House Details and Other Loans
            </div>
            <Form id="file-family-details-form">
              <Col sm={12} className="border-label">
                <div className="border-label-span">Affected House Details</div>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formDamageDueToViolance">
                    <Form.Label>
                      Any property damaged due to violence activity *
                    </Form.Label>
                    <Row>
                      <Col>
                        <Form.Check
                          disabled
                          inline
                          name="formDamageDueToViolance"
                          label="Yes"
                          type="radio"
                          id={`formDamageDueToViolance-N`}
                          checked={
                            houseDetails.anyPropertyDamagedDueToViolenceActivity
                          }
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          disabled
                          inline
                          name="formDamageDueToViolance"
                          label="No"
                          type="radio"
                          id={`formDamageDueToViolance-O`}
                          checked={
                            !houseDetails.anyPropertyDamagedDueToViolenceActivity
                          }
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group className="mb-3" controlId="formAhAddress">
                    <Form.Label>Address of the Affected House</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      placeholder="Address of the Affected House"
                      value={houseDetails.addressOfTheAffectedHouse}
                    />
                  </Form.Group>
                </Row>
                <Form.Group className="mb-3" controlId="formIncidentDate">
                  <Form.Label>Incident Date</Form.Label>
                  <Form.Control
                    disabled
                    type="text"
                    placeholder="Incident Date"
                    value={
                      fileRegistrationObject.incidentDate != null
                        ? new Date(
                            fileRegistrationObject.incidentDate * 1000
                          ).toLocaleDateString()
                        : undefined
                    }
                  />
                </Form.Group>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formNatureOfDamage">
                    <Form.Label>Nature of the Damage</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      placeholder="Nature of the Damage"
                      value={houseDetails.natureOfTheDamage}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group
                    className="mb-3"
                    controlId="formRegistrationCardNumber"
                  >
                    <Form.Label>Family Registration Card Number</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      placeholder="Family Registration Card Number"
                      value={houseDetails.familyRegistrationCardNumber}
                    />
                  </Form.Group>
                </Row>
              </Col>
              <Col sm={12} className="border-label mt-4">
                <div className="border-label-span">Other Loan Details</div>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formHouseDonation">
                    <Form.Label>
                      Qualified for Consolidated subsidies Housing Donations
                    </Form.Label>
                    <Row>
                      <Col>
                        <Form.Check
                          inline
                          name="formHouseDonation"
                          label="Yes"
                          type="radio"
                          id={`formHouseDonation-N`}
                          checked={
                            houseDetails.qualifiedForConsolidatedSubsidiesHousingDonations
                          }
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          disabled
                          inline
                          name="formHouseDonation"
                          label="No"
                          type="radio"
                          id={`formHouseDonation-O`}
                          checked={
                            !houseDetails.qualifiedForConsolidatedSubsidiesHousingDonations
                          }
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group
                    className="mb-3"
                    controlId="formDeatilsofSubsidies"
                  >
                    <Form.Label>Details of any subsidies received</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      placeholder="Details of any subsidies received"
                      value={houseDetails.detailsOfAnySubsidiesReceived}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formRehabilitation">
                    <Form.Label>
                      Any rehabilitation loans taken previously for the same?
                    </Form.Label>
                    <Row>
                      <Col>
                        <Form.Check
                          disabled
                          inline
                          name="formRehabilitation"
                          label="Yes"
                          type="radio"
                          id={`formRehabilitation-N`}
                          checked={
                            houseDetails.anyRehabilitationLoansTakenPreviouslyForTheSame
                          }
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          disabled
                          inline
                          name="formRehabilitation"
                          label="No"
                          type="radio"
                          id={`formRehabilitation-O`}
                          checked={
                            !houseDetails.anyRehabilitationLoansTakenPreviouslyForTheSame
                          }
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Row>
                {houseDetails.anyRehabilitationLoansTakenPreviouslyForTheSame && (
                  <Row className="mb-3">
                    <Form.Group
                      className="mb-3"
                      controlId="formOutstandingAmount"
                    >
                      <Form.Label>Outstanding Amount (Rs.)</Form.Label>
                      <Form.Control
                        type="text"
                        disabled
                        placeholder="Outstanding Amount"
                        value={
                          houseDetails.outstandingAmount
                            ? currencyMask(houseDetails.outstandingAmount)
                            : ''
                        }
                      />
                    </Form.Group>
                  </Row>
                )}
              </Col>
              <Col sm={12} className="border-label mt-4 mb-3">
                <div className="border-label-span">{`Bank Details`}</div>
                <Row>
                  <Form.Group as={Col} controlId="formBankName">
                    <Form.Label>{`Bank Name`}</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      placeholder="Bank Name"
                      value={houseDetails.bank ? houseDetails.bank.name : ''}
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formBranchName">
                    <Form.Label>{`Branch Name`}</Form.Label>
                    <Form.Control
                      disabled
                      type="text"
                      placeholder="Branch Name"
                      value={
                        houseDetails.branch ? houseDetails.branch.name : ''
                      }
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formAccountNumber">
                    <Form.Label>Account Number</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      placeholder="Account Number"
                      value={houseDetails.accountNumber}
                    />
                  </Form.Group>
                </Row>
              </Col>
            </Form>
          </Col>
          <Col sm={12} className="d-flex justify-content-end mt-4">
            <button className="cancel-button" onClick={onOk}>
              Ok
            </button>
          </Col>
        </>
      )}
    </>
  )
}

export default AffectedHouseSection
