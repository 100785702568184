import { FilterModel } from '../../../models/utils/filterModel'
import apiService from '../../api/apiManager'

class ManageChequePaymentsService {
  async apiFetchFileList(filterData: FilterModel) {
    return apiService.apiPost(
      `/cheque-payment/manage-cheque-details/view/list`,
      filterData
    )
  }

  async apiFetchFileListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/cheque-payment/manage-cheque-details/view/count`,
      filterData
    )
  }
  async apiGetFileDetails(id: number) {
    return apiService.apiGet(`/cheque-payment/manage-cheque-details/view/${id}`)
  }
  async apiAddChequePaymentDetails(id: number, chequetDetails: any) {
    return apiService.apiPost(
      `/cheque-payment/manage-cheque-details/${id}/add/cheque-details`,
      chequetDetails
    )
  }
  async apiGetChequePaymentDetails(id: number) {
    return apiService.apiPost(
      `/cheque-payment/manage-cheque-details/${id}/view/cheque-details-list`,
      {}
    )
  }
  async apiGetDependents(id: number) {
    return apiService.apiGet(
      `/cheque-payment/manage-cheque-details/${id}/view/dependents`
    )
  }
  async apiApproveChequePayment(id: number) {
    return apiService.apiPutCommon(
      `/cheque-payment/manage-cheque-details/${id}/approve`,
      {}
    )
  }
  async apiReturnChequePayment(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/cheque-payment/manage-cheque-details/${id}/return`,
      { remark: remark }
    )
  }
  async apiFetchReturnFileList(filterData: FilterModel) {
    return apiService.apiPost(
      `/cheque-payment/cheque-print/file-return/view/list`,
      filterData
    )
  }

  async apiFetchReturnFileListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/cheque-payment/cheque-print/file-return/view/count`,
      filterData
    )
  }
  async apiModifyChequePaymentDetail(
    id: number,
    cheque_details_id: number,
    chequetDetails: any
  ) {
    return apiService.apiPutCommon(
      `/cheque-payment/manage-cheque-details/${id}/modify/cheque-details/${cheque_details_id}`,
      chequetDetails
    )
  }
  async apiModifyChequePrintReturnFile(id: number) {
    return apiService.apiPutCommon(
      `/cheque-payment/cheque-print/file-return/${id}/modify`,
      {}
    )
  }
}
export default new ManageChequePaymentsService()
