import React, { useState } from 'react'
import { Tab, Row, Nav } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useRouteMatch, Switch, Route } from 'react-router'
import {
  getUserModuleActions,
  getUserSubModuleActions,
} from '../../../../store/user/selectors'
import { checkPermission } from '../../../../utils/utilFunctions'
import GscApplication from './GscApplication'
import GscFileView from './GscFileView'
import GscReturn from './GscReturn'

const Gsc = () => {
  const { path } = useRouteMatch()

  const [selectedSection, setSelectedSection] = useState('gsc')

  const actionListModule = useSelector(
    getUserModuleActions('compensation-certification', 'compensation', 'gsc')
  )

  const actionListSubModule = useSelector(
    getUserSubModuleActions(
      'compensation-certification',
      'compensation',
      'gsc',
      'file-return'
    )
  )
  return (
    <Switch>
      <Route path={`${path}/`} exact>
        <Tab.Container id="left-tabs-example" defaultActiveKey="gsc">
          <Row className="tab-page-layout">
            <Nav variant="pills" className="flex-row">
              {checkPermission('view', actionListModule) && (
                <Nav.Item className="custom-nav-item">
                  <Nav.Link
                    onClick={() => {
                      setSelectedSection('gsc')
                    }}
                    eventKey="gsc"
                  >
                    GSC Application
                  </Nav.Link>
                </Nav.Item>
              )}
              {checkPermission('view', actionListSubModule) && (
                <Nav.Item className="custom-nav-item">
                  <Nav.Link
                    onClick={() => {
                      setSelectedSection('gsc-return')
                    }}
                    eventKey="gsc-return"
                  >
                    GSC Return
                  </Nav.Link>
                </Nav.Item>
              )}
            </Nav>
          </Row>
          <Row className="page-tiles-layout align-content-start">
            {selectedSection === 'gsc' ? (
              <>
                {checkPermission('view', actionListModule) && (
                  <GscApplication />
                )}
              </>
            ) : (
              <>
                {checkPermission('view', actionListSubModule) && <GscReturn />}
              </>
            )}
          </Row>
        </Tab.Container>
      </Route>
      <Route
        path={`${path}/view/:id`}
        render={(props) => <GscFileView type="view" {...props} />}
      />
      <Route
        path={`${path}/return/:id`}
        render={(props) => <GscFileView type="file-return" {...props} />}
      />
    </Switch>
  )
}

export default Gsc
