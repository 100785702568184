import { SchemeCodeModel } from '../../../models/systemConfiguration/incidentType/schemeCodeModel'
import { FileTypeListModel } from '../../../models/systemConfiguration/supportingDocuments/fileTypeListModel'
import * as errorMessages from '../../../utils/errorMessages'

export const validateDocumentNameEnglish = (value: string) => {
  if (!value) {
    return errorMessages.documentNameEmpty
  } else if (value.length > 400) {
    return errorMessages.documentNameLength
  }
}

export const validateDocumentNameSinhala = (value: string) => {
  if (!value) {
    return errorMessages.documentNameEmpty
  } else if (value.length > 400) {
    return errorMessages.documentNameLength
  }
}

export const validateDocumentNameTamil = (value: string) => {
  if (!value) {
    return errorMessages.documentNameEmpty
  } else if (value.length > 400) {
    return errorMessages.documentNameLength
  }
}

export const validateSchemeList = (schemeList: SchemeCodeModel[] | any) => {
  if (!schemeList || schemeList.length === 0) {
    return errorMessages.schemeCodeEmpty
  }
}
export const validatFileTypes = (fileTypeList: FileTypeListModel[] | any) => {
  if (!fileTypeList || fileTypeList.length === 0) {
    return errorMessages.fileTypeEmpty
  }
}
export const validateFileSize = (value: number) => {
  if (value <= 0) {
    return errorMessages.fileSizeNotValid
  } else if (!value) {
    return errorMessages.fileSizeEmpty
  }
}
export const validateMandatory = (value: boolean) => {
  if (value == null) {
    return errorMessages.checkboxEmpty
  }
}
export const validateMultipleAllowed = (value: boolean) => {
  if (value == null) {
    return errorMessages.checkboxEmpty
  }
}
