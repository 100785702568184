import {
  ClearPermissionsListRequest,
  DeselectAllActionsRequest,
  DESELECT_ALL_ACTIONS_REQUEST,
  FetchPermissionsListFailure,
  FetchPermissionsListFailurePayload,
  FetchPermissionsListRequest,
  FetchPermissionsListSuccess,
  FetchPermissionsListSuccessPayload,
  FETCH_PERMISSIONS_LIST_FAILURE,
  FETCH_PERMISSIONS_LIST_REQUEST,
  FETCH_PERMISSIONS_LIST_SUCCESS,
  ModuleActionDeselectRequest,
  ModuleActionDeselectRequestPayload,
  ModuleActionSelectRequest,
  ModuleActionSelectRequestPayload,
  MODULE_ACTION_DESELECT_REQUEST,
  MODULE_ACTION_SELECT_REQUEST,
  PackageActionDeselectRequest,
  PackageActionDeselectRequestPayload,
  PackageActionSelectRequest,
  PackageActionSelectRequestPayload,
  PACKAGE_ACTION_DESELECT_REQUEST,
  PACKAGE_ACTION_SELECT_REQUEST,
  PermissionsListResetPayload,
  PermissionsListResetRequest,
  PERMISSIONS_LIST_CLEAR_REQUEST,
  PERMISSIONS_LIST_RESET_REQUEST,
  SetModifyPermissionsListRequest,
  SET_MODIFY_PERMISSION_LIST_REQUEST,
  SubModuleActionDeselectRequest,
  SubModuleActionDeselectRequestPayload,
  SubModuleActionSelectRequest,
  SubModuleActionSelectRequestPayload,
  SUBMODULE_ACTION_DESELECT_REQUEST,
  SUBMODULE_ACTION_SELECT_REQUEST,
  SubPackageActionDeselectRequest,
  SubPackageActionDeselectRequestPayload,
  SubPackageActionSelectRequest,
  SubPackageActionSelectRequestPayload,
  SUBPACKAGE_ACTION_DESELECT_REQUEST,
  SUBPACKAGE_ACTION_SELECT_REQUEST,
} from './actionTypes'

export const PermissionListRestRequest = (
  payload: PermissionsListResetPayload
): PermissionsListResetRequest => ({
  type: PERMISSIONS_LIST_RESET_REQUEST,
  payload,
})

export const clearPermissionsLisRequest = (): ClearPermissionsListRequest => ({
  type: PERMISSIONS_LIST_CLEAR_REQUEST,
})

export const deselectAllActionsRequest = (): DeselectAllActionsRequest => ({
  type: DESELECT_ALL_ACTIONS_REQUEST,
})

export const fetchPermissionsLisRequest = (): FetchPermissionsListRequest => ({
  type: FETCH_PERMISSIONS_LIST_REQUEST,
})

export const fetchPermissionsLisSuccess = (
  payload: FetchPermissionsListSuccessPayload
): FetchPermissionsListSuccess => ({
  type: FETCH_PERMISSIONS_LIST_SUCCESS,
  payload,
})

export const fetchPermissionsLisFailure = (
  payload: FetchPermissionsListFailurePayload
): FetchPermissionsListFailure => ({
  type: FETCH_PERMISSIONS_LIST_FAILURE,
  payload,
})

export const packageActionSelectRequest = (
  payload: PackageActionSelectRequestPayload
): PackageActionSelectRequest => ({
  type: PACKAGE_ACTION_SELECT_REQUEST,
  payload,
})

export const packageActionDeselectRequest = (
  payload: PackageActionDeselectRequestPayload
): PackageActionDeselectRequest => ({
  type: PACKAGE_ACTION_DESELECT_REQUEST,
  payload,
})

export const subPackageActionSelectRequest = (
  payload: SubPackageActionSelectRequestPayload
): SubPackageActionSelectRequest => ({
  type: SUBPACKAGE_ACTION_SELECT_REQUEST,
  payload,
})

export const subPackageActionDeselectRequest = (
  payload: SubPackageActionDeselectRequestPayload
): SubPackageActionDeselectRequest => ({
  type: SUBPACKAGE_ACTION_DESELECT_REQUEST,
  payload,
})

export const moduleActionSelectRequest = (
  payload: ModuleActionSelectRequestPayload
): ModuleActionSelectRequest => ({
  type: MODULE_ACTION_SELECT_REQUEST,
  payload,
})

export const moduleActionDeselectRequest = (
  payload: ModuleActionDeselectRequestPayload
): ModuleActionDeselectRequest => ({
  type: MODULE_ACTION_DESELECT_REQUEST,
  payload,
})
export const subModuleActionSelectRequest = (
  payload: SubModuleActionSelectRequestPayload
): SubModuleActionSelectRequest => ({
  type: SUBMODULE_ACTION_SELECT_REQUEST,
  payload,
})

export const subModuleActionDeselectRequest = (
  payload: SubModuleActionDeselectRequestPayload
): SubModuleActionDeselectRequest => ({
  type: SUBMODULE_ACTION_DESELECT_REQUEST,
  payload,
})

export const SetModifyPermissionListRequest = (
  payload: PermissionsListResetPayload
): SetModifyPermissionsListRequest => ({
  type: SET_MODIFY_PERMISSION_LIST_REQUEST,
  payload,
})
