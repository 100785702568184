import './AdministrativeArea.scss'
import { FC } from 'react'
import { Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import {
  dsDivisionSelectRequest,
  dsDivisionDeselectRequest,
} from '../../../store/administrativeArea/actions'

export interface DsDivisionProps {
  id: number
  name: string
  code?: string
  allSelected?: boolean | undefined
  districtId: number
  isDsSelected?: boolean
}

const DsDivision: FC<DsDivisionProps> = ({
  id,
  name,
  code,
  districtId,
  isDsSelected,
}) => {
  const dispatch = useDispatch()

  const onSelected = (value: boolean) => {
    if (value) {
      dispatch(dsDivisionSelectRequest(id, districtId))
    } else {
      dispatch(dsDivisionDeselectRequest(id, districtId))
    }
  }

  return (
    <div className="mt-2">
      <ul key={`gn-division-${id}-${code}`}>
        <Form.Check
          key={`gn-division-${id}-${code}-${name}`}
          label={name}
          name={name}
          className="custom-form-check"
          onChange={(e) => {
            onSelected(e.target.checked)
          }}
          onClick={(e) => {
            e.stopPropagation()
          }}
          checked={isDsSelected}
        />
      </ul>
    </div>
  )
}

export default DsDivision
