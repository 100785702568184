import * as errorMessages from '../../utils/errorMessages'

export const validateTitle = (title: string) => {
  if (!title) {
    return errorMessages.titleEmpty
  }
}
export const validateFirstName = (firstName: string) => {
  if (!firstName) {
    return errorMessages.firstNameEmpty
  }
}
export const validateLastName = (lastName: string) => {
  if (!lastName) {
    return errorMessages.lastNameEmpty
  }
}

export const validateMobile = (mobile: string) => {
  if (!mobile) {
    return errorMessages.mobileNumberEmpty
  } else if (!/\d{10}/g.test(mobile)) {
    return errorMessages.mobileNumberInvalid
  }
}

export const validateEmail = (email: string) => {
  if (!email) {
    return errorMessages.emailAdressEmpty
  } else if (!/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email)) {
    return errorMessages.emailAdressInvalid
  }
}
