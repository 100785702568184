import React, { useEffect, useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import LoadingComponent from '../../../components/common/loading/LoadingComponent'
import SchemeService from '../../../services/systemConfiguration/schemes/schemeService'
import { SchemeModel } from '../../../models/systemConfiguration/schemes/schemeModel'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import produce from 'immer'
import ConfirmationModal from '../../../components/modals/ConfirmationModal'
import {
  ALERT_SUCCESS,
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../../utils/constants'
import { schemeModify } from '../../../utils/successMessages'
import { showTotast } from '../../../utils/toast'
import {
  schemeDescriptionEmpty,
  validationError,
} from '../../../utils/errorMessages'
import metaDataService from '../../../services/metaDataService'
interface ModifySchemeModel {
  description: string
  communityType: string
  schemeCategory: string
  endDate: number | null
}
interface ModifySchemeErrorModel {
  descriptionError: string
}
type TParams = { code: string }
const SchemesModify = ({ match }: RouteComponentProps<TParams>) => {
  const [loading, setloading] = useState(true)
  const [errors, setErrors] = useState({} as ModifySchemeErrorModel)
  const [scheme, setScheme] = useState({} as SchemeModel)
  const [showConfirmation, seShowConfirmation] = useState(false)
  const [modifySchemeObject, setModifySchemeObject] = useState(
    {} as ModifySchemeModel
  )
  // const [schemeCatagory, setSchemeCatagory] = useState([])
  const [communityType, setCommunityType] = useState([])
  const history = useHistory()

  useEffect(() => {
    async function getSchemesData() {
      const schemesData = await SchemeService.apiGetScheme(match.params.code)
      setScheme(schemesData.data)
      // const schemeCatagorydData = await metaDataService.apiGetSchemeCategory()
      // setSchemeCatagory(schemeCatagorydData.data)
      const communityTypeData = await metaDataService.apiGetCommunityType()
      setCommunityType(communityTypeData.data)
      setloading(false)
    }
    getSchemesData()
  }, [])

  const handleEndDtae = (date: string) => {
    if (date) {
      const newValue = produce(modifySchemeObject, (draft) => {
        draft.endDate = new Date(date).getTime() / 1000
      })
      setModifySchemeObject(newValue)
    } else if (!date) {
      const newValue2 = produce(modifySchemeObject, (draft) => {
        draft.endDate = null
      })
      setModifySchemeObject(newValue2)
    }
  }
  const validateDescription = (value: string) => {
    if (!value || value === '') {
      const newError = produce(errors, (draft) => {
        draft.descriptionError = schemeDescriptionEmpty
      })
      setErrors(newError)
    }
  }
  const findFormErrors = () => {
    const newErrors = {} as ModifySchemeErrorModel
    if (
      modifySchemeObject.description ||
      modifySchemeObject.description == ''
    ) {
      if (modifySchemeObject.description == '') {
        newErrors.descriptionError = schemeDescriptionEmpty
      }
    }
    return newErrors
  }
  const handleSubmit = () => {
    const foundErrros = findFormErrors()
    if (Object.keys(foundErrros).length > 0) {
      showTotast(
        ALERT_WARNING,
        validationError,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setErrors(foundErrros)
    } else {
      setErrors(foundErrros)
      seShowConfirmation(true)
    }
  }
  const handleConfirm = async () => {
    const result = await SchemeService.apiUpdateScheme(
      scheme.code,
      modifySchemeObject
    )
    let success = false
    let message = result.errorMessage ? result.errorMessage : ''
    if (result.status === 200) {
      success = true
      message = schemeModify
      history.goBack()
    }
    showTotast(
      success ? ALERT_SUCCESS : ALERT_WARNING,
      message,
      TOAST_POSITION_TOP_RIGHT,
      5,
      TOAST_TRANSITION_SLIDE
    )
  }
  return (
    <>
      {loading ? (
        <Container className="scheme-loading-layout">
          <LoadingComponent />
        </Container>
      ) : (
        <Row className="view-scheme-page-layout">
          <Col sm={12} className="border-label">
            <div className="border-label-span">Modify Schemes</div>
            <Form>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="programType">
                    <Form.Label>Program Type</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      value={scheme.programType}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="code">
                    <Form.Label>Scheme Code</Form.Label>
                    <Form.Control type="text" disabled value={scheme.code} />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="description">
                    <Form.Label>Scheme Description</Form.Label>
                    <Form.Control
                      isInvalid={!!errors.descriptionError}
                      type="text"
                      onBlur={(e) => {
                        validateDescription(e.target.value)
                      }}
                      onChange={(e) => {
                        const newValue = produce(
                          modifySchemeObject,
                          (draft) => {
                            draft.description = e.target.value
                          }
                        )
                        setModifySchemeObject(newValue)
                        const newError = produce(errors, (draft) => {
                          draft.descriptionError = ''
                        })
                        setErrors(newError)
                      }}
                      defaultValue={scheme.description}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.descriptionError}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="communityType">
                    <Form.Label>Community Type</Form.Label>
                    <Form.Control
                      as="select"
                      type="text"
                      className="form-select"
                      defaultValue={scheme.communityType}
                      onChange={(e) => {
                        const newValue = produce(
                          modifySchemeObject,
                          (draft) => {
                            draft.communityType = e.target.value
                          }
                        )
                        setModifySchemeObject(newValue)
                      }}
                    >
                      {communityType.map(
                        (comType: { name: string; id: number }) => {
                          return (
                            <option key={comType.id} value={comType.name}>
                              {comType.name}
                            </option>
                          )
                        }
                      )}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="schemeCategory">
                    <Form.Label>Scheme Category</Form.Label>
                    <Form.Control
                      disabled
                      type="text"
                      className="form-select"
                      value={scheme.schemeCategory}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="startDate">
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      value={new Date(
                        scheme.startDate * 1000
                      ).toLocaleDateString()}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="endDate">
                    <Form.Label>End Date</Form.Label>
                    <Form.Control
                      type="date"
                      defaultValue={
                        scheme.endDate != null
                          ? new Date(scheme.endDate * 1000)
                              .toISOString()
                              .substr(0, 10)
                          : ''
                      }
                      onChange={(e) => {
                        handleEndDtae(e.target.value)
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Col>
          <Row>
            <Col sm={12} className="d-flex justify-content-end mt-4">
              <button
                className="ok-button"
                onClick={(e: any) => {
                  e.preventDefault()
                  history.goBack()
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="modify-button"
                onClick={handleSubmit}
              >
                Save
              </button>
            </Col>
          </Row>
          {showConfirmation && (
            <ConfirmationModal
              name={'Confirmation'}
              message={'Are you sure to modify this scheme ?'}
              onCancel={() => {
                seShowConfirmation(false)
              }}
              onConfirm={handleConfirm}
            />
          )}
        </Row>
      )}
    </>
  )
}

export default SchemesModify
