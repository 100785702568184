/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable eslint-comments/no-duplicate-disable */
/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { BankDetailsModel } from '../../models/applicationRegistration/compensation/bankDetailsModel'
import { NextOfKinModel } from '../../models/applicationRegistration/compensation/nextOfKinModel'
import { FileRegistrationModel } from '../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { ResponseObject } from '../../services/api/apiManager'
import common from '../../services/applicationRegistration/common'
import enquireApplicationService from '../../services/enquireApplication/enquireApplicationService'
import LoadingComponent from '../common/loading/LoadingComponent'
import ReactTableWithPaginationComponent from '../table/ReactTableWithPaginationComponent'
export interface BankDetailsProps {
  fileRegistrationObject: FileRegistrationModel
  applicantName: string
  scheme: string
  onOk?: any
  packageName?: string
}
const SectionBankDetails: FC<BankDetailsProps> = ({
  fileRegistrationObject,
  onOk,
  applicantName,
  scheme,
  packageName = 'application-registration',
}) => {
  const [loading, setLoading] = useState(true)
  const [bankDetails, setBankDetails] = useState({} as BankDetailsModel)
  const [branchDetails, setBranchDetails] = useState({})
  const [nextOfKinDetails, setNextOfKinDetails] = useState({} as NextOfKinModel)
  const [trusteeBoardbankDetails, SetTrusteeBoardbankDetails] = useState(
    [] as BankDetailsModel[]
  )
  useEffect(() => {
    async function getData() {
      let result = {} as ResponseObject
      if (scheme === 'RLP') {
        if (packageName === 'enquire-application') {
          result = await enquireApplicationService.apiGetBoardOfTrustee(
            fileRegistrationObject.id,
            fileRegistrationObject.scheme.toLowerCase()
          )
        } else {
          result = await common.apiGetBoardOfTrustee(
            packageName,
            fileRegistrationObject.programType.toLowerCase(),
            fileRegistrationObject.scheme.toLowerCase(),
            fileRegistrationObject.id
          )
        }
        if (result.status === 200) {
          SetTrusteeBoardbankDetails(result.data)
        }
      } else {
        if (packageName === 'enquire-application') {
          result = await enquireApplicationService.apiGetBankData(
            fileRegistrationObject.id,
            fileRegistrationObject.scheme.toLowerCase()
          )
        } else {
          result = await common.apiGetBankData(
            packageName,
            fileRegistrationObject.programType.toLowerCase(),
            fileRegistrationObject.scheme.toLowerCase(),
            fileRegistrationObject.id
          )
        }

        if (result.status === 200) {
          setBankDetails(result.data)
          if (result.data.branch) {
            setBranchDetails(result.data.branch)
          }
        }
      }

      if (result.status === 200 && (scheme === 'GPC' || scheme === 'GSC')) {
        const nextOfKin = await common.apiGetNextOfKin(
          packageName,
          fileRegistrationObject.id,
          result.data.id,
          scheme.toLowerCase()
        )
        if (nextOfKin.status === 200) {
          setNextOfKinDetails(nextOfKin.data)
        }
      }
      setLoading(false)
    }

    getData()
  }, [])
  const columns = React.useMemo(
    () => [
      {
        Header: 'Name of the Board of Trustee',
        accessor: 'boardOfTrustee',
      },
      {
        Header: 'Name of Head Priest',
        accessor: 'headPriestName',
      },
      {
        Header: 'Bank Name',
        accessor: 'bank.name',
      },
      {
        Header: 'Branch Name',
        accessor: 'branch.name',
      },
      {
        Header: 'Branch Code',
        accessor: 'branch.code',
      },
      {
        Header: 'Account Number',
        accessor: 'accountNumber',
      },
      {
        Header: 'Created Date',
        accessor: 'createdDate',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: ' Board of Trustee Document',
        accessor: 'fileDownloadUri',
        Cell: (e: any) => {
          if (e.cell.value != '') {
            return (
              <button
                className="save-button"
                onClick={(action) => {
                  action.preventDefault()
                  action.stopPropagation()
                  handleDownload(e.row.original.fileDownloadUri)
                }}
              >
                View
              </button>
            )
          }
        },
      },
    ],
    []
  )
  const handleDownload = (url: string) => {
    const name = getFileNameFromUri(url)
    downloadUrl(url, name)
  }

  const downloadUrl = (url: string, name: string) => {
    const headers = {
      responseType: 'blob',
      'Content-Type': 'blob',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }
    fetch(url, { headers })
      .then((response) => {
        return response.blob()
      })
      .then((data) => {
        saveAs(data, name)
      })
  }

  const getFileNameFromUri = (url: string) => {
    const splitList = url.split('/')
    if (splitList.length > 0) {
      return splitList[splitList.length - 1].replace(/%/g, ' ')
    }
    return 'file'
  }
  return (
    <>
      {loading ? (
        <div className="loading-layout">
          <LoadingComponent />
        </div>
      ) : (
        <>
          <Col sm={12} className="border-label">
            <div className="border-label-span">Bank Details</div>
            {scheme === 'RLP' ? (
              <ReactTableWithPaginationComponent
                columns={columns}
                data={trusteeBoardbankDetails}
                onClickRow={(row: any) => {}}
              />
            ) : (
              <Form id="bankDetails">
                <Form.Group className="mb-5" controlId="applicantName">
                  <Form.Label>Applicant Name</Form.Label>
                  <Form.Control
                    placeholder="Applicant Name"
                    disabled={true}
                    value={applicantName}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="bankName">
                  <Form.Label>Bank Name</Form.Label>
                  <Form.Control
                    disabled={true}
                    placeholder="Bank Name"
                    value={
                      bankDetails.bank != null
                        ? bankDetails.bank.description
                        : ''
                    }
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="branchkName">
                  <Form.Label>Branch Name</Form.Label>
                  <Form.Control
                    disabled={true}
                    placeholder="Branch Name"
                    value={
                      bankDetails.branch != null
                        ? bankDetails.branch.description
                        : ''
                    }
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  as={Col}
                  controlId="formDpBankName"
                >
                  <Form.Label>{`Branch Code`} </Form.Label>
                  <Form.Control
                    disabled
                    type="text"
                    placeholder="Enter Branch Code"
                    value={branchDetails.code || ''}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="accountNumber">
                  <Form.Label>Account Number</Form.Label>
                  <Form.Control
                    disabled={true}
                    placeholder="Account Number"
                    value={bankDetails.accountNumber}
                  />
                </Form.Group>
              </Form>
            )}
          </Col>
          {nextOfKinDetails.id != null && (
            <Col sm={12} className="border-label mt-4">
              <div className="border-label-span">Next of Kin Details</div>
              <Row className="mb-3">
                <Form.Group controlId="formNameofNextofKin">
                  <Form.Label>Name of ‘Next of Kin’</Form.Label>
                  <Form.Control
                    disabled
                    type="text"
                    placeholder="Name of ‘Next of Kin"
                    value={nextOfKinDetails.name}
                  />
                </Form.Group>
                <Form.Group controlId="formPermanentAddress">
                  <Form.Label>Permanent Address</Form.Label>
                  <Form.Control
                    disabled
                    type="text"
                    placeholder="Permanent Address"
                    value={nextOfKinDetails.permanentAddress}
                  />
                </Form.Group>
                <Form.Group controlId="formNICNumber">
                  <Row>
                    <Col>
                      <Form.Label>Old NIC Number</Form.Label>
                      <Form.Control
                        disabled
                        type="text"
                        placeholder=">Old NIC Number"
                        value={nextOfKinDetails.oldNicNumber}
                      />
                    </Col>
                    <Col>
                      <Form.Label>New NIC Number</Form.Label>
                      <Form.Control
                        disabled
                        placeholder="New NIC Number"
                        type="text"
                        value={nextOfKinDetails.newNicNumber}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group controlId="formRelationship">
                  <Form.Label>Relationship to the Deceased person</Form.Label>
                  <Form.Control
                    disabled
                    type="text"
                    placeholder="Relationship to the Deceased person"
                    value={nextOfKinDetails.relationship}
                  />
                </Form.Group>
                <Row>
                  <Col>
                    <Form.Group controlId="formRelationship">
                      <Form.Label>Bank Name</Form.Label>
                      <Form.Control
                        disabled
                        type="text"
                        placeholder="Bank Name"
                        value={nextOfKinDetails.bank.name}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="formRelationship">
                      <Form.Label>Branch Name</Form.Label>
                      <Form.Control
                        disabled
                        type="text"
                        placeholder="Branch Name"
                        value={nextOfKinDetails.branch.name}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group controlId="formRelationship">
                  <Form.Label>Account Number</Form.Label>
                  <Form.Control
                    disabled
                    type="text"
                    placeholder="Account Number"
                    value={nextOfKinDetails.accountNumber}
                  />
                </Form.Group>
              </Row>
            </Col>
          )}
          <Col sm={12} className="d-flex justify-content-end mt-4">
            <button className="cancel-button" onClick={onOk}>
              Ok
            </button>
          </Col>
        </>
      )}
    </>
  )
}
export default SectionBankDetails
