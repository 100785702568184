import { DSDivisionModal } from '../../../models/administrativeArea/dsDivisionModal'
import { GuarantorDetailsModel } from '../../../models/applicationRegistration/loan/guarantorDetails'
import { RoleListModal } from '../../../models/user/roleListModel'
import * as errorMessages from '../../../utils/errorMessages'

export const validateTitle = (title: string) => {
  if (!title) {
    return errorMessages.titleEmpty
  }
}
export const validatefirstName = (firsName: string) => {
  if (!firsName) {
    return errorMessages.firstNameEmpty
  } else if (firsName.length > 50) {
    return errorMessages.firstNameLength
  }
}
export const validatelastName = (lastName: string) => {
  if (!lastName) {
    return errorMessages.lastNameEmpty
  } else if (lastName.length > 50) {
    return errorMessages.lastNameLength
  }
}
export const validateEpfNumber = (epfNumber: string) => {
  if (!epfNumber) {
    return errorMessages.epfNumberEmpty
  } else if (epfNumber.length > 50) {
    return errorMessages.epfNumberLength
  }
}
export const validateEmail = (email: string) => {
  // const regex = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/
  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (!email) {
    return errorMessages.emailAdressEmpty
  } else if (!regex.test(email)) {
    return errorMessages.emailAdressInvalid
  } else if (email.length > 50) {
    return errorMessages.emailAdressLength
  }
}
export const validateMobileNumber = (mobileNumber: string) => {
  const regex = /^(\+\d{1,3}[- ]?)?\d{10,15}$/
  if (!mobileNumber) {
    return errorMessages.mobileNumberEmpty
  } else if (!regex.test(mobileNumber)) {
    return errorMessages.mobileNumberInvalid
  }
}
export const validateOrganization = (organizationId: number) => {
  if (!organizationId) {
    return errorMessages.organizationEmpty
  }
}
export const validateDesignation = (designation: string) => {
  if (!designation) {
    return errorMessages.designationEmpty
  }
}
export const validateRoleList = (roleList: RoleListModal[] | any) => {
  if (!roleList || roleList.length === 0) {
    return errorMessages.rolesEmpty
  }
}
export const validateAdministrativeAreaList = (dsList: DSDivisionModal[]) => {
  if (dsList.length === 0) {
    return errorMessages.administrativeAreaListEmpty
  }
}

export const validatePayeeList = (payeeList: GuarantorDetailsModel[] | any) => {
  if (!payeeList || payeeList.length === 0) {
    return errorMessages.payeeEmpty
  }
}
