export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE'
export const USER_LOGOUT_REQUEST = 'USER_LOGOUT_REQUEST'

import { UserModal } from '../../models/userModel'

export interface UserState {
  pending: boolean
  user: UserModal
  error: string | null
}

export interface LoginUserSuccessPayload {
  user: UserModal
}

export interface LoginUserFailurePayload {
  error: string
}

export interface LoginUserRequest {
  type: typeof USER_LOGIN_REQUEST
}

export type LoginUserSuccess = {
  type: typeof USER_LOGIN_SUCCESS
  payload: LoginUserSuccessPayload
}

export type LoginUserFailure = {
  type: typeof USER_LOGIN_FAILURE
  payload: LoginUserFailurePayload
}

export interface LogoutUserRequest {
  type: typeof USER_LOGOUT_REQUEST
}

export type UserActions =
  | LoginUserRequest
  | LoginUserSuccess
  | LoginUserFailure
  | LogoutUserRequest
