import { PropertyFieldListModel } from '../../../models/systemConfiguration/propertyDamageType/propertyFieldListModel'
import * as errorMessages from '../../../utils/errorMessages'

export const validatePropertyName = (value: string) => {
  if (!value) {
    return errorMessages.propertyNameEmpty
  } else if (value.length > 75) {
    return errorMessages.propertyNameLength
  }
}
export const validatePropertyDescription = (value: string) => {
  if (!value) {
    return errorMessages.propertyDescriptionEmpty
  } else if (value.length > 75) {
    return errorMessages.propertyDescriptionLength
  }
}
export const validatePropertyCategory = (value: string) => {
  if (!value) {
    return errorMessages.propertyCategoryEmpty
  }
}
export const validateScheme = (value: string) => {
  if (!value) {
    return errorMessages.schemeEmpty
  }
}
export const validateApplicableValuesList = (
  ApplicableValues: PropertyFieldListModel[] | any
) => {
  if (!ApplicableValues || ApplicableValues.length === 0) {
    return errorMessages.applicablevaluesEmpty
  }
}
