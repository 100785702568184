import React, { useEffect, useState } from 'react'
import { Row, Col, Tab, Nav } from 'react-bootstrap'
import { useHistory } from 'react-router'
import LoadingComponent from '../../../../components/common/loading/LoadingComponent'
import ApplicationDataView from '../../../../components/common/view/ApplicationDataView'
import ReactTableWithPaginationComponent from '../../../../components/table/ReactTableWithPaginationComponent'
import { FileRegistrationModel } from '../../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import fileAllocationDataService from '../../../../services/applicationRegistration/fileAllocation'
import applicationFile from '../../../../services/applicationRegistration/applicationFiles'
import { fileRegistrationHistoryColumns } from '../../../../utils/metaData'
import AllocateModal from '../../../../components/modals/AllocateModal'
import { useSelector } from 'react-redux'
import {
  getUserModuleActions,
  getUserSubModuleActions,
} from '../../../../store/user/selectors'
import { ActionListModal } from '../../../../models/package/actionListModal'
import SimilarRecordTable from '../../../../components/common/similarRecordTable/SimilarRecordTable'
import common from '../../../../services/applicationRegistration/common'
const FIleAllocationView = (props: any) => {
  const {
    type,
    match: { params },
  } = props
  const [fileRegistrationObject, setFileRegistrationObject] = useState(
    {} as FileRegistrationModel
  )
  const [similarRecordList, setSimilarRecordList] = useState([])
  const [historyList, setHistoryList] = useState([])
  const [isOverAge, setIsOverAge] = useState(true)
  const [loading, setLoading] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const history = useHistory()
  const actionListModule = useSelector(
    getUserModuleActions(
      'application-registration',
      'file-registration',
      'file-allocation'
    )
  )
  const actionListSubModule = useSelector(
    getUserSubModuleActions(
      'application-registration',
      'file-registration',
      'file-allocation',
      'pending-file-allocation'
    )
  )
  useEffect(() => {
    console.log(type)
    async function getFileDetails() {
      const fileData =
        await fileAllocationDataService.apiFetchFileAllocationPendingView(
          parseInt(params.id)
        )
      const fileHistoryData =
        await applicationFile.apiFetchApplicationFilesHistory(
          parseInt(params.id)
        )
      if (fileHistoryData.status === 200) {
        setHistoryList(fileHistoryData.data)
      }
      const similarRecordData = await common.apiGetSimilarRecords(
        parseInt(params.id)
      )

      if (similarRecordData.status === 200) {
        setSimilarRecordList(similarRecordData.data)
      }
      setIsOverAge(true)
      setFileRegistrationObject(fileData.data)
      setLoading(false)
    }
    getFileDetails()
  }, [])

  const checkPermission = (permissionName: string) => {
    const filterdAction =
      type === 'pending'
        ? actionListSubModule.filter((action: ActionListModal) => {
            if (action.action.name === permissionName) {
              return action
            }
          })[0]
        : actionListModule.filter((action: ActionListModal) => {
            if (action.action.name === permissionName) {
              return action
            }
          })[0]
    if (filterdAction != null) {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      {loading ? (
        <div className="loading-layout">
          <LoadingComponent />
        </div>
      ) : (
        <>
          <Row className="title-layout">
            <Col className="d-flex justify-content-start">
              <span className="m-2">Application File</span>
            </Col>
          </Row>
          <Row className="create-role-page-layout">
            <Col sm={12} className="border-label">
              <div className="border-label-span">Application File View</div>
              <ApplicationDataView
                fileRegistrationObject={fileRegistrationObject}
                isOverAge={isOverAge}
              />
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="audit-history"
              >
                <Row className="tab-page-layout">
                  <Nav variant="pills" className="flex-row">
                    <Nav.Item className="custom-nav-item">
                      <Nav.Link eventKey="audit-history">
                        Audit History
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="custom-nav-item">
                      <Nav.Link eventKey="similar-records">
                        Similar Record
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Row>
                <Row>
                  <Tab.Content>
                    <Tab.Pane eventKey="audit-history">
                      <ReactTableWithPaginationComponent
                        columns={fileRegistrationHistoryColumns}
                        data={historyList}
                        onClickRow={(row: any) => {
                          console.log(row)
                        }}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="similar-records">
                      <SimilarRecordTable
                        similarRecordList={similarRecordList}
                      />
                    </Tab.Pane>
                  </Tab.Content>
                </Row>
              </Tab.Container>
            </Col>
            <Col sm={12} className="d-flex justify-content-end mt-4">
              {type === 'pending' ? (
                <>
                  {checkPermission('allocate') && (
                    <button
                      className="save-button"
                      onClick={() => {
                        setShowModal(true)
                      }}
                    >
                      Allocate
                    </button>
                  )}
                </>
              ) : (
                <>
                  {checkPermission('reallocate') && (
                    <button
                      className="save-button"
                      onClick={() => {
                        setShowModal(true)
                      }}
                    >
                      Re Allocate
                    </button>
                  )}
                </>
              )}
              <button
                className="cancel-button"
                onClick={() => {
                  history.goBack()
                }}
              >
                Ok
              </button>
            </Col>
          </Row>
        </>
      )}
      {showModal && (
        <AllocateModal
          name={type === 'pending' ? 'Allocate' : 'Re Allocate'}
          dsId={fileRegistrationObject.dsDivisionId}
          fileId={fileRegistrationObject.id}
          onSave={() => {
            history.goBack()
          }}
          onCancel={() => {
            setShowModal(false)
          }}
          scheme={fileRegistrationObject.scheme}
        />
      )}
    </>
  )
}

export default FIleAllocationView
