import { AffectedPropertyModel } from '../../../models/applicationRegistration/compensation/affectedPropertyModel'
import { BankDetailsModel } from '../../../models/applicationRegistration/compensation/bankDetailsModel'
import { FileRegistrationModel } from '../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { FilterModel } from '../../../models/utils/filterModel'
import apiService from '../../api/apiManager'

class RlpDataService {
  async apiFetchRlpList(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/compensation/rlp/view/list`,
      filterData
    )
  }

  async apiFetchRlpListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/compensation/rlp/view/count`,
      filterData
    )
  }

  async apiFetchRlpReturnList(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/compensation/rlp/file-return/view/list`,
      filterData
    )
  }

  async apiFetchRlpReturnListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/compensation/rlp/file-return/view/count`,
      filterData
    )
  }

  async apiFetchRlpFile(id: number) {
    return apiService.apiGet(
      `/application-registration/compensation/rlp/view/${id}`
    )
  }

  async apiRlpProceed(file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/rlp/add/proceed`,
      file
    )
  }

  async apiRlpModify(file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/rlp/file-return/modify/${file.id}`,
      file
    )
  }

  async apiRlpReject(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/rlp/${id}/reject`,
      { remark: remark }
    )
  }

  async apiRlpReturn(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/rlp/${id}/return`,
      { remark: remark }
    )
  }

  async apiRlpClose(id: number, remark: string, description: string) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/rlp/${id}/close`,
      { remark: remark, description: description }
    )
  }

  async apiRlpReopen(
    id: number,
    remark: string,
    description: string,
    allocateTo: string
  ) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/rlp/${id}/reopen`,
      { remark: remark, description: description, allocateTo: allocateTo }
    )
  }

  async apiRlpMerge(id: number, idList: any[]) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/rlp/add/${id}/merge`,
      idList
    )
  }

  async apiRlpAddApplicantDetails(file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/rlp/add/applicant`,
      file
    )
  }

  async apiGetRlpAffectedPropertyData(id: number) {
    return apiService.apiGet(
      `/application-registration/compensation/rlp/view/${id}/affected_property`
    )
  }

  async apiRlpAddAffectedPropertyDetails(
    file: AffectedPropertyModel,
    id: number
  ) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/rlp/add/${id}/affected_property`,
      file
    )
  }

  async apiRlpAddBankDetails(file: BankDetailsModel, id: number) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/rlp/add/${id}/bank`,
      file
    )
  }
  async apiRlpGetSupportingDocument(id: number) {
    return apiService.apiGet(
      `/application-registration/compensation/rlp/view/${id}/document`
    )
  }
  async apiGeRlpBoardOfTrusteeAndBankList(id: number) {
    return apiService.apiGet(
      `/application-registration/compensation/rlp/view/${id}/board_of_trustee_and_bank_list`
    )
  }
  async apiRlpAddSupportingDocument(formData: FormData, id: number) {
    return apiService.apiPutFormData(
      `/application-registration/compensation/rlp/add/${id}/document`,
      formData
    )
  }

  async apiRlpRegisterDocument(id: number) {
    return apiService.apiPutCommon(
      `/application-registration/compensation/rlp/add/${id}/register`,
      {}
    )
  }

  async apiRlpGetBoardOfTrustee(id: number) {
    return apiService.apiGet(
      `/application-registration/compensation/rlp/view/${id}/board_of_trustee_and_bank_list`
    )
  }

  async apiRlpAddBoardOfTrustee(formData: FormData, id: number) {
    return apiService.apiPutFormData(
      `/application-registration/compensation/rlp/add/${id}/board_of_trustee_and_bank`,
      formData
    )
  }
}

export default new RlpDataService()
