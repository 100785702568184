import React, { useState } from 'react'
import { Nav, Row, Tab } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import {
  getUserModuleActions,
  getUserSubModuleActions,
} from '../../../../store/user/selectors'
import { checkPermission } from '../../../../utils/utilFunctions'
import FileAllocationComponent from './FileAllocationComponent'
import FIleAllocationView from './FIleAllocationView'
import PendingFileAllocation from './PendingFileAllocation'

const FileAllocation = () => {
  const { path } = useRouteMatch()
  const [selectedSection, setSelectedSection] = useState('file-allocations')

  const actionListModule = useSelector(
    getUserModuleActions(
      'application-registration',
      'file-registration',
      'file-allocation'
    )
  )

  const actionListSubModule = useSelector(
    getUserSubModuleActions(
      'application-registration',
      'file-registration',
      'file-allocation',
      'pending-file-allocation'
    )
  )
  return (
    <Switch>
      <Route path={`${path}/`} exact>
        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey="file-allocations"
        >
          <Row className="tab-page-layout">
            <Nav variant="pills" className="flex-row">
              {checkPermission('view', actionListModule) && (
                <Nav.Item className="custom-nav-item">
                  <Nav.Link
                    onClick={() => {
                      setSelectedSection('file-allocations')
                    }}
                    eventKey="file-allocations"
                  >
                    File Allocations
                  </Nav.Link>
                </Nav.Item>
              )}
              {checkPermission('view', actionListSubModule) && (
                <Nav.Item className="custom-nav-item">
                  <Nav.Link
                    onClick={() => {
                      setSelectedSection('pending-file-allocations')
                    }}
                    eventKey="pending-file-allocations"
                  >
                    Pending File Allocations
                  </Nav.Link>
                </Nav.Item>
              )}
            </Nav>
          </Row>
          <Row className="page-tiles-layout align-content-start">
            {selectedSection === 'file-allocations' ? (
              <>
                {checkPermission('view', actionListModule) && (
                  <FileAllocationComponent />
                )}
              </>
            ) : (
              <>
                {checkPermission('view', actionListSubModule) && (
                  <PendingFileAllocation />
                )}
              </>
            )}
          </Row>
        </Tab.Container>
      </Route>
      <Route
        path={`${path}/view/:id`}
        render={(props) => <FIleAllocationView type="re-allocate" {...props} />}
      />
      <Route
        path={`${path}/pending-file-allocation/view/:id`}
        render={(props) => <FIleAllocationView type="pending" {...props} />}
      />
    </Switch>
  )
}

export default FileAllocation
