import React, { FC, useEffect, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import metaDataService from '../../services/metaDataService'
import './Modals.scss'

export interface ConfirmationModalProps {
  name: string
  message: string
  isRemark?: boolean
  onConfirm?: any
  onCancel?: React.MouseEventHandler<HTMLButtonElement>
  section?: string
  reasonType?: string
  isReason?: boolean
  remarkValue?: string
  loading?: boolean
}
export interface RequestObjectModel {
  reasonType: string
  section: string
  status: string
}

const ConfirmationModal: FC<ConfirmationModalProps> = ({
  name,
  message,
  isRemark = false,
  onConfirm,
  onCancel,
  section,
  reasonType,
  isReason = true,
  remarkValue,
}) => {
  const [remark, setRemark] = useState('')
  const [reasons, setReasons] = useState([])
  const [isProcessing, setIsProcessing] = useState(false)

  const requestObject = {} as RequestObjectModel

  useEffect(() => {
    if (remarkValue) {
      setRemark(remarkValue)
    }
    async function getData() {
      if (isRemark && reasonType && section && isReason) {
        requestObject.reasonType = reasonType
        requestObject.section = section
        requestObject.status = 'Active'
        const reasons = await metaDataService.apiGetRejectReturnReasons(
          requestObject
        )
        setReasons(reasons.data)
      }
    }
    getData()
  }, [])

  const handleConfirm = async () => {
    if (isProcessing) return
    setIsProcessing(true)
    try {
      await onConfirm(remark)
    } finally {
      setIsProcessing(false)
    }
  }

  return (
    <Modal
      size="lg"
      show={true}
      onHide={onCancel}
      aria-labelledby="example-modal-sizes-title-sm"
      dialogClassName="custom-modal-layout"
      contentClassName="custom-modal-content"
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        className="custom-modal-header"
      >
        <Modal.Title id="example-modal-sizes-title-sm">{name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isRemark ? (
          <Form id="remark-form">
            {message}
            {isReason && (
              <>
                <Form.Group className="mb-3" controlId="Reason">
                  <Form.Label>Reason</Form.Label>
                  <Form.Control
                    className="form-select"
                    as="select"
                    onChange={(e) => {
                      setRemark(e.target.value)
                    }}
                  >
                    {!remark ? <option value="">--Select Reason--</option> : ''}
                    {reasons?.map(
                      (reason: { reasonDescription: string; id: number }) => {
                        return (
                          <option
                            key={`reason-${reason.id}`}
                            value={reason.reasonDescription}
                          >
                            {reason.reasonDescription}
                          </option>
                        )
                      }
                    )}
                  </Form.Control>
                </Form.Group>
              </>
            )}
            <Form.Group className="mb-3" controlId="remark">
              <Form.Label>Remark</Form.Label>
              <Form.Control
                maxLength={50}
                type="text"
                placeholder="Enter Remark"
                value={remark}
                onChange={(e) => {
                  setRemark(e.target.value)
                }}
              />
            </Form.Group>
          </Form>
        ) : (
          <div>{message}</div>
        )}
        {/* {section === chequeCancel && (
          <Form id="cancel-cheque-form">
            <Form.Group className="mb-3" controlId="remark">
              <Form.Label>Reason</Form.Label>
              <Select
                placeholder="Select reason"
                options={reasonsForCancelCheque}
                onChange={(selectedOption: any) => {
                  setRemark(selectedOption.value)
                }}
              />
            </Form.Group>
          </Form>
        )} */}
        {/* {section === applicationReturnWithModifyIndex && (
          <Form id="cancel-cheque-form">
            <Form.Group className="mb-3" controlId="remark">
              <Form.Label>Level</Form.Label>
              <Select
                placeholder="Select reason"
                options={reasonsForCancelApplication}
                onChange={(selectedOption: any) => {
                  setRemark(selectedOption.value)
                }}
              />
            </Form.Group>
          </Form>
        )} */}
      </Modal.Body>
      <Modal.Footer>
        <button className="cancel-button" onClick={onCancel}>
          Cancel
        </button>
        {isRemark ? (
          <>
            {remark !== '' && (
              <button
                disabled={isProcessing}
                className="save-button"
                onClick={handleConfirm}
              >
                Ok
              </button>
            )}
          </>
        ) : (
          <button
            disabled={isProcessing}
            id="confirmation-ok"
            className="save-button"
            onClick={handleConfirm}
          >
            Ok
          </button>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmationModal
