import React, { useEffect, useState } from 'react'
import './IncidentTypes.scss'
import { Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import {
  useHistory,
  useRouteMatch,
  useLocation,
  Switch,
  Route,
} from 'react-router'
import ActionButtonComponent from '../../../components/common/actionButton/ActionButtonComponent'
import { ActionListModal } from '../../../models/package/actionListModal'
import { getUserSubPackageActions } from '../../../store/user/selectors'
import IncidentTypesServices from '../../../services/systemConfiguration/incidentTypes/incidentTypesServices'
import incident_type from '../../../assets/images/systemConfiguration/incident-type.svg'
import AddIncidentType from './AddIncidentType'
import ViewIncidentTypes from './ViewIncidentTypes'
import ModifyIncidentType from './ModifyIncidentType'
import { FilterModel } from '../../../models/utils/filterModel'
import IterminalTableComponent from '../../../components/table/IterminalTableComponent'
import { filtersSetRequest } from '../../../store/filter/actions'
import { getFilters } from '../../../store/filter/selectors'
import { incidentTypesFilters } from '../../../utils/metaData'
import { getPageCount } from '../../../utils/tableFunctions'
import produce from 'immer'
import IncidentTypesFilters from '../../../components/filters/IncidentTypesFilters'
import { FilterOptionModel } from '../../../models/utils/filterOption'
import PaymentType from './PaymentType'
import {
  defaultPageLimit,
  defaultOrderListLimit,
} from '../../../utils/constants'
const IncidentTypes = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [incidentTypesList, setIncidentTypesList] = useState([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const history = useHistory()
  const { path } = useRouteMatch()
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  const filterOption = {
    descending: true,
    limit: defaultPageLimit,
    orderFields: defaultOrderListLimit,
  } as FilterModel
  const [filter, setFilter] = useState(filterOption)
  const [pageCount, setPageCount] = useState(0)
  const [dataCount, setDatacount] = useState(0)
  const filters = useSelector(getFilters)
  const dispatch = useDispatch()

  const actionList = useSelector(
    getUserSubPackageActions('system-configuration', 'incident-type')
  )

  const checkPermission = (permissionName: string) => {
    const filterdAction = actionList.filter((action: ActionListModal) => {
      if (action.action.name === permissionName) {
        return action
      }
    })[0]
    if (filterdAction != null) {
      return true
    } else {
      return false
    }
  }
  useEffect(() => {
    dispatch(filtersSetRequest({ filters: incidentTypesFilters }))
    async function getIncidentTypesData() {
      await retriveDataCountAndData(filter)
      setLoading(false)
    }
    getIncidentTypesData()
  }, [location.pathname])

  async function retriveDataCountAndData(filterData: FilterModel) {
    const incidentDataCountData =
      await IncidentTypesServices.apiFetchIncidentTypesListCount(filterData)
    setDatacount(incidentDataCountData.data.count)
    setPageCount(
      getPageCount(incidentDataCountData.data.count, filterOption.limit)
    )
    const incidentData = await IncidentTypesServices.apiFetchIncidentTypesList(
      filterData
    )
    setIncidentTypesList(incidentData.data)
  }

  const retriveFilterData = async (selectedFilters: FilterOptionModel[]) => {
    console.log(selectedFilters)
    setLoading(true)
    const newFilter = produce(filter, (draft) => {
      draft.page = 1
      draft.filterData = selectedFilters
    })
    setFilter(newFilter)
    await retriveDataCountAndData(newFilter)
    setLoading(false)
  }

  const retriveIncidentData = async (pageDetails: any) => {
    setLoading(true)
    const newFilter = produce(filter, (draft) => {
      draft.page = pageDetails.pageIndex + 1
    })
    setFilter(newFilter)

    const incidentData = await IncidentTypesServices.apiFetchIncidentTypesList(
      newFilter
    )
    setIncidentTypesList(incidentData.data)
    setLoading(false)
  }
  const columns = React.useMemo(
    () => [
      {
        Header: 'Incident Category',
        accessor: 'incidentCategory',
      },
      {
        Header: 'Incident Type',
        accessor: 'incidentType',
      },
      {
        Header: 'Incident Description',
        accessor: 'description',
      },
      // {
      //   Header: 'Schemes',
      //   id: 'schemeList',
      //   accessor: (data: { schemeList: any[] }) => {
      //     const output: any[] = []
      //     data.schemeList.map((item) => {
      //       return output.push(item.schemeCode)
      //     })
      //     return output.join(', ')
      //   },
      // },
      {
        Header: 'Incident Date',
        accessor: 'effectiveDate',
      },
      {
        Header: 'Completion Date',
        accessor: 'endDate',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
    ],
    []
  )
  const handleView = (id: number) => {
    history.push(`${path}/view-incident-type/${id}`)
  }
  return (
    <>
      <Row className="title-layout">
        <Col className="d-flex justify-content-start">
          <img src={incident_type} alt="" />
          <span className="m-2">Incident Types</span>
        </Col>
      </Row>

      <Switch>
        <Route path={`${path}/`} exact>
          <Row className="incident-types-body-layout mt-4">
            <Col sm={12} className="mb-4">
              <Row>
                <Col sm={6}>
                  {checkPermission('view') === true && !loading && (
                    <IncidentTypesFilters
                      filterData={filters}
                      onFilter={(e: FilterOptionModel[]) => {
                        dispatch(filtersSetRequest({ filters: e }))
                        retriveFilterData(
                          e.filter(
                            (filter: FilterOptionModel) =>
                              filter.selected === true
                          )
                        )
                      }}
                    />
                  )}
                </Col>
                <Col sm={6} className="d-flex justify-content-end">
                  {checkPermission('add') === true && (
                    <ActionButtonComponent name="Add" routeURL="add" />
                  )}
                </Col>
              </Row>
            </Col>
            <Col sm={12}>
              <IterminalTableComponent
                columns={columns}
                data={incidentTypesList}
                fetchData={retriveIncidentData}
                loading={loading}
                pageCount={pageCount}
                totalCount={dataCount}
                onClickRow={(row: any) => {
                  console.log(row)
                  handleView(row.id)
                }}
              />
            </Col>
          </Row>
        </Route>
        <Route path={`${path}/add`} component={AddIncidentType} />
        {/* <Route path={`${path}/add`} component={PaymentType} /> */}
        <Route
          path={`${path}/view-incident-type/:id`}
          component={ViewIncidentTypes}
        />
        <Route path={`${path}/modify/:id`} component={ModifyIncidentType} />
        <Route
          path={`${path}/update-payment-type/:id`}
          component={PaymentType}
        />
      </Switch>
    </>
  )
}

export default IncidentTypes
