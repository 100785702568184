import { FileRegistrationModel } from '../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { FilterModel } from '../../../models/utils/filterModel'
import apiService from '../../api/apiManager'

class GpcDataService {
  async apiFetchGpcList(filterData: FilterModel) {
    return apiService.apiPost(
      `/compensation-certification/compensation/gpc/view/list`,
      filterData
    )
  }

  async apiFetchGpcListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/compensation-certification/compensation/gpc/view/count`,
      filterData
    )
  }

  async apiFetchGpcReturnList(filterData: FilterModel) {
    return apiService.apiPost(
      `/compensation-certification/compensation/gpc/file-return/view/list`,
      filterData
    )
  }

  async apiFetchGpcReturnListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/compensation-certification/compensation/gpc/file-return/view/count`,
      filterData
    )
  }

  async apiApproveGpcFile(id: number) {
    return apiService.apiPutCommon(
      `/compensation-certification/compensation/gpc/${id}/approve`,
      {}
    )
  }

  async apiRejectGpcFile(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/compensation-certification/compensation/gpc/${id}/reject`,
      { remark: remark }
    )
  }
  async apiReturnGpcFile(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/compensation-certification/compensation/gpc/${id}/return`,
      { remark: remark }
    )
  }
  async apiUpdateGpcFile(id: number, file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/compensation-certification/compensation/gpc/file-return/${id}`,
      file
    )
  }
  async apiGetReturnGpcFile(id: number) {
    return apiService.apiGet(
      `/compensation-certification/compensation/gpc/file-return/view/${id}`
    )
  }
  async apiGetGpcFile(id: number) {
    return apiService.apiGet(
      `/compensation-certification/compensation/gpc/view/${id}`
    )
  }
}

export default new GpcDataService()
