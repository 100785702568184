import { FilterModel } from '../../models/utils/filterModel'
import apiService from '../api/apiManager'
class EnquireApplicationService {
  async apiGetFiles(filterData: FilterModel) {
    return apiService.apiPost(`/enquire-application/view/list`, filterData)
  }
  async apiGetFilesCount(filterData: FilterModel) {
    return apiService.apiPost(`/enquire-application/view/count`, filterData)
  }
  async apiGetFile(id: number) {
    return apiService.apiGet(`/enquire-application/view/${id}`)
  }
  async apiGetAggrivedDetails(id: number, scheme: string) {
    return apiService.apiGet(
      `/enquire-application/view/${id}/${scheme}/aggrieved_person`
    )
  }
  async apiGetDependentDetails(id: number, scheme: string) {
    return apiService.apiGet(
      `/enquire-application/view/${id}/${scheme}/dependents`
    )
  }
  async apiGetSupportingDocument(id: number, scheme: string) {
    return apiService.apiGet(
      `/enquire-application/view/${id}/${scheme}/document`
    )
  }
  async apiGetAffectedPropertyData(id: number, scheme: string) {
    return apiService.apiGet(
      `/enquire-application/view/${id}/${scheme}/affected_property`
    )
  }
  async apiGetBankData(id: number, scheme: string) {
    return apiService.apiGet(`/enquire-application/view/${id}/${scheme}/bank`)
  }
  async apiGetBoardOfTrustee(id: number, scheme: string) {
    return apiService.apiGet(
      `/enquire-application/view/${id}/${scheme}/board_of_trustee_and_bank_list`
    )
  }
  async apiGetOthersDetails(id: number, scheme: string) {
    return apiService.apiGet(
      `/enquire-application/view/${id}/${scheme}/other-details`
    )
  }
  async apiGetFamilyDetails(id: number, scheme: string) {
    return apiService.apiGet(
      `/enquire-application/view/${id}/${scheme}/family-details`
    )
  }
  async apiGetAffectedHouseDetails(id: number, scheme: string) {
    return apiService.apiGet(
      `/enquire-application/view/${id}/${scheme}/affected-house-details`
    )
  }
  async apiSrtGetDependentAndOtherDetails(id: number, scheme: string) {
    return apiService.apiGet(
      `/enquire-application/view/${id}/${scheme}/dependent-and-other-details`
    )
  }

  async apiFetchGurantorDetails(fileId: number) {
    return apiService.apiGet(
      `/enquire-application/view/${fileId}/guarantor-details`
    )
  }

  async apiFetchDisbursementDetails(fileId: number) {
    return apiService.apiGet(
      `/enquire-application/view/${fileId}/disbursement-details`
    )
  }

  async apiFetchRepaymentDetails(fileId: number) {
    return apiService.apiGet(
      `/enquire-application/view/${fileId}/repayment-details`
    )
  }

  async apiFetchNPADetails(fileId: number) {
    return apiService.apiGet(`/enquire-application/view/${fileId}/npa-details`)
  }

  async apiFetchRescheduleDetails(fileId: number) {
    return apiService.apiGet(
      `/enquire-application/view/${fileId}/reschedule-details`
    )
  }

  async apiFetchGurantorHistoryDetails(fileId: number) {
    return apiService.apiGet(
      `/enquire-application/view/${fileId}/guarantor-details/history`
    )
  }

  async apiFetchDisbursementHistoryDetails(fileId: number) {
    return apiService.apiGet(
      `/enquire-application/view/${fileId}/disbursement-details/history`
    )
  }

  async apiFetchRepaymentHistoryDetails(fileId: number) {
    return apiService.apiGet(
      `/enquire-application/view/${fileId}/repayment-details/history`
    )
  }

  async apiFetchNPAHistoryDetails(fileId: number) {
    return apiService.apiGet(
      `/enquire-application/view/${fileId}/npa-details/history`
    )
  }

  async apiFetchRescheduleHistoryDetails(fileId: number) {
    return apiService.apiGet(
      `/enquire-application/view/${fileId}/reschedule-details/history`
    )
  }
}
export default new EnquireApplicationService()
