/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { SupportingDocumentDataModel } from '../../models/applicationRegistration/compensation/supportingDocument'
import { FileRegistrationModel } from '../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import LoadingComponent from '../common/loading/LoadingComponent'
import ReactTableWithPaginationComponent from '../table/ReactTableWithPaginationComponent'
import { saveAs } from 'file-saver'
import common from '../../services/applicationRegistration/common'
import enquireApplicationService from '../../services/enquireApplication/enquireApplicationService'
import { ResponseObject } from '../../services/api/apiManager'
import { InstallmentModel } from '../../models/applicationRegistration/compensation/installmentModel'
import metaDataService from '../../services/metaDataService'
import ImageViewModal from '../modals/ImageViewModal'
import { FileSupportingDocument } from '../../models/applicationRegistration/compensation/FileSupportingDocument'
import { SupportingDocumentsModel } from '../../models/systemConfiguration/supportingDocuments/supportingDocumentsModel'
import { ReminderModel } from '../../models/applicationRegistration/compensation/reminderModel'

export interface SectionSupportDocumentProps {
  fileRegistrationObject: FileRegistrationModel
  packageName?: string
  onOk: any
}

const SupportDocumentSection: FC<SectionSupportDocumentProps> = ({
  fileRegistrationObject,
  packageName = 'application-registration',
  onOk,
}) => {
  const [loading, setLoading] = useState(true)
  // const [supportingDocumentDetails, setSupportingDocumentDetails] = useState(
  //   {} as SupportingDocumentDataModel
  // )
  const [installmentList, setInstallmentList] = useState(
    [] as InstallmentModel[]
  )

  const [reminderList, setReminderList] = useState<ReminderModel[]>([])

  const [
    applicationFileSupportingDocumentList,
    setApplicationFileSupportingDocumentList,
  ] = useState<FileSupportingDocument[]>([])

  const [supportingDocumentList, setSupportingDocumentList] = useState<
    SupportingDocumentsModel[]
  >([])

  useEffect(() => {
    async function getData() {
      let supportingDocumentData = {} as ResponseObject
      if (packageName === 'enquire-application') {
        supportingDocumentData =
          await enquireApplicationService.apiGetSupportingDocument(
            fileRegistrationObject.id,
            fileRegistrationObject.scheme.toLowerCase()
          )
      } else {
        supportingDocumentData = await common.apiGetSupportingDocument(
          packageName,
          fileRegistrationObject.programType.toLowerCase(),
          fileRegistrationObject.scheme.toLowerCase(),
          fileRegistrationObject.id
        )
      }

      if (supportingDocumentData.status === 200) {
        setSupportingDocumentList(
          supportingDocumentData.data.supportingDocumentList
        )

        const array = [] as FileSupportingDocument[]
        for (
          let i = 0;
          i <
          supportingDocumentData.data.applicationFileSupportingDocumentList
            .length;
          i++
        ) {
          const element =
            supportingDocumentData.data.applicationFileSupportingDocumentList[i]
          const newObj = {} as FileSupportingDocument
          newObj.applicationFileId = element.applicationFileId
          newObj.fileDownloadUri = element.fileDownloadUri
          newObj.fileId = element.fileId
          newObj.fileName = element.fileName
          newObj.fileSize = element.fileSize
          newObj.fileType = element.fileType
          newObj.name = element.name
          newObj.supportingDocumentId = element.supportingDocumentId

          const x = supportingDocumentData.data.supportingDocumentList?.find(
            (s: any) => s.id == element.supportingDocumentId
          )

          newObj.supportingDocumentName = x?.documentNameEnglish

          array.push(newObj)
        }

        setApplicationFileSupportingDocumentList(array)

        setReminderList(supportingDocumentData.data.reminderList)
      }
      const installmentData =
        await metaDataService.apiInstallmentListToSupportDocument(
          fileRegistrationObject.id
        )
      if (installmentData.status === 200) {
        setInstallmentList(installmentData.data)
      }
      setLoading(false)
    }
    getData()
  }, [])

  const columns = React.useMemo(
    () => [
      {
        Header: 'Document',
        accessor: 'supportingDocumentName',
      },
      {
        Header: 'File Name',
        accessor: 'name',
      },
      // {
      //   Header: 'File Type',
      //   accessor: 'fileType',
      // },
      {
        Header: 'Action',
        accessor: 'fileDownloadUri',
        // Cell method will provide the cell value; we pass it to render a custom component
        Cell: (e: any) => {
          if (e.cell.value != '') {
            return (
              <>
                {(e?.row?.original?.fileType == 'image/jpeg' ||
                  e?.row?.original?.fileType == 'image/png' ||
                  e?.row?.original?.fileType == 'application/pdf') && (
                  <button
                    className="save-button custom-margin-right mt-1"
                    onClick={() => {
                      handlePdf(e?.row?.original)
                    }}
                  >
                    View
                  </button>
                )}
                <button
                  className="save-button mt-1"
                  onClick={(action) => {
                    action.preventDefault()
                    action.stopPropagation()
                    handleDownload(e.row.original)
                    console.log(e?.row?.original)
                  }}
                >
                  Download
                </button>
              </>
            )
          }
        },
      },
    ],
    []
  )
  const handleDownload = (file: any) => {
    const headers = {
      responseType: 'blob',
      'Content-Type': 'blob',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }
    fetch(`${process.env.baseUrl}download_file/${file?.fileName}`, { headers })
      .then((response) => {
        return response.blob()
      })
      .then((data) => {
        saveAs(data, file.name)
      })
  }
  const reminderColumns = React.useMemo(
    () => [
      {
        Header: 'Reminder',
        accessor: 'reminder',
      },
      {
        Header: 'Reminder Date',
        accessor: 'sentDate',
      },
      {
        Header: 'Remark',
        accessor: 'remarks',
      },
    ],
    []
  )

  const handlePdf = async (file: any) => {
    const headers: any = {
      responseType: 'blob',
      'Content-Type': 'blob',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }
    fetch(
      `${process.env.baseUrl}download_file/${getFileNameFromUri(
        file?.fileDownloadUri
      )}`,
      { headers }
    )
      .then((response) => {
        return response.blob()
      })
      .then((data) => {
        window.open(URL.createObjectURL(data))
      })
  }

  const getFileNameFromUri = (url: string) => {
    const splitList = url.split('/')
    if (splitList.length > 0) {
      return splitList[splitList.length - 1]
    }
    return 'file'
  }

  const handleDownloadInstallmentFile = async (url: string, name: string) => {
    const headers = {
      responseType: 'blob',
      'Content-Type': 'blob',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }
    fetch(`${process.env.baseUrl}download_file/${name}`, { headers })
      .then((response) => {
        return response.blob()
      })
      .then((data) => {
        saveAs(data, name)
      })
  }

  function getFileExtensionFromUrl(url: string): string {
    const parts = url.split('.')
    if (parts.length > 1) {
      return parts.pop()!.toLowerCase()
    }
    return ''
  }

  const renderInstallmentDocuments = () => {
    return installmentList.map(
      (installment: InstallmentModel, index: number) => {
        return (
          <Form.Group
            key={`installment-${installment.installmentNumber}-${index}`}
            className="mb-3"
            controlId="letter"
          >
            <Form.Label>Installment {installment.installmentNumber}</Form.Label>
            <Row className="mt-3">
              <table className="table table-bordered">
                <thead className="custom-table-header">
                  <tr>
                    <th>File Name</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="custom-table-body">
                  {installment.fileDownloadUri != null && (
                    <tr>
                      <td>{getFileNameFromUri(installment.fileDownloadUri)}</td>
                      <td>
                        {(getFileExtensionFromUrl(
                          installment?.fileDownloadUri
                        ) == 'png' ||
                          getFileExtensionFromUrl(
                            installment?.fileDownloadUri
                          ) == 'jpg' ||
                          getFileExtensionFromUrl(
                            installment?.fileDownloadUri
                          ) == 'jpeg' ||
                          getFileExtensionFromUrl(
                            installment?.fileDownloadUri
                          ) == 'pdf') && (
                          <button
                            className="save-button custom-margin-right"
                            onClick={() => {
                              handlePdf(installment)
                              // console.log(installment)
                            }}
                          >
                            View
                          </button>
                        )}
                        <button
                          type="button"
                          className="save-button"
                          onClick={() => {
                            handleDownloadInstallmentFile(
                              installment.fileDownloadUri,
                              getFileNameFromUri(installment.fileDownloadUri)
                            )
                          }}
                        >
                          Download
                        </button>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Row>
          </Form.Group>
        )
      }
    )
  }
  return (
    <>
      {loading ? (
        <div className="loading-layout">
          <LoadingComponent />
        </div>
      ) : (
        <>
          <Col sm={12} className="border-label">
            <div className="border-label-span">{`Support Documents Details`}</div>
            <ReactTableWithPaginationComponent
              columns={columns}
              data={applicationFileSupportingDocumentList}
              onClickRow={(row: any) => {}}
            />
          </Col>
          <Col sm={12} className="border-label mt-4">
            <div className="border-label-span">{`Reminders`}</div>
            <ReactTableWithPaginationComponent
              columns={reminderColumns}
              data={reminderList}
              onClickRow={(row: any) => {
                console.log(row)
              }}
            />
          </Col>
          <Col sm={12} className="border-label mt-4">
            <div className="border-label-span">{`Installment Documents`}</div>
            <div>{renderInstallmentDocuments()}</div>
          </Col>
          <Col sm={12} className="d-flex justify-content-end mt-4">
            <button className="cancel-button" onClick={onOk}>
              Ok
            </button>
          </Col>
        </>
      )}
    </>
  )
}

export default SupportDocumentSection
