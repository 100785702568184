import { useEffect, useState } from 'react'
import { Row, Col, Nav, Tab } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import LoadingComponent from '../../../../components/common/loading/LoadingComponent'
import ApplicationDataView from '../../../../components/common/view/ApplicationDataView'
import ConfirmationModal from '../../../../components/modals/ConfirmationModal'
import ReactTableWithPaginationComponent from '../../../../components/table/ReactTableWithPaginationComponent'
import { FileRegistrationModel } from '../../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import applicationFiles from '../../../../services/applicationRegistration/applicationFiles'
import applicationFile from '../../../../services/applicationRegistration/applicationFiles'
import { changeLoadingRequest } from '../../../../store/app/actionTypes'
import { fileRegistrationHistoryColumns } from '../../../../utils/metaData'
import { handleNotification } from '../../../../utils/utilFunctions'
import '../FileRegistration.scss'
import common from '../../../../services/applicationRegistration/common'
import SimilarRecordTable from '../../../../components/common/similarRecordTable/SimilarRecordTable'

interface ConfirmationModal {
  isShow: boolean
  message: string
  section: string
  isRemark: boolean
}

const ApplicationFileView = (props: any) => {
  const {
    type,
    match: { params },
  } = props
  const [fileRegistrationObject, setFileRegistrationObject] = useState(
    {} as FileRegistrationModel
  )
  const [similarRecordList, setSimilarRecordList] = useState([])
  const [historyList, setHistoryList] = useState([])
  const [isOverAge, setIsOverAge] = useState(true)
  const [loading, setLoading] = useState(true)
  const history = useHistory()
  const dispatch = useDispatch()
  const [showConfirm, setShowConfirm] = useState({
    isShow: false,
    isRemark: false,
  } as ConfirmationModal)
  useEffect(() => {
    console.log(type)
    async function getFileDetails() {
      const fileData = await applicationFile.apiFetchApplicationFilesView(
        parseInt(params.id)
      )
      const fileHistoryData =
        await applicationFile.apiFetchApplicationFilesHistory(
          parseInt(params.id)
        )
      if (fileHistoryData.status === 200) {
        setHistoryList(fileHistoryData.data)
      }

      const similarRecordData = await common.apiGetSimilarRecords(
        parseInt(params.id)
      )

      if (similarRecordData.status === 200) {
        setSimilarRecordList(similarRecordData.data)
      }

      setIsOverAge(true)
      setFileRegistrationObject(fileData.data)
      setLoading(false)
    }
    getFileDetails()
  }, [loading])

  const handleCancel = async (remark: string) => {
    dispatch(changeLoadingRequest())
    const result = await applicationFiles.apiFetchApplicationFilesReturnCancel(
      fileRegistrationObject.id,
      remark
    )
    dispatch(changeLoadingRequest())
    if (result.status === 200) {
      history.goBack()
    }
    handleNotification(result, 'Application Cancel Successfully')
  }

  const handleConfirm = (remark: string) => {
    setShowConfirm({ isShow: false, message: '', section: '', isRemark: false })
    if (showConfirm.section === 'cancel') {
      handleCancel(remark)
    }
  }

  return (
    <>
      {loading ? (
        <div className="loading-layout">
          <LoadingComponent />
        </div>
      ) : (
        <>
          <Row className="title-layout">
            <Col className="d-flex justify-content-start">
              <span className="m-2">Application File</span>
            </Col>
          </Row>
          <Row className="create-role-page-layout">
            <Col sm={12} className="border-label">
              <div className="border-label-span">Application File View</div>
              <ApplicationDataView
                fileRegistrationObject={fileRegistrationObject}
                isOverAge={isOverAge}
              />
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="audit-history"
              >
                <Row className="tab-page-layout">
                  <Nav variant="pills" className="flex-row">
                    <Nav.Item className="custom-nav-item">
                      <Nav.Link eventKey="audit-history">
                        Audit History
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="custom-nav-item">
                      <Nav.Link eventKey="similar-records">
                        Similar Record
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Row>
                <Row>
                  <Tab.Content>
                    <Tab.Pane eventKey="audit-history">
                      <ReactTableWithPaginationComponent
                        columns={fileRegistrationHistoryColumns}
                        data={historyList}
                        onClickRow={(row: any) => {
                          console.log(row)
                        }}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="similar-records">
                      <SimilarRecordTable
                        similarRecordList={similarRecordList}
                      />
                    </Tab.Pane>
                  </Tab.Content>
                </Row>
              </Tab.Container>
            </Col>
            <Col sm={12} className="d-flex justify-content-end mt-4">
              {type === 'file-return' && (
                <>
                  <button
                    className="save-button"
                    onClick={() => {
                      console.log('Modify')
                    }}
                  >
                    Modify
                  </button>
                  <button
                    className="save-button"
                    onClick={() => {
                      const message = 'Do you wish to Cancel application ?'
                      const section = 'cancel'
                      setShowConfirm({
                        isShow: true,
                        message: message,
                        section: section,
                        isRemark: true,
                      })
                    }}
                  >
                    Cancel
                  </button>
                </>
              )}
              <button
                className="cancel-button"
                onClick={() => {
                  history.goBack()
                }}
              >
                Ok
              </button>
            </Col>
          </Row>
        </>
      )}
      {showConfirm.isShow && (
        <ConfirmationModal
          name={'Confirmation'}
          message={showConfirm.message}
          onCancel={() => {
            setShowConfirm({
              isShow: false,
              message: '',
              section: '',
              isRemark: false,
            })
          }}
          isRemark={showConfirm.isRemark}
          onConfirm={(remark: string) => {
            handleConfirm(remark)
          }}
        />
      )}
    </>
  )
}

export default ApplicationFileView
