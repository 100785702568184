import produce from 'immer'
import {
  FilterActions,
  FilterState,
  REMOVE_FILTERS,
  RESET_FILTERS,
  SET_FILTERS,
} from './actionTypes'

const initialState: FilterState = {
  filters: [],
}

export default (state = initialState, action: FilterActions) => {
  switch (action.type) {
    case SET_FILTERS:
      return produce(state, (draft: FilterState) => {
        draft.filters = action.payload.filters
      })
    case RESET_FILTERS:
      return produce(state, (draft: FilterState) => {
        draft.filters = []
      })
    case REMOVE_FILTERS:
      return produce(state, (draft: FilterState) => {
        draft.filters = []
      })
    default:
      return {
        ...state,
      }
  }
}
