import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import BreadCrumb from '../../components/breadCrumb/BreadCrumb'
import HomeLoader from '../../components/common/homeLoader/HomeLoader'
import Header from '../../components/header/Header'
import LeftNavigation from '../../components/leftNavigation/LeftNavigation'
import LoadingModal from '../../components/modals/LoadingModal'
import {
  provinceFetchRequest,
  districtFetchRequest,
  dsFetchRequest,
  gnFetchRequest,
} from '../../store/administrativeArea/actions'
import { getApplicationLoadingState } from '../../store/app/selectors'
import './Home.scss'
import 'react-datepicker/dist/react-datepicker.css'
import { getInitialBreadCrumb } from '../../utils/utilFunctions'
import { breadCrumbListAddRequest } from '../../store/breadCrumb/actions'
import { useIdleTimer } from 'react-idle-timer'
import SessionTimeModal from '../../components/modals/SessionTimeModal'
import { getUser } from '../../store/user/selectors'

const HomeComponent = () => {
  const location = useLocation()
  const history = useHistory()
  const applicationLoadingState = useSelector(getApplicationLoadingState)
  const user = useSelector(getUser)
  const [sessionTimeModalShow, setSessionTimeModalShow] = useState(false)
  const dispatch = useDispatch()
  const handleOnIdle = () => {
    console.log('time remaining', getRemainingTime())
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    localStorage.removeItem('refreshToken')
    setSessionTimeModalShow(true)
  }

  const { getRemainingTime } = useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
    debounce: 500,
  })

  const handleCanel = () => {
    history.push('/login')
  }

  useEffect(() => {
    async function getMetaData() {
      const breadCrumbs = await getInitialBreadCrumb(location.pathname)
      dispatch(breadCrumbListAddRequest({ breadcrumbs: breadCrumbs }))
      dispatch(provinceFetchRequest())
      dispatch(districtFetchRequest())
      dispatch(dsFetchRequest())
      dispatch(gnFetchRequest())
    }
    getMetaData()
  }, [])
  return (
    <>
      <Container fluid className="home-layout">
        <Row className="page-layout">
          <Col xs={12} sm={12} md={2}>
            <LeftNavigation />
          </Col>
          <Col xs={12} sm={12} md={10}>
            <Row className="header-layout">
              <Header />
            </Row>
            <Row className="body-layout">
              <Col sm={12}>
                <Row className="podian-layout">
                  <BreadCrumb />
                </Row>
                <Row className="dynamic-page-layout">
                  <HomeLoader />
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      {applicationLoadingState && <LoadingModal />}
      {sessionTimeModalShow && (
        <SessionTimeModal
          username={user.epfNumber}
          onCancel={handleCanel}
          onLogin={() => {
            setSessionTimeModalShow(false)
          }}
        />
      )}
    </>
  )
}

export default HomeComponent
