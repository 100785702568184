/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useRef } from 'react'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import { Row, Col, Form, Table } from 'react-bootstrap'
import {
  programeTypes,
  ProgramOption,
  Schemeption,
} from '../../../../../utils/metaData'
import { FileRegistrationModel } from '../../../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import produce from 'immer'
import metaDataService from '../../../../../services/metaDataService'
import { ProvinceOption } from '../../../../../models/selectOptionModels/provinceOption'
import { DistrictOption } from '../../../../../models/selectOptionModels/districtOption'
import { DsOption } from '../../../../../models/selectOptionModels/dsOption'
import { dsSelectionModal } from '../../../../../models/applicationRegistration/fileRegistration/dsSelectionModel'
import {
  ALERT_SUCCESS,
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../../../../utils/constants'
import { showTotast } from '../../../../../utils/toast'
import fileRegistrationDataService from '../../../../../services/applicationRegistration/fileRegistration/fileRegistrationService'
import { IncidentTypeOption } from '../../../../../models/selectOptionModels/incidentTypeOption'
import { useSelector, useDispatch } from 'react-redux'
import {
  getProvincesOptions,
  geDistrictsOptions,
  getDsOptions,
  getGnOptions,
} from '../../../../../store/administrativeArea/selectors'
import { GnOption } from '../../../../../models/selectOptionModels/gnOption'
import { useHistory } from 'react-router'
import {
  calculateAge,
  checkNicMandatory,
  checkPermission,
  getAge,
  getAgeByIncidentDate,
  getOldAndNewNicFromGivenNic,
  handleNotification,
  nicFormatFormat,
  seAllocateUsersOptions,
} from '../../../../../utils/utilFunctions'
import DialogModal from '../../../../../components/modals/DialogModal'
import { AllocateToTypeOption } from '../../../../../models/selectOptionModels/allocateOption'
import { getUserSubModuleActions } from '../../../../../store/user/selectors'
import ApplicationDataView from '../../../../../components/common/view/ApplicationDataView'
import { ResponseObject } from '../../../../../services/api/apiManager'
import { FileRegistrationErrorModel } from '../../../../../models/applicationRegistration/fileRegistration/fileRegistrationErrorModel'
import * as validation from './validations'

import { changeLoadingRequest } from '../../../../../store/app/actionTypes'
import applicationFiles from '../../../../../services/applicationRegistration/applicationFiles'
import ConfirmationModal from '../../../../../components/modals/ConfirmationModal'
import SimlarRecordViewModal from '../../../../../components/modals/SimlarRecordViewModal'
import { SimilarRecordModel } from '../../../../../models/applicationRegistration/fileRegistration/similarRecordModel'
import IdentificationTypesComponent from '../../../../../components/common/identificationComponent/IdentificationTypesComponent'
import NicTypeSelectionComponent from '../../../../../components/common/identificationComponent/NicTypeSelectionComponent'
import { IncidentTypemodel } from '../../../../../models/systemConfiguration/incidentType/incidentTypemodel'

interface ConfirmationModal {
  isShow: boolean
  message: string
  section: string
  isRemark: boolean
}

const Add = (props: any) => {
  const {
    isDraft,
    isReturn,
    match: { params },
  } = props
  const [fileRegistrationObject, setFileRegistrationObject] = useState({
    newNic: true,
    aggrievedPersonNewNic: true,
  } as FileRegistrationModel)
  const [isProcessing, setIsProcessing] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isOverAge, setIsOverAge] = useState(true)
  const [isModify, setIsModify] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showSimilarModal, setShowSimilarModal] = useState(false)
  const inputForm = useRef(null)
  const [showConfirm, setShowConfirm] = useState({
    isShow: false,
    isRemark: false,
  } as ConfirmationModal)
  const [similarRecordList, setSimilarRecordList] = useState([])
  const [similarRecord, setSimilarRecord] = useState({} as SimilarRecordModel)
  const provinceList = useSelector(getProvincesOptions)
  const districtList = useSelector(geDistrictsOptions)
  const dsList = useSelector(getDsOptions)
  const gnList = useSelector(getGnOptions)
  const [incidentList, setIncidentList] = useState([] as IncidentTypeOption[])
  const [incidentDataList, setIncidentDataList] = useState(
    [] as IncidentTypemodel[]
  )
  const [userList, setUserList] = useState([] as AllocateToTypeOption[])
  const actionList = useSelector(
    getUserSubModuleActions(
      'application-registration',
      'file-registration',
      'file-registration',
      'allocate'
    )
  )

  const actionListFileReturn = useSelector(
    getUserSubModuleActions(
      'application-registration',
      'file-registration',
      'application-files',
      'file-return'
    )
  )
  const [selectedBirthCertificateDs, setSelectedBirthCertificateDs] = useState(
    {} as dsSelectionModal
  )
  const [selectedDeathCertificateDs, setSelectedDeathCertificateDs] = useState(
    {} as dsSelectionModal
  )
  const [selectedAgBirthCertificateDs, setSelectedAgBirthCertificateDs] =
    useState({} as dsSelectionModal)
  const [selectedInstrumentDs, setSelectedInstrumentDs] = useState(
    {} as dsSelectionModal
  )
  const [reset, setReset] = useState(false)
  const [searchState, setSearchState] = useState(false)
  const [schemesList, setSchemesList] = useState([] as Schemeption[])
  const [errors, setErrors] = useState({} as FileRegistrationErrorModel)
  const history = useHistory()
  const dispatch = useDispatch()
  useEffect(() => {
    console.log(isDraft)
    console.log(params)
    async function getMetaData() {
      console.log(gnList)
      const incidentTypeData = await metaDataService.apiIncidentTypeList()
      const incidentTypeTempList: IncidentTypeOption[] = []
      for (let i = 0; i < incidentTypeData.data.length; i++) {
        const incident = {} as IncidentTypeOption
        incident.value = incidentTypeData.data[i].id
        incident.label = incidentTypeData.data[i].incidentType
        incidentTypeTempList.push(incident)
      }
      setIncidentList(incidentTypeTempList)
      setIncidentDataList(incidentTypeData.data)
    }
    async function getDraftApplication() {
      const draftFileData =
        await fileRegistrationDataService.apiFetchFileRegistrationView(
          parseInt(params.id)
        )
      console.log(draftFileData)
      setFileRegistrationObject(draftFileData.data)
      const similarRecordData =
        await fileRegistrationDataService.apiFileRegistrationSearchSimilarRecordDrafted(
          draftFileData.data
        )
      if (similarRecordData.status === 200) {
        setSimilarRecordList(similarRecordData.data)
      }
    }
    getMetaData()
    if (isDraft) {
      getDraftApplication()
      setSearchState(true)
    }
    if (isReturn) {
      setSearchState(true)
    }
  }, [])

  const getSchemeListByProgrammeType = async (programeType: string) => {
    const selectedProgrameType =
      programeType === 'COMPENSATION' ? 'Compensation' : 'Loan'
    const response = await metaDataService.getSchemeListByProgramTypeAndStatus(
      selectedProgrameType,
      'Active'
    )
    if (response.status === 200) {
      const schemeList = [] as Schemeption[]
      for (let index = 0; index < response.data.length; index++) {
        const element = {} as Schemeption
        element.value = response.data[index].code
        element.label = response.data[index].description
        element.programeType = programeType
        schemeList.push(element)
      }
      setSchemesList(schemeList)
    }
  }

  const handleViewSimilarRecord = (record: any) => {
    if (fileRegistrationObject.status === 'File Drafted') {
      if (record.isFromReppia) {
        setSimilarRecord(record.reppiaApplicationDetails)
        setShowSimilarModal(true)
      } else {
        // history.push(
        //   `/application-registration/${record.programType.toLowerCase()}/${record.scheme.toLowerCase()}/view/${
        //     record.id
        //   }`
        // )
        // const win = window.open(
        //   `/application-registration/${record.programType.toLowerCase()}/${record.scheme.toLowerCase()}/view-similar/${
        //     record.orimsApplicationFileId
        //   }`,
        //   '_blank'
        // )
        // win.focus()
        const win = window.open(
          `/enquire-application/view/${record.orimsApplicationFileId}`,
          '_blank'
        )
        win.focus()
      }
    } else {
      if (record.isFromReppia) {
        setSimilarRecord(record)
        setShowSimilarModal(true)
      } else {
        // history.push(
        //   `/application-registration/${record.programType.toLowerCase()}/${record.scheme.toLowerCase()}/view/${
        //     record.id
        //   }`
        // )
        // const win = window.open(
        //   `/application-registration/${record.programType.toLowerCase()}/${record.scheme.toLowerCase()}/view-similar/${
        //     record.id
        //   }`,
        //   '_blank'
        // )
        // win.focus()
        const win = window.open(
          `/enquire-application/view/${record.id}`,
          '_blank'
        )
        win.focus()
      }
    }
  }

  const handleConfirm = (remark: string) => {
    if (showConfirm.section === 'cancel') {
      handleCancel(remark)
    } else if (showConfirm.section === 'proceed') {
      handleProceed(remark)
    }

    setShowConfirm({ isShow: false, message: '', section: '', isRemark: false })
  }

  const handleReset = () => {
    const newFileRegistrationObject = { newNic: true } as FileRegistrationModel
    const new2ileRegistrationObject = produce(
      newFileRegistrationObject,
      (draft: FileRegistrationModel) => {
        draft.programTypeOption = null
        draft.schemeOption = null
        draft.incidentType = null
        draft.applicantName = ''
        draft.age = ''
        draft.identificationType = 'BIRTH_CERTIFICATE'
        draft.newNic = true
        draft.newNicNumber = ''
        draft.oldNicNumber = ''
        draft.passportNumber = ''
        draft.drivingLicenseNumber = ''
        draft.seniorCitizenId = ''
        draft.birthCertificateNumber = ''
        draft.deathCertificateNumber = ''
        draft.aggrievedPersonAge = ''
        draft.aggrievedPersonBirthCertificateNumber = ''
        draft.religiousPlaceRegNumber = ''
        draft.aggrievedPersonNewNic = true
        draft.aggrievedPersonNewNicNumber = ''
        draft.aggrievedPersonOldNicNumber = ''
        draft.assetIdentificationNumber = ''
        draft.certificateOfAbsence = ''
      }
    )
    setFileRegistrationObject(new2ileRegistrationObject)
    setIsOverAge(false)
    const dsSelectionModel = {} as dsSelectionModal
    const resetDsSelectionModel = produce(
      dsSelectionModel,
      (draft: dsSelectionModal) => {
        draft.provinceOption = null
        draft.districtOption = null
        draft.dsOption = null
      }
    )
    setSelectedBirthCertificateDs(resetDsSelectionModel)
    setSelectedDeathCertificateDs(resetDsSelectionModel)
    setSelectedAgBirthCertificateDs(resetDsSelectionModel)
    setSelectedInstrumentDs(resetDsSelectionModel)
    setReset(!reset)
    setSearchState(false)

    // const resetObject = FileRegistrationModel.reset({})
  }
  const customStyles = {
    control: (base: any, state: { isFocused: any }) => ({
      ...base,
      // state.isFocused can display different borderColor if you need it
      borderColor: state.isFocused ? '#ddd' : 'red',
      // overwrittes hover style
      '&:hover': {
        borderColor: state.isFocused ? '#ddd' : 'red',
      },
    }),
  }
  const findFormErrors = () => {
    const newErrors = {} as FileRegistrationErrorModel

    if (validation.validateProgramType(fileRegistrationObject.programType)) {
      newErrors.programTypeError = validation.validateProgramType(
        fileRegistrationObject.programType
      )
    }
    if (validation.validateScheme(fileRegistrationObject.scheme)) {
      newErrors.schemeError = validation.validateScheme(
        fileRegistrationObject.scheme
      )
    }
    // if (
    //   validation.validateIncidantType(fileRegistrationObject.incidentTypeId)
    // ) {
    //   newErrors.incidentTypeIdError = validation.validateIncidantType(
    //     fileRegistrationObject.incidentTypeId
    //   )
    // }validateAge
    // if (validation.validateIncidantDate(fileRegistrationObject.incidentDate)) {
    //   newErrors.incidentDateError = validation.validateIncidantDate(
    //     fileRegistrationObject.incidentDate
    //   )
    // }
    if (
      validation.validateApplicantName(fileRegistrationObject.applicantName)
    ) {
      newErrors.applicantNameError = validation.validateApplicantName(
        fileRegistrationObject.applicantName
      )
    }

    if (validation.validateDateofBirth(fileRegistrationObject.dateOfBirth)) {
      newErrors.dateOfBirthError = validation.validateDateofBirth(
        fileRegistrationObject.dateOfBirth
      )
    }

    // if (validation.validateAge(fileRegistrationObject.age)) {
    //   newErrors.ageError = validation.validateAge(fileRegistrationObject.age)
    // }

    if (
      validation.validateIdentificationType(
        fileRegistrationObject.identificationType
      )
    ) {
      newErrors.identificationTypeError = validation.validateIdentificationType(
        fileRegistrationObject.identificationType
      )
    }

    if (checkNicMandatory(fileRegistrationObject.identificationType)) {
      if (
        validation.validateNICnumber(
          fileRegistrationObject.newNicNumber,
          fileRegistrationObject.oldNicNumber
        )
      ) {
        newErrors.nicError = validation.validateNICnumber(
          fileRegistrationObject.newNicNumber,
          fileRegistrationObject.oldNicNumber
        )
      }
    }

    if (fileRegistrationObject.identificationType === 'PASSPORT') {
      if (validation.validatePassport(fileRegistrationObject.passportNumber)) {
        newErrors.passportNumberError = validation.validatePassport(
          fileRegistrationObject.passportNumber
        )
      }
    }
    if (fileRegistrationObject.identificationType === 'SENIOR_CITIZEN_ID') {
      if (
        validation.validatSeniorCitizenId(
          fileRegistrationObject.seniorCitizenId
        )
      ) {
        newErrors.seniorCitizenIdError = validation.validatSeniorCitizenId(
          fileRegistrationObject.seniorCitizenId
        )
      }
    }
    if (fileRegistrationObject.identificationType === 'DRIVING_LICENSE') {
      if (
        validation.validateDrivingLicense(
          fileRegistrationObject.drivingLicenseNumber
        )
      ) {
        newErrors.drivingLicenseNumberError = validation.validateDrivingLicense(
          fileRegistrationObject.drivingLicenseNumber
        )
      }
    }
    if (fileRegistrationObject.identificationType === 'TIC') {
      if (validation.validateTic(fileRegistrationObject.ticNumber)) {
        newErrors.ticNumberError = validation.validateTic(
          fileRegistrationObject.ticNumber
        )
      }
    }

    if (fileRegistrationObject.identificationType === 'BIRTH_CERTIFICATE') {
      if (
        validation.validateBCNumber(
          fileRegistrationObject.birthCertificateNumber
        )
      ) {
        newErrors.birthCertificateNumberError = validation.validateBCNumber(
          fileRegistrationObject.birthCertificateNumber
        )
      }

      if (validation.validateProvince(selectedBirthCertificateDs.provinceId)) {
        newErrors.bcNumberprovinceError = validation.validateProvince(
          selectedBirthCertificateDs.provinceId
        )
      }

      if (validation.validateDistrict(selectedBirthCertificateDs.districtId)) {
        newErrors.bcNunberdistrictError = validation.validateDistrict(
          selectedBirthCertificateDs.districtId
        )
      }

      if (validation.validateDsDivision(selectedBirthCertificateDs.dsId)) {
        newErrors.birthCertificateDsDivisionError =
          validation.validateDsDivision(selectedBirthCertificateDs.dsId)
      }
    }
    if (
      fileRegistrationObject.scheme === 'PD' ||
      fileRegistrationObject.scheme === 'GSD'
    ) {
      if (
        validation.validateDCNumber(
          fileRegistrationObject.deathCertificateNumber
        )
      ) {
        newErrors.deathCertificateNumberError = validation.validateDCNumber(
          fileRegistrationObject.deathCertificateNumber
        )
      }
    }
    if (
      fileRegistrationObject.scheme === 'PD' ||
      fileRegistrationObject.scheme === 'GSD'
    ) {
      if (
        validation.validateDCNumberProvince(
          selectedDeathCertificateDs.provinceId
        )
      ) {
        newErrors.deathCertificateProvinceError =
          validation.validateDCNumberProvince(
            selectedDeathCertificateDs.provinceId
          )
      }
      if (selectedDeathCertificateDs.provinceId) {
        if (
          validation.validateDCNumberDistrict(
            selectedDeathCertificateDs.districtId
          )
        ) {
          newErrors.deathCertificateDistrictError =
            validation.validateDCNumberDistrict(
              selectedDeathCertificateDs.districtId
            )
        }
      }
      if (
        selectedDeathCertificateDs.provinceId &&
        selectedDeathCertificateDs.districtId
      ) {
        if (
          validation.validateDCNumberDsDivision(selectedDeathCertificateDs.dsId)
        ) {
          newErrors.deathCertificateDsDivisionError =
            validation.validateDCNumberDsDivision(
              selectedDeathCertificateDs.dsId
            )
        }
      }
    }
    if (
      fileRegistrationObject.scheme === 'PI' ||
      fileRegistrationObject.scheme === 'GSI'
    ) {
      if (
        validation.validateAggrievedPersonsAge(
          fileRegistrationObject.aggrievedPersonAge,
          fileRegistrationObject.scheme
        )
      ) {
        newErrors.aggrievedPersonAgeError =
          validation.validateAggrievedPersonsAge(
            fileRegistrationObject.aggrievedPersonAge,
            fileRegistrationObject.scheme
          )
      }
      if (
        fileRegistrationObject.aggrievedPersonAge > 16 &&
        fileRegistrationObject.scheme === 'PI'
      ) {
        if (
          validation.validateAggNICnumber(
            fileRegistrationObject.aggrievedPersonNewNicNumber,
            fileRegistrationObject.aggrievedPersonOldNicNumber
          )
        ) {
          newErrors.aggrievedPersonNicError = validation.validateAggNICnumber(
            fileRegistrationObject.aggrievedPersonNewNicNumber,
            fileRegistrationObject.aggrievedPersonOldNicNumber
          )
        }
      }
      if (
        fileRegistrationObject.aggrievedPersonAge < 16 &&
        fileRegistrationObject.scheme === 'PI'
      ) {
        if (
          validation.validateAggBCNumber(
            fileRegistrationObject.aggrievedPersonBirthCertificateNumber
          )
        ) {
          newErrors.aggrievedPersonBirthCertificateNumberError =
            validation.validateAggBCNumber(
              fileRegistrationObject.aggrievedPersonBirthCertificateNumber
            )
        }
      }
      if (fileRegistrationObject.scheme === 'GSI') {
        if (
          validation.validateAggNICnumber(
            fileRegistrationObject.aggrievedPersonNewNicNumber,
            fileRegistrationObject.aggrievedPersonOldNicNumber
          )
        ) {
          newErrors.aggrievedPersonNicError = validation.validateAggNICnumber(
            fileRegistrationObject.aggrievedPersonNewNicNumber,
            fileRegistrationObject.aggrievedPersonOldNicNumber
          )
        }
      }
      // if (
      //   !fileRegistrationObject.aggrievedPersonNewNicNumber ||
      //   !fileRegistrationObject.aggrievedPersonOldNicNumber
      // ) {
      //   if (
      //     validation.validateAggBCNumber(
      //       fileRegistrationObject.aggrievedPersonBirthCertificateNumber
      //     )
      //   ) {
      //     newErrors.aggrievedPersonBirthCertificateNumberError =
      //       validation.validateAggBCNumber(
      //         fileRegistrationObject.aggrievedPersonBirthCertificateNumber
      //       )
      //   }
      // }
      if (fileRegistrationObject.aggrievedPersonBirthCertificateNumber) {
        if (
          validation.validateAggBCProvince(
            selectedAgBirthCertificateDs.provinceId
          )
        ) {
          newErrors.aggrievedPersonBCProvinceError =
            validation.validateAggBCProvince(
              selectedAgBirthCertificateDs.provinceId
            )
        }
        if (selectedAgBirthCertificateDs.provinceId) {
          if (
            validation.validateAggBCDistrict(
              selectedAgBirthCertificateDs.districtId
            )
          ) {
            newErrors.aggrievedPersonBCDistrictError =
              validation.validateAggBCDistrict(
                selectedAgBirthCertificateDs.districtId
              )
          }
        }
        if (selectedAgBirthCertificateDs.districtId) {
          if (
            validation.validateAggBCDsDivision(
              selectedAgBirthCertificateDs.dsId
            )
          ) {
            newErrors.aggrievedPersoneBCDsDivisionError =
              validation.validateAggBCDsDivision(
                selectedAgBirthCertificateDs.dsId
              )
          }
        }
      }
    }
    if (
      fileRegistrationObject.scheme === 'GPC' ||
      fileRegistrationObject.scheme === 'GSC'
    ) {
      // if (fileRegistrationObject.assetIdentificationNumber) {
      //   if (
      //     validation.validateAssetIdentificationNumber(
      //       fileRegistrationObject.assetIdentificationNumber
      //     )
      //   ) {
      //     newErrors.assetIdentificationNumberError =
      //       validation.validateAssetIdentificationNumber(
      //         fileRegistrationObject.assetIdentificationNumber
      //       )
      //   }
      // }
      if (
        validation.validateInstrumentProvince(selectedInstrumentDs.provinceId)
      ) {
        newErrors.instrumentProvinceError =
          validation.validateInstrumentProvince(selectedInstrumentDs.provinceId)
      }
      if (selectedInstrumentDs.provinceId) {
        if (
          validation.validateInstrumentDistrict(selectedInstrumentDs.districtId)
        ) {
          newErrors.instrumentDistrictError =
            validation.validateInstrumentDistrict(
              selectedInstrumentDs.districtId
            )
        }
      }
      if (selectedInstrumentDs.districtId) {
        if (
          validation.validateInstrumentDsDivision(selectedInstrumentDs.dsId)
        ) {
          newErrors.instrumentDsDivisionError =
            validation.validateInstrumentDsDivision(selectedInstrumentDs.dsId)
        }
      }
    }
    if (fileRegistrationObject.scheme === 'RLP') {
      if (
        validation.validateReligiousPlaceRegNumber(
          fileRegistrationObject.religiousPlaceRegNumber
        )
      ) {
        newErrors.religiousPlaceRegstringNoError =
          validation.validateReligiousPlaceRegNumber(
            fileRegistrationObject.religiousPlaceRegNumber
          )
      }

      if (
        validation.validateReligiousPlaceName(
          fileRegistrationObject.religiousPlaceName
        )
      ) {
        newErrors.religiousPlaceNameError =
          validation.validateReligiousPlaceName(
            fileRegistrationObject.religiousPlaceName
          )
      }
    }
    return newErrors
  }

  const handleModify = async () => {
    if (isModify) {
      let result = { status: 200 } as ResponseObject
      dispatch(changeLoadingRequest())
      if (isReturn) {
        result = await applicationFiles.apiFetchApplicationFilesReturnModify(
          fileRegistrationObject.id,
          fileRegistrationObject
        )
      }
      dispatch(changeLoadingRequest())
      handleNotification(
        result,
        'The application has been modified successfully'
      )
      if (result.status === 200) {
        history.goBack()
      }
    } else {
      setIsModify(true)
    }
  }

  const handleCancel = async (remark: string) => {
    let result = { status: 200 } as ResponseObject
    dispatch(changeLoadingRequest())
    if (isReturn) {
      result = await applicationFiles.apiFetchApplicationFilesReturnCancel(
        fileRegistrationObject.id,
        remark
      )
    } else {
      result = await fileRegistrationDataService.apiFetchFileRegistrationCancel(
        fileRegistrationObject.id,
        remark
      )
    }
    dispatch(changeLoadingRequest())
    handleNotification(result, 'Application Cancel Successfully')
    if (result.status === 200) {
      history.goBack()
    }
  }

  const handleSearchDoubleClick = async () => {
    if (isProcessing) return
    setIsProcessing(true)
    try {
      await handleSearch()
    } finally {
      setIsProcessing(false)
    }
  }

  const handleSearch = async () => {
    setLoading(true)
    let result = { status: 200 } as ResponseObject
    let success = false
    let message = result.errorMessage ? result.errorMessage : ''
    dispatch(changeLoadingRequest())
    if (!isDraft) {
      const foundErrros = findFormErrors()
      console.log(foundErrros)
      if (Object.keys(foundErrros).length > 0) {
        dispatch(changeLoadingRequest())
        setErrors(foundErrros)
      } else {
        const searchObject = produce(fileRegistrationObject, (draft) => {
          draft.birthCertificateDsDivisionId =
            selectedBirthCertificateDs.dsOption?.value
          draft.deathCertificateDsDivisionId =
            selectedDeathCertificateDs.dsOption?.value
          draft.instrumentDsDivisionId = selectedInstrumentDs.dsOption?.value
          draft.aggrievedPersonBirthCertificateDsDivisionId =
            selectedAgBirthCertificateDs.dsOption?.value
          draft.incidentTypeValue = draft.incidentType?.incidentType
        })
        setFileRegistrationObject(searchObject)
        result =
          await fileRegistrationDataService.apiFileRegistrationSearchSimilarRecord(
            searchObject
          )
        message = result.errorMessage ? result.errorMessage : ''
        if (result.status === 200) {
          success = true
          message = 'Application Drafted Successfully'
          setSimilarRecordList(result.data.similarRecordList)
          setSearchState(true)
          const draftedObject = produce(fileRegistrationObject, (draft) => {
            draft.id = result.data.id
          })
          setFileRegistrationObject(draftedObject)
        }
        dispatch(changeLoadingRequest())
        showTotast(
          success ? ALERT_SUCCESS : ALERT_WARNING,
          message,
          TOAST_POSITION_TOP_RIGHT,
          5,
          TOAST_TRANSITION_SLIDE
        )
      }
    }
    setLoading(false)
  }
  const findOtherDetailsFormErrors = () => {
    const otherFormErrors = {} as FileRegistrationErrorModel

    if (searchState) {
      if (validation.validateProvince(fileRegistrationObject.provinceId)) {
        otherFormErrors.applicantProvinceError = validation.validateProvince(
          fileRegistrationObject.provinceId
        )
      }
      if (fileRegistrationObject.provinceId) {
        if (validation.validateDistrict(fileRegistrationObject.districtId)) {
          otherFormErrors.applicantDistrictError = validation.validateDistrict(
            fileRegistrationObject.districtId
          )
        }
      }

      if (fileRegistrationObject.districtId) {
        if (
          validation.validateDsDivision(fileRegistrationObject.dsDivisionId)
        ) {
          otherFormErrors.applicantDSError = validation.validateDsDivision(
            fileRegistrationObject.dsDivisionId
          )
        }
      }
      if (fileRegistrationObject.dsDivisionId) {
        if (
          validation.validateGNDivision(fileRegistrationObject.gnDivisionId)
        ) {
          otherFormErrors.applicantGNError = validation.validateGNDivision(
            fileRegistrationObject.gnDivisionId
          )
        }
      }

      if (
        validation.validateFileRecievedDate(
          fileRegistrationObject.fileReceivedDate
        )
      ) {
        otherFormErrors.fileReceivedDateError =
          validation.validateFileRecievedDate(
            fileRegistrationObject.fileReceivedDate
          )
      }
      if (validation.validateAppliedDate(fileRegistrationObject.appliedDate)) {
        otherFormErrors.appliedDateError = validation.validateAppliedDate(
          fileRegistrationObject.appliedDate
        )
      }
    }
    return otherFormErrors
  }
  const handleProceed = async (remark?: string) => {
    const foundOtherErrros = findOtherDetailsFormErrors()
    if (Object.keys(foundOtherErrros).length > 0) {
      setErrors(foundOtherErrros)
    } else {
      dispatch(changeLoadingRequest())
      const saveFileRegistrationObject = produce(
        fileRegistrationObject,
        (draft) => {
          if (remark) {
            draft.remarksToProceedWithDuplicates = remark
          }
        }
      )
      const result = await fileRegistrationDataService.apiFileRegistrationSave(
        saveFileRegistrationObject
      )
      dispatch(changeLoadingRequest())
      if (result.status === 200) {
        setFileRegistrationObject(result.data)
        setShowModal(true)
      } else {
        showTotast(
          ALERT_WARNING,
          result.errorMessage,
          TOAST_POSITION_TOP_RIGHT,
          5,
          TOAST_TRANSITION_SLIDE
        )
      }
    }
  }

  const renderIdentificationTypeName = (typeName: string) => {
    switch (typeName) {
      case 'TIC': {
        return 'Tic'
        break
      }
      case 'PASSPORT': {
        return 'Passport'
        break
      }
      case 'SENIOR_CITIZEN_ID': {
        return 'Senior Citizen ID'
        break
      }
      default: {
        return 'Driving License'
        break
      }
    }
  }

  const renderIdentificationTypeValue = (typeName: string) => {
    switch (typeName) {
      case 'TIC': {
        return fileRegistrationObject.ticNumber
        break
      }
      case 'PASSPORT': {
        return fileRegistrationObject.passportNumber
        break
      }
      case 'SENIOR_CITIZEN_ID': {
        return fileRegistrationObject.seniorCitizenId
        break
      }
      default: {
        return fileRegistrationObject.drivingLicenseNumber
        break
      }
    }
  }

  const getAllocateUsers = async (dsId: number) => {
    const response = await metaDataService.apiGetUserListByDsDivision(dsId)
    setUserList(seAllocateUsersOptions(response.data))
  }

  const apiGetActiveUserList = async () => {
    if (fileRegistrationObject.scheme == 'MP') {
      const response = await metaDataService.apiGetActiveUserList()
      setUserList(seAllocateUsersOptions(response.data))
    }
  }

  const handleReferenceNo = (record: any) => {
    if (
      fileRegistrationObject.status === 'File Drafted' ||
      fileRegistrationObject.status === 'File Returned'
    ) {
      if (record.isFromReppia) {
        return record.reppiaApplicationDetails.mapFileNo
      } else {
        return record.applicationReference
      }
    } else {
      return record.referenceNumber
    }
  }

  return (
    <>
      <Row className="create-role-page-layout">
        <Col sm={12} className="border-label">
          <div className="border-label-span">Search For Similar Record</div>
          {isDraft || searchState ? (
            <ApplicationDataView
              fileRegistrationObject={fileRegistrationObject}
              isOverAge={isOverAge}
              isDraft={true}
              isSearchState={searchState}
            />
          ) : (
            <Form ref={inputForm} id="file-registration-form">
              <Col sm={12} className="border-label">
                <div className="border-label-span">
                  Application Type Details
                </div>
                <Form.Group className="mb-3" controlId="formProgramType">
                  <Form.Label>
                    Program Type<span className="required-field-astrix">*</span>
                  </Form.Label>
                  <Select
                    styles={errors.programTypeError ? customStyles : undefined}
                    isDisabled={isDraft || searchState}
                    value={fileRegistrationObject.programTypeOption}
                    options={programeTypes}
                    onChange={(selectedOption) => {
                      const newFileRegistrationObject = produce(
                        fileRegistrationObject,
                        (draft: FileRegistrationModel) => {
                          draft.programType = selectedOption
                            ? selectedOption.value
                            : ''
                          draft.programTypeOption = selectedOption
                            ? selectedOption
                            : ({} as ProgramOption)
                          draft.schemeOption = null
                          draft.scheme = ''
                        }
                      )
                      getSchemeListByProgrammeType(selectedOption.value)
                      setFileRegistrationObject(newFileRegistrationObject)
                      const clearError = produce(errors, (draft) => {
                        draft.programTypeError = ''
                      })
                      setErrors(clearError)
                    }}
                  />
                  <span className="feedback-error-span-custom">
                    {errors.programTypeError}
                  </span>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formSchema">
                  <Form.Label>
                    Scheme<span className="required-field-astrix">*</span>
                  </Form.Label>
                  <Select
                    styles={errors.schemeError ? customStyles : undefined}
                    isDisabled={isDraft || searchState}
                    value={fileRegistrationObject.schemeOption}
                    options={schemesList.filter(
                      (scheme) =>
                        scheme.programeType ===
                        fileRegistrationObject.programType
                    )}
                    onChange={(selectedOption) => {
                      const newFileRegistrationObject = produce(
                        fileRegistrationObject,
                        (draft: FileRegistrationModel) => {
                          draft.scheme = selectedOption
                            ? selectedOption.value
                            : ''
                          draft.schemeOption = selectedOption
                        }
                      )
                      setFileRegistrationObject(newFileRegistrationObject)
                      const clearError = produce(errors, (draft) => {
                        draft.schemeError = ''
                        draft.deathCertificateNumberError = ''
                        draft.deathCertificateProvinceError = ''
                        draft.deathCertificateDistrictError = ''
                        draft.deathCertificateDsDivisionError = ''
                        draft.aggrievedPersonNicError = ''
                        draft.aggrievedPersonBirthCertificateNumberError = ''
                        draft.aggrievedPersonBCProvinceError = ''
                        draft.aggrievedPersonBCDistrictError = ''
                        draft.aggrievedPersoneBCDsDivisionError = ''
                        draft.assetIdentificationNumberError = ''
                        draft.instrumentProvinceError = ''
                        draft.instrumentDistrictError = ''
                        draft.instrumentDsDivisionError = ''
                        draft.religiousPlaceRegstringNoError = ''
                      })
                      setErrors(clearError)
                    }}
                  />
                  <span className="feedback-error-span-custom">
                    {errors.schemeError}
                  </span>
                </Form.Group>

                {fileRegistrationObject.scheme == 'MP' && (
                  <Form.Group className="mb-3" controlId="formSchema">
                    <Form.Label>OMP Number</Form.Label>
                    <Form.Control
                      maxLength={50}
                      disabled={isDraft || searchState}
                      type="text"
                      placeholder="Enter OMP Number"
                      onChange={(e) => {
                        const newFileRegistrationObject = produce(
                          fileRegistrationObject,
                          (draft: FileRegistrationModel) => {
                            draft.ompNumber = e.target.value
                          }
                        )
                        setFileRegistrationObject(newFileRegistrationObject)
                      }}
                      value={fileRegistrationObject.ompNumber}
                    />
                  </Form.Group>
                )}
                {fileRegistrationObject.programType == 'COMPENSATION' && (
                  <>
                    <Form.Group className="mb-3" controlId="formIncidantType">
                      <Form.Label>
                        Incident Type
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Select
                        value={incidentList.filter((type: any) => {
                          if (
                            type.value === fileRegistrationObject.incidentTypeId
                          ) {
                            return type
                          }
                        })}
                        styles={
                          errors.incidentTypeIdError ? customStyles : undefined
                        }
                        isDisabled={isDraft || searchState}
                        options={incidentList}
                        onChange={(selectedOption) => {
                          console.log(selectedOption)
                          const newFileRegistrationObject = produce(
                            fileRegistrationObject,
                            (draft: FileRegistrationModel) => {
                              draft.incidentTypeId = selectedOption
                                ? selectedOption.value
                                : 0
                              draft.incidentType = incidentDataList.filter(
                                (incident: IncidentTypemodel) => {
                                  if (incident.id === selectedOption.value) {
                                    return incident
                                  }
                                }
                              )[0]
                            }
                          )
                          setFileRegistrationObject(newFileRegistrationObject)
                          const clearError = produce(errors, (draft) => {
                            draft.incidentTypeIdError = ''
                          })
                          setErrors(clearError)
                        }}
                      />
                      <span className="feedback-error-span-custom">
                        {errors.incidentTypeIdError}
                      </span>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formIncidantDate">
                      <Form.Label>
                        Incident Date
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <DatePicker
                        disabled={fileRegistrationObject.incidentTypeId == null}
                        minDate={
                          new Date(
                            fileRegistrationObject.incidentType?.effectiveDate *
                              1000
                          )
                        }
                        maxDate={new Date()}
                        className={
                          errors.incidentDateError
                            ? 'date-picker-validate'
                            : 'form-control'
                        }
                        placeholderText="Enter Incident Date"
                        selected={
                          fileRegistrationObject.incidentDate != null
                            ? new Date(
                                fileRegistrationObject.incidentDate * 1000
                              )
                            : null
                        }
                        onChange={(date: Date) => {
                          if (date != null) {
                            const dateOfBirth =
                              fileRegistrationObject.dateOfBirth != null
                                ? new Date(
                                    fileRegistrationObject.dateOfBirth * 1000
                                  )
                                : new Date()

                            const age = calculateAge(dateOfBirth, date)

                            const newFileRegistrationObject = produce(
                              fileRegistrationObject,
                              (draft: FileRegistrationModel) => {
                                draft.incidentDate = date.getTime() / 1000
                                draft.age = age.years
                                draft.ageInMonths = age.months
                                draft.ageInDays = age.days
                              }
                            )
                            setFileRegistrationObject(newFileRegistrationObject)
                          }
                        }}
                        onSelect={(date: any) => {
                          const dateOfBirth =
                            fileRegistrationObject.dateOfBirth != null
                              ? new Date(
                                  fileRegistrationObject.dateOfBirth * 1000
                                )
                              : new Date()

                          const age = calculateAge(dateOfBirth, date)

                          const newFileRegistrationObject = produce(
                            fileRegistrationObject,
                            (draft: FileRegistrationModel) => {
                              draft.incidentDate = date.getTime() / 1000
                              draft.aggrievedPersonAge
                              draft.age = age.years
                              draft.ageInMonths = age.months
                              draft.ageInDays = age.days
                            }
                          )
                          setFileRegistrationObject(newFileRegistrationObject)
                        }}
                      />
                      <span className="feedback-error-span-custom">
                        {errors.incidentDateError}
                      </span>
                    </Form.Group>
                  </>
                )}
              </Col>
              <Col sm={12} className="border-label mt-4">
                <div className="border-label-span">Applicant Details</div>
                <Form.Group className="mb-3" controlId="formApplicantName">
                  <Form.Label>
                    Applicant Name
                    <span className="required-field-astrix">*</span>
                  </Form.Label>
                  <Form.Control
                    isInvalid={!!errors.applicantNameError}
                    maxLength={50}
                    disabled={isDraft || searchState}
                    type="text"
                    placeholder="Enter Applicant Name"
                    onChange={(e) => {
                      const newFileRegistrationObject = produce(
                        fileRegistrationObject,
                        (draft: FileRegistrationModel) => {
                          draft.applicantName = e.target.value
                        }
                      )
                      setFileRegistrationObject(newFileRegistrationObject)
                      const clearError = produce(errors, (draft) => {
                        draft.applicantNameError = ''
                      })
                      setErrors(clearError)
                    }}
                    value={fileRegistrationObject.applicantName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.applicantNameError}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formSchema">
                  <Form.Label>Contact Number</Form.Label>
                  <Form.Control
                    maxLength={15}
                    disabled={isDraft || searchState}
                    type="text"
                    placeholder="Enter Contact Number"
                    onChange={(e) => {
                      const newFileRegistrationObject = produce(
                        fileRegistrationObject,
                        (draft: FileRegistrationModel) => {
                          draft.contactNo = e.target.value
                        }
                      )
                      setFileRegistrationObject(newFileRegistrationObject)
                    }}
                    value={fileRegistrationObject.contactNo}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formSelection">
                  <Form.Label>
                    Identification Type
                    <span className="required-field-astrix">*</span>
                  </Form.Label>
                  <IdentificationTypesComponent
                    isDisabled={isDraft || searchState}
                    identificationType={
                      fileRegistrationObject.identificationType
                    }
                    onChange={(type: string) => {
                      const newFileRegistrationObject = produce(
                        fileRegistrationObject,
                        (draft: FileRegistrationModel) => {
                          draft.identificationType = type
                          if (type === 'NIC') {
                            draft.dateOfBirth = null
                            draft.age = ''
                            draft.newNic = false
                            draft.newNicNumber = ''
                            draft.oldNicNumber = ''
                          }
                        }
                      )
                      setFileRegistrationObject(newFileRegistrationObject)
                      const clearError = produce(errors, (draft) => {
                        draft.identificationTypeError = ''
                      })
                      setErrors(clearError)
                    }}
                  />
                  <span className="feedback-error-span-custom">
                    {errors.identificationTypeError}
                  </span>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formApplicantDob">
                  <Form.Label>
                    Applicants DoB
                    <span className="required-field-astrix">*</span>
                  </Form.Label>
                  <DatePicker
                    disabled={
                      fileRegistrationObject.identificationType === 'NIC'
                    }
                    maxDate={new Date()}
                    className={
                      errors.dateOfBirthError
                        ? 'date-picker-validate'
                        : 'form-control'
                    }
                    placeholderText="Enter Date of Birth"
                    selected={
                      fileRegistrationObject.dateOfBirth != null
                        ? new Date(fileRegistrationObject.dateOfBirth * 1000)
                        : null
                    }
                    onChange={(date: Date) => {
                      if (date != null) {
                        const incidentDate =
                          fileRegistrationObject.incidentDate != null
                            ? new Date(
                                fileRegistrationObject.incidentDate * 1000
                              )
                            : new Date()

                        const age = calculateAge(date, incidentDate)
                        console.log(age)
                        const newFileRegistrationObject = produce(
                          fileRegistrationObject,
                          (draft: FileRegistrationModel) => {
                            draft.dateOfBirth = date.getTime() / 1000
                            draft.age = age.years
                            draft.ageInMonths = age.months
                            draft.ageInDays = age.days
                          }
                        )
                        setFileRegistrationObject(newFileRegistrationObject)
                      }
                    }}
                    onSelect={(date: any) => {
                      const incidentDate =
                        fileRegistrationObject.incidentDate != null
                          ? new Date(fileRegistrationObject.incidentDate * 1000)
                          : new Date()

                      const age = calculateAge(date, incidentDate)
                      console.log(age)
                      const newFileRegistrationObject = produce(
                        fileRegistrationObject,
                        (draft: FileRegistrationModel) => {
                          draft.dateOfBirth = date.getTime() / 1000
                          draft.age = age.years
                          draft.ageInMonths = age.months
                          draft.ageInDays = age.days
                        }
                      )
                      setFileRegistrationObject(newFileRegistrationObject)
                    }}
                  />
                  <span className="feedback-error-span-custom">
                    {errors.dateOfBirthError}
                  </span>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formApplicantAge">
                  <Form.Label>
                    Applicant Age
                    <span className="required-field-astrix">*</span>
                  </Form.Label>
                  <div className="d-flex align-items-center">
                    <Form.Control
                      style={{ width: '100px' }}
                      isInvalid={!!errors.ageError}
                      min={0}
                      disabled={
                        fileRegistrationObject.identificationType === 'NIC'
                      }
                      type="number"
                      placeholder="Years"
                      onBlur={(e) => {
                        const ageValue =
                          e.target.value == '' ? '0' : e.target.value
                        const clearError = produce(errors, (draft) => {
                          draft.dateOfBirthError = ''

                          if (parseInt(ageValue) >= 16) {
                            draft.birthCertificateNumberError = ''
                            draft.bcNumberprovinceError = ''
                            draft.bcNunberdistrictError = ''
                            draft.birthCertificateDsDivisionError = ''
                          } else {
                            draft.identificationTypeError = ''
                            draft.nicError = ''
                            draft.passportNumberError = ''
                            draft.seniorCitizenIdError = ''
                            draft.drivingLicenseNumberError = ''
                          }
                        })
                        setErrors(clearError)
                      }}
                      onChange={(e) => {
                        const ageValue =
                          e.target.value == '' ? null : e.target.value
                        const newFileRegistrationObject = produce(
                          fileRegistrationObject,
                          (draft: FileRegistrationModel) => {
                            draft.age = parseInt(ageValue)
                          }
                        )
                        setFileRegistrationObject(newFileRegistrationObject)
                        const clearError = produce(errors, (draft) => {
                          draft.ageError = ''
                        })
                        setErrors(clearError)
                      }}
                      value={fileRegistrationObject.age}
                    />
                    <span>&nbsp; (Years)</span>

                    <Form.Control
                      className="ms-3"
                      style={{ width: '100px' }}
                      isInvalid={!!errors.ageError}
                      min={0}
                      disabled={
                        fileRegistrationObject.identificationType === 'NIC'
                      }
                      type="number"
                      placeholder="Months"
                      onBlur={(e) => {
                        const ageValue =
                          e.target.value == '' ? '0' : e.target.value
                        const clearError = produce(errors, (draft) => {
                          draft.dateOfBirthError = ''

                          if (parseInt(ageValue) >= 16) {
                            draft.birthCertificateNumberError = ''
                            draft.bcNumberprovinceError = ''
                            draft.bcNunberdistrictError = ''
                            draft.birthCertificateDsDivisionError = ''
                          } else {
                            draft.identificationTypeError = ''
                            draft.nicError = ''
                            draft.passportNumberError = ''
                            draft.seniorCitizenIdError = ''
                            draft.drivingLicenseNumberError = ''
                          }
                        })
                        setErrors(clearError)
                      }}
                      onChange={(e) => {
                        const ageValue =
                          e.target.value == '' ? null : e.target.value
                        const newFileRegistrationObject = produce(
                          fileRegistrationObject,
                          (draft: FileRegistrationModel) => {
                            draft.ageInMonths = parseInt(ageValue)
                          }
                        )

                        setFileRegistrationObject(newFileRegistrationObject)
                      }}
                      value={fileRegistrationObject.ageInMonths}
                    />
                    <span>&nbsp; (Months)</span>

                    <Form.Control
                      className="ms-3"
                      style={{ width: '100px' }}
                      isInvalid={!!errors.ageError}
                      min={0}
                      disabled={
                        fileRegistrationObject.identificationType === 'NIC'
                      }
                      type="number"
                      placeholder="Days"
                      onBlur={(e) => {
                        const ageValue =
                          e.target.value == '' ? '0' : e.target.value
                        const clearError = produce(errors, (draft) => {
                          draft.dateOfBirthError = ''

                          if (parseInt(ageValue) >= 16) {
                            draft.birthCertificateNumberError = ''
                            draft.bcNumberprovinceError = ''
                            draft.bcNunberdistrictError = ''
                            draft.birthCertificateDsDivisionError = ''
                          } else {
                            draft.identificationTypeError = ''
                            draft.nicError = ''
                            draft.passportNumberError = ''
                            draft.seniorCitizenIdError = ''
                            draft.drivingLicenseNumberError = ''
                          }
                        })
                        setErrors(clearError)
                      }}
                      onChange={(e) => {
                        const ageValue =
                          e.target.value == '' ? null : e.target.value
                        const newFileRegistrationObject = produce(
                          fileRegistrationObject,
                          (draft: FileRegistrationModel) => {
                            draft.ageInDays = parseInt(ageValue)
                          }
                        )
                        setFileRegistrationObject(newFileRegistrationObject)
                      }}
                      value={fileRegistrationObject.ageInDays}
                    />
                    <span>&nbsp; (Days)</span>
                  </div>
                  <Form.Control.Feedback type="invalid">
                    {errors.ageError}
                  </Form.Control.Feedback>
                </Form.Group>
                {fileRegistrationObject.identificationType !=
                  'BIRTH_CERTIFICATE' && (
                  <>
                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="formNICNumber">
                        <Form.Label>
                          Enter NIC Number
                          {checkNicMandatory(
                            fileRegistrationObject.identificationType
                          ) && <span className="required-field-astrix">*</span>}
                        </Form.Label>
                        <NicTypeSelectionComponent
                          isDisabled={isDraft || searchState}
                          newNic={fileRegistrationObject.newNic}
                          onChange={(value: boolean) => {
                            const newFileRegistrationObject = produce(
                              fileRegistrationObject,
                              (draft: FileRegistrationModel) => {
                                draft.newNic = value
                              }
                            )
                            setFileRegistrationObject(newFileRegistrationObject)
                          }}
                        />
                        <Form.Control
                          isInvalid={!!errors.nicError}
                          maxLength={fileRegistrationObject.newNic ? 12 : 10}
                          disabled={isDraft || searchState}
                          type="text"
                          placeholder="Enter NIC Number"
                          onBlur={async (e) => {
                            const identity = await getOldAndNewNicFromGivenNic(
                              fileRegistrationObject.incidentDate != null
                                ? new Date(
                                    fileRegistrationObject.incidentDate * 1000
                                  )
                                : new Date(),
                              e.target.value,
                              fileRegistrationObject.newNic ? 'N' : 'O'
                            )

                            if (identity.error) {
                              const clearError = produce(errors, (draft) => {
                                draft.nicError = 'Not a valid nic number'
                              })
                              setErrors(clearError)
                            } else {
                              const dateOfBirth = identity.birthDate
                              const incidentDate =
                                fileRegistrationObject.incidentDate != null
                                  ? new Date(
                                      fileRegistrationObject.incidentDate * 1000
                                    )
                                  : new Date()

                              const age = calculateAge(
                                dateOfBirth,
                                incidentDate
                              )

                              const newFileRegistrationObject = produce(
                                fileRegistrationObject,
                                (draft: FileRegistrationModel) => {
                                  draft.newNicNumber = identity.newNic
                                  draft.oldNicNumber = identity.oldNic
                                  draft.dateOfBirth =
                                    identity.birthDate.getTime() / 1000
                                  draft.age = age.years
                                  draft.ageInMonths = age.months
                                  draft.ageInDays = age.days
                                  draft.gender = identity.gender
                                }
                              )
                              setFileRegistrationObject(
                                newFileRegistrationObject
                              )
                              const clearError = produce(errors, (draft) => {
                                draft.dateOfBirthError = ''
                                draft.ageError = ''
                              })
                              setErrors(clearError)
                            }
                          }}
                          onChange={(e) => {
                            const newFileRegistrationObject = produce(
                              fileRegistrationObject,
                              (draft: FileRegistrationModel) => {
                                if (draft.newNic) {
                                  draft.newNicNumber = nicFormatFormat(
                                    e.target.value
                                  )
                                } else {
                                  if (e.target.value.length < 10) {
                                    draft.oldNicNumber = nicFormatFormat(
                                      e.target.value
                                    )
                                  } else {
                                    draft.oldNicNumber = e.target.value
                                  }
                                }
                              }
                            )
                            setFileRegistrationObject(newFileRegistrationObject)
                            const clearError = produce(errors, (draft) => {
                              draft.nicError = ''
                            })
                            setErrors(clearError)
                          }}
                          value={
                            fileRegistrationObject.newNic
                              ? fileRegistrationObject.newNicNumber
                              : fileRegistrationObject.oldNicNumber
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.nicError}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    {fileRegistrationObject.identificationType != 'NIC' && (
                      <Row className="mb-3">
                        <Form.Group as={Col} controlId="formPSDNumber">
                          <Form.Label>
                            {renderIdentificationTypeName(
                              fileRegistrationObject.identificationType
                            )}{' '}
                            <span className="required-field-astrix">*</span>
                          </Form.Label>
                          <Form.Control
                            isInvalid={
                              !!errors.passportNumberError ||
                              !!errors.seniorCitizenIdError ||
                              !!errors.drivingLicenseNumberError ||
                              !!errors.ticNumberError
                            }
                            type="text"
                            disabled={
                              fileRegistrationObject.identificationType ===
                                '' ||
                              fileRegistrationObject.identificationType ===
                                null ||
                              isDraft ||
                              searchState
                            }
                            placeholder={`Enter ${renderIdentificationTypeName(
                              fileRegistrationObject.identificationType
                            )}`}
                            onChange={(e) => {
                              const newFileRegistrationObject = produce(
                                fileRegistrationObject,
                                (draft: FileRegistrationModel) => {
                                  switch (
                                    fileRegistrationObject.identificationType
                                  ) {
                                    case 'PASSPORT': {
                                      draft.passportNumber = e.target.value
                                      break
                                    }
                                    case 'TIC': {
                                      draft.ticNumber = e.target.value
                                      break
                                    }
                                    case 'SENIOR_CITIZEN_ID': {
                                      draft.seniorCitizenId = e.target.value
                                      break
                                    }
                                    case 'DRIVING_LICENSE': {
                                      draft.drivingLicenseNumber =
                                        e.target.value
                                      break
                                    }
                                    default: {
                                      break
                                    }
                                  }
                                }
                              )
                              setFileRegistrationObject(
                                newFileRegistrationObject
                              )
                              const clearError = produce(errors, (draft) => {
                                draft.passportNumberError = ''
                                draft.seniorCitizenIdError = ''
                                draft.drivingLicenseNumberError = ''
                              })
                              setErrors(clearError)
                            }}
                            value={renderIdentificationTypeValue(
                              fileRegistrationObject.identificationType
                            )}
                          />
                          <Form.Control.Feedback type="invalid">
                            {fileRegistrationObject.identificationType ==
                            'PASSPORT'
                              ? errors.passportNumberError
                              : fileRegistrationObject.identificationType ==
                                'TIC'
                              ? errors.ticNumberError
                              : fileRegistrationObject.identificationType ==
                                'SENIOR_CITIZEN_ID'
                              ? errors.seniorCitizenIdError
                              : fileRegistrationObject.identificationType ==
                                'DRIVING_LICENSE'
                              ? errors.drivingLicenseNumberError
                              : ''}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                    )}
                  </>
                )}

                <Form.Group className="mb-3" controlId="idNumberRemark">
                  <Form.Label>ID Number Remark</Form.Label>
                  <Form.Control
                    maxLength={45}
                    disabled={isDraft || searchState}
                    type="text"
                    placeholder="Enter Number Remark"
                    onChange={(e) => {
                      const newFileRegistrationObject = produce(
                        fileRegistrationObject,
                        (draft: FileRegistrationModel) => {
                          draft.idNumberRemark = e.target.value
                        }
                      )
                      setFileRegistrationObject(newFileRegistrationObject)
                    }}
                    value={fileRegistrationObject.idNumberRemark}
                  />
                </Form.Group>

                {(fileRegistrationObject.scheme == 'PD' ||
                  fileRegistrationObject.scheme == 'GSD') && (
                  <Form.Group className="mb-3" controlId="certificateOfAbsence">
                    <Form.Label>Certificate Of Absence</Form.Label>
                    <Form.Control
                      isInvalid={!!errors.certificateOfAbsenceError}
                      maxLength={100}
                      disabled={isDraft || searchState}
                      type="text"
                      placeholder="Enter Certificate Of Absence"
                      onChange={(e) => {
                        const newFileRegistrationObject = produce(
                          fileRegistrationObject,
                          (draft: FileRegistrationModel) => {
                            draft.certificateOfAbsence = e.target.value
                          }
                        )
                        setFileRegistrationObject(newFileRegistrationObject)
                        const clearError = produce(errors, (draft) => {
                          draft.certificateOfAbsenceError = ''
                        })
                        setErrors(clearError)
                      }}
                      value={fileRegistrationObject.certificateOfAbsence}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.certificateOfAbsenceError}
                    </Form.Control.Feedback>
                  </Form.Group>
                )}

                <Col sm={12} className="border-label mt-4">
                  <div className="border-label-span">
                    Birth Certificate Details
                  </div>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formBirthCNumber">
                      <Form.Label>
                        Birth Certificate Number
                        {fileRegistrationObject.identificationType ==
                          'BIRTH_CERTIFICATE' && (
                          <span className="required-field-astrix">*</span>
                        )}
                      </Form.Label>
                      <Form.Control
                        isInvalid={!!errors.birthCertificateNumberError}
                        disabled={isDraft || searchState}
                        type="text"
                        maxLength={4}
                        placeholder="Enter Birth Certificate Number"
                        onChange={(e) => {
                          const newFileRegistrationObject = produce(
                            fileRegistrationObject,
                            (draft: FileRegistrationModel) => {
                              draft.birthCertificateNumber = e.target.value
                            }
                          )
                          setFileRegistrationObject(newFileRegistrationObject)
                          const clearError = produce(errors, (draft) => {
                            draft.birthCertificateNumberError = ''
                          })
                          setErrors(clearError)
                        }}
                        value={fileRegistrationObject.birthCertificateNumber}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.birthCertificateNumberError}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3 align-items-baseline">
                    <Form.Group as={Col} controlId="formBirthCNumber">
                      <Form.Label>Province</Form.Label>
                      <Select
                        styles={
                          errors.bcNumberprovinceError
                            ? customStyles
                            : undefined
                        }
                        value={selectedBirthCertificateDs.provinceOption}
                        options={provinceList}
                        onChange={(selectedOption) => {
                          const newSelectedBirthCertificateDs = produce(
                            selectedBirthCertificateDs,
                            (draft: dsSelectionModal) => {
                              draft.provinceOption = selectedOption
                                ? selectedOption
                                : ({} as ProvinceOption)
                              draft.provinceId = selectedOption
                                ? selectedOption.value
                                : undefined
                              draft.districtOption = null
                              draft.districtId = undefined
                              draft.dsOption = null
                            }
                          )
                          setSelectedBirthCertificateDs(
                            newSelectedBirthCertificateDs
                          )
                          const clearError = produce(errors, (draft) => {
                            draft.bcNumberprovinceError = ''
                          })
                          setErrors(clearError)
                        }}
                      />
                      <span className="feedback-error-span-custom">
                        {errors.bcNumberprovinceError}
                      </span>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formBirthCDsDistrict">
                      <Form.Label>District</Form.Label>
                      <Select
                        styles={
                          errors.bcNunberdistrictError
                            ? customStyles
                            : undefined
                        }
                        value={selectedBirthCertificateDs.districtOption}
                        isDisabled={
                          selectedBirthCertificateDs.provinceId === undefined
                        }
                        options={districtList.filter(
                          (district: DistrictOption) => {
                            return (
                              district.provinceId ===
                              selectedBirthCertificateDs.provinceId
                            )
                          }
                        )}
                        onChange={(selectedOption) => {
                          const newSelectedBirthCertificateDs = produce(
                            selectedBirthCertificateDs,
                            (draft: dsSelectionModal) => {
                              draft.districtOption = selectedOption
                                ? selectedOption
                                : ({} as DistrictOption)
                              draft.districtId = selectedOption
                                ? selectedOption.value
                                : undefined
                              draft.dsOption = null
                            }
                          )
                          setSelectedBirthCertificateDs(
                            newSelectedBirthCertificateDs
                          )
                          const clearError = produce(errors, (draft) => {
                            draft.bcNunberdistrictError = ''
                          })
                          setErrors(clearError)
                        }}
                      />
                      <span className="feedback-error-span-custom">
                        {errors.bcNunberdistrictError}
                      </span>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formBirthCDsDivision">
                      <Form.Label>
                        Ds Division of Birth Certificate
                        {fileRegistrationObject.identificationType ==
                          'BIRTH_CERTIFICATE' && (
                          <span className="required-field-astrix">*</span>
                        )}
                      </Form.Label>
                      <Select
                        styles={
                          errors.birthCertificateDsDivisionError
                            ? customStyles
                            : undefined
                        }
                        value={selectedBirthCertificateDs.dsOption}
                        isDisabled={
                          selectedBirthCertificateDs.districtId === undefined
                        }
                        options={dsList.filter((ds: DsOption) => {
                          return (
                            ds.districtId ===
                            selectedBirthCertificateDs.districtId
                          )
                        })}
                        onChange={(selectedOption) => {
                          const newSelectedBirthCertificateDs = produce(
                            selectedBirthCertificateDs,
                            (draft: dsSelectionModal) => {
                              draft.dsOption = selectedOption
                                ? selectedOption
                                : ({} as DsOption)
                              draft.dsId = selectedOption
                                ? selectedOption.value
                                : undefined
                            }
                          )
                          setSelectedBirthCertificateDs(
                            newSelectedBirthCertificateDs
                          )
                          const clearError = produce(errors, (draft) => {
                            draft.birthCertificateDsDivisionError = ''
                          })
                          setErrors(clearError)
                        }}
                      />
                      <span className="feedback-error-span-custom">
                        {errors.birthCertificateDsDivisionError}
                      </span>
                    </Form.Group>
                  </Row>
                </Col>
              </Col>
              {fileRegistrationObject.programType === 'COMPENSATION' &&
                fileRegistrationObject.scheme != 'MP' && (
                  <Col sm={12} className="border-label mt-4">
                    {fileRegistrationObject.scheme === 'PD' ||
                    fileRegistrationObject.scheme === 'GSD' ||
                    fileRegistrationObject.scheme === 'PI' ||
                    fileRegistrationObject.scheme === 'GSI' ? (
                      <div className="border-label-span">
                        Aggrieved Person Details
                      </div>
                    ) : (
                      <div className="border-label-span">
                        Affected Property Details
                      </div>
                    )}

                    {(fileRegistrationObject.scheme === 'PD' ||
                      fileRegistrationObject.scheme === 'GSD') && (
                      <Col sm={12} className="border-label mt-1 mb-4">
                        <div className="border-label-span">
                          Death Certificate Details
                        </div>
                        <Row className="mb-3">
                          <Form.Group as={Col} controlId="formDeathCNumber">
                            <Form.Label>
                              Death Certificate Number
                              <span className="required-field-astrix">*</span>
                            </Form.Label>
                            <Form.Control
                              isInvalid={!!errors.deathCertificateNumberError}
                              type="text"
                              maxLength={4}
                              placeholder="Enter Death Certificate Number"
                              onChange={(e) => {
                                const newFileRegistrationObject = produce(
                                  fileRegistrationObject,
                                  (draft: FileRegistrationModel) => {
                                    draft.deathCertificateNumber =
                                      e.target.value
                                  }
                                )
                                setFileRegistrationObject(
                                  newFileRegistrationObject
                                )
                                const clearError = produce(errors, (draft) => {
                                  draft.deathCertificateNumberError = ''
                                })
                                setErrors(clearError)
                              }}
                              value={
                                fileRegistrationObject.deathCertificateNumber
                              }
                            />
                            <span className="feedback-error-span-custom">
                              {errors.deathCertificateNumberError}
                            </span>
                          </Form.Group>
                        </Row>
                        <Row className="mb-3 align-items-baseline">
                          <Form.Group as={Col} controlId="formDeathCProvince">
                            <Form.Label>Province</Form.Label>
                            <Select
                              styles={
                                errors.deathCertificateProvinceError
                                  ? customStyles
                                  : undefined
                              }
                              value={selectedDeathCertificateDs.provinceOption}
                              options={provinceList}
                              onChange={(selectedOption) => {
                                const newSelectedDeathCertificateDs = produce(
                                  selectedDeathCertificateDs,
                                  (draft: dsSelectionModal) => {
                                    draft.provinceOption = selectedOption
                                      ? selectedOption
                                      : ({} as ProvinceOption)
                                    draft.provinceId = selectedOption
                                      ? selectedOption.value
                                      : undefined
                                    draft.districtOption = null
                                    draft.districtId = undefined
                                    draft.dsOption = null
                                  }
                                )
                                setSelectedDeathCertificateDs(
                                  newSelectedDeathCertificateDs
                                )
                                const clearError = produce(errors, (draft) => {
                                  draft.deathCertificateProvinceError = ''
                                })
                                setErrors(clearError)
                              }}
                            />
                            <span className="feedback-error-span-custom">
                              {errors.deathCertificateProvinceError}
                            </span>
                          </Form.Group>
                          <Form.Group as={Col} controlId="formDeathCDsDistrict">
                            <Form.Label>District</Form.Label>
                            <Select
                              styles={
                                errors.deathCertificateDistrictError
                                  ? customStyles
                                  : undefined
                              }
                              value={selectedDeathCertificateDs.districtOption}
                              isDisabled={
                                selectedDeathCertificateDs.provinceId ===
                                undefined
                              }
                              options={districtList.filter(
                                (district: DistrictOption) => {
                                  return (
                                    district.provinceId ===
                                    selectedDeathCertificateDs.provinceId
                                  )
                                }
                              )}
                              onChange={(selectedOption) => {
                                const newSelectedDeathCertificateDs = produce(
                                  selectedDeathCertificateDs,
                                  (draft: dsSelectionModal) => {
                                    draft.districtOption = selectedOption
                                      ? selectedOption
                                      : ({} as DistrictOption)
                                    draft.districtId = selectedOption
                                      ? selectedOption.value
                                      : undefined
                                    draft.dsOption = null
                                  }
                                )
                                setSelectedDeathCertificateDs(
                                  newSelectedDeathCertificateDs
                                )
                                const clearError = produce(errors, (draft) => {
                                  draft.deathCertificateDistrictError = ''
                                })
                                setErrors(clearError)
                              }}
                            />
                            <span className="feedback-error-span-custom">
                              {errors.deathCertificateDistrictError}
                            </span>
                          </Form.Group>
                          <Form.Group as={Col} controlId="formDeathCDsDivision">
                            <Form.Label>
                              Ds Division of Death Certificate
                              <span className="required-field-astrix">*</span>
                            </Form.Label>
                            <Select
                              styles={
                                errors.deathCertificateDsDivisionError
                                  ? customStyles
                                  : undefined
                              }
                              value={selectedDeathCertificateDs.dsOption}
                              isDisabled={
                                selectedDeathCertificateDs.districtId ===
                                undefined
                              }
                              options={dsList.filter((ds: DsOption) => {
                                return (
                                  ds.districtId ===
                                  selectedDeathCertificateDs.districtId
                                )
                              })}
                              onChange={(selectedOption) => {
                                const newSelectedDeathCertificateDs = produce(
                                  selectedDeathCertificateDs,
                                  (draft: dsSelectionModal) => {
                                    draft.dsOption = selectedOption
                                      ? selectedOption
                                      : ({} as DsOption)
                                    draft.dsId = selectedOption
                                      ? selectedOption.value
                                      : undefined
                                  }
                                )
                                setSelectedDeathCertificateDs(
                                  newSelectedDeathCertificateDs
                                )
                                const clearError = produce(errors, (draft) => {
                                  draft.deathCertificateDsDivisionError = ''
                                })
                                setErrors(clearError)
                              }}
                            />
                            <span className="feedback-error-span-custom">
                              {errors.deathCertificateDsDivisionError}
                            </span>
                          </Form.Group>
                        </Row>
                      </Col>
                    )}
                    {(fileRegistrationObject.scheme === 'PI' ||
                      fileRegistrationObject.scheme === 'GSI') && (
                      <>
                        <Form.Group
                          className="mb-3"
                          controlId="aggrivedPersionAge"
                        >
                          <Form.Label>
                            Aggrieved Persons Age
                            <span className="required-field-astrix">*</span>
                          </Form.Label>
                          <Form.Control
                            isInvalid={!!errors.aggrievedPersonAgeError}
                            value={fileRegistrationObject.aggrievedPersonAge}
                            type="number"
                            min={0}
                            onChange={(e) => {
                              const ageValue =
                                e.target.value == ''
                                  ? ''
                                  : parseInt(e.target.value)
                              const newFileRegistrationObject = produce(
                                fileRegistrationObject,
                                (draft: FileRegistrationModel) => {
                                  draft.aggrievedPersonAge = ageValue
                                }
                              )
                              setFileRegistrationObject(
                                newFileRegistrationObject
                              )
                              const clearError = produce(errors, (draft) => {
                                draft.aggrievedPersonNicError = ''
                              })
                              setErrors(clearError)
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.aggrievedPersonAgeError}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          className="mb-3"
                          controlId="formAggrievedPersonNIC"
                        >
                          <Form.Label>
                            Aggrieved Persons NIC
                            <span className="required-field-astrix">*</span>
                            <Col>
                              <Form.Check
                                inline
                                name="formAggrievedPersonNICType"
                                label="New"
                                type="radio"
                                id={`formAggrievedPersonNICType-N`}
                                checked={
                                  fileRegistrationObject.aggrievedPersonNewNic
                                }
                                onChange={() => {
                                  const newFileRegistrationObject = produce(
                                    fileRegistrationObject,
                                    (draft: FileRegistrationModel) => {
                                      draft.aggrievedPersonNewNic = true
                                    }
                                  )
                                  setFileRegistrationObject(
                                    newFileRegistrationObject
                                  )
                                }}
                              />
                            </Col>
                            <Col>
                              <Form.Check
                                inline
                                name="formAggrievedPersonNICType"
                                label="Old"
                                type="radio"
                                id={`formAggrievedPersonNICType-O`}
                                onChange={() => {
                                  const newFileRegistrationObject = produce(
                                    fileRegistrationObject,
                                    (draft: FileRegistrationModel) => {
                                      draft.aggrievedPersonNewNic = false
                                    }
                                  )
                                  setFileRegistrationObject(
                                    newFileRegistrationObject
                                  )
                                }}
                              />
                            </Col>
                          </Form.Label>
                          <Form.Control
                            isInvalid={!!errors.aggrievedPersonNicError}
                            type="text"
                            placeholder="Enter Aggrieved Persons NIC"
                            onBlur={async (e) => {
                              const identity =
                                await getOldAndNewNicFromGivenNic(
                                  fileRegistrationObject.incidentDate != null
                                    ? new Date(
                                        fileRegistrationObject.incidentDate *
                                          1000
                                      )
                                    : new Date(),
                                  e.target.value,
                                  fileRegistrationObject.aggrievedPersonNewNic
                                    ? 'N'
                                    : 'O'
                                )
                              if (identity.error) {
                                const clearError = produce(errors, (draft) => {
                                  draft.aggrievedPersonNicError =
                                    'Not a valid nic number'
                                })
                                setErrors(clearError)
                              } else {
                                const newFileRegistrationObject = produce(
                                  fileRegistrationObject,
                                  (draft: FileRegistrationModel) => {
                                    draft.aggrievedPersonNewNicNumber =
                                      identity.newNic
                                    draft.aggrievedPersonOldNicNumber =
                                      identity.oldNic
                                    draft.aggrievedPersonAge = identity.age
                                  }
                                )
                                setFileRegistrationObject(
                                  newFileRegistrationObject
                                )
                              }
                            }}
                            onChange={(e) => {
                              const newFileRegistrationObject = produce(
                                fileRegistrationObject,
                                (draft: FileRegistrationModel) => {
                                  if (draft.aggrievedPersonNewNic) {
                                    draft.aggrievedPersonNewNicNumber =
                                      e.target.value
                                  } else {
                                    draft.aggrievedPersonOldNicNumber =
                                      e.target.value
                                  }
                                }
                              )
                              setFileRegistrationObject(
                                newFileRegistrationObject
                              )
                              const clearError = produce(errors, (draft) => {
                                draft.aggrievedPersonNicError = ''
                              })
                              setErrors(clearError)
                            }}
                            value={
                              fileRegistrationObject.aggrievedPersonNewNic
                                ? fileRegistrationObject.aggrievedPersonNewNicNumber
                                : fileRegistrationObject.aggrievedPersonOldNicNumber
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.aggrievedPersonNicError}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </>
                    )}
                    {fileRegistrationObject.scheme === 'PI' && (
                      <Col sm={12} className="border-label mt-4 mb-4">
                        <div className="border-label-span">{`Aggrieved Person's Birth Certificate Details`}</div>
                        <Row className="mb-3">
                          <Form.Group as={Col} controlId="formBirthCNumber">
                            <Form.Label>
                              {`Aggrieved Person's Birth Certificate Number`}
                              <span className="required-field-astrix">*</span>
                            </Form.Label>
                            <Form.Control
                              isInvalid={
                                !!errors.aggrievedPersonBirthCertificateNumberError
                              }
                              type="text"
                              maxLength={4}
                              placeholder={`Enter Aggrieved Person's Birth Certificate Number`}
                              onChange={(e) => {
                                const newFileRegistrationObject = produce(
                                  fileRegistrationObject,
                                  (draft: FileRegistrationModel) => {
                                    draft.aggrievedPersonBirthCertificateNumber =
                                      e.target.value
                                  }
                                )
                                setFileRegistrationObject(
                                  newFileRegistrationObject
                                )
                                const clearError = produce(errors, (draft) => {
                                  draft.aggrievedPersonBirthCertificateNumberError =
                                    ''
                                })
                                setErrors(clearError)
                              }}
                              value={
                                fileRegistrationObject.aggrievedPersonBirthCertificateNumber
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {
                                errors.aggrievedPersonBirthCertificateNumberError
                              }
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                        <Row className="mb-3 align-items-baseline">
                          <Form.Group as={Col} controlId="formAgProvince">
                            <Form.Label>Province</Form.Label>
                            <Select
                              styles={
                                errors.aggrievedPersonBCProvinceError
                                  ? customStyles
                                  : undefined
                              }
                              value={
                                selectedAgBirthCertificateDs.provinceOption
                              }
                              options={provinceList}
                              onChange={(selectedOption) => {
                                const newSelectedAgBirthCertificateDs = produce(
                                  selectedAgBirthCertificateDs,
                                  (draft: dsSelectionModal) => {
                                    draft.provinceOption = selectedOption
                                      ? selectedOption
                                      : ({} as ProvinceOption)
                                    draft.provinceId = selectedOption
                                      ? selectedOption.value
                                      : undefined
                                    draft.districtOption = null
                                    draft.districtId = undefined
                                    draft.dsOption = null
                                  }
                                )
                                setSelectedAgBirthCertificateDs(
                                  newSelectedAgBirthCertificateDs
                                )
                                const clearError = produce(errors, (draft) => {
                                  draft.aggrievedPersonBCProvinceError = ''
                                })
                                setErrors(clearError)
                              }}
                            />
                            <span className="feedback-error-span-custom">
                              {errors.aggrievedPersonBCProvinceError}
                            </span>
                          </Form.Group>
                          <Form.Group as={Col} controlId="formAgBirthCDistrict">
                            <Form.Label>District</Form.Label>
                            <Select
                              styles={
                                errors.aggrievedPersonBCDistrictError
                                  ? customStyles
                                  : undefined
                              }
                              value={
                                selectedAgBirthCertificateDs.districtOption
                              }
                              isDisabled={
                                selectedAgBirthCertificateDs.provinceId ===
                                undefined
                              }
                              options={districtList.filter(
                                (district: DistrictOption) => {
                                  return (
                                    district.provinceId ===
                                    selectedAgBirthCertificateDs.provinceId
                                  )
                                }
                              )}
                              onChange={(selectedOption) => {
                                const newSelectedAgBirthCertificateDs = produce(
                                  selectedAgBirthCertificateDs,
                                  (draft: dsSelectionModal) => {
                                    draft.districtOption = selectedOption
                                      ? selectedOption
                                      : ({} as DistrictOption)
                                    draft.districtId = selectedOption
                                      ? selectedOption.value
                                      : undefined
                                    draft.dsOption = null
                                  }
                                )
                                setSelectedAgBirthCertificateDs(
                                  newSelectedAgBirthCertificateDs
                                )
                                const clearError = produce(errors, (draft) => {
                                  draft.aggrievedPersonBCDistrictError = ''
                                })
                                setErrors(clearError)
                              }}
                            />
                            <span className="feedback-error-span-custom">
                              {errors.aggrievedPersonBCDistrictError}
                            </span>
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            controlId="formAgBirthCDsDivision"
                          >
                            <Form.Label>
                              {`DS Division of Aggrieved Person’s Birth Certificate`}
                              <span className="required-field-astrix">*</span>
                            </Form.Label>
                            <Select
                              styles={
                                errors.aggrievedPersoneBCDsDivisionError
                                  ? customStyles
                                  : undefined
                              }
                              value={selectedAgBirthCertificateDs.dsOption}
                              isDisabled={
                                selectedAgBirthCertificateDs.districtId ===
                                undefined
                              }
                              options={dsList.filter((ds: DsOption) => {
                                return (
                                  ds.districtId ===
                                  selectedAgBirthCertificateDs.districtId
                                )
                              })}
                              onChange={(selectedOption) => {
                                const newSelectedAgBirthCertificateDs = produce(
                                  selectedAgBirthCertificateDs,
                                  (draft: dsSelectionModal) => {
                                    draft.dsOption = selectedOption
                                      ? selectedOption
                                      : ({} as DsOption)
                                    draft.dsId = selectedOption?.value
                                  }
                                )
                                setSelectedAgBirthCertificateDs(
                                  newSelectedAgBirthCertificateDs
                                )
                                const clearError = produce(errors, (draft) => {
                                  draft.aggrievedPersoneBCDsDivisionError = ''
                                })
                                setErrors(clearError)
                              }}
                            />
                            <span className="feedback-error-span-custom">
                              {errors.aggrievedPersoneBCDsDivisionError}
                            </span>
                          </Form.Group>
                        </Row>
                      </Col>
                    )}
                    {(fileRegistrationObject.scheme === 'GPC' ||
                      fileRegistrationObject.scheme === 'GSC') && (
                      <Col sm={12} className="border-label mt-4 mb-4">
                        <div className="border-label-span">{`Instrument Details`}</div>
                        <Row className="mb-3">
                          <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>{`Asset Identification Type`}</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder={`Enter Asset Identification Type`}
                            />
                          </Form.Group>

                          <Form.Group as={Col} controlId="formAssetIdNumber">
                            <Form.Label>
                              {`Asset Identification Number`}{' '}
                            </Form.Label>
                            <Form.Control
                              isInvalid={
                                !!errors.assetIdentificationNumberError
                              }
                              type="text"
                              placeholder={`Enter Asset Identification Number`}
                              onChange={(e) => {
                                const newFileRegistrationObject = produce(
                                  fileRegistrationObject,
                                  (draft: FileRegistrationModel) => {
                                    draft.assetIdentificationNumber =
                                      e.target.value
                                  }
                                )
                                setFileRegistrationObject(
                                  newFileRegistrationObject
                                )
                                const clearError = produce(errors, (draft) => {
                                  draft.assetIdentificationNumberError = ''
                                })
                                setErrors(clearError)
                              }}
                              value={
                                fileRegistrationObject.assetIdentificationNumber
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.assetIdentificationNumberError}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                        <Row className="mb-3 align-items-baseline">
                          <Form.Group
                            as={Col}
                            controlId="formInstrumentProvince"
                          >
                            <Form.Label>
                              Province{' '}
                              <span className="required-field-astrix">*</span>
                            </Form.Label>
                            <Select
                              styles={
                                errors.instrumentProvinceError
                                  ? customStyles
                                  : undefined
                              }
                              value={selectedInstrumentDs.provinceOption}
                              options={provinceList}
                              onChange={(selectedOption) => {
                                const newSelectedInstrumentDs = produce(
                                  selectedInstrumentDs,
                                  (draft: dsSelectionModal) => {
                                    draft.provinceOption = selectedOption
                                      ? selectedOption
                                      : ({} as ProvinceOption)
                                    draft.provinceId = selectedOption
                                      ? selectedOption.value
                                      : undefined
                                    draft.districtOption = null
                                    draft.districtId = undefined
                                    draft.dsOption = null
                                  }
                                )
                                setSelectedInstrumentDs(newSelectedInstrumentDs)
                                const clearError = produce(errors, (draft) => {
                                  draft.instrumentProvinceError = ''
                                })
                                setErrors(clearError)
                              }}
                            />
                            <span className="feedback-error-span-custom">
                              {errors.instrumentProvinceError}
                            </span>
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            controlId="formInstrumentDistrict"
                          >
                            <Form.Label>
                              District{' '}
                              <span className="required-field-astrix">*</span>
                            </Form.Label>
                            <Select
                              styles={
                                errors.instrumentDistrictError
                                  ? customStyles
                                  : undefined
                              }
                              value={selectedInstrumentDs.districtOption}
                              isDisabled={
                                selectedInstrumentDs.provinceId === undefined
                              }
                              options={districtList.filter(
                                (district: DistrictOption) => {
                                  return (
                                    district.provinceId ===
                                    selectedInstrumentDs.provinceId
                                  )
                                }
                              )}
                              onChange={(selectedOption) => {
                                const newSelectedInstrumentDs = produce(
                                  selectedInstrumentDs,
                                  (draft: dsSelectionModal) => {
                                    draft.districtOption = selectedOption
                                      ? selectedOption
                                      : ({} as DistrictOption)
                                    draft.districtId = selectedOption
                                      ? selectedOption.value
                                      : undefined
                                    draft.dsOption = null
                                  }
                                )
                                setSelectedInstrumentDs(newSelectedInstrumentDs)
                                const clearError = produce(errors, (draft) => {
                                  draft.instrumentDistrictError = ''
                                })
                                setErrors(clearError)
                              }}
                            />
                            <span className="feedback-error-span-custom">
                              {errors.instrumentDistrictError}
                            </span>
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            controlId="formInstrumentDSDivision"
                          >
                            <Form.Label>
                              {`DS Division of Instrument`}
                              <span className="required-field-astrix">*</span>
                            </Form.Label>
                            <Select
                              styles={
                                errors.instrumentDsDivisionError
                                  ? customStyles
                                  : undefined
                              }
                              value={selectedInstrumentDs.dsOption}
                              isDisabled={
                                selectedInstrumentDs.districtId === undefined
                              }
                              options={dsList.filter((ds: DsOption) => {
                                return (
                                  ds.districtId ===
                                  selectedInstrumentDs.districtId
                                )
                              })}
                              onChange={(selectedOption) => {
                                const newSelectedInstrumentDs = produce(
                                  selectedInstrumentDs,
                                  (draft: dsSelectionModal) => {
                                    draft.dsOption = selectedOption
                                      ? selectedOption
                                      : ({} as DsOption)
                                    draft.dsId = selectedOption
                                      ? selectedOption.value
                                      : undefined
                                  }
                                )
                                setSelectedInstrumentDs(newSelectedInstrumentDs)
                                const clearError = produce(errors, (draft) => {
                                  draft.instrumentDsDivisionError = ''
                                })
                                setErrors(clearError)
                              }}
                            />
                            <span className="feedback-error-span-custom">
                              {errors.instrumentDsDivisionError}
                            </span>
                          </Form.Group>
                        </Row>
                      </Col>
                    )}
                    {fileRegistrationObject.scheme === 'RLP' && (
                      <Row className="mb-3">
                        <Form.Group
                          as={Col}
                          controlId="formReligiousPlaceRegNumber"
                        >
                          <Form.Label>
                            {`Religious Place Reg. Number`}
                            <span className="required-field-astrix">*</span>
                          </Form.Label>
                          <Form.Control
                            isInvalid={!!errors.religiousPlaceRegstringNoError}
                            type="text"
                            placeholder={`Enter Religious Place Reg. Number`}
                            onChange={(e) => {
                              const newFileRegistrationObject = produce(
                                fileRegistrationObject,
                                (draft: FileRegistrationModel) => {
                                  draft.religiousPlaceRegNumber = e.target.value
                                }
                              )
                              setFileRegistrationObject(
                                newFileRegistrationObject
                              )
                              const clearError = produce(errors, (draft) => {
                                draft.religiousPlaceRegstringNoError = ''
                              })
                              setErrors(clearError)
                            }}
                            value={
                              fileRegistrationObject.religiousPlaceRegNumber
                            }
                          />
                          <Form.Label className="mt-3">
                            {`Religious Place Name`}
                            <span className="required-field-astrix">*</span>
                          </Form.Label>
                          <Form.Control
                            isInvalid={!!errors.religiousPlaceNameError}
                            type="text"
                            placeholder={`Enter Religious Place Name`}
                            onChange={(e) => {
                              const newFileRegistrationObject = produce(
                                fileRegistrationObject,
                                (draft: FileRegistrationModel) => {
                                  draft.religiousPlaceName = e.target.value
                                }
                              )
                              setFileRegistrationObject(
                                newFileRegistrationObject
                              )
                              const clearError = produce(errors, (draft) => {
                                draft.religiousPlaceNameError = ''
                              })
                              setErrors(clearError)
                            }}
                            value={fileRegistrationObject.religiousPlaceName}
                          />

                          <Form.Control.Feedback type="invalid">
                            {errors.religiousPlaceNameError}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                    )}
                  </Col>
                )}
            </Form>
          )}
          {!searchState && (
            <Col sm={12} className="d-flex justify-content-end mt-4">
              <button className="cancel-button" onClick={handleReset}>
                Clear
              </button>
              <button
                disabled={isProcessing}
                className="save-button"
                onClick={handleSearchDoubleClick}
              >
                Search
              </button>
            </Col>
          )}
          <Col sm={12} className="border-label mt-4 mb-4">
            <div className="border-label-span">{`Applications with same Identification Details`}</div>
            {similarRecordList.length > 0 && (
              <div className="row justify-content-start">
                <div className="col-3">
                  <span className="similar-record-found-span">
                    Similar Records Found
                  </span>
                </div>
              </div>
            )}
            <Table striped bordered hover size="sm" className="mt-4">
              <thead className="custom-table-header">
                <tr>
                  <th>Application Reference</th>
                  <th>Program Type</th>
                  <th>Scheme</th>
                  <th>Applicant Name</th>
                  <th>Created Date/Time</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody className="custom-table-body">
                {similarRecordList.map((record: any) => (
                  <tr
                    key={record.id}
                    onClick={() => {
                      handleViewSimilarRecord(record)
                    }}
                  >
                    <td className="td">{handleReferenceNo(record)}</td>
                    <td className="td">{record.programType}</td>
                    <td className="td">{record.scheme}</td>
                    <td className="td">{record.applicantName}</td>
                    <td className="td">
                      {fileRegistrationObject.status === 'File Drafted' ||
                      fileRegistrationObject.status === 'File Returned'
                        ? record.createDateAndTime == null
                          ? 'N/A'
                          : new Date(
                              record.createDateAndTime * 1000
                            ).toLocaleString()
                        : record.createdDateTime == null
                        ? 'N/A'
                        : new Date(
                            record.createdDateTime * 1000
                          ).toLocaleString()}
                    </td>
                    <td className="td">{record.status}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Col>
        {searchState && (
          <>
            <Col sm={12} className="border-label mt-4 mb-4">
              <div className="border-label-span">{`Other Details`}</div>
              <Row className="mb-3 align-items-baseline">
                <Form.Group as={Col} controlId="formProvince">
                  <Form.Label>
                    Province<span className="required-field-astrix">*</span>
                  </Form.Label>
                  <Select
                    isDisabled={isReturn ? true : false}
                    value={provinceList.filter((province: ProvinceOption) => {
                      return (
                        province.value === fileRegistrationObject.provinceId
                      )
                    })}
                    styles={
                      errors.applicantProvinceError ? customStyles : undefined
                    }
                    options={provinceList}
                    onChange={(selectedOption) => {
                      const newFileRegistrationObject = produce(
                        fileRegistrationObject,
                        (draft: FileRegistrationModel) => {
                          draft.provinceId = selectedOption
                            ? selectedOption.value
                            : 0
                          draft.districtId = 0
                          draft.dsDivisionId = 0
                          draft.gnDivisionId = 0
                        }
                      )
                      setFileRegistrationObject(newFileRegistrationObject)
                      const clearError = produce(errors, (draft) => {
                        draft.applicantProvinceError = ''
                      })
                      setErrors(clearError)
                    }}
                  />
                  <span className="feedback-error-span-custom">
                    {errors.applicantProvinceError}
                  </span>
                </Form.Group>
                <Form.Group as={Col} controlId="formDistrict">
                  <Form.Label>
                    District<span className="required-field-astrix">*</span>
                  </Form.Label>
                  <Select
                    value={districtList.filter((district: DistrictOption) => {
                      return (
                        district.value === fileRegistrationObject.districtId
                      )
                    })}
                    styles={
                      errors.applicantDistrictError ? customStyles : undefined
                    }
                    isDisabled={
                      fileRegistrationObject.provinceId === undefined ||
                      fileRegistrationObject.provinceId === 0 ||
                      (isReturn ? true : false)
                    }
                    options={districtList.filter((district: DistrictOption) => {
                      return (
                        district.provinceId ===
                        fileRegistrationObject.provinceId
                      )
                    })}
                    onChange={(selectedOption) => {
                      apiGetActiveUserList()
                      const newFileRegistrationObject = produce(
                        fileRegistrationObject,
                        (draft: FileRegistrationModel) => {
                          draft.districtId = selectedOption
                            ? selectedOption.value
                            : 0
                          draft.dsDivisionId = 0
                          draft.gnDivisionId = 0
                        }
                      )
                      setFileRegistrationObject(newFileRegistrationObject)
                      const clearError = produce(errors, (draft) => {
                        draft.applicantDistrictError = ''
                      })
                      setErrors(clearError)
                    }}
                  />
                  <span className="feedback-error-span-custom">
                    {errors.applicantDistrictError}
                  </span>
                </Form.Group>
                <Form.Group as={Col} controlId="formDsDivision">
                  <Form.Label>
                    {`DS Division`}
                    <span className="required-field-astrix">*</span>
                  </Form.Label>
                  <Select
                    value={dsList.filter((ds: DsOption) => {
                      return ds.value === fileRegistrationObject.dsDivisionId
                    })}
                    styles={errors.applicantDSError ? customStyles : undefined}
                    isDisabled={
                      fileRegistrationObject.districtId === undefined ||
                      fileRegistrationObject.districtId === 0 ||
                      (isReturn ? true : false)
                    }
                    options={dsList.filter((ds: DsOption) => {
                      return ds.districtId === fileRegistrationObject.districtId
                    })}
                    onChange={(selectedOption) => {
                      const newFileRegistrationObject = produce(
                        fileRegistrationObject,
                        (draft: FileRegistrationModel) => {
                          draft.dsDivisionId = selectedOption
                            ? selectedOption.value
                            : 0
                          draft.allocateToTypeOption =
                            {} as AllocateToTypeOption
                          draft.allocateTo = ''
                          draft.gnDivisionId = 0
                        }
                      )
                      setFileRegistrationObject(newFileRegistrationObject)
                      getAllocateUsers(
                        selectedOption ? selectedOption.value : 0
                      )
                      const clearError = produce(errors, (draft) => {
                        draft.applicantDSError = ''
                      })
                      setErrors(clearError)
                    }}
                  />
                  <span className="feedback-error-span-custom">
                    {errors.applicantDSError}
                  </span>
                </Form.Group>
                <Form.Group as={Col} controlId="formGnDivision">
                  <Form.Label>
                    {`GN Division`}
                    <span className="required-field-astrix">*</span>
                  </Form.Label>
                  <Select
                    value={gnList.filter((gn: GnOption) => {
                      return gn.value === fileRegistrationObject.gnDivisionId
                    })}
                    styles={errors.applicantGNError ? customStyles : undefined}
                    isDisabled={
                      fileRegistrationObject.dsDivisionId === undefined ||
                      fileRegistrationObject.dsDivisionId === 0 ||
                      (isReturn ? true : false)
                    }
                    options={gnList.filter((gn: GnOption) => {
                      return gn.dsId === fileRegistrationObject.dsDivisionId
                    })}
                    onChange={(selectedOption) => {
                      const newFileRegistrationObject = produce(
                        fileRegistrationObject,
                        (draft: FileRegistrationModel) => {
                          draft.gnDivisionId = selectedOption
                            ? selectedOption.value
                            : 0
                        }
                      )
                      setFileRegistrationObject(newFileRegistrationObject)
                      const clearError = produce(errors, (draft) => {
                        draft.applicantGNError = ''
                      })
                      setErrors(clearError)
                    }}
                  />
                  <span className="feedback-error-span-custom">
                    {errors.applicantGNError}
                  </span>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group className="mb-3" controlId="formAppliedDate">
                  <Form.Label>
                    Applied Date
                    <span className="required-field-astrix">*</span>
                  </Form.Label>
                  <DatePicker
                    disabled={!isModify && isReturn ? true : false}
                    maxDate={new Date()}
                    placeholderText="Enter Applied Date"
                    className={
                      errors.appliedDateError
                        ? 'date-picker-validate'
                        : 'form-control'
                    }
                    selected={
                      fileRegistrationObject.appliedDate != null
                        ? new Date(fileRegistrationObject.appliedDate * 1000)
                        : null
                    }
                    onChange={(date: any) => {
                      const newFileRegistrationObject = produce(
                        fileRegistrationObject,
                        (draft: FileRegistrationModel) => {
                          draft.appliedDate = date
                            ? date.getTime() / 1000
                            : null
                        }
                      )
                      setFileRegistrationObject(newFileRegistrationObject)
                      const clearError = produce(errors, (draft) => {
                        draft.appliedDateError = ''
                      })
                      setErrors(clearError)
                    }}
                  />
                  <span className="feedback-error-span-custom">
                    {errors.appliedDateError}
                  </span>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formFileRecievedDate">
                  <Form.Label>
                    File Received Date
                    <span className="required-field-astrix">*</span>
                  </Form.Label>
                  <DatePicker
                    disabled={!isModify && isReturn ? true : false}
                    maxDate={new Date()}
                    placeholderText="Enter File Received Date"
                    className={
                      errors.fileReceivedDateError
                        ? 'date-picker-validate'
                        : 'form-control'
                    }
                    selected={
                      fileRegistrationObject.fileReceivedDate != null
                        ? new Date(
                            fileRegistrationObject.fileReceivedDate * 1000
                          )
                        : null
                    }
                    onChange={(date: any) => {
                      const newFileRegistrationObject = produce(
                        fileRegistrationObject,
                        (draft: FileRegistrationModel) => {
                          draft.fileReceivedDate = date
                            ? date.getTime() / 1000
                            : null
                        }
                      )
                      setFileRegistrationObject(newFileRegistrationObject)
                      const clearError = produce(errors, (draft) => {
                        draft.fileReceivedDateError = ''
                      })
                      setErrors(clearError)
                    }}
                  />
                  <span className="feedback-error-span-custom">
                    {errors.fileReceivedDateError}
                  </span>
                </Form.Group>
              </Row>
              {checkPermission('allocate', actionList) && !isReturn && (
                <Row className="mb-3">
                  <Col xs={11}>
                    <Form.Group className="mb-3" controlId="formAllocate">
                      <Form.Label>
                        Allocate{' '}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Select
                        styles={
                          errors.allocateToError ? customStyles : undefined
                        }
                        isDisabled={
                          fileRegistrationObject.gnDivisionId === undefined
                        }
                        options={userList}
                        placeholder={'--Select User--'}
                        value={fileRegistrationObject.allocateToTypeOption}
                        onChange={(selectedOption) => {
                          const newFileRegistrationObject = produce(
                            fileRegistrationObject,
                            (draft: FileRegistrationModel) => {
                              draft.allocateTo = selectedOption
                                ? selectedOption.value
                                : ''
                              draft.allocateToTypeOption = selectedOption
                                ? selectedOption
                                : ({} as AllocateToTypeOption)
                            }
                          )
                          setFileRegistrationObject(newFileRegistrationObject)
                          const clearError = produce(errors, (draft) => {
                            draft.allocateToError = ''
                          })
                          setErrors(clearError)
                        }}
                      />
                      <span className="feedback-error-span-custom">
                        {errors.allocateToError}
                      </span>
                    </Form.Group>
                  </Col>
                  <Col xs={1} className="d-flex mt-4 justify-content-end">
                    <button
                      className="action-button-layout"
                      onClick={() => {
                        const newFileRegistrationObject = produce(
                          fileRegistrationObject,
                          (draft: FileRegistrationModel) => {
                            draft.allocateToTypeOption =
                              {} as AllocateToTypeOption
                            draft.allocateTo = ''
                          }
                        )
                        setFileRegistrationObject(newFileRegistrationObject)
                      }}
                    >
                      Reset
                    </button>
                  </Col>
                </Row>
              )}
            </Col>
            <Col sm={12} className="d-flex justify-content-end mt-4">
              {isReturn ? (
                <>
                  {checkPermission('modify', actionListFileReturn) && (
                    <button className="save-button" onClick={handleModify}>
                      Modify
                    </button>
                  )}
                </>
              ) : (
                <>
                  {/* <button className="cancel-button" onClick={handleReset}>
                    Clear
                  </button> */}
                  <button
                    className="save-button"
                    onClick={() => {
                      if (similarRecordList.length > 0) {
                        const message =
                          'Do you wish to Proceed application with similar records?'
                        const section = 'proceed'
                        setShowConfirm({
                          isShow: true,
                          message: message,
                          section: section,
                          isRemark: true,
                        })
                      } else {
                        handleProceed()
                      }
                    }}
                  >
                    Proceed
                  </button>
                </>
              )}
              <button
                className="save-button"
                onClick={() => {
                  const message = 'Do you wish to Cancel application ?'
                  const section = 'cancel'
                  setShowConfirm({
                    isShow: true,
                    message: message,
                    section: section,
                    isRemark: true,
                  })
                }}
              >
                Cancel
              </button>
            </Col>
          </>
        )}
      </Row>
      {showModal && (
        <DialogModal
          name="File Creation"
          body={
            <div className="custom-dialog-body">
              <p>File Created Successfully</p>
              <p>
                {`Application Number : ${fileRegistrationObject.referenceNumber}`}
              </p>
            </div>
          }
          otherButtons={
            <button
              className="save-button"
              onClick={(e) => {
                e.preventDefault()
                window.location.reload(false)
              }}
            >
              New Applicaton
            </button>
          }
          onOk={() => {
            history.goBack()
          }}
        />
      )}
      {showConfirm.isShow && (
        <ConfirmationModal
          name={'Confirmation'}
          message={showConfirm.message}
          onCancel={() => {
            setShowConfirm({
              isShow: false,
              message: '',
              section: '',
              isRemark: false,
            })
          }}
          isReason={false}
          isRemark={showConfirm.isRemark}
          onConfirm={(remark: string) => {
            handleConfirm(remark)
          }}
        />
      )}
      {showSimilarModal && (
        <SimlarRecordViewModal
          similarRecord={similarRecord}
          onCancel={() => {
            setShowSimilarModal(false)
          }}
        />
      )}
    </>
  )
}

export default Add
