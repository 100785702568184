import React, { useEffect, useState } from 'react'
import Package from '../../../components/common/allPermissions/Package'
import { PackageModal } from '../../../models/package/packageModal'
import userManagementService from '../../../services/userManagementService'
import './RolePermissionsComponent.scss'
export interface RoleIdProp {
  id: number
}
const RolePermissionsComponent: React.FC<RoleIdProp> = ({ id }) => {
  const [rolePermissionsList, setRolePermissionsList] = useState([])
  const [checkAllPermissions, setCheckAllPermissions] = useState(Boolean)

  useEffect(() => {
    async function getRolePermissionList() {
      const rolePermissionData =
        await userManagementService.apiRolePermissionListFetch(id)
      setCheckAllPermissions(rolePermissionData.data.allPermissions)
      if (checkAllPermissions == false) {
        try {
          setRolePermissionsList(
            rolePermissionData.data.permissions.packageList
          )
        } catch {
          console.log('All Permissions Granted to This Role')
        }
      }
    }

    getRolePermissionList()
  }, [])

  if (checkAllPermissions == false) {
    return (
      <>
        {rolePermissionsList.map((pack: PackageModal, index: number) => (
          <ul key={pack.id}>
            <Package packageModal={pack} packageIndex={index} mode={true} />
          </ul>
        ))}
      </>
    )
  } else {
    return (
      <h5 className="font-color-green">
        All Permissions Granted to This Role{' '}
      </h5>
    )
  }
}

export default RolePermissionsComponent
