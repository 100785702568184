import produce from 'immer'
import React, { useEffect, useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { RouteComponentProps } from 'react-router-dom'
import Select from 'react-select'
import AdministrativeArea from '../../../components/common/administrativeAreaWithRedux/AdministrativeArea'
import LoadingComponent from '../../../components/common/loading/LoadingComponent'
import { RoleIdModel } from '../../../models/user/roleIdModel'
import { RoleListModal } from '../../../models/user/roleListModel'
import { UserModal } from '../../../models/usermanagement/userModel'
import metaDataService from '../../../services/metaDataService'
import userManagementService from '../../../services/userManagementService'
import {
  clearAdminsitrativeAreeLisRequest,
  setModifyAdministrativeAreaListRequest,
} from '../../../store/administrativeArea/actions'
import { getSelectedDsList } from '../../../store/administrativeArea/selectors'
import * as errorMessages from '../../../utils/errorMessages'
import * as constants from '../../../utils/constants'
import { showTotast } from '../../../utils/toast'
import * as validation from './validations'
import './AddUserComponent.scss'
import { FormErrorModel } from '../../../models/user/formErrorModel'
import ConfirmationModal from '../../../components/modals/ConfirmationModal'
import { userUpdate } from '../../../utils/successMessages'
import { IRole } from '../../../store/usermanagement/actionTypes'
import { handleNotification } from '../../../utils/utilFunctions'
import { changeLoadingRequest } from '../../../store/app/actionTypes'
import { DSListModel } from '../../../models/administrativeArea/dsListModel'
import { DSDivisionModal } from '../../../models/administrativeArea/dsDivisionModal'

const initiateUserModel = {} as UserModal
type TParams = { epfNumber: string }
const ModifyUserComponent = ({ match }: RouteComponentProps<TParams>) => {
  const [loading, setloading] = useState(true)
  const [isValid, setisValid] = useState(true)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [errors, setErrors] = useState({} as FormErrorModel)
  const [designationList, setDesignationList] = useState([])
  const [organizationList, setOrganizationList] = useState([])
  const [titleList, setTitleList] = useState([])
  const roleOption: constants.RoleOption[] = []
  const [roleOptions, setRoleOptions] = useState(roleOption)
  const [userRoles, setUserRoles] = useState(roleOption)
  const [user, setuser] = useState(initiateUserModel)
  const dsList = useSelector(getSelectedDsList)
  const history = useHistory()
  const dispatch = useDispatch()
  useEffect(() => {
    async function getMetaData() {
      const checkLock = await userManagementService.apiUserLock(
        match.params.epfNumber
      )

      if (checkLock.status === 400) {
        handleNotification(checkLock, '')
        history.goBack()
      }

      const userData = await userManagementService.apiFetchUserDataByEpfNumber(
        match.params.epfNumber
      )

      const designatationData = await metaDataService.apiDesignationFetch()
      const organizationData = await metaDataService.apiOrganizationFetch()
      const titleData = await metaDataService.apiTitleListFetch()
      const roleData = await metaDataService.apiGetRolesList()
      setDesignationList(designatationData.data)
      setOrganizationList(organizationData.data)
      setTitleList(titleData.data)
      const activatedRoles = roleData.data.filter((role: IRole) => {
        return role.status == 'Active'
      })
      const roleOptionList: constants.RoleOption[] = []
      for (let i = 0; i < activatedRoles.length; i++) {
        const roleOption: constants.RoleOption = {
          value: activatedRoles[i].id,
          label: activatedRoles[i].name,
        }
        roleOptionList.push(roleOption)
      }

      const userRoleList: constants.RoleOption[] = []
      for (let i = 0; i < userData.data.roleList.length; i++) {
        const roleOption: constants.RoleOption = {
          value: userData.data.roleList[i].role.id,
          label: userData.data.roleList[i].role.name,
        }
        userRoleList.push(roleOption)
      }

      setuser(userData.data)
      setRoleOptions(roleOptionList)
      setUserRoles(userRoleList)
      setloading(false)
      dispatch(
        setModifyAdministrativeAreaListRequest({
          administrativeAreaList: userData.data.userAdministrativeAreas,
        })
      )
    }

    getMetaData()
    return () => {
      dispatch(clearAdminsitrativeAreeLisRequest())
    }
  }, [])

  const findFormErrors = () => {
    const newErrors = {} as FormErrorModel
    if (validation.validatefirstName(user.firstName)) {
      newErrors.firstNameError = validation.validatefirstName(user.firstName)
    }
    if (validation.validatelastName(user.lastName)) {
      newErrors.lastNameError = validation.validatelastName(user.lastName)
    }
    if (validation.validateEpfNumber(user.epfNumber)) {
      newErrors.epfNumberError = validation.validateEpfNumber(user.epfNumber)
    }
    if (validation.validateEmail(user.email)) {
      newErrors.emailError = validation.validateEmail(user.email)
    }
    if (validation.validateMobileNumber(user.mobileNumber)) {
      newErrors.mobileNumberError = validation.validateMobileNumber(
        user.mobileNumber
      )
    }
    if (validation.validateRoleList(user.roleList)) {
      newErrors.roleListError = validation.validateRoleList(user.roleList)
      setisValid(false)
    }
    if (validation.validateAdministrativeAreaList(dsList)) {
      newErrors.userAdministrativeAreasError =
        validation.validateAdministrativeAreaList(dsList)
    }

    return newErrors
  }
  useEffect(() => {
    console.log(dsList)
    if (dsList.length === 0) {
      const clearError = produce(errors, (draft) => {
        draft.userAdministrativeAreasError =
          errorMessages.administrativeAreaListEmpty
      })
      setErrors(clearError)
    } else if (dsList.length > 0) {
      const clearError = produce(errors, (draft) => {
        draft.userAdministrativeAreasError = ''
      })
      setErrors(clearError)
    }
  }, [dsList])
  const buttonClickSubmit = async () => {
    const foundErrros = findFormErrors()
    if (Object.keys(foundErrros).length > 0) {
      setErrors(foundErrros)
      showTotast(
        constants.ALERT_WARNING,
        errorMessages.validationError,
        constants.TOAST_POSITION_TOP_RIGHT,
        5,
        constants.TOAST_TRANSITION_SLIDE
      )
    } else {
      setErrors(foundErrros)
      setShowConfirmation(true)
    }
  }
  const handleConfirm = async () => {
    document.getElementById('confirmation-ok').disabled = true
    const defaultSelectedRoles: RoleListModal[] = []
    for (let i = 0; i < user.roleList.length; i++) {
      const roleObject = { role: {} as RoleIdModel } as RoleListModal
      roleObject.role.id = user.roleList[i].role.id

      defaultSelectedRoles.push(roleObject)
    }

    const dsListArray: DSListModel[] = []
    for (let i = 0; i < dsList.length; i++) {
      const dsListObject = {
        dsDivision: {} as DSDivisionModal,
      } as DSListModel
      dsListObject.dsDivision.id = dsList[i].id
      dsListArray.push(dsListObject)
    }

    const submitObject = produce(user, (draft: UserModal) => {
      draft.administrativeAreaList = dsListArray
      draft.roleList = defaultSelectedRoles
    })
    dispatch(changeLoadingRequest())
    const result = await userManagementService.apiUserUpdate(
      submitObject.epfNumber,
      submitObject
    )
    dispatch(changeLoadingRequest())
    let success = false
    let message = result.errorMessage ? result.errorMessage : ''
    if (result.status === 200) {
      await userManagementService.apiUserUnLock(match.params.epfNumber)
      success = true
      message = userUpdate
      setTimeout(history.goBack, 1500)
    }
    showTotast(
      success ? constants.ALERT_SUCCESS : constants.ALERT_WARNING,
      message,
      constants.TOAST_POSITION_TOP_RIGHT,
      5,
      constants.TOAST_TRANSITION_SLIDE
    )
  }
  const handleBack = async () => {
    await userManagementService.apiUserUnLock(match.params.epfNumber)
    history.goBack()
  }
  const customStyles = {
    control: (base: any, state: { isFocused: any }) => ({
      ...base,
      // state.isFocused can display different borderColor if you need it
      borderColor: state.isFocused ? '#ddd' : isValid ? '#ddd' : 'red',
      // overwrittes hover style
      '&:hover': {
        borderColor: state.isFocused ? '#ddd' : isValid ? '#ddd' : 'red',
      },
    }),
  }
  const handleMultiselct = (selectedOption: any) => {
    setUserRoles(selectedOption)
    const selectedRoles: RoleListModal[] = []
    for (let i = 0; i < selectedOption.length; i++) {
      const roleObject = { role: {} as RoleIdModel } as RoleListModal
      roleObject.role.id = selectedOption[i].value
      selectedRoles.push(roleObject)
    }

    const newUser = produce(user, (draft: UserModal) => {
      draft.roleList = selectedRoles
    })
    setuser(newUser)
  }
  return (
    <>
      {loading ? (
        <Container className="user-loading-layout">
          <LoadingComponent />
        </Container>
      ) : (
        <Row className="create-user-page-layout">
          <Col sm={12} className="border-label">
            <div className="border-label-span">Modify User</div>
            <Form>
              <Form.Group className="mb-3" controlId="formGroupTitle">
                <Form.Label>
                  Title <span className="required-field-astrix">*</span>
                </Form.Label>
                <Form.Control
                  value={user.title}
                  className="form-select"
                  as="select"
                  onChange={(e) => {
                    const newFormValue = produce(user, (draft: UserModal) => {
                      draft.title = e.target.value
                    })
                    setuser(newFormValue)
                  }}
                >
                  {titleList.map(
                    (title: { name: string; id: number }, index: number) => {
                      return (
                        <option key={`country-${index}`} value={title.name}>
                          {title.name}
                        </option>
                      )
                    }
                  )}
                </Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupFirstName">
                <Form.Label>
                  First Name <span className="required-field-astrix">*</span>
                </Form.Label>
                <Form.Control
                  isInvalid={!!errors.firstNameError}
                  placeholder="Enter First Name"
                  onBlur={(e) => {
                    const newError = produce(errors, (draft) => {
                      draft.firstNameError = validation.validatefirstName(
                        e.target.value
                      )
                    })
                    setErrors(newError)
                  }}
                  type="text"
                  value={user.firstName}
                  onChange={(e) => {
                    const newFormValue = produce(user, (draft: UserModal) => {
                      draft.firstName = e.target.value
                    })
                    setuser(newFormValue)
                    const clearError = produce(errors, (draft) => {
                      draft.firstNameError = ''
                    })
                    setErrors(clearError)
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.firstNameError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupLastName">
                <Form.Label>
                  Last Name <span className="required-field-astrix">*</span>
                </Form.Label>
                <Form.Control
                  isInvalid={!!errors.lastNameError}
                  onBlur={(e) => {
                    const newError = produce(errors, (draft) => {
                      draft.lastNameError = validation.validatelastName(
                        e.target.value
                      )
                    })
                    setErrors(newError)
                  }}
                  placeholder="Enter Last Name"
                  type="text"
                  value={user.lastName}
                  onChange={(e) => {
                    const newFormValue = produce(user, (draft: UserModal) => {
                      draft.lastName = e.target.value
                    })
                    setuser(newFormValue)
                    const clearError = produce(errors, (draft) => {
                      draft.lastNameError = ''
                    })
                    setErrors(clearError)
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.lastNameError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupEmailAddress">
                <Form.Label>
                  Email Address <span className="required-field-astrix">*</span>
                </Form.Label>
                <Form.Control
                  isInvalid={!!errors.emailError}
                  onBlur={(e) => {
                    const newError = produce(errors, (draft) => {
                      draft.emailError = validation.validateEmail(
                        e.target.value
                      )
                    })
                    setErrors(newError)
                  }}
                  placeholder="Enter Email Address"
                  type="email"
                  value={user.email}
                  onChange={(e) => {
                    const newFormValue = produce(user, (draft: UserModal) => {
                      draft.email = e.target.value
                    })
                    setuser(newFormValue)
                    const clearError = produce(errors, (draft) => {
                      draft.emailError = ''
                    })
                    setErrors(clearError)
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.emailError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupMobileNumber">
                <Form.Label>
                  Mobile Number <span className="required-field-astrix">*</span>
                </Form.Label>
                <Form.Control
                  onBlur={(e) => {
                    const newError = produce(errors, (draft) => {
                      draft.mobileNumberError = validation.validateMobileNumber(
                        e.target.value
                      )
                    })
                    setErrors(newError)
                  }}
                  isInvalid={!!errors.mobileNumberError}
                  placeholder="Enter Mobile Number"
                  type="text"
                  maxLength={15}
                  value={user.mobileNumber}
                  onChange={(e) => {
                    const newFormValue = produce(user, (draft: UserModal) => {
                      draft.mobileNumber = e.target.value
                    })
                    setuser(newFormValue)
                    const clearError = produce(errors, (draft) => {
                      draft.mobileNumberError = ''
                    })
                    setErrors(clearError)
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.mobileNumberError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupOrganization">
                <Form.Label>
                  Organization <span className="required-field-astrix">*</span>
                </Form.Label>
                <Form.Control
                  value={user.organizationId}
                  className="form-select"
                  as="select"
                  onChange={(e) => {
                    const newFormValue = produce(user, (draft: UserModal) => {
                      draft.organizationId = parseInt(e.target.value)
                    })
                    setuser(newFormValue)
                  }}
                >
                  {organizationList.map(
                    (org: { name: string; id: number }, index: number) => {
                      return (
                        <option key={`org-${index}`} value={org.id}>
                          {org.name}
                        </option>
                      )
                    }
                  )}
                </Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupDesignation">
                <Form.Label>
                  Designation <span className="required-field-astrix">*</span>
                </Form.Label>
                <Form.Control
                  value={user.designationId}
                  className="form-select"
                  as="select"
                  onChange={(e) => {
                    const newFormValue = produce(user, (draft: UserModal) => {
                      draft.designationId = parseInt(e.target.value)
                    })
                    setuser(newFormValue)
                  }}
                >
                  {designationList.map(
                    (dsg: { name: string; id: number }, index: number) => {
                      return (
                        <option key={`org-${index}`} value={dsg.name}>
                          {dsg.name}
                        </option>
                      )
                    }
                  )}
                </Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="Roles">
                <Form.Label>
                  Roles <span className="required-field-astrix">*</span>
                </Form.Label>
                <Select
                  styles={customStyles}
                  isMulti
                  value={userRoles}
                  options={roleOptions}
                  onChange={(selectedOption) => {
                    handleMultiselct(selectedOption)
                    selectedOption.length === 0
                      ? setisValid(false)
                      : setisValid(true)
                    const newError = produce(errors, (draft) => {
                      draft.roleListError =
                        validation.validateRoleList(selectedOption)
                    })
                    setErrors(newError)
                  }}
                />
                <span className="feedback-error-span">
                  {errors.roleListError}
                </span>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formGroupAdminAreaList">
                <Form.Label>
                  Administrative Area List
                  <span className="required-field-astrix">*</span>
                </Form.Label>
                <br />
                <span className="feedback-error-span">
                  {errors.userAdministrativeAreasError}
                </span>
                <Row className="add-admin-area-list">
                  <Col sm={12}>
                    <AdministrativeArea />
                  </Col>
                </Row>
              </Form.Group>
            </Form>
          </Col>
          <Col sm={12} className="d-flex justify-content-end mt-4">
            <button className="cancel-button" onClick={handleBack}>
              Cancel
            </button>
            <button className="save-button" onClick={buttonClickSubmit}>
              Save
            </button>
          </Col>
          {showConfirmation && (
            <ConfirmationModal
              name={'Confirmation'}
              message={'Are you sure to update this user ?'}
              onCancel={() => {
                setShowConfirmation(false)
              }}
              onConfirm={handleConfirm}
            />
          )}
        </Row>
      )}
    </>
  )
}

export default ModifyUserComponent
