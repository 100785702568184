import {
  ChangeLoadingRequest,
  ChangeNotificationCountRequest,
  SET_APP_STATE_LOADING,
  SET_APP_STATE_NOTIFICATION_COUNT,
} from './actions'

export const changeLoadingRequest = (): ChangeLoadingRequest => ({
  type: SET_APP_STATE_LOADING,
})

export const changeNotificationCountRequest = (
  payload: number
): ChangeNotificationCountRequest => ({
  type: SET_APP_STATE_NOTIFICATION_COUNT,
  payload,
})
