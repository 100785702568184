/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect, useState } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import LoadingComponent from '../common/loading/LoadingComponent'
import GpcService from '../../services/applicationRegistration/compensation/gpcService'
import GscService from '../../services/applicationRegistration/compensation/gscService'
import RlpService from '../../services/applicationRegistration/compensation/rlpService'
import { AffectedPropertyModel } from '../../models/applicationRegistration/compensation/affectedPropertyModel'
import ReactTableWithPaginationComponent from '../table/ReactTableWithPaginationComponent'
import { FileRegistrationModel } from '../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { currencyMask, thousandSeperator } from '../../utils/utilFunctions'
import common from '../../services/applicationRegistration/common'
import enquireApplicationService from '../../services/enquireApplication/enquireApplicationService'
import { ResponseObject } from '../../services/api/apiManager'
import metaDataService from '../../services/metaDataService'
import { InstallmentModel } from '../../models/applicationRegistration/compensation/installmentModel'
import { PropertyDamageTypeModel } from '../../models/systemConfiguration/propertyDamageType/propertyDamageTypeModel'
import PropertyArea from '../property/PropertyArea'
export interface AffectedPropertyProps {
  fileRegistrationObject: FileRegistrationModel
  packageName?: string
  onOk?: any
}
const SectionAffectedProperty: FC<AffectedPropertyProps> = ({
  fileRegistrationObject,
  packageName = 'application-registration',
  onOk,
}) => {
  const [loading, setLoading] = useState(true)
  const [affectedProperty, setAffectedProperty] = useState(
    {} as AffectedPropertyModel
  )
  const [installmentList, setInstallmentList] = useState(
    [] as InstallmentModel[]
  )
  const [propertyTypeList, setPropertyTypeList] = useState(
    [] as PropertyDamageTypeModel[]
  )
  useEffect(() => {
    async function getData() {
      let affectedPropertyData = {} as ResponseObject
      if (packageName === 'enquire-application') {
        affectedPropertyData =
          await enquireApplicationService.apiGetAffectedPropertyData(
            fileRegistrationObject.id,
            fileRegistrationObject.scheme.toLowerCase()
          )
      } else {
        affectedPropertyData = await common.apiGetAffectedPropertyData(
          packageName,
          fileRegistrationObject.programType.toLowerCase(),
          fileRegistrationObject.scheme.toLowerCase(),
          fileRegistrationObject.id
        )
      }
      if (affectedPropertyData.status === 200) {
        const affectedPropertyDetails: AffectedPropertyModel =
          affectedPropertyData.data
        affectedPropertyDetails.propertyDamageList = JSON.parse(
          affectedPropertyDetails.propertyDamageData
        )
        setAffectedProperty(affectedPropertyDetails)
      }
      const installmentData =
        await metaDataService.getApplicationInstallmentAmountList(
          fileRegistrationObject.id
        )

      if (installmentData.status === 200) {
        const sortedIntallment = installmentData.data.sort((a, b) => {
          return a.installmentNumber - b.installmentNumber
        })
        setInstallmentList(sortedIntallment)
      }
      setLoading(false)

      const propertyTypeData = await metaDataService.getPropertyTypesByScheme(
        fileRegistrationObject.scheme
      )
      if (propertyTypeData.status == 200) {
        const filterdProperty = propertyTypeData.data.filter(
          (data: { status: string }) => {
            return data.status == 'Active'
          }
        )
        setPropertyTypeList(filterdProperty)
      }
    }
    getData()
  }, [])
  const [oRRecommendedAmount, setORRecommendedAmount] = useState(0)
  useEffect(() => {
    if (
      fileRegistrationObject.scheme == 'GPC' ||
      fileRegistrationObject.scheme == 'GSC'
    ) {
      if (affectedProperty.propertyDamageList) {
        calORRecommendedAmount()
      }
    }
  }, [affectedProperty.propertyDamageList])

  const calORRecommendedAmount = () => {
    const dataArray = [] as any[]
    for (let i = 0; i < affectedProperty.propertyDamageList.length; i++) {
      if (affectedProperty.propertyDamageList[i]['Value Approved by the OR']) {
        const dataObj = {} as any
        dataObj.id = affectedProperty.propertyDamageList[i].propertyId
        const valueWithComma =
          affectedProperty.propertyDamageList[i]['Value Approved by the OR']
        const valueWithoutCommas = valueWithComma.replace(/,/g, '')
        dataObj.value = parseFloat(valueWithoutCommas)
        dataArray.push(dataObj)
      }
    }

    const sum = dataArray.reduce((accumulator, object) => {
      return accumulator + object.value
    }, 0)
    setORRecommendedAmount(sum)
  }

  const propertyDamageListColumns = React.useMemo(
    () => [
      {
        Header: 'Asset Type',
        accessor: 'assetType',
      },
      {
        Header: 'Vehicle Type',
        accessor: 'vehicleType',
      },
      {
        Header: 'Vehicle Number',
        accessor: 'vehicleNumber',
      },
      {
        Header: 'Item Name',
        accessor: 'itemName',
      },
      {
        Header: 'Fate of Purchase',
        accessor: 'dateOfPurchase',
      },
      {
        Header: 'Purchase Price',
        accessor: 'purchasePrice',
      },
      {
        Header: 'Estimated Value',
        accessor: 'estimatedValue',
      },
    ],
    []
  )

  const renderMergedInstallmentByMegeNumber = (mergeNumber: number) => {
    const mergedInstallment = installmentList.filter(
      (installment: InstallmentModel) => {
        if (installment.mergeNumber === mergeNumber) {
          return installment
        }
      }
    )

    return mergedInstallment.map(
      (installment: InstallmentModel, index: number) => {
        return (
          <div
            className={`${index != mergedInstallment.length - 1 ? 'mb-3' : ''}`}
            key={`installment-merge-${index}`}
          >
            {`Installment ${
              installment.installmentNumber
            } Rs. ${thousandSeperator(installment.installmentAmount)}`}
          </div>
        )
      }
    )
  }

  const checkPreviousHasMergeSame = (index: number, mergeNumber: number) => {
    if (index == 0 || mergeNumber == 0) {
      return false
    }
    const previousInstallment = installmentList[index - 1]
    if (previousInstallment.mergeNumber === mergeNumber) {
      return true
    }

    return false
  }

  const renderInstallmentList = () => {
    return installmentList.map(
      (installment: InstallmentModel, index: number) => {
        return (
          <div key={`installment-${installment.id}`}>
            {!checkPreviousHasMergeSame(index, installment.mergeNumber) && (
              <ul key={`installment-ul-${installment.id}`}>
                {installment.mergeNumber == 0 && (
                  <Form.Check
                    key={`installment-check-${installment.id}`}
                    label={`Installment ${installment.installmentNumber} Rs. ${
                      installment.installmentAmount
                        ? currencyMask(installment.installmentAmount)
                        : ''
                    }`}
                    name={`installment`}
                    className="custom-form-check"
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                    disabled
                    checked={installment.merge || installment.isChecked}
                  />
                )}
                {installment.mergeNumber > 0 && (
                  <div className="row mb-0">
                    <div className="col max-width-content">
                      <Form.Check
                        className="custom-form-check"
                        disabled={true}
                        checked={true}
                        label={renderMergedInstallmentByMegeNumber(
                          installment.mergeNumber
                        )}
                      />
                    </div>
                  </div>
                )}
              </ul>
            )}
          </div>
        )
      }
    )
  }

  const renderPropertyDamageList = () => {
    if (affectedProperty.propertyDamageList) {
      return propertyTypeList.map(
        (propertyType: PropertyDamageTypeModel, index: number) => {
          return (
            <PropertyArea
              disabled={true}
              key={`property-${index}`}
              property={propertyType}
              properties={affectedProperty.propertyDamageList.filter(
                (detail: any) => {
                  return detail.propertyId === propertyType.id
                }
              )}
            />
          )
        }
      )
    } else {
      return <span>No records not found</span>
    }
  }
  const otherAssistanceListColumns = React.useMemo(
    () => [
      {
        Header: 'Name of the Institution',
        accessor: 'institution',
      },
      {
        Header: 'Amount Received (Rs.)',
        accessor: 'amountReceived',
      },
      {
        Header: 'Received Date',
        accessor: 'receivedDate',
      },
    ],
    []
  )
  const renderotherAssistancList = () => {
    if (affectedProperty.otherAssistanceList) {
      return (
        <Col sm={12} className="border-label">
          <div className="border-label-span"> Other Assistance Details</div>
          <ReactTableWithPaginationComponent
            columns={otherAssistanceListColumns}
            data={affectedProperty.otherAssistanceList}
            onClickRow={(row: any) => {}}
          />
        </Col>
      )
    } else {
      return <span>No records not found</span>
    }
  }
  const renderRlpSection = () => {
    return (
      <Col sm={12} className="border-label">
        <div className="border-label-span">Details of the Location</div>
        <Row>
          <Form.Group className="mb-3" controlId="nameoftheWorshipPlace">
            <Form.Label>Name of the Worship Place</Form.Label>
            <Form.Control
              disabled={true}
              placeholder="Name of the Worship Place"
              value={affectedProperty.nameOfTheWorshipPlace}
            />
          </Form.Group>
          <Col>
            <Form.Group className="mb-3" controlId="province">
              <Form.Label>Province</Form.Label>
              <Form.Control
                disabled={true}
                placeholder="Province"
                value={
                  affectedProperty.province
                    ? affectedProperty.province.name
                    : ''
                }
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="district">
              <Form.Label>District</Form.Label>
              <Form.Control
                disabled={true}
                placeholder="District"
                value={
                  affectedProperty.district
                    ? affectedProperty.district.name
                    : ''
                }
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="ds_Division">
              <Form.Label>DS Division</Form.Label>
              <Form.Control
                disabled={true}
                placeholder="DS Division"
                value={
                  affectedProperty.dsDivision
                    ? affectedProperty.dsDivision.name
                    : ''
                }
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="gnDivision">
              <Form.Label>GN Division</Form.Label>
              <Form.Control
                disabled={true}
                placeholder="GN Division"
                value={
                  affectedProperty.gnDivision
                    ? affectedProperty.gnDivision.name
                    : ''
                }
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="villageOrTown">
              <Form.Label>Village/Town</Form.Label>
              <Form.Control
                disabled={true}
                placeholder="Village/Town"
                value={affectedProperty.villageOrTown}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="policeDivision">
              <Form.Label>Police Station</Form.Label>
              <Form.Control
                disabled={true}
                placeholder="Police Station"
                value={
                  affectedProperty.detailsOfLocationPoliceStation
                    ? affectedProperty.detailsOfLocationPoliceStation.name
                    : ''
                }
              />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group className="mb-3" controlId="registrationNumber">
          <Form.Label>Registration Number</Form.Label>
          <Form.Control
            disabled={true}
            placeholder="Registration Number"
            value={affectedProperty.registrationNumber}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="registeredInstitution">
          <Form.Label>Registered Institution</Form.Label>
          <Form.Control
            disabled={true}
            placeholder="Registered Institution"
            value={affectedProperty.registeredInstitution}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="placeOfTheIncidentOccurred">
          <Form.Label>Place of the Incident occurred</Form.Label>
          <Form.Control
            disabled={true}
            placeholder="Place of the Incident occurred"
            value={affectedProperty.placeOfTheIncidentOccurred}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="ds_Division">
          <Form.Label>Address of Affected Property</Form.Label>
          <Form.Control
            disabled={true}
            placeholder="Address of Affected Property"
            value={affectedProperty.addressOfTheProperty}
          />
        </Form.Group>
      </Col>
    )
  }

  const renderOtherSection = () => {
    return (
      <Col>
        <Form.Group className="mb-3" controlId="addressOfAffectedProperty">
          <Form.Label>Address of Affected Property</Form.Label>
          <Form.Control
            disabled={true}
            placeholder="Address of Affected Property"
            value={affectedProperty.addressOfTheProperty}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="placeOfTheIncidentOccurred">
          <Form.Label>Place of the incident occurred</Form.Label>
          <Form.Control
            disabled={true}
            placeholder="Place of the incident occurred"
            value={affectedProperty.placeOfTheIncidentOccurred}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="ownershipOfTheProperty">
          <Form.Label>Ownership of the Property</Form.Label>
          <Form.Control
            disabled={true}
            placeholder="Ownership of the Property"
            value={affectedProperty.ownershipOfTheProperty}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="recommendedAmount">
          <Form.Label>Recommended Amount (Rs.)</Form.Label>
          <Form.Control
            disabled={true}
            placeholder="Recommended Amount"
            value={
              affectedProperty.recommendedAmount
                ? currencyMask(affectedProperty.recommendedAmount)
                : ''
            }
          />
        </Form.Group>
      </Col>
    )
  }
  const renderPoliceComplainDetails = () => {
    return (
      <Col sm={12} className="border-label">
        <div className="border-label-span">Details of Police Complain</div>
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="policeStation">
              <Form.Label>Police Station</Form.Label>
              <Form.Control
                disabled={true}
                placeholder="Police Station"
                value={affectedProperty.policeStation}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="complaintDate">
              <Form.Label>Complain Date</Form.Label>
              <Form.Control
                disabled={true}
                placeholder="Complain Date"
                value={
                  affectedProperty.complaintDate != null
                    ? new Date(
                        affectedProperty.complaintDate * 1000
                      ).toLocaleDateString()
                    : undefined
                }
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="complaintNumber">
              <Form.Label>Complain Number</Form.Label>
              <Form.Control
                disabled={true}
                placeholder="Complain Number"
                value={affectedProperty.complaintNumber}
              />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group className="mb-3" controlId="reasons">
          <Form.Label>
            Reasons for not made the complaint promptly after the incident
          </Form.Label>
          <Form.Control
            placeholder="Reasons for not made the complaint promptly after the incident"
            disabled={true}
            value={affectedProperty.reasons}
          />
        </Form.Group>
      </Col>
    )
  }
  const renderDamageDetails = () => {
    return (
      <Col sm={12} className="border-label">
        <div className="border-label-span">Damage Details</div>
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="damageType">
              <Form.Label>Damage Typee</Form.Label>
              <Form.Control
                disabled={true}
                value={affectedProperty.damageType}
                placeholder="Damage Typee"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="damage">
              <Form.Label>Damage %</Form.Label>
              <Form.Control
                placeholder="Damage"
                disabled={true}
                value={affectedProperty.damage}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="reasonsForDamage">
              <Form.Label>Reasons for Damage</Form.Label>
              <Form.Control
                disabled={true}
                value={affectedProperty.reasonsForDamage}
                placeholder="Reasons for Damage"
              />
            </Form.Group>
            {fileRegistrationObject.scheme === 'RLP' ? (
              <>
                <Form.Group className="mb-3" controlId="assessmentOfTheDamage">
                  <Form.Label>Assessment of the Damage</Form.Label>
                  <Form.Control
                    disabled={true}
                    value={affectedProperty.assessmentOfTheDamage}
                    placeholder="Assessment of the Damag"
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="presentConditionOfTheDamagedPlace"
                >
                  <Form.Label>
                    Present Condition of the Damaged Place
                  </Form.Label>
                  <Form.Control
                    disabled={true}
                    placeholder="Present Condition of the Damaged Place"
                    value={affectedProperty.presentConditionOfTheDamagedPlace}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="amountSpentForRepairs">
                  <Form.Label>Amount Spent for Repairs</Form.Label>
                  <Form.Control
                    disabled={true}
                    placeholder="Amount Spent for Repairs"
                    value={
                      affectedProperty.amountSpentForRepairs
                        ? currencyMask(affectedProperty.amountSpentForRepairs)
                        : ''
                    }
                  />
                </Form.Group>
              </>
            ) : (
              ''
            )}
          </Col>
        </Row>
      </Col>
    )
  }
  const renderIncidentDetails = () => {
    return (
      <Col sm={12} className="border-label">
        <div className="border-label-span">Incident Details</div>
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="incidentDate">
              <Form.Label>Incident Date</Form.Label>
              <Form.Control
                disabled={true}
                placeholder="Incident Date"
                value={
                  fileRegistrationObject.incidentDate! - null
                    ? new Date(
                        fileRegistrationObject.incidentDate * 1000
                      ).toLocaleDateString()
                    : undefined
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="IncidentType">
              <Form.Label>Incident Type</Form.Label>
              <Form.Control
                disabled={true}
                placeholder="Incident Type"
                value={fileRegistrationObject.incidentType?.description}
              />
            </Form.Group>
          </Col>
        </Row>
      </Col>
    )
  }
  return (
    <>
      {loading ? (
        <div className="loading-layout">
          <LoadingComponent />
        </div>
      ) : (
        <>
          <Col sm={12} className="border-label">
            <div className="border-label-span">
              Affected Property Information
            </div>
            <Form id="affected-property-information-form">
              <Row className="mt-4">
                {fileRegistrationObject.scheme === 'RLP'
                  ? renderRlpSection()
                  : renderOtherSection()}
              </Row>
              <Row className="mt-4">{renderIncidentDetails()}</Row>
              <Row className="mt-4">{renderDamageDetails()}</Row>
              <Row className="mt-4">{renderPoliceComplainDetails()}</Row>
              <Row className="mt-4">{renderPropertyDamageList()}</Row>

              {(fileRegistrationObject.scheme == 'GPC' ||
                fileRegistrationObject.scheme == 'GSC') && (
                <Form.Group className="mb-5" controlId="oRRecommendedAmount">
                  <Form.Label>
                    Value Approved by the OR (Rs.){' '}
                    <span className="required-field-astrix">*</span>
                  </Form.Label>
                  <Form.Control
                    disabled
                    placeholder="Value Approved by the OR (Rs.)"
                    value={
                      oRRecommendedAmount && currencyMask(oRRecommendedAmount)
                    }
                  />
                </Form.Group>
              )}

              <Row className="mt-4">{renderotherAssistancList()}</Row>
              {fileRegistrationObject.scheme === 'GPC' && (
                <Form.Group className="mt-4 mb-4" controlId="gpcRemark">
                  <Form.Label>Remark</Form.Label>
                  <Form.Control
                    disabled={true}
                    placeholder="Remark"
                    value={affectedProperty.remark}
                  />
                </Form.Group>
              )}
              <Form.Group className="mt-4 mb-4" controlId="compensationAmount">
                <Form.Label>Compensation Amount (Rs.)</Form.Label>
                <Form.Control
                  disabled={true}
                  placeholder="Compensation Amoun"
                  value={
                    affectedProperty.compensationAmount
                      ? currencyMask(affectedProperty.compensationAmount)
                      : ''
                  }
                />
              </Form.Group>
              {installmentList.length > 0 && (
                <Col sm={12} className="border-label mb-3">
                  <div className="border-label-span">Installment Details</div>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="installmentList">
                      <Row>{renderInstallmentList()}</Row>
                    </Form.Group>
                  </Row>
                </Col>
              )}
            </Form>
          </Col>
          <Col sm={12} className="d-flex justify-content-end mt-4">
            <button className="cancel-button" onClick={onOk}>
              Ok
            </button>
          </Col>
        </>
      )}
    </>
  )
}

export default SectionAffectedProperty
