import React, { FC } from 'react'
import './FilterButton.scss'
// import filter from '../../../assets/images/filter-icon.svg'

export interface ReactControlledComponentProps {
  onClick: any
}

const FilterRestButton: FC<ReactControlledComponentProps> = ({ onClick }) => {
  return (
    <>
      <button className="filter-button" onClick={onClick}>
        {/* <img src={filter} alt="" /> */}
        <span>Reset</span>
      </button>
    </>
  )
}

export default FilterRestButton
