import produce from 'immer'
import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Form } from 'react-bootstrap'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import LoadingComponent from '../../../components/common/loading/LoadingComponent'
import ConfirmationModal from '../../../components/modals/ConfirmationModal'
import { CommonTypeOption } from '../../../models/selectOptionModels/commonOptionType'
import metaDataService from '../../../services/metaDataService'
import Select from 'react-select'
import PropertyDamageTypeServices from '../../../services/systemConfiguration/propertyDamageType/propertyDamageTypeServices'
import {
  ALERT_SUCCESS,
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../../utils/constants'
import { propertyModified } from '../../../utils/successMessages'
import { showTotast } from '../../../utils/toast'
import * as validation from './validations'
import { validationError } from '../../../utils/errorMessages'
import { SchemeModel } from '../../../models/systemConfiguration/schemes/schemeModel'
import { PropertyDamageTypeModel } from '../../../models/systemConfiguration/propertyDamageType/propertyDamageTypeModel'
import { PropertyFieldListModel } from '../../../models/systemConfiguration/propertyDamageType/propertyFieldListModel'
import { PropertyDamageTypeErrorModel } from '../../../models/systemConfiguration/propertyDamageType/propertyDamageTypeErrorModel'
type TParams = { id: string }
const ModifyPropertyDamageType = ({ match }: RouteComponentProps<TParams>) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false)
  const [isValid, setisValid] = useState(true)
  const [errors, setErrors] = useState({} as PropertyDamageTypeErrorModel)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [propertyCategoryList, setPropertyCategoryList] = useState([])
  const [schemeList, setSchemeList] = useState([])
  const options: CommonTypeOption[] = []
  const [applicableValuesList, setApplicableValuesList] = useState(options)
  const [selectedApplicableValues, setSelectedApplicableValues] =
    useState(options)
  const [propertyState, setPropertyState] = useState(
    {} as PropertyDamageTypeModel
  )
  const history = useHistory()
  const customStyles = {
    control: (base: any, state: { isFocused: any }) => ({
      ...base,
      // state.isFocused can display different borderColor if you need it
      borderColor: state.isFocused ? '#ddd' : isValid ? '#ddd' : 'red',
      // overwrittes hover style
      '&:hover': {
        borderColor: state.isFocused ? '#ddd' : isValid ? '#ddd' : 'red',
      },
    }),
  }
  useEffect(() => {
    async function getMetaData() {
      const propertyData = await PropertyDamageTypeServices.getPropertyById(
        parseInt(match.params.id)
      )
      setPropertyState(propertyData.data)
      const propertyCategory = await metaDataService.getPropertyCategoryList()
      const filtedSchemesList = await (
        await metaDataService.apiGetAllSchemesList()
      ).data.filter((scheme: SchemeModel) => {
        return (
          scheme.schemeCategory == 'Property - Religious Places' ||
          scheme.schemeCategory == 'Property - Personal'
        )
      })
      setSchemeList(filtedSchemesList)
      const propertyField = await metaDataService.getPropertyFieldList()
      const propertyFieldOptionList: CommonTypeOption[] = []
      for (let i = 0; i < propertyField.data.length; i++) {
        const propertyFieldOptions: CommonTypeOption = {
          value: propertyField.data[i].name,
          label: propertyField.data[i].name,
        }
        propertyFieldOptionList.push(propertyFieldOptions)
      }
      setPropertyCategoryList(propertyCategory.data)
      setApplicableValuesList(propertyFieldOptionList)
      const defaultPropertyFieldOptionList: CommonTypeOption[] = []
      for (let i = 0; i < propertyData.data.propertyFieldList.length; i++) {
        const defaultPropertyFieldObject: CommonTypeOption = {
          value: propertyData.data.propertyFieldList[i].propertyField,
          label: propertyData.data.propertyFieldList[i].propertyField,
        }
        defaultPropertyFieldOptionList.push(defaultPropertyFieldObject)
      }
      setSelectedApplicableValues(defaultPropertyFieldOptionList)
    }
    getMetaData()
  }, [])
  const handleApplicableValues = (selectedOption: any) => {
    setSelectedApplicableValues(selectedOption)
    const applicableValues: PropertyFieldListModel[] = []
    for (let i = 0; i < selectedOption.length; i++) {
      const ApplicableValuesObject: PropertyFieldListModel = {
        propertyField: selectedOption[i].label,
        propertyFieldType: '',
      }
      applicableValues.push(ApplicableValuesObject)
    }
    const newValue = produce(propertyState, (draft) => {
      draft.propertyFieldList = applicableValues
    })
    setPropertyState(newValue)
  }
  const findFormErrors = () => {
    const newErrors = {} as PropertyDamageTypeErrorModel
    if (validation.validatePropertyName(propertyState.name)) {
      newErrors.nameError = validation.validatePropertyName(propertyState.name)
    }
    if (
      validation.validatePropertyDescription(propertyState.propertyDescription)
    ) {
      newErrors.propertyDescriptionError =
        validation.validatePropertyDescription(
          propertyState.propertyDescription
        )
    }
    if (validation.validatePropertyCategory(propertyState.propertyCategory)) {
      newErrors.propertyCategoryError = validation.validatePropertyCategory(
        propertyState.propertyCategory
      )
    }
    if (validation.validateScheme(propertyState.scheme)) {
      newErrors.schemeError = validation.validateScheme(propertyState.scheme)
    }
    if (
      validation.validateApplicableValuesList(propertyState.propertyFieldList)
    ) {
      newErrors.propertyFieldListError =
        validation.validateApplicableValuesList(propertyState.propertyFieldList)
      setisValid(false)
    }
    return newErrors
  }
  const handleSubmit = () => {
    const foundErrros = findFormErrors()
    if (Object.keys(foundErrros).length > 0) {
      showTotast(
        ALERT_WARNING,
        validationError,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setErrors(foundErrros)
    } else {
      setErrors(foundErrros)
      setShowConfirmation(true)
    }
  }
  const handleConfirm = async () => {
    console.log(propertyState)
    const result = await PropertyDamageTypeServices.apiUpdateProperty(
      parseInt(match.params.id),
      propertyState
    )
    let success = false
    let message = result.errorMessage ? result.errorMessage : ''
    if (result.status === 200) {
      success = true
      message = propertyModified
      history.goBack()
    }
    showTotast(
      success ? ALERT_SUCCESS : ALERT_WARNING,
      message,
      TOAST_POSITION_TOP_RIGHT,
      5,
      TOAST_TRANSITION_SLIDE
    )
  }
  return (
    <>
      {loading ? (
        <Container className="add-Incident-Type-loading-layout">
          <LoadingComponent />
        </Container>
      ) : (
        <Row className="add-Incident-Type-page-layout">
          <Col sm={12} className="border-label">
            <div className="border-label-span">Modify Properties</div>
            <Form>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="propertyName">
                    <Form.Label>Name of the Property</Form.Label>
                    <Form.Control
                      value={propertyState.name}
                      isInvalid={!!errors.nameError}
                      type="text"
                      onBlur={(e) => {
                        const newError = produce(errors, (draft) => {
                          draft.nameError = validation.validatePropertyName(
                            e.target.value
                          )
                        })
                        setErrors(newError)
                      }}
                      onChange={(e) => {
                        const newValue = produce(propertyState, (draft) => {
                          draft.name = e.target.value
                        })
                        setPropertyState(newValue)
                        const clearError = produce(errors, (draft) => {
                          draft.nameError = ''
                        })
                        setErrors(clearError)
                      }}
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.nameError}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="propertyDescription">
                    <Form.Label>Property Description</Form.Label>
                    <Form.Control
                      value={propertyState.propertyDescription}
                      isInvalid={!!errors.propertyDescriptionError}
                      type="text"
                      onBlur={(e) => {
                        const newError = produce(errors, (draft) => {
                          draft.propertyDescriptionError =
                            validation.validatePropertyDescription(
                              e.target.value
                            )
                        })
                        setErrors(newError)
                      }}
                      onChange={(e) => {
                        const newValue = produce(propertyState, (draft) => {
                          draft.propertyDescription = e.target.value
                        })
                        setPropertyState(newValue)
                        const clearError = produce(errors, (draft) => {
                          draft.propertyDescriptionError = ''
                        })
                        setErrors(clearError)
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.propertyDescriptionError}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="propertyCategory">
                    <Form.Label>Property Category</Form.Label>
                    <Form.Control
                      value={propertyState.propertyCategory}
                      isInvalid={!!errors.propertyCategoryError}
                      type="text"
                      className="form-select"
                      as="select"
                      onBlur={(e) => {
                        const newError = produce(errors, (draft) => {
                          draft.propertyCategoryError =
                            validation.validatePropertyCategory(e.target.value)
                        })
                        setErrors(newError)
                      }}
                      onChange={(e) => {
                        const newValue = produce(propertyState, (draft) => {
                          draft.propertyCategory = e.target.value
                        })
                        setPropertyState(newValue)
                        const clearError = produce(errors, (draft) => {
                          draft.propertyCategoryError = ''
                        })
                        setErrors(clearError)
                      }}
                    >
                      {!propertyState.propertyCategory ? (
                        <option value="">--Property Category--</option>
                      ) : (
                        ''
                      )}
                      {propertyCategoryList.map(
                        (
                          catagory: { name: string; id: number },
                          index: number
                        ) => {
                          return (
                            <option
                              key={`catagory-${index}-${catagory.id}`}
                              value={catagory.name}
                            >
                              {catagory.name}
                            </option>
                          )
                        }
                      )}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.propertyCategoryError}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="scheme">
                    <Form.Label>Scheme</Form.Label>
                    <Form.Control
                      value={propertyState.scheme}
                      isInvalid={!!errors.schemeError}
                      type="text"
                      className="form-select"
                      as="select"
                      onBlur={(e) => {
                        const newError = produce(errors, (draft) => {
                          draft.schemeError = validation.validateScheme(
                            e.target.value
                          )
                        })
                        setErrors(newError)
                      }}
                      onChange={(e) => {
                        const newValue = produce(propertyState, (draft) => {
                          draft.scheme = e.target.value
                        })
                        setPropertyState(newValue)
                        const clearError = produce(errors, (draft) => {
                          draft.schemeError = ''
                        })
                        setErrors(clearError)
                      }}
                    >
                      {!propertyState.scheme ? (
                        <option value="">--Select Scheme--</option>
                      ) : (
                        ''
                      )}
                      {schemeList.map((scheme: SchemeModel, index: number) => {
                        return (
                          <option
                            key={`scheme-${index}-${scheme.code}`}
                            value={scheme.code}
                          >
                            {scheme.code}
                          </option>
                        )
                      })}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.schemeError}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="applicableValues">
                    <Form.Label>Applicable values</Form.Label>
                    <Select
                      value={selectedApplicableValues}
                      styles={customStyles}
                      placeholder="--Applicable values--"
                      options={applicableValuesList}
                      isMulti
                      onChange={(e) => {
                        handleApplicableValues(e)
                        const newError = produce(errors, (draft) => {
                          draft.propertyFieldListError =
                            validation.validateApplicableValuesList(e)
                        })
                        setErrors(newError)
                        e.length === 0 ? setisValid(false) : setisValid(true)
                      }}
                    />
                    <span className="feedback-error-span-custom">
                      {errors.propertyFieldListError}
                    </span>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
            <Row>
              <Col sm={12} className="d-flex justify-content-end mt-4">
                <button
                  className="cancel-button"
                  onClick={(e) => {
                    e.preventDefault()
                    history.goBack()
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="save-button"
                  onClick={handleSubmit}
                >
                  Save
                </button>
              </Col>
            </Row>
          </Col>
          {showConfirmation && (
            <ConfirmationModal
              name={'Confirmation'}
              message={'Are you sure to create this property damage type ?'}
              onCancel={() => {
                setShowConfirmation(false)
              }}
              onConfirm={handleConfirm}
            />
          )}
        </Row>
      )}
    </>
  )
}

export default ModifyPropertyDamageType
