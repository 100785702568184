import React, { useState } from 'react'
import { Tab, Row, Nav } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useRouteMatch, Switch, Route } from 'react-router'
import {
  getUserModuleActions,
  getUserSubModuleActions,
} from '../../../../store/user/selectors'
import { checkPermission } from '../../../../utils/utilFunctions'
import GsiFileView from '../../../compensationRecommendation/compensation/gsi/GsiFileView'
import GsiApplication from './GsiApplication'
import GsiFileRegistration from './GsiFileRegistration'
import GsiReturn from './GsiReturn'

const Gsi = () => {
  const { path } = useRouteMatch()
  const [selectedSection, setSelectedSection] = useState('gsi')

  const actionListModule = useSelector(
    getUserModuleActions('application-registration', 'compensation', 'gsi')
  )

  const actionListSubModule = useSelector(
    getUserSubModuleActions(
      'application-registration',
      'compensation',
      'gsi',
      'file-return'
    )
  )
  return (
    <Switch>
      <Route path={`${path}/`} exact>
        <Tab.Container id="left-tabs-example" defaultActiveKey="gsi">
          <Row className="tab-page-layout">
            <Nav variant="pills" className="flex-row">
              {checkPermission('view', actionListModule) && (
                <Nav.Item className="custom-nav-item">
                  <Nav.Link
                    onClick={() => {
                      setSelectedSection('gsi')
                    }}
                    eventKey="gsi"
                  >
                    GSI Application
                  </Nav.Link>
                </Nav.Item>
              )}
              {checkPermission('view', actionListSubModule) && (
                <Nav.Item className="custom-nav-item">
                  <Nav.Link
                    onClick={() => {
                      setSelectedSection('gsi-return')
                    }}
                    eventKey="gsi-return"
                  >
                    GSI Return
                  </Nav.Link>
                </Nav.Item>
              )}
            </Nav>
          </Row>
          <Row className="page-tiles-layout align-content-start">
            {selectedSection === 'gsi' ? (
              <>
                {checkPermission('view', actionListModule) && (
                  <GsiApplication />
                )}
              </>
            ) : (
              <>
                {checkPermission('view', actionListSubModule) && <GsiReturn />}
              </>
            )}
          </Row>
        </Tab.Container>
      </Route>
      <Route
        path={`${path}/view/:id`}
        render={(props) => <GsiFileRegistration type="file" {...props} />}
      />
      <Route
        path={`${path}/return/:id`}
        render={(props) => <GsiFileRegistration type="return" {...props} />}
      />
      <Route
        path={`${path}/view-similar/:id`}
        render={(props) => <GsiFileView type="similar" {...props} />}
      />
    </Switch>
  )
}

export default Gsi
