import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ITerminalWizardComponent from '../../../../components/common/ITerminalWizardComponent/ITerminalWizardComponent'
import LoadingComponent from '../../../../components/common/loading/LoadingComponent'
import { FileRegistrationModel } from '../../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import mpService from '../../../../services/compensationRecommendation/compensation/mpService'
import { changeLoadingRequest } from '../../../../store/app/actionTypes'
import { handleNotification } from '../../../../utils/utilFunctions'
import SectionOne from '../../../../components/sections/SectionOne'
import SectionTwo from '../../../../components/sections/SectionTwo'
import SectionThree from '../../../../components/sections/SectionThree'
import { ResponseObject } from '../../../../services/api/apiManager'
import { approved, rejected, returned } from '../../../../utils/successMessages'
import { approve, reject, ApplicationReturn } from '../../../../utils/constants'
import { mpSectionList } from '../../../../utils/metaData'
import SectionFour from '../../../../components/sections/SectionFour'
import SupportDocumentSection from '../../../../components/sections/SupportDocumentSection'
import NoDataBanner from '../../../../components/common/noData/NoDataBanner'

const MpFileView = (props: any) => {
  const {
    type,
    match: { params },
  } = props
  console.log(type)
  console.log(params)
  const [loading, setLoading] = useState(true)
  const [available, setAvailable] = useState(true)
  const [permission, setPermission] = useState(true)
  const [activeSection, setActiveSection] = useState(1)
  const [fileRegistrationObject, setFileRegistrationObject] = useState(
    {} as FileRegistrationModel
  )

  const history = useHistory()
  const dispatch = useDispatch()
  useEffect(() => {
    async function getFileDetails() {
      const result = await mpService.apiGetMpFile(parseInt(params.id))
      console.log(result)
      if (result.status === 200) {
        setFileRegistrationObject(result.data)
      } else {
        setAvailable(false)
        if (result.status === 401) {
          setPermission(false)
        }
      }

      setLoading(false)
    }
    getFileDetails()
  }, [])

  const handleClick = async (clickObject: any) => {
    console.log(clickObject)
    if (clickObject.section === 'ok') {
      history.goBack()
    } else {
      dispatch(changeLoadingRequest())
      let result = {} as ResponseObject
      if (clickObject.section === approve) {
        result = await mpService.apiApproveMpFile(fileRegistrationObject.id)
        if (result.status === 200) {
          history.goBack()
        }
        handleNotification(result, approved)
      }
      if (clickObject.section === reject) {
        result = await mpService.apiRejectMpFile(
          fileRegistrationObject.id,
          clickObject.remark
        )
        if (result.status === 200) {
          history.goBack()
        }
        handleNotification(result, rejected)
      }
      if (clickObject.section === ApplicationReturn) {
        result = await mpService.apiReturnMpFile(
          fileRegistrationObject.id,
          clickObject.remark
        )
        if (result.status === 200) {
          history.goBack()
        }
        handleNotification(result, returned)
      }
      dispatch(changeLoadingRequest())
    }
  }
  const handleGoback = () => {
    history.goBack()
  }
  return (
    <>
      {loading ? (
        <div className="loading-layout">
          <LoadingComponent />
        </div>
      ) : (
        <>
          {available ? (
            <>
              <Row className="title-layout">
                <Col className="d-flex justify-content-start">
                  <span className="compensation-title mt-2 mb-2 ml-2">
                    Most Affected General Public Death
                  </span>
                  <span className="m-2">
                    {`Application reference : ${fileRegistrationObject.referenceNumber}`}
                  </span>
                </Col>
              </Row>
              <ITerminalWizardComponent
                sectionList={mpSectionList}
                activeSection={activeSection}
                validationLevel={fileRegistrationObject.validationLevel}
                onClick={(sectionNumber: number) => {
                  setActiveSection(sectionNumber)
                }}
              />
              <Row className="section-layout">
                {activeSection === 1 && (
                  <SectionOne
                    packageName={'compensation-recommendation'}
                    type={type}
                    fileRegistrationObject={fileRegistrationObject}
                    onClick={(data: any) => {
                      handleClick(data)
                    }}
                  />
                )}
                {activeSection === 2 && (
                  <SectionTwo
                    fileRegistrationObject={fileRegistrationObject}
                    onOk={handleGoback}
                  />
                )}
                {activeSection === 3 && (
                  <SectionThree
                    packageName={'compensation-recommendation'}
                    fileRegistrationObject={fileRegistrationObject}
                    onOk={handleGoback}
                  />
                )}
                {activeSection === 4 && (
                  <SectionFour
                    packageName={'compensation-recommendation'}
                    fileRegistrationObject={fileRegistrationObject}
                    onOk={handleGoback}
                  />
                )}
                {activeSection === 5 && (
                  <SupportDocumentSection
                    packageName={'compensation-recommendation'}
                    fileRegistrationObject={fileRegistrationObject}
                    onOk={handleGoback}
                  />
                )}
              </Row>
            </>
          ) : (
            <>
              <Row className="title-layout">
                <Col className="d-flex justify-content-start">
                  <span className="compensation-title mt-2 mb-2 ml-2">
                    Missing Persons
                  </span>
                  <span className="m-2">{`Application reference : N/A`}</span>
                </Col>
              </Row>
              <Row className="section-layout">
                <NoDataBanner permission={permission} />
              </Row>
            </>
          )}
        </>
      )}
    </>
  )
}

export default MpFileView
