import { DistrictModal } from '../../models/administrativeArea/districtModal'
import { DSDivisionModal } from '../../models/administrativeArea/dsDivisionModal'
//import { gnDivisionListModel } from '../../models/administrativeArea/gnDivisionListModel'
import { GNDivisionModal } from '../../models/administrativeArea/gnDivisionModal'
//import { GNListModel } from '../../models/administrativeArea/gnListModel'
import { ProvinceModal } from '../../models/administrativeArea/provinceModal'

// export const SELECT_DS_REQUEST = 'SELECT_DS_REQUEST'
export const SELECT_ALL_DS_REQUEST = 'SELECT_ALL_DS_REQUEST'
export const SELECT_ALL_GN_REQUEST = 'SELECT_ALL_GN_REQUEST'
export const DESELECT_ALL_DS_REQUEST = 'DESELECT_ALL_DS_REQUEST'
export const DESELECT_ALL_GN_REQUEST = 'DESELECT_ALL_GN_REQUEST'
export const SELECT_DS_REQUEST = 'SELECT_DS_REQUEST'
export const DESELECT_DS_REQUEST = 'DESELECT_DS_REQUEST'
export const PROVINCE_FETCH_REQUEST = 'PROVINCE_FETCH_REQUEST'
export const PROVINCE_FETCH_SUCCESS = 'PROVINCE_FETCH_SUCCESS'
export const PROVINCE_FETCH_FAILURE = 'PROVINCE_FETCH_FAILURE'
export const DISTRICT_FETCH_REQUEST = 'DISTRICT_FETCH_REQUEST'
export const DISTRICT_FETCH_SUCCESS = 'DISTRICT_FETCH_SUCCESS'
export const DISTRICT_FETCH_FAILURE = 'DISTRICT_FETCH_FAILURE'
export const DS_FETCH_REQUEST = 'DS_FETCH_REQUEST'
export const DS_FETCH_SUCCESS = 'DS_FETCH_SUCCESS'
export const DS_FETCH_FAILURE = 'DS_FETCH_FAILURE'
export const GN_FETCH_REQUEST = 'GN_FETCH_REQUEST'
export const GN_FETCH_SUCCESS = 'GN_FETCH_SUCCESS'
export const GN_FETCH_FAILURE = 'GN_FETCH_FAILURE'
export const CLEAR_ADMINISTRATIVEAREA_LIST_REQUEST =
  'CLEAR_ADMINISTRATIVEAREA_LIST_REQUEST'
export const SET_MODIFY_ADMINISTRATIVEAREA_LIST_REQUEST =
  'SET_MODIFY_ADMINISTRATIVEAREA_LIST_REQUEST'

export interface AdministrativeAreaState {
  provinces: ProvinceModal[]
  districts: DistrictModal[]
  ds: DSDivisionModal[]
  gn: GNDivisionModal[]
  pending: boolean
  error: string | null
}
export interface ModifyAdministrativeAreaListdPayload {
  administrativeAreaList: ProvinceModal[]
}
export interface SetModifyAdministrativeAreaListRequest {
  type: typeof SET_MODIFY_ADMINISTRATIVEAREA_LIST_REQUEST
  payload: ModifyAdministrativeAreaListdPayload
}

export interface ProvinceFetchSuccessPayload {
  provinces: ProvinceModal[]
}

export interface ProvinceFetchFailurePayload {
  error: string
}

export interface ProvinceFetchRequest {
  type: typeof PROVINCE_FETCH_REQUEST
}

export interface DSDivisionSelectRequest {
  type: typeof SELECT_DS_REQUEST
  payload: { id: number; districtId: number }
}

export interface GNDivisionAllSelectRequest {
  type: typeof SELECT_ALL_GN_REQUEST
  payload: { id: number; districtId: number; gnList: GNDivisionModal[] }
}
// export interface GNDivisionAllDeselectRequestPayload {}

export interface GNDivisionAllDeselectRequest {
  type: typeof DESELECT_ALL_GN_REQUEST
  payload: { id: number; districtId: number; gnList: GNDivisionModal[] }
}

export interface DSDivisionAllSelectRequest {
  type: typeof SELECT_ALL_DS_REQUEST
  payload: { id: number; dsList: DSDivisionModal[] }
}
export interface DSDivisionAllDeselectRequest {
  type: typeof DESELECT_ALL_DS_REQUEST
  payload: { id: number; dsList: DSDivisionModal[] }
}

export interface DSDivisionDeselectRequest {
  type: typeof DESELECT_DS_REQUEST
  payload: { id: number; districtId: number }
}

export type ProvinceFetchSuccess = {
  type: typeof PROVINCE_FETCH_SUCCESS
  payload: ProvinceFetchSuccessPayload
}

export type ProvinceFetchFailure = {
  type: typeof PROVINCE_FETCH_FAILURE
  payload: ProvinceFetchFailurePayload
}

export interface DistrictFetchSuccessPayload {
  districts: DistrictModal[]
}

export interface DistrictFetchFailurePayload {
  error: string
}

export interface DistrictFetchRequest {
  type: typeof DISTRICT_FETCH_REQUEST
}

export type DistrictFetchSuccess = {
  type: typeof DISTRICT_FETCH_SUCCESS
  payload: DistrictFetchSuccessPayload
}

export type DistrictFetchFailure = {
  type: typeof DISTRICT_FETCH_FAILURE
  payload: DistrictFetchFailurePayload
}

export interface DsFetchSuccessPayload {
  ds: DSDivisionModal[]
}

export interface DsFetchFailurePayload {
  error: string
}

export interface DsFetchRequest {
  type: typeof DS_FETCH_REQUEST
}

export type DsFetchSuccess = {
  type: typeof DS_FETCH_SUCCESS
  payload: DsFetchSuccessPayload
}

export type DsFetchFailure = {
  type: typeof DS_FETCH_FAILURE
  payload: DsFetchFailurePayload
}

export interface GnFetchSuccessPayload {
  gn: GNDivisionModal[]
}

export interface GnFetchFailurePayload {
  error: string
}

export interface GnFetchRequest {
  type: typeof GN_FETCH_REQUEST
}

export type GnFetchSuccess = {
  type: typeof GN_FETCH_SUCCESS
  payload: GnFetchSuccessPayload
}

export type GnFetchFailure = {
  type: typeof GN_FETCH_FAILURE
  payload: GnFetchFailurePayload
}

export interface ClearAdminsitrativeAreListRequest {
  type: typeof CLEAR_ADMINISTRATIVEAREA_LIST_REQUEST
}

export type AdminsitrativeAreaActions =
  | ProvinceFetchRequest
  | ProvinceFetchSuccess
  | ProvinceFetchFailure
  | DistrictFetchRequest
  | DistrictFetchSuccess
  | DistrictFetchFailure
  | DsFetchRequest
  | DsFetchSuccess
  | DsFetchFailure
  | GnFetchRequest
  | GnFetchSuccess
  | GnFetchFailure
  | DSDivisionSelectRequest
  | DSDivisionDeselectRequest
  | SetModifyAdministrativeAreaListRequest
  | ClearAdminsitrativeAreListRequest
  | GNDivisionAllSelectRequest
  | GNDivisionAllDeselectRequest
  | DSDivisionAllSelectRequest
  | DSDivisionAllDeselectRequest
