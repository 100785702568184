import {
  FETCH_USERS_REQUEST,
  FETCH_USERS_FAILURE,
  FETCH_USERS_SUCCESS,
  CREATE_USER_FAILURE,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  CLEAR_USERS_REQUEST,
  ClearUsersRequest,
  CreateUserFailure,
  CreateUserFailurePayload,
  CreateUserRequest,
  CreateUserSuccess,
  CreateUserSuccessPayload,
  FetchUsersFailure,
  FetchUsersFailurePayload,
  FetchUsersRequest,
  FetchUsersSuccess,
  FetchUsersSuccessPayload,
  IUser,
  UpdateUserFailure,
  UpdateUserFailurePayload,
  UpdateUserRequest,
  UpdateUserSuccess,
  UpdateUserSuccessPayload,
  // FetchRolesFailure,
  // FetchRolesRequest,
  // FetchRolesSuccess,
  // FetchRolesSuccessPayload,
  // FetchURolesFailurePayload,
  // FETCH_ROLES_FAILURE,
  // FETCH_ROLES_REQUEST,
  // FETCH_ROLES_SUCCESS,
  // CLEAR_ROLES_REQUEST,
  // ClearRolesRequest,
} from './actionTypes'

export const clearUsersRequest = (): ClearUsersRequest => ({
  type: CLEAR_USERS_REQUEST,
})

export const fetchUsersRequest = (): FetchUsersRequest => ({
  type: FETCH_USERS_REQUEST,
})

export const fetchUsersSuccess = (
  payload: FetchUsersSuccessPayload
): FetchUsersSuccess => ({
  type: FETCH_USERS_SUCCESS,
  payload,
})

export const fetchUsersFailure = (
  payload: FetchUsersFailurePayload
): FetchUsersFailure => ({
  type: FETCH_USERS_FAILURE,
  payload,
})

export const createUserRequest = (user: IUser): CreateUserRequest => ({
  type: CREATE_USER_REQUEST,
  user,
})

export const createUserSuccess = (
  payload: CreateUserSuccessPayload
): CreateUserSuccess => ({
  type: CREATE_USER_SUCCESS,
  payload,
})

export const createUserFailure = (
  payload: CreateUserFailurePayload
): CreateUserFailure => ({
  type: CREATE_USER_FAILURE,
  payload,
})

export const updateUserRequest = (user: IUser): UpdateUserRequest => ({
  type: UPDATE_USER_REQUEST,
  user,
})

export const updateUserSuccess = (
  payload: UpdateUserSuccessPayload
): UpdateUserSuccess => ({
  type: UPDATE_USER_SUCCESS,
  payload,
})

export const updateUserFailure = (
  payload: UpdateUserFailurePayload
): UpdateUserFailure => ({
  type: UPDATE_USER_FAILURE,
  payload,
})

// export const fetchRolesRequest = (): FetchRolesRequest => ({
//   type: FETCH_ROLES_REQUEST,
// })

// export const fetchRolesSuccess = (
//   payload: FetchRolesSuccessPayload
// ): FetchRolesSuccess => ({
//   type: FETCH_ROLES_SUCCESS,
//   payload,
// })

// export const fetchRolesFailure = (
//   payload: FetchURolesFailurePayload
// ): FetchRolesFailure => ({
//   type: FETCH_ROLES_FAILURE,
//   payload,
// })

// export const clearRolesRequest = (): ClearRolesRequest => ({
//   type: CLEAR_ROLES_REQUEST,
// })
