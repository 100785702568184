import { FileRegistrationModel } from '../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { FilterModel } from '../../../models/utils/filterModel'
import apiService from '../../api/apiManager'

class PiDataService {
  async apiFetchPiList(filterData: FilterModel) {
    return apiService.apiPost(
      `/compensation-approval/compensation/pi/view/list`,
      filterData
    )
  }

  async apiFetchPiListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/compensation-approval/compensation/pi/view/count`,
      filterData
    )
  }
  async apiFetchPiReturnList(filterData: FilterModel) {
    return apiService.apiPost(
      `/compensation-approval/compensation/pi/file-return/view/list`,
      filterData
    )
  }

  async apiFetchPiReturnListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/compensation-approval/compensation/pi/file-return/view/count`,
      filterData
    )
  }
  async apiApprovePiFile(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/compensation-approval/compensation/pi/${id}/approve`,
      { remark: remark }
    )
  }

  async apiRejectPiFile(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/compensation-approval/compensation/pi/${id}/reject`,
      { remark: remark }
    )
  }
  async apiReturnPiFile(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/compensation-approval/compensation/pi/${id}/return`,
      { remark: remark }
    )
  }
  async apiUpdatePiFile(id: number, file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/compensation-approval/compensation/pi/file-return/${id}`,
      file
    )
  }
  async apiGetReturnPiFile(id: number) {
    return apiService.apiGet(
      `/compensation-approval/compensation/pi/file-return/view/${id}`
    )
  }
  async apiGetPiFile(id: number) {
    return apiService.apiGet(
      `/compensation-approval/compensation/pi/view/${id}`
    )
  }
}

export default new PiDataService()
