import produce from 'immer'
import { FC, useEffect, useState } from 'react'
import { Col, Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import LoadingComponent from '../../../components/common/loading/LoadingComponent'
import { FileSupportingDocument } from '../../../models/applicationRegistration/compensation/FileSupportingDocument'
import { ReminderModel } from '../../../models/applicationRegistration/compensation/reminderModel'
import { SupportingDocumentDataModel } from '../../../models/applicationRegistration/compensation/supportingDocument'
import { FileRegistrationModel } from '../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { SupportingDocumentsModel } from '../../../models/systemConfiguration/supportingDocuments/supportingDocumentsModel'
import { ResponseObject } from '../../../services/api/apiManager'
import gpcService from '../../../services/applicationRegistration/compensation/gpcService'
import gscService from '../../../services/applicationRegistration/compensation/gscService'
import gsdService from '../../../services/applicationRegistration/compensation/gsdService'
import gsiService from '../../../services/applicationRegistration/compensation/gsiService'
import pdService from '../../../services/applicationRegistration/compensation/pdService'
import mpService from '../../../services/applicationRegistration/compensation/mpService'
import piService from '../../../services/applicationRegistration/compensation/piService'
import rlpService from '../../../services/applicationRegistration/compensation/rlpService'
import ahService from '../../../services/applicationRegistration/loan/ahService'
import seService from '../../../services/applicationRegistration/loan/seService'
import srtService from '../../../services/applicationRegistration/loan/srtService'
import metaDataService from '../../../services/metaDataService'
import { changeLoadingRequest } from '../../../store/app/actionTypes'
import { handleNotification } from '../../../utils/utilFunctions'
import { saveAs } from 'file-saver'
import { InstallmentModel } from '../../../models/applicationRegistration/compensation/installmentModel'
import ApplicationModificationService from '../../../services/applicationModification/ApplicationModificationService'
import SupportingDocument from './supporting/SupportingDocument'
import InstallmentDocument from './supporting/InstallmentDocument'
import ConfirmationModal from '../../../components/modals/ConfirmationModal'
export interface SectionFiveProps {
  fileRegistrationObject: FileRegistrationModel
}

const SectionFive: FC<SectionFiveProps> = ({ fileRegistrationObject }) => {
  const [loading, setLoading] = useState(true)

  const [uploadFileList, setUploadFileList] = useState([] as File[])
  const [installmentList, setInstallmentList] = useState(
    [] as InstallmentModel[]
  )
  const [reminderObject, setReminderObject] = useState({
    applicationFileId: fileRegistrationObject.id,
  } as ReminderModel)
  const [supportingDocumentDetails, setSupportingDocumentDetails] = useState(
    {} as SupportingDocumentDataModel
  )
  const [showConfirm, setShowConfirm] = useState({
    isShow: false,
    message: '',
    fileToRemove: null as FileSupportingDocument | null, // Store the file to be removed
  })

  const confirmRemoveFile = async () => {
    if (showConfirm.fileToRemove) {
      await handleRemoveFile(showConfirm.fileToRemove)
      setShowConfirm({ isShow: false, message: '', fileToRemove: null })
    }
  }

  const dispatch = useDispatch()
  useEffect(() => {
    async function getMetaData() {
      console.log(uploadFileList)
      const installmentData =
        await metaDataService.apiInstallmentListToSupportDocument(
          fileRegistrationObject.id
        )
      console.log(installmentData)
      if (installmentData.status === 200) {
        setInstallmentList(installmentData.data)
      }
      const supportingDocumentDataList =
        await metaDataService.getSupportingDocumentListByScheme(
          fileRegistrationObject.scheme
        )
      if (supportingDocumentDataList.status == 200) {
      }
      let supportingDocumentData = {} as ResponseObject
      supportingDocumentData = await getSupportDocumentDataByScheme()
      if (supportingDocumentData.status === 200) {
        setSupportingDocumentDetails(supportingDocumentData.data)
        const newReminder = produce(reminderObject, (draft: ReminderModel) => {
          draft.reminder = supportingDocumentData.data.reminderList.length + 1
        })
        setReminderObject(newReminder)
      }

      setLoading(false)
    }
    getMetaData()
  }, [])

  const getSupportDocumentDataByScheme = async () => {
    let supportingDocumentData = {} as ResponseObject
    if (fileRegistrationObject.scheme === 'MP') {
      supportingDocumentData = await mpService.apiMpGetSupportingDocument(
        fileRegistrationObject.id
      )
    }
    if (fileRegistrationObject.scheme === 'PD') {
      supportingDocumentData = await pdService.apiPdGetSupportingDocument(
        fileRegistrationObject.id
      )
    } else if (fileRegistrationObject.scheme === 'PI') {
      supportingDocumentData = await piService.apiPiGetSupportingDocument(
        fileRegistrationObject.id
      )
    } else if (fileRegistrationObject.scheme === 'GSD') {
      supportingDocumentData = await gsdService.apiGsdGetSupportingDocument(
        fileRegistrationObject.id
      )
    } else if (fileRegistrationObject.scheme === 'GSI') {
      supportingDocumentData = await gsiService.apiGsiGetSupportingDocument(
        fileRegistrationObject.id
      )
    } else if (fileRegistrationObject.scheme === 'GPC') {
      supportingDocumentData = await gpcService.apiGpcGetSupportingDocument(
        fileRegistrationObject.id
      )
    } else if (fileRegistrationObject.scheme === 'GSC') {
      supportingDocumentData = await gscService.apiGscGetSupportingDocument(
        fileRegistrationObject.id
      )
    } else if (fileRegistrationObject.scheme === 'RLP') {
      supportingDocumentData = await rlpService.apiRlpGetSupportingDocument(
        fileRegistrationObject.id
      )
    } else if (fileRegistrationObject.scheme === 'AH') {
      supportingDocumentData = await ahService.apiAhGetSupportingDocument(
        fileRegistrationObject.id
      )
    } else if (fileRegistrationObject.scheme === 'SE') {
      supportingDocumentData = await seService.apiSeGetSupportingDocument(
        fileRegistrationObject.id
      )
    } else if (fileRegistrationObject.scheme === 'SRT') {
      supportingDocumentData = await srtService.apiSrtGetSupportingDocument(
        fileRegistrationObject.id
      )
    }
    return supportingDocumentData
  }

  const handleFilesUpload = async (file: File, newName: string, id: number) => {
    const formData = new FormData()
    const addFile = {} as FileSupportingDocument
    addFile.applicationFileId = fileRegistrationObject.id
    addFile.supportingDocumentId = id
    addFile.fileName = file.name
    addFile.fileId = null as unknown as number
    addFile.name = newName
    addFile.fileType = file.type
    addFile.uuid = file.lastModified

    formData.append('file', file)
    const request = {
      fileId: null,
      supportingDocumentId: id,
      name: newName,
      fileName: file.name,
    }
    formData.append('request', JSON.stringify(request))
    dispatch(changeLoadingRequest())
    const response =
      await ApplicationModificationService.apiUpdateSupportingDocument(
        fileRegistrationObject.id,
        formData
      )

    if (response.status === 200) {
      const newSupportingDocumentDetails = produce(
        supportingDocumentDetails,
        (draft: SupportingDocumentDataModel) => {
          draft.applicationFileSupportingDocumentList.push(response.data)
        }
      )
      setSupportingDocumentDetails(newSupportingDocumentDetails)
    }

    setUploadFileList([])
    handleNotification(response, 'File uploaded successfully')
    dispatch(changeLoadingRequest())
  }

  const handleRemoveFile = async (file: FileSupportingDocument) => {
    dispatch(changeLoadingRequest())
    const response =
      await ApplicationModificationService.apiDeleteSupportingDocument(
        file.fileId
      )
    if (response.status === 204) {
      const supportingDocumentData = await getSupportDocumentDataByScheme()
      if (supportingDocumentData.status === 200) {
        setSupportingDocumentDetails(supportingDocumentData.data)
      }
      handleNotification(response, 'Removed successfully')
    }
    dispatch(changeLoadingRequest())
  }

  const handleDownloadFile = async (file: FileSupportingDocument) => {
    if (file.fileId == null) {
      const downloadFile = uploadFileList.filter((uploadFile: File) => {
        if (uploadFile.lastModified === file.uuid) {
          return uploadFile
        }
      })[0]
      if (downloadFile != null) {
        const fileURL = URL.createObjectURL(downloadFile)
        const link = document.createElement('a')
        link.href = fileURL
        link.setAttribute('download', file.name)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    } else {
      const headers: any = {
        responseType: 'blob',
        'Content-Type': 'blob',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }
      fetch(`${process.env.baseUrl}download_file/${file?.fileName}`, {
        headers,
      })
        .then((response) => {
          return response.blob()
        })
        .then((data) => {
          saveAs(data, file.name)
        })
    }
  }

  const renderSupportingDocumentArea = () => {
    console.log(supportingDocumentDetails)
    return supportingDocumentDetails.supportingDocumentList.map(
      (document: SupportingDocumentsModel, index: number) => {
        return (
          <SupportingDocument
            key={index}
            isDisabled={false}
            fileRegistrationObject={fileRegistrationObject}
            supportDocument={document}
            fileList={supportingDocumentDetails.applicationFileSupportingDocumentList.filter(
              (file: FileSupportingDocument) => {
                return file.supportingDocumentId === document.id
              }
            )}
            upload={(data: any) => {
              console.log(data)
              handleFilesUpload(data.file, data.name, document.id)
            }}
            // remove={(file: FileSupportingDocument) => {
            //   handleRemoveFile(file)
            // }}
            remove={(file: FileSupportingDocument) => {
              setShowConfirm({
                isShow: true,
                message: `Do you want to remove the file: ${file.name}?`,
                fileToRemove: file,
              })
            }}
            download={(file: FileSupportingDocument) => {
              handleDownloadFile(file)
            }}
          />
        )
      }
    )
  }

  const handleUploadInstallmentDocument = async (
    file: any,
    mergeNumber: number
  ) => {
    dispatch(changeLoadingRequest())
    const formData = new FormData()
    formData.append('file', file[0])
    const result =
      await ApplicationModificationService.apiUploadInstallmentSupportDocument(
        fileRegistrationObject.id,
        mergeNumber,
        formData
      )
    const installmentData =
      await metaDataService.apiInstallmentListToSupportDocument(
        fileRegistrationObject.id
      )
    if (installmentData.status === 200) {
      setInstallmentList(installmentData.data)
    }
    handleNotification(result, 'File Upload Sucessfully')
    dispatch(changeLoadingRequest())
  }

  const handleDownloadInstallmentFile = async (url: string, name: string) => {
    const headers: any = {
      responseType: 'blob',
      'Content-Type': 'blob',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }
    fetch(`${process.env.baseUrl}download_file/${name}`, { headers })
      .then((response) => {
        return response.blob()
      })
      .then((data) => {
        saveAs(data, name)
      })
  }

  const renderInstallmentDocuments = () => {
    return installmentList.map(
      (installment: InstallmentModel, index: number) => {
        return (
          <Form.Group
            key={`installment-${installment.installmentNumber}-${index}`}
            className="mb-3"
            controlId="letter"
          >
            <Form.Label>Installment {installment.installmentNumber}</Form.Label>
            <InstallmentDocument
              fileRegistrationObject={fileRegistrationObject}
              installment={installment}
              upload={(file: any) => {
                handleUploadInstallmentDocument(
                  file,
                  installment.mergeInstallmentNumber
                )
              }}
              download={(url: string, name: string) => {
                handleDownloadInstallmentFile(url, name)
              }}
              isDisabled={false}
            />
          </Form.Group>
        )
      }
    )
  }

  return (
    <>
      {loading ? (
        <div className="loading-layout">
          <LoadingComponent />
        </div>
      ) : (
        <>
          <Col sm={12} className="border-label">
            <div className="border-label-span">Supporting Documents</div>
            {renderSupportingDocumentArea()}
          </Col>
          {installmentList.length > 0 && (
            <Col sm={12} className="border-label mt-3">
              <div className="border-label-span">Installment Documents</div>
              {renderInstallmentDocuments()}
            </Col>
          )}
          {/* Confirmation Modal */}
          {showConfirm.isShow && (
            <ConfirmationModal
              name={'Confirmation'}
              message={showConfirm.message}
              onCancel={() => {
                setShowConfirm({
                  isShow: false,
                  message: '',
                  fileToRemove: null,
                })
              }}
              onConfirm={confirmRemoveFile}
              isRemark={false}
            />
          )}
        </>
      )}
    </>
  )
}

export default SectionFive
