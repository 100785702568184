import { FileRegistrationModel } from '../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { HouseDetailsModel } from '../../../models/applicationRegistration/loan/houseDetails'
import { FilterModel } from '../../../models/utils/filterModel'
import apiService from '../../api/apiManager'

class AhDataService {
  async apiFetchAhList(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/loan/ah/view/list`,
      filterData
    )
  }

  async apiFetchAhListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/loan/ah/view/count`,
      filterData
    )
  }

  async apiFetchAhReturnList(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/loan/ah/file-return/view/list`,
      filterData
    )
  }

  async apiFetchAhReturnListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/loan/ah/file-return/view/count`,
      filterData
    )
  }

  async apiFetchAhAttributeList(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/loan/ah/attribute/view/list`,
      filterData
    )
  }

  async apiFetchAhAttributeListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/loan/ah/attribute/view/count`,
      filterData
    )
  }

  async apiFetchAhFile(id: number) {
    return apiService.apiGet(`/application-registration/loan/ah/view/${id}`)
  }

  async apiFetchAhReturnFile(id: number) {
    return apiService.apiGet(
      `/application-registration/loan/ah/file-return/view/${id}`
    )
  }

  async apiAhProceed(file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/application-registration/loan/ah/add/proceed`,
      file
    )
  }

  async apiAhModify(file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/application-registration/loan/ah/file-return/modify/${file.id}`,
      file
    )
  }

  async apiAhReject(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/application-registration/loan/ah/${id}/reject`,
      { remark: remark }
    )
  }

  async apiAhReturn(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/application-registration/loan/ah/${id}/return`,
      { remark: remark }
    )
  }

  async apiAhClose(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/application-registration/loan/ah/${id}/close`,
      { remark: remark }
    )
  }

  async apiAhReOpen(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/application-registration/loan/ah/${id}/reopen`,
      { remark: remark }
    )
  }

  async apiAhReRegister(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/application-registration/loan/ah/${id}/register`,
      { remark: remark }
    )
  }
  async apiAhAddApplicantDetails(file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/application-registration/loan/ah/add/applicant`,
      file
    )
  }

  async apiAhAddAffectedHouseDetails(file: HouseDetailsModel) {
    return apiService.apiPutCommon(
      `/application-registration/loan/ah/add/${file.applicationFileId}/affected-house-details`,
      file
    )
  }

  async apiAhGetAffectedHouseDetails(id: number) {
    return apiService.apiGet(
      `/application-registration/loan/ah/view/${id}/affected-house-details`
    )
  }

  async apiAhAddDocument(file: FileRegistrationModel) {
    return apiService.apiPutCommon(
      `/application-registration/loan/ah/add/${file.id}/document`,
      file
    )
  }

  async apiAhGetDocument(id: number) {
    return apiService.apiGet(
      `/application-registration/loan/ah/view/${id}/document`
    )
  }

  async apiAhGetSupportingDocument(id: number) {
    return apiService.apiGet(
      `/application-registration/loan/ah/view/${id}/document`
    )
  }

  async apiAhAddSupportingDocument(formData: FormData, id: number) {
    return apiService.apiPutFormData(
      `/application-registration/loan/ah/add/${id}/document`,
      formData
    )
  }

  async apiAhRegisterDocument(id: number) {
    return apiService.apiPutCommon(
      `/application-registration/loan/ah/add/${id}/register`,
      {}
    )
  }
}

export default new AhDataService()
