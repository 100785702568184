import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Form, Dropdown } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import LoadingComponent from '../../../components/common/loading/LoadingComponent'
import ReactTableWithPaginationComponent from '../../../components/table/ReactTableWithPaginationComponent'
import { ActionListModal } from '../../../models/package/actionListModal'
import { getUserSubPackageActions } from '../../../store/user/selectors'
import CompensationValuesServices from '../../../services/systemConfiguration/compensationValues/compensationValuesServices'
import {
  ALERT_SUCCESS,
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../../utils/constants'
import {
  compensationValueActive,
  compensationValueInActive,
} from '../../../utils/successMessages'
import { showTotast } from '../../../utils/toast'
import { CompensationValuesModel } from '../../../models/systemConfiguration/compensationValues/compensationValuesModel'
import { currencyMask } from '../../../utils/utilFunctions'
type TParams = { id: string }
const ViewCompensationValues = ({ match }: RouteComponentProps<TParams>) => {
  const [loading, setLoading] = useState(true)
  const [aduitHistory, setAduitHistory] = useState([])
  const [compensationValuesState, setCompensationValuesState] = useState(
    {} as CompensationValuesModel
  )
  const history = useHistory()
  const actionList = useSelector(
    getUserSubPackageActions('system-configuration', 'compensation-value')
  )

  const checkPermission = (permissionName: string) => {
    const filterdAction = actionList.filter((action: ActionListModal) => {
      if (action.action.name === permissionName) {
        return action
      }
    })[0]
    if (filterdAction != null) {
      return true
    } else {
      return false
    }
  }
  useEffect(() => {
    async function getCompensationValues() {
      const compensationValues =
        await CompensationValuesServices.getCompensationValueById(
          parseInt(match.params.id)
        )
      setCompensationValuesState(compensationValues.data)
      const AduitHistoryData =
        await CompensationValuesServices.getCompensationValueHistory(
          parseInt(match.params.id)
        )
      setAduitHistory(AduitHistoryData.data)
      setLoading(false)
    }
    getCompensationValues()
  }, [])
  const aduitHistorycolumns = React.useMemo(
    () => [
      {
        Header: 'Action',
        accessor: 'action',
      },
      {
        Header: 'Scheme',
        accessor: 'scheme',
      },
      {
        Header: 'Incident Type',
        accessor: 'incidentType',
      },
      {
        Header: 'Compensation for',
        accessor: 'compensationFor',
      },
      {
        Header: 'Percentages among Relationship',
        accessor: 'percentageAmongRelationship',
      },
      {
        Header: 'Performed By',
        accessor: 'performedBy',
      },
      {
        Header: 'Property Compensation Percentage (%)',
        accessor: '',
      },
      {
        Header: 'Minimum Amount (Rs.)',
        accessor: 'minimumAmount',
        Cell: (e: any) => {
          if (e.cell.value) {
            return (
              <div className="cell-align-right">
                {e.row.original.minimumAmount
                  ? currencyMask(e.row.original.minimumAmount)
                  : ''}
              </div>
            )
          } else if (e.cell.value == 0 || e.cell.value == null) {
            return <div className="cell-align-right">{'N/A'}</div>
          }
        },
      },
      {
        Header: 'Maximum Amount (Rs.)',
        accessor: 'maximumAmount',
        Cell: (e: any) => {
          if (e.cell.value !== null) {
            return (
              <div className="cell-align-right">
                {e.row.original.maximumAmount
                  ? currencyMask(e.row.original.maximumAmount)
                  : ''}
              </div>
            )
          }
        },
      },
      {
        Header: 'Performed Date/Time',
        accessor: 'performedDateTime',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
    ],
    []
  )
  const columns = React.useMemo(
    () => [
      {
        Header: 'Relationship',
        accessor: 'name',
      },
      {
        Header: 'Rule',
        accessor: 'rule',
      },
    ],
    []
  )
  let statusAction = ''
  if (compensationValuesState.status == 'Active') {
    checkPermission('inactive') === true && (statusAction = 'Inactive')
  }
  if (compensationValuesState.status == 'Inactive') {
    checkPermission('active') === true && (statusAction = 'Active')
  }
  const handleSelect = async (e: any) => {
    if (e == 'Inactive') {
      const inActiveData =
        await CompensationValuesServices.apiInactiveCompensationValue(
          parseInt(match.params.id)
        )
      let success = false
      let message = inActiveData.errorMessage ? inActiveData.errorMessage : ''
      if (inActiveData.status === 200) {
        success = true
        message = compensationValueInActive
      }
      showTotast(
        success ? ALERT_SUCCESS : ALERT_WARNING,
        message,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setCompensationValuesState(inActiveData.data)
    }
    if (e == 'Active') {
      const activeData =
        await CompensationValuesServices.apiActiveCompensationValue(
          parseInt(match.params.id)
        )
      let success = false
      let message = activeData.errorMessage ? activeData.errorMessage : ''
      if (activeData.status === 200) {
        success = true
        message = compensationValueActive
      }
      showTotast(
        success ? ALERT_SUCCESS : ALERT_WARNING,
        message,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setCompensationValuesState(activeData.data)
    }
    const AduitHistoryData =
      await CompensationValuesServices.getCompensationValueHistory(
        parseInt(match.params.id)
      )
    setAduitHistory(AduitHistoryData.data)
  }
  const handleModify = () => {
    history.push(
      `/system-configuration/compensation-value/modify/${match.params.id}`
    )
  }
  return (
    <>
      {loading ? (
        <Container className="IncidentTypes-loading-layout">
          <LoadingComponent />
        </Container>
      ) : (
        <Row className="add-Incident-Type-page-layout">
          <Col sm={12} className="border-label">
            <div className="border-label-span">View Compensation Value</div>
            <Form>
              <Row className="mb-4">
                <Col sm={11}>
                  <Form.Group className="mb-3" controlId="status">
                    <Form.Label>Status</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      value={compensationValuesState.status}
                    />
                  </Form.Group>
                </Col>
                <Col sm={1} className="text-align-righ mt-2">
                  <Form.Group className="mb-3" controlId="action">
                    <Form.Label></Form.Label>
                    {checkPermission('inactive') ||
                    checkPermission('Active') ? (
                      <Dropdown onSelect={handleSelect}>
                        <Dropdown.Toggle
                          variant="secondary"
                          id="dropdown-basic"
                        >
                          Action
                        </Dropdown.Toggle>
                        <Dropdown.Menu align={{ lg: 'end' }}>
                          <Dropdown.Item eventKey={statusAction}>
                            {statusAction}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : (
                      ''
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="typeofProgram">
                    <Form.Label>Type of Program</Form.Label>
                    <Form.Control
                      type="text"
                      value={compensationValuesState.programType}
                      disabled
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="scheme">
                    <Form.Label>Scheme</Form.Label>
                    <Form.Control
                      type="text"
                      value={compensationValuesState.scheme}
                      disabled
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="incidentType">
                    <Form.Label>Incident Type</Form.Label>
                    <Form.Control
                      type="text"
                      value={compensationValuesState.incidentType}
                      disabled
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="compensationFor">
                    <Form.Label>Compensation for</Form.Label>
                    <Form.Control
                      type="text"
                      value={compensationValuesState.compensationFor}
                      disabled
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId=" percentagesAmongRelationship"
                  >
                    <Form.Label>Percentages among Relationship </Form.Label>
                    {compensationValuesState.percentageAmongRelationshipList ? (
                      <ReactTableWithPaginationComponent
                        columns={columns}
                        data={
                          compensationValuesState.percentageAmongRelationshipList
                        }
                        onClickRow={(row: any) => {
                          console.log(row)
                        }}
                      />
                    ) : (
                      ''
                    )}
                  </Form.Group>
                  {compensationValuesState.damageType ? (
                    <Form.Group className="mb-3" controlId="damageType">
                      <Form.Label>Damage Type</Form.Label>
                      <Form.Control
                        type="text"
                        value={compensationValuesState.damageType}
                        disabled
                      />
                    </Form.Group>
                  ) : (
                    ''
                  )}
                  {compensationValuesState.minimumAmount ? (
                    <Form.Group className="mb-3" controlId="minimumAmount">
                      <Form.Label>Minimum Amount (Rs.)</Form.Label>
                      <Form.Control
                        type="text"
                        value={
                          compensationValuesState.minimumAmount
                            ? currencyMask(
                                compensationValuesState.minimumAmount
                              )
                            : ''
                        }
                        disabled
                      />
                    </Form.Group>
                  ) : (
                    ''
                  )}
                  <Form.Group className="mb-3 mt-3" controlId="maximumAmount">
                    <Form.Label>Maximum Amount (Rs.)</Form.Label>
                    <Form.Control
                      type="text"
                      value={
                        compensationValuesState.maximumAmount
                          ? currencyMask(compensationValuesState.maximumAmount)
                          : ''
                      }
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Label>Audit History</Form.Label>
                  {aduitHistory ? (
                    <ReactTableWithPaginationComponent
                      columns={aduitHistorycolumns}
                      data={aduitHistory}
                      onClickRow={(row: any) => {
                        console.log(row)
                      }}
                    />
                  ) : (
                    ''
                  )}
                </Col>
              </Row>

              <Row>
                <Col sm={12} className="d-flex justify-content-end mt-4">
                  <button
                    className="ok-button"
                    onClick={(e) => {
                      e.preventDefault()
                      history.goBack()
                    }}
                  >
                    Ok
                  </button>
                  {checkPermission('modify') === true &&
                    compensationValuesState.status === 'Active' && (
                      <button className="modify-button" onClick={handleModify}>
                        Modify
                      </button>
                    )}
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      )}
    </>
  )
}

export default ViewCompensationValues
