import React, { useState } from 'react'
import { Tab, Row, Nav } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useRouteMatch, Route, Switch } from 'react-router-dom'
import {
  getUserModuleActions,
  getUserSubModuleActions,
} from '../../../../store/user/selectors'
import { checkPermission } from '../../../../utils/utilFunctions'
import PiApplication from './PiApplication'
import PiFileView from './PiFileView'
import PiReturn from './PiReturn'

const Pi = () => {
  const { path } = useRouteMatch()

  const [selectedSection, setSelectedSection] = useState('pi')

  const actionListModule = useSelector(
    getUserModuleActions('compensation-approval', 'compensation', 'pi')
  )

  const actionListSubModule = useSelector(
    getUserSubModuleActions(
      'compensation-approval',
      'compensation',
      'pi',
      'file-return'
    )
  )
  return (
    <Switch>
      <Route path={`${path}/`} exact>
        <Tab.Container id="left-tabs-example" defaultActiveKey="pi">
          <Row className="tab-page-layout">
            <Nav variant="pills" className="flex-row">
              {checkPermission('view', actionListModule) && (
                <Nav.Item className="custom-nav-item">
                  <Nav.Link
                    onClick={() => {
                      setSelectedSection('pi')
                    }}
                    eventKey="pi"
                  >
                    PI Application
                  </Nav.Link>
                </Nav.Item>
              )}
              {checkPermission('view', actionListSubModule) && (
                <Nav.Item className="custom-nav-item">
                  <Nav.Link
                    onClick={() => {
                      setSelectedSection('pi-return')
                    }}
                    eventKey="pi-return"
                  >
                    PI Return
                  </Nav.Link>
                </Nav.Item>
              )}
            </Nav>
          </Row>
          <Row className="page-tiles-layout align-content-start">
            {selectedSection === 'pi' ? (
              <>
                {checkPermission('view', actionListModule) && <PiApplication />}
              </>
            ) : (
              <>
                {checkPermission('view', actionListSubModule) && <PiReturn />}
              </>
            )}
          </Row>
        </Tab.Container>
      </Route>
      <Route
        path={`${path}/view/:id`}
        render={(props) => <PiFileView type="view" {...props} />}
      />
      <Route
        path={`${path}/return/:id`}
        render={(props) => <PiFileView type="file-return" {...props} />}
      />
    </Switch>
  )
}

export default Pi
