import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { Col, Container, Form, Row } from 'react-bootstrap'
import LoadingComponent from '../../../components/common/loading/LoadingComponent'
import './ModifyIncidentType.scss'
import produce from 'immer'
import { IncidentTypemodel } from '../../../models/systemConfiguration/incidentType/incidentTypemodel'
import incidentTypesServices from '../../../services/systemConfiguration/incidentTypes/incidentTypesServices'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import {
  ALERT_SUCCESS,
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../../utils/constants'
import * as validation from './validations'
import { showTotast } from '../../../utils/toast'
import ConfirmationModal from '../../../components/modals/ConfirmationModal'
import { IncidentTypeErrorModel } from '../../../models/systemConfiguration/incidentType/incidentTypeErrprModel'
import { validationError } from '../../../utils/errorMessages'
import { incidentTypeModify } from '../../../utils/successMessages'

type TParams = { id: string }
const initialState = {} as IncidentTypemodel
const ModifyIncidentType = ({ match }: RouteComponentProps<TParams>) => {
  const [loading, setLoading] = useState(true)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [incidentTypeState, setIncidentTypeState] = useState(initialState)
  const [errors, setErrors] = useState({} as IncidentTypeErrorModel)

  const history = useHistory()
  useEffect(() => {
    async function getSchemesData() {
      const incidentTypeData =
        await incidentTypesServices.apiGetIncidentTypesById(
          parseInt(match.params.id)
        )

      if (incidentTypeData.status === 200) {
        setIncidentTypeState(incidentTypeData.data)
        setLoading(false)
      }
    }
    getSchemesData()
  }, [])

  const findFormErrors = () => {
    const newErrors = {} as IncidentTypeErrorModel
    if (
      validation.validateIncidentDescription(incidentTypeState.incidentType)
    ) {
      newErrors.descriptionError = validation.validateIncidentDescription(
        incidentTypeState.description
      )
    }

    if (validation.validateEffectiveDate(incidentTypeState.effectiveDate)) {
      newErrors.effectiveDateError = validation.validateEffectiveDate(
        incidentTypeState.effectiveDate
      )
    }
    if (validation.validateEndDate(incidentTypeState.endDate)) {
      newErrors.endDateError = validation.validateEndDate(
        incidentTypeState.endDate
      )
    }
    return newErrors
  }
  const handleSubmit = async () => {
    const foundErrros = findFormErrors()
    if (Object.keys(foundErrros).length > 0) {
      showTotast(
        ALERT_WARNING,
        validationError,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setErrors(foundErrros)
    } else {
      setErrors(foundErrros)
      setShowConfirmation(true)
    }
  }
  const handleConfirm = async () => {
    const result = await incidentTypesServices.apiUpdateIncidentType(
      parseInt(match.params.id),
      incidentTypeState
    )
    let success = false
    let message = result.errorMessage ? result.errorMessage : ''
    if (result.status === 200) {
      success = true
      message = incidentTypeModify
      history.goBack()
    }
    showTotast(
      success ? ALERT_SUCCESS : ALERT_WARNING,
      message,
      TOAST_POSITION_TOP_RIGHT,
      5,
      TOAST_TRANSITION_SLIDE
    )
  }
  return (
    <>
      {loading ? (
        <Container className="add-Incident-Type-loading-layout">
          <LoadingComponent />
        </Container>
      ) : (
        <Row className="add-Incident-Type-page-layout">
          <Col sm={12} className="border-label">
            <div className="border-label-span">Modify Incident Type</div>
            <Form>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="incidentCategory">
                    <Form.Label>
                      Incident Category{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Form.Control
                      disabled
                      type="text"
                      value={incidentTypeState.incidentCategory}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="incidentType">
                    <Form.Label>
                      Incident Type{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Form.Control
                      disabled
                      type="text"
                      value={incidentTypeState.incidentType}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="incidentDescription">
                    <Form.Label>
                      Incident Description{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Form.Control
                      defaultValue={incidentTypeState.description}
                      isInvalid={!!errors.descriptionError}
                      type="text"
                      onBlur={(e) => {
                        const newError = produce(errors, (draft) => {
                          draft.descriptionError =
                            validation.validateIncidentDescription(
                              e.target.value
                            )
                        })
                        setErrors(newError)
                      }}
                      onChange={(e) => {
                        const newValue = produce(incidentTypeState, (draft) => {
                          draft.description = e.target.value
                        })
                        setIncidentTypeState(newValue)
                        const clearError = produce(errors, (draft) => {
                          draft.descriptionError = ''
                        })
                        setErrors(clearError)
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.descriptionError}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="effectiveDate">
                    <Form.Label>
                      Incident Date{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <DatePicker
                      className={
                        errors.effectiveDateError
                          ? 'date-picker-validate'
                          : 'form-control'
                      }
                      selected={
                        incidentTypeState.effectiveDate != null
                          ? new Date(incidentTypeState.effectiveDate * 1000)
                          : null
                      }
                      onSelect={(date: Date) => {
                        const newValue = produce(incidentTypeState, (draft) => {
                          draft.effectiveDate = new Date(date).getTime() / 1000
                        })
                        setIncidentTypeState(newValue)
                        const clearError = produce(errors, (draft) => {
                          draft.effectiveDateError = ''
                        })
                        setErrors(clearError)
                      }}
                      onChange={(date: Date) => {
                        const newValue = produce(incidentTypeState, (draft) => {
                          draft.effectiveDate = new Date(date).getTime() / 1000
                        })
                        setIncidentTypeState(newValue)
                        const clearError = produce(errors, (draft) => {
                          draft.effectiveDateError = ''
                        })
                        setErrors(clearError)
                      }}
                    />
                    <span className="feedback-error-span-custom">
                      {errors.effectiveDateError}
                    </span>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="endDate">
                    <Form.Label>
                      Completion Date{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <DatePicker
                      className={
                        errors.endDateError
                          ? 'date-picker-validate'
                          : 'form-control'
                      }
                      selected={
                        incidentTypeState.endDate != null
                          ? new Date(incidentTypeState.endDate * 1000)
                          : null
                      }
                      onSelect={(date: Date) => {
                        const newValue = produce(incidentTypeState, (draft) => {
                          draft.endDate = new Date(date).getTime() / 1000
                        })
                        setIncidentTypeState(newValue)
                        const clearError = produce(errors, (draft) => {
                          draft.endDateError = ''
                        })
                        setErrors(clearError)
                      }}
                      onChange={(date: Date) => {
                        const newValue = produce(incidentTypeState, (draft) => {
                          draft.endDate = new Date(date).getTime() / 1000
                        })
                        setIncidentTypeState(newValue)
                        const clearError = produce(errors, (draft) => {
                          draft.endDateError = ''
                        })
                        setErrors(clearError)
                      }}
                    />
                    <span className="feedback-error-span-custom">
                      {errors.endDateError}
                    </span>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
            <Row>
              <Col sm={12} className="d-flex justify-content-end mt-4">
                <button
                  className="cancel-button"
                  onClick={(e) => {
                    e.preventDefault()
                    history.goBack()
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="update-payment-type-button"
                  onClick={(e: any) => {
                    e.preventDefault()
                    history.push(
                      `/system-configuration/incident-type/update-payment-type/${incidentTypeState.id}`
                    )
                  }}
                >
                  Update Payment Type
                </button>
                <button
                  type="button"
                  className="save-button"
                  onClick={handleSubmit}
                >
                  Save
                </button>
              </Col>
            </Row>
          </Col>
          {showConfirmation && (
            <ConfirmationModal
              name={'Confirmation'}
              message={'Are you sure to create this incident type ?'}
              onCancel={() => {
                setShowConfirmation(false)
              }}
              onConfirm={handleConfirm}
            />
          )}
        </Row>
      )}
    </>
  )
}

export default ModifyIncidentType
