import React from 'react'
import { Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { BreadCrumbModal } from '../../models/breadcrumb/breadCrumb'
import { breadCrumbSelectRequest } from '../../store/breadCrumb/actions'
import { getBreadCrumbSelector } from '../../store/breadCrumb/selectors'

const BreadCrumb = () => {
  const breadCrumbList = useSelector(getBreadCrumbSelector)
  const history = useHistory()
  const dispatch = useDispatch()
  const handleButtonClick = (breadCrumb: BreadCrumbModal, index: number) => {
    dispatch(breadCrumbSelectRequest({ index: index }))
    history.push(`${breadCrumb.route}`)
  }

  const renderBreadCrumbList = () => {
    return breadCrumbList.map((breadCrumb: BreadCrumbModal, index: number) => {
      return (
        <>
          <button
            className={`bread-crumb ${
              index == breadCrumbList.length - 1 ? ' bread-crumb-active' : ''
            }`}
            key={`bread-crumb-${index}`}
            onClick={() => {
              handleButtonClick(breadCrumb, index)
            }}
          >
            {breadCrumb.name}
          </button>
          {index != breadCrumbList.length - 1 && (
            <span className="align-self-center">/</span>
          )}
        </>
      )
    })
  }
  return <Col className="d-flex">{renderBreadCrumbList()}</Col>
}

export default BreadCrumb
