import { FC, useState } from 'react'
import user from '../../assets/images/user.svg'
import lock from '../../assets/images/lock.svg'
import { Container, Form, InputGroup, Modal } from 'react-bootstrap'
import { handleNotification } from '../../utils/utilFunctions'
import { FileDeleteRequestModel } from '../../models/common/FileDeleteRequestModel'
import produce from 'immer'
import ApplicationModificationService from '../../services/applicationModification/ApplicationModificationService'

export interface Props {
  applicationFileId: any
  onCancel: any
  username: string
  show: boolean
  onSuccess: any
}

const FileDeleteModal: FC<Props> = ({
  applicationFileId,
  onCancel,
  username,
  show,
  onSuccess,
}) => {
  const [state, setState] = useState({
    userName: username,
    applicationFileId: applicationFileId,
  } as FileDeleteRequestModel)

  const handleSubmit = async () => {
    const response = await ApplicationModificationService.apiDeleteFile(state)
    if (response.status === 200) {
      onSuccess()
      onCancel()
    }
    handleNotification(response, 'Successfully Deleted ')
  }

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onCancel}
      aria-labelledby="example-modal-sizes-title-sm"
      dialogClassName="custom-modal-layout"
      contentClassName="custom-modal-content"
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        className="custom-modal-header"
      >
        <Modal.Title id="example-modal-sizes-title-sm">
          Confirmation of File Delete
        </Modal.Title>
      </Modal.Header>
      <Form
        className="p-2"
        onSubmit={(e) => {
          e.preventDefault()
          handleSubmit()
        }}
      >
        <Modal.Body>
          <Container className="login-form-layout">
            <Form.Group className="mb-3" controlId="loginUserName">
              <Form.Label className="login-label">User Name</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  disabled={true}
                  type="text"
                  placeholder="Username"
                  value={username}
                />
                <InputGroup.Text>
                  <img src={user} alt="" />
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3" controlId="loginPassword">
              <Form.Label className="login-label">Password</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  type="password"
                  placeholder="Password"
                  required
                  onChange={(e) => {
                    const newValue = produce(state, (draft) => {
                      draft.password = e.target.value
                    })
                    setState(newValue)
                  }}
                />
                <InputGroup.Text>
                  <img src={lock} alt="" />
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3" controlId="loginPassword">
              <Form.Label className="login-label">Enter Remark</Form.Label>
              <Form.Control
                as="textarea"
                type="text"
                placeholder="Enter Remark"
                required
                onChange={(e) => {
                  const newValue = produce(state, (draft) => {
                    draft.remark = e.target.value
                  })
                  setState(newValue)
                }}
              />
            </Form.Group>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="cancel-button" onClick={onCancel}>
            Close
          </button>
          <button type="submit" className={`save-button`}>
            Delete
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default FileDeleteModal
