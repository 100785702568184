export const SET_APP_STATE_LOADING = 'SET_APP_STATE_LOADING'
export const SET_APP_STATE_NOTIFICATION_COUNT =
  'SET_APP_STATE_NOTIFICATION_COUNT'

export interface ApplicationState {
  applicationLoading: boolean
  notificationCount: number
}

export interface ChangeLoadingRequest {
  type: typeof SET_APP_STATE_LOADING
}

export interface ChangeNotificationCountRequest {
  type: typeof SET_APP_STATE_NOTIFICATION_COUNT
  payload: number
}

export type ApplicationActions =
  | ChangeLoadingRequest
  | ChangeNotificationCountRequest
