import { FileRegistrationModel } from '../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { FilterModel } from '../../../models/utils/filterModel'
import apiService from '../../api/apiManager'

class FileRegistrationDataService {
  async apiFetchFileRegistrationList(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/file-registration/file-registration/view/list`,
      filterData
    )
  }

  async apiFetchFileRegistrationListCount(filterData: FilterModel) {
    return apiService.apiPost(
      `/application-registration/file-registration/file-registration/view/count`,
      filterData
    )
  }

  async apiFileRegistrationSearchSimilarRecord(file: FileRegistrationModel) {
    return apiService.apiPost(
      `/application-registration/file-registration/file-registration/add/search/similar-record`,
      file
    )
  }

  async apiFileRegistrationSearchSimilarRecordDrafted(
    file: FileRegistrationModel
  ) {
    return apiService.apiGet(
      `/meta-data/application-file/${file.id}/similar-record/list`
    )
  }

  async apiFileRegistrationDraft(file: FileRegistrationModel) {
    return apiService.apiPost(
      `/application-registration/file-registration/file-registration/add/draft`,
      file
    )
  }

  async apiFileRegistrationSave(file: FileRegistrationModel) {
    return apiService.apiPost(
      `/application-registration/file-registration/file-registration/add/save`,
      file
    )
  }

  async apiFetchFileRegistrationView(id: number) {
    return apiService.apiGet(
      `/application-registration/file-registration/file-registration/view/${id}`
    )
  }

  async apiFetchFileRegistrationCancel(id: number, remark: string) {
    return apiService.apiPutCommon(
      `/application-registration/file-registration/file-registration/cancel/${id}`,
      { remark: remark }
    )
  }
}

export default new FileRegistrationDataService()
