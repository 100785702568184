import React, { FC, useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import ReactDatePicker from 'react-datepicker'
import LoadingComponent from '../../../components/common/loading/LoadingComponent'
import PriceInputComponent from '../../../components/common/priceInputComponent/PriceInputComponent'
import ReactTableWithPaginationComponent from '../../../components/table/ReactTableWithPaginationComponent'
import { FileRegistrationModel } from '../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { DisbursementDetailsModel } from '../../../models/applicationRegistration/loan/disbursementDetails'
import enquireApplicationService from '../../../services/enquireApplication/enquireApplicationService'
import metaDataService from '../../../services/metaDataService'
import { disburstmentsHistoryColumns } from '../../../utils/metaData'

export interface DisbursmentDetailsProps {
  fileRegistrationObject: FileRegistrationModel
}

const DisbursmentDetails: FC<DisbursmentDetailsProps> = ({
  fileRegistrationObject,
}) => {
  const [loading, setLoading] = useState(true)
  const [disbursmentDetails, setDisbursmentDetails] = useState(
    {} as DisbursementDetailsModel
  )

  const [bankList, setBankList] = useState([])
  const [branchList, setBranchList] = useState([])
  const [historyList, setHistoryList] = useState([])
  useEffect(() => {
    async function getData() {
      const disbursmentData =
        await enquireApplicationService.apiFetchDisbursementDetails(
          fileRegistrationObject.id
        )
      const bankData = await metaDataService.getBankList()
      if (bankData.status == 200) {
        setBankList(bankData.data)
      }
      if (disbursmentData.status === 200) {
        getBranchesList(disbursmentData.data.bankId)
        setDisbursmentDetails(disbursmentData.data)
      }
      const historyData =
        await enquireApplicationService.apiFetchDisbursementHistoryDetails(
          fileRegistrationObject.id
        )
      if (historyData.status === 200) {
        setHistoryList(historyData.data)
      }
      setLoading(false)
    }
    getData()
  }, [])

  const getBranchesList = async (id: number) => {
    const branchData = await metaDataService.getBranchesList(id)
    if (branchData.status == 200) {
      setBranchList(branchData.data)
    } else {
      setBranchList([])
    }
  }

  return (
    <>
      {loading ? (
        <div className="loading-layout">
          <LoadingComponent />
        </div>
      ) : (
        <>
          <Col sm={12} className="border-label mb-4">
            <div className="border-label-span">Disbursement Details</div>
            <Form id="file-disbursment-details-form">
              <Form.Group className="mb-3" controlId="formDisbursementAmount">
                <Form.Label>
                  Amount <span className="required-field-astrix">*</span>
                </Form.Label>
                <PriceInputComponent
                  disabled={true}
                  value={disbursmentDetails.disbursementAmount}
                  placeholder="Disbursement Amount (Rs.)"
                />
              </Form.Group>
              <Form.Group as={Col} controlId="formDisbursmentDate">
                <Form.Label>
                  Date <span className="required-field-astrix">*</span>
                </Form.Label>
                <ReactDatePicker
                  disabled={true}
                  className={'form-control'}
                  placeholderText="Disbursment Date"
                  selected={
                    disbursmentDetails.disbursementDate != null
                      ? new Date(disbursmentDetails.disbursementDate * 1000)
                      : null
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formDisbursmentReason">
                <Form.Label>
                  Reason <span className="required-field-astrix">*</span>
                </Form.Label>
                <Form.Control
                  disabled={true}
                  type="text"
                  placeholder="Reason"
                  value={disbursmentDetails.reason}
                />
              </Form.Group>
              <Col sm={12} className="border-label mt-3 mb-3">
                <div className="border-label-span">{`Bank Details`}</div>
                <Row>
                  <Form.Group as={Col} controlId="formDpBankName">
                    <Form.Label>
                      {`Bank Name`}{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Form.Control
                      disabled={true}
                      className="form-select"
                      as="select"
                      value={disbursmentDetails.bankId}
                    >
                      {bankList.map(
                        (bank: { name: string; id: number }, index: number) => {
                          return (
                            <option key={`bank-${index}`} value={bank.id}>
                              {bank.name}
                            </option>
                          )
                        }
                      )}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} controlId="formDpBankName">
                    <Form.Label>
                      {`Branch Name`}{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Form.Control
                      className="form-select"
                      as="select"
                      disabled={true}
                      value={disbursmentDetails.branchId}
                    >
                      {branchList.map(
                        (
                          branch: { name: string; id: number },
                          index: number
                        ) => {
                          return (
                            <option key={`branch-${index}`} value={branch.id}>
                              {branch.name}
                            </option>
                          )
                        }
                      )}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} controlId="formDpAccountNumber">
                    <Form.Label>
                      Account Number{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Form.Control
                      disabled={true}
                      type="text"
                      placeholder="Enter Account Number"
                      value={disbursmentDetails.accountNumber}
                    />
                  </Form.Group>
                </Row>
              </Col>
            </Form>
          </Col>
        </>
      )}
      {historyList.length > 0 && (
        <Col sm={12} className="border-label mb-4">
          <div className="border-label-span">History</div>
          <Row className="mt-4">
            <ReactTableWithPaginationComponent
              columns={disburstmentsHistoryColumns}
              data={historyList}
              onClickRow={(row: any) => {
                console.log(row)
              }}
            />
          </Row>
        </Col>
      )}
    </>
  )
}

export default DisbursmentDetails
