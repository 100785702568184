import { DistrictOption } from '../models/selectOptionModels/districtOption'
import { DsOption } from '../models/selectOptionModels/dsOption'
import { GnOption } from '../models/selectOptionModels/gnOption'
import { ProvinceOption } from '../models/selectOptionModels/provinceOption'
import moment from 'moment'
import { AllocateToTypeOption } from '../models/selectOptionModels/allocateOption'
import { ActionListModal } from '../models/package/actionListModal'
import { ResponseObject } from '../services/api/apiManager'
import { showTotast } from './toast'
import {
  ALERT_SUCCESS,
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from './constants'
import { BreadCrumbModal } from '../models/breadcrumb/breadCrumb'
import { InjuryTypeOption } from '../models/selectOptionModels/injuryTypeOption'
import { FilterOptionModel } from '../models/utils/filterOption'
import { ValidationObjectModel } from '../models/common/validationObjectModel'
import { DashboardItemModel } from '../models/dashboard/dashboardItem'
import { DashboardFilterModel } from '../models/utils/dashboardFilterModel'
import { FileRegistrationModel } from '../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { LoanRepaymentGurantorDetailsModel } from '../models/applicationRegistration/loan/repaymentGurantorDetails'

// import { FileRegistrationModel } from '../models/applicationRegistration/fileRegistration/fileRegistrationModel'

export const setProvinceSelectOptions = (provinceDataList: any) => {
  const provinceListTemp: ProvinceOption[] = []
  for (let i = 0; i < provinceDataList.length; i++) {
    const province = {} as ProvinceOption
    province.value = provinceDataList[i].id
    province.label = provinceDataList[i].name
    provinceListTemp.push(province)
  }
  return provinceListTemp
}

export const setDistrictSelectOptions = (districtDataList: any) => {
  const districtListTemp: DistrictOption[] = []
  for (let i = 0; i < districtDataList.length; i++) {
    const district = {} as DistrictOption
    district.value = districtDataList[i].id
    district.label = districtDataList[i].name
    district.provinceId = districtDataList[i].provinceId
    districtListTemp.push(district)
  }
  return districtListTemp
}

export const setDsSelectOptions = (dsDataList: any) => {
  const dsListTemp: DsOption[] = []
  for (let i = 0; i < dsDataList.length; i++) {
    const ds = {} as DsOption
    ds.value = dsDataList[i].id
    ds.label = dsDataList[i].name
    ds.districtId = dsDataList[i].districtId
    dsListTemp.push(ds)
  }
  return dsListTemp
}

export const setGnSelectOptions = (gnDataList: any) => {
  const gnListTemp: GnOption[] = []
  for (let i = 0; i < gnDataList.length; i++) {
    const gn = {} as GnOption
    gn.value = gnDataList[i].id
    gn.label = gnDataList[i].name
    gn.dsId = gnDataList[i].dsDivisionId
    gnListTemp.push(gn)
  }
  return gnListTemp
}

export const setApplicantselectOptions = (applicantDataList: any) => {
  const applicantDataListTemp: AllocateToTypeOption[] = []
  for (let i = 0; i < applicantDataList.length; i++) {
    const applicant = {} as AllocateToTypeOption
    applicant.value = applicantDataList[i].name
    applicant.label = applicantDataList[i].name
    applicantDataListTemp.push(applicant)
  }
  return applicantDataListTemp
}

export const setInjuryTypeOptions = (injuryDataList: any) => {
  const injuryDataListTemp: InjuryTypeOption[] = []
  for (let i = 0; i < injuryDataList.length; i++) {
    const injury = {} as InjuryTypeOption
    injury.value = injuryDataList[i].id
    injury.label = injuryDataList[i].injuryDescription
    injury.categoryOfInjury = injuryDataList[i].categoryOfInjury
    injury.compensationEligibility = injuryDataList[i].compensationEligibility
    injury.disablementPeriod = injuryDataList[i].disablementPeriod
    injury.earningCapacityLoss = injuryDataList[i].earningCapacityLoss
    injury.typeOfDisablement = injuryDataList[i].typeOfDisablement
    injuryDataListTemp.push(injury)
  }
  return injuryDataListTemp
}

export const getOldAndNewNicFromGivenNic = (
  incidentDate?: Date,
  nic: string,
  type: string
) => {
  const identity = {
    oldNic: '',
    newNic: '',
    birthDate: new Date(),
    age: 0,
    gender: 'Male',
    error: true,
  }

  if (incidentDate == undefined) {
    incidentDate = new Date()
  }

  let valid = true
  if ((type === 'O' && nic.length < 10) || (type === 'N' && nic.length < 12)) {
    valid = false
  } else {
    const checkNic = type === 'N' ? nic : nic.substring(0, 9)
    const formattedNic = nicFormatFormat(checkNic)
    console.log(formattedNic)
    if (
      (type === 'O' && formattedNic.length < 9) ||
      (type === 'N' && formattedNic.length < 12)
    ) {
      valid = false
    }
  }

  if (valid) {
    identity.error = false
    let year = 0
    let day = 0
    if (type === 'O') {
      year = parseInt(`19${nic.substring(0, 2)}`)
      day = parseInt(nic.substring(2, 5))
      identity.oldNic = nic
      identity.newNic = `19${nic.substring(0, 5)}0${nic.substring(5, 9)}`
    } else {
      year = parseInt(nic.substring(0, 4))
      day = parseInt(nic.substring(4, 7))
      identity.newNic = nic
      identity.oldNic = `${nic.substring(2, 7)}${nic.substring(8, 12)}V`
    }

    if (day > 500) {
      day = day - 500
      identity.gender = 'Female'
    }
    if (year % 4 != 0 && day > 59) {
      day = day - 1
    }
    identity.birthDate = getDateFromYearAndDate(year, day)
    identity.age = getAgeByIncidentDate(incidentDate, identity.birthDate)
  }

  return identity
}

export const nicFormatFormat = (x: any) => {
  if (x != null || x != '') {
    return x.replace(/\D/g, '')
  }
  return ''
}

export const getDateFromYearAndDate = (year: number, day: number) => {
  const date = new Date(year, 0) // initialize a date in `year-01-01`
  return new Date(date.setDate(day)) // add the number of days
}

export const getAge = (dob: Date) => {
  const age = moment().diff(dob, 'years')
  return age > -1 ? age : 0
}

export const getAgeWithIncidnetDate = (dob: Date, incidentDate: Date) => {
  const age = moment(incidentDate).diff(dob, 'years')
  debugger
  return age >= 0 ? age : 0
}

export const getAgeByIncidentDate = (incidentDate: Date, dob: Date) => {
  const incident = moment(incidentDate)
  const birthDate = moment(dob)
  return incident.diff(birthDate, 'years')
}

// export function calculateAge(
//   birthDate: Date,
//   currentDate: Date = new Date()
// ): { years: number; months: number; days: number } {
//   let years = currentDate.getFullYear() - birthDate.getFullYear()
//   let months = currentDate.getMonth() - birthDate.getMonth()
//   let days = currentDate.getDate() - birthDate.getDate()

//   // Adjust for negative months or days
//   if (days < 0) {
//     months--
//     days += new Date(
//       currentDate.getFullYear(),
//       currentDate.getMonth(),
//       0
//     ).getDate()
//   }
//   if (months < 0) {
//     years--
//     months += 12
//   }

//   return {
//     years: years,
//     months: months,
//     days: days,
//   }
// }
export function calculateAge(birthday: any, incidentDate: any) {
  // Convert epoch times to Date objects
  const startDate = new Date(birthday) // Epoch time is in seconds, so multiply by 1000
  const endDate = new Date(incidentDate)

  if (startDate > endDate) {
    return {
      years: 0,
      months: 0,
      days: 0,
    }
  }

  // Ensure date1 is the earlier date
  // const startDate = date1 < date2 ? date1 : date2
  // const endDate = date1 < date2 ? date2 : date1

  // Extract years, months, and days
  const startYear = startDate.getFullYear()
  const startMonth = startDate.getMonth()
  const startDay = startDate.getDate()

  const endYear = endDate.getFullYear()
  const endMonth = endDate.getMonth()
  const endDay = endDate.getDate()

  // Initialize differences
  let yearDiff = endYear - startYear
  let monthDiff = endMonth - startMonth
  let dayDiff = endDay - startDay

  // Adjust days and months if necessary
  if (dayDiff < 0) {
    // Borrow days from the previous month
    const previousMonth = (endMonth - 1 + 12) % 12
    const daysInPreviousMonth = new Date(
      endYear,
      previousMonth + 1,
      0
    ).getDate()
    dayDiff += daysInPreviousMonth
    monthDiff--
  }

  if (monthDiff < 0) {
    // Borrow months from the previous year
    monthDiff += 12
    yearDiff--
  }

  return {
    years: yearDiff,
    months: monthDiff,
    days: dayDiff,
  }
}

export const getAgeByMiliSeconds = (mili: number) => {
  const dob = new Date(mili * 1000)
  return moment().diff(dob, 'years')
}

export const getGenderByNic = (nic: string) => {
  if (nic == null || nic == undefined || nic.length < 12) {
    return ''
  } else {
    const genderValues = parseInt(nic.substring(4, 7))
    console.log(genderValues)

    return genderValues < 500 ? 'Male' : 'Female'
  }
}

export const seAllocateUsersOptions = (userList: any) => {
  const allocateUserTemp: AllocateToTypeOption[] = []
  for (let i = 0; i < userList.length; i++) {
    const allocateUser = {} as AllocateToTypeOption
    allocateUser.value = userList[i].epfNumber
    allocateUser.label = `${userList[i].firstName} ${userList[i].lastName}`
    allocateUserTemp.push(allocateUser)
  }
  return allocateUserTemp
}

export const checkPermission = (
  permissionName: string,
  actionList: ActionListModal[]
) => {
  const filterdAction = actionList.filter((action: ActionListModal) => {
    if (action.action.name === permissionName) {
      return action
    }
  })[0]
  if (filterdAction != null) {
    return true
  } else {
    return false
  }
}

export const handleNotification = (
  response: ResponseObject,
  sucesssMessage: string
) => {
  console.log(response)
  let success = false
  let message = response.errorMessage ? response.errorMessage : ''
  if (response.status === 200 || response.status === 204) {
    message = sucesssMessage
    success = true
  }
  showTotast(
    success ? ALERT_SUCCESS : ALERT_WARNING,
    message,
    TOAST_POSITION_TOP_RIGHT,
    5,
    TOAST_TRANSITION_SLIDE
  )
}

export const FetchRequesthandleNotification = (
  response: ResponseObject,
  sucesssMessage: string
) => {
  console.log(response)
  let success = false
  let message = ''
  if (response.status === 200 || response.status === 204) {
    message = sucesssMessage
    success = true
  } else {
    message = 'Something went wrong'
  }
  showTotast(
    success ? ALERT_SUCCESS : ALERT_WARNING,
    message,
    TOAST_POSITION_TOP_RIGHT,
    5,
    TOAST_TRANSITION_SLIDE
  )
}
export const renderIdentificationTypeName = (typeName: string) => {
  switch (typeName) {
    case 'TIC': {
      return 'Tic'
      break
    }
    case 'PASSPORT': {
      return 'Passport'
      break
    }
    case 'SENIOR_CITIZEN_ID': {
      return 'Senior Citizen ID'
      break
    }
    default: {
      return 'Driving License'
      break
    }
  }
}

export const renderIdentificationTypeValue = (
  typeName: string,
  fileRegistrationObject: any
) => {
  switch (typeName) {
    case 'TIC': {
      return fileRegistrationObject.ticNumber
      break
    }
    case 'PASSPORT': {
      return fileRegistrationObject.passportNumber
      break
    }
    case 'SENIOR_CITIZEN_ID': {
      return fileRegistrationObject.seniorCitizenId
      break
    }
    default: {
      return fileRegistrationObject.drivingLicenseNumber
      break
    }
  }
}

export const getDateToInputField = (date: Date) => {
  if (
    !(date == null || date === undefined) &&
    date.getFullYear() != undefined
  ) {
    const dateString = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`
    console.log(dateString)
    return dateString
  }
  return ''
}

export const getSelectedInjuryTypeList = (
  injuryTypeList: InjuryTypeOption[],
  id: number
) => {
  return injuryTypeList.filter((injury: InjuryTypeOption) => {
    return injury.value === id
  })
}

export const getInjuryTypeDisabled = (schema: string) => {
  if (schema === 'PD' || schema === 'GSD') {
    return true
  } else {
    return false
  }
}

export const getApplicantTypeDisabled = (schema: string) => {
  if (schema === 'PD' || schema === 'GSD' || schema === 'GPC') {
    return true
  } else {
    return false
  }
}

export const getDependentRelationTypeEnable = (
  schema: string,
  checkPrintTo: string,
  applicantType: string
) => {
  if (
    schema === 'GSI' ||
    applicantType === 'Primary Applicant' ||
    (schema === 'PI' && checkPrintTo === 'Aggrived')
  ) {
    return false
  } else {
    return true
  }
}

export const getConfirmationOfDeath = (schema: string) => {
  if (schema === 'PD' || schema === 'GSD') {
    return true
  } else {
    return false
  }
}

export const getConfirmationOfInjury = (schema: string) => {
  if (schema === 'PI' || schema === 'GSI') {
    return true
  } else {
    return false
  }
}

export const getInitialBreadCrumb = (location: string) => {
  console.log(location)
  const sections = location.split('/')
  console.log(sections)
  const home: BreadCrumbModal = {
    active: true,
    name: 'Home',
    route: '/',
  }
  const breadCrumbList = [home]
  let route = ''
  for (let i = 1; i < sections.length; i++) {
    const breadCrumb = {} as BreadCrumbModal
    if (i <= 4) {
      const section = sections[i]
      route = route + '/' + section
      breadCrumb.route = route
      breadCrumb.name = breadCrumbPathToName(section)
      breadCrumb.active = false
      breadCrumbList.push(breadCrumb)
    }
  }
  console.log(breadCrumbList)
  return breadCrumbList
}

const breadCrumbPathToName = (path: string) => {
  const name = path.replaceAll('-', ' ')
  switch (name) {
    case 'pd':
      return 'PD Application'
    case 'pi':
      return 'PI Application'
    case 'gsi':
      return 'GSI Application'
    case 'gsd':
      return 'GSD Application'
    case 'gsc':
      return 'GSC Application'
    case 'gpc':
      return 'GPC Application'
    case 'rlp':
      return 'RLP Application'
    case 'ah':
      return 'AH Application'
    case 'se':
      return 'SE Application'
    case 'srt':
      return 'SRT Application'
    default:
      break
  }
  return name
}

export const checkStatsDraftOrModifyDraft = (status: string) => {
  if (
    status === 'Application Drafted' ||
    status === 'Application Modify Draft'
  ) {
    return true
  } else {
    return false
  }
}

export const checkLoggedUser = (epfNumber: string, allocaeTo: string) => {
  if (epfNumber === allocaeTo) {
    return true
  }
  return false
}

// const RecommendtoPayOrReject = (status: string) => {
//   if (status === 'Recommend to Pay' || status === 'Recommend to Reject') {
//     return true
//   }
//   return false
// }

// // approve button validation in compenstation recomendation, approval and certification
// export const checkStatsForApproval = (status: string, packageName: string) => {
//   if (
//     ((RecommendtoPayOrReject(status) || 'Approval Returned') &&
//       packageName == 'compensation-approval') ||
//     ((status === 'Certification Pending' || 'Certification Returned') &&
//       packageName == 'compensation-certification')
//   ) {
//     return true
//   } else {
//     return false
//   }
// }

// export const checkStatsForRecommendation = (
//   status: string,
//   packageName: string
// ) => {
//   if (
//     (status === 'Recommendation Pending' ||
//       status === 'Recommendation Returned') &&
//     packageName == 'compensation-recommendation'
//   ) {
//     return true
//   } else {
//     return false
//   }
// }

// export const checkStatsForReturn = (status: string, packageName: string) => {
//   if (
//     ((status === 'Recommendation Pending' ||
//       status === 'Recommendation Returned') &&
//       packageName == 'compensation-recommendation') ||
//     ((RecommendtoPayOrReject(status) || status === 'Approval Returned') &&
//       packageName == 'compensation-approval') ||
//     ((status === 'Certification Pending' ||
//       status === 'Certification Returned') &&
//       packageName == 'compensation-certification')
//   ) {
//     return true
//   } else {
//     return false
//   }
// }

// export const checkStatsForReject = (status: string, packageName: string) => {
//   if (
//     ((RecommendtoPayOrReject(status) || status === 'Approval Returned') &&
//       packageName == 'compensation-approval') ||
//     ((status === 'Certification Pending' ||
//       status === 'Certification Returned') &&
//       packageName == 'compensation-certification')
//   ) {
//     return true
//   } else {
//     return false
//   }
// }

export const checkStatsForApproval = (status: string, packageName: string) => {
  switch (packageName) {
    case 'compensation-recommendation':
      if (
        status == 'Recommendation Pending' ||
        status == 'Recommendation Returned'
      ) {
        return true
      }
      break

    case 'compensation-approval':
      if (
        status == 'Recommend to Pay' ||
        status == 'Recommend to Reject' ||
        status == 'Approval Returned'
      ) {
        return true
      }
      break

    case 'compensation-certification':
      if (
        status == 'Certification Pending' ||
        status == 'Certification Returned'
      ) {
        return true
      }

      break

    default:
      return false
      break
  }
}

export const checkStatsForReturn = (status: string, packageName: string) => {
  switch (packageName) {
    case 'compensation-recommendation':
      if (
        status == 'Recommendation Pending' ||
        status == 'Recommendation Returned'
      ) {
        return true
      }
      break

    case 'compensation-approval':
      if (
        status == 'Recommend to Pay' ||
        status == 'Recommend to Reject' ||
        status == 'Approval Returned'
      ) {
        return true
      }
      break

    case 'compensation-certification':
      if (
        status == 'Certification Pending' ||
        status == 'Certification Returned'
      ) {
        return true
      }

      break

    default:
      return false
      break
  }
}

export const checkStatsForReject = (status: string, packageName: string) => {
  switch (packageName) {
    case 'compensation-recommendation':
      if (
        status == 'Recommendation Pending' ||
        status == 'Recommendation Returned'
      ) {
        return true
      }
      break

    case 'compensation-approval':
      if (
        status == 'Recommend to Pay' ||
        status == 'Recommend to Reject' ||
        status == 'Approval Returned'
      ) {
        return true
      }
      break

    case 'compensation-certification':
      if (
        status == 'Certification Pending' ||
        status == 'Certification Returned'
      ) {
        return true
      }

      break

    default:
      return false
      break
  }
}

export const getFilterObjectList = (
  selectedOption: any,
  filter: FilterOptionModel
) => {
  console.log(selectedOption)
  const returnFilterList = [] as FilterOptionModel[]

  for (let i = 0; i < selectedOption.length; i++) {
    const filterObject = {
      value: selectedOption[i].value,
      groupProperty: filter.property,
      property: filter.property,
      operator: filter.operator,
      selected: true,
    } as FilterOptionModel
    if (i == 0) {
      filterObject.groupingOperator = 'AND'
    } else {
      filterObject.groupingOperator = 'OR'
    }
    returnFilterList.push(filterObject)
  }
  return returnFilterList
}

export const reselectFilterOption = (
  requestedFilters: FilterOptionModel[],
  checked: boolean,
  property: string
) => {
  let newRequestedFilter = [] as FilterOptionModel[]
  for (let i = 0; i < requestedFilters.length; i++) {
    if (requestedFilters[i].property === property) {
      const filterObject = {
        value: requestedFilters[i].value,
        groupingOperator: requestedFilters[i].groupingOperator,
        groupProperty: requestedFilters[i].property,
        property: requestedFilters[i].property,
        operator: requestedFilters[i].operator,
        selected: checked,
      } as FilterOptionModel
      newRequestedFilter.push(filterObject)
    } else {
      newRequestedFilter = requestedFilters
    }
  }
  return newRequestedFilter
}

// export const validateForm = (validationProps: ValidationObjectModel) => {
//   console.log(validationProps)
//   if (!validationProps.value && validationProps.value !== 0) {
//     if (validationProps.customMessage) {
//       return validationProps.customMessage
//     } else {
//       return `${validationProps.section} cannot be empty !`
//     }
//   } else {
//     if (Array.isArray(validationProps.value)) {
//       window.alert('Array')
//     } else {
//       if (!/^-?[\d.]+(?:e-?\d+)?$/.test(validationProps.value)) {
//         if (validationProps.value.length > validationProps.maxLength) {
//           if (validationProps.customMessage) {
//             return validationProps.customMessage
//           } else {
//             return `${validationProps.section} cannot be more than ${validationProps.maxLength} characters !`
//           }
//         } else if (validationProps.value.length > validationProps.minLength) {
//           if (validationProps.customMessage) {
//             return validationProps.customMessage
//           } else {
//             return `${validationProps.section} cannot be less than ${validationProps.minLength} characters !`
//           }
//         }
//       } else if (/^-?[\d.]+(?:e-?\d+)?$/.test(validationProps.value)) {
//         if (
//           validationProps.value.toString().length > validationProps.maxLength
//         ) {
//           if (validationProps.customMessage) {
//             return validationProps.customMessage
//           } else {
//             return `${validationProps.section} cannot be more than ${validationProps.maxLength} digits !`
//           }
//         } else if (
//           validationProps.value.toString().length <= validationProps.minLength
//         ) {
//           if (validationProps.customMessage) {
//             return validationProps.customMessage
//           } else {
//             return `${validationProps.section} cannot be or less than ${validationProps.minLength} digits !`
//           }
//         } else if (
//           parseFloat(validationProps.value) > validationProps.maxValue
//         ) {
//           if (validationProps.customMessage) {
//             return validationProps.customMessage
//           } else {
//             return `${validationProps.section} cannot be more than ${validationProps.maxValue} !`
//           }
//         } else if (
//           parseFloat(validationProps.value) < validationProps.minValue
//         ) {
//           if (validationProps.customMessage) {
//             return validationProps.customMessage
//           } else {
//             return `${validationProps.section} cannot be less than ${validationProps.minValue} or empty !`
//           }
//         }
//       }
//     }
//   }
// }

// Number.prototype.countDecimals = function () {
//   if (Math.floor(this.valueOf()) === this.valueOf()) return 0
//   return this.toString().split('.')[1].length || 0
// }

export const validateForm = (
  validationProps: ValidationObjectModel,
  isMandatory = true
) => {
  // console.log(validationProps)
  if (!validationProps.value && validationProps.value !== 0) {
    if (isMandatory) {
      if (validationProps.type === 'boolean') {
        if (validationProps.value == undefined) {
          if (validationProps.customMessage) {
            return validationProps.customMessage
          } else {
            return `${validationProps.section} cannot be empty !`
          }
        }
      } else {
        if (validationProps.customMessage) {
          return validationProps.customMessage
        } else {
          return `${validationProps.section} cannot be empty !`
        }
      }
    }
  } else {
    if (validationProps.type === 'text') {
      if (validationProps.value.length > validationProps.maxLength) {
        if (validationProps.customMessage) {
          return validationProps.customMessage
        } else {
          return `${validationProps.section} cannot be more than ${validationProps.maxLength} characters !`
        }
      } else if (validationProps.value.length < validationProps.minLength) {
        if (validationProps.customMessage) {
          return validationProps.customMessage
        } else {
          return `${validationProps.section} cannot be less than ${validationProps.minLength} characters !`
        }
      }
    }
    if (validationProps.type === 'number') {
      let decimalPoints = 0
      const numStr = String(validationProps.value)
      if (numStr.includes('.')) {
        decimalPoints = numStr.split('.')[1].length
      }
      let newMaxleght = validationProps.maxLength
      if (decimalPoints == 1) {
        newMaxleght = validationProps.maxLength + 2
      } else if (decimalPoints == 2) {
        newMaxleght = validationProps.maxLength + 3
      }
      if (validationProps.value.toString().length > newMaxleght) {
        if (validationProps.customMessage) {
          return validationProps.customMessage
        } else {
          return `${validationProps.section} cannot be more than ${validationProps.maxLength} digits !`
        }
      } else if (
        validationProps.value.toString().length <= validationProps.minLength
      ) {
        if (validationProps.customMessage) {
          return validationProps.customMessage
        } else {
          return `${validationProps.section} cannot be or less than ${validationProps.minLength} digits !`
        }
      } else if (parseFloat(validationProps.value) > validationProps.maxValue) {
        if (validationProps.customMessage) {
          return validationProps.customMessage
        } else {
          return `${validationProps.section} cannot be more than ${validationProps.maxValue} !`
        }
      } else if (parseFloat(validationProps.value) < validationProps.minValue) {
        if (validationProps.customMessage) {
          return validationProps.customMessage
        } else {
          return `${validationProps.section} cannot be less than ${validationProps.minValue} or empty !`
        }
      }
    }
    if (validationProps.type === 'array') {
      if (validationProps.value.length > validationProps.maxLength) {
        return validationProps.customMessage
      } else if (validationProps.value.length > validationProps.minLength) {
        return validationProps.customMessage
      }
    }
    if (validationProps.type === 'date') {
      if (parseInt(validationProps.value) > validationProps.maxValue) {
        if (validationProps.customMessage) {
          return validationProps.customMessage
        } else {
          return `${validationProps.section} cannot be future date !`
        }
      }
    }
    if (validationProps.type === 'mobile') {
      if (!mobileNumberValidation(validationProps.value)) {
        if (validationProps.customMessage) {
          return validationProps.customMessage
        } else {
          return `${validationProps.section} not valid !`
        }
      }
    }
  }
}
export const currencyMask = (num: any) => {
  // return 'RS-: ' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  if (num == null || num == undefined) {
    return ''
  }
  return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const currencyMaskForString = (value: any) => {
  const num = parseFloat(value)
  if (num == null || num == undefined || isNaN(num)) {
    return value
  }
  return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const returnFileType = (type: string) => {
  switch (type) {
    case 'application/pdf':
      return 'PDF'
    case 'image/png':
      return 'PNG'
    case 'image/jpeg':
      return 'JPEG'
    case 'image/jpg':
      return 'JPEG'
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'Word'
    default:
      return 'Unkown'
  }
}

export const returnDashboardData = (dashboardItems: DashboardItemModel[]) => {
  const returnList = [] as DashboardFilterModel[]
  for (let i = 0; i < dashboardItems.length; i++) {
    const filterItem = {
      selected: true,
      label: dashboardItems[i].status,
      value: null,
      to: null,
    } as DashboardFilterModel
    returnList.push(filterItem)
  }
  return returnList
}

export const fileRedirectUtil = (
  status: string,
  programeType: string,
  scheme: string,
  fileId: number
) => {
  let basePath = '/'
  switch (status.toUpperCase()) {
    case 'FILE DRAFTED':
      basePath = `application-registration/file-registration/file-registration/draft/${fileId}`
      break
    case 'FILE ALLOCATION PENDING':
      basePath = `application-registration/file-registration/file-allocation/pending-file-allocation/view/${fileId}`
      break
    case 'FILE RETURNED':
      basePath = `application-registration/file-registration/file-registration/application-file/file-return/view/${fileId}`
      break
    case 'FILE RE-CREATED':
    case 'APPLICATION RE-ALLOCATED':
    case 'APPLICATION DRAFTED':
    case 'APPLICATION CLOSED':
    case 'APPLICATION MODIFY DRAFT':
    case 'APPLICATION CREATION PENDING':
      basePath = `application-registration/${programeType.toLowerCase()}/${scheme.toLowerCase()}/view/${fileId}`
      break
    case 'APPLICATION RETURNED':
      basePath = `application-registration/${programeType.toLowerCase()}/${scheme.toLowerCase()}/return/${fileId}`
      break
    case 'RECOMMENDATION PENDING':
      basePath = `compensation-recommendation/${programeType.toLowerCase()}/${scheme.toLowerCase()}/view/${fileId}`
      break
    case 'RECOMMENDATION RETURNED':
      basePath = `compensation-recommendation/${programeType.toLowerCase()}/${scheme.toLowerCase()}/return/${fileId}`
      break
    case 'APPROVAL PENDING':
      basePath = `compensation-approval/${programeType.toLowerCase()}/${scheme.toLowerCase()}/view/${fileId}`
      break
    case 'APPROVAL RETURNED':
      basePath = `compensation-approval/${programeType.toLowerCase()}/${scheme.toLowerCase()}/return/${fileId}`
      break
    case 'CERTIFICATION PENDING':
      basePath = `compensation-certification/${programeType.toLowerCase()}/${scheme.toLowerCase()}/view/${fileId}`
      break
    case 'CERTIFICATION RETURNED':
      basePath = `compensation-certification/${programeType.toLowerCase()}/${scheme.toLowerCase()}/return/${fileId}`
      break
    case 'CHEQUE PAYMENT PENDING':
    case 'CHEQUE PAYMENT RE-CREATED':
      basePath = `cheque-payment/manage-cheque-details/${scheme.toLowerCase()}/add/${fileId}`
      break
    case 'CHEQUE PRINT PENDING':
      basePath = `cheque-payment/cheque-print/${scheme.toLowerCase()}/view-cheques/${fileId}`
      break
    case 'CHEQUE PAYMENT RETURNED':
      basePath = `cheque-payment/manage-cheque-details/${scheme.toLowerCase()}`
      break
    case 'PAYMENT COMPLETED':
      basePath = `cheque-payment/cheque-print/${scheme.toLowerCase()}`
      break
    case 'LOAN RESCHEDULED':
    case 'LOAN DISBURSED':
    case 'LOAN NPA':
    case 'LEGAL ACTION TAKEN':
    case 'DISBURSEMENT PENDING':
      basePath = `application-registration/${programeType.toLowerCase()}/${scheme.toLowerCase()}/attribute/${fileId}`
      break
    default:
      //to do chanage after routing fix
      basePath = `enquire-application/view/${fileId}`
      break
  }

  return basePath
}

export const checkInstallmentMergeEnable = (
  length: number,
  status: string,
  modifyIndex: number
) => {
  if (
    length > 0 &&
    (status === 'Application Drafted' ||
      status === 'Application Modify Draft') &&
    (modifyIndex == 0 || modifyIndex == 1 || modifyIndex == 3)
  ) {
    return true
  }
  return false
}

export const checkApplicationRegistrationButtonEnable = (
  status: string,
  modifyIndex: number
) => {
  if (
    (status === 'Application Drafted' ||
      status === 'Application Modify Draft') &&
    modifyIndex == 0
  ) {
    return true
  }
  return false
}

export const checkDependentSectionEnable = (
  status: string,
  modifyIndex: number
) => {
  if (
    (status === 'Application Drafted' ||
      status === 'Application Modify Draft') &&
    (modifyIndex == 0 || modifyIndex == 5 || modifyIndex == 4)
  ) {
    return true
  }
  return false
}

export const checkDependentEditDisabled = (
  status: string,
  modifyIndex: number
) => {
  if (
    (status === 'Application Drafted' ||
      status === 'Application Modify Draft') &&
    (modifyIndex == 0 || modifyIndex == 4)
  ) {
    return false
  }
  return true
}

export const checkInstallmentDocumentEnable = (
  status: string,
  modifyIndex: number
) => {
  if (
    (status === 'Application Drafted' ||
      status === 'Application Modify Draft') &&
    (modifyIndex == 0 || modifyIndex == 6)
  ) {
    return true
  }
  return false
}

export const checkSectionThreeNextButtonDisable = (
  paymentType: string,
  installmentLength: number
) => {
  if (paymentType === 'Installment' && installmentLength == 0) {
    return true
  } else false
}

export const getSectionFiveSuccessMessage = (
  fileRegistrationObject: FileRegistrationModel
) => {
  if (fileRegistrationObject.modifyIndex > 0) {
    return 'Application Updated Successfully'
  } else if (fileRegistrationObject.status === 'Application Drafted') {
    return 'Application Created Successfully'
  }
  return 'Application Created Successfully'
}

export const thousandSeperator = (amount: number) => {
  if (amount != null) {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  return ''
}

export const customCellRender = (cell: any) => {
  switch (cell.column.id) {
    case 'createdDateTime':
    case 'modifiedDateTime':
    case 'performedDateTime':
    case 'sendDateTime':
      return cell.value == null
        ? 'N/A'
        : new Date(cell.value * 1000).toLocaleString()
    case 'effectiveDate':
    case 'sentDate':
    case 'dateOfBirth':
    case 'receivedDate':
    case 'fileReceivedDate':
    case 'cheque_issued_date':
    case 'chequeIssuedDate':
    case 'startDate':
    case 'endDate':
      return cell.value == null
        ? 'N/A'
        : new Date(cell.value * 1000).toLocaleDateString()
    case 'identificationType':
      const identificationType = cell.value
      if (identificationType == null) {
        return cell.render('Cell')
      } else {
        switch (identificationType) {
          case 'TIC':
            return 'TIC'
          case 'PASSPORT':
            return 'Passport'
          case 'SENIOR_CITIZEN_ID':
            return 'Senior Citizen ID'
          case 'DRIVING_LICENSE':
            return 'Driving License'
          case 'BIRTH_CERTIFICATE':
            return 'Birth Certificate'
          default:
            return 'NIC'
        }
      }
    default:
      return cell.render('Cell')
  }
}

export const checkView = (
  fileRegistrationObject: FileRegistrationModel,
  loggedUser: string
) => {
  if (
    (fileRegistrationObject.status === 'Application Drafted' ||
      fileRegistrationObject.status === 'Application Modify Draft') &&
    fileRegistrationObject.allocateTo == loggedUser
  ) {
    return true
  }

  return false
}

export const checkNicMandatory = (identificationType: string) => {
  if (
    identificationType === 'SENIOR_CITIZEN_ID' ||
    identificationType === 'TIC' ||
    identificationType === 'BIRTH_CERTIFICATE'
  ) {
    return false
  }

  return true
}

export const mobileNumberValidation = (mobileNo: string) => {
  const mobileReg = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
  return mobileReg.test(mobileNo)
}

export const renderPayeeNames = (
  payeeList: LoanRepaymentGurantorDetailsModel[]
) => {
  let names = ''
  for (let i = 0; i < payeeList.length; i++) {
    names = names + payeeList[i].guarantorDetails.name
    if (i != payeeList.length - 1) {
      names = names + ' , '
    }
  }
  return names
}

export const checkGenderDisable = (
  modifyIndex: number,
  selectionType: string
) => {
  if (modifyIndex > 0 || selectionType === 'NIC') {
    return true
  }
  return false
}

export const handleDateValue = (value: any) => {
  if (!value || value == 0 || value == '') {
    return undefined
  } else {
    return new Date(value * 1000).toLocaleDateString()
  }
}
