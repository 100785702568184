import React, { useEffect, useState } from 'react'
import { Col, Container, Dropdown, Form, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import LoadingComponent from '../../../components/common/loading/LoadingComponent'
import { ActionListModal } from '../../../models/package/actionListModal'
import { getUserSubPackageActions } from '../../../store/user/selectors'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import {
  ALERT_SUCCESS,
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../../utils/constants'
import PropertyDamageTypeServices from '../../../services/systemConfiguration/propertyDamageType/propertyDamageTypeServices'
import { showTotast } from '../../../utils/toast'
import {
  propertyActive,
  propertyInActive,
} from '../../../utils/successMessages'
import { PropertyDamageTypeModel } from '../../../models/systemConfiguration/propertyDamageType/propertyDamageTypeModel'
import ReactTableWithPaginationComponent from '../../../components/table/ReactTableWithPaginationComponent'
type TParams = { id: string }
const ViewPropertyDamageType = ({ match }: RouteComponentProps<TParams>) => {
  const [loading, setloading] = useState(true)
  const [applicableValuesList, setApplicableValuesList] = useState()
  const [aduitHistory, setAduitHistory] = useState([])
  const [propertyState, setPropertyState] = useState(
    {} as PropertyDamageTypeModel
  )
  const history = useHistory()
  const actionList = useSelector(
    getUserSubPackageActions('system-configuration', 'incident-type')
  )

  const checkPermission = (permissionName: string) => {
    const filterdAction = actionList.filter((action: ActionListModal) => {
      if (action.action.name === permissionName) {
        return action
      }
    })[0]
    if (filterdAction != null) {
      return true
    } else {
      return false
    }
  }
  useEffect(() => {
    async function getIncidentTypesData() {
      const propertyData = await PropertyDamageTypeServices.getPropertyById(
        parseInt(match.params.id)
      )
      setPropertyState(propertyData.data)

      const propertyHistoryData =
        await PropertyDamageTypeServices.getPropertyHistoryById(
          parseInt(match.params.id)
        )
      setAduitHistory(propertyHistoryData.data)

      const applicableValuesList = propertyData.data.propertyFieldList.map(
        (propertyFieldList: { propertyField: string }) => {
          return propertyFieldList.propertyField
        }
      )
      const applicableValues = applicableValuesList.join(', ')
      setApplicableValuesList(applicableValues)
      setloading(false)
    }
    getIncidentTypesData()
  }, [])
  let statusAction = ''
  if (propertyState.status == 'Active') {
    checkPermission('inactive') === true && (statusAction = 'Inactive')
  }
  if (propertyState.status == 'Inactive') {
    checkPermission('active') === true && (statusAction = 'Active')
  }
  const handleSelect = async (e: any) => {
    if (e == 'Inactive') {
      const inActiveData = await PropertyDamageTypeServices.apiInactiveProperty(
        parseInt(match.params.id)
      )
      let success = false
      let message = inActiveData.errorMessage ? inActiveData.errorMessage : ''
      if (inActiveData.status === 200) {
        success = true
        message = propertyInActive
      }
      showTotast(
        success ? ALERT_SUCCESS : ALERT_WARNING,
        message,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setPropertyState(inActiveData.data)
    }
    if (e == 'Active') {
      const activeData = await PropertyDamageTypeServices.apiActiveProperty(
        parseInt(match.params.id)
      )
      let success = false
      let message = activeData.errorMessage ? activeData.errorMessage : ''
      if (activeData.status === 200) {
        success = true
        message = propertyActive
      }
      showTotast(
        success ? ALERT_SUCCESS : ALERT_WARNING,
        message,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setPropertyState(activeData.data)
    }
    const propertyHistoryData =
      await PropertyDamageTypeServices.getPropertyHistoryById(
        parseInt(match.params.id)
      )
    setAduitHistory(propertyHistoryData.data)
  }
  const handleModify = () => {
    history.push(
      `/system-configuration/property-damage-type/modify/${match.params.id}`
    )
  }
  const aduitHistorycolumns = React.useMemo(
    () => [
      {
        Header: 'Action',
        accessor: 'action',
      },
      {
        Header: 'Property Name',
        accessor: 'propertyName',
      },
      {
        Header: 'Property Description',
        accessor: 'propertyDescription',
      },
      {
        Header: 'Property Category',
        accessor: 'propertyCategory',
      },
      {
        Header: 'Scheme',
        accessor: 'scheme',
      },
      {
        Header: 'Property Field List',
        accessor: 'propertyFieldList',
      },
      {
        Header: 'Performed By',
        accessor: 'performedBy',
      },
      {
        Header: 'Performed Date/Time',
        accessor: 'performedDateTime',
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
    ],
    []
  )
  return (
    <>
      {loading ? (
        <Container className="IncidentTypes-loading-layout">
          <LoadingComponent />
        </Container>
      ) : (
        <Row className="view-IncidentTypes-page-layout">
          <Col sm={12} className="border-label">
            <div className="border-label-span">View Property</div>
            <Form>
              <Row className="mb-4">
                <Col sm={11}>
                  <Form.Group className="mb-3" controlId="formGroupTitle">
                    <Form.Label>Status</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      value={propertyState.status}
                    />
                  </Form.Group>
                </Col>
                <Col sm={1} className="text-align-righ mt-2">
                  <Form.Group className="mb-3" controlId="formGroupTitle">
                    <Form.Label></Form.Label>
                    {checkPermission('inactive') === true ||
                    checkPermission('Active') === true ? (
                      <Dropdown onSelect={handleSelect}>
                        <Dropdown.Toggle
                          variant="secondary"
                          id="dropdown-basic"
                        >
                          Action
                        </Dropdown.Toggle>
                        <Dropdown.Menu align={{ lg: 'end' }}>
                          <Dropdown.Item eventKey={statusAction}>
                            {statusAction}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : (
                      ''
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="nameoftheProperty">
                    <Form.Label>Name of the Property</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      value={propertyState.name}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="propertyDescription">
                    <Form.Label>Property Description</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      value={propertyState.propertyDescription}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="propertyCategory">
                    <Form.Label>Property Category</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      value={propertyState.propertyCategory}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="Scheme">
                    <Form.Label>Scheme</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      value={propertyState.scheme}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="applicableValues">
                    <Form.Label>Applicable values</Form.Label>
                    <Form.Control
                      type="text"
                      disabled
                      value={applicableValuesList}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label>Audit History</Form.Label>
                  {aduitHistory ? (
                    <ReactTableWithPaginationComponent
                      columns={aduitHistorycolumns}
                      data={aduitHistory}
                      onClickRow={(row: any) => {
                        console.log(row)
                      }}
                    />
                  ) : (
                    ''
                  )}
                </Col>
              </Row>
              <Row>
                <Col sm={12} className="d-flex justify-content-end mt-4">
                  <button
                    className="ok-button"
                    onClick={(e) => {
                      e.preventDefault()
                      history.goBack()
                    }}
                  >
                    Ok
                  </button>
                  {checkPermission('modify') === true &&
                    propertyState.status === 'Active' && (
                      <button className="modify-button" onClick={handleModify}>
                        Modify
                      </button>
                    )}
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      )}
    </>
  )
}

export default ViewPropertyDamageType
