// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/display-name */
import produce from 'immer'
import React, { FC, useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import ReactDatePicker from 'react-datepicker'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import IdentificationTypesComponent from '../../../components/common/identificationComponent/IdentificationTypesComponent'
import NicInputComponent from '../../../components/common/identificationComponent/NicInputComponent'
import InvalidFeedback from '../../../components/common/invalidFeedback/InvalidFeedback'

import LoadingComponent from '../../../components/common/loading/LoadingComponent'
import PriceInputComponent from '../../../components/common/priceInputComponent/PriceInputComponent'
import ConfirmationModal from '../../../components/modals/ConfirmationModal'
import NextOfKinModal from '../../../components/modals/NextOfKinModal'
import ReactTableWithPaginationComponent from '../../../components/table/ReactTableWithPaginationComponent'
import { DSDivisionModal } from '../../../models/administrativeArea/dsDivisionModal'
import { DependentCompensationModel } from '../../../models/applicationRegistration/compensation/dependentCompensationModel'
import { PdAggrivedPersonDetailsModel } from '../../../models/applicationRegistration/compensation/pd/pdAggrivedPerson'
import { PdDependentDetailsModel } from '../../../models/applicationRegistration/compensation/pd/pdDependentDetails'
import { PdDependentDetailsErrorModel } from '../../../models/applicationRegistration/compensation/pd/pdDependentDetailsErrorModel'

import { NextOfKinModel } from '../../../models/applicationRegistration/compensation/nextOfKinModel'
import { dsSelectionModal } from '../../../models/applicationRegistration/fileRegistration/dsSelectionModel'
import { FileRegistrationModel } from '../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { ValidationObjectModel } from '../../../models/common/validationObjectModel'
import { DistrictOption } from '../../../models/selectOptionModels/districtOption'
import { DsOption } from '../../../models/selectOptionModels/dsOption'
import { GnOption } from '../../../models/selectOptionModels/gnOption'
import { ProvinceOption } from '../../../models/selectOptionModels/provinceOption'
import { ResponseObject } from '../../../services/api/apiManager'
import common from '../../../services/applicationRegistration/common'
import gsdService from '../../../services/applicationRegistration/compensation/gsdService'
import gsiService from '../../../services/applicationRegistration/compensation/gsiService'
import pdService from '../../../services/applicationRegistration/compensation/pdService'
import piService from '../../../services/applicationRegistration/compensation/piService'
import mpService from '../../../services/applicationRegistration/compensation/mpService'
import metaDataService from '../../../services/metaDataService'
import {
  geDistrictsOptions,
  getDsOptions,
  getGnOptions,
  getProvincesOptions,
} from '../../../store/administrativeArea/selectors'
import { changeLoadingRequest } from '../../../store/app/actionTypes'
import {
  ALERT_WARNING,
  number,
  text,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../../utils/constants'
import { validationError } from '../../../utils/errorMessages'
import { dependentDetailsColumns } from '../../../utils/metaData'
import { showTotast } from '../../../utils/toast'
import {
  checkGenderDisable,
  checkNicMandatory,
  getAgeWithIncidnetDate,
  getDependentRelationTypeEnable,
  handleNotification,
  renderIdentificationTypeName,
  renderIdentificationTypeValue,
  validateForm,
} from '../../../utils/utilFunctions'
import ApplicationModificationService from '../../../services/applicationModification/ApplicationModificationService'

export interface SectionFourProps {
  fileRegistrationObject: FileRegistrationModel
}

interface ConfirmationModal {
  isShow: boolean
  message: string
  section: string
}

const SectionFour: FC<SectionFourProps> = ({ fileRegistrationObject }) => {
  const [showConfirm, setShowConfirm] = useState({
    isShow: false,
  } as ConfirmationModal)
  const [errors, setErrors] = useState({} as PdDependentDetailsErrorModel)
  const [loading, setLoading] = useState(true)
  const [nextOfKin, setNextOfKin] = useState(false)
  const [titleList, setTitleList] = useState([])
  const [relationshipList, setRelationShipList] = useState([])
  const [martialStatusList, setMartialList] = useState([])
  const [dependentList, setDependentList] = useState([])
  const [bankList, setBankList] = useState([])
  const [branchList, setBranchList] = useState([])
  const [dependentDetails, setDependentDetails] = useState(
    {} as PdDependentDetailsModel
  )
  const [dependentsCompensationDetails, setDependentsCompensationDetails] =
    useState([] as DependentCompensationModel[])
  const [selectedBirthCertificateDs, setSelectedBirthCertificateDs] = useState(
    {} as dsSelectionModal
  )
  const [aggrivedPersonDetails, setAggrivedPersonDetails] = useState({
    birthCertificateDsDivision: {} as DSDivisionModal,
  } as PdAggrivedPersonDetailsModel)
  const provinceList = useSelector(getProvincesOptions)
  const districtList = useSelector(geDistrictsOptions)
  const dsList = useSelector(getDsOptions)
  const gnList = useSelector(getGnOptions)
  const dispatch = useDispatch()
  const [checkPrintToApplicant, setCheckPrintToApplicant] =
    useState('Applicant')
  useEffect(() => {
    getMetaData()
  }, [])

  async function getMetaData() {
    const titleData = await metaDataService.apiTitleListFetch()
    const relationshipData = await metaDataService.getRelationshipList()
    const maritalData = await metaDataService.getMaritalList()
    const bankData = await metaDataService.getBankList()
    let dependentData = {} as ResponseObject
    dependentData = await getDependentDetails()

    if (titleData.status == 200) {
      setTitleList(titleData.data)
    }

    if (relationshipData.status == 200) {
      setRelationShipList(relationshipData.data)
    }

    if (maritalData.status == 200) {
      setMartialList(maritalData.data)
    }

    if (bankData.status == 200) {
      setBankList(bankData.data)
    }

    if (dependentData.status == 200) {
      setDependentList(dependentData.data)
    }

    if (
      dependentData.data?.length < 1 &&
      fileRegistrationObject.scheme === 'PI' &&
      fileRegistrationObject.applicantType === 'Applicant'
    ) {
      const aggrivedDetailsData =
        await piService.apiPiGetAggrivedPersonDetailsDetails(
          fileRegistrationObject.id
        )

      if (aggrivedDetailsData.status === 200) {
        setAggrivedPersonDetails(aggrivedDetailsData.data)
      }
    }

    if (dependentData.data?.length < 1) {
      setApplicantData()
    } else {
      const newFormValue = produce(
        dependentDetails,
        (draft: PdDependentDetailsModel) => {
          draft.applicationFileId = fileRegistrationObject.id
          draft.bothParentsDeceased = false
          draft.newNic = false
        }
      )
      setDependentDetails(newFormValue)
    }
    setLoading(false)
  }

  const setApplicantData = () => {
    const newFormValue = produce(
      dependentDetails,
      (draft: PdDependentDetailsModel) => {
        draft.applicationFileId = fileRegistrationObject.id
        draft.bothParentsDeceased = false
        draft.applicant = true
        draft.age = fileRegistrationObject.age
        draft.fullName = fileRegistrationObject.applicantName
        draft.relationship = fileRegistrationObject.relationship
        draft.identificationType = fileRegistrationObject.identificationType
        draft.newNic = fileRegistrationObject.newNic
        draft.newNicNumber = fileRegistrationObject.newNicNumber
        draft.oldNicNumber = fileRegistrationObject.oldNicNumber
        draft.passportNumber = fileRegistrationObject.passportNumber
        draft.seniorCitizenId = fileRegistrationObject.seniorCitizenId
        draft.drivingLicenseNumber = fileRegistrationObject.drivingLicenseNumber
        draft.ticNumber = fileRegistrationObject.ticNumber
        draft.gender = fileRegistrationObject.gender
        draft.dateOfBirth = fileRegistrationObject.dateOfBirth
        draft.address = fileRegistrationObject.permanentAddress
        draft.birthCertificateNumber =
          fileRegistrationObject.birthCertificateNumber
        draft.birthCertificateDsDivisionId =
          fileRegistrationObject.birthCertificateDsDivisionId
        draft.provinceId = fileRegistrationObject.provinceId
        draft.districtId = fileRegistrationObject.districtId
        draft.dsDivisionId = fileRegistrationObject.dsDivisionId
        draft.gnDivisionId = fileRegistrationObject.gnDivisionId
        draft.idNumberRemark = fileRegistrationObject.idNumberRemark
      }
    )
    setDependentDetails(newFormValue)
    const newSelectedBirthCertificateDs = produce(
      selectedBirthCertificateDs,
      (draft: dsSelectionModal) => {
        draft.provinceId =
          fileRegistrationObject.birthCertificateDsDivision?.provinceId
        draft.districtId =
          fileRegistrationObject.birthCertificateDsDivision?.districtId
        draft.dsId = fileRegistrationObject.birthCertificateDsDivisionId
      }
    )
    setSelectedBirthCertificateDs(newSelectedBirthCertificateDs)
  }

  const setAggrivedData = () => {
    const newFormValue = produce(
      dependentDetails,
      (draft: PdDependentDetailsModel) => {
        draft.applicationFileId = fileRegistrationObject.id
        draft.bothParentsDeceased = false
        draft.applicant = true
        draft.relationship = ''
        draft.age = aggrivedPersonDetails.age
        draft.fullName = aggrivedPersonDetails.fullName
        draft.identificationType = aggrivedPersonDetails.identificationType
        draft.newNic = aggrivedPersonDetails.newNic
        draft.newNicNumber = aggrivedPersonDetails.newNicNumber
        draft.oldNicNumber = aggrivedPersonDetails.oldNicNumber
        draft.passportNumber = aggrivedPersonDetails.passportNumber
        draft.seniorCitizenId = aggrivedPersonDetails.seniorCitizenId
        draft.drivingLicenseNumber = aggrivedPersonDetails.drivingLicenseNumber
        draft.ticNumber = aggrivedPersonDetails.ticNumber
        draft.gender = aggrivedPersonDetails.gender
        draft.dateOfBirth = aggrivedPersonDetails.dateOfBirth
        draft.address = aggrivedPersonDetails.address
        draft.birthCertificateNumber =
          aggrivedPersonDetails.birthCertificateNumber
        draft.birthCertificateDsDivisionId =
          aggrivedPersonDetails.birthCertificateDsDivisionId
        draft.provinceId = aggrivedPersonDetails.provinceId
        draft.districtId = aggrivedPersonDetails.districtId
        draft.dsDivisionId = aggrivedPersonDetails.dsDivisionId
        draft.gnDivisionId = aggrivedPersonDetails.gnDivisionId
      }
    )
    setDependentDetails(newFormValue)
    const newSelectedBirthCertificateDs = produce(
      selectedBirthCertificateDs,
      (draft: dsSelectionModal) => {
        draft.provinceId =
          aggrivedPersonDetails.birthCertificateDsDivision?.provinceId
        draft.districtId =
          aggrivedPersonDetails.birthCertificateDsDivision?.districtId
        draft.dsId = aggrivedPersonDetails.birthCertificateDsDivisionId
      }
    )
    setSelectedBirthCertificateDs(newSelectedBirthCertificateDs)
  }
  const customStyles = {
    control: (base: any, state: { isFocused: any }) => ({
      ...base,
      // state.isFocused can display different borderColor if you need it
      borderColor: state.isFocused ? '#ddd' : 'red',
      // overwrittes hover style
      '&:hover': {
        borderColor: state.isFocused ? '#ddd' : 'red',
      },
    }),
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const findErrors = () => {
    const newErrors = {} as PdDependentDetailsErrorModel
    if (
      validateForm({
        type: text,
        value: dependentDetails.title,
      } as ValidationObjectModel)
    ) {
      newErrors.titleError = validateForm({
        type: text,
        value: dependentDetails.title,
        section: 'Title of the Dependent',
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        type: text,
        value: dependentDetails.fullName,
      } as ValidationObjectModel)
    ) {
      newErrors.fullNameError = validateForm({
        type: text,
        value: dependentDetails.fullName,
        section: 'Full name',
      } as ValidationObjectModel)
    }
    if (
      validateForm(
        {
          type: text,
          value: dependentDetails.relationship,
        } as ValidationObjectModel,
        getDependentRelationTypeEnable(
          fileRegistrationObject.scheme,
          checkPrintToApplicant,
          fileRegistrationObject.applicantType
        )
      )
    ) {
      newErrors.relationshipError = validateForm(
        {
          type: text,
          value: dependentDetails.relationship,
          section: 'Dependent’s Relationship to the Aggrieved Person',
        } as ValidationObjectModel,
        getDependentRelationTypeEnable(
          fileRegistrationObject.scheme,
          checkPrintToApplicant,
          fileRegistrationObject.applicantType
        )
      )
    }

    if (
      validateForm({
        type: 'date',
        maxValue: new Date().getTime() / 1000,
        value: dependentDetails.dateOfBirth,
      } as ValidationObjectModel)
    ) {
      newErrors.dateOfBirthError = validateForm({
        type: 'date',
        maxValue: new Date().getTime() / 1000,
        value: dependentDetails.dateOfBirth,
        section: 'Date Of birth',
      } as ValidationObjectModel)
    }

    if (
      validateForm({
        type: number,
        value: dependentDetails.identificationType,
      } as ValidationObjectModel)
    ) {
      newErrors.identificationTypeError = validateForm({
        type: number,
        value: dependentDetails.identificationType,
        section: 'Identification type',
      } as ValidationObjectModel)
    }
    if (dependentDetails.identificationType != 'BIRTH_CERTIFICATE') {
      if (checkNicMandatory(dependentDetails.identificationType)) {
        if (
          validateForm({
            type: text,
            value: dependentDetails.newNic
              ? dependentDetails.newNicNumber
              : dependentDetails.oldNicNumber,
          } as ValidationObjectModel)
        ) {
          newErrors.nicError = validateForm({
            type: text,
            value: dependentDetails.newNic
              ? dependentDetails.newNicNumber
              : dependentDetails.oldNicNumber,
            section: 'NIC number of the Dependent',
          } as ValidationObjectModel)
        }
      }
      if (dependentDetails.identificationType === 'PASSPORT') {
        if (
          validateForm({
            type: text,
            maxLength: 8,
            value: dependentDetails.passportNumber,
          } as ValidationObjectModel)
        ) {
          newErrors.identificationDocError = validateForm({
            type: text,
            maxLength: 8,
            value: dependentDetails.passportNumber,
            section: 'Passport Number',
          } as ValidationObjectModel)
        }
      } else if (dependentDetails.identificationType === 'SENIOR_CITIZEN_ID') {
        if (
          validateForm({
            type: text,
            maxLength: 13,
            value: dependentDetails.seniorCitizenId,
          } as ValidationObjectModel)
        ) {
          newErrors.identificationDocError = validateForm({
            type: text,
            maxLength: 13,
            value: dependentDetails.seniorCitizenId,
            section: 'Senior Citizen Identity Card Number',
          } as ValidationObjectModel)
        }
      } else if (dependentDetails.identificationType === 'DRIVING_LICENSE') {
        if (
          validateForm({
            type: text,
            maxLength: 15,
            value: dependentDetails.drivingLicenseNumber,
          } as ValidationObjectModel)
        ) {
          newErrors.identificationDocError = validateForm({
            type: text,
            maxLength: 15,
            value: dependentDetails.drivingLicenseNumber,
            section: 'Driving license number',
          } as ValidationObjectModel)
        }
      } else if (dependentDetails.identificationType === 'TIC') {
        if (
          validateForm({
            type: text,
            maxLength: 30,
            value: dependentDetails.ticNumber,
          } as ValidationObjectModel)
        ) {
          newErrors.identificationDocError = validateForm({
            type: text,
            maxLength: 30,
            value: dependentDetails.ticNumber,
            section: 'Tic number',
          } as ValidationObjectModel)
        }
      }
    } else {
      if (
        validateForm({
          type: text,
          maxLength: 4,
          value: dependentDetails.birthCertificateNumber,
        } as ValidationObjectModel)
      ) {
        newErrors.birthCertificateNumberError = validateForm({
          type: text,
          maxLength: 4,
          value: dependentDetails.birthCertificateNumber,
          section: 'birth certificate number',
        } as ValidationObjectModel)
      }

      if (
        validateForm({
          type: text,
          value: selectedBirthCertificateDs.provinceId,
        } as ValidationObjectModel)
      ) {
        newErrors.birthCertificateProvinceIdError = validateForm({
          type: text,
          value: selectedBirthCertificateDs.provinceId,
          section: 'Province',
        } as ValidationObjectModel)
      }

      if (selectedBirthCertificateDs.provinceId) {
        if (
          validateForm({
            type: text,
            value: selectedBirthCertificateDs.districtId,
          } as ValidationObjectModel)
        ) {
          newErrors.birthCertificateDistrictIdError = validateForm({
            type: text,
            value: selectedBirthCertificateDs.districtId,
            section: 'District',
          } as ValidationObjectModel)
        }
      }
      if (selectedBirthCertificateDs.districtId) {
        if (
          validateForm({
            type: text,
            value: selectedBirthCertificateDs.dsId,
          } as ValidationObjectModel)
        ) {
          newErrors.birthCertificateDsDivisionIdError = validateForm({
            type: text,
            value: selectedBirthCertificateDs.dsId,
            section: 'Ds Division',
          } as ValidationObjectModel)
        }
      }
    }
    if (
      validateForm({
        type: text,
        maxLength: 3,
        value: dependentDetails.age,
      } as ValidationObjectModel)
    ) {
      newErrors.ageError = validateForm({
        type: text,
        maxLength: 3,
        value: dependentDetails.age,
        section: 'Age',
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        type: text,
        value: dependentDetails.gender,
      } as ValidationObjectModel)
    ) {
      newErrors.genderError = validateForm({
        type: text,
        value: dependentDetails.gender,
        section: 'Gender',
      } as ValidationObjectModel)
    }
    if (
      validateForm(
        {
          type: text,
          value: dependentDetails.maritalStatus,
        } as ValidationObjectModel,
        fileRegistrationObject.scheme == 'MP' ? false : true
      )
    ) {
      newErrors.maritalStatusError = validateForm({
        type: text,
        value: dependentDetails.maritalStatus,
        section: 'Marital status',
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        type: text,
        value: dependentDetails.address,
        maxLength: 255,
      } as ValidationObjectModel)
    ) {
      newErrors.addressError = validateForm({
        type: text,
        value: dependentDetails.address,
        maxLength: 255,
        section: 'Address of the Dependent',
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        type: text,
        value: dependentDetails.provinceId,
      } as ValidationObjectModel)
    ) {
      newErrors.provinceIdError = validateForm({
        type: text,
        value: dependentDetails.provinceId,
        section: 'Province',
      } as ValidationObjectModel)
    }
    if (dependentDetails.provinceId) {
      if (
        validateForm({
          type: text,
          value: dependentDetails.districtId,
        } as ValidationObjectModel)
      ) {
        newErrors.districtIdError = validateForm({
          type: text,
          value: dependentDetails.districtId,
          section: 'District',
        } as ValidationObjectModel)
      }
    }

    if (dependentDetails.districtId) {
      if (
        validateForm({
          type: text,
          value: dependentDetails.dsDivisionId,
        } as ValidationObjectModel)
      ) {
        newErrors.dsDivisionIdError = validateForm({
          type: text,
          value: dependentDetails.dsDivisionId,
          section: 'DS division',
        } as ValidationObjectModel)
      }
    }

    if (dependentDetails.dsDivisionId) {
      if (
        validateForm({
          type: text,
          value: dependentDetails.gnDivisionId,
        } as ValidationObjectModel)
      ) {
        newErrors.gnDivisionIdError = validateForm({
          type: text,
          value: dependentDetails.gnDivisionId,
          section: 'GN division',
        } as ValidationObjectModel)
      }
    }
    if (
      validateForm(
        {
          type: text,
          value: dependentDetails.city,
        } as ValidationObjectModel,
        fileRegistrationObject.scheme != 'GSD' &&
          fileRegistrationObject.scheme != 'MP'
          ? true
          : false
      )
    ) {
      newErrors.cityError = validateForm(
        {
          type: text,
          value: dependentDetails.city,
          section: 'Address',
        } as ValidationObjectModel,
        fileRegistrationObject.scheme != 'GSD' ? true : false
      )
    }
    if (
      validateForm({
        type: 'boolean',
        value: dependentDetails.bothParentsDeceased,
      } as ValidationObjectModel)
    ) {
      newErrors.bothParentsDeceasedError = validateForm({
        type: 'boolean',
        value: dependentDetails.bothParentsDeceased,
        section: 'Option to mark whether both parents are deceased',
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        type: number,
        value: dependentDetails.bankId,
      } as ValidationObjectModel)
    ) {
      newErrors.bankError = validateForm({
        type: number,
        value: dependentDetails.bankId,
        section: 'Bank Name',
      } as ValidationObjectModel)
    }
    // if(dependentDetails.bankId){

    // }
    if (
      validateForm({
        type: number,
        value: dependentDetails.branchId,
      } as ValidationObjectModel)
    ) {
      newErrors.branchError = validateForm({
        type: number,
        value: dependentDetails.branchId,
        section: 'Branch name',
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        type: number,
        value: dependentDetails.accountNumber,
      } as ValidationObjectModel)
    ) {
      newErrors.accountNumberError = validateForm({
        type: number,
        value: dependentDetails.accountNumber,
        section: 'Account Number',
      } as ValidationObjectModel)
    }
    return newErrors
  }

  const handleGetSuggestCompensation = async () => {
    dispatch(changeLoadingRequest())
    const scheme = fileRegistrationObject.scheme.toUpperCase()
    const results = await common.apiGetDependentSuggestAmount(
      fileRegistrationObject.id,
      scheme
    )

    if (results.status === 200) {
      setDependentsCompensationDetails(results.data)
    }
    dispatch(changeLoadingRequest())
  }

  const renderDependentCompensationTable = () => {
    return dependentsCompensationDetails.map(
      (dependent: DependentCompensationModel) => {
        return (
          <tr key={`dependent-${dependent.dependentId}`}>
            <td>{dependent.dependentName}</td>
            <td>{dependent.relationship}</td>
            <td>
              <PriceInputComponent
                value={dependent.amountPercentage}
                placeholder={`Enter Suggested Value ${dependent.compensationPercentage}`}
                onChange={(price: string) => {
                  const newFormValue = produce(
                    dependentsCompensationDetails,
                    (draft: DependentCompensationModel[]) => {
                      const indexNumber = draft.findIndex(
                        (x) => x.dependentId === dependent.dependentId
                      )
                      if (indexNumber > -1) {
                        draft[indexNumber].amountPercentage = parseFloat(price)
                      }
                    }
                  )
                  setDependentsCompensationDetails(newFormValue)
                }}
              />
            </td>
          </tr>
        )
      }
    )
  }

  const addCompensationPercentageValues = async () => {
    const success = {
      status: 400,
      errorMessage: 'Compentation value total not valid',
    } as ResponseObject
    const dependentObjectList = [] as PdDependentDetailsModel[]
    let totalAmount = 0
    for (let i = 0; i < dependentsCompensationDetails.length; i++) {
      const dependent = {} as PdDependentDetailsModel
      dependent.id = dependentsCompensationDetails[i].dependentId
      dependent.amountPercentage =
        dependentsCompensationDetails[i].amountPercentage == null
          ? 0
          : dependentsCompensationDetails[i].amountPercentage
      dependentObjectList.push(dependent)
      totalAmount = totalAmount + dependent.amountPercentage
    }

    if (totalAmount == 100) {
      dispatch(changeLoadingRequest())
      const results = await common.apiAddDependentCompensationPercentage(
        dependentObjectList,
        fileRegistrationObject.id,
        fileRegistrationObject.scheme.toLowerCase()
      )

      if (results.status === 200) {
        const dependentData = await getDependentDetails()
        if (dependentData.status == 200) {
          setDependentList(dependentData.data)
          setDependentsCompensationDetails([])
        }
      }
      handleNotification(
        results,
        'Dependent Compensation Percentage Added Successfully'
      )
      dispatch(changeLoadingRequest())
    } else {
      handleNotification(success, '')
    }
  }

  const handleConfirm = async () => {
    dispatch(changeLoadingRequest())
    const results = await ApplicationModificationService.apiUpdateDependent(
      dependentDetails
    )
    handleNotification(
      results,
      'The application has been modified successfully'
    )

    setShowConfirm({ isShow: false, message: '', section: '' })

    getMetaData()

    dispatch(changeLoadingRequest())
  }

  const onClickSave = () => {
    const foundErrros = findErrors()
    if (Object.keys(foundErrros).length > 0) {
      showTotast(
        ALERT_WARNING,
        validationError,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setErrors(foundErrros)
    } else {
      const message = 'Do you wish to Save application ?'
      const section = 'save'
      setShowConfirm({
        isShow: true,
        message: message,
        section: section,
      })
    }
  }

  const getBranchesList = async (id: number) => {
    const branchData = await metaDataService.getBranchesList(id)
    if (branchData.status == 200) {
      setBranchList(branchData.data)
    } else {
      setBranchList([])
    }
  }

  const handleEditButton = async (depended: PdDependentDetailsModel) => {
    await getBranchesList(depended.bankId)
    setDependentDetails(depended)
    if (depended.birthCertificateDsDivision != null) {
      const newSelectedBirthCertificateDs = produce(
        selectedBirthCertificateDs,
        (draft: dsSelectionModal) => {
          draft.provinceId = depended.birthCertificateDsDivision?.provinceId
          draft.districtId = depended.birthCertificateDsDivision?.districtId
          draft.dsId = depended.birthCertificateDsDivision?.id
        }
      )
      setSelectedBirthCertificateDs(newSelectedBirthCertificateDs)
    }
    if (depended.relationship == '') {
      setCheckPrintToApplicant('Aggrived')
    }
  }

  const handleSaveNextOfKin = async (nextOfKin: NextOfKinModel) => {
    dispatch(changeLoadingRequest())
    let message = 'Next of Kin Added Successfully'
    if (nextOfKin.id > 0) {
      message = 'Next of Kin Updated Successfully'
    }
    let results = {} as ResponseObject
    results = await addNextOfKinDetails(nextOfKin)
    handleNotification(results, message)
    if (results.status == 200) {
      let dependentData = {} as ResponseObject
      dependentData = await getDependentDetails()
      if (dependentData.status == 200) {
        setDependentList(dependentData.data)
      }
    }
    dispatch(changeLoadingRequest())
  }

  const getDependentDetails = async () => {
    let dependentData = {} as ResponseObject

    switch (fileRegistrationObject.scheme) {
      case 'MP':
        dependentData = await mpService.apiMpGetDependentDetails(
          fileRegistrationObject.id
        )
        return dependentData
        break
      case 'PD':
        dependentData = await pdService.apiPdGetDependentDetails(
          fileRegistrationObject.id
        )
        return dependentData
        break
      case 'PI':
        dependentData = await piService.apiPiGetDependentDetails(
          fileRegistrationObject.id
        )
        return dependentData
        break
      case 'GSD':
        dependentData = await gsdService.apiGsdGetDependentDetails(
          fileRegistrationObject.id
        )
        return dependentData
        break
      case 'GSI':
        dependentData = await gsiService.apiGsiGetDependentDetails(
          fileRegistrationObject.id
        )
        return dependentData
        break
      default:
        dependentData = await pdService.apiPdGetDependentDetails(
          fileRegistrationObject.id
        )
        return dependentData
        break
    }
  }

  const addNextOfKinDetails = async (nextOfKin: NextOfKinModel) => {
    let nextOfKinData = { status: 400 } as ResponseObject

    switch (fileRegistrationObject.scheme) {
      case 'MP':
        nextOfKinData = await mpService.apiMpAddDependantNextOfKinDetails(
          nextOfKin
        )
        return nextOfKinData
        break
      case 'PD':
        nextOfKinData = await pdService.apiPdAddDependantNextOfKinDetails(
          nextOfKin
        )
        return nextOfKinData
        break
      case 'PI':
        nextOfKinData = await piService.apiPiAddDependantNextOfKinDetails(
          nextOfKin
        )
        return nextOfKinData
        break
      case 'GSD':
        nextOfKinData = await gsdService.apiGsdAddDependantNextOfKinDetails(
          nextOfKin
        )
        return nextOfKinData
        break
      case 'GSI':
        nextOfKinData = await gsiService.apiGsiAddDependantNextOfKinDetails(
          nextOfKin
        )
        return nextOfKinData
        break
      default:
        return nextOfKinData
        break
    }
  }

  console.log(dependentDetailsColumns)
  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'fullName',
      },
      {
        Header: 'Relationship',
        accessor: 'relationship',
      },
      {
        Header: 'ID Number',
        accessor: 'idNumber',
      },
      {
        Header: 'Gender',
        accessor: 'gender',
      },
      {
        Header: 'Date of Birth',
        accessor: 'dateOfBirth',
      },
      {
        Header: 'Address Line 1',
        accessor: 'address',
      },

      {
        Header: 'Province',
        accessor: 'province.name',
      },
      {
        Header: 'District',
        accessor: 'district.name',
      },
      {
        Header: 'DS Division',
        accessor: 'dsDivision.name',
      },
      {
        Header: 'GN Division',
        accessor: 'gnDivision.name',
      },
      {
        Header: 'Compensation Percentage (%)',
        accessor: 'amountPercentage',
      },
      {
        Header: 'Bank Name',
        accessor: 'bank.description',
      },
      {
        Header: 'Branch Name',
        accessor: 'branch.name',
      },
      {
        Header: 'Account Number',
        accessor: 'accountNumber',
      },
      {
        Header: 'Action',
        accessor: 'id',
        // Cell method will provide the cell value; we pass it to render a custom component
        Cell: (e: any) => {
          if (e.cell.value) {
            return (
              <button
                className={'save-button'}
                onClick={(action) => {
                  action.preventDefault()
                  action.stopPropagation()
                  handleEditButton(e.row.original)
                }}
              >
                Edit
              </button>
            )
          }
        },
      },
    ],
    []
  )

  const branchOptions = branchList.map(
    (branch: { id: number; code: number }) => ({
      value: branch.id,
      label: branch.code.toString(),
    })
  )

  return (
    <>
      {loading ? (
        <div className="loading-layout">
          <LoadingComponent />
        </div>
      ) : (
        <>
          <Col sm={12} className="border-label mb-4">
            <div className="border-label-span">{`Family Details`}</div>
            <ReactTableWithPaginationComponent
              columns={columns}
              data={dependentList}
              onClickRow={(row: any) => {
                console.log(row)
              }}
            />
          </Col>

          {fileRegistrationObject.scheme === 'PI' &&
            dependentList.length == 0 &&
            fileRegistrationObject.applicantType === 'Applicant' &&
            fileRegistrationObject.modifyIndex == 0 && (
              <Row className="mb-4">
                <Col sm={12} className="border-label">
                  <div className="border-label-span">{`Check Print To`}</div>
                  <Form.Group as={Col} controlId="formCheckrintTo">
                    <Form.Label>
                      Check Print To{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Form.Control
                      className="form-select"
                      as="select"
                      value={checkPrintToApplicant}
                      onChange={(e) => {
                        console.log(e)
                        setCheckPrintToApplicant(e.target.value)
                        if (e.target.value === 'Applicant') {
                          setApplicantData()
                        } else {
                          setAggrivedData()
                        }
                      }}
                    >
                      <option value="">--Select Check Print To--</option>
                      <option value="Applicant">Applicant</option>
                      <option value="Aggrived">Aggrived Person</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
            )}

          {dependentDetails.id && (
            <Col sm={12} className="border-label">
              <div className="border-label-span">{`Dependent & Bank Details`}</div>
              <>
                <Form id="file-dependent-details-form">
                  <Form.Group className="mb-3" controlId="formGroupTitle">
                    <Form.Label>
                      Title <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Form.Control
                      isInvalid={!!errors.titleError}
                      disabled={dependentDetails.applicant}
                      className="form-select"
                      as="select"
                      value={dependentDetails.title}
                      onBlur={() => {
                        const newErrors = produce(errors, (draft) => {
                          draft.titleError = validateForm({
                            type: text,
                            value: dependentDetails.title,
                            section: 'Title of the dependent',
                          } as ValidationObjectModel)
                        })
                        setErrors(newErrors)
                      }}
                      onChange={(e) => {
                        const newFormValue = produce(
                          dependentDetails,
                          (draft: PdDependentDetailsModel) => {
                            draft.title = e.target.value
                          }
                        )
                        setDependentDetails(newFormValue)
                        const clearError = produce(errors, (draft) => {
                          draft.titleError = ''
                        })
                        setErrors(clearError)
                      }}
                    >
                      {1 === 1 ? (
                        <option value="">--Select Title--</option>
                      ) : (
                        ''
                      )}
                      {titleList.map(
                        (
                          title: { name: string; id: number },
                          index: number
                        ) => {
                          return (
                            <option key={`country-${index}`} value={title.name}>
                              {title.name}
                            </option>
                          )
                        }
                      )}
                    </Form.Control>
                    <InvalidFeedback message={errors.titleError} />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formApplicantName">
                    <Form.Label>
                      Name <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <Form.Control
                      isInvalid={!!errors.fullNameError}
                      disabled={dependentDetails.applicant}
                      type="text"
                      placeholder="Enter Name"
                      onBlur={() => {
                        const newErrors = produce(errors, (draft) => {
                          draft.fullNameError = validateForm({
                            type: text,
                            value: dependentDetails.fullName,
                            section: 'Full name',
                          } as ValidationObjectModel)
                        })
                        setErrors(newErrors)
                      }}
                      onChange={(e) => {
                        const newFormValue = produce(
                          dependentDetails,
                          (draft: PdDependentDetailsModel) => {
                            draft.fullName = e.target.value
                          }
                        )
                        setDependentDetails(newFormValue)
                        const clearError = produce(errors, (draft) => {
                          draft.fullNameError = ''
                        })
                        setErrors(clearError)
                      }}
                      value={dependentDetails.fullName}
                    />
                    <InvalidFeedback message={errors.fullNameError} />
                  </Form.Group>
                  {getDependentRelationTypeEnable(
                    fileRegistrationObject.scheme,
                    checkPrintToApplicant,
                    fileRegistrationObject.applicantType
                  ) && (
                    <Form.Group as={Col} controlId="formRelationship">
                      <Form.Label>
                        Relationship{' '}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Form.Control
                        isInvalid={!!errors.relationshipError}
                        className="form-select"
                        as="select"
                        disabled={dependentDetails.applicant}
                        value={dependentDetails.relationship}
                        onBlur={() => {
                          const newErrors = produce(errors, (draft) => {
                            draft.relationshipError = validateForm({
                              type: text,
                              value: dependentDetails.relationship,
                              section: 'Relationship',
                            } as ValidationObjectModel)
                          })
                          setErrors(newErrors)
                        }}
                        onChange={(e) => {
                          const newFormValue = produce(
                            dependentDetails,
                            (draft: PdDependentDetailsModel) => {
                              draft.relationship = e.target.value
                            }
                          )
                          setDependentDetails(newFormValue)
                          const clearError = produce(errors, (draft) => {
                            draft.relationshipError = ''
                          })
                          setErrors(clearError)
                        }}
                      >
                        <option value="">--Select Relationship--</option>
                        {relationshipList.map(
                          (
                            title: { name: string; id: number },
                            index: number
                          ) => {
                            return (
                              <option
                                key={`country-${index}`}
                                value={title.name}
                              >
                                {title.name}
                              </option>
                            )
                          }
                        )}
                      </Form.Control>
                      <InvalidFeedback message={errors.relationshipError} />
                    </Form.Group>
                  )}
                  <Form.Group className="mb-3" controlId="formSelection">
                    <Form.Label>Identification Type</Form.Label>
                    <IdentificationTypesComponent
                      isDisabled={dependentDetails.applicant}
                      identificationType={dependentDetails.identificationType}
                      onChange={(type: string) => {
                        const newFormValue = produce(
                          dependentDetails,
                          (draft: PdDependentDetailsModel) => {
                            draft.identificationType = type
                          }
                        )
                        setDependentDetails(newFormValue)
                        const clearError = produce(errors, (draft) => {
                          draft.identificationDocError = ''
                        })
                        setErrors(clearError)
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formApplicantDob">
                    <Form.Label>
                      Date of birth{' '}
                      <span className="required-field-astrix">*</span>
                    </Form.Label>
                    <ReactDatePicker
                      onBlur={() => {
                        const newErrors = produce(errors, (draft) => {
                          draft.dateOfBirthError = validateForm({
                            type: text,
                            value: dependentDetails.dateOfBirth,
                            section: 'Date of birth',
                          } as ValidationObjectModel)
                        })
                        setErrors(newErrors)
                      }}
                      maxDate={new Date()}
                      disabled={dependentDetails.applicant}
                      className={
                        errors.dateOfBirthError
                          ? 'date-picker-validate'
                          : 'form-control'
                      }
                      placeholderText="Date of Birth"
                      selected={
                        dependentDetails.dateOfBirth != null
                          ? new Date(dependentDetails.dateOfBirth * 1000)
                          : null
                      }
                      onChange={(date: Date) => {
                        const age = getAgeWithIncidnetDate(
                          date,
                          new Date(fileRegistrationObject.incidentDate * 1000)
                        )
                        const newFormValue = produce(
                          dependentDetails,
                          (draft: PdDependentDetailsModel) => {
                            draft.age = age
                            draft.dateOfBirth = date
                              ? date.getTime() / 1000
                              : null
                          }
                        )
                        setDependentDetails(newFormValue)
                        const clearError = produce(errors, (draft) => {
                          draft.dateOfBirthError = ''
                          draft.identificationTypeError = ''
                          draft.identificationDocError = ''
                          draft.nicError = ''
                          draft.ageError = ''
                        })
                        setErrors(clearError)
                      }}
                      onSelect={(date: any) => {
                        console.log(date)
                        const age = getAgeWithIncidnetDate(
                          date,
                          new Date(fileRegistrationObject.incidentDate * 1000)
                        )
                        const newFormValue = produce(
                          dependentDetails,
                          (draft: PdDependentDetailsModel) => {
                            draft.age = age
                            draft.dateOfBirth = date.getTime() / 1000
                          }
                        )
                        setDependentDetails(newFormValue)
                      }}
                    />
                    <InvalidFeedback message={errors.dateOfBirthError} />
                  </Form.Group>
                  {dependentDetails.identificationType !=
                    'BIRTH_CERTIFICATE' && (
                    <>
                      <Row className="mb-3">
                        <Form.Group as={Col} controlId="formNICNumber">
                          <Form.Label>
                            NIC Number{' '}
                            {checkNicMandatory(
                              dependentDetails.identificationType
                            ) && (
                              <span className="required-field-astrix">*</span>
                            )}
                          </Form.Label>
                          <Row>
                            <Col sm={1}>
                              <Form.Check
                                inline
                                name="formNICNumberType"
                                label="New"
                                type="radio"
                                id={`formNICNumberType-N`}
                                disabled={dependentDetails.applicant}
                                checked={dependentDetails.newNic}
                                onChange={() => {
                                  const newFormValue = produce(
                                    dependentDetails,
                                    (draft: PdDependentDetailsModel) => {
                                      draft.newNic = true
                                    }
                                  )
                                  setDependentDetails(newFormValue)
                                  const clearError = produce(
                                    errors,
                                    (draft) => {
                                      draft.nicError = ''
                                    }
                                  )
                                  setErrors(clearError)
                                }}
                              />
                            </Col>
                            <Col sm={1}>
                              <Form.Check
                                checked={!dependentDetails.newNic}
                                inline
                                name="formNICNumberType"
                                label="Old"
                                type="radio"
                                id={`formNICNumberType-O`}
                                disabled={dependentDetails.applicant}
                                onChange={() => {
                                  const newFormValue = produce(
                                    dependentDetails,
                                    (draft: PdDependentDetailsModel) => {
                                      draft.newNic = false
                                    }
                                  )
                                  setDependentDetails(newFormValue)
                                  const clearError = produce(
                                    errors,
                                    (draft) => {
                                      draft.nicError = ''
                                    }
                                  )
                                  setErrors(clearError)
                                }}
                              />
                            </Col>
                          </Row>
                          <NicInputComponent
                            errors={errors}
                            isDisabled={dependentDetails.applicant}
                            incidentDate={
                              new Date(
                                fileRegistrationObject.incidentDate * 1000
                              )
                            }
                            newNic={dependentDetails.newNic}
                            value={
                              dependentDetails.newNic
                                ? dependentDetails.newNicNumber
                                : dependentDetails.oldNicNumber
                            }
                            onChange={(identity: any) => {
                              if (identity.error) {
                                if (
                                  dependentDetails.identificationType === 'NIC'
                                ) {
                                  const clearError = produce(
                                    errors,
                                    (draft) => {
                                      draft.nicError = 'Not a valid nic number'
                                    }
                                  )
                                  setErrors(clearError)
                                }
                              } else {
                                const newFormValue = produce(
                                  dependentDetails,
                                  (draft: PdDependentDetailsModel) => {
                                    draft.newNicNumber = identity.newNic
                                    draft.oldNicNumber = identity.oldNic
                                    draft.dateOfBirth =
                                      identity.birthDate.getTime() / 1000
                                    draft.age =
                                      identity.age > -1 ? identity.age : 0
                                    draft.gender = identity.gender
                                  }
                                )
                                setDependentDetails(newFormValue)
                                if (
                                  dependentDetails.identificationType === 'NIC'
                                ) {
                                  const clearError = produce(
                                    errors,
                                    (draft) => {
                                      draft.dateOfBirthError = ''
                                      draft.ageError = ''
                                    }
                                  )
                                  setErrors(clearError)
                                }
                              }
                            }}
                          />
                        </Form.Group>
                      </Row>
                      {dependentDetails.identificationType != 'NIC' && (
                        <Row className="mb-3">
                          <Form.Group as={Col} controlId="formPSDNumber">
                            <Form.Label>
                              {renderIdentificationTypeName(
                                dependentDetails.identificationType
                              )}{' '}
                              *
                            </Form.Label>
                            <Form.Control
                              isInvalid={!!errors.identificationDocError}
                              type="text"
                              disabled={dependentDetails.applicant}
                              onChange={(e) => {
                                const newFormValue = produce(
                                  dependentDetails,
                                  (draft: PdDependentDetailsModel) => {
                                    switch (
                                      dependentDetails.identificationType
                                    ) {
                                      case 'PASSPORT': {
                                        //statements;
                                        draft.passportNumber = e.target.value
                                        break
                                      }
                                      case 'TIC': {
                                        draft.ticNumber = e.target.value
                                        break
                                      }
                                      case 'SENIOR_CITIZEN_ID': {
                                        //statements;
                                        draft.seniorCitizenId = e.target.value
                                        break
                                      }
                                      case 'DRIVING_LICENSE': {
                                        //statements;
                                        draft.drivingLicenseNumber =
                                          e.target.value
                                        break
                                      }
                                      default: {
                                        break
                                      }
                                    }
                                  }
                                )
                                setDependentDetails(newFormValue)
                                const clearError = produce(errors, (draft) => {
                                  draft.identificationDocError = ''
                                })
                                setErrors(clearError)
                              }}
                              onBlur={() => {
                                const newErrors = produce(
                                  errors,
                                  (draft: PdDependentDetailsErrorModel) => {
                                    switch (
                                      dependentDetails.identificationType
                                    ) {
                                      case 'PASSPORT': {
                                        //statements;
                                        draft.identificationDocError =
                                          validateForm({
                                            type: text,
                                            maxLength: 8,
                                            value:
                                              dependentDetails.passportNumber,
                                            section: 'Passport Number',
                                          } as ValidationObjectModel)
                                        break
                                      }
                                      case 'TIC': {
                                        //statements;
                                        draft.identificationDocError =
                                          validateForm({
                                            type: text,
                                            maxLength: 30,
                                            value: dependentDetails.ticNumber,
                                            section: 'Tic Number',
                                          } as ValidationObjectModel)
                                        break
                                      }
                                      case 'SENIOR_CITIZEN_ID': {
                                        //statements;
                                        draft.identificationDocError =
                                          validateForm({
                                            type: text,
                                            maxLength: 13,
                                            value:
                                              dependentDetails.seniorCitizenId,
                                            section:
                                              'Senior Citizen Identity Card Number',
                                          } as ValidationObjectModel)
                                        break
                                      }
                                      case 'DRIVING_LICENSE': {
                                        //statements;
                                        draft.identificationDocError =
                                          validateForm({
                                            type: text,
                                            maxLength: 15,
                                            value:
                                              dependentDetails.drivingLicenseNumber,
                                            section: 'Driving license number',
                                          } as ValidationObjectModel)
                                        break
                                      }
                                      default: {
                                        break
                                      }
                                    }
                                  }
                                )
                                setErrors(newErrors)
                              }}
                              placeholder={`Enter ${renderIdentificationTypeName(
                                dependentDetails.identificationType
                              )}`}
                              value={renderIdentificationTypeValue(
                                dependentDetails.identificationType,
                                dependentDetails
                              )}
                            />
                            <InvalidFeedback
                              message={errors.identificationDocError}
                            />
                          </Form.Group>
                        </Row>
                      )}
                    </>
                  )}

                  <Form.Group className="mb-3" controlId="idNumberRemark">
                    <Form.Label>ID Number Remark</Form.Label>
                    <Form.Control
                      disabled={dependentDetails.applicant}
                      maxLength={45}
                      type="text"
                      placeholder="Enter Number Remark"
                      onChange={(e) => {
                        const newFormValue = produce(
                          dependentDetails,
                          (draft: PdDependentDetailsModel) => {
                            draft.idNumberRemark = e.target.value
                          }
                        )
                        setDependentDetails(newFormValue)
                      }}
                      value={dependentDetails.idNumberRemark}
                    />
                  </Form.Group>

                  <Col sm={12} className="border-label mt-4">
                    <div className="border-label-span">
                      Birth Certificate Details
                    </div>
                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="formBirthCNumber">
                        <Form.Label>
                          Birth Certificate Number{' '}
                          {dependentDetails.identificationType ==
                          'BIRTH_CERTIFICATE' ? (
                            <span className="required-field-astrix">*</span>
                          ) : (
                            ''
                          )}
                        </Form.Label>
                        <Form.Control
                          isInvalid={!!errors.birthCertificateNumberError}
                          disabled={dependentDetails.applicant}
                          type="text"
                          placeholder="Enter Birth Certificate Number"
                          onBlur={() => {
                            const newErrors = produce(errors, (draft) => {
                              draft.birthCertificateNumberError = validateForm({
                                type: text,
                                maxLength: 4,
                                value: dependentDetails.birthCertificateNumber,
                                section: 'Birth certificate number',
                              } as ValidationObjectModel)
                            })
                            setErrors(newErrors)
                          }}
                          onChange={(e) => {
                            const newFormValue = produce(
                              dependentDetails,
                              (draft: PdDependentDetailsModel) => {
                                draft.birthCertificateNumber = e.target.value
                              }
                            )
                            setDependentDetails(newFormValue)
                            const clearError = produce(errors, (draft) => {
                              draft.birthCertificateNumberError = ''
                            })
                            setErrors(clearError)
                          }}
                          value={dependentDetails.birthCertificateNumber}
                        />
                        <InvalidFeedback
                          message={errors.birthCertificateNumberError}
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="formDpProvince">
                        <Form.Label>
                          Province{' '}
                          {dependentDetails.identificationType ==
                          'BIRTH_CERTIFICATE' ? (
                            <span className="required-field-astrix">*</span>
                          ) : (
                            ''
                          )}
                        </Form.Label>
                        <Select
                          styles={
                            errors.birthCertificateProvinceIdError
                              ? customStyles
                              : undefined
                          }
                          isDisabled={dependentDetails.applicant}
                          value={provinceList.filter(
                            (province: ProvinceOption) => {
                              return (
                                province.value ===
                                selectedBirthCertificateDs.provinceId
                              )
                            }
                          )}
                          options={provinceList}
                          onChange={(selectedOption) => {
                            const newSelectedBirthCertificateDs = produce(
                              selectedBirthCertificateDs,
                              (draft: dsSelectionModal) => {
                                draft.provinceOption = selectedOption
                                  ? selectedOption
                                  : ({} as ProvinceOption)
                                draft.provinceId = selectedOption
                                  ? selectedOption.value
                                  : undefined
                                draft.districtOption = null
                                draft.districtId = undefined
                                draft.dsOption = null
                              }
                            )
                            setSelectedBirthCertificateDs(
                              newSelectedBirthCertificateDs
                            )
                            const clearError = produce(errors, (draft) => {
                              draft.birthCertificateProvinceIdError = ''
                            })
                            setErrors(clearError)
                          }}
                        />
                        <InvalidFeedback
                          message={errors.birthCertificateProvinceIdError}
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="formDpDistrict">
                        <Form.Label>
                          District{' '}
                          {dependentDetails.identificationType ==
                          'BIRTH_CERTIFICATE' ? (
                            <span className="required-field-astrix">*</span>
                          ) : (
                            ''
                          )}
                        </Form.Label>
                        <Select
                          styles={
                            errors.birthCertificateDistrictIdError
                              ? customStyles
                              : undefined
                          }
                          value={districtList.filter(
                            (district: DistrictOption) => {
                              return (
                                district.value ===
                                selectedBirthCertificateDs.districtId
                              )
                            }
                          )}
                          isDisabled={
                            selectedBirthCertificateDs.provinceId ===
                              undefined || dependentDetails.applicant
                          }
                          options={districtList.filter(
                            (district: DistrictOption) => {
                              return (
                                district.provinceId ===
                                selectedBirthCertificateDs.provinceId
                              )
                            }
                          )}
                          onChange={(selectedOption) => {
                            const newSelectedBirthCertificateDs = produce(
                              selectedBirthCertificateDs,
                              (draft: dsSelectionModal) => {
                                draft.districtOption = selectedOption
                                  ? selectedOption
                                  : ({} as DistrictOption)
                                draft.districtId = selectedOption
                                  ? selectedOption.value
                                  : undefined
                                draft.dsOption = null
                              }
                            )
                            setSelectedBirthCertificateDs(
                              newSelectedBirthCertificateDs
                            )
                            const clearError = produce(errors, (draft) => {
                              draft.birthCertificateDistrictIdError = ''
                            })
                            setErrors(clearError)
                          }}
                        />
                        <InvalidFeedback
                          message={errors.birthCertificateDistrictIdError}
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="formDpBirthCDsDivision">
                        <Form.Label>
                          Ds Division of Birth Certificate{' '}
                          {dependentDetails.identificationType ==
                          'BIRTH_CERTIFICATE' ? (
                            <span className="required-field-astrix">*</span>
                          ) : (
                            ''
                          )}
                        </Form.Label>
                        <Select
                          styles={
                            errors.birthCertificateDsDivisionIdError
                              ? customStyles
                              : undefined
                          }
                          value={dsList.filter((ds: DsOption) => {
                            return ds.value === selectedBirthCertificateDs.dsId
                          })}
                          isDisabled={
                            selectedBirthCertificateDs.districtId ===
                              undefined || dependentDetails.applicant
                          }
                          options={dsList.filter((ds: DsOption) => {
                            return (
                              ds.districtId ===
                              selectedBirthCertificateDs.districtId
                            )
                          })}
                          onChange={(selectedOption) => {
                            const newSelectedBirthCertificateDs = produce(
                              selectedBirthCertificateDs,
                              (draft: dsSelectionModal) => {
                                draft.dsOption = selectedOption
                                  ? selectedOption
                                  : ({} as DsOption)
                                draft.dsId = selectedOption
                                  ? selectedOption.value
                                  : undefined
                              }
                            )
                            setSelectedBirthCertificateDs(
                              newSelectedBirthCertificateDs
                            )
                            const newFormValue = produce(
                              dependentDetails,
                              (draft: PdDependentDetailsModel) => {
                                draft.birthCertificateDsDivisionId =
                                  selectedOption?.value
                              }
                            )
                            setDependentDetails(newFormValue)
                            const clearError = produce(errors, (draft) => {
                              draft.birthCertificateDsDivisionIdError = ''
                            })
                            setErrors(clearError)
                          }}
                        />
                        <InvalidFeedback
                          message={errors.birthCertificateDsDivisionIdError}
                        />
                      </Form.Group>
                    </Row>
                  </Col>
                  <Row className="mt-3 mb-3">
                    <Form.Group as={Col} controlId="formDpAge">
                      <Form.Label>
                        Age <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Form.Control
                        disabled
                        isInvalid={!!errors.ageError}
                        type="number"
                        placeholder="Enter Age"
                        value={
                          dependentDetails.age > -1 ? dependentDetails.age : ''
                        }
                        onBlur={() => {
                          const newErrors = produce(errors, (draft) => {
                            draft.ageError = validateForm({
                              type: number,
                              maxLength: 3,
                              value: dependentDetails.age,
                              section: 'Age',
                            } as ValidationObjectModel)
                          })
                          setErrors(newErrors)
                        }}
                        onChange={(e) => {
                          const newFormValue = produce(
                            dependentDetails,
                            (draft: PdDependentDetailsModel) => {
                              draft.age = parseInt(e.target.value)
                            }
                          )
                          setDependentDetails(newFormValue)
                        }}
                      />
                      <InvalidFeedback message={errors.ageError} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formDpGender">
                      <Form.Label>
                        Gender <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Form.Control
                        isInvalid={!!errors.genderError}
                        disabled={checkGenderDisable(
                          fileRegistrationObject.modifyIndex,
                          dependentDetails.identificationType
                        )}
                        className="form-select"
                        as="select"
                        placeholder="Enter Gender"
                        value={dependentDetails.gender}
                        onBlur={() => {
                          const newErrors = produce(errors, (draft) => {
                            draft.genderError = validateForm({
                              type: text,
                              value: dependentDetails.gender,
                              section: 'Gender',
                            } as ValidationObjectModel)
                          })
                          setErrors(newErrors)
                        }}
                        onChange={(e) => {
                          const newFormValue = produce(
                            dependentDetails,
                            (draft: PdDependentDetailsModel) => {
                              draft.gender = e.target.value
                            }
                          )
                          setDependentDetails(newFormValue)
                          const clearError = produce(errors, (draft) => {
                            draft.genderError = ''
                          })
                          setErrors(clearError)
                        }}
                      >
                        <option value="N/A">--Select Gender--</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formDpMaritalStatus">
                      <Form.Label>
                        Marital Status{' '}
                        {fileRegistrationObject.scheme != 'MP' && (
                          <span className="required-field-astrix">*</span>
                        )}
                      </Form.Label>
                      <Form.Control
                        isInvalid={!!errors.maritalStatusError}
                        disabled={dependentDetails.applicant}
                        className="form-select"
                        as="select"
                        value={dependentDetails.maritalStatus}
                        onBlur={() => {
                          const newErrors = produce(errors, (draft) => {
                            draft.maritalStatusError = validateForm({
                              type: text,
                              value: dependentDetails.maritalStatus,
                              section: 'Marital status',
                            } as ValidationObjectModel)
                          })
                          setErrors(newErrors)
                        }}
                        onChange={(e) => {
                          const newFormValue = produce(
                            dependentDetails,
                            (draft: PdDependentDetailsModel) => {
                              draft.maritalStatus = e.target.value
                            }
                          )
                          setDependentDetails(newFormValue)
                          const clearError = produce(errors, (draft) => {
                            draft.maritalStatusError = ''
                          })
                          setErrors(clearError)
                        }}
                      >
                        <option value="">--Select Title--</option>
                        {martialStatusList.map(
                          (
                            title: { name: string; id: number },
                            index: number
                          ) => {
                            return (
                              <option
                                key={`country-${index}`}
                                value={title.name}
                              >
                                {title.name}
                              </option>
                            )
                          }
                        )}
                      </Form.Control>
                      <InvalidFeedback message={errors.maritalStatusError} />
                    </Form.Group>
                  </Row>
                  <Col sm={12} className="border-label mt-4 mb-3">
                    <div className="border-label-span">{`Resident Details`}</div>
                    {dependentList.length > 0 && (
                      <Col>
                        <Form.Check
                          disabled={dependentDetails.applicant}
                          type="checkbox"
                          label="Same as previous"
                          className="mb-2"
                          onChange={(e) => {
                            if (e.target.checked) {
                              const lastIndex = dependentList.length - 1
                              const newFormValue = produce(
                                dependentDetails,
                                (draft: PdDependentDetailsModel) => {
                                  draft.address =
                                    dependentList[lastIndex].address
                                  draft.provinceId =
                                    dependentList[lastIndex].provinceId
                                  draft.districtId =
                                    dependentList[lastIndex].districtId
                                  draft.dsDivisionId =
                                    dependentList[lastIndex].dsDivisionId
                                  draft.gnDivisionId =
                                    dependentList[lastIndex].gnDivisionId
                                  draft.city = dependentList[lastIndex].city
                                }
                              )
                              setDependentDetails(newFormValue)
                              const clearError = produce(errors, (draft) => {
                                draft.addressError = ''
                                draft.provinceIdError = ''
                                draft.districtIdError = ''
                                draft.dsDivisionIdError = ''
                                draft.gnDivisionIdError = ''
                                draft.cityError = ''
                              })
                              setErrors(clearError)
                            } else {
                              const newFormValue = produce(
                                dependentDetails,
                                (draft: PdDependentDetailsModel) => {
                                  draft.address = ''
                                  draft.provinceId = 0
                                  draft.districtId = 0
                                  draft.dsDivisionId = 0
                                  draft.gnDivisionId = 0
                                  draft.city = ''
                                }
                              )
                              setDependentDetails(newFormValue)
                            }
                          }}
                        />
                      </Col>
                    )}
                    <Form.Group className="mb-3" controlId="formDpAddress">
                      <Form.Label>
                        Address Line 1{' '}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Form.Control
                        isInvalid={!!errors.addressError}
                        disabled={dependentDetails.applicant}
                        type="text"
                        placeholder="Enter Address"
                        onBlur={() => {
                          const newErrors = produce(errors, (draft) => {
                            draft.addressError = validateForm({
                              type: text,
                              value: dependentDetails.address,
                              maxLength: 255,
                              section: 'Address of the Dependent',
                            } as ValidationObjectModel)
                          })
                          setErrors(newErrors)
                        }}
                        onChange={(e) => {
                          const newFormValue = produce(
                            dependentDetails,
                            (draft: PdDependentDetailsModel) => {
                              draft.address = e.target.value
                            }
                          )
                          setDependentDetails(newFormValue)
                          const clearError = produce(errors, (draft) => {
                            draft.addressError = ''
                          })
                          setErrors(clearError)
                        }}
                        value={dependentDetails.address}
                      />
                      <InvalidFeedback message={errors.addressError} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formDpCity">
                      <Form.Label>
                        Address Line 2{' '}
                        {fileRegistrationObject.scheme != 'MP' &&
                          fileRegistrationObject.scheme != 'GSD' && (
                            <span className="required-field-astrix">*</span>
                          )}
                      </Form.Label>
                      <Form.Control
                        isInvalid={!!errors.cityError}
                        disabled={dependentDetails.applicant}
                        type="text"
                        placeholder="Enter Address"
                        onBlur={() => {
                          const newErrors = produce(errors, (draft) => {
                            draft.cityError = validateForm(
                              {
                                type: text,
                                value: dependentDetails.city,
                                section: 'Address',
                              } as ValidationObjectModel,
                              fileRegistrationObject.scheme != 'GSD'
                                ? true
                                : false
                            )
                          })
                          setErrors(newErrors)
                        }}
                        onChange={(e) => {
                          const newFormValue = produce(
                            dependentDetails,
                            (draft: PdDependentDetailsModel) => {
                              draft.city = e.target.value
                            }
                          )
                          setDependentDetails(newFormValue)
                          const clearError = produce(errors, (draft) => {
                            draft.cityError = ''
                          })
                          setErrors(clearError)
                        }}
                        value={dependentDetails.city}
                      />
                      <InvalidFeedback message={errors.cityError} />
                    </Form.Group>
                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="formDpProvince">
                        <Form.Label>
                          Province{' '}
                          <span className="required-field-astrix">*</span>
                        </Form.Label>
                        <Select
                          styles={
                            errors.provinceIdError ? customStyles : undefined
                          }
                          isDisabled={dependentDetails.applicant}
                          value={provinceList.filter(
                            (province: ProvinceOption) => {
                              return (
                                province.value === dependentDetails.provinceId
                              )
                            }
                          )}
                          options={provinceList}
                          onChange={(selectedOption) => {
                            const newFormValue = produce(
                              dependentDetails,
                              (draft: PdDependentDetailsModel) => {
                                draft.provinceId = selectedOption
                                  ? selectedOption.value
                                  : 0
                              }
                            )
                            setDependentDetails(newFormValue)
                            const clearError = produce(errors, (draft) => {
                              draft.provinceIdError = ''
                            })
                            setErrors(clearError)
                          }}
                        />
                        <InvalidFeedback message={errors.provinceIdError} />
                      </Form.Group>
                      <Form.Group as={Col} controlId="formDpDistrict">
                        <Form.Label>
                          District{' '}
                          <span className="required-field-astrix">*</span>
                        </Form.Label>
                        <Select
                          styles={
                            errors.districtIdError ? customStyles : undefined
                          }
                          value={districtList.filter(
                            (district: DistrictOption) => {
                              return (
                                district.value === dependentDetails.districtId
                              )
                            }
                          )}
                          isDisabled={
                            dependentDetails.provinceId === undefined ||
                            dependentDetails.provinceId === 0 ||
                            dependentDetails.applicant
                          }
                          options={districtList.filter(
                            (district: DistrictOption) => {
                              return (
                                district.provinceId ===
                                dependentDetails.provinceId
                              )
                            }
                          )}
                          onChange={(selectedOption) => {
                            const newFormValue = produce(
                              dependentDetails,
                              (draft: PdDependentDetailsModel) => {
                                draft.districtId = selectedOption
                                  ? selectedOption.value
                                  : 0
                              }
                            )
                            setDependentDetails(newFormValue)
                            const clearError = produce(errors, (draft) => {
                              draft.districtIdError = ''
                            })
                            setErrors(clearError)
                          }}
                        />
                        <InvalidFeedback message={errors.districtIdError} />
                      </Form.Group>
                      <Form.Group as={Col} controlId="formDpDsDivision">
                        <Form.Label>
                          {`DS Division`}{' '}
                          <span className="required-field-astrix">*</span>
                        </Form.Label>
                        <Select
                          styles={
                            errors.dsDivisionIdError ? customStyles : undefined
                          }
                          value={dsList.filter((ds: DsOption) => {
                            return ds.value === dependentDetails.dsDivisionId
                          })}
                          isDisabled={
                            dependentDetails.districtId === undefined ||
                            dependentDetails.districtId === 0 ||
                            dependentDetails.applicant
                          }
                          options={dsList.filter((ds: DsOption) => {
                            return ds.districtId === dependentDetails.districtId
                          })}
                          onChange={(selectedOption) => {
                            const newFormValue = produce(
                              dependentDetails,
                              (draft: PdDependentDetailsModel) => {
                                draft.dsDivisionId = selectedOption
                                  ? selectedOption.value
                                  : 0
                              }
                            )
                            setDependentDetails(newFormValue)
                            const clearError = produce(errors, (draft) => {
                              draft.dsDivisionIdError = ''
                            })
                            setErrors(clearError)
                          }}
                        />
                        <InvalidFeedback message={errors.dsDivisionIdError} />
                      </Form.Group>
                      <Form.Group as={Col} controlId="formDpGnDivision">
                        <Form.Label>
                          {`GN Division`}{' '}
                          <span className="required-field-astrix">*</span>
                        </Form.Label>
                        <Select
                          styles={
                            errors.gnDivisionIdError ? customStyles : undefined
                          }
                          value={gnList.filter((gn: GnOption) => {
                            return gn.value === dependentDetails.gnDivisionId
                          })}
                          isDisabled={
                            dependentDetails.dsDivisionId === undefined ||
                            dependentDetails.dsDivisionId === 0 ||
                            dependentDetails.applicant
                          }
                          options={gnList.filter((gn: GnOption) => {
                            return gn.dsId === dependentDetails.dsDivisionId
                          })}
                          onChange={(selectedOption) => {
                            const newFormValue = produce(
                              dependentDetails,
                              (draft: PdDependentDetailsModel) => {
                                draft.gnDivisionId = selectedOption
                                  ? selectedOption.value
                                  : 0
                              }
                            )
                            setDependentDetails(newFormValue)
                            const clearError = produce(errors, (draft) => {
                              draft.gnDivisionIdError = ''
                            })
                            setErrors(clearError)
                          }}
                        />
                        <InvalidFeedback message={errors.gnDivisionIdError} />
                      </Form.Group>
                    </Row>
                  </Col>
                  {!(fileRegistrationObject.scheme === 'GSI') && (
                    <Form.Group className="mb-3" controlId="formDpBothParent">
                      <Form.Label>
                        Both parents are deceased{' '}
                        <span className="required-field-astrix">*</span>
                      </Form.Label>
                      <Row>
                        <Col>
                          <Form.Check
                            disabled={dependentDetails.applicant}
                            inline
                            label="Yes"
                            name="formBothParent"
                            type="radio"
                            id={`both-1`}
                            checked={dependentDetails.bothParentsDeceased}
                            onChange={() => {
                              const newFormValue = produce(
                                dependentDetails,
                                (draft: PdDependentDetailsModel) => {
                                  draft.bothParentsDeceased = true
                                }
                              )
                              setDependentDetails(newFormValue)
                              const clearError = produce(errors, (draft) => {
                                draft.bothParentsDeceasedError = ''
                              })
                              setErrors(clearError)
                            }}
                          />
                        </Col>
                        <Col>
                          <Form.Check
                            disabled={dependentDetails.applicant}
                            inline
                            label="No"
                            name="formBothParent"
                            type="radio"
                            id={`both-2`}
                            checked={!dependentDetails.bothParentsDeceased}
                            onChange={() => {
                              const newFormValue = produce(
                                dependentDetails,
                                (draft: PdDependentDetailsModel) => {
                                  draft.bothParentsDeceased = false
                                }
                              )
                              setDependentDetails(newFormValue)
                              const clearError = produce(errors, (draft) => {
                                draft.bothParentsDeceasedError = ''
                              })
                              setErrors(clearError)
                            }}
                          />
                        </Col>
                      </Row>
                      <InvalidFeedback
                        message={errors.bothParentsDeceasedError}
                      />
                    </Form.Group>
                  )}
                  <Col sm={12} className="border-label mt-3 mb-3">
                    <div className="border-label-span">{`Bank Details`}</div>
                    <Row>
                      <Form.Group as={Col} controlId="formDpBankName">
                        <Form.Label>
                          {`Bank Name`}{' '}
                          <span className="required-field-astrix">*</span>
                        </Form.Label>
                        <Form.Control
                          isInvalid={!!errors.bankError}
                          className="form-select"
                          as="select"
                          value={dependentDetails.bankId}
                          onBlur={() => {
                            const newErrors = produce(errors, (draft) => {
                              draft.bankError = validateForm({
                                type: number,
                                value: dependentDetails.bankId,
                                section: 'Bank Name',
                              } as ValidationObjectModel)
                            })
                            setErrors(newErrors)
                          }}
                          onChange={(e) => {
                            const newFormValue = produce(
                              dependentDetails,
                              (draft: PdDependentDetailsModel) => {
                                draft.bankId = parseInt(e.target.value)
                                draft.branchId = 0
                              }
                            )
                            getBranchesList(parseInt(e.target.value))
                            setDependentDetails(newFormValue)

                            const clearError = produce(errors, (draft) => {
                              draft.bankError = ''
                            })
                            setErrors(clearError)
                          }}
                        >
                          {dependentDetails.bankId == null ||
                          dependentDetails.bankId == 0 ? (
                            <option value="0">--Select Bank--</option>
                          ) : (
                            ''
                          )}
                          {bankList.map(
                            (
                              bank: { name: string; id: number },
                              index: number
                            ) => {
                              return (
                                <option key={`bank-${index}`} value={bank.id}>
                                  {bank.name}
                                </option>
                              )
                            }
                          )}
                        </Form.Control>
                        <InvalidFeedback message={errors.bankError} />
                      </Form.Group>
                      <Form.Group as={Col} controlId="formDpBankName">
                        <Form.Label>
                          {`Branch Name`}{' '}
                          <span className="required-field-astrix">*</span>
                        </Form.Label>
                        <Form.Control
                          isInvalid={!!errors.branchError}
                          className="form-select"
                          as="select"
                          disabled={
                            dependentDetails.bankId == null ||
                            dependentDetails.bankId == 0
                          }
                          value={dependentDetails.branchId}
                          onBlur={() => {
                            const newErrors = produce(errors, (draft) => {
                              draft.branchError = validateForm({
                                type: number,
                                value: dependentDetails.branchId,
                                section: 'Branch name',
                              } as ValidationObjectModel)
                            })
                            setErrors(newErrors)
                          }}
                          onChange={(e) => {
                            const newFormValue = produce(
                              dependentDetails,
                              (draft: PdDependentDetailsModel) => {
                                draft.branchId = parseInt(e.target.value)
                              }
                            )
                            setDependentDetails(newFormValue)
                            const clearError = produce(errors, (draft) => {
                              draft.branchError = ''
                            })
                            setErrors(clearError)
                          }}
                        >
                          {dependentDetails.branchId == null ||
                          dependentDetails.branchId == 0 ? (
                            <option value="0">--Select Branch--</option>
                          ) : (
                            ''
                          )}
                          {branchList.map(
                            (
                              branch: { name: string; id: number },
                              index: number
                            ) => {
                              return (
                                <option
                                  key={`branch-${index}`}
                                  value={branch.id}
                                >
                                  {branch.name}
                                </option>
                              )
                            }
                          )}
                        </Form.Control>
                        <InvalidFeedback message={errors.branchError} />
                      </Form.Group>
                      {/* <Form.Group as={Col} controlId="searchInput">
                        <Form.Label>
                          {`Branch Code`}{' '}
                          <span className="required-field-astrix">*</span>
                        </Form.Label>
                        <Form.Control
                          isInvalid={!!errors.branchError}
                          className="form-select"
                          as="select"
                          disabled={
                            dependentDetails.bankId == null ||
                            dependentDetails.bankId == 0
                          }
                          value={dependentDetails.branchId}
                          onBlur={() => {
                            const newErrors = produce(errors, (draft) => {
                              draft.branchError = validateForm({
                                type: number,
                                value: dependentDetails.branchId,
                                section: 'Branch code',
                              } as ValidationObjectModel)
                            })
                            setErrors(newErrors)
                          }}
                          onChange={(e) => {
                            const newFormValue = produce(
                              dependentDetails,
                              (draft: PdDependentDetailsModel) => {
                                draft.branchId = parseInt(e.target.value)
                              }
                            )
                            setDependentDetails(newFormValue)
                            const clearError = produce(errors, (draft) => {
                              draft.branchError = ''
                            })
                            setErrors(clearError)
                          }}
                        >
                          {dependentDetails.branchId == null ||
                          dependentDetails.branchId == 0 ? (
                            <option value="0">--Select Branch--</option>
                          ) : (
                            ''
                          )}
                          {branchList.map(
                            (
                              branch: { code: number; id: number },
                              index: number
                            ) => {
                              return (
                                <option
                                  key={`branch-${index}`}
                                  value={branch.id}
                                >
                                  {branch.code}
                                </option>
                              )
                            }
                          )}
                        </Form.Control>
                        <InvalidFeedback message={errors.branchError} />
                      </Form.Group> */}
                      <Form.Group as={Col} controlId="formDpBankName">
                        <Form.Label>
                          {`Branch Code`}{' '}
                          <span className="required-field-astrix">*</span>
                        </Form.Label>

                        {/* React Select Component */}
                        <Select
                          // isInvalid={!!errors.branchError} // Add error highlighting if needed
                          isDisabled={
                            dependentDetails.bankId == null ||
                            dependentDetails.bankId == 0
                          }
                          options={branchOptions} // Use branch options for dropdown
                          value={branchOptions.find(
                            (option) =>
                              option.value === dependentDetails.branchId
                          )} // Set the value based on branchId
                          onChange={(selectedOption) => {
                            const newFormValue = produce(
                              dependentDetails,
                              (draft) => {
                                draft.branchId = selectedOption
                                  ? selectedOption.value
                                  : 0
                              }
                            )
                            setDependentDetails(newFormValue)
                            const clearError = produce(errors, (draft) => {
                              draft.branchError = ''
                            })
                            setErrors(clearError)
                          }}
                          placeholder="Select Branch"
                          noOptionsMessage={() => 'No branches found'}
                          onBlur={() => {
                            const newErrors = produce(errors, (draft) => {
                              draft.branchError = validateForm({
                                type: number,
                                value: dependentDetails.branchId,
                                section: 'Branch code',
                              } as ValidationObjectModel)
                            })
                            setErrors(newErrors)
                          }}
                          isSearchable={true} // Enable filtering
                          filterOption={(option, inputValue) =>
                            option.label
                              .toLowerCase()
                              .includes(inputValue.toLowerCase())
                          }
                        />

                        <InvalidFeedback message={errors.branchError} />
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group as={Col} controlId="formDpAccountNumber">
                        <Form.Label>
                          Account Number{' '}
                          <span className="required-field-astrix">*</span>
                        </Form.Label>
                        <Form.Control
                          isInvalid={!!errors.accountNumberError}
                          type="text"
                          placeholder="Enter Account Number"
                          onBlur={() => {
                            const newErrors = produce(errors, (draft) => {
                              draft.accountNumberError = validateForm({
                                type: number,
                                value: dependentDetails.accountNumber,
                                section: 'Account Number',
                              } as ValidationObjectModel)
                            })
                            setErrors(newErrors)
                          }}
                          onChange={(e) => {
                            const newFormValue = produce(
                              dependentDetails,
                              (draft: PdDependentDetailsModel) => {
                                draft.accountNumber = e.target.value
                              }
                            )
                            setDependentDetails(newFormValue)
                            const clearError = produce(errors, (draft) => {
                              draft.accountNumberError = ''
                            })
                            setErrors(clearError)
                          }}
                          value={dependentDetails.accountNumber}
                        />
                        <InvalidFeedback message={errors.accountNumberError} />
                      </Form.Group>
                    </Row>
                  </Col>
                </Form>
              </>

              {dependentList.length > 0 &&
                (fileRegistrationObject.status == 'Application Drafted' ||
                  fileRegistrationObject.status ==
                    'Application Modify Draft') &&
                fileRegistrationObject.modifyIndex === 0 && (
                  <Col sm={12} className="border-label mt-3">
                    <div className="border-label-span">{`Compensation Details`}</div>
                    {dependentsCompensationDetails.length == 0 && (
                      <Row className="justify-content-end">
                        <button
                          className="save-button custom-margin-right"
                          onClick={handleGetSuggestCompensation}
                        >
                          Compensation Amount
                        </button>
                      </Row>
                    )}
                    {dependentsCompensationDetails.length > 0 && (
                      <Row className="mt-3">
                        <table className="table table-bordered">
                          <thead className="custom-table-header">
                            <tr>
                              <th>Dependent Name</th>
                              <th>Relationship</th>
                              <th>Compensation Percentage (%)</th>
                            </tr>
                          </thead>
                          <tbody className="custom-table-body">
                            {renderDependentCompensationTable()}
                          </tbody>
                        </table>
                      </Row>
                    )}
                    {dependentsCompensationDetails.length > 0 && (
                      <Row className="justify-content-end">
                        <button
                          className="save-button custom-margin-right"
                          onClick={addCompensationPercentageValues}
                        >
                          Add
                        </button>
                      </Row>
                    )}
                  </Col>
                )}
            </Col>
          )}
          {dependentList.length > 0 && (
            <Col sm={12} className="d-flex justify-content-end mt-4">
              <button
                className="save-button custom-margin-right"
                onClick={onClickSave}
              >
                Save
              </button>
            </Col>
          )}

          {showConfirm.isShow && (
            <ConfirmationModal
              name={'Confirmation'}
              message={showConfirm.message}
              onCancel={() => {
                setShowConfirm({
                  isShow: false,
                  message: '',
                  section: '',
                })
              }}
              isRemark={false}
              onConfirm={handleConfirm}
            />
          )}

          {nextOfKin && (
            <NextOfKinModal
              fileRegistartionObject={fileRegistrationObject}
              nextOfKin={nextOfKinDetails}
              onCancel={() => {
                setNextOfKin(false)
              }}
              onSave={(nextOdKin: NextOfKinModel) => {
                handleSaveNextOfKin(nextOdKin)
              }}
            />
          )}
        </>
      )}
    </>
  )
}

export default SectionFour
