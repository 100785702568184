/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useRouteMatch, Switch, Route } from 'react-router-dom'
import TileButtonComponent from '../../components/common/tileButton/TileButtonComponent'
import { SubPackageModal } from '../../models/package/subPackageModal'
import { getUserSubPackages } from '../../store/user/selectors'
import system_configuration from '../../assets/images/systemConfiguration/system_configuration.svg'
import Scheme from './scheme/Scheme'
import IncidentTypes from './incidentTypes/IncidentTypes'
import TypeOfInjuries from './typeOfInjuries/TypeOfInjuries'
import StampCharges from './manageStampChargers/ManageStampChargeComponent'
import SupportingDocuments from './supportingDocuments/SupportingDocuments'
import CompensationValues from './compensationValues/CompensationValues'
import RejectionReturnReasons from './rejectionReturnReasons/RejectionReturnReasons'
import PropertyDamageType from './propertyDamageType/PropertyDamageType'
import ManagePoliceStation from './mangePoliceStations/ManagePoliceStation'
import ManageBankBranch from './manageBankAndBranch/ManageBankBranch'
import AdministrativeAreas from './administrativeAreas/AdministrativeAreas'

const SystemConfiguration = () => {
  const { path } = useRouteMatch()
  const subPackageList = useSelector(getUserSubPackages('system-configuration'))

  const renderSubPackages = () => {
    return subPackageList.map((subPackage: SubPackageModal, index: number) => {
      return (
        <Col key={`sub-package-${index}`} sm={12} lg={6} className="mt-4">
          <TileButtonComponent
            name={subPackage.description}
            routeURL={subPackage.name}
          />
        </Col>
      )
    })
  }

  return (
    <Switch>
      <Route path={`${path}/`} exact>
        <Row className="title-layout">
          <Col className="d-flex justify-content-start">
            <img src={system_configuration} alt="" />
            <span className="m-2">System Configuration</span>
          </Col>
        </Row>
        <Row className="page-tiles-layout">{renderSubPackages()}</Row>
      </Route>
      <Route path={`${path}/scheme`} component={Scheme} />
      <Route path={`${path}/incident-type`} component={IncidentTypes} />
      <Route path={`${path}/injury-type`} component={TypeOfInjuries} />
      <Route path={`${path}/manage-stamp-charges`} component={StampCharges} />
      <Route
        path={`${path}/rejection-return-reason`}
        component={RejectionReturnReasons}
      />
      <Route
        path={`${path}/compensation-value`}
        component={CompensationValues}
      />
      <Route
        path={`${path}/supporting-document`}
        component={SupportingDocuments}
      />
      <Route
        path={`${path}/property-damage-type`}
        component={PropertyDamageType}
      />
      <Route path={`${path}/police-station`} component={ManagePoliceStation} />

      <Route path={`${path}/bank-and-branch`} component={ManageBankBranch} />

      <Route
        path={`${path}/administrative-areas`}
        component={AdministrativeAreas}
      />
    </Switch>
  )
}

export default SystemConfiguration
