import './EnquireApplication.scss'
import { useState } from 'react'
import { useRouteMatch, useHistory, Route, Switch } from 'react-router-dom'
import { AdvancedFilterModel } from '../../models/utils/filterModel'
import { Col, Row } from 'react-bootstrap'
import { FileRegistrationModel } from '../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import FileView from './FileView'
import dashboard_icon from '../../assets/images/nav-icons/enquire-application.svg'
import { defaultPageLimit } from '../../utils/constants'
import AdvancedSearchTable from '../../components/table/advancedSearchTable/AdvancedSearchTable'
import { applicationCommonColumns } from '../../components/table/advancedSearchTable/AdvancedSearchTableUtil'

const EnquireApplication = () => {
  const [file, setFile] = useState({} as FileRegistrationModel)
  const { path } = useRouteMatch()
  const history = useHistory()

  const initFilterOption = {
    filterValue: null,
    limit: defaultPageLimit,
    page: 1,
    orderBy: 'created_date_time', // Replace with the actual default sorting column
    order: 'DESC',
  } as AdvancedFilterModel

  const handleView = (row: FileRegistrationModel) => {
    setFile(row)
    history.push(`${path}/view/${row.id}`)
  }

  return (
    <>
      <Switch>
        <Route path={`${path}/`} exact>
          <Row className="title-layout">
            <Col className="d-flex justify-content-start">
              <img src={dashboard_icon} alt="" />
              <span className="m-2">Enquire Applications</span>
            </Col>
          </Row>
          <Row className="enquire-page-layout">
            <Col>
              <AdvancedSearchTable
                initFilterOption={initFilterOption}
                columns={applicationCommonColumns}
                dataApiPath={'/enquire-application/view/list'}
                countApiPath={'/enquire-application/view/count'}
                onClickRow={(row: any) => {
                  handleView(row)
                }}
                withProgramType
                withScheme
                withStatus
              />
            </Col>
          </Row>
        </Route>
        {/* <Route
          path={`${path}/view`}
          render={(props) => <FileView applicationFile={file} {...props} />}
        /> */}
        <Route
          path={`${path}/view/:id`}
          render={(props) => <FileView applicationFile={file} {...props} />}
        />
      </Switch>
    </>
  )
}
export default EnquireApplication
