import produce from 'immer'
import React, { FC, useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { FilterOptionModel } from '../../models/utils/filterOption'
import DatePicker from 'react-datepicker'

import {
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../utils/constants'
import { showTotast } from '../../utils/toast'

export interface FilterProps {
  filterData: FilterOptionModel[]
  onFilter: any
}

const GpcReportFilter: FC<FilterProps> = ({ filterData, onFilter }) => {
  const [filters, setFilters] = useState(filterData)
  const [show, setShow] = useState(false)
  useEffect(() => {
    setFilters(filterData)
  }, [show])

  const validate = () => {
    let isValid = false
    const selectedFilters = filters.filter((data) => {
      return data.selected === true
    })

    selectedFilters.map((filterData) => {
      if (filterData.value == null) {
        isValid = false
      } else {
        isValid = true
      }
    })
    return isValid
  }

  const onSubmit = () => {
    if (!validate()) {
      showTotast(
        ALERT_WARNING,
        'Validation error found. Please complete all selected fields and try again.',
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
    } else {
      onFilter(filters)
      setShow(false)
    }
  }
  return (
    <>
      <div style={{ width: '70%' }}>
        <div className="p-4">
          {filters.length > 0 && (
            <Form>
              <Form.Group as={Row} className="mb-3" controlId="from">
                <Col sm="1" className="align-self-center">
                  <Form.Check
                    disabled
                    type="checkbox"
                    checked={filters[0].selected}
                    onChange={(e) => {
                      const newFilters = produce(
                        filters,
                        (draft: FilterOptionModel[]) => {
                          draft[0].selected = e.target.checked
                        }
                      )
                      setFilters(newFilters)
                    }}
                  />
                </Col>
                <Form.Label column sm="5">
                  {filters[0].label}
                </Form.Label>
                <Col sm="6">
                  <DatePicker
                    disabled={!filters[0].selected}
                    className={'form-control'}
                    selected={
                      filters[0].value != null
                        ? new Date(filters[0].value * 1000)
                        : null
                    }
                    onSelect={(date: Date) => {
                      const newFilters = produce(
                        filters,
                        (draft: FilterOptionModel[]) => {
                          draft[0].value = new Date(date).getTime() / 1000
                        }
                      )
                      setFilters(newFilters)
                    }}
                    onChange={(date: Date) => {
                      const newFilters = produce(
                        filters,
                        (draft: FilterOptionModel[]) => {
                          draft[0].value = new Date(date).getTime() / 1000
                        }
                      )
                      setFilters(newFilters)
                    }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="to">
                <Col sm="1" className="align-self-center">
                  <Form.Check
                    disabled
                    type="checkbox"
                    checked={filters[1].selected}
                    onChange={(e) => {
                      const newFilters = produce(
                        filters,
                        (draft: FilterOptionModel[]) => {
                          draft[1].selected = e.target.checked
                        }
                      )
                      setFilters(newFilters)
                    }}
                  />
                </Col>
                <Form.Label column sm="5">
                  {filters[1].label}
                </Form.Label>
                <Col sm="6">
                  <DatePicker
                    disabled={!filters[1].selected}
                    className={'form-control'}
                    selected={
                      filters[1].value != null
                        ? new Date(filters[1].value * 1000)
                        : null
                    }
                    onSelect={(date: Date) => {
                      const newFilters = produce(
                        filters,
                        (draft: FilterOptionModel[]) => {
                          draft[1].value = new Date(date).getTime() / 1000
                        }
                      )
                      setFilters(newFilters)
                    }}
                    onChange={(date: Date) => {
                      debugger
                      const newFilters = produce(
                        filters,
                        (draft: FilterOptionModel[]) => {
                          draft[1].value = new Date(date).getTime() / 1000
                        }
                      )
                      setFilters(newFilters)
                    }}
                  />
                </Col>
              </Form.Group>
            </Form>
          )}
        </div>

        <div className="d-flex justify-content-right p-4">
          <button className="save-button" onClick={onSubmit}>
            Filter Results
          </button>
        </div>
      </div>
    </>
  )
}

export default GpcReportFilter
