import React, { useState } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { useLocation, useHistory, RouteComponentProps } from 'react-router-dom'
import ReactTableWithPaginationComponent from '../../../components/table/ReactTableWithPaginationComponent'
import metaDataService from '../../../services/metaDataService'
import { getDsByDsId } from '../../../store/administrativeArea/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { ActionListModal } from '../../../models/package/actionListModal'
import { getUserSubPackageActions } from '../../../store/user/selectors'
import { gnFetchRequest } from '../../../store/administrativeArea/actions'
import ConfirmationModal from '../../../components/modals/ConfirmationModal'
import {
  ALERT_SUCCESS,
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
} from '../../../utils/constants'
import { showTotast } from '../../../utils/toast'

interface ConfirmationModal {
  isShow: boolean
  message: string
  section: string
  isRemark: boolean
}

type TParams = { dsId: string; provinceId: string; districtId: string }
const GnDivisionList = ({ match }: RouteComponentProps<TParams>) => {
  const [gnList, setGnList] = useState([])
  const location = useLocation()
  //   const history = useHistory()
  const ds = useSelector(getDsByDsId(parseInt(match.params.dsId)))
  const history = useHistory()
  const [gnId, setGnId] = useState('')
  const dispatch = useDispatch()
  const [showConfirm, setShowConfirm] = useState({
    isShow: false,
    isRemark: false,
  } as ConfirmationModal)

  const actionList = useSelector(
    getUserSubPackageActions('system-configuration', 'administrative-areas')
  )

  const checkPermission = (permissionName: string) => {
    const filterdAction = actionList.filter((action: ActionListModal) => {
      if (action.action.name === permissionName) {
        return action
      }
    })[0]
    if (filterdAction != null) {
      return true
    } else {
      return false
    }
  }

  React.useEffect(() => {
    getGnList('')
  }, [location.pathname])

  const getGnList = async (value: string) => {
    const result = await metaDataService.apiGetGnListByDsId(match.params.dsId)
    if (result.status == 200) {
      if (value === '') {
        setGnList(result.data)
      }
      const newData = result.data.filter((data: any) => {
        return data.name.includes(value)
      })
      setGnList(newData)
    }
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Code',
        accessor: 'code', // accessor is the "key" in the data
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Action',
        accessor: 'id',
        width: 100,
        // eslint-disable-next-line react/display-name
        Cell: (e: any) => {
          return (
            <div className="text-center">
              <button
                style={{ width: '6rem' }}
                onClick={(action) => {
                  action.preventDefault()
                  action.stopPropagation()
                  setGnId(e.cell.value)
                  setShowConfirm({
                    isShow: true,
                    message: 'Are you sure you want to modify this record ?',
                    section: 'modify',
                    isRemark: false,
                  })
                }}
                className="save-button m-1 me-1"
              >
                Modify
              </button>
              <button
                style={{ width: '5rem' }}
                onClick={(action) => {
                  action.preventDefault()
                  action.stopPropagation()
                  setGnId(e.cell.value)
                  setShowConfirm({
                    isShow: true,
                    message: 'Are you sure you want to delete this record ?',
                    section: 'delete',
                    isRemark: false,
                  })
                }}
                className="save-button m-1"
              >
                Delete
              </button>
            </div>
          )
        },
      },
    ],
    []
  )

  const deleteGnDivision = async () => {
    const result = await metaDataService.apiDeleteGnDivistion(gnId)
    let success = false
    let message = result.errorMessage ? result.errorMessage : ''
    if (result.status == 200) {
      success = true
      message = 'Gn Division successfully deleted'
      setGnList(result.data)
      dispatch(gnFetchRequest())
      setGnId('')
      setShowConfirm({
        isShow: false,
        message: '',
        section: '',
        isRemark: false,
      })
    }
    showTotast(
      success ? ALERT_SUCCESS : ALERT_WARNING,
      message,
      TOAST_POSITION_TOP_RIGHT,
      5,
      TOAST_TRANSITION_SLIDE
    )
  }

  const handleConfirm = () => {
    if (showConfirm.section == 'delete') {
      deleteGnDivision()
    } else if (showConfirm.section == 'modify') {
      history.push(
        `/system-configuration/administrative-areas/province/${match.params.provinceId}/district/${match.params.districtId}/ds-division/${match.params.dsId}/gn-division/${gnId}/modify`
      )
      setGnId('')
    }
  }

  return (
    <>
      <Row className="title-layout">
        <Col className="d-flex justify-content-start">
          {/* <img src={incident_type} alt="" /> */}
          <span className="m-2">GN Divisions - {ds && ds.name}</span>
        </Col>
      </Row>

      <Row className="incident-types-body-layout mt-4">
        <Col sm={3}>
          <Form.Control
            placeholder="Enter name for filter records"
            className="mt-2 ms-1"
            onChange={(e) => {
              getGnList(e.target.value)
            }}
          />
        </Col>
        <Col sm={9} className="d-flex justify-content-end">
          {checkPermission('add') === true && (
            <button
              className="action-button-layout"
              onClick={() => {
                history.push(
                  `/system-configuration/administrative-areas/province/${match.params.provinceId}/district/${match.params.districtId}/ds-division/${match.params.dsId}/gn-division/add`
                )
              }}
            >
              Add
            </button>
          )}
        </Col>
        <Col sm={12}>
          <ReactTableWithPaginationComponent
            hover={false}
            columns={columns}
            data={gnList ? gnList : []}
            onClickRow={(row: any) => {
              console.log(row.id)
            }}
          />
        </Col>
        {showConfirm.isShow && (
          <ConfirmationModal
            name={'Confirmation'}
            message={showConfirm.message}
            onCancel={() => {
              setShowConfirm({
                isShow: false,
                message: '',
                section: '',
                isRemark: false,
              })
            }}
            onConfirm={handleConfirm}
          />
        )}
      </Row>
    </>
  )
}

export default GnDivisionList
