/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/display-name */

import saveAs from 'file-saver'
import produce from 'immer'
import React, { FC, useState, useRef, useEffect } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import InvalidFeedback from '../../../components/common/invalidFeedback/InvalidFeedback'
import LoadingComponent from '../../../components/common/loading/LoadingComponent'
import ConfirmationModal from '../../../components/modals/ConfirmationModal'
import ReactTableWithPaginationComponent from '../../../components/table/ReactTableWithPaginationComponent'
import { BoardOfTrusteeModel } from '../../../models/applicationRegistration/compensation/boardOfTrustee'
import { BoardOfTrusteeErrorModel } from '../../../models/applicationRegistration/compensation/boardOfTrusteeErrorModel'
import { FileRegistrationModel } from '../../../models/applicationRegistration/fileRegistration/fileRegistrationModel'
import { ValidationObjectModel } from '../../../models/common/validationObjectModel'
import rlpService from '../../../services/applicationRegistration/compensation/rlpService'
import metaDataService from '../../../services/metaDataService'
import {
  number,
  ALERT_WARNING,
  TOAST_POSITION_TOP_RIGHT,
  TOAST_TRANSITION_SLIDE,
  text,
} from '../../../utils/constants'
import { validationError } from '../../../utils/errorMessages'
import { showTotast } from '../../../utils/toast'
import { validateForm, handleNotification } from '../../../utils/utilFunctions'
import ApplicationModificationService from '../../../services/applicationModification/ApplicationModificationService'

export interface SectionBoardOfTrusteeProps {
  fileRegistrationObject: FileRegistrationModel
}

interface ConfirmationModal {
  isShow: boolean
  message: string
  section: string
}

const SectionBoardOfTrustee: FC<SectionBoardOfTrusteeProps> = ({
  fileRegistrationObject,
}) => {
  const [showConfirm, setShowConfirm] = useState({
    isShow: false,
  } as ConfirmationModal)
  const [loading, setLoading] = useState(true)
  const [boardOfTrusteeDetails, setBoardOfTrusteeDetails] = useState(
    {} as BoardOfTrusteeModel
  )
  const [boardOfTrusteeDetailsList, setBoardOfTrusteeDetailsList] = useState(
    [] as BoardOfTrusteeModel[]
  )
  const [errors, setErrors] = useState({} as BoardOfTrusteeErrorModel)
  const [bankList, setBankList] = useState([])
  const [branchList, setBranchList] = useState([])
  const [uploadFile, setUploadFile] = useState({} as File)
  const inputFile = useRef(null)
  const [editMode, setEditMode] = useState(false)
  const columns = React.useMemo(
    () => [
      {
        Header: 'Name of the Board of Trustee',
        accessor: 'boardOfTrustee',
      },
      {
        Header: 'Name of Head Priest',
        accessor: 'headPriestName',
      },
      {
        Header: 'Letter of Board of Trustee',
        accessor: 'fileDownloadUri',
        Cell: (e: any) => {
          return (
            <button
              className="save-button"
              onClick={(action) => {
                action.preventDefault()
                action.stopPropagation()
                downloadUrl(e.cell.value, getFileNameFromUri(e.cell.value))
              }}
            >
              View
            </button>
          )
        },
      },
      {
        Header: 'Bank Name',
        accessor: 'bank.name',
      },
      {
        Header: 'Branch Name',
        accessor: 'branch.name',
      },
      {
        Header: 'Account Number',
        accessor: 'accountNumber',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
    ],
    []
  )

  useEffect(() => {
    async function getMetaData() {
      const bankData = await metaDataService.getBankList()
      if (bankData.status == 200) {
        setBankList(bankData.data)
      }

      const boardOfTrusteeDetailsData =
        await rlpService.apiRlpGetBoardOfTrustee(fileRegistrationObject.id)

      if (boardOfTrusteeDetailsData.status === 200) {
        setBoardOfTrusteeDetailsList(boardOfTrusteeDetailsData.data)
        if (boardOfTrusteeDetailsData.data.length === 0) {
          const newTrustee = produce(
            boardOfTrusteeDetails,
            (draft: BoardOfTrusteeModel) => {
              draft.headPriestName = fileRegistrationObject.applicantName
            }
          )
          setBoardOfTrusteeDetails(newTrustee)
        }
      }
      setLoading(false)
    }
    getMetaData()
  }, [])

  const getBranchesList = async (id: number) => {
    const branchData = await metaDataService.getBranchesList(id)
    if (branchData.status == 200) {
      setBranchList(branchData.data)
    } else {
      setBranchList([])
    }
  }
  const findErrors = () => {
    const newErrors = {} as BoardOfTrusteeErrorModel
    if (
      validateForm({
        type: text,
        maxLength: 50,
        value: boardOfTrusteeDetails.boardOfTrustee,
      } as ValidationObjectModel)
    ) {
      newErrors.boardOfTrusteeError = validateForm({
        type: text,
        maxLength: 50,
        value: boardOfTrusteeDetails.boardOfTrustee,
        section: ' Name of the Board of Trustee/ Dayaka Saba',
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        type: text,
        maxLength: 50,
        value: boardOfTrusteeDetails.headPriestName,
      } as ValidationObjectModel)
    ) {
      newErrors.headPriestNameError = validateForm({
        type: text,
        maxLength: 50,
        value: boardOfTrusteeDetails.headPriestName,
        section: 'Name of Head Priest',
      } as ValidationObjectModel)
    }
    if (
      validateForm({
        type: number,
        value: boardOfTrusteeDetails.bankId,
      } as ValidationObjectModel)
    ) {
      newErrors.bankIdError = validateForm({
        type: number,
        value: boardOfTrusteeDetails.bankId,
        section: 'Bank Name',
      } as ValidationObjectModel)
    }
    if (boardOfTrusteeDetails.bankId) {
      if (
        validateForm({
          type: number,
          minValue: 1,
          customMessage: 'Branch name cannot be empty !',
          value: boardOfTrusteeDetails.branchId,
        } as ValidationObjectModel)
      ) {
        newErrors.branchIdError = validateForm({
          type: number,
          minValue: 1,
          value: boardOfTrusteeDetails.branchId,
          customMessage: 'Branch name cannot be empty !',
          section: 'Branch Name',
        } as ValidationObjectModel)
      }
    }
    if (
      validateForm({
        type: number,
        value: boardOfTrusteeDetails.accountNumber,
        section: 'Account Number',
      } as ValidationObjectModel)
    ) {
      newErrors.accountNumberError = validateForm({
        type: number,
        value: boardOfTrusteeDetails.accountNumber,
        section: 'Account Number',
      } as ValidationObjectModel)
    }
    if (!(uploadFile.name || boardOfTrusteeDetails.fileDownloadUri)) {
      newErrors.LetterofBoardofTrusteeError =
        'Please upload the letter of board of trustee !'
    }
    return newErrors
  }

  const onClickAdd = () => {
    const foundErrros = findErrors()
    if (Object.keys(foundErrros).length > 0) {
      showTotast(
        ALERT_WARNING,
        validationError,
        TOAST_POSITION_TOP_RIGHT,
        5,
        TOAST_TRANSITION_SLIDE
      )
      setErrors(foundErrros)
    } else {
      const message = 'Do you wish to update Trustee ?'
      const section = 'save'
      setShowConfirm({
        isShow: true,
        message: message,
        section: section,
      })
    }
  }

  const onClickEdit = async () => {
    setEditMode(true)
    const activeRecord = boardOfTrusteeDetailsList.filter(
      (trustee: BoardOfTrusteeModel) => {
        if (trustee.status === 'Active') return trustee
      }
    )
    const lastActiveRecord = activeRecord[activeRecord.length - 1]
    await getBranchesList(lastActiveRecord.bankId)
    setBoardOfTrusteeDetails(lastActiveRecord)
  }

  // const onClickSave = () => {
  //   const foundErrros = findErrors()
  //   if (Object.keys(foundErrros).length > 0) {
  //     showTotast(
  //       ALERT_WARNING,
  //       validationError,
  //       TOAST_POSITION_TOP_RIGHT,
  //       5,
  //       TOAST_TRANSITION_SLIDE
  //     )
  //     setErrors(foundErrros)
  //   } else {
  //     const message = 'Do you wish to Save application ?'
  //     const section = 'save'
  //     setShowConfirm({
  //       isShow: true,
  //       message: message,
  //       section: section,
  //     })
  //   }
  // }

  const clearData = () => {
    document.getElementById('bankDetails').reset()
    const newTrusteeDetails = produce(
      boardOfTrusteeDetails,
      (draft: BoardOfTrusteeModel) => {
        draft.applicationFileId = fileRegistrationObject.id
        draft.accountNumber = null
        draft.bankId = 0
        draft.branchId = 0
        draft.boardOfTrustee = null
        draft.headPriestName = null
      }
    )
    setBoardOfTrusteeDetails(newTrusteeDetails)
    setUploadFile({} as File)
  }

  const handleConfirm = async () => {
    document.getElementById('confirmation-ok').disabled = true
    console.log(fileRegistrationObject)
    const message = 'Application Drafted Successfully'
    if (showConfirm.section === 'save') {
      const formData = new FormData()
      formData.append('request', JSON.stringify(boardOfTrusteeDetails))
      if (uploadFile.size > 0) {
        formData.append('files', uploadFile)
      } else {
        formData.append('files', new File([], ''))
      }

      const result =
        await ApplicationModificationService.apiRlpAddBoardOfTrustee(
          formData,
          fileRegistrationObject.id
        )
      handleNotification(result, message)
      if (result.status === 200) {
        setEditMode(false)
        clearData()
        const boardOfTrusteeDetailsData =
          await rlpService.apiRlpGetBoardOfTrustee(fileRegistrationObject.id)

        if (boardOfTrusteeDetailsData.status === 200) {
          setBoardOfTrusteeDetailsList(boardOfTrusteeDetailsData.data)
        } else {
          document.getElementById('confirmation-ok').disabled = false
        }
      }
    }
    setShowConfirm({ isShow: false, message: '', section: '' })
  }

  const checkDisable = () => {
    if (
      fileRegistrationObject.modifyIndex === 0 ||
      fileRegistrationObject.modifyIndex === 2 ||
      fileRegistrationObject.modifyIndex === 3
    ) {
      return false
    }
    return true
  }

  const handleUpload = (file: any) => {
    if (file.length > 0) {
      setUploadFile(file[0])
    }
  }

  const downloadFile = (file: File) => {
    const fileURL = URL.createObjectURL(file)
    const link = document.createElement('a')
    link.href = fileURL
    link.setAttribute('download', file.name)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const downloadUrl = (url: string, name: string) => {
    const headers = {
      responseType: 'blob',
      'Content-Type': 'blob',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }
    fetch(`${process.env.baseUrl}download_file/${name}`, { headers })
      .then((response) => {
        return response.blob()
      })
      .then((data) => {
        saveAs(data, name)
      })
  }

  const getFileNameFromUri = (url: string) => {
    const splitList = url.split('/')
    if (splitList.length > 0) {
      return splitList[splitList.length - 1].replace(/%/g, ' ')
    }
    return 'file'
  }
  return (
    <>
      {loading ? (
        <div className="loading-layout">
          <LoadingComponent />
        </div>
      ) : (
        <>
          <Col sm={12} className="border-label">
            <div className="border-label-span">Board of Trustee</div>
            <Form id="bankDetails">
              <Form.Group className="mb-3" controlId="trusteeNae">
                <Form.Label>
                  Name of the Board of Trustee/ Dayaka Saba{' '}
                  <span className="required-field-astrix">*</span>
                </Form.Label>
                <Form.Control
                  isInvalid={!!errors.boardOfTrusteeError}
                  disabled={checkDisable()}
                  value={boardOfTrusteeDetails.boardOfTrustee}
                  placeholder="Enter Name of the Board of Trustee/ Dayaka Saba"
                  onBlur={() => {
                    const newErrors = produce(errors, (draft) => {
                      draft.boardOfTrusteeError = validateForm({
                        type: text,
                        maxLength: 50,
                        value: boardOfTrusteeDetails.boardOfTrustee,
                        section: ' Name of the Board of Trustee/ Dayaka Saba',
                      } as ValidationObjectModel)
                    })
                    setErrors(newErrors)
                  }}
                  onChange={(e) => {
                    const newBankDetails = produce(
                      boardOfTrusteeDetails,
                      (draft: BoardOfTrusteeModel) => {
                        draft.boardOfTrustee = e.target.value
                      }
                    )
                    setBoardOfTrusteeDetails(newBankDetails)
                    const clearError = produce(errors, (draft) => {
                      draft.boardOfTrusteeError = ''
                    })
                    setErrors(clearError)
                  }}
                />
                <InvalidFeedback message={errors.boardOfTrusteeError} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="headPriest">
                <Form.Label>
                  Name of Head Priest{' '}
                  <span className="required-field-astrix">*</span>
                </Form.Label>
                <Form.Control
                  placeholder="Enter Name of Head Priest"
                  isInvalid={!!errors.headPriestNameError}
                  disabled={
                    boardOfTrusteeDetailsList.length == 0 || checkDisable()
                  }
                  value={boardOfTrusteeDetails.headPriestName}
                  onBlur={() => {
                    const newErrors = produce(errors, (draft) => {
                      draft.headPriestNameError = validateForm({
                        type: text,
                        maxLength: 50,
                        value: boardOfTrusteeDetails.headPriestName,
                        section: 'Name of Head Priest',
                      } as ValidationObjectModel)
                    })
                    setErrors(newErrors)
                  }}
                  onChange={(e) => {
                    const newBankDetails = produce(
                      boardOfTrusteeDetails,
                      (draft: BoardOfTrusteeModel) => {
                        draft.headPriestName = e.target.value
                      }
                    )
                    setBoardOfTrusteeDetails(newBankDetails)
                    const clearError = produce(errors, (draft) => {
                      draft.headPriestNameError = ''
                    })
                    setErrors(clearError)
                  }}
                />
                <InvalidFeedback message={errors.headPriestNameError} />
              </Form.Group>
              {editMode && (
                <Form.Group className="mb-3" controlId="letter">
                  <Form.Label>
                    Letter of Board of Trustee{' '}
                    <span className="required-field-astrix">*</span>
                  </Form.Label>
                  <Row className="justify-content-end">
                    <div className="col-sm-4">
                      <button
                        type="button"
                        disabled={checkDisable()}
                        className={
                          checkDisable() ? 'disable-button' : 'save-button'
                        }
                        onClick={() => {
                          inputFile.current.click()
                        }}
                      >
                        {uploadFile.size > 0 ||
                        boardOfTrusteeDetails.fileDownloadUri != null
                          ? 'Replace'
                          : 'Upload'}
                      </button>
                      <input
                        ref={inputFile}
                        id={`letter-document`}
                        type="file"
                        style={{ display: 'none' }}
                        onChange={(event) => {
                          event.stopPropagation()
                          event.preventDefault()
                          handleUpload(event.target.files)
                          const clearError = produce(errors, (draft) => {
                            draft.LetterofBoardofTrusteeError = ''
                          })
                          setErrors(clearError)
                        }}
                      />
                    </div>
                  </Row>
                  <Row className="mt-3">
                    <table className="table table-bordered">
                      <thead className="custom-table-header">
                        <tr>
                          <th>File Name</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody className="custom-table-body">
                        {uploadFile.size > 0 ? (
                          <tr>
                            <td>{uploadFile.name}</td>
                            <td>
                              <button
                                type="button"
                                className="save-button"
                                onClick={() => {
                                  downloadFile(uploadFile)
                                }}
                              >
                                View
                              </button>
                            </td>
                          </tr>
                        ) : (
                          <>
                            {boardOfTrusteeDetails.fileDownloadUri != null && (
                              <tr>
                                <td>
                                  {getFileNameFromUri(
                                    boardOfTrusteeDetails.fileDownloadUri
                                  )}
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    className="save-button"
                                    onClick={() => {
                                      downloadUrl(
                                        boardOfTrusteeDetails.fileDownloadUri,
                                        getFileNameFromUri(
                                          boardOfTrusteeDetails.fileDownloadUri
                                        )
                                      )
                                    }}
                                  >
                                    View
                                  </button>
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                      </tbody>
                    </table>
                    <InvalidFeedback
                      message={errors.LetterofBoardofTrusteeError}
                    />
                  </Row>
                </Form.Group>
              )}
              <Form.Group className="mb-3" controlId="bankName">
                <Form.Label>
                  Bank Name <span className="required-field-astrix">*</span>
                </Form.Label>
                <Form.Control
                  isInvalid={!!errors.bankIdError}
                  disabled={checkDisable()}
                  className="form-select"
                  as="select"
                  value={boardOfTrusteeDetails.bankId}
                  onBlur={() => {
                    const newErrors = produce(errors, (draft) => {
                      draft.bankIdError = validateForm({
                        type: number,
                        value: boardOfTrusteeDetails.bankId,
                        section: 'Bank Name',
                      } as ValidationObjectModel)
                    })
                    setErrors(newErrors)
                  }}
                  onChange={(e) => {
                    const newBankDetails = produce(
                      boardOfTrusteeDetails,
                      (draft: BoardOfTrusteeModel) => {
                        draft.bankId = parseInt(e.target.value)
                        draft.branchId = 0
                      }
                    )
                    getBranchesList(parseInt(e.target.value))
                    setBoardOfTrusteeDetails(newBankDetails)

                    const clearError = produce(errors, (draft) => {
                      draft.bankIdError = ''
                    })
                    setErrors(clearError)
                  }}
                >
                  {boardOfTrusteeDetails.bankId == null ||
                  boardOfTrusteeDetails.bankId == 0 ? (
                    <option value="0">--Select Bank--</option>
                  ) : (
                    ''
                  )}
                  {bankList.map(
                    (bank: { name: string; id: number }, index: number) => {
                      return (
                        <option key={`bank-${index}`} value={bank.id}>
                          {bank.name}
                        </option>
                      )
                    }
                  )}
                </Form.Control>
                <InvalidFeedback message={errors.bankIdError} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="branchkName">
                <Form.Label>
                  Branch Name <span className="required-field-astrix">*</span>
                </Form.Label>
                <Form.Control
                  isInvalid={!!errors.branchIdError}
                  className="form-select"
                  as="select"
                  disabled={
                    boardOfTrusteeDetails.bankId == null ||
                    boardOfTrusteeDetails.bankId == 0 ||
                    checkDisable()
                  }
                  value={boardOfTrusteeDetails.branchId}
                  onBlur={() => {
                    const newErrors = produce(errors, (draft) => {
                      draft.branchIdError = validateForm({
                        type: number,
                        value: boardOfTrusteeDetails.branchId,
                        section: 'Branch Name',
                      } as ValidationObjectModel)
                    })
                    setErrors(newErrors)
                  }}
                  onChange={(e) => {
                    const newBankDetails = produce(
                      boardOfTrusteeDetails,
                      (draft: BoardOfTrusteeModel) => {
                        draft.branchId = parseInt(e.target.value)
                      }
                    )
                    setBoardOfTrusteeDetails(newBankDetails)
                    const clearError = produce(errors, (draft) => {
                      draft.branchIdError = ''
                    })
                    setErrors(clearError)
                  }}
                >
                  {boardOfTrusteeDetails.branchId == null ||
                  boardOfTrusteeDetails.branchId == 0 ? (
                    <option value="0">--Select Branch--</option>
                  ) : (
                    ''
                  )}
                  {branchList.map(
                    (branch: { name: string; id: number }, index: number) => {
                      return (
                        <option key={`branch-${index}`} value={branch.id}>
                          {branch.name}
                        </option>
                      )
                    }
                  )}
                </Form.Control>
                <InvalidFeedback message={errors.branchIdError} />
              </Form.Group>
              <Form.Group className="mb-3" as={Col} controlId="formDpBankName">
                <Form.Label>{`Branch Code`} </Form.Label>
                <Form.Control
                  disabled
                  type="text"
                  placeholder="Enter Branch Code"
                  value={
                    branchList.filter(
                      (b: any) => b.id == boardOfTrusteeDetails.branchId
                    )[0]?.code
                  }
                />
                <InvalidFeedback message={errors.branchError} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="accountNumber">
                <Form.Label>
                  Account Number{' '}
                  <span className="required-field-astrix">*</span>
                </Form.Label>
                <Form.Control
                  placeholder="Enter Account Number"
                  isInvalid={!!errors.accountNumberError}
                  disabled={checkDisable()}
                  value={boardOfTrusteeDetails.accountNumber}
                  onBlur={() => {
                    const newErrors = produce(errors, (draft) => {
                      draft.accountNumberError = validateForm({
                        type: number,
                        value: boardOfTrusteeDetails.accountNumber,
                        section: 'Account Number',
                      } as ValidationObjectModel)
                    })
                    setErrors(newErrors)
                  }}
                  onChange={(e) => {
                    const newBankDetails = produce(
                      boardOfTrusteeDetails,
                      (draft: BoardOfTrusteeModel) => {
                        draft.accountNumber = e.target.value
                      }
                    )
                    setBoardOfTrusteeDetails(newBankDetails)
                    const clearError = produce(errors, (draft) => {
                      draft.accountNumberError = ''
                    })
                    setErrors(clearError)
                  }}
                />
                <InvalidFeedback message={errors.accountNumberError} />
              </Form.Group>
            </Form>
            {editMode && (
              <Col sm={12} className="d-flex justify-content-end mt-4">
                <button className="save-button" onClick={onClickAdd}>
                  Update
                </button>
              </Col>
            )}
            <Col sm={12} className="border-label mt-4">
              <div className="border-label-span">{`Trustee Details`}</div>
              <ReactTableWithPaginationComponent
                columns={columns}
                data={boardOfTrusteeDetailsList}
                onClickRow={(row: any) => {
                  console.log(row)
                }}
              />
              {boardOfTrusteeDetailsList.length > 0 && (
                <Col sm={12} className="d-flex justify-content-end mt-4">
                  <button className="save-button" onClick={onClickEdit}>
                    Edit
                  </button>
                </Col>
              )}
            </Col>
          </Col>
          {showConfirm.isShow && (
            <ConfirmationModal
              name={'Confirmation'}
              message={showConfirm.message}
              onCancel={() => {
                setShowConfirm({
                  isShow: false,
                  message: '',
                  section: '',
                })
              }}
              isRemark={false}
              onConfirm={handleConfirm}
            />
          )}
        </>
      )}
    </>
  )
}

export default SectionBoardOfTrustee
