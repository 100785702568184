import produce from 'immer'
import { DashboardFilterModel } from '../../models/utils/dashboardFilterModel'
import {
  DashboardFilterActions,
  DashboardFilterState,
  RESET_TIME_FILTER,
  SET_STATUS_FILTERS,
  UPDATE_STATUS_FILTER,
  UPDATE_TIME_FILTER,
} from './actionTypes'

const initialState: DashboardFilterState = {
  timeFilters: [
    { selected: false, label: 'Last 24 hours', value: null, to: null },
    { selected: false, label: 'Last 7 days', value: null, to: null },
    { selected: false, label: 'Last month', value: null, to: null },
    { selected: false, label: 'Last 3 months', value: null, to: null },
    { selected: false, label: 'Considering', value: null, to: null },
  ],
  statusFileters: [],
}

export default (state = initialState, action: DashboardFilterActions) => {
  switch (action.type) {
    case RESET_TIME_FILTER:
      return produce(state, (draft: DashboardFilterState) => {
        draft.timeFilters = [
          { selected: false, label: 'Last 24 hours', value: null, to: null },
          { selected: false, label: 'Last 7 days', value: null, to: null },
          { selected: false, label: 'Last month', value: null, to: null },
          { selected: false, label: 'Last 3 months', value: null, to: null },
          { selected: false, label: 'Considering', value: null, to: null },
        ]
      })
    case SET_STATUS_FILTERS:
      return produce(state, (draft: DashboardFilterState) => {
        draft.statusFileters = action.payload.filters
      })
    case UPDATE_STATUS_FILTER:
      return produce(state, (draft: DashboardFilterState) => {
        draft.statusFileters = draft.statusFileters.map(
          (filter: DashboardFilterModel, index: number) => {
            if (index == action.payload.index) {
              filter.selected = action.payload.filter.selected
            }
            return filter
          }
        )
      })
    case UPDATE_TIME_FILTER:
      return produce(state, (draft: DashboardFilterState) => {
        draft.timeFilters = draft.timeFilters.map(
          (filter: DashboardFilterModel, index: number) => {
            if (index == action.payload.index) {
              filter.selected = action.payload.filter.selected
              filter.value = action.payload.filter.value
              filter.to = action.payload.filter.to
            } else {
              filter.selected = false
            }
            return filter
          }
        )
      })
    default:
      return {
        ...state,
      }
  }
}
