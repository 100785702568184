import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import {
  useHistory,
  useRouteMatch,
  useLocation,
  Switch,
  Route,
} from 'react-router'
import ActionButtonComponent from '../../../components/common/actionButton/ActionButtonComponent'
import LoadingComponent from '../../../components/common/loading/LoadingComponent'
import { ActionListModal } from '../../../models/package/actionListModal'
import { getUserSubPackageActions } from '../../../store/user/selectors'
import RejectionReturnReasonsServices from '../../../services/systemConfiguration/rejectionReturnReasons/rejectionReturnReasonsServices'
import rejection_return_reason from '../../../assets/images/systemConfiguration/rejection-return-reason.svg'
import AddRejectionReturnReasons from './AddRejectionReturnReasons'
import ViewRejectionReturnReasons from './ViewRejectionReturnReasons'
import ModifyRejectionReturnReasons from './ModifyRejectionReturnReasons'

import ReactTableWithPaginationComponent from '../../../components/table/ReactTableWithPaginationComponent'
const RejectionReturnReasons = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [rejectionReturnReasonsList, setRejectionReturnReasonsList] = useState(
    []
  )

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const history = useHistory()
  const { path } = useRouteMatch()
  const location = useLocation()
  const [loading, setLoading] = useState(true)

  const actionList = useSelector(
    getUserSubPackageActions('system-configuration', 'rejection-return-reason')
  )

  const checkPermission = (permissionName: string) => {
    const filterdAction = actionList.filter((action: ActionListModal) => {
      if (action.action.name === permissionName) {
        return action
      }
    })[0]
    if (filterdAction != null) {
      return true
    } else {
      return false
    }
  }
  useEffect(() => {
    async function getIncidentTypesData() {
      const rejectionReturnReasonsData =
        await RejectionReturnReasonsServices.getRejectionReturnReasonsList()
      if (rejectionReturnReasonsData.status === 200) {
        setRejectionReturnReasonsList(rejectionReturnReasonsData.data)
      }
      setLoading(false)
    }
    getIncidentTypesData()
  }, [location.pathname])

  const columns = React.useMemo(
    () => [
      {
        Header: 'Reason Type',
        accessor: 'reasonType',
      },
      {
        Header: 'Reason Description',
        accessor: 'reasonDescription',
      },
      {
        Header: 'Action Type',
        id: 'actionTypeList',
        accessor: (data: { actionTypeList: any[] }) => {
          const output: any[] = []
          data.actionTypeList.map((item) => {
            return output.push(item.actionType)
          })
          return output.join(', ')
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
    ],
    []
  )
  const handleView = (id: number) => {
    history.push(`${path}/view/${id}`)
  }
  return (
    <>
      <Row className="title-layout">
        <Col className="d-flex justify-content-start">
          <img src={rejection_return_reason} alt="" />
          <span className="m-2">Rejection/Return Reasons</span>
        </Col>
      </Row>
      {loading ? (
        <div className="loading-layout">
          <LoadingComponent />
        </div>
      ) : (
        <Switch>
          <Route path={`${path}/`} exact>
            <Row className="incident-types-body-layout mt-4">
              <Col sm={12} className="mb-4">
                <Col className="d-flex justify-content-end">
                  {checkPermission('add') === true && (
                    <ActionButtonComponent name="Add" routeURL="add" />
                  )}
                </Col>
              </Col>
              <Col sm={12}>
                <ReactTableWithPaginationComponent
                  columns={columns}
                  data={rejectionReturnReasonsList}
                  onClickRow={(row: any) => {
                    handleView(row.id)
                  }}
                />
              </Col>
            </Row>
          </Route>
          <Route path={`${path}/add`} component={AddRejectionReturnReasons} />
          <Route
            path={`${path}/view/:id`}
            component={ViewRejectionReturnReasons}
          />
          <Route
            path={`${path}/modify/:id`}
            component={ModifyRejectionReturnReasons}
          />
        </Switch>
      )}
    </>
  )
}

export default RejectionReturnReasons
