// import produce from 'immer'
// import React, { useEffect, useState } from 'react'
// import { useDispatch, useSelector } from 'react-redux'
// // import LoadingComponent from '../../../../components/common/loading/LoadingComponent'
// import IterminalTableComponent from '../../../../components/table/IterminalTableComponent'
// import { FilterModel } from '../../../../models/utils/filterModel'
// import fileAllocationDataService from '../../../../services/applicationRegistration/fileAllocation'
// import {
//   fileAllocationFilters,
//   fileRegistrationColumns,
// } from '../../../../utils/metaData'
// import { getPageCount } from '../../../../utils/tableFunctions'
// import { filtersSetRequest } from '../../../../store/filter/actions'
// import { FilterOptionModel } from '../../../../models/utils/filterOption'
// import FileAllocationFilters from '../../../../components/filters/FileAllocationFilters'
// import { getFilters } from '../../../../store/filter/selectors'
// import {
//   defaultOrderListLimit,
//   defaultPageLimit,
// } from '../../../../utils/constants'
// import { useHistory, useRouteMatch } from 'react-router'

// const FileAllocationComponent = () => {
//   const filterOption = {
//     descending: true,
//     limit: defaultPageLimit,
//     orderFields: defaultOrderListLimit,
//   } as FilterModel
//   const [filter, setFilter] = useState(filterOption)
//   const [pageCount, setPageCount] = useState(0)
//   const [dataCount, setDatacount] = useState(0)
//   const [state, setstate] = useState([])
//   const [loading, setLoading] = useState(true)
//   const filters = useSelector(getFilters)
//   const dispatch = useDispatch()
//   const { path } = useRouteMatch()
//   const history = useHistory()

//   useEffect(() => {
//     dispatch(filtersSetRequest({ filters: fileAllocationFilters }))
//     async function getApplicationFiles() {
//       await retriveDataCountAndData(filter)
//       setLoading(false)
//     }
//     getApplicationFiles()
//   }, [])
//   const columns = React.useMemo(() => fileRegistrationColumns, [])

//   const retriveApplicationFilesData = async (pageDetails: any) => {
//     setLoading(true)
//     const newFilter = produce(filter, (draft) => {
//       draft.page = pageDetails.pageIndex + 1
//     })
//     setFilter(newFilter)
//     const applicationData =
//       await fileAllocationDataService.apiFetchFileAllocationList(newFilter)
//     setstate(applicationData.data)
//     setLoading(false)
//   }

//   const retriveFilterData = async (selectedFilters: FilterOptionModel[]) => {
//     setLoading(true)
//     const newFilter = produce(filter, (draft) => {
//       draft.page = 1
//       draft.filterData = selectedFilters
//     })
//     setFilter(newFilter)
//     await retriveDataCountAndData(newFilter)
//     setLoading(false)
//   }

//   async function retriveDataCountAndData(filterData: FilterModel) {
//     const applicationCountData =
//       await fileAllocationDataService.apiFetchFileAllocationListCount(
//         filterData
//       )
//     setDatacount(applicationCountData.data.count)
//     setPageCount(
//       getPageCount(applicationCountData.data.count, filterOption.limit)
//     )
//     const applicationData =
//       await fileAllocationDataService.apiFetchFileAllocationList(filterData)
//     setstate(applicationData.data)
//   }
//   const handleView = (id: number) => {
//     history.push(`${path}view/${id}`)
//   }
//   return (
//     <>
//       {!loading && (
//         <div className="mb-4">
//           <FileAllocationFilters
//             filterData={filters}
//             onFilter={(e: FilterOptionModel[]) => {
//               dispatch(filtersSetRequest({ filters: e }))
//               retriveFilterData(
//                 e.filter(
//                   (filter: FilterOptionModel) => filter.selected === true
//                 )
//               )
//             }}
//           />
//         </div>
//       )}
//       <div>
//         <IterminalTableComponent
//           columns={columns}
//           data={state.length > 0 ? state : []}
//           fetchData={retriveApplicationFilesData}
//           loading={loading}
//           pageCount={pageCount}
//           totalCount={dataCount}
//           onClickRow={(row: any) => {
//             handleView(row.id)
//           }}
//         />
//       </div>
//     </>
//   )
// }

// export default FileAllocationComponent

import { useEffect } from 'react'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { defaultPageLimit } from '../../../../utils/constants'
import AdvancedSearchTable from '../../../../components/table/advancedSearchTable/AdvancedSearchTable'
import { applicationCommonColumns } from '../../../../components/table/advancedSearchTable/AdvancedSearchTableUtil'
import { AdvancedFilterModel } from '../../../../models/utils/filterModel'
import { useDispatch } from 'react-redux'
import { filtersSetRequest } from '../../../../store/filter/actions'
import { fileAllocationFilters } from '../../../../utils/metaData'

const FileAllocationComponent = () => {
  // Initialize the filter option as per the AdvancedSearchTable requirements
  const initFilterOption = {
    filterValue: null,
    limit: defaultPageLimit, // Adjust if needed
    page: 1,
    orderBy: 'created_date_time', // Adjust the default orderBy field if necessary
    order: 'DESC',
  } as AdvancedFilterModel

  const { path } = useRouteMatch()
  const history = useHistory()
  const dispatch = useDispatch()

  // Effect to set the filters in the Redux store
  useEffect(() => {
    dispatch(filtersSetRequest({ filters: fileAllocationFilters }))
  }, [dispatch])

  // Handle row click event
  const handleView = (id: number) => {
    history.push(`${path}view/${id}`)
  }

  return (
    <>
      <AdvancedSearchTable
        initFilterOption={initFilterOption} // Pass the initialized filter option
        columns={applicationCommonColumns} // Use the columns relevant to your data
        dataApiPath={
          '/application-registration/file-registration/file-allocation/view/list'
        } // Set to your actual data API path
        countApiPath={
          '/application-registration/file-registration/file-allocation/view/count'
        } // Set to your actual count API path
        onClickRow={(row: any) => {
          handleView(row.id) // Handle row clicks
        }}
      />
    </>
  )
}

export default FileAllocationComponent
